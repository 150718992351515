import * as presentationTypes from '../types/PresentationTypes';

const initialState = {
  active: false,
  pasued: false,
  type: "",
  schedule: [],
  index: 0,
  subIndex: 0,
  childActive: false,
  childLastIndex: 0,
  lastPage: "",
};

const presentationReducer = (state = (localStorage.getItem("session") ? JSON.parse(localStorage.getItem("session")) : initialState), action) => {
  switch (action.type) {
    case presentationTypes.PRESENTATION_STARTED:
      return { ...state, active: true };
    case presentationTypes.PRESENTATION_ENDED:
      return { ...initialState, active: false, paused: false };
    case presentationTypes.PRESENTATION_UPDATED:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default presentationReducer;
