export const mapEventData = (events) => {
  return events.map(event => {
    return {
      id: event.id,
      title: event.title,
      allDay: true,
      start: new Date(event.date),
      end: new Date(event.date),
      sharedStatus: event.sharedStatus,
      bgColour: event.colour.bgColour,
      fgColour: event.colour.fgColour
    }
  })
}
