import { client } from "./BaseService";
import { gql } from "apollo-boost";

const getInnovations = async (id, type = "INNOVATION_ISSUE") => {
  return await client.query({
    query: gql`query{
      team(id: "${id}") {
        id
        loggedIssues(type: ${type} ) {
          id
          whyText
          whatText
          archived
          reviewDate
          innovationArchived
          innovationApproved
          innovationStatus
          shared
          suggestedBy {
            id
            firstNames
            lastName
          }
          creator {
            id
          }
          actions {
            id
            status
            actionType
            description
            archived
            who {
              id
              firstNames
              lastName
            }
            when
            archived
            escalated
          }
        }
      }
    }`
  })
}

export const getInnovationsPG = async (id, archived, page, download, sortField, sortDirection) => {
  return await client.query(
    {
      query: gql`query{
        getInnovations(teamId:"${id}", archived: ${archived}, page: ${page}, download: ${download}, sortField: "${sortField}", sortDirection: ${sortDirection}) 
      }`
    }
  )
}

export const createIssue = async(creatorId, whatText, whyText, date, suggestedById) => {

  return await client.mutate({
    mutation: gql`mutation {
      createIssue(
        creatorId: "${creatorId}",
        whatText: "${whatText}",
        whyText: "${whyText}",
        reviewDate: "${date}",
        suggestedById: "${suggestedById}",
        type: INNOVATION_ISSUE,
        innovationStatus: NONE,
        innovationApproved: NONE
      ) {
        id
      }
    }`
  })
}

const updateInnovationStatus = async (id, status) => {
  return await client.mutate({
    mutation: gql`mutation {
        updateIssue(
          id: "${id}",
          innovationStatus: ${status}
        ) {
          id
        }
      }`
  })
}

const updateApprovalStatus = async (id, status) => {
  return await client.mutate({
    mutation: gql`mutation {
        updateIssue(
          id: "${id}",
          innovationApproved: ${status}
        ) {
          id
        }
      }`
  })
}

const updateNextSteps = async (id, nextSteps) => {
  return await client.mutate({
    mutation: gql`mutation {
        updateAction(
          id: "${id}",
          status: ${nextSteps}
        ) {
          id
        }
      }`
  })
}

export const createAction = async(issueId, actionType, status) => {
  return await client.mutate({
    mutation: gql`mutation {
        createAction(
          issueId: "${issueId}",
          actionType: ${actionType},
          status: ${status},
        ) {
          id
        }
      }`
  })
}

const updateIssue = async (id, whatText, whyText, date, suggestedById) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateIssue(
        id: "${id}",
        whatText: "${whatText}",
        whyText: "${whyText}",
        reviewDate: "${date}",
        suggestedById: "${suggestedById}",
      ) {
        id
      }
    }`
  })
}

const archiveInnovation = async (id, archived) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateIssue(
        id: "${id}",
        innovationArchived: ${archived}
      ) {
        id
      }
    }`
  })
}

const deleteAction = async(id) => {
  return await client.mutate({
    mutation: gql`mutation {
      deleteAction(
        id: "${id}"
      ) {
        id
      }
    }`
  })
}

export {
  getInnovations,
  updateApprovalStatus,
  updateIssue,
  updateNextSteps,
  archiveInnovation,
  deleteAction,
  updateInnovationStatus
}
