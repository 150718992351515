import React, { Component, Fragment } from 'react'
import debounce from 'lodash/debounce'
import { connect } from 'react-redux'
import { updatePermissions } from '../redux/services/UserService';
import SubHeaderComponent from "../components/SubHeaderComponent";
// services
import { getGoalCategories, updateGoalCategoriesOrder } from '../services/GoalCategoryService'
import { createCompanyValue, getCompanyValues, updateCompanyValue, deleteCompanyValue, updateCompanyValuesOrder } from '../services/CompanyValueService'
import { createGoal, getGoals, getVision, updateVision, updateGoal, deleteGoal, createCompanyStartWeek, updateCompanyStartWeek, getStartWeeks } from '../services/GoalService'
import { getUnits } from "../services/UnitService"

// bootstrap
import { Table, Row, Button, Col, OverlayTrigger, Tooltip, ButtonGroup, ToggleButton } from "react-bootstrap"
import { faPlus, faCheck, faSpinner, faBan } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { toast } from 'react-toastify'
import changeListItemOrder from '../components/utils/changeListItemOrder'
import displayErrorToast from '../components/utils/displayErrorToast'
import alphabeticalSort from '../components/utils/alphabeticalSort'
import putOtherLast from "../components/utils/putOtherLast"
import FiscalYearDateSelector from "../components/FiscalYearDateSelector"
import CreatableSelect from 'react-select/creatable'
import LabelComponent from "../components/utils/getCompanyLabel";
import * as XLSX from 'xlsx';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import * as DefaultLabels from '../assets/glossary.json';

// icons
import Save from '../assets/ButtonIcons/Save Blue.svg';
import Cancel from '../assets/ButtonIcons/Cancel Blue.svg';
import Edit from '../assets/ButtonIcons/Edit Blue.svg';
import Delete from '../assets/ButtonIcons/Delete Blue.svg';
import Export from '../assets/ButtonIcons/Export Blue.svg';
import PostNextYear from '../assets/ButtonIcons/Post to Next Year Blue.svg';
import MoveUp from '../assets/ButtonIcons/Move Up Blue.svg';
import MoveDown from '../assets/ButtonIcons/Move Down Blue.svg';
import Add from '../assets/ButtonIcons/Add Blue.svg';
import Loader from '../assets/ButtonIcons/Loader Blue.svg';
const labels = DefaultLabels.default;

function renderTooltip(props, text) {
  return (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );
}

class GoalsScreen extends Component {

  constructor(props) {
    super(props)

    this.state = {
      goalCategories: [],
      companyValues: [],
      goals: [],
      allGoals: [],
      units: [],
      months: [
        {
          index: 0,
          name: "Jan"
        },
        {
          index: 1,
          name: "Feb"
        },
        {
          index: 2,
          name: "Mar"
        },
        {
          index: 3,
          name: "Apr"
        },
        {
          index: 4,
          name: "May"
        },
        {
          index: 5,
          name: "Jun"
        },
        {
          index: 6,
          name: "Jul"
        },
        {
          index: 7,
          name: "Aug"
        },
        {
          index: 8,
          name: "Sep"
        },
        {
          index: 9,
          name: "Oct"
        },
        {
          index: 10,
          name: "Nov"
        },
        {
          index: 11,
          name: "Dec"
        }
      ],
      value: "",
      definition: "",
      addValue: false,
      disabled: true,
      addGoal: false,
      category: "",
      measurement: "",
      measurementId: "",
      unit: "",
      numberFrom: 0,
      numberTo: 0,
      dueDate: new Date(),
      vision: "",
      editId: 0,
      editType: "",
      prevVision: "",
      companyId: 0,
      companyName: "",
      loading: false,
      isFetchingData: true,
      loadType: "",
      startMonth: "",
      deleteId: 0,
      fixedUnit: false,
      period: "",
      prevPeriod: "",
      nextPeriod: "",
      selectedPeriod: "",
      loadingId: "",
      startWeeks: [],
      week: null,
      weekPeriod: null,
      weekDate: null
    }
    this.props.toggleDirtyState(true);
    this.props.togglePageLoad(true)
    this.submitValue = this.submitValue.bind(this)
    this.renderGoalRow = this.renderGoalRow.bind(this)
    this.renderAddGoal = this.renderAddGoal.bind(this)
    this.renderGoals = this.renderGoals.bind(this)
    this.fetchStartWeeks = this.fetchStartWeeks.bind(this)
  }

  async componentDidMount() {
    this.setState({ isFetchingData: true });
    await this.fetchVision()
    await Promise.all([
      this.fetchGoalCategories(),
      this.fetchGoals(),
      this.fetchCompanyValues(),
      this.fetchUnits(),
      this.fetchStartWeeks()
    ]);
    this.setState({ isFetchingData: false });
    this.props.togglePageLoad(false)
  }

  async fetchUnits () {
    const response = await getUnits()

    this.setState({
      units: putOtherLast(response.data.units)
    })
  }

  async fetchStartWeeks () {
    const response = await getStartWeeks()

    this.setState({
      startWeeks: response.data.companyStartWeeks
    })
  }

  async fetchGoals() {
    const { selectedPeriod } = this.state
    const response = await getGoals()
    if (response.data) {
      // Sorting here because we cannot sort by a nested field in the API
      const goals = response.data.goals
      this.setState({
        goals: goals.filter(goal => goal.period === selectedPeriod).sort((a, b) => alphabeticalSort(a, b, 'measurement.measurement')),
        allGoals: goals
      })
    }
  }

  getLabelText = key => <LabelComponent val={key}/>

  setPeriod = (period) => {
    const { allGoals } = this.state
    this.setState({
      selectedPeriod: period,
      goals: allGoals.filter(goal => goal.period === period).sort((a, b) => alphabeticalSort(a, b, 'measurement.measurement')),
    })
  }

  getText = val => {
    const { user } = this.props;

    const companyId = localStorage.getItem("companyId");
    let lang = "EN";
    let langLabels = labels.find(lbl => lbl.lang === lang);
    let def = langLabels.glossary.find(label => label.key === val);

    if(user && user.companies && user.companies.length) {
      const company = user.companies.find(company => company.id === companyId);
      if(company) {
        lang = company.language;
        langLabels = labels.find(lbl => lbl.lang === lang);
        def = langLabels.glossary.find(label => label.key === val);
        if(!def) {
          lang = "EN";
          langLabels = labels.find(lbl => lbl.lang === lang);
          def = langLabels.glossary.find(label => label.key === val);
        }
      }
      if(company && company.companyGlossaries) {
        const custom = company.companyGlossaries.find(label => label.key === val);

        if(custom) return custom.value
      }
    }

    if(!def) {
      console.log(val)
    }
    return def.value
  }

  getPeriod = (startMonth, type) => {
    const { months } = this.state

    const fiscalMonth = months.find(month => startMonth.includes(month.name))
    const currentMonth = new Date().getMonth()

    switch(type) {
      case "next":
        return currentMonth < fiscalMonth.index ? `${new Date().getFullYear()}${new Date().getFullYear() + 1}` : `${new Date().getFullYear() + 1}${new Date().getFullYear() + 2}`
      case "prev":
        return currentMonth < fiscalMonth.index ? `${new Date().getFullYear() - 2}${new Date().getFullYear() - 1}` : `${new Date().getFullYear() - 1}${new Date().getFullYear()}`
      default:
        return currentMonth < fiscalMonth.index ? `${new Date().getFullYear() - 1}${new Date().getFullYear()}` : `${new Date().getFullYear()}${new Date().getFullYear() + 1}`
    }

  }

  fetchVision = async () => {
    const { selectedPeriod } = this.state
    const response = await getVision()
    if (response.data.company) {
      this.setState({
        vision: response.data.company.vision !== "null" ? response.data.company.vision : "",
        companyId: response.data.company.id,
        companyName: response.data.company.name,
        startMonth: response.data.company.finalcialYearEnd,
        period: this.getPeriod(response.data.company.finalcialYearEnd, "current"),
        nextPeriod: this.getPeriod(response.data.company.finalcialYearEnd, "next"),
        prevPeriod: this.getPeriod(response.data.company.finalcialYearEnd, "prev"),
        selectedPeriod: selectedPeriod && selectedPeriod !== "" ? selectedPeriod : this.getPeriod(response.data.company.finalcialYearEnd, "current")
      })
    }

  }

  async fetchCompanyValues() {
    const response = await getCompanyValues()

    this.setState({
      companyValues: response.data.companyValues
    })
  }

  async fetchGoalCategories() {
    const response = await getGoalCategories()

    if(response.data.goalCategories.length > 0) {
      this.setState({
        goalCategories: putOtherLast(response.data.goalCategories)
      })
    }
  }

  toggleAddValue = () => {
    this.props.toggleDirtyState()
    if (this.state.editId !== 0) {
      this.setState({
        value: "",
        definition: "",
        editId: 0,
        editType: "",
        addValue: this.state.addValue
      })
    } else {
      this.setState({
        value: "",
        definition: "",
        editId: 0,
        editType: "",
        addValue: !this.state.addValue
      })
    }
  }

  toggleEditValue = (companyValue) => {
    this.props.toggleDirtyState()
    this.setState({
      editId: companyValue.id,
      editType: "value",
      value: companyValue.value,
      definition: companyValue.definition
    })
  }

  toggleAddStartWeek = () => {
    this.props.toggleDirtyState()
    if (this.state.editId !== 0) {
      this.setState({
        weekDate: null,
        editId: 0,
        editType: "",
        addStartWeek: this.state.addStartWeek
      })
    } else {
      this.setState({
        weekDate: new Date(),
        editId: 0,
        editType: "",
        addStartWeek: !this.state.addStartWeek
      })
    }
  }

  toggleEditStartWeek = (startWeek) => {
    this.props.toggleDirtyState()
    this.setState({
      editId: startWeek.id,
      editType: "startWeek",
      weekDate: new Date(startWeek.date)
    })
  }

  downloadCSV = (measurement) => {
    const csvData = measurement.allMetricsCSV;

    if(csvData && csvData.length) {
      let downloadLink = document.createElement("a");
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const ws = XLSX.utils.json_to_sheet(csvData.map(metric => {
        delete metric.__typename

        return metric
      }), {
        skipHeader: true
      });
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], {type: fileType});
      let url = URL.createObjectURL(blob);

      downloadLink.href = url;
      downloadLink.download = `[${new Date().toLocaleDateString('en-GB')}] ${measurement.goalCategory.name + " - " + measurement.measurement.measurement} (${measurement.measurement.unit.name}).xlsx`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

    }
  }

  getDefaultDate = () => {
    const { startMonth, months, selectedPeriod } = this.state
    const fiscalMonth = months.find(month => startMonth.includes(month.name))
    const EndMonth = months.find(month => month.index === (fiscalMonth.index === 0 ? 11 : fiscalMonth.index - 1))

    return new Date(parseInt(selectedPeriod.substr(selectedPeriod.length - 4)), EndMonth.index + 1, 0);
  }

  toggleAddGoal = (category = "") => {
    this.props.toggleDirtyState()
    this.setState({
      category,
      measurement: "",
      measurementId: "",
      unit: "",
      numberFrom: 0,
      numberTo: 0,
      dueDate: this.getDefaultDate(),
      editType: "",
      editId: 0,
      addGoal: true
    })
  }

  clearGoal = () => {
    this.props.toggleDirtyState()
    this.setState({
      category: "",
      measurement: "",
      measurementId: "",
      unit: "",
      numberFrom: 0,
      numberTo: 0,
      dueDate: "",
      editType: "",
      editId: 0,
      addGoal: false
    })
  }

  toggleEditGoal = (goal) => {
    this.props.toggleDirtyState()
    this.setState({
      category: goal.goalCategory.id,
      measurement: goal.measurement.measurement,
      measurementId: goal.measurement.id,
      unit: goal.measurement.unit.id,
      numberFrom: goal.numberFrom,
      numberTo: goal.numberTo,
      dueDate: new Date(goal.dueDate),
      editId: goal.id,
      editType: "goal",
      addGoal: false
    })
  }

  toggleEditVision = () => {
    const { vision, prevVision, editType } = this.state
    this.props.toggleDirtyState()
    let options = {}

    if (editType === "vision") {
      options = {
        editType: "",
        vision: prevVision,
        prevVision: ""
      }
    } else {
      options = {
        editType: "vision",
        prevVision: vision
      }
    }

    this.setState(options)
  }

  handleChangeValuesOrder = (dir, index) => {
    const { companyValues } = this.state;
    const updated = changeListItemOrder(companyValues, dir, index)
    this.setState({ companyValues: updated })
    this.submitValuesOrder(updated)
  }

  handleChangeCategoriesOrder = (dir, index) => {
    const { goalCategories } = this.state;
    const updated = changeListItemOrder(goalCategories, dir, index)
    this.setState({ goalCategories: updated })
    this.submitCategoriesOrder(updated)
  }

  renderCompanyValueRow = (companyValue, i, length) => {
    const { editType, editId, loading, loadType, deleteId } = this.state
    const isTopItem = i === 0
    const isBottomItem = i === length - 1

    if (editType === "value" && editId === companyValue.id) {
      return this.renderAddValue()
    } else {
      return (
        <tr key={companyValue.id}>
          <td>{companyValue.value}</td>
          <td>{companyValue.definition}</td>
          {this.props.permissions.canEdit &&  (
            <td className="text-right">
              {this.state.selectedPeriod !== this.state.prevPeriod ? (
                  <OverlayTrigger
                      placement="top"
                      delay={{ show: 25, hide: 40 }}
                      overlay={(props) => renderTooltip({...props}, this.getLabelText('edit'))}
                  >
                    <Button className={"btn-icons-container" + (this.state.editType !== "" || this.state.addValue || this.state.addGoal ? " disabled" : "")} variant="primary" onClick={() => this.toggleEditValue(companyValue)}><img src={Edit} alt="Edit" className="btn-icons"/></Button>
                  </OverlayTrigger>
              ) : null}
              {this.state.selectedPeriod !== this.state.prevPeriod ? (
                  <OverlayTrigger
                      placement="top"
                      delay={{ show: 25, hide: 40 }}
                      overlay={(props) => renderTooltip({...props}, this.getLabelText('delete'))}
                  >
                    <Button className={"btn-icons-container" + (this.state.editType !== "" || this.state.addValue || this.state.addGoal ? " disabled" : "")} variant="primary" onClick={() => this.deleteCompanyValueItem(companyValue.id)}>
                      {loading && loadType === "delValue" && deleteId === companyValue.id
                          ? (
                          <img src={Loader} alt="Loader" className="loader-spinner"/>
                          )
                          : (
                          <img src={Delete} alt="Delete" className="btn-icons"/>
                          )
                      }
                    </Button>
                  </OverlayTrigger>
              ) : null}
              <OverlayTrigger
                placement="top"
                delay={{ show: 25, hide: 40 }}
                overlay={(props) => renderTooltip({...props}, this.getLabelText('up'))}
              >
                <Button className={"btn-icons-container" + (this.state.editType !== "" || this.state.addValue || this.state.addGoal ? " disabled" : "")} disabled={isTopItem} onClick={() => this.handleChangeValuesOrder('dec', i)}><img src={MoveUp} alt="MoveUp" className="btn-icons"/></Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                delay={{ show: 25, hide: 40 }}
                overlay={(props) => renderTooltip({...props}, this.getLabelText('down'))}
              >
                <Button className={"btn-icons-container" + (this.state.editType !== "" || this.state.addValue || this.state.addGoal ? " disabled" : "")} disabled={isBottomItem} onClick={() => this.handleChangeValuesOrder('inc', i)}><img src={MoveDown} alt="MoveDown" className="btn-icons"/></Button>
              </OverlayTrigger>
            </td>
          )}
        </tr>
      )
    }
  }

  renderStartWeekRow = (startWeek) => {
    const { editType, editId } = this.state

    if(!startWeek) {
      return (
        <tr>
          <td colSpan={2} className="text-center">{this.getLabelText("no_date_selected_true_north")}</td>
        </tr>
      )
    }

    if (editType === "startWeek" && editId === startWeek.id) {
      return this.renderAddStartWeek()
    } else {
      return (
        <tr>
          {this.props.permissions.canEdit && (
            <td className="text-right">
              {this.state.selectedPeriod !== this.state.prevPeriod ? (
                  <OverlayTrigger
                      placement="top"
                      delay={{ show: 25, hide: 40 }}
                      overlay={(props) => renderTooltip({...props}, this.getLabelText('edit'))}
                  >
                    <Button className={"btn-icons-container" + (this.state.editType !== "" || this.state.addValue || this.state.addGoal ? " disabled" : "")} variant="primary" onClick={() => this.toggleEditStartWeek(startWeek)}><img src={Edit} alt="Edit" className="btn-icons"/></Button>
                  </OverlayTrigger>
              ) : null}
            </td>
          )}
          <td className={"text-center"}>{this.formattedStartWeekDate(new Date(startWeek.date))}</td>
        </tr>
      )
    }
  }

  renderVisionField = () => {
    const { vision, editType, loading, loadType } = this.state

    let visionField = "";

    if (editType === "vision") {
      visionField = (
        <Fragment>
          <td className="active-edit pr-0">
            <textarea
              autoFocus
              className="form-control"
              type="text"
              placeholder="Our Vision"
              name="vision"
              rows="5"
              value={vision}
              onChange={(e) => this.setState({ vision: e.target.value })}
              autoComplete="off"
            />
            {this.props.permissions.canEdit && (
                <div className="mt-1">
                  <Button className="btn btn-nrml" variant="primary" onClick={() => this.submitVision()}>
                      {loading && loadType === "vision"
                        ? (
                          <Fragment>
                            <>{this.getLabelText("saving")}...</>
                          <FontAwesomeIcon className="ml-2 fa-spin" icon={faSpinner}/>
                        </Fragment>
                      )
                      : (
                        <Fragment>
                          <>{this.getLabelText("save")}</>
                        <FontAwesomeIcon className="ml-2" icon={faCheck} />
                      </Fragment>
                    )
                  }
                  </Button>
                  <Button className="btn ml-1 btn-nrmlr" variant="danger" onClick={() => this.toggleEditVision()}>{this.getLabelText("cancel")} <FontAwesomeIcon icon={faBan}></FontAwesomeIcon></Button>
                </div>

            )}
          </td>
        </Fragment>
      )
    } else {
      visionField = (
        <Fragment>
          <td className="pr-0">
            <Row xs={12} className="pr-0">
              <Col xs={6} className="pr-0">
                <Row>
                  <Col xs={10} className="pr-0">
                    <p>{vision !== "" ? vision : this.getLabelText('no_data')}</p>
                  </Col>
                  <Col xs={2} className="pr-0">
                    {this.props.permissions.canEdit && this.state.selectedPeriod !== this.state.prevPeriod ? (
                      <Button className={"btn-icons-container" + (this.state.editType !== "" || this.state.addValue || this.state.addGoal ? " disabled" : "")} variant="primary" onClick={() => this.toggleEditVision()}><img src={Edit} alt="Edit" className="btn-icons"/></Button>
                    ) : null}
                  </Col>
                </Row>
              </Col>
              <Col xs={6} className="pr-0">
                <table className={"w-100"}>
                  <tbody>
                    <tr className="pr-0">
                      {/*<td valign="top">*/}
                      {/*  <p className="cdi-txt-lbl"><b>Business First Week:</b></p>*/}
                      {/*  {this.props.permissions.canEdit && this.state.startWeeks.filter(week => week.period === this.state.selectedPeriod).length < 1 && (*/}
                      {/*    <Button className={"btn btn-nrml" + (this.state.editType !== "" || this.state.addValue || this.state.addGoal ? " disabled" : "")} variant="primary" onClick={() => this.toggleAddStartWeek()}>Add <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon></Button>*/}
                      {/*  )}*/}
                      {/*</td>*/}
                      <td>
                        {this.renderStartWeeks()}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </td>
        </Fragment>
      )
    }

    return visionField
  }

  renderCompanyValues() {
    return (
      <Row className="pr-0">
        <Col className="pr-0">
          <Table striped bordered hover className="standard-tbl standard-value-tbl pr-0">
            <thead>
              <tr>
                <th width="30%">{this.getLabelText("value")}</th>
                <th width="45%">{this.getLabelText("definition")}</th>
                {this.props.permissions.canEdit && (<th width="25%"></th>)}
              </tr>
            </thead>
            <tbody>
              {this.state.addValue
                ? this.renderAddValue()
                : null
              }
              {this.state.companyValues.map((companyValue, i, arr) => (
                this.renderCompanyValueRow(companyValue, i, arr.length)
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    )
  }


  renderAddValue() {
    const { loading, loadType } = this.state
    return (
      <tr key="0">
        <td>
          <input
            className="form-control"
            autoFocus
            name="value"
            value={this.state.value}
            onChange={(e) => this.setState({ value: e.target.value })}
            autoComplete="off"
          ></input>
        </td>
        <td>
          <input
            className="form-control"
            name="definition"
            value={this.state.definition}
            onChange={(e) => this.setState({ definition: e.target.value })}
            autoComplete="off"
          ></input>
        </td>
        <td className="text-right">
          <OverlayTrigger
            placement="top"
            delay={{ show: 25, hide: 40 }}
            overlay={(props) => renderTooltip({...props}, this.getLabelText('save'))}
          >
            <Button className="btn-icons-container" variant="primary" disabled={this.state.value.length === 0} onClick={() => this.submitValue()}>
              {loading && loadType === "value"
                ? (
                  <img src={Loader} alt="Loader" className="loader-spinner"/>
              )
              : (
                  <img src={Save} alt="Save" className="btn-icons"/>
            )
          }
        </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            delay={{ show: 25, hide: 40 }}
            overlay={(props) => renderTooltip({...props}, this.getLabelText('cancel'))}
          >
            <Button className="btn-icons-container" variant="danger" onClick={() => this.toggleAddValue()}>
              <img src={Cancel} alt="Cancel" className="btn-icons"/>
            </Button>
          </OverlayTrigger>
        </td>
      </tr>
    )
  }

  renderStartWeeks() {
    const { startWeeks, selectedPeriod } = this.state;

    const currentStartWeek = startWeeks.find(week => week.period === selectedPeriod);

    return (
      <Row>
        <Col className="pr-0">
          <Table striped bordered hover className="standard-tbl standard-value-tbl pr-0">
            <thead>
              <tr className="pr-0">
                {this.props.permissions.canEdit && (<th width="40%">{this.getLabelText('business_first_week')}:
                  {this.props.permissions.canEdit && this.state.startWeeks.filter(week => week.period === this.state.selectedPeriod).length < 1 && this.state.selectedPeriod !== this.state.prevPeriod ? (
                      <Button className={"btn btn-nrml" + (this.state.editType !== "" || this.state.addValue || this.state.addGoal ? " disabled" : "")} variant="primary" onClick={() => this.toggleAddStartWeek()}>{this.getLabelText('add')} <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon></Button>
                  ) : null}</th>)}
                <th width="60%">{this.getLabelText('start_date')}</th>
              </tr>
            </thead>
            <tbody>
              {this.state.addStartWeek
                ? this.renderAddStartWeek()
                : null
              }
              {this.renderStartWeekRow(currentStartWeek)}
            </tbody>
          </Table>
        </Col>
      </Row>
    )
  }

  handleDateChange = date => {
    this.setState({
      weekDate: date
    })
  }


  renderAddStartWeek() {
    const { loading, loadType } = this.state
    return (
      <tr key="0">
        <td className="text-left">
          <OverlayTrigger
            placement="top"
            delay={{ show: 25, hide: 40 }}
            overlay={(props) => renderTooltip({...props}, this.getLabelText('save'))}
          >
            <Button className="btn-icons-container" variant="primary" disabled={!this.state.weekDate} onClick={() => this.submitStartWeek()}>
              {loading && loadType === "startWeek"
                ? (
                  <img src={Loader} alt="Loader" className="loader-spinner"/>
              )
              : (
                  <img src={Save} alt="Save" className="btn-icons"/>
            )
          }
        </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            delay={{ show: 25, hide: 40 }}
            overlay={(props) => renderTooltip({...props}, this.getLabelText('cancel'))}
          >
            <Button className="btn-icons-container" variant="danger" onClick={() => this.toggleAddStartWeek()}>
              <img src={Cancel} alt="Cancel" className="btn-icons"/>
            </Button>
          </OverlayTrigger>
        </td>
        <td>
          <DatePicker
            name="date"
            className="form-control w-100"
            required={true}
            dateFormat="dd/MM/yyyy"
            selected={this.state.weekDate ? this.state.weekDate : null}
            onChange={this.handleDateChange}
            tabIndex="0"
            autoComplete="off"
          />
        </td>
      </tr>
    )
  }

  handleChange = date => {

    this.setState({
      dueDate: new Date(date.getFullYear(), date.getMonth() + 1, 0)
    })
  }

  getFormattedDateString = dueDate => {
    const date = new Date(dueDate)
    const { months }  = this.state
    return months.filter(month => {
      return month.index === date.getMonth()
    })[0].name + " " + date.getFullYear()
  }

  formattedStartWeekDate(date) {
    let month = date.getMonth() + 1
    let day = date.getDate()

    if(month.toString().length < 2)
      month = "0" + month.toString()

    if(day.toString().length < 2)
      day = "0" + day.toString()

    return day + "/" + month + "/" + date.getFullYear()
  }

  renderGoalRow = (goal, index) => {
    const { measurement, editType, editId, loading, loadingId, loadType, deleteId, addGoal, category, goalCategories, unit, units, fixedUnit, selectedPeriod, period, prevPeriod } = this.state
    if (editType === "goal" && editId === goal.id) {
      let selectedUnit = {label: unit, value: unit}
      const unitOptions = units.map(({id, name}) => {
        if(id === unit) {
          selectedUnit = { value: id, label: name }
        }

        return { value: id, label: name }
      })

      let selectedMeasurement = {label: measurement, value: measurement}
      const goalCat = goalCategories.find(goal => goal.id === category)
      const measurementOptions = goalCat.measurements.map((measure) => {
        if(measure.id === measurement) {
          selectedMeasurement = { value: measure.id, label: measure.measurement }
        }
        return { value: measure.id, label: measure.measurement }
      })
      return (
        <tr key={`${goal.id}_${index}`}>
          <td className="text-dark">
            <select
              className="form-control"
              onChange={(e) => this.setState({ category: e.target.value })}
              disabled={addGoal}
              defaultValue={category}
              >
                {goalCategories.map(({id, name}, index) => {
                  return <option key={index} value={id}>{name}</option>
                })}
            </select>
          </td>
          <td>
            <CreatableSelect
              className="font-weight-normal"
              onChange={({ value }) => this.handleCreatbleChange(value, goalCat)}
              options={measurementOptions}
              value={selectedMeasurement}

            />
          <small>{this.getLabelText("create_new_measurement_true_north")}</small>
          </td>
          <td>
            <CreatableSelect
              className={"font-weight-normal" + (fixedUnit ? " disabled" : "")}
              onChange={({ value }) => this.setState({ unit: value })}
              options={unitOptions}
              value={selectedUnit}
            />
            <small>{this.getLabelText("create_new_unit_true_north")}</small>
          </td>
          <td>
            <input
              className="form-control"
              required={true}
              name="numberFrom"
              type="number"
              step="0.01"
              value={this.state.numberFrom}
              onChange={(e) => this.setState({ numberFrom: e.target.value })}
              autoComplete="off"
            ></input>
          </td>
          <td>
            <input
              className="form-control"
              required={true}
              name="numberTo"
              type="number"
              step="0.01"
              value={this.state.numberTo}
              onChange={(e) => this.setState({ numberTo: e.target.value })}
              autoComplete="off"
            ></input>
          </td>
          <td>
            <FiscalYearDateSelector
              action={this.handleChange}
              startMonth={this.state.startMonth}
              selectedValue={this.state.dueDate}
              selectedPeriod={this.state.selectedPeriod}
              period={this.state.period}
              nextPeriod={this.state.nextPeriod}
              prevPeriod={this.state.prevPeriod}
              />
          </td>
          <td className="text-right">
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, this.getLabelText('save'))}
            >
              <Button className="btn-icons-container" variant="primary" type="submit">
                {loading && loadType === "goal"
                  ? (
                    <img src={Loader} alt="Loader" className="loader-spinner"/>
                )
                : (
                  <img src={Save} alt="Save" className="btn-icons"/>

              )
            }
          </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, this.getLabelText('cancel'))}
            >
              <Button className="btn-icons-container" variant="primary" onClick={() => this.clearGoal()}><img src={Cancel} alt="Cancel" className="btn-icons"/></Button>
            </OverlayTrigger>
          </td>
        </tr>
      )
    } else {
      return (
        <tr key={`${goal.id}_${index}`}>
          <td>{goal.goalCategory.name}</td>
          <td>{goal.measurement.measurement}</td>
          <td>{goal.measurement.unit.name}</td>
          <td>{goal.numberFrom}</td>
          <td>{goal.numberTo}</td>
          <td>{this.getFormattedDateString(goal.dueDate)}</td>
          {this.props.permissions.canEdit && (
            <td className="text-right">
              {goal.period !== this.state.nextPeriod ? (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 25, hide: 40 }}
                  overlay={(props) => renderTooltip({...props}, this.getLabelText('export'))}
                >
                  <Button className={"btn-icons-container" + (this.state.editType !== "" || !goal.allMetricsCSV || (goal.allMetricsCSV && !goal.allMetricsCSV.length) ? " disabled" : "")} variant="primary" onClick={() => this.downloadCSV(goal)}>
                    <img src={Export} alt="Export" className="btn-icons"/>
                  </Button>
                </OverlayTrigger>
              ) : null}
              {goal.period !== this.state.prevPeriod ? (
                  <OverlayTrigger
                      placement="top"
                      delay={{ show: 25, hide: 40 }}
                      overlay={(props) => renderTooltip({...props}, this.getLabelText('edit'))}
                  >
                    <button type="button" className={"btn-icons-container" + (this.state.editType !== "" || this.state.addValue || this.state.addGoal ? " disabled" : "")} variant="primary" onClick={() => this.toggleEditGoal(goal)}>
                      <img src={Edit} alt="Edit" className="btn-icons"/>
                    </button>
                  </OverlayTrigger>
              ) : null}
              {goal.period !== this.state.prevPeriod ? (
                  <OverlayTrigger
                      placement="top"
                      delay={{ show: 25, hide: 40 }}
                      overlay={(props) => renderTooltip({...props}, this.getLabelText('delete'))}
                  >
                    <Button className={"btn-icons-container" + (this.state.editType !== "" || this.state.addValue || this.state.addGoal ? " disabled" : "")} variant="primary" onClick={() => this.deleteGoalItem(goal.id)}>
                      {loading && loadType === "delGoal" && deleteId === goal.id
                          ? (
                              <img src={Loader} alt="Loader" className="loader-spinner"/>
                          )
                          : (
                              <img src={Delete} alt="Delete" className="btn-icons"/>
                          )
                      }
                    </Button>
                  </OverlayTrigger>
              ) : null}
              {selectedPeriod === period && (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 25, hide: 40 }}
                  overlay={(props) => renderTooltip({...props}, this.getLabelText('post_to_next'))}
                >
                  <Button className={"btn-icons-container" + (loading && loadType === "postNext" && loadingId === goal.id ? " disabled" : "")} variant="primary" onClick={() => this.postToNext(goal)}>
                    {loading && loadType === "postNext" && loadingId === goal.id
                      ? (
                        <img src={Loader} alt="Loader" className="loader-spinner"/>
                      )
                      : (
                        <img src={PostNextYear} alt="PostNextYear" className="btn-icons"/>
                      )
                    }
                  </Button>
                </OverlayTrigger>
              )}
              {selectedPeriod === prevPeriod && (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 25, hide: 40 }}
                  overlay={(props) => renderTooltip({...props}, this.getLabelText('post_to_current'))}
                >
                  <Button className={"btn-icons-container" + (loading && loadType === "postNext" && loadingId === goal.id ? " disabled" : "")} variant="primary" onClick={() => this.postToCurrent(goal)}>
                    {loading && loadType === "postNext" && loadingId === goal.id
                      ? (
                        <img src={Loader} alt="Loader" className="loader-spinner"/>
                      )
                      : (
                        <img src={PostNextYear} alt="PostNextYear" className="btn-icons"/>
                      )
                    }
                  </Button>
                </OverlayTrigger>
              )}
            </td>
          )}
        </tr>
      )
    }
  }

  handleCreatbleChange = (value, category) => {
    let options = {
      measurement: value
    }

    const measurement = category.measurements.find(measurement => measurement.id === value)

    if(typeof measurement === "undefined") {
      options = {
        ...options,
        fixedUnit: false,
        unit: ""
      }
    } else {
      options = {
        ...options,
        fixedUnit: true,
        unit: measurement.unit.id
      }
    }

    this.setState(options)

  }


  renderAddGoal = () => {
    const { measurement, loading, loadType, category, unit, goalCategories, units, addGoal, editId, fixedUnit } = this.state
    let selectedUnit = {label: unit, value: unit}
    const unitOptions = units.map(({id, name}) => {
      if(id === unit) {
        selectedUnit = { value: id, label: name }
      }

      return { value: id, label: name }
    })

    let selectedMeasurement = {label: measurement, value: measurement}
    const goalCat = goalCategories.find(goal => goal.id === category)
    const measurementOptions = goalCat.measurements.map((measure) => {
      if(measure.id === measurement) {
        selectedMeasurement = { value: measure.id, label: measure.measurement }
      }
      return { value: measure.id, label: measure.measurement }
    })

    return (
      <tr key={`${addGoal}_${editId}`}>
        <td className="text-dark">
          <select
            className="form-control"
            onChange={(e) => this.setState({ category: e.target.value })}
            disabled={addGoal}
            defaultValue={category}
            >
              {goalCategories.map(({id, name}, index) => {
                return <option key={index} value={id}>{name}</option>
              })}
          </select>
        </td>
        <td>
          <CreatableSelect
            className="font-weight-normal"
            onChange={({ value }) => this.handleCreatbleChange(value, goalCat)}
            options={measurementOptions}
            value={selectedMeasurement}

          />
        <small>{this.getLabelText("create_new_measurement_true_north")}</small>
        </td>
        <td>
          <CreatableSelect
            className={"font-weight-normal" + (fixedUnit ? " disabled" : "")}
            onChange={({ value }) => this.setState({ unit: value })}
            options={unitOptions}
            value={selectedUnit}
          />
          <small>{this.getLabelText("create_new_unit_true_north")}</small>
        </td>
        <td>
          <input
            className="form-control"
            required={true}
            name="numberFrom"
            type="number"
            step="0.01"
            value={this.state.numberFrom}
            onChange={(e) => this.setState({ numberFrom: e.target.value })}
            autoComplete="off"
          ></input>
        </td>
        <td>
          <input
            className="form-control"
            required={true}
            name="numberTo"
            type="number"
            step="0.01"
            value={this.state.numberTo}
            onChange={(e) => this.setState({ numberTo: e.target.value })}
            autoComplete="off"
          ></input>
        </td>
        <td>
          <FiscalYearDateSelector
            action={this.handleChange}
            startMonth={this.state.startMonth}
            selectedValue={this.state.dueDate}
            selectedPeriod={this.state.selectedPeriod}
            period={this.state.period}
            nextPeriod={this.state.nextPeriod}
            prevPeriod={this.state.prevPeriod}
            />
        </td>
        <td className="text-right">
          <OverlayTrigger
            placement="top"
            delay={{ show: 25, hide: 40 }}
            overlay={(props) => renderTooltip({...props}, this.getLabelText('save'))}
          >
            <Button className="btn-icons-container" variant="primary" type="submit">
              {loading && loadType === "goal"
                ? (
                  <img src={Loader} alt="Loader" className="loader-spinner"/>
              )
              : (
                  <img src={Save} alt="Save" className="btn-icons"/>
            )
          }
        </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            delay={{ show: 25, hide: 40 }}
            overlay={(props) => renderTooltip({...props}, this.getLabelText('cancel'))}
          >
            <Button className="btn-icons-container" variant="primary" onClick={() => this.clearGoal()}><img src={Cancel} alt="Cancel" className="btn-icons"/></Button>
          </OverlayTrigger>
        </td>
      </tr>
    )
  }

  validateGoal = () => {
    const { category, measurement, unit, dueDate } = this.state
    return category.length > 0 && measurement.length > 0 && unit.length > 0 && dueDate.toISOString().length > 0
  }

  submitGoal = async (e) => {
    e.preventDefault()
    this.setState({
      loading: true,
      loadType: "goal"
    })
    const { category, goalCategories, measurement, unit, units, numberFrom, numberTo, dueDate, editId, selectedPeriod } = this.state
    if (this.validateGoal()) {
      const isNewUnit = units.every(({ id }) => id !== unit);
      const goalCat = goalCategories.find(goal => goal.id === category)
      const isNewMeasurement = goalCat.measurements.every(({ id }) => id !== measurement);
      const isNewCategory = goalCategories.every(({ id }) => id !== category);

      const options = { isNewUnit, isNewCategory, isNewMeasurement };
      try {
        if (editId !== 0) {
          await updateGoal({
            id: editId,
            goalCategoryId: category,
            measurement,
            measurementId: measurement,
            unit,
            dueDate: dueDate.toISOString(),
            numberFrom: parseFloat(numberFrom),
            numberTo: parseFloat(numberTo),
            options,
          });
        } else {
          await createGoal(
            category,
            measurement,
            unit,
            dueDate.toISOString(),
            parseFloat(numberFrom),
            parseFloat(numberTo),
            selectedPeriod,
            options
          );
        }
        if (options.isNewUnit) this.fetchUnits()
        if (options.isNewCategory) this.fetchGoalCategories()
        await this.fetchGoals()
        this.clearGoal()
        this.props.updatePermissions("hasBusinessGoals", true)
      } catch (error) {
        displayErrorToast(error)
      }
    }

    this.setState({
      loading: false,
      loadType: ""
    })
  }

  validateValue = () => {
    const { value } = this.state
    return value.length > 0
  }

  getWeekNumber = date => {
    var d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    var dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    return Math.ceil((((d - yearStart) / 86400000) + 1)/7);
  }


  submitStartWeek = async () => {
    this.setState({
      loading: true,
      loadType: "startWeek"
    })
    const { weekDate, editId, selectedPeriod } = this.state;

    const weekNumber = this.getWeekNumber(weekDate)
    try {
      if(editId !== 0) {
        await updateCompanyStartWeek(editId, weekNumber, weekDate.toISOString())
      } else {
        await createCompanyStartWeek(`${selectedPeriod}`, weekNumber, weekDate.toISOString())
      }
      await this.fetchStartWeeks()
      this.toggleAddStartWeek()
    } catch (error) {
      error.graphQLErrors.map(error => {
        toast.error(error.message)

        return true
      })
    }

    this.setState({
      loading: false,
      loadType: ""
    })
  }



  submitValue = async () => {
    this.setState({
      loading: true,
      loadType: "value"
    })
    const { value, definition, editId } = this.state
    if (this.validateValue()) {
      try {
        if(editId !== 0) {
          await updateCompanyValue(editId, value, definition)
        } else {
          await createCompanyValue(value, definition)
        }
        await this.fetchCompanyValues()
        this.toggleAddValue()
        this.props.updatePermissions("hasCompanyValues", true)
      } catch (error) {
        error.graphQLErrors.map(error => {
          toast.error(error.message)

          return true
        })
      }
    }

    this.setState({
      loading: false,
      loadType: ""
    })
  }

  submitValuesOrder = debounce(async () => {
    const { companyValues } = this.state
    await updateCompanyValuesOrder(companyValues.map(({ id }) => id)).catch(displayErrorToast)
  }, 1000)

  submitCategoriesOrder = debounce(async () => {
    const { goalCategories } = this.state
    await updateGoalCategoriesOrder(goalCategories.map(({ id }) => id)).catch(displayErrorToast)
  }, 1000)

  submitVision = async () => {
    const { vision, companyId, companyName } = this.state
    this.setState({
      loading: true,
      loadType: "vision"
    })
    if (vision !== "") {
      try {
        await updateVision(companyId, vision, companyName)
        this.setState({
          editType: "",
          prevVision: ""
        })
        this.props.toggleDirtyState()
      } catch (error) {
        error.graphQLErrors.map(error => {
          if(error) {
            toast.error(error.message)
          }

          return true
        })
      }
    }
    this.setState({
      loading: false,
      loadType: ""
    })
  }

  postToCurrent = async measurement => {
    const { period } = this.state
    this.setState({
      loadingId: measurement.id,
      loadType: "postNext",
      loading: true
    })
    const isNewUnit = false
    const isNewMeasurement = false
    const isNewCategory = false
    const options = { isNewUnit, isNewCategory, isNewMeasurement };
    if (window.confirm(this.getText('post_to_current_prompt'))) {
      try {
        await createGoal(
          measurement.goalCategory.id,
          measurement.measurement.id,
          measurement.measurement.unit.id,
          new Date(new Date(measurement.dueDate).getFullYear(), new Date(measurement.dueDate).getMonth(), new Date(measurement.dueDate).getDate()).toISOString(),
          parseFloat(measurement.numberFrom),
          parseFloat(measurement.numberTo),
          period,
          options
        );

        await this.fetchGoals()
      } catch (err) {
        displayErrorToast(err)
      }
    }
    this.setState({
      deleteId: 0,
      loadType: "",
      loading: false
    })
  }

  postToNext = async measurement => {
    const { nextPeriod } = this.state
    this.setState({
      loadingId: measurement.id,
      loadType: "postNext",
      loading: true
    })
    const isNewUnit = false
    const isNewMeasurement = false
    const isNewCategory = false
    const options = { isNewUnit, isNewCategory, isNewMeasurement };
    if (window.confirm(this.getText('post_to_next_prompt'))) {
      try {
        await createGoal(
          measurement.goalCategory.id,
          measurement.measurement.id,
          measurement.measurement.unit.id,
          new Date(new Date(measurement.dueDate).getFullYear() + 1, new Date(measurement.dueDate).getMonth(), new Date(measurement.dueDate).getDate()).toISOString(),
          parseFloat(measurement.numberFrom),
          parseFloat(measurement.numberTo),
          nextPeriod,
          options
        );

        await this.fetchGoals()
      } catch (err) {
        displayErrorToast(err)
      }
    }
    this.setState({
      deleteId: 0,
      loadType: "",
      loading: false
    })
  }

  deleteGoalItem = async (id) => {
    this.setState({
      loading: true,
      loadType: "delGoal",
      deleteId: id
    })
    if (window.confirm(this.getText('delete_department_prompt'))) {
      try {
        await deleteGoal(id)
        this.fetchGoals()
      } catch (error) {
        displayErrorToast(error)
      }
    }

    this.setState({
      loading: false,
      loadType: "",
      deleteId: 0
    })
  }

  deleteCompanyValueItem = async (id) => {
    this.setState({
      loading: true,
      loadType: "delValue",
      deleteId: id
    })
    if (window.confirm(this.getText('delete_department_prompt'))) {
      try {
        await deleteCompanyValue(id)
        this.fetchCompanyValues()
      } catch (error) {
        displayErrorToast(error)
      }
    }
    this.setState({
      loading: false,
      loadType: "",
      deleteId: 0
    })
  }

  renderGoals() {
    return (
      <Row className="goal-row pr-0">
        <Col className="goal-row pr-0">
          <Table className="goal-row pr-0" bordered>
            <tbody className="goal-row pr-0">
              {this.state.goalCategories.length > 0 ? this.state.goalCategories.map((category, i, { length }) => (
                  <tr key={category.id}>
                    <td>
                      <div className="w-100 mb-3 table-sub-heading">
                        <h5 className="d-inline">{category.name}</h5>
                        {this.props.permissions.canEdit && (
                          <div className="d-inline" style={{ float: "right" }}>
                            <div className="ml-2 d-inline">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 25, hide: 40 }}
                                overlay={(props) => renderTooltip({...props}, this.getLabelText('up'))}
                              >
                                <Button
                                  className={"btn-icons-container" + (this.state.editType !== "" || this.state.addValue || this.state.addGoal ? " disabled" : "")}
                                  disabled={i === 0}
                                  onClick={() =>
                                    this.handleChangeCategoriesOrder("dec", i)
                                  }
                                  >
                                  <img src={MoveUp} alt="MoveUp" className="btn-icons"/>
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 25, hide: 40 }}
                                overlay={(props) => renderTooltip({...props}, this.getLabelText('down'))}
                              >
                                <Button
                                  className={"btn-icons-container" + (this.state.editType !== "" || this.state.addValue || this.state.addGoal ? " disabled" : "")}
                                  disabled={i === length - 1}
                                  onClick={() =>
                                    this.handleChangeCategoriesOrder("inc", i)
                                  }
                                  >
                                  <img src={MoveDown} alt="MoveDown" className="btn-icons"/>
                                </Button>
                              </OverlayTrigger>
                            </div>
                            {this.state.selectedPeriod !== this.state.prevPeriod ? (
                                <div className="d-inline">
                                  <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 25, hide: 40 }}
                                      overlay={(props) => renderTooltip({...props}, "Add")}
                                  >
                                    <Button
                                        className={"btn-icons-container" + (this.state.editType !== "" || this.state.addValue || this.state.addGoal ? " disabled" : "")}
                                        variant="primary"
                                        onClick={() =>
                                            this.toggleAddGoal(category.id)
                                        }
                                    >
                                      <img src={Add} alt="Add" className="btn-icons"/>
                                    </Button>
                                  </OverlayTrigger>
                                </div>
                            ) : null}
                          </div>
                        )}
                      </div>
                      {(this.state.addGoal && this.state.category === category.id) || this.state.goals.some(
                        (goal) => goal.goalCategory.id === category.id
                      ) ? (
                        <form onSubmit={(e) => this.submitGoal(e)}>
                          <Table striped bordered hover className="standardl-tbl standard-goal-tbl">
                            <thead>
                              <tr>
                                <th width="17%">{this.getLabelText("category")}</th>
                                <th width="18%">{this.getLabelText("measurement")}</th>
                                <th width="15%">{this.getLabelText("units")}</th>
                                <th width="10%">{this.getLabelText("from")}</th>
                                <th width="10%">{this.getLabelText("to")}</th>
                                <th width="15%">{this.getLabelText("by")}</th>
                                {this.props.permissions.canEdit && (<th width="15%"></th>)}
                              </tr>
                            </thead>
                            <tbody>
                              {(this.state.addGoal && this.state.category === category.id) ? this.renderAddGoal({ category }) : null}
                              {this.state.goals
                                .filter(
                                  ({ goalCategory, period }) =>
                                    goalCategory.id === category.id && period === this.state.selectedPeriod
                                )
                                .map((goalCategory, index) => this.renderGoalRow(goalCategory, index))}
                            </tbody>
                          </Table>
                        </form>
                      ) : (
                        !this.state.isFetchingData && (
                          <div><b className="cdi-blue-txt">{this.getLabelText("no_data_true_north")}</b></div>
                        )
                      )}
                    </td>
                  </tr>
              )) : null}
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <Fragment>
        <SubHeaderComponent>
          <div className={"w-100 text-right"}>
            <ButtonGroup toggle className="ml-1">
              <ToggleButton
                type="radio"
                variant="secondary"
                name="createType"
                value="0"
                checked={this.state.selectedPeriod === this.state.prevPeriod}
                onChange={() => this.setPeriod(this.state.prevPeriod)}
              >
                {this.getLabelText("previous")}
              </ToggleButton>
              <ToggleButton
                type="radio"
                variant="secondary"
                name="createType"
                value="0"
                checked={this.state.selectedPeriod === this.state.period}
                onChange={() => this.setPeriod(this.state.period)}
              >
                {this.getLabelText("current")}
              </ToggleButton>
              <ToggleButton
                type="radio"
                variant="secondary"
                name="createType"
                value="1"
                checked={this.state.selectedPeriod === this.state.nextPeriod}
                onChange={() => this.setPeriod(this.state.nextPeriod)}
              >
                {this.getLabelText("next")}
              </ToggleButton>
            </ButtonGroup>
          </div>
        </SubHeaderComponent>
        <div className="grey-header-space container-left">
          <Row className="justify-content-center pr-0">
            <Col className="pr-0">
              <table className="true-north-table table-heading-background user-text pr-0" width="100%">
                <tbody className="pr-0">
                <tr className="vision-row pr-0">
                  <td valign="top">
                    <p className="cdi-txt-lbl"><b>{this.getLabelText("our_vision")}:</b></p>
                  </td>
                  {this.renderVisionField()}
                </tr>
                <tr className="value-row pr-0">
                  <td valign="top" className="pr-0">
                    <p className="cdi-txt-lbl"><b>{this.getLabelText("our_values")}:</b></p>
                    {this.props.permissions.canEdit && this.state.selectedPeriod !== this.state.prevPeriod ? (

                      <Button className={"btn btn-nrml" + (this.state.editType !== "" || this.state.addValue || this.state.addGoal ? " disabled" : "")} variant="primary" onClick={() => this.toggleAddValue()}>{this.getLabelText('add')} <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon></Button>
                    ) : null}
                  </td>
                  <td className="pr-0">
                    {this.renderCompanyValues()}
                  </td>
                </tr>
                <tr className="goal-row pr-0">
                </tr>
                <tr className="goal-row pr-0">
                  <td valign="top">
                    <p className="cdi-txt-lbl"><b>{this.getLabelText("business_goals")}:</b></p>
                  </td>
                  <td className="pr-0">
                    {this.renderGoals()}
                  </td>
                </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </div>
      </Fragment>
    )

  }
}

const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam,
  user: state.userReducer.user
})

const mapDispatchToProps = {
  updatePermissions
};

export default connect(mapStateToProps, mapDispatchToProps)(GoalsScreen);
