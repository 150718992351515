import { client } from "./BaseService";
import { gql } from "apollo-boost";

export const createUserLog = async(teamId, userId, standInId, goalId, periodKey) => {
  return await client.mutate({
    mutation: gql`mutation {
      createIndividualResultAssignedUserLog(
        teamId: "${teamId}",
        userId: "${userId}",
        standInId: "${standInId}",
        goalId: "${goalId}",
        periodKey: "${periodKey}"
      )
      {
        id
        periodKey
        goal {
          id
        }
        user {
          id
        }  
        standIn {
          id
        }      
      }
    }`
  })
}

export const updateUserLog = async(id, userId, standInId) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateIndividualResultAssignedUserLog(
        id: "${id}",
        userId: "${userId}"
        standInId: "${standInId}"
      )
      {
        id
        periodKey
        goal {
          id
        }
        user {
          id
        }         
        standIn {
          id
        }        
      }
    }`
  })
}
