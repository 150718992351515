import React, { useState, useCallback, useEffect, Fragment } from "react";
import {Row, Col, Button } from "react-bootstrap"
import {useMutation, useLazyQuery} from "@apollo/react-hooks";
import {
  SCHEDULED_EVENTS_QUERY,
  CREATE_EVENT_MUTATION,
  UPDATE_EVENT_MUTATION,
  DELETE_EVENT_MUTATION,
  COLOURS_QUERY,
  ACTION_SHARED_EVENT
} from "./resources/gql";
import { connect } from 'react-redux';
import Calendar from "./Calendar"
import Modal from 'react-modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import displayErrorToast from "../../components/utils/displayErrorToast";
import SubHeaderComponent from "../../components/SubHeaderComponent";
import RealtimeRefreshComponent from "../../components/RealtimeRefreshComponent";
import { mapEventExportData} from "./resources/mapEventExportData"   ;
import {
  faSpinner,
  faPlus,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import LabelComponent from "../../components/utils/getCompanyLabel";
import * as DefaultLabels from '../../assets/glossary.json';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { CREATE_ACTION_MUTATION, CREATE_ISSUE_MUTATION } from "../ProjectTracker/resources/gql";
import { Link } from "react-router-dom";
import Colours from "./Colours";
import NextStep from "../../components/shared/NextStep";
const labels = DefaultLabels.default;
const workSheetName = 'Worksheet-1';
const columns = [
  { header: 'Event Date', key: 'start' },
  { header: 'Event Title', key: 'title' }
];
const getLabelText = key => <LabelComponent val={key}/>

const ScheduledEventsScreen = ({ selectedTeam, togglePageLoad, refresh, hasNewData, toggleRefresh, toggleHasNewData, user }) => {
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [loadingType, setLoadingType] = useState("")
  const [events, setEvents] = useState([])
  const [date, setDate] = useState(new Date())
  const [title, setTitle] = useState("")
  const [colour, setColour] = useState(null);
  const [eventIssueStatus, setEventIssueStatus] = useState();
  const [eventId, setEventId] = useState(null)
  const [editable, setEditable] = useState(true);
  const [shared, setShared] = useState(false);
  const [sharedStatus, setSharedStatus] = useState(null);
  const [actionId, setActionId] = useState(null);
  const [actionArchived, setActionArchived] = useState(false);
  const [calenderSelectedMonth, setcalenderSelectedMonth] = useState(new Date().getMonth())
  const [calenderSelectedYear, setcalenderSelectedYear] = useState(new Date().getFullYear())
  const [colours, setColours] = useState([]);

  const getText = val => {
    const companyId = localStorage.getItem("companyId");
    let lang = "EN";
    let langLabels = labels.find(lbl => lbl.lang === lang);
    let def = langLabels.glossary.find(label => label.key === val);

    if(user && user.companies && user.companies.length) {
      const company = user.companies.find(company => company.id === companyId);
      if(company) {
        lang = company.language;
        langLabels = labels.find(lbl => lbl.lang === lang);
        def = langLabels.glossary.find(label => label.key === val);
        if(!def) {
          lang = "EN";
          langLabels = labels.find(lbl => lbl.lang === lang);
          def = langLabels.glossary.find(label => label.key === val);
        }
      }
      if(company && company.companyGlossaries) {
        const custom = company.companyGlossaries.find(label => label.key === val);

        if(custom) return custom.value
      }
    }

    if(!def) {
      console.log(val)
    }
    return def.value
  }

  const setLoading = useCallback((type) => {
    setIsLoading(true)
    setLoadingType(type)
  }, [setIsLoading, setLoadingType]);

  const stopLoading = () => {
    setIsLoading(false)
    setLoadingType("")
  }

  const [createEvent] = useMutation(CREATE_EVENT_MUTATION, {
    onCompleted: ({ createScheduledEvent }) => {
      if(createScheduledEvent.id)
      {
        loadEvents()
        stopLoading()
        setTitle("")
        setDate(new Date())
        setShowModal(false)
        setColour(null)
        setShared(false)
      }
    },
  });

  const saveEvent = useCallback(() => {
    setLoading("save")

    createEvent({
      variables: {
        team: selectedTeam.id,
        title: title,
        date: date,
        isShared: shared,
        colour: colour
      },
    });
  }, [createEvent, selectedTeam, setLoading, title, date, colour, shared]);

  const [updateEvent] = useMutation(UPDATE_EVENT_MUTATION, {
    onCompleted: ({ updateScheduledEvent }) => {
      if(updateScheduledEvent.id)
      {
        loadEvents()
        stopLoading()
        setTitle("")
        setDate(new Date())
        setEventId(null)
        setShowModal(false)
        setColour(null)
        setShared(false)
      }
    },
  });

  const editEvent = useCallback(() => {
    setLoading("save")

    updateEvent({
      variables: {
        id: eventId,
        title: title,
        date: date,
        colour: colour
      },
    });
  }, [updateEvent, eventId, setLoading, title, date, colour]);

  const onError = useCallback(
    (error) => {
      setShowModal(false);
      setLoading();
      displayErrorToast(error);
    },
    []
  );

  const [deleteEvent] = useMutation(DELETE_EVENT_MUTATION, {
    onError,
    onCompleted: () => {
      setEvents(events.filter(event => event.id !== eventId))
      stopLoading()
      setTitle("")
      setDate(new Date())
      setEventId(null)
      setShowModal(false)
      setColour(null);
      setShared(false)
    },
  });

  const removeEvent = useCallback(() => {
    setLoading("delete")
    
    deleteEvent({
      variables: {
        id: eventId,
        isShared: editable ? false : shared
      },
    });
  }, [deleteEvent, eventId, setLoading]);

  const [createIssue] = useMutation(CREATE_ISSUE_MUTATION, {
    onCompleted: ({ createIssue }) => {
      createAction({
        variables: {
          issueId: createIssue.id,
          actionType: "SCHEDULE",
          status: "NEXT_STEP",
          description: ""
        }
      })
    }
  });

  const [createAction] = useMutation(CREATE_ACTION_MUTATION, {
    onCompleted: (data) => {
      loadEvents()
    }
  });

  const ns = useCallback(() => {
    setLoading("nextSteps")

    createIssue({
      variables: {
        creatorId: selectedTeam.id,
        whatText: title,
        reviewDate: date,
        type: "SCHEDULE_ISSUE",
        scheduleId: editable ? eventId : null,
        sharedScheduleId: !editable ? eventId : null
      }
    });
  }, [createIssue, eventId, date, title, selectedTeam.id, setLoading]);

  const computeData = useCallback(
    (data) => {
      if (!data) return;
      setEvents(data.getScheduledEvents);
      setIsLoading(false);
      togglePageLoad(false)
      if (refresh) {
        toggleRefresh()
        toggleHasNewData()
        togglePageLoad(false)
      }
      setShowModal(false);
    },
    [refresh, setEvents, setIsLoading, togglePageLoad, toggleRefresh, toggleHasNewData]
  );

  const [loadEvents] = useLazyQuery(SCHEDULED_EVENTS_QUERY, {
    variables: { id: selectedTeam.id },
    onCompleted: computeData,
    onError: displayErrorToast,
  });

  const [getColours] = useLazyQuery(COLOURS_QUERY, {
    onCompleted: useCallback((data) => {
      setColours(data.colours);
    }, [])
  });

  useEffect(() => {
    togglePageLoad(true)
    if (!selectedTeam || selectedTeam.id === 0) return
    getColours()
    loadEvents()
  }, [selectedTeam, loadEvents, getColours, togglePageLoad])

  const handleDate = date => {
    if (!date || date === "") return false;

    setDate(date)
  }

  const valid = () => {
    return title !== "" && date
  }

  const [actionSharedEvent] = useMutation(ACTION_SHARED_EVENT, {
    onError,
    onCompleted: () => {
      loadEvents()
      stopLoading()
      closeModal()
    },
  });

  const actionShared = (val) => {
    actionSharedEvent({
      variables: {
        id: eventId,
        status: val === true ? "ACCEPTED" : "REJECTED"
      }
    })
  }

  const edit = id => {
    const event = events.find(event => event.id === id)

    if (!event) return;

    setTitle(event.title)
    setDate(new Date(event.date))
    setEventId(id)
    setEventIssueStatus(event.status);
    setColour(event.colour.id);
    setEditable(event.editable);
    setShared(event.isShared);
    setSharedStatus(event.sharedStatus);
    setActionId(event.actionId);
    setActionArchived(event.actionArchived);
    setShowModal(true)
  }

  const selectDate = event => {
    let selectedDate = new Date(event);
    setcalenderSelectedMonth(selectedDate.getMonth())
    setcalenderSelectedYear(selectedDate.getFullYear())
  }

  const save = () => {
    if (eventId) editEvent()
    else saveEvent()
  }

  const del = () => {
    if (window.confirm(getText("delete_department_prompt"))) {
      removeEvent()
    }
  }

  const closeModal = () => {
    setTitle("")
    setDate(new Date())
    setEventId(null)
    setShowModal(false)
    setShared(false)
    setEditable(true)
    setColour(null)
    setSharedStatus(null)
    setActionId(null)
    setActionArchived(false)
  }

  const saveExcel = async () => {
    const workbook = new Excel.Workbook();
    try {
      let allScheduledEvents = mapEventExportData(events);
      let filteredEvents = [];
      let teamName;
      if (selectedTeam.id !== 0) {
        teamName = selectedTeam.name;
        filteredEvents = allScheduledEvents.filter(e => e.start.getMonth() === calenderSelectedMonth 
            && e.start.getFullYear() === calenderSelectedYear);
      }
      const fileName = teamName + "-Scheduled Events";
      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(workSheetName);

      // add worksheet columns
      // each columns contains header and its mapping key from data
      worksheet.columns = columns;

      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true };

      // loop through all of the columns and set the alignment with width.
      worksheet.columns.forEach(column => {
        column.width = column.header.length + 15;
        column.alignment = { horizontal: 'left' };
      });

      filteredEvents.sort((a, b) => {
        return (a.start - b.start)
      })
      filteredEvents.forEach(singleEvent => {
        worksheet.addRow(singleEvent);
      });

      // loop through all of the rows and set the outline style.
      worksheet.eachRow({ includeEmpty: false }, row => {
        // store each cell to currentCell
        const currentCell = row._cells;

        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach(singleCell => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;

          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        });
      });

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error('Error saving file - ', error.message);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
    }
  };

  return (
    <Fragment>
      <SubHeaderComponent>
        <Col className="text-right childenSpace">
          <div className={"refreshBtn"}>
            <RealtimeRefreshComponent
              refresh={refresh}
              hasNewData={hasNewData}
              toggleRefresh={toggleRefresh}
            />
          </div>
          <Button className="ml-1" varian="primary" onClick={() => setShowModal(true)}>{getLabelText("add")}<FontAwesomeIcon icon={faPlus} className="ml-2"></FontAwesomeIcon></Button>
          <Button className="ml-1" onClick={(e) => saveExcel()}>Export</Button>
        </Col>
      </SubHeaderComponent>
      <div className="grey-header-space container-left-plus6">
        <Row className="width justify-content-center">
          <Col xs={10}>
            <Row className="mt-2 mb-5">
              <Col>
                <Calendar
                    events={events}
                    edit={edit}
                    selectDate ={selectDate}
                />
              </Col>
            </Row>
          </Col>
          <Modal
              isOpen={showModal}
              onRequestClose={() => closeModal()}
              contentLabel="Add Scheduled Event"
              // style={customStyles}
              className="standard-modal standard-modal-scheduled-events"
          >
            <Row>
              <Col className={eventId ? "text-left" : "text-center"}>
                <label className="cdi-blue-txt ml-0 mb-2">{eventId ? getLabelText("edit_scheduled_event") : getLabelText("add_scheduled_event")}</label>
              </Col>
              {eventId && sharedStatus !== 'INIT' && (
                  <div className="d-flex justify-content-end">
                    <NextStep
                        loading={isLoading}
                        loadingType={loadingType}
                        loadingId={eventId}
                        editType={null}
                        issue={{id: eventId}}
                        action={{id: actionId, archived: actionArchived}}
                        actionId={null}
                        status={(eventIssueStatus === null || eventIssueStatus === 'NONE' || eventIssueStatus === undefined) ? 'NONE' : (eventIssueStatus === 'NEXT_STEP' || eventIssueStatus === 'TRIGGERED') ? 'NEXT_STEP' : 'COMPLETED'}
                        nextSteps={() => ns()}
                        loadingClassName="mr-2 mt-2 chk chk-white"
                    />
                    <Button variant="primary" className="btn-minimal more-padding"  onClick={() => del()}>
                      {loadingType === "delete" && isLoading
                          ? (
                              <FontAwesomeIcon className="color-blue mr-1 fa-spin" icon={faSpinner} />
                          ) : (
                              <FontAwesomeIcon className="color-blue mr-1" icon={faTrash} />
                          )
                      }
                    </Button>
                  </div>
              )}
            </Row>
            <Row className="my-2">
              <Col xs={3}>
                <label className="cdi-blue-txt ml-2">{getLabelText("date")}:</label>
              </Col>
              <Col xs={9} className="datepicker-100">
                <DatePicker
                    name="date"
                    className="form-control w-100"
                    required={true}
                    dateFormat="yyyy/MM/dd"
                    showYearDropdown
                    selected={date}
                    onChange={handleDate}
                    inline={false}
                    autoComplete="off"
                    disabled={!editable}
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xs={3}>
                <label className="cdi-blue-txt ml-2">{getLabelText("title")}:</label>
              </Col>
              <Col xs={9} className="w-100">
                <input
                    type="text"
                    name="title"
                    className="form-control w-100"
                    value={title}
                    disabled={!editable}
                    onChange={(e) => setTitle(e.target.value)}
                    autoComplete="off"
                />
              </Col>
            </Row>
            {
              (shared || !eventId) && (
                <Row className="mb-2">
                  <Col xs={3}>
                    <label className="cdi-blue-txt ml-2">{getLabelText("shared")}:</label>
                  </Col>
                  <Col xs={9} className="w-100 pl-3">
                    <div className="form-check form-switch">
                      <input
                          className="form-check-input"
                          type="checkbox"
                          style={{ height: "20px", width: "20px" }}
                          checked={shared}
                          disabled={eventId !== null}
                          onChange={() => {
                            setShared(!shared)
                          }}
                      />
                    </div>
                  </Col>
                </Row>
              )
            }
            {
              editable && (
                  <Row className="mb-2">
                    <Col xs={3}>
                      <label className="cdi-blue-txt ml-2">{getLabelText("colour")}:</label>
                    </Col>
                    <Col xs={9} className="w-100">
                      <Colours colours={colours} selectedColor={colour} setColour={setColour} />
                    </Col>
                  </Row>
              )
            }
            {
              sharedStatus !== 'INIT' && (
                  <Row className="mb-4 mt-4 width">
                    <Col xs={6} className="text-right">
                      <Button variant="primary" className={"btn-scheduled"} disabled={!valid || !editable || sharedStatus !== null} onClick={() => save()}>
                        {(isLoading && loadingType === "save")
                            ? (
                                <><FontAwesomeIcon icon={faSpinner} className="fa-spin ml-2"></FontAwesomeIcon></>
                            ) : (
                                <>{getLabelText("save")}</>
                            )
                        }
                      </Button>
                    </Col>
                    <Col xs={6} className="text-left">
                      <Button variant="default" className="btn-scheduled cdi-blue-outline color-blue" onClick={() => closeModal()}>{getLabelText("cancel")}</Button>
                    </Col>
                  </Row>
                )
            }
            {
              sharedStatus === 'INIT' && (
                  <Row className="mb-4 mt-4 width">
                    <Col xs={6} className="text-right">
                      <Button variant="primary" className={"btn-scheduled"} onClick={() => actionShared(true)}>
                        {getLabelText("accepted")}
                      </Button>
                    </Col>
                    <Col xs={6} className="text-left">
                      <Button variant="danger" className={"btn-scheduled"} onClick={() => actionShared(false)}>
                        {getLabelText("rejected")}
                      </Button>
                    </Col>
                  </Row>
                )
            }
          </Modal>
        </Row>
      </div>
    </Fragment>
  )
}
const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam,
  user: state.userReducer.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledEventsScreen);
