import { client } from "./BaseService";
import { gql } from "apollo-boost";

export const getConsequences = async (teamId, valueId) => {
  return await client.query({
    query: gql`query{
      consequences(teamId: "${teamId}", valueId: "${valueId}") {
        id
        description
        value {
          id
          value
        }
        team {
          id
          name
        }
      }
    }`
  })
}

export const getTeamConsequences = async (teamId) => {
  return await client.query({
    query: gql`query{
      teamConsequences(teamId: "${teamId}") {
        id
        description
        value {
          id
          value
        }
        team {
          id
          name
        }
      }
    }`
  })
}

export const createConsequence = async(teamId, valueId, description) => {
  return await client.mutate({
    mutation: gql`mutation {
      createConsequence(
        teamId: "${teamId}",
        valueId: "${valueId}",
        description: "${description}"
      )
      {
        id
      }
    }`
  })
}

export const updateConsequence = async(id, description) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateConsequence(
        id: "${id}",
        description: "${description}"
      )
      {
        id
      }
    }`
  })
}

export const deleteConsequence = async(id) => {
  return await client.mutate({
    mutation: gql`mutation {
      deleteConsequence(id: "${id}")
      {
        id
      }
    }`
  })
}
