import { client } from "./BaseService";
import { gql } from "apollo-boost";
import toGqlStrArray from "../components/utils/toGqlStrArray";

const createTeam = async (name, purpose, leaderIds, memberIds, coachId, departmentId, logo, meetingMemberIds = null) => {
  if(meetingMemberIds) {
    return await client.mutate({
      mutation: gql`mutation {
        createTeam(
          name: "${name}",
          purpose: "${purpose}",
          leaderIds: ${toGqlStrArray(leaderIds)},
          memberIds: ${toGqlStrArray(memberIds)},
          meetingMemberIds: ${toGqlStrArray(meetingMemberIds)},
          coachId: "${coachId}",
          departmentId: "${departmentId}",
          logo: "${logo}"
        ) {
          id
          name
          department {
            id
            name
          }
        }
      } `
    })
  } else {
    return await client.mutate({
      mutation: gql`mutation {
        createTeam(
          name: "${name}",
          purpose: "${purpose}",
          leaderIds: ${toGqlStrArray(leaderIds)},
          memberIds: ${toGqlStrArray(memberIds)},
          coachId: "${coachId}",
          departmentId: "${departmentId}",
          logo: "${logo}"
        ) {
          id
          name
          department {
            id
            name
          }
        }
      } `
    })
  }
}

const updateTeam = async (id, name, purpose, leaderIds, memberIds, coachId, departmentId, logo, meetingMemberIds = null) => {
  if(meetingMemberIds) {
    return await client.mutate({
      mutation: gql`mutation {
        updateTeam(
          id: "${id}",
          name: "${name}",
          purpose: "${purpose}",
          leaderIds: ${toGqlStrArray(leaderIds)},
          memberIds: ${toGqlStrArray(memberIds)},
          meetingMemberIds: ${toGqlStrArray(meetingMemberIds)},
          coachId: "${coachId}",
          departmentId: "${departmentId}",
          logo: "${logo}"
        ) {
          id
          name
          department {
            id
            name
          }
        }
      } `
    })
  } else {
    return await client.mutate({
      mutation: gql`mutation {
        updateTeam(
          id: "${id}",
          name: "${name}",
          purpose: "${purpose}",
          leaderIds: ${toGqlStrArray(leaderIds)},
          memberIds: ${toGqlStrArray(memberIds)},
          coachId: "${coachId}",
          departmentId: "${departmentId}",
          logo: "${logo}"
        ) {
          id
          name
          department {
            id
            name
          }
        }
      } `
    })
  }
}

const getTeams = async () => {
  return await client.query({
    query: gql`query{
      teams {
        id
        purpose
        name
        coach{
          id
          firstNames
          lastName
          nickName
        }
        leaders {
          id
          firstNames
          nickName
          lastName
        }
        members {
          id
          firstNames
          nickName
          lastName
        }
        meetingMembers {
          id
        }
        department {
          id
          name
          departmentType {
            name
          }
        }
        partners {
          id
          name
          departmentType {
            name
          }
        }
        customers {
          id
          name
          departmentType {
            name
          }
        }
      }
    }`
  })
}

const getAllTeamsList = async () => {
  return await client.query({
    query: gql`query{
      allTeams {
        id
        name
        department {
          id
          name
        }
      }
    }`
  })
}

const getAllTeamsSummary = async () => {
  return await client.query({
    query: gql`query{
      allTeams {
        id
        name
        department {
          id
          name
        }
        partners {
          id
          name
          type
        }
      }
    }`
  })
}

const getAssignedTeamUsers = async (id) => {
  return await client.query({
    query: gql`query{
      team(id: "${id}") {
        id
        name
        combinedMembers {
          id
          firstNames
          nickName
          lastName
          avatar
        }
        assignedUserLogs {
          id
          url
          user {
            id
          }
          standIn {
            id
          }
        }
      }
    }
  `})
}

const getAllTeams = async () => {
  return await client.query({
    query: gql`query{
      allTeams {
        id
        purpose
        name
        hasTeamGoals
        hasCustomers
        hasSuppliers
        hasIndividualGoals
        hasValuePartners
        hasTaskTracker
        hasImplementationModule
        company {
          color
          finalcialYearEnd
          vision
        }
        logo
        coach{
          id
          firstNames
          lastName
          nickName
          avatar
        }
        leaders {
          id
          firstNames
          nickName
          lastName
          avatar
        }
        members {
          id
          firstNames
          nickName
          lastName
          avatar
        }
        meetingMembers {
          id
        }
        combinedMembers {
          id
          firstNames
          nickName
          lastName
          avatar
        }
        department {
          id
          name
          teamLength
          departmentType {
            id
            name
            level
          }
        }
        partners {
          id
          name
          type
          departmentType {
            name
          }
        }
        customers {
          id
          name
          type
          departmentType {
            name
          }
        }
        assignedUserLogs {
          id
          url
          user {
            id
          }
          standIn {
            id
          }
        }
        teamGoalAssignedUserLogs {
          id
          periodKey
          user {
            id
          }
          standIn {
            id
          }
          teamMeasurement {
            id
          }
        }
        businessResultAssignedUserLogs {
          id
          periodKey
          user {
            id
          }
          standIn {
            id
          }
          goal {
            id
          }
        }
        individualResultAssignedUserLogs {
          id
          periodKey
          user {
            id
          }
          standIn {
            id
          }
          goal {
            id
          }
        }
      }
    }`
  })
}


const getTeam = async (id) => {
  return await client.query({
    query: gql`query{
      team(id: "${id}") {
        id
        purpose
        name
        hasTeamGoals
        hasCustomers
        hasSuppliers
        hasIndividualGoals
        hasValuePartners
        hasTaskTracker
        company {
          color
          finalcialYearEnd
          vision
        }
        logo
        coach{
          id
          firstNames
          lastName
          nickName
          avatar
        }
        leaders {
          id
          firstNames
          nickName
          lastName
          avatar
        }
        members {
          id
          firstNames
          nickName
          lastName
          avatar
        }
        meetingMembers {
          id
        }
        combinedMembers {
          id
          firstNames
          nickName
          lastName
          avatar
        }
        department {
          id
          name
          teamLength
          departmentType {
            id
            name
            level
          }
        }
        partners {
          id
          name
          type
          departmentType {
            name
          }
        }
        customers {
          id
          name
          type
          departmentType {
            name
          }
        }
        assignedUserLogs {
          id
          url
          user {
            id
          }
          standIn {
            id
          }
        }
        teamGoalAssignedUserLogs {
          id
          periodKey
          user {
            id
          }
          standIn {
            id
          }
          teamMeasurement {
            id
          }
        }
        businessResultAssignedUserLogs {
          id
          periodKey
          user {
            id
          }
          standIn {
            id
          }
          goal {
            id
          }
        }
        individualResultAssignedUserLogs {
          id
          periodKey
          user {
            id
          }
          standIn {
            id
          }
          goal {
            id
          }
        }
      }
    }`
  })
}

const deleteTeam = async (id) => {
  return await client.mutate({
    mutation: gql`mutation {
      deleteTeam(id: "${id}") {
        name
      }
    } `
  })
}

const createMemberAvatar = async (userId, teamId, url) => {
  return await client.mutate({
    mutation: gql`mutation {
      createTeamMemberAvatar(
        userId: "${userId}"
        teamId: "${teamId}"
        avatar: "${url}"
      ) {
        id
      }
    }`
  })
}

const updateMemberAvatar = async (id, url) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateTeamMemberAvatar(
        id: "${id}"
        avatar: "${url}"
      ) {
        id
      }
    }`
  })
}

const getValuePartners = async (id) => {
  return await client.query({
    query: gql`query{
      team(id: "${id}") {
        id
        valuePartners {
          id
          name
          department {
            name
          }
        }
      }
    }`
  })
}

const linkValuePartner = async (id, teamIds) => {
  return await client.mutate({
    mutation: gql`mutation linkValuePartner(
      $id: ID!
      $teamIds: [String]!
    ) {
      linkValuePartner(
        id: $id
        teamIds: $teamIds
      ) {
        id
        valuePartners {
          id
          name
          department {
            name
          }
        }
      }
    }`,
    variables: {
      id,
      teamIds
    }
  })
}

const getTeamSuccessForExport = async (teamId, period) => {
  return await client.query({
    query: gql`query {
      teamSuccessesForExport (teamId: "${teamId}", period: "${period}") {
        id
      user {
        id
        firstNames
        lastName
        avatar
      }
      recognition{
        id
        name
      }
      period
      month
      }
    }`
  })
}

export {
  getTeam,
  getTeams,
  createTeam,
  updateTeam,
  deleteTeam,
  createMemberAvatar,
  updateMemberAvatar,
  getValuePartners,
  linkValuePartner,
  getAllTeams,
  getAllTeamsList,
  getAllTeamsSummary,
  getAssignedTeamUsers,
  getTeamSuccessForExport
}
