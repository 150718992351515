import React, { useEffect, useState } from "react";
import { FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-modal';
import { getDateFromWeek, getWeekNumber } from "../resources/utils";
import DatePicker from "react-datepicker"
import Delete from "../../../assets/ButtonIcons/Delete Blue.svg";
import IconButton from "../../../components/shared/IconButton";

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '30%'
  }
};

const ImplementationTrackerModal = ({ object: { name, id, from, to, user, fromDate, toDate, order, implementationModule }, save, cancel, showModal, type, loading, setLoading, users, getText, modules }) => {
  const [value, setValue] = useState(name ? name : "")
  const [start, setStart] = useState(from ? from : 1)
  const [end, setEnd] = useState(to ? to : 1)
  const [startDate, setStartDate] = useState(fromDate ? fromDate : getDateFromWeek(start))
  const [endDate, setEndDate] = useState(toDate ? toDate : getDateFromWeek(end))
  const [index, setIndex] = useState(order ? order : 1)
  const [selectedUser, setSelectedUser] = useState(user ? user.id : users.length ? users[0].id : null)
  const [selectedModule, setSelectedModule] = useState(implementationModule ?? "")
  const [disableName, setDisableName] = useState(false)
  const [selectedModuleMilestones, setSelectedModuleMilestones] = useState([]);

  useEffect(() => {
    setValue(name)
    setStart(from)
    setEnd(to)
    setStartDate(fromDate ? fromDate : getDateFromWeek(from))
    setEndDate(toDate ? toDate : getDateFromWeek(to))
    setIndex(order)
    setSelectedModule(implementationModule)
    setDisableName(false)
    if(user) setSelectedUser(user.id)
    else if(users.length) setSelectedUser(users[0].id)

  }, [name, from, to, fromDate, toDate, order, user, users, setValue, setStart, setEnd, setSelectedUser, setStartDate, setEndDate, setIndex, setSelectedModule, implementationModule])

  const saveData = async() => {
    setLoading(true)
    save(type === "Milestone" ? {
      name: value,
      from: start,
      to: end,
      userId: selectedUser,
      fromDate: startDate,
      toDate: endDate,
      order: index,
      implementationModule: selectedModule,
      milestones: selectedModuleMilestones
    } : {
      name: value,
      from: start,
      to: end,
      fromDate: startDate,
      toDate: endDate,
      order: index,
      implementationModule: selectedModule,
      milestones: selectedModuleMilestones
    })
  }

  const setDateValues = (value, type) => {
    switch(type) {
      case "from":
        setStart(getWeekNumber(value))
        setStartDate(new Date(value))

        if(new Date(value) > endDate)
        {
          setEndDate(new Date(value))
          setEnd(getWeekNumber(value))
        }
        break;
      default:
        setEnd(getWeekNumber(value))
        setEndDate(value)

        if(new Date(value) < startDate)
        {
          setStartDate(new Date(value))
          setStart(getWeekNumber(value))
        }
        break;
    }
  }

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => cancel()}
      contentLabel=""
      style={{content: {...customStyles.content, width: selectedModule ? "60%" : "30%"}}}
    >
      <div className="row">
        <div className="col-12 text-center">
          <label className="cdi-blue-txt ml-0 mb-2">{!id ? getText("add") : getText("edit")}</label>
        </div>
      </div>
      {(!id && type === 'ImplementationModule') && (
        <div className="row mb-2">
          <div className="col-12">
            <label className="cdi-blue-txt ml-2">{getText("implementation_module")}:</label>
          </div>
          <div className="col-12">
            <select className="form-control cdi-ddl w-100 ml-0"
                name="implementationModule"
                value={selectedModule}
                onChange={(e) => {
                  setSelectedModule(e.target.value);
                  let selectedModules = modules.find(module => module.id === e.target.value).milestones;
                  selectedModules.forEach(m => {
                    m.owner = selectedUser
                    m.fromDate = fromDate
                    m.from = from
                    m.toDate = toDate
                    m.to = to
                  })
                  setSelectedModuleMilestones(selectedModules)
                  setValue(modules.find(m => m.id === e.target.value).name);
                  setDisableName(e.target.value !== "")
                }}
                autoComplete="off"
            >
              <option value="">{getText("select_implementation_module")}</option>
              {modules.map((module, i) => {
                return <option value={module.id} key={i}>{module.name}</option>
              })}
            </select>
          </div>
        </div>
      )}
      {selectedModule && (
          <div className="row my-2">
            <div className="col-4 font-weight-bold">{getText("milestone")}</div>
            <div className="col-3 font-weight-bold">{getText("owner")}</div>
            <div className="col-2 font-weight-bold">{getText("from")}</div>
            <div className="col-2 font-weight-bold">{getText("to")}</div>
          </div>
      )}
      {selectedModule && selectedModuleMilestones?.map(milestone => (
          <div key={milestone.id}>
            <div className="row mb-2">
              <div className="col-4">
                <div className="col-12">{milestone.name}</div>
              </div>
              <div className="col-3">
                <select className="form-control cdi-ddl w-100 ml-0" name="user" value={milestone.owner} onChange={(e) => {
                  let stones = selectedModuleMilestones
                  const index = selectedModuleMilestones.findIndex(m => m.id === milestone.id)
                  stones[index] = {...milestone, owner: e.target.value}
                  setSelectedModuleMilestones([...stones])
                }}>
                  {users.map((user, i) => {
                    return <option value={user.id} key={i}>{user.firstNames} {user.lastName}</option>
                  })}
                </select>
              </div>
              <div className="col-2 datepicker-100">
                <DatePicker
                    name="date"
                    className="form-control"
                    required={true}
                    dateFormat="dd/MM/yyyy"
                    selected={milestone.fromDate}
                    onChange={(value) => {
                      let stones = selectedModuleMilestones
                      const index = selectedModuleMilestones.findIndex(m => m.id === milestone.id)

                      let flag = false;
                      if(new Date(value) > stones[index].toDate) flag = true;

                      stones[index] = {...milestone, fromDate: new Date(value), from: getWeekNumber(value), toDate: flag ? new Date(value) : stones[index].toDate, to: flag ? getWeekNumber(value) : stones[index].to}
                      setSelectedModuleMilestones([...stones])
                    }}
                    inline={false}
                    autoComplete="off"
                />
              </div>
              <div className="col-2 datepicker-100">
                <DatePicker
                    name="date"
                    className="form-control"
                    required={true}
                    dateFormat="dd/MM/yyyy"
                    selected={milestone.toDate}
                    onChange={(value) => {
                      let stones = selectedModuleMilestones
                      const index = selectedModuleMilestones.findIndex(m => m.id === milestone.id)

                      let flag = false
                      if(new Date(value) < stones[index].fromDate) flag = true;

                      stones[index] = {...milestone, toDate: new Date(value), to: getWeekNumber(new Date(value))}
                      stones[index] = {...milestone, toDate: new Date(value), to: getWeekNumber(value), fromDate: flag ? new Date(value) : stones[index].fromDate, from: flag ? getWeekNumber(value) : stones[index].from}
                      setSelectedModuleMilestones([...stones])
                    }}
                    inline={false}
                    autoComplete="off"
                />
              </div>
              <div className="col-1 d-flex justify-content-end">
                <IconButton eventHandler={() => {
                    let stones = selectedModuleMilestones
                    stones.splice(selectedModuleMilestones.findIndex(m => m.id === milestone.id), 1)
                    setSelectedModuleMilestones([...stones])
                  }
                }>
                  <img src={Delete} alt="Delete" className="btn-icons"/>
                </IconButton>
              </div>
            </div>
          </div>
      ))}
      {(type === 'ImplementationModule' && id) || (type !== 'ImplementationModule') && (
        <div className="row mb-2">
          <div className="col-12">
            <label className="cdi-blue-txt ml-2">{getText("milestone")}:</label>
          </div>
          <div className="col-12">
            <FormControl
                value={value}
                onChange={(e) => setValue(e.target.value)}
                autoComplete="off"
                disabled={disableName || (id && type !== 'Milestone')}
            ></FormControl>
          </div>
        </div>
      )}
      {type === "Milestone" && (
        <div className="row mb-2">
          <div className="col-12">
            <label className="cdi-blue-txt ml-2">{getText("owner")}:</label>
          </div>
          <div className="col-12">
            <select className="form-control cdi-ddl w-100 ml-0"
              name="user"
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
              autoComplete="off"
            >
              <option value="1" disabled>{getText("select_member")}</option>
              {users.map((user, i) => {
                return <option value={user.id} key={i}>{user.firstNames} {user.lastName}</option>
              })}
            </select>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-6">
          <div className="row">
            <div className="col-12">
              <label className="cdi-blue-txt ml-2">{getText("from")}:</label>
            </div>
            <div className="col-12 datepicker-100">
              <DatePicker
                name="date"
                className="form-control"
                required={true}
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                onChange={(value) => setDateValues(value, "from")}
                inline={false}
                autoComplete="off"
              />
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="row">
            <div className="col-12">
              <label className="cdi-blue-txt ml-2">{getText("to")}:</label>
            </div>
            <div className="col-12 datepicker-100">
              <DatePicker
                name="date"
                className="form-control"
                required={true}
                dateFormat="dd/MM/yyyy"
                selected={endDate}
                onChange={(value) => setDateValues(value, "end")}
                inline={false}
                autoComplete="off"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-6">
          <button className="btn btn-primary w-100" disabled={loading || !value} onClick={() => saveData()}>
            {loading
              ? (
                <>{getText("saving")} <FontAwesomeIcon icon={faSpinner} className="fa-spin ml-2"></FontAwesomeIcon></>
              ) : (
                <>{getText("save")}</>
              )
            }
          </button>
        </div>
        <div className="col-6">
          <button className="btn btn-default cdi-blue-outline color-blue w-100" onClick={() => cancel()}>{getText("cancel")}</button>
        </div>
      </div>
    </Modal>
  )
}

export default ImplementationTrackerModal
