import * as teamTypes from '../types/TeamTypes';

const initialState = {
  isLoading: false,
  teams: [],
  selectedTeam: {
    id: 0,
    name: '',
    leaders: [],
    members: [],
    coach: {
    },
    combinedMembers: [],
    department: {
      name: '',
      teamLength: 1,
      departmentType: {
        name: "",
        level: "DEP"
      }
    },
    company: {
      color: "Blue",
      finalcialYearEnd: "January",
    },
    assignedUserLogs:[],
    teamGoalAssignedUserLogs: [],
    businessResultAssignedUserLogs: [],
    individualResultAssignedUserLogs: [],
    hasTeamGoals: false,
    hasCustomers: false,
    hasSuppliers: false,
    hasIndividualGoals: false,
    hasValuePartners: false,
  },
};

const teamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case teamTypes.FETCH_TEAMS_STARTED:
      return { ...state, error: null, isLoading: true };
    case teamTypes.FETCH_TEAMS_SUCCESS:
      return { ...state, ...action.payload, error: null, isLoading: false };
    case teamTypes.FETCH_TEAMS_FAILED:
      return { ...state, error: action.payload.error, isLoading: false };
    case teamTypes.SELECT_TEAM:
      localStorage.setItem("teamId", action.payload.selectedTeam.id)
      return { ...state, ...action.payload, error: null };
    case teamTypes.UPDATE_PAGE_PERMISSIONS:
    return { ...state, selectedTeam: {
      ...state.selectedTeam,
      [action.payload.name]: action.payload.value
    } }
    default:
      return state;
  }
};

export default teamsReducer;
