import { client } from "./BaseService";
import { gql } from "apollo-boost";
import * as authenticationActions from '../actions/AuthenticationActions';
import displayErrorToast from "../../components/utils/displayErrorToast";

export const ME_QUERY = gql`
query {
  me {
    id
      firstNames
      nickName
    teams {
      id
      purpose
      name
      coach {
        id
        firstNames
        lastName
        nickName
      }
      leaders {
        id
        firstNames
        nickName
        lastName
      }
      members {
        id
        firstNames
        nickName
        lastName
      }
      department {
        id
        name
        departmentType {
          name
        }
      }
      partners {
        id
        name
        departmentType {
          name
        }
      }
      customers {
        id
        name
        departmentType {
          name
        }
      }
    }
  }
}
`;

export const loadMe = async () => {
  return await client.query({
    query: ME_QUERY,
  });
};

export const login = (email, password) => async (dispatch) => {
  dispatch(authenticationActions.authenticationStarted());
  try {
    const response = await client.mutate({
      mutation: gql`mutation {
        login(email: "${email}", password: "${password}") {
          token
          companyId
          user {
            id
            firstNames
            nickName
            role
            hasPassword
            teams {
              id
              name
              department {
                name
              }
            }
          }
        }
      }`,
    });

    if (response.data.login && !response.data.login.user.hasPassword){
      dispatch(authenticationActions.authenticationSuccess(response.data.login));
    }
    else {
      if(response.data.login && response.data.login.token && response.data.login.companyId) {
        localStorage.setItem('token', response.data.login.token);
        localStorage.setItem('companyId', response.data.login.companyId)
    
        dispatch(authenticationActions.authenticationSuccess(response.data.login));
      }
      else 
      {
        displayErrorToast({message: "Ops! something went wrong"});
        dispatch(authenticationActions.authenticationFailed(null));
      }
    }
  } catch (error) {
    displayErrorToast(error);

    dispatch(authenticationActions.authenticationFailed(error));
  }
};

export const changeCompany = (companyId) => async (dispatch) => {
  try {
    const response = await client.mutate({
      mutation: gql`mutation {
        changeCompany(companyId: "${companyId}") {
          token
          companyId
          user {
            id
            firstNames
            nickName
            role
            teams {
              id
              name
              department {
                name
              }
            }
          }
        }
      }`,
    });
    localStorage.setItem('token', response.data.changeCompany.token);
    localStorage.setItem('companyId', response.data.changeCompany.companyId)

    dispatch(authenticationActions.authenticationSuccess(response.data.changeCompany));
  } catch (error) {
    displayErrorToast(error);

    dispatch(authenticationActions.authenticationFailed(error));
  }
};

export const setPassword = (id, password, confirmPassword) => async (dispatch) => {
  dispatch(authenticationActions.authenticationStarted());
  try {
    const response = await client.mutate({
      mutation: gql`mutation {
        setPassword(id: "${id}", password: "${password}", confirmPassword: "${confirmPassword}") {
          token
          companyId
          user {
            id
            firstNames
            nickName
            role
            hasPassword
            teams {
              id
              name
              department {
                name
              }
            }
          }
        }
      }`,
    });

    localStorage.setItem('token', response.data.setPassword.token);
    localStorage.setItem('companyId', response.data.setPassword.companyId)

    dispatch(authenticationActions.authenticationSuccess(response.data.setPassword));
  } catch (error) {
    displayErrorToast(error);

    dispatch(authenticationActions.authenticationFailed(error));
  }
};
