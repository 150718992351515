import React, { Fragment, useState } from "react";
import { Row, Col, Accordion, Card, Button, useAccordionToggle } from "react-bootstrap";
import IssueComponent from "./IssueComponent";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import LabelComponent from "../../components/utils/getCompanyLabel";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// icons
import Add from '../../assets/ButtonIcons/Add Blue.svg';
import Cancel from '../../assets/ButtonIcons/Cancel Blue.svg';
import Collapse from '../../assets/ButtonIcons/Collapse Blue.svg';
import Edit from '../../assets/ButtonIcons/Edit Blue.svg';
import Expand from '../../assets/ButtonIcons/Expand Blue.svg';
import Loader from '../../assets/ButtonIcons/Loader Blue.svg';
import Save from '../../assets/ButtonIcons/Save Blue.svg';
import Lock from '../../assets/ButtonIcons/Lock Blue.svg';
import Unlock from '../../assets/ButtonIcons/Unlock Blue.svg';
import View from '../../assets/ButtonIcons/View Blue.svg';
import Upload from '../../assets/ButtonIcons/Upload Blue.svg';

const getLabelText = key => <LabelComponent val={key}/>

function formattedDate(date) {
  let month = date.getMonth() + 1
  let day = date.getDate()

  if (month.toString().length < 2)
    month = "0" + month.toString()

  if (day.toString().length < 2)
    day = "0" + day.toString()

  return day + "/" + month + "/" + date.getFullYear()
}

function IssueToggle({ children, eventKey, handleClick }) {

  const toggle = useAccordionToggle(eventKey, () => {
    handleClick();
  });
  return (
    <button className="btn-icons-container" type="submit" onClick={toggle}>
      {children}
    </button>
  );
}

const getEntityLabel = entity => {
  const label = entity.teams && entity.teams.length ? `${entity.name} (${entity.teams[0].name})` : `${entity.name} (External)`;
  return <span><b>{label}</b></span>
}

const EntityAccordion = (props) => {
  const [activeKey, setActiveKey] = useState(0);

  function AddIssue({entityId, eventKey}) {
    const toggle = useAccordionToggle(eventKey, (e) => {
      if(eventKey !== activeKey) {
        setActiveKey(eventKey)
        props.addProblem(e, entityId)
      }
    })

    return (
      <button className={"btn-icons-container" + (props.editable === 1 || props.archived ? " disabled" : "")} type="submit" onClick={(e) => toggle(e)}>
        <img src={Add} alt="Add" className="btn-icons"/>
      </button>
    )

  }

  return (
    <Accordion>
      {props.entities.map((entity, key) => {
        return (
          <Card key={key + 1}>
            <Card.Header>
              <Row>
                <Col xs={4} className="pt-1">
                  <label className="customer-supplier-label text-left mr-3">{props.type === "customer" ? getLabelText("customer") : getLabelText("supplier")} {key + 1}:</label>
                  {getEntityLabel(entity)}
                </Col>
                <Col xs={2}>
                  {!props.archived && (
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td valign="middle" width="20%" className="pt-1">
                            <label className="customer-supplier-label text-left">{getLabelText("status")}:</label>
                          </td>
                          <td valign="middle" className="text-left">
                            <span
                              className={"status chk chk-" + (entity.teamRelationshipStatuses.length === 0 || entity.teamRelationshipStatuses[0].status ? "blue color-selected" : "red") + (props.editable === 1 ? " disabled" : "")}
                              onClick={() => props.canEdit && props.toggleRelationshipStatus(entity)}
                            >
                              {props.loading && props.loadingId === entity.id && props.loadingType === "relationshipStatus"
                                ? <FontAwesomeIcon className="color-white ml-3 mt-2 mr-3 mb-2 fa-spin" icon={faSpinner}></FontAwesomeIcon>
                              : null
                              }
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </Col>
                <Col xs={4} className="pt-1">
                  {!props.archived && (
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td>
                            <label className="customer-supplier-label text-left">{getLabelText("next_review_date")}:</label>
                          </td>
                          <td>
                            {props.editReviewDate && props.editReviewDateId === entity.id
                              ? (
                                <DatePicker
                                  name="date"
                                  className="form-control"
                                  required={true}
                                  dateFormat="yyyy/MM/dd"
                                  showYearDropdown
                                  selected={props.reviewDate}
                                  onChange={props.reviewDateChange}
                                  tabIndex="0"
                                  inline={false}
                                  autoComplete="off"
                                />
                              ) : (
                                <p className={((entity.teamRelationshipStatuses.length < 1 || !entity.teamRelationshipStatuses[0].reviewDate) || new Date(entity.teamRelationshipStatuses[0].reviewDate) > new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()) ? "cdi-blue-txt" : "cdi-red-txt") + " text-right d-inline"}>{entity.teamRelationshipStatuses.length > 0 && entity.teamRelationshipStatuses[0].reviewDate ? formattedDate(new Date(entity.teamRelationshipStatuses[0].reviewDate)) : getLabelText("not_set")}</p>
                              )
                            }
                          </td>
                          <td>
                            {props.editReviewDate && props.editReviewDateId === entity.id
                              ? (
                                <Fragment>
                                  <button
                                    className="btn-icons-container"
                                    type="submit"
                                    disabled={props.archived}
                                    onClick={() => props.saveReviewDate(entity)}
                                  >
                                    {props.loading && props.loadingId === entity.id && props.loadingType === "reviewDate"
                                      ? (
                                        <img src={Loader} alt="Loader" className="loader-spinner"/>
                                      ) : (
                                        <img src={Save} alt="Save" className="btn-icons"/>
                                      )
                                    }
                                  </button>
                                  <button
                                    className={"btn-icons-container " + (props.loading && props.loadingId === entity.id && props.loadingType === "upload" ? "disabled" : "")}
                                    type="submit"
                                    disabled={props.archived}
                                    onClick={() => document.getElementById(`upload-agreement-${entity.id}`).click()}
                                  >
                                    {props.loading && props.loadingId === entity.id && props.loadingType === "upload"
                                      ? (
                                        <img src={Loader} alt="Loader" className="loader-spinner"/>
                                      ) : (
                                        <img src={Upload} alt="Upload" className="btn-icons"/>
                                      )
                                    }
                                  </button>
                                  <input type="file" className={"custom-file-input"} style={{ display: "none" }} id={`upload-agreement-${entity.id}`} onChange={(e) => props.uploadAgreement(e.target.files, entity.id)} accept="application/pdf"/>
                                  <button
                                    className="btn-icons-container"
                                    type="submit"
                                    disabled={props.archived}
                                    onClick={() => props.cancelReviewDate()}
                                  >
                                    <img src={Cancel} alt="Cancel" className="btn-icons"/>
                                  </button>
                                </Fragment>
                              ) : (
                                <Fragment>
                                  {
                                    props.canEdit && (
                                      <button
                                        className={"btn-icons-container" + (props.editable === 1 ? " disabled" : "")}
                                        type="submit"
                                        disabled={props.archived}
                                        onClick={() => props.editDate(entity)}
                                      >
                                        <img src={Edit} alt="Edit" className="btn-icons"/>
                                      </button>
                                    )
                                  }
                                  <button
                                    className={"btn-icons-container" + ((props.editable === 1 || (entity.teamRelationshipStatuses[0] && entity.shareRequested)) ? " disabled" : "")}
                                    type="submit"
                                    disabled={props.archived || !props.canEdit}
                                    onClick={() => props.canEdit && props.toggleLock(entity)}
                                  >
                                    {props.loading && props.loadingType === "toggleLock" && props.loadingId === entity.id
                                      ? (
                                        <img src={Loader} alt="Loader" className="loader-spinner"/>
                                      ) : entity.teamRelationshipStatuses[0] && (entity.teamRelationshipStatuses[0].shareOpen || entity.shareRequested)
                                      ? (
                                        <img src={Unlock} alt="Unlock" className="btn-icons"/>
                                      ) : (
                                        <img src={Lock} alt="Lock" className="btn-icons"/>
                                      )
                                    }
                                  </button>
                                  <a
                                    className={"btn-icons-container" + (props.editable === 1 || (!entity.teamRelationshipStatuses[0] || (entity.teamRelationshipStatuses[0] && !entity.teamRelationshipStatuses[0].agreement)) ? " disabled" : "")}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={entity.teamRelationshipStatuses[0] ? entity.teamRelationshipStatuses[0].agreement : null}
                                  >
                                    <img src={View} alt="View" className="btn-icons"/>
                                  </a>
                                </Fragment>
                              )
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </Col>
                <Col lg={2} xs={1} className="pt-1 text-right">
                  {!props.archived && (
                    <Fragment>
                      {
                        props.canEdit && (
                          <>
                            {key + 1 !== activeKey ? 
                              (
                                <AddIssue
                                  as={Button}
                                  eventKey={key + 1}
                                  entityId={entity.id}
                                  autoComplete="off"
                                  ></AddIssue>
                              ) : (
                                <button
                                  className={"btn-icons-container" + (props.editable === 1 ? " disabled" : "")}
                                  type="submit"
                                  disabled={props.archived}
                                  onClick={(e) => props.addProblem(e, entity.id)}
                                >
                                  <img src={Add} alt="Add" className="btn-icons"/>
                                </button>
                              )
                            }
                          </>
                        )
                      }
                    </Fragment>
                  )}
                  {entity.assignedIssues.length > 0 && entity.assignedIssues.filter(issue => issue.archived === props.archived).length > 0 && (
                    <IssueToggle
                      as={Button}
                      eventKey={key + 1}
                      autoComplete="off"
                      handleClick={() => {
                        if (activeKey === key + 1) {
                          setActiveKey(null);
                        } else {
                          setActiveKey(key + 1);
                        }
                      }}
                    >
                      {activeKey === key + 1
                        ? (
                          <img src={Collapse} alt="Collapse" className="btn-icons"/>
                        ) : (
                          <img src={Expand} alt="Expand" className="btn-icons"/>
                        )
                      }

                    </IssueToggle>
                  )}
                </Col>
              </Row>

            </Card.Header>
            <Accordion.Collapse eventKey={key + 1}>
              <div>
                {entity.assignedIssues.map((problem, i) => {
                  if (problem.archived === props.archived) {
                    return (
                      <table className="customer-supplier-tbl" key={i}>
                        <tbody>
                          <IssueComponent
                            problem={problem}
                            type={props.type}
                            problemId={props.problemId}
                            editable={props.editable}
                            archiveProblem={props.archiveProblem}
                            addProblem={props.addProblem}
                            handleChange={props.handleChange}
                            saveAction={props.saveAction}
                            clearEditableState={props.clearEditableState}
                            newSolution={props.newSolution}
                            newFix={props.newFix}
                            actions={props.actions}
                            saveProblem={props.saveProblem}
                            editProblem={props.editProblem}
                            feedback={props.feedback}
                            rootCause={props.rootCause}
                            date={props.date}
                            entityId={entity.id}
                            handleDateChange={props.handleDateChange}
                            editAction={props.editAction}
                            nextStep={props.nextStep}
                            loading={props.loading}
                            loadingType={props.loadingType}
                            loadingId={props.loadingId}
                            deleteProblem={props.deleteProblem}
                            postToImprovementIdeas={props.postToImprovementIdeas}
                            shareRequested={entity.shareRequested}
                            shareIssue={props.shareIssue}
                            teamId={props.teamId}
                            canEdit={props.canEdit}
                          />
                        </tbody>
                      </table>
                    )
                  }

                  return null
                })}
              </div>
            </Accordion.Collapse>
          </Card>
        )
      })}
    </Accordion>
  )
}

export default EntityAccordion;
