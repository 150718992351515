import React, { Component, Fragment } from "react";
import { Button, FormGroup, FormControl, FormLabel, Alert, Row, Col, Container } from "react-bootstrap";
import { createUser, getUser, deleteUser, updateUser } from "../../services/UserService";
import { Redirect } from "react-router-dom";
import { faCheck, faBan, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from 'react-toastify'
class InputUserScreen extends Component {
  state = {
    title: "Create User",
    edit: false,
    userId: 0,
    user: {
      email: "",
      firstNames: "",
      lastName: "",
      nickName: "",
      isAdmin: false,
      mobileNumber: "",
      password: ""
    },
    error: "",
    redirect: false
  }

  validateForm = () => {
    let valid = true
    const { edit, user } = this.state

    if (user.firstNames.length < 1)
      valid = false

    if (user.lastName.length < 1)
      valid = false

    if (user.email.length < 1)
      valid = false

    if (user.mobileNumber.length < 1)
      valid = false

    if (!edit && user.password.length < 1)
      valid = false

    return valid
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    if (this.validateForm()) {
      const { edit, user, userId } = this.state
      try {
        edit ? await updateUser(userId, user) : await createUser(user)
        this.setState({
          redirect: true
        })
      } catch (error) {
        error.graphQLErrors.map(error => {
          toast.error(error.message)

          return true
        })
      }
    } else {
      this.setState({
        error: "Validation Failed"
      })
    }
  }

  async componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ title: "Edit User", edit: true })
      try {
        const response = await getUser(this.props.match.params.id)
        this.setState({
          user: response.data.user,
          userId: this.props.match.params.id
        })
      } catch (error) {
        this.setState({
          error: error.message
        })
      }
    }
  }

  deleteUser = async (e) => {
    e.preventDefault()
    if (window.confirm('Are you sure you wish to delete this item?')) {
      const { userId } = this.state
      try {
        await deleteUser(userId)
        this.setState({
          redirect: true
        })
      } catch (error) {
        this.setState({
          error: error.message
        })
      }
    }
  }

  handleChange = (e) => {
    e.persist()
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [e.target.name]: e.target.value
      },
      error: null
    }))
  }

  handleCheck = (key) => {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [key]: !prevState.user[key]
      },
      error: null
    }))
  }

  render() {
    const { user, error, redirect, title } = this.state

    if (redirect) return <Redirect to="/dashboard/users" />

    return (
      <Fragment>
        <Container>
          <Row className="justify-content-center">
            <Col xl={6} md={8} sm={12}>

              <form onSubmit={(e) => this.handleSubmit(e)} className="create-user-from">
                <Row>
                  <Col className="text-left">
                    <h2 className="page-title pull-left mt-0">{title}</h2>
                  </Col>
                  <Col className="text-right">
                    {this.state.edit
                      ? <button className="btn btn-danger" onClick={(e) => this.deleteUser(e)}>Delete<FontAwesomeIcon icon={faTrash} className="ml-1"></FontAwesomeIcon></button>
                      : null
                    }
                  </Col>
                </Row>
                <Alert className={error ? "d-block" : "d-none"} variant="danger">
                  {error}
                </Alert>
                <FormGroup>
                  <FormLabel htmlFor="firstNames">First Names</FormLabel>
                  <FormControl
                    autoFocus
                    type="text"
                    required
                    placeholder="First Names"
                    value={user.firstNames}
                    name="firstNames"
                    tabIndex="1"
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel htmlFor="lastName">Last Name</FormLabel>
                  <FormControl
                    value={user.lastName}
                    placeholder="Last Name"
                    name="lastName"
                    required
                    onChange={(e) => this.handleChange(e)}
                    type="text"
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel htmlFor="nickName">Nickname</FormLabel>
                  <FormControl
                    value={user.nickName}
                    placeholder="Nickname"
                    name="nickName"
                    required
                    onChange={(e) => this.handleChange(e)}
                    type="text"
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel htmlFor="email">Email Address</FormLabel>
                  <FormControl
                    type="email"
                    placeholder="Email Address"
                    required
                    value={user.email}
                    onChange={(e) => this.handleChange(e)}
                    name="email"
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel htmlFor="mobileNumber">Mobile Number</FormLabel>
                  <FormControl
                    type="text"
                    placeholder="Mobile Number"
                    required
                    value={user.mobileNumber}
                    onChange={(e) => this.handleChange(e)}
                    name="mobileNumber"
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel htmlFor="password">Password</FormLabel>
                  <FormControl
                    placeholder="Password"
                    required
                    name="password"
                    onChange={(e) => this.handleChange(e)}
                    type="password"
                  />
                </FormGroup>
                <FormGroup className="row">
                  <Col xs="6">
                    <label className="form-label">Admin:</label>
                    <br />
                    <span
                      className={user.isAdmin ? "chk chk-blue" : "chk chk-white"}
                      onClick={() => this.handleCheck("isAdmin")}
                      name="isAdmin"
                    >
                    </span>
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Col>
                    <Button
                      block
                      className="btn"
                      type="submit"
                      variant="primary"
                    >
                      {this.state.edit ? "Save" : "Create"}
                      <FontAwesomeIcon icon={faCheck} className="ml-1"></FontAwesomeIcon>
                    </Button>
                  </Col>
                  <Col>
                    <a className="btn btn-danger w-100 ml-1" href="/dashboard/users">Cancel<FontAwesomeIcon icon={faBan} className="ml-1"></FontAwesomeIcon></a>
                  </Col>
                </FormGroup>
              </form>
            </Col>
          </Row>
        </Container>
      </Fragment>
    )
  }
}

export default InputUserScreen;
