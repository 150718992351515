import React from "react";
import { Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../components/shared/IconButton";
// icons
import Add from '../../../assets/ButtonIcons/Add Blue.svg';
import Edit from '../../../assets/ButtonIcons/Edit Blue.svg';

const EmptyTile = ({setData, item, getLabelText}) => {
  return (
    <table className="w-100 h-100">
      <tbody>
        <tr>
          <td className="text-center">
            <p className="cdi-blue-txt ml-0">{getLabelText("add_item")}</p>
          </td>
        </tr>
        <tr>
          <td valign="bottom">
            <Button variant="primary" className="button-bottom w-100" onClick={() => setData(item)}>
              <img src={Add} alt="Add" className="btn-icons"/>
            </Button>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const DataTile = ({ disabled, setData, item, getLabelText }) => {
  return (
    <table className="w-100 h-100">
      <tbody className="h-100">
        <tr style={{ height: "10%" }}>
          <td className="text-right">
            <IconButton tooltip={getLabelText("edit")} eventHandler={() => setData(item)} classNames={disabled ? "disabled" : ""}>
              <img src={Edit} alt="Edit" className="btn-icons"/>
            </IconButton>
          </td>
        </tr>
        <tr>
          <td className="text-center" style={{ height: "80%" }}>
            {item.data.user.avatar
              ? <img src={item.data.user.avatar} style={{ maxWidth: "120px", maxHeight: "120px" }} alt="User Avatar"/>
              : <FontAwesomeIcon icon={faCamera} className="ml-2 fa-4x" style={{ color: "darkgrey" }}></FontAwesomeIcon>
            }
          </td>
        </tr>
        <tr style={{ height: "10%" }}>
          <td valign="bottom" className="text-center">
            <h5 className="cdi-blue-txt ml-0">{`${item.data.user.firstNames}`}</h5>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const Tile = ({ key, item, disabled, setData, getLabelText }) => {
  return (
    <Col key={key} xl={2} lg={3} md={4} sm={6} xs={6} className="text-center">
      <p className="cdi-blue-txt ml-0 mb-0 mt-2">{item.title}</p>
      <div className="team-success-tile mx-auto">
        {item.data
          ? <DataTile disabled={disabled} setData={setData} item={item} getLabelText={getLabelText}/>
        : <EmptyTile disabled={disabled} setData={setData} item={item} getLabelText={getLabelText}/>
        }
      </div>
    </Col>
  )
}

export default Tile;
