import React, { useCallback, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import classnames from "../../components/utils/classnames";
import { useMutation } from "@apollo/react-hooks";
import {
    CREATE_TASK_TRACKER_METRIC_MUTATION,
    UPDATE_TASK_TRACKER_METRIC_MUTATION
} from "../../services/TaskCategoryService";
import displayErrorToast from "../../components/utils/displayErrorToast";

const TASK_TRACKER_STATES = [
    "SCHEDULED",
    "COMPLETE",
    "INCOMPLETE"
];

const taskTrackerCellProps = {
    [TASK_TRACKER_STATES[0]]: { classes: "bg-grey", loadingClasses: "fa-spin text-black" },
    [TASK_TRACKER_STATES[1]]: { classes: "color-selected", loadingClasses: "fa-spin text-white" },
    [TASK_TRACKER_STATES[2]]: { classes: "bg-red", loadingClasses: "fa-spin text-white" },
};

const TaskTrackerCell = ({
     day,
     today,
     taskTrackerId,
     disabledDays,
     result,
     isMetricApplicable,
     team
}) => {
    const [obj, setObj] = useState(result);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(obj.status);
    const [currDate, setCurrDate] = useState(today);
    const [displayedStatus, setDisplayedStatus] = useState(obj.status);

    const onError = (error) => {
        setDisplayedStatus(status);
        displayErrorToast(error);
        setLoading(false);
    };

    useEffect(() => {
        if((currDate.getMonth() - today.getMonth()) === 0) return;
        setStatus(result.status);
        setDisplayedStatus(result.status);
        setObj(result);
        setCurrDate(today);
    }, [result, today, currDate, setCurrDate, setStatus, setDisplayedStatus]);

    const [createMetric] = useMutation(CREATE_TASK_TRACKER_METRIC_MUTATION, {
        onCompleted: ({ createTaskTrackerMetric }) => {
            setObj(createTaskTrackerMetric)
            setLoading(false)
        },
        onError
    });

    const [updateMetric] = useMutation(UPDATE_TASK_TRACKER_METRIC_MUTATION, {
        onCompleted: ({ updateTaskTrackerMetric }) => {
            setObj(updateTaskTrackerMetric)
            setLoading(false)
        },
        onError
    });

    const create = useCallback((nextStatus) => {
        createMetric({
            variables: {
                taskTrackerId: taskTrackerId,
                status: nextStatus,
                date: obj.date,
                teamId: team.id
            },
        });
    }, [createMetric, taskTrackerId, obj]);

    const update = useCallback((nextStatus) => {
        updateMetric({
            variables: {
                id: obj.id,
                status: nextStatus,
                teamId: team.id
            },
        });
    }, [updateMetric, obj]);

    const toggleStatus = () => {
        const nextIndex = TASK_TRACKER_STATES.indexOf(displayedStatus) === 2 ? 0 : (TASK_TRACKER_STATES.indexOf(displayedStatus) + 1);

        setDisplayedStatus(TASK_TRACKER_STATES[nextIndex]);

        return TASK_TRACKER_STATES[nextIndex];
    }

    const onClick = () => {
        setLoading(true);
        const nextStatus = toggleStatus();

        if(obj?.id) update(nextStatus)
        else create(nextStatus);
    }

    const { classes, icon } = taskTrackerCellProps[displayedStatus]

    const visibility = (disabledDays.has(day) || !isMetricApplicable) && "invisible";

    const className = classnames(
        "attendance-cell text-center font-weight-bold p-1 mr-2",
        classes,
        visibility,
        (loading ? " disabled" : "")
    );

    return (
        <div onClick={onClick} className={className}>
            {loading ? <FontAwesomeIcon icon={faSpinner} className={taskTrackerCellProps[displayedStatus].loadingClasses} /> : icon ? <FontAwesomeIcon icon={icon} className="text-white"/> : null}
        </div>
    );
};

export default TaskTrackerCell;
