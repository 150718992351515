import { client } from "./BaseService";
import { gql } from "apollo-boost";
import * as teamActions from '../actions/TeamActions';

const fetchTeams = () => async dispatch => {

  dispatch(teamActions.fetchTeamsStarted());

  let response = await client.query({
    query: gql`query{
      authorizedTeams {
        id
        name
        inTeam
        department {
          id
          name
        }
      }
    }`
  })

  const teamId = localStorage.getItem("teamId");

  response.data.teams = response.data.authorizedTeams;

  if(response.data.teams.length > 0) {

    const orderedTeams = response.data.teams.sort((a, b) => a.department.name.localeCompare(b.department.name))
    let selectedTeamId = null;
    if(orderedTeams?.some(t => t.id === teamId)) 
    {
      selectedTeamId = teamId;
    } 
    else 
    {
      localStorage.setItem("teamId", orderedTeams[0].id);
      selectedTeamId = orderedTeams[0].id;
    }

    if(selectedTeamId)
    {
      const selectedTeam = await fetchSelectedTeam(selectedTeamId)
  
      dispatch(teamActions.selectTeam({ selectedTeam }))
    }
  }
  dispatch(teamActions.fetchTeamsSuccess(response.data));
}

const fetchSelectedTeam = async (id) => {

  const response = await client.query({
    query: gql`query{
      team(id: "${id}") {
        id
        purpose
        name
        hasTeamGoals
        hasCustomers
        hasSuppliers
        hasIndividualGoals
        hasValuePartners
        hasTaskTracker
        hasImplementationModule
        company {
          color
          finalcialYearEnd
          vision
        }
        logo
        coach{
          id
          firstNames
          lastName
          nickName
          avatar
        }
        leaders {
          id
          firstNames
          nickName
          lastName
          avatar
        }
        members {
          id
          firstNames
          nickName
          lastName
          avatar
        }
        meetingMembers {
          id
        }
        combinedMembers {
          id
          firstNames
          nickName
          lastName
          avatar
        }
        department {
          id
          name
          teamLength
          departmentType {
            id
            name
            level
          }
        }
        partners {
          id
          name
          type
          departmentType {
            name
          }
        }
        customers {
          id
          name
          type
          departmentType {
            name
          }
        }
        assignedUserLogs {
          id
          url
          user {
            id
          }
          standIn {
            id
          }
        }
        teamGoalAssignedUserLogs {
          id
          periodKey
          user {
            id
          }
          standIn {
            id
          }
          teamMeasurement {
            id
          }
        }
        businessResultAssignedUserLogs {
          id
          periodKey
          user {
            id
          }
          standIn {
            id
          }
          goal {
            id
          }
        }
        individualResultAssignedUserLogs {
          id
          periodKey
          user {
            id
          }
          standIn {
            id
          }
          goal {
            id
          }
        }
      }
    }`
  })

  return response.data.team

}

const selectTeam = (id) => async dispatch => {
  dispatch(teamActions.fetchTeamsStarted());
  const selectedTeam = await fetchSelectedTeam(id)
  dispatch(teamActions.selectTeam({ selectedTeam }))
  dispatch(teamActions.fetchTeamsSuccess(selectedTeam));
}

const fetchTeamMeasurements = (id) => async (dispatch) => {
  dispatch(teamActions.fetchTeamMeasurementsStarted());
  const response = await client.query({
    query: gql`query {
      teamMeasurements {
        id
        frequency
        numberTo
        numberFrom
        dueDate
        measurement {
          id
          measurement
          unit {
            id
            name
          }
          goalCategory {
            id
            name
          }
        }
      }
    }`
  })
  dispatch(teamActions.fetchTeamMeasurementsSuccess(response.data));
}

const updateTeamPermissions = (name, value) => async (dispatch) => {
  dispatch(teamActions.updatePagePermissions({name, value}))
}

export {
  fetchTeamMeasurements,
  fetchTeams,
  selectTeam,
  updateTeamPermissions
}
