import { connect } from "react-redux";
import * as DefaultLabels from '../../assets/glossary.json';

const labels = DefaultLabels.default;

const LabelComponent = ({val, user}) => {
  const companyId = localStorage.getItem("companyId");
  let lang = localStorage.getItem("lang");
  if(!lang) {
    lang = "EN";
  }
  let langLabels = labels.find(lbl => lbl.lang === lang);
  let def = langLabels.glossary.find(label => label.key === val);

  if(user && user.companies && user.companies.length) {
    const company = user.companies.find(company => company.id === companyId);
    if(company) {
      if(company.language !== lang) {
        lang = company.language;
        localStorage.setItem("lang", company.language)
      }
      langLabels = labels.find(lbl => lbl.lang === lang);
      def = langLabels.glossary.find(label => label.key === val);
      if(!def) {
        lang = "EN";
        langLabels = labels.find(lbl => lbl.lang === lang);
        def = langLabels.glossary.find(label => label.key === val);
      }
    }
    if(company && company.companyGlossaries) {
      const custom = company.companyGlossaries.find(label => label.key === val);

      if(custom) return custom.value
    }
  }

  if(!def) {
    console.log(val)
  }
  return def.value
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LabelComponent);
