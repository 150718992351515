export const mapModuleData = (modules, moduleId, itemId, type, obj = null) => {
  switch(type) {
    case "CREATE":
      return modules.map((module) => {
        return module.id === moduleId ? {
          ...module,
          subjects: [obj, ...module.subjects]
        } : module
      })
    case "UPDATE":
      return modules.map((module) => {
        return module.id === moduleId ? {
          ...module,
          subjects: module.subjects.map(subject => subject.id === itemId ? obj : subject)
        } : module
      })
    default:
      // IF DELETE
      return modules.map((module) => {
        return module.id === moduleId ? {
          ...module,
          subjects: module.subjects.filter(({ id }) => id !== itemId)
        } : module
      })
  }
}
