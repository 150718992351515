import { client } from "./BaseService";
import { gql } from "apollo-boost";

export const getMeetingItems = async (teamId, type, current = false) => {
  return await client.query({
    query: gql`query{
      teamMeetingItems(teamId: "${teamId}", type: ${type}, current: ${current}) {
        id
        screen
        frequency
        days
        weeks
        index
        type
        target
        children {
          id
          frequency
          days
          weeks
          index
          type
          team {
            id
          }
          goal {
            id
            measurement {
              id
              measurement
              unit {
                id
                name
              }
            }
          }
          individualGoal {
            id
            period
            nextYearLink
            latestYearLink
            measurement {
              id
              measurement
              unit {
                id
                name
              }
            }
          }
          measure {
            id
            period
            nextYearLink
            latestYearLink
            measurement {
              id
              measurement
              unit {
                id
                name
              }
            }
          }
          selected {
            id
            name
            department {
              id
              name
            }
          }
        }
        currentChildren {
          id
          frequency
          days
          weeks
          index
          type
          team {
            id
          }
          goal {
            id
            measurement {
              id
              measurement
              unit {
                id
                name
              }
            }
          }
          individualGoal {
            id
            period
            nextYearLink
            latestYearLink
            measurement {
              id
              measurement
              unit {
                id
                name
              }
            }
          }
          measure {
            id
            period
            nextYearLink
            latestYearLink
            measurement {
              id
              measurement
              unit {
                id
                name
              }
            }
          }
          selected {
            id
            name
            department {
              id
              name
            }
          }
        }
        team {
          id
        }
      }
    }`
  })
}

export const createMeetingItem = async(teamId, screen, frequency, days, index, type, weeks, target = null) => {
  if(target) {
    return await client.mutate({
      mutation: gql`mutation {
        createTeamMeetingItem(
          teamId: "${teamId}",
          screen: "${screen}",
          frequency: "${frequency}",
          weeks: "${weeks}",
          days: "${days}",
          type: ${type},
          index: ${index},
          target: ${target}
        )
        {
          id
        }
      }`
    })
  } else {
    return await client.mutate({
      mutation: gql`mutation {
        createTeamMeetingItem(
          teamId: "${teamId}",
          screen: "${screen}",
          frequency: "${frequency}",
          weeks: "${weeks}",
          days: "${days}",
          type: ${type},
          index: ${index}
        )
        {
          id
        }
      }`
    })
  }
}

export const updateMeetingItem = async(id, frequency, days, weeks) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateTeamMeetingItem(
        id: "${id}"
        frequency: "${frequency}",
        weeks: "${weeks}",
        days: "${days}"
      )
      {
        id
      }
    }`
  })
}

export const updateItemIndex = async(id, teamId, items) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateItemIndex(
        id: "${id}",
        teamId: "${teamId}",
        items: "${items}"
      )
      {
        id
      }
    }`
  })
}

export const createMeetingSubItem = async(teamId, itemId, goalId, measureId, selectedId, individualGoalId, frequency, days, index, type, weeks) => {
  if(goalId) {
    return await client.mutate({
      mutation: gql`mutation {
        createTeamMeetingSubItem(
          teamId: "${teamId}",
          itemId: "${itemId}",
          goalId: "${goalId}",
          frequency: "${frequency}",
          days: "${days}",
          type: ${type},
          weeks: "${weeks}",
          index: ${index}
        )
        {
          id
        }
      }`
    })
  } else if(measureId) {
    return await client.mutate({
      mutation: gql`mutation {
        createTeamMeetingSubItem(
          teamId: "${teamId}",
          itemId: "${itemId}",
          measureId: "${measureId}",
          frequency: "${frequency}",
          days: "${days}",
          weeks: "${weeks}",
          type: ${type},
          index: ${index}
        )
        {
          id
        }
      }`
    })
  } else if(individualGoalId) {
    return await client.mutate({
      mutation: gql`mutation {
        createTeamMeetingSubItem(
          teamId: "${teamId}",
          itemId: "${itemId}",
          individualGoal: "${individualGoalId}",
          frequency: "${frequency}",
          days: "${days}",
          weeks: "${weeks}",
          type: ${type},
          index: ${index}
        )
        {
          id
        }
      }`
    })
  } else {
    return await client.mutate({
      mutation: gql`mutation {
        createTeamMeetingSubItem(
          teamId: "${teamId}",
          itemId: "${itemId}",
          selectedId: "${selectedId}",
          frequency: "${frequency}",
          days: "${days}",
          weeks: "${weeks}",
          type: ${type},
          index: ${index}
        )
        {
          id
        }
      }`
    })
  }
}

export const updateMeetingSubItem = async(id, frequency, days, weeks) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateTeamMeetingSubItem(
        id: "${id}"
        frequency: "${frequency}",
        weeks: "${weeks}",
        days: "${days}"
      )
      {
        id
      }
    }`
  })
}

export const updateSubItemIndex = async(id, items) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateSubItemIndex(
        id: "${id}",
        items: "${items}"
      )
      {
        id
      }
    }`
  })
}

export const deleteTeamMeetingSubItem = async(id) => {
  return await client.mutate({
    mutation: gql`mutation {
      deleteTeamMeetingSubItem(
        id: "${id}"
      )
      {
        id
      }
    }`
  })
}

export const deleteTeamMeetingItem = async(id) => {
  return await client.mutate({
    mutation: gql`mutation {
      deleteTeamMeetingItem(
        id: "${id}"
      )
      {
        id
      }
    }`
  })
}
