import * as teamTypes from '../types/TeamTypes';

const fetchTeamStarted = () => ({
  type: teamTypes.FETCH_TEAM_STARTED,
});

const fetchTeamSuccess = (payload) => ({
  type: teamTypes.FETCH_TEAM_SUCCESS,
  payload
});

const fetchTeamFailure = (error) => ({
  type: teamTypes.FETCH_TEAM_FAILED,
  payload: { error },
});

const selectTeam = (payload) => ({
  type: teamTypes.SELECT_TEAM,
  payload
});

const updatePagePermissions = (payload) => ({
  type: teamTypes.UPDATE_PAGE_PERMISSIONS,
  payload
});


const fetchTeamsStarted = () => ({
  type: teamTypes.FETCH_TEAMS_STARTED,
});

const fetchTeamsSuccess = (payload) => ({
  type: teamTypes.FETCH_TEAMS_SUCCESS,
  payload
});

const fetchTeamsFailure = (error) => ({
  type: teamTypes.FETCH_TEAMS_FAILED,
  payload: { error },
});

const fetchTeamMeasurementsStarted = () => ({
  type: teamTypes.FETCH_TEAM_STARTED,
});

const fetchTeamMeasurementsSuccess = (payload) => ({
  type: teamTypes.FETCH_TEAM_SUCCESS,
  payload
});

const fetchTeamMeasurementsFailure = (error) => ({
  type: teamTypes.FETCH_TEAM_FAILED,
  payload: { error },
});

export {
  fetchTeamStarted,
  fetchTeamSuccess,
  fetchTeamFailure,
  fetchTeamsStarted,
  fetchTeamsSuccess,
  fetchTeamsFailure,
  selectTeam,
  fetchTeamMeasurementsStarted,
  fetchTeamMeasurementsSuccess,
  fetchTeamMeasurementsFailure,
  updatePagePermissions
};
