import * as userTypes from '../types/UserTypes';

const fetchUserStarted = () => ({
  type: userTypes.FETCH_USER_STARTED,
});

const fetchUserSuccess = (payload) => ({
  type: userTypes.FETCH_USER_SUCCESS,
  payload
});

const fetchUserFailed= (error) => ({
  type: userTypes.FETCH_USER_FAILED,
  payload: { error },
});

const updateCompanyPagePermissions = (payload) => ({
  type: userTypes.UPDATE_COMPANY_PAGE_PERMISSIONS,
  payload
});

export {
  fetchUserStarted,
  fetchUserSuccess,
  fetchUserFailed,
  updateCompanyPagePermissions
};
