import { gql } from "apollo-boost";

// TODO: remove hardcoded value
export const PROJECTS_QUERY = gql`
  query Projects($teamId: ID!) {
    company {
      projects(teamId: $teamId) {
        id
        name
        to
        from
        fromDate
        toDate
        order
        archived
        milestones(teamId: $teamId) {
          id
          name
          from
          to
          fromDate
          toDate
          order
          archived
          user {
            id
            firstNames
            lastName
          }
          team {
            id
          }
          project {
            id
            name
          }
          milestoneStates {
            id
            key
            state
          }
          issue {
            id
            actions {
              id
              status
              archived
            }
          }
        }
      }
    }
  }
`;

export const GET_START_WEEK = gql`
  query {
    companyStartWeeks {
      id
      period
      week
      date
    }
  }
`

export const CREATE_PROJECT_MUTATION = gql`
  mutation createProject($teamId: String! $name: String! $from: Int! $to: Int!, $fromDate: String!, $toDate: String!, $order: Int! ) {
    createProject(teamId: $teamId, name: $name, from: $from, to: $to, fromDate: $fromDate, toDate: $toDate, order: $order) {
      id
      name
      from
      to
      fromDate
      toDate
      order
      archived
    }
  }
`;


export const UPDATE_PROJECT_MUTATION = gql`
  mutation updateProject($id: ID!, $name: String, $to: Int, $from: Int, $fromDate: String!, $toDate: String!) {
    updateProject(id:$id, name: $name, to: $to, from: $from, fromDate: $fromDate, toDate: $toDate) {
      id
      name
      to
      from
      fromDate
      toDate
      order
      archived
    }
  }
`;


export const DELETE_PROJECT_MUTATION = gql`
  mutation deleteProject($id: ID!) {
    deleteProject(id:$id) {
      id
    }
  }
`;

export const ARCHIVE_PROJECT_MUTATION = gql`
  mutation archiveProject($id: ID!, $archived: Boolean) {
    updateProject(id: $id, archived: $archived) {
      id
    }
  }
`;

export const ARCHIVE_MILESTONE_MUTATION = gql`
  mutation archiveMilestone($id: String, $archive: Boolean) {
    archiveMilestone(id: $id, archive: $archive) {
      id
    }
  }
`;

export const CREATE_MILESTONE_MUTATION = gql`
  mutation createMilestone($projectId: String! $teamId: String! $name: String $from: Int $to: Int $userId: String!, $fromDate: String!, $toDate: String!, $order: Int!) {
    createMilestone(projectId: $projectId teamId: $teamId, name: $name, from: $from, to: $to, userId: $userId, fromDate: $fromDate, toDate: $toDate, order: $order) {
      id
      name
      from
      to
      fromDate
      toDate
      order
      user {
        id
      }
    }
  }
`;

export const UPDATE_MILESTONE_MUTATION = gql`
  mutation updateMilestone($id: ID!, $name: String, $to: Int, $from: Int, $userId: String, $fromDate: String!, $toDate: String!) {
    updateMilestone(id:$id, name: $name, to: $to, from: $from, userId: $userId, fromDate: $fromDate, toDate: $toDate) {
      id
      name
      to
      from
      fromDate
      toDate
      order
      user {
        id
      }
    }
  }
`;



export const DELETE_MILESTONE_MUTATION = gql`
  mutation deleteMilestone($id: ID!) {
    deleteMilestone(id:$id) {
      id
    }
  }
`;

export const UPDATE_MILESTONESTATE_MUTATION = gql`
  mutation updateMilestoneState($id: ID!, $state: Int) {
    updateMilestoneState(id:$id, state: $state) {
      id
      key
      state
    }
  }
`;



export const CREATE_MILESTONESTATE_MUTATION = gql`
  mutation createMilestoneState($milestoneId: String!, $key: Int, $state: Int) {
    createMilestoneState(milestoneId:$milestoneId, key: $key, state: $state) {
      id
      key
      state
    }
  }
`;


export const CREATE_ISSUE_MUTATION = gql`
  mutation createIssue($creatorId: String, $whatText: String, $reviewDate: String, $type: IssueType, $milestoneId: String, $scheduleId: String, $sharedScheduleId: String, $metricId: String, $measurementId: String) {
    createIssue(creatorId: $creatorId, whatText: $whatText, reviewDate: $reviewDate, type: $type, milestoneId: $milestoneId, scheduleId: $scheduleId, sharedScheduleId: $sharedScheduleId, metricId: $metricId, measurementId: $measurementId) {
      id
    }
  }
`

export const CREATE_ACTION_MUTATION = gql`
  mutation createAction($issueId: String!, $actionType: ActionType!, $status: ActionStatus!, $description: String) {
    createAction(issueId: $issueId, actionType: $actionType, status: $status, description: $description) {
      id
    }
  }
`

export const SET_PROJECTS_ORDER = gql`
  mutation setProjectsOrder($values: [String]!) {
    setProjectsOrder(values: $values)
  }
`

export const SET_MILESTONES_ORDER = gql`
  mutation setMilestonesOrder($values: [String]!) {
    setMilestonesOrder(values: $values)
  }
`
