import * as authenticationTypes from '../types/AuthenticationTypes';

const initialState = {
  user: null,
  isLoading: false,
  error: null,
};

const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case authenticationTypes.AUTHENTICATION_STARTED:
      return { ...state, error: null, isLoading: true };
    case authenticationTypes.AUTHENTICATION_SUCCESS:
      return { ...state, ...action.payload, isLoading: false, error: null };
    case authenticationTypes.AUTHENTICATION_FAILED:
      return { ...state, error: action.payload.error, isLoading: false, };
    case authenticationTypes.AUTHENTICATION_ENDED:
      return { ...state, ...action.payload, error: null, isLoading: false };
    default:
      return state;
  }
};

export default authenticationReducer;
