import { gql } from "apollo-boost";
import { client } from "./BaseService";
import gqlConditionalString from "../components/utils/gqlConditionalString";

const getUsers = async (page = null) => {
  return await client.query({
    query: gql`query {
      company {
        users (page: ${page}) {
          id
          lastName
          firstNames
          email
          nickName
          mobileNumber
          isAdmin
          hasPassword
          role
          avatar
          archived
          hasTeams  
        }
      }
    }`
  })
}

const getUsersIdentity = async (page = null) => {
  return await client.query({
    query: gql`query {
      company {
        users (page: ${page}) {
          id
          lastName
          firstNames
          email
          nickName
          mobileNumber
          isAdmin
          role
          avatar
          archived
        }
      }
    }`
  })
}

const getTeamExport = async () => {
  return await client.query({
    query: gql`query {
      company {
        userCSV {
          name
          email
          userRole
          teamRole
        }
      }
    }`
  })
}


const createUser = async ({ email, mobileNumber, firstNames, lastName, nickName, password, isAdmin, role }) => {
  const companyId = localStorage.getItem("companyId")
  return await client.mutate({
    mutation: gql`mutation {
      createUser(
       ${gqlConditionalString(email?.trim(), 'email')}
       ${gqlConditionalString(mobileNumber?.trim(), 'mobileNumber')}
       ${gqlConditionalString(password, 'password')}
       ${gqlConditionalString(nickName?.trim(), 'nickName')}
       ${gqlConditionalString(role, 'role')}
       firstNames: "${firstNames?.trim()}",
       lastName: "${lastName?.trim()}",
       isAdmin: ${isAdmin},
       companyId: "${companyId}"
     ) {
      id
      lastName
      firstNames
      email
      nickName
      mobileNumber
      isAdmin
      hasPassword
      role
      archived
      }
    }`
  })
}

const setUserAvatar = async(id, avatar) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateUser (
        id: "${id}"
        avatar: "${avatar}"
      ) {
        id
        lastName
        firstNames
        email
        nickName
        mobileNumber
        isAdmin
        hasPassword
        role
        archived
      }
    }
    `
  })
}

const deleteUser = async (id) => {
  return await client.mutate({
    mutation: gql`mutation {
      deleteUser(id:"${id}") {
         id
      }
    }
    `
  })
}

const getUser = async (id) => {
  return await client.query({
    query: gql`query {
      user (id: "${id}") {
        id
        lastName
        firstNames
        email
        nickName
        mobileNumber
        isAdmin
        hasPassword
        role
        archived
      }
    }`
  })
}

const updateUser = async (id, user) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateUser (
        id: "${id}"
        firstNames: "${user.firstNames.trim()}"
        lastName: "${user.lastName.trim()}"
        isAdmin: ${user.isAdmin}
        mobileNumber: "${user.mobileNumber?.trim()}"
        ${gqlConditionalString(user.password, 'password')}
        ${gqlConditionalString(user.email.trim(), 'email')}
        ${gqlConditionalString(user.role, 'role')}
      ) {
        id
        lastName
        firstNames
        email
        nickName
        mobileNumber
        isAdmin
        hasPassword
        role
        archived
      }
    }
    `
  })
}

const resetPassword = async (id, email) => {
  return await client.mutate({
    mutation: gql`mutation {
      resetPassword (
        id: "${id}"
        email: "${email}"
      ) {
        id
      }
    }
    `
  })
}

const importUsers = async (users) => {

  return await client.mutate({
    mutation: gql`mutation importUsers(
      $users: [ImportInput]!
    ) {
      importUsers (
        users: $users
      ) {
        id
        lastName
        firstNames
        email
        nickName
        mobileNumber
        isAdmin
        hasPassword
        role
        archived
      }
    }`,
    variables: {
      users: users
    }
  })
}

const clearPassword = async (id) => {

  return await client.mutate({
    mutation: gql`mutation {
      clearPassword (
        id: "${id}"
      ) {
        id
        lastName
        firstNames
        email
        nickName
        mobileNumber
        isAdmin
        hasPassword
        role
        archived
      }
    }`
  })
}

const updateArchived = async(id, archived) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateUser (
        id: "${id}"
        archived: ${archived}
      ) {
        id
        lastName
        firstNames
        email
        nickName
        mobileNumber
        isAdmin
        hasPassword
        role
        archived
      }
    }
    `
  })
}

export {
  getUsers,
  getUsersIdentity,
  getUser,
  deleteUser,
  createUser,
  updateUser,
  resetPassword,
  setUserAvatar,
  importUsers,
  clearPassword,
  updateArchived,
  getTeamExport
}
