import React, { useState, useCallback } from "react";
import Modal from 'react-modal';
import { PERIOD_FREQUENCIES, frequencyProps} from "../resources/enums";
import LabelComponent from "../../../components/utils/getCompanyLabel";

const getLabelText = key => <LabelComponent val={key}/>

const FilterModal = ({ users, routines, showModal, setShowModal, filters, setFilters, getText }) => {
    const [selectedLeadershipRoutine, setSelectedLeadershipRoutine] = useState(filters.leadershipRoutine)
    const [selectedUser, setSelectedUser] = useState(filters.userId)
    const [selectedFrequency, setSelectedFrequency] = useState(filters.frequency)

    const close = useCallback(
        () => {
            setShowModal(false);
        },
        [setShowModal]
    );

    const reset = useCallback(
        () => {
            setSelectedUser(null);
            setSelectedFrequency(null);
            setSelectedLeadershipRoutine(null);
            setFilters({
                userId: null,
                frequency: null,
                leadershipRoutine: null,
            })
            close();
        },
        [setSelectedUser, setSelectedFrequency, setSelectedLeadershipRoutine, setFilters, close]
    );

    const onSave = () => {
        setFilters({
            userId: selectedUser,
            frequency: selectedFrequency,
            leadershipRoutine: selectedLeadershipRoutine
        })
        close();
    };


    const changeFrequency = (frequency) => {
        setSelectedFrequency(frequency)
    }

    return (
        <Modal
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            contentLabel="Result"
            className="standard-modal"
        >
            <div className="row mb-2">
                <div className="col-12 text-center">
                    <h6 className="cdi-blue-txt ml-0 mb-1">{getLabelText("leadership_routine")} {getLabelText("filter")}</h6>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-12 text-center">
                    <select
                        className={"form-control cdi-ddl w-100 ml-0"}
                        value={selectedLeadershipRoutine}
                        onChange={({ target: { value } }) => setSelectedLeadershipRoutine(value)}
                        autoComplete="off"
                    >
                        <option value={''}>{getText("select_routine")}</option>
                        {routines.map(({ id, name }) => {
                            return <option autoComplete="off" key={id} value={id}>{name}</option>
                        })}
                    </select>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-12 text-center">
                    <select
                        className="form-control cdi-ddl w-100 ml-0"
                        value={selectedUser}
                        onChange={({ target: { value } }) => setSelectedUser(value)}
                        autoComplete="off"
                    >
                        <option value={''}>{getText("select_member")}</option>
                        {users.map(({ id, firstNames, lastName }) => {
                            return <option autoComplete="off" key={id} value={id}>{firstNames} {lastName}</option>
                        })}
                    </select>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-12 text-center">
                    <select
                        className={"form-control cdi-ddl w-100 ml-0"}
                        value={selectedFrequency}
                        onChange={({ target: { value } }) => changeFrequency(value)}
                        autoComplete="off"
                    >
                        <option value={''}>{getText("select_frequency")}</option>
                        {PERIOD_FREQUENCIES.map((frequency, i) => {
                            return <option autoComplete="off" key={i} value={frequency}>{getText(frequencyProps[frequency].text.toLowerCase())}</option>
                        })}
                    </select>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-4">
                    <button className="btn btn-primary w-100" onClick={() => onSave()}>
                        {getLabelText("save")}
                    </button>
                </div>
                <div className="col-4">
                    <button className="btn btn-default cdi-blue-outline color-blue w-100" onClick={() => close()}>{getLabelText("cancel")}</button>
                </div>
                <div className="col-4">
                    <button className="btn btn-default cdi-blue-outline color-blue w-100" onClick={() => reset()}>{getLabelText("clear")} {getLabelText("filter")}</button>
                </div>
            </div>
        </Modal>
    )
}

export default FilterModal
