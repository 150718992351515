import React, { useState, useEffect, Fragment } from "react";
import { FormControl, Button } from "react-bootstrap";
// icons
import Save from '../../assets/ButtonIcons/Save Blue.svg';
import Cancel from '../../assets/ButtonIcons/Cancel Blue.svg';
import Edit from '../../assets/ButtonIcons/Edit Blue.svg';
import Delete from '../../assets/ButtonIcons/Delete Blue.svg';
import Loader from '../../assets/ButtonIcons/Loader Blue.svg';

const getName = (id, array) => {
  const item = array.find(arr => arr.id === id)

  return item ? item.name : ""
}

const getType = (id, array) => {
  const item = array.find(arr => arr.id === id)

  return item ? item.type : ""
}


const PartnerField = ({item, editable, editId, array, setEdit, cancel, save, deleteItem, type, fullArr}) => {
  const [name, setName] = useState(getName(item, fullArr))
  const [id, setId] = useState(item)
  const [loading, setLoading] = useState(false)

  const saveItem = () => {
    setLoading(true)
    save(id, name)
  }

  useEffect(() => {
    if(editable) return;
    setName(getName(item, array))
    setId(item)
  }, [item, array, editable, setName, setId])

  useEffect(() => {
    if(editable) return;
    setLoading(false)
  }, [editable, setLoading])

  const getButtons = id => {
    if(editable && editId === id) {
      return (
        <Fragment>
          <td>
            <Button className="btn-icons-container" onClick={() => saveItem()} disabled={loading}>
              {loading ? (
                <img src={Loader} alt="Loader" className="loader-spinner"/>
            ) : (
                <img src={Save} alt="Save" className="btn-icons"/>
            )}
            </Button>
            <Button className="btn-icons-container" onClick={() => cancel()}>
              <img src={Cancel} alt="Cancel" className="btn-icons"/>
            </Button>
          </td>
        </Fragment>
      )
    }

    if(getType(id, fullArr) === "EXTERNAL") {
      return (
        <Fragment>
          <td>
            <Button className="btn-icons-container" onClick={() => setEdit(id)} disabled={editable}>
              <img src={Edit} alt="Edit" className="btn-icons"/>
            </Button>
            <Button className="btn-icons-container" onClick={() => deleteItem(id, type)} disabled={editable}>
              <img src={Delete} alt="Delete" className="btn-icons"/>
            </Button>
          </td>
        </Fragment>
      )
    }

    return (
      <td colSpan={2} className="text-left">
        <Button className="btn-icons-container" onClick={() => deleteItem(item, type)} disabled={editable}>
          <img src={Delete} alt="Delete" className="btn-icons"/>
        </Button>
      </td>
    )
  }

  return (
    <tr>
      <td width="80%">
        <FormControl
          type="text"
          disabled={!editable || (editable && editId !== id)}
          value={name}
          onChange={(e) => setName(e.target.value)}
          autoComplete="off"
        />
      </td>
      {getButtons(item)}
    </tr>

  )
}

export default PartnerField
