import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";

import Loader from '../../../assets/ButtonIcons/Loader Blue.svg';

import { CREATE_MILESTONE_STATE_MUTATION, UPDATE_MILESTONE_STATE_MUTATION } from "../resources/gql";

const ChartCell = ({...props}) => {
  const [milestoneState, setMilestoneState] = useState(props.milestoneState)
  const [milestone] = useState(props.milestone)
  const [index] = useState(props.index)
  const [color] = useState(props.color)
  const [state, setState] = useState(milestoneState ? milestoneState.state : 0)
  const [displayState, setDisplayState] = useState(milestoneState ? milestoneState.state : 0)

  const colors = ['#FFF', '', '#C00000'];

  const [updateMilestoneState] = useMutation(UPDATE_MILESTONE_STATE_MUTATION, {
    onCompleted: (data) => {
      setMilestoneState(data.updateImplementationMilestoneState)
      setState(data.updateImplementationMilestoneState.state)
    },
    onError: () => {
      setDisplayState(state)
    }
  })

  const [createMilestoneState] = useMutation(CREATE_MILESTONE_STATE_MUTATION, {
    onCompleted: (data) => {
      setMilestoneState(data.createImplementationMilestoneState)
      setState(data.createImplementationMilestoneState.state)
    },
    onError: () => {
      setDisplayState(state)
    }
  })

  const setNextState = (nextIndex) => {
    if(props.archived) return;

    if (nextIndex >= 2) {
      nextIndex = 0;
    } else {
      nextIndex = nextIndex + 1;
    }

    setDisplayState(nextIndex)

    if (milestoneState !== undefined) {
      updateMilestoneState({ variables: { id: milestoneState.id, state: nextIndex } })
    } else {
      createMilestoneState({ variables: { milestoneId: milestone.id, key: index, state: nextIndex } })
    }

    props.getImplementationTrackers(milestoneState?.id);
  }

  const colorClass = () => {
    if (displayState === 1) {
      return "color-selected"
    } else {
      return ""
    }
  }

  if (color) {
    return (<div key={Math.random()} style={{ textAlign: 'center', display: 'inline-block', width: 100, height: 50, backgroundColor: color }}></div>)
  } else {
    return (
      <div key={Math.random()} style={{ width: 100, height: 50, display: 'inline-block', textAlign: 'center', backgroundColor: 'lightgray' }} className={props.archived ? "disabled" : ""}>
        {
          (props.loading.loading && props.loading.loadingType === 'cell' && props.loading.loadingId === milestoneState?.id) ? (
            <div className={colorClass()} style={{ display: 'inline-block', width: 30, height: 30, margin: 10 }} >
              <div className="position-absolute">
                <img src={Loader} alt="Loader" className="d-flex justify-content-center align-items-center loader-spinner"/>
              </div>
            </div>
          ) : 
          (
            <div onClick={() => setNextState(displayState)} className={colorClass()} style={{ border: '1px solid gray', display: 'inline-block', width: 30, height: 30, margin: 10, backgroundColor: colors[displayState ? displayState : 0] }} />
          )
        }
      </div>
    )
  }
}

export default ChartCell;
