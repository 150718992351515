import get from "lodash/get";

const alphabeticalSort = (a, b, path) => {
  let A = path ? get(a, path) : a;
  let B = path ? get(b, path) : b;

  if (!A || !B) return 0;

  A = A.toUpperCase();
  B = B.toUpperCase();

  if (A > B) return 1;
  if (A < B) return -1;

  return 0;
};

export default alphabeticalSort;
