import React, { useEffect, useState, useCallback } from "react";
import { useMutation } from "@apollo/react-hooks";
import { useDebounce } from "use-debounce/lib";
import { format, set } from "date-fns";

import classnames from "../../components/utils/classnames";
import displayErrorToast from "../../components/utils/displayErrorToast";

import useIndexedState from "./resources/useIndexedState";
import { DAY_ATTENDANCE_STATES } from "./resources/statuses";
import {
  UPDATE_TEAM_ATTENDANCE_MUTATION,
  CREATE_TEAM_ATTENDANCE_MUTATION,
} from "./resources/gql";

const dateCellProps = {
  [DAY_ATTENDANCE_STATES[0]]: { classes: "" },
  [DAY_ATTENDANCE_STATES[1]]: { classes: "bg-danger text-white" },
  [DAY_ATTENDANCE_STATES[2]]: { classes: "bg-dark text-white" },
};

const DayCell = ({
  day,
  onCellChange,
  teamId,
  loading,
  today,
  attendance = { status: "NORMAL_DAY", id: null },
}) => {
  const [attendanceId, setAttendanceId] = useState(attendance.id);
  const [
    { displayedStatus, status },
    advanceState,
    reset,
    save,
  ] = useIndexedState(attendance.status, DAY_ATTENDANCE_STATES, true);

  const [nextStatus] = useDebounce(displayedStatus, 1000);

  const onError = (error) => {
    reset();
    displayErrorToast(error);
  };

  const [updateTeamAttendance] = useMutation(UPDATE_TEAM_ATTENDANCE_MUTATION, {
    onError,
    onCompleted: ({ updateTeamAttendance: { status } }) => {
      save(status);
    },
  });

  const [createTeamAttendance] = useMutation(CREATE_TEAM_ATTENDANCE_MUTATION, {
    onError,
    onCompleted: ({ createTeamAttendance: { status, id } }) => {
      save(status);
      setAttendanceId(id);
    },
  });

  const onClick = () => {
    if (loading) return;
    const nextState = advanceState();
    onCellChange({ state: nextState, index: day });
  };

  const create = useCallback(() => {
    createTeamAttendance({
      variables: {
        status: nextStatus,
        team: teamId,
        date: format(set(today, { date: day }), "yyyy-MM-dd"),
      },
    });
  }, [createTeamAttendance, nextStatus, teamId, today, day]);

  const update = useCallback(() => {
    updateTeamAttendance({
      variables: {
        status: nextStatus,
        id: attendanceId,
      },
    });
  }, [updateTeamAttendance, nextStatus, attendanceId]);

  useEffect(() => {
    if (nextStatus === status) return;

    if (attendanceId) update();
    else create();
  }, [nextStatus, status, attendanceId, update, create]);

  const className = classnames(
    "attendance-cell attendance-date-border text-center p-1 border mr-2",
    dateCellProps[displayedStatus].classes
  );

  return (
    <div onClick={onClick} className={className}>
      {day}
    </div>
  );
};

export default DayCell;
