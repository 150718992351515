import * as teamTypes from '../types/TeamTypes';

const initialState = {
  team: null,
  teamMeasurements: [],
  isLoading: false,
  teams: [],
};

const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case teamTypes.FETCH_TEAM_STARTED:
      return { ...state, error: null };
    case teamTypes.FETCH_TEAM_SUCCESS:
      return { ...state, ...action.payload, error: null };
    case teamTypes.FETCH_TEAM_FAILED:
      return { ...state, error: action.payload.error };
    case teamTypes.FETCH_TEAM_MEASUREMENTS_STARTED:
      return { ...state, error: null };
    case teamTypes.FETCH_TEAM_MEASUREMENTS_SUCCESS:
      return { ...state, ...action.payload, error: null };
    case teamTypes.FETCH_TEAM_MEASUREMENTS_FAILED:
      return { ...state, error: action.payload.error };
    default:
      return state;
  }
};

export default teamReducer;
