import React, { useState, Fragment } from "react";
import Chart from "./Chart/Chart";
import ResultInput from "./ResultInput";
import { toNextPeriodStatus } from "./resources";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import { faSpinner, faCaretSquareLeft, faCaretSquareRight } from "@fortawesome/free-solid-svg-icons";
import MonthSelect from "../../components/MonthSelect";
import { addMonths } from "date-fns";
import {
  useDynamicDomain,
  useMetricData,
  useCreateOrUpdateMetric,
  getPeriodKey,
  getPeriodValue
} from "./hooks";
import LabelComponent from "../../components/utils/getCompanyLabel";

const getLabelText = key => <LabelComponent val={key}/>

const LOWER_TRIGGER = [0, 8];

const getGoalFormattedDate = date => {
  const months = [
    {
      index: 0,
      name: "Jan"
    },
    {
      index: 1,
      name: "Feb"
    },
    {
      index: 2,
      name: "Mar"
    },
    {
      index: 3,
      name: "Apr"
    },
    {
      index: 4,
      name: "May"
    },
    {
      index: 5,
      name: "Jun"
    },
    {
      index: 6,
      name: "Jul"
    },
    {
      index: 7,
      name: "Aug"
    },
    {
      index: 8,
      name: "Sep"
    },
    {
      index: 9,
      name: "Oct"
    },
    {
      index: 10,
      name: "Nov"
    },
    {
      index: 11,
      name: "Dec"
    }
  ]

  return months[new Date(date).getMonth()].name + " " + new Date(date).getFullYear()
}
const TeamGoalsContent = ({ date, goal, isLoading, deltaUpdate, attendances, isTrend, monthList, monthValues, selectedId, fiscalYear, colour, setDate, showNext, showPrev, setSelectedGoalIndex, getNextIndex, setIsTrend, selectedGoalIndex, teamGoals, setLinkedId }) => {
  const attendanceWeekStatuses = () => {
    let data = [];

    attendances.forEach((att) => {
      let date = new Date(att.date);
      let start = new Date(date.getFullYear(), 0, 1);
      let week = Math.ceil((((date - start) / 86400000) + start.getDay() + 1) / 7);

      let currentWeekObject = data.find((x) => x.week === week);
      if (currentWeekObject) {
        if (att.status === "NON_WORKING_DAY") {
          currentWeekObject.week = week;
          currentWeekObject.nonWorkingDayCount++;
        }
      } else {
        let weekObject = {
          week: week,
          nonWorkingDayCount: att.status === "NON_WORKING_DAY" ? 1 : 0,
        }

        data.push(weekObject);
      }
    })
    return data;
  }
  const getLatestComment = () => {
    let latestComment = ""
    monthValues.map(value => {
      const monthMetric = goal.companyMetrics.find(metric => metric.periodValue === value)
      if(monthMetric && monthMetric.comment) latestComment = monthMetric.comment
      return true
    })
    return latestComment
  }

  const changeMonth = (delta) => {
    const nextDate = addMonths(date, delta);
    setDate(nextDate, delta > 0);
  };

  const getCommentLabel = () => {
    if(commentIndex) {
      const year = monthValues[commentIndex - 1] < monthValues[0] ? `${date.getFullYear() + 1}` : `${date.getFullYear()}`

      return monthList[commentIndex - 1] + " " + year
    } else {
      let latestComment = monthValues[0]
      monthValues.map(value => {
        const monthMetric = goal.companyMetrics.find(metric => metric.periodValue === value)
        if(monthMetric && monthMetric.comment) latestComment = value
        return true
      })
      const year = latestComment < monthValues[0] ? `${date.getFullYear() + 1}` : `${date.getFullYear()}`
      return monthList[monthValues.indexOf(latestComment)] + " " + year
    }
  }

  const data = useMetricData(goal, date, isTrend, monthValues);

  const [index, setIndex] = useState(null);
  const [commentIndex, setCommentIndex] = useState(null)
  const domain = useDynamicDomain(
    data,
    [0, 0],
    goal.numberTo,
    LOWER_TRIGGER[1]
  );

  const [target] = useState([goal.numberFrom, goal.numberTo]);
  const [upperTrigger] = useState([0, 0]);
  const [lowerTrigger] = useState([0, 0]);
  const [frequencyTrigger] = useState(0);
  const [metricTarget, setMetricTarget] = useState(null)
  const [createMetric, updateMetric, isRequesting] = useCreateOrUpdateMetric();
  const [activeComment, setActiveComment] = useState("");

  const isLoadingOrRequesting = isLoading || isRequesting;

  const handleSelectMetric = (index, commentIndex) => {
    setIndex(index);
    setCommentIndex(commentIndex)
  };

  const handleTogglePeriodStatus = async (index) => {
    const metric = data[index];

    const periodStatus = toNextPeriodStatus(metric);

    if (metric.id) {
      const variables = {
        id: metric.id,
        period: "MONTH",
        periodValue: metric.x,
        periodStatus
      };
      const response = await updateMetric({ variables });
      if (response) {
        deltaUpdate(response.data.updateCompanyMetric);
      }
    } else {
      // If we are toggling period status before adding any value for this period,
      // we need to create a new metric record to hold the period status
      const variables = {
        goal: goal.id,
        value: metric.y,
        status: metric.status,
        period: "MONTH",
        periodKey: getPeriodKey(fiscalYear, date),
        periodValue: getPeriodValue(metric.x, monthValues),
        periodStatus,
        team: selectedId
      };
      const response = await createMetric({ variables });
      if (response) deltaUpdate(response.data.createCompanyMetric);
    }
  };

  return (
    <div>
      {isLoadingOrRequesting && (
        <div className="page-loader">
          <div className="loader-box text-center">
            <FontAwesomeIcon className="text-white fa-spin fa-3x" icon={faSpinner} />
            <p className="text-white mt-4"><strong>Loading</strong></p>
          </div>
        </div>
      )}
      <div className={"row goal-title-container"}>
        <div className={"offset-xl-3 offset-lg-0 offset-xs-0 " + (getLatestComment() || activeComment ? "col-xl-6 col-lg-9 col-xs-12 py-2 text-center" : "col-xl-5 col-lg-8 col-xs-12 py-2 text-center")}>
          {goal
            ? (
              <Fragment>
                <table className="w-100 h-100">
                  <tbody>
                    <tr>
                      <td valign="middle" className="pr-2">
                        <button
                          className="button-unstyled d-inline text-white"
                          style={{ color: "#fff!important" }}
                          onClick={() => {
                            setSelectedGoalIndex(getNextIndex(true)); setIsTrend(false); setLinkedId(null); localStorage.setItem("selectedBusinessResult", getNextIndex(true)); 
                          }}
                        >
                          <FontAwesomeIcon
                            className="text-white"
                            size="2x"
                            icon={faCaretSquareLeft}
                          />
                        </button>
                      </td>
                      <td valign="middle" className="text-center">
                        <Dropdown>
                          <Dropdown.Toggle className={"nav-link w-100"} variant="none" id="dropdown-basic" style={{ whiteSpace: "break-spaces" }}>
                            <h4 className="d-inline"><b>{goal.measurement.goalCategory.name + " - " + goal.measurement.measurement} ({goal.measurement.unit.name})</b></h4>
                          </Dropdown.Toggle>
                          <Dropdown.Menu style={{ maxHeight: "400px", overflow: "auto" }}>
                            {teamGoals.map((goal, i) => {
                              return <Dropdown.Item key={i} value={i} onClick={(e) => { setSelectedGoalIndex(parseInt(i)); setIsTrend(false); setLinkedId(null); localStorage.setItem("selectedBusinessResult", i); }}>{goal.measurement.goalCategory.name + " - " + goal.measurement.measurement} ({goal.measurement.unit.name})</Dropdown.Item>
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                      <td valign="middle" className="pl-2">
                        <button
                          className="button-unstyled d-inline text-white"
                          onClick={() => {
                            setSelectedGoalIndex(getNextIndex()); setIsTrend(false); setLinkedId(null); localStorage.setItem("selectedBusinessResult", getNextIndex());
                          }}
                        >
                          <FontAwesomeIcon size="2x" icon={faCaretSquareRight} />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Fragment>
            ) : null
          }
        </div>
        <div className={getLatestComment() || activeComment ? "col-lg-3 col-xs-12 py-2 text-right" : "col-lg-4 col-xs-12 py-2 text-right"}>

        </div>

      </div>
      <div className="row controls-container mb-4">
        <div className={getLatestComment() || activeComment ? "col-9 pb-1 pt-2 text-center" : "col-12 pb-1 pt-2 text-center"}>
          <div className="w-100">
            <h6 className="font-weight-bold">{getLabelText("trend_target")}</h6>
            <div className="w-100 justify-content-center">
              <label className="mr-2 control-label d-inline">{getLabelText("from")}</label>
              <label className="font-weight-bold control-label">{goal.numberFrom}</label>
              <label className="mx-2 control-label d-inline">{getLabelText("to")}</label>
              <label className="font-weight-bold control-label">{goal.numberTo}</label>
              <label className="mx-2 control-label d-inline">{getLabelText("by")}</label>
              <label className="font-weight-bold control-label">{getGoalFormattedDate(goal.dueDate)}</label>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ opacity: isLoadingOrRequesting ? 0.5 : 1 }}
        className="team-goals-container w-100 justify-content-around pb-3"
      >
        <div className="row">
          <div className={getLatestComment() || activeComment ? "col-9" : "col-12"}>
            <div className="row mb-5 pr-3 justify-content-center">
              <div className="col-12">
                <div className="chart">
                  <Chart
                    data={data}
                    target={target}
                    lowerTrigger={lowerTrigger}
                    upperTrigger={upperTrigger}
                    frequencyTrigger={frequencyTrigger}
                    domain={domain}
                    opaque={index !== null}
                    handleSelectMetric={handleSelectMetric}
                    handleTogglePeriodStatus={handleTogglePeriodStatus}
                    attendanceWeekStatuses={attendanceWeekStatuses()}
                    monthList={monthList}
                    monthValues={monthValues}
                    resultDate={date}
                    goal={goal}
                    setMetricTarget={setMetricTarget}
                    colour={colour}
                    setActiveComment={setActiveComment}
                  />
                  {index !== null && (
                    <ResultInput
                      resultDate={date}
                      goal={goal}
                      onSubmit={deltaUpdate}
                      colour={colour}
                      data={data[index]}
                      onClose={() => {
                        setIndex(null)
                        setCommentIndex(null)
                      }}
                      isTrend={isTrend}
                      monthList={monthList}
                      monthValues={monthValues}
                      metricTarget={metricTarget}
                      selectedId={selectedId}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-5 justify-content-center">
              <div className="col-10 text-center">
                <div className="row justify-content-center">
                  <div className="col-4">
                    <div className="text-center">
                      <MonthSelect
                        date={date}
                        onChange={changeMonth}
                        iconSize="2x"
                        containerClass="justify-content-between text-uppercase my-2"
                        isTrend={isTrend}
                        showNext={showNext}
                        showPrev={showPrev}
                        isMonth={true}
                      />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {getLatestComment() || activeComment
            ? (
              <div className="col-3">
                <div
                  className="d-flex justify-content-center"
                >
                  {commentIndex !== null && (
                    <>
                      <div className="cdi-comment-box">
                        <h3><b>{getCommentLabel()}:</b></h3>
                        <h5 className="mt-2">
                          {activeComment ? activeComment : getLabelText("no_comment")}
                        </h5>
                      </div>
                    </>
                  )}
                  {!commentIndex && getLatestComment() !== "" && (
                    <>
                    <div className="cdi-comment-box">
                      <h3><b>{getCommentLabel()}:</b></h3>
                      <h5 className="mt-2">
                        {getLatestComment()}
                      </h5>
                    </div>
                    </>
                  )}
                </div>
              </div>
            ) : null
          }

        </div>
      </div>
    </div>
  );
};

export default TeamGoalsContent;
