import 'bootstrap/dist/css/bootstrap.min.css';  
import React from 'react';
import {Pagination as PaginationBoostrap} from 'react-bootstrap';  

function Pagination({current, totalPages, setPage}) { 
    const displayRange = 2;
    let startPage = current - displayRange;
    let endPage = current + displayRange;

    if (startPage < 1) {
        startPage = 1;
        endPage = Math.min(totalPages, 2 * displayRange + 1);
    }

    if (endPage > totalPages) {
        endPage = totalPages;
        startPage = Math.max(1, totalPages - (2 * displayRange + 1));
    }

    let items = [];

    for (let i = startPage; i <= endPage; i++) {
        items.push(
            <PaginationBoostrap.Item key={i} active={i === current} onClick={() => setPage(i)}>{i}</PaginationBoostrap.Item> 
        )
    }

  return (  
    <>  
        <div className='container d-flex justify-content-center'>  
            <PaginationBoostrap>  
                <PaginationBoostrap.First onClick={() => setPage(1)} disabled={current === 1} />
                <PaginationBoostrap.Prev onClick={() => setPage(current > 1 ? current - 1 : 1)} disabled={current === 1} />
                {startPage > 1 && <PaginationBoostrap.Ellipsis disabled={true} />}
                {
                    items
                } 
                {endPage < totalPages - 1 && <PaginationBoostrap.Ellipsis disabled={true} />}
                <PaginationBoostrap.Next onClick={() => setPage(Math.min(current + 1, totalPages))} disabled={current === totalPages} />
                <PaginationBoostrap.Last onClick={() => setPage(totalPages)} disabled={current === totalPages} />
            </PaginationBoostrap>  
        </div>  
    </>  
  );  
}  
export default Pagination;  