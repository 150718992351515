import React, { Component, Fragment  } from "react";
import {  Row, Col, ButtonGroup, ToggleButton, Button } from "react-bootstrap";
import { toast } from 'react-toastify';
import displayErrorToast from "../../components/utils/displayErrorToast";
import EntityAccordion from "./EntityAccordion";
import { connect } from "react-redux";
import { getEntity, createIssue, createImprovementIdea, updateIssue, createAction, updateAction, customerSupplierArchive, nextStep, createRelationship, updateRelationship, createReviewDate, updateReviewDate, deleteIssue, createShared, updateShared, createRequest, createAgreement, updateAgreement } from "../../services/CustomerSupplierService";
import { Redirect } from "react-router-dom";
import SubHeaderComponent from "../../components/SubHeaderComponent";
import RealtimeRefreshComponent from "../../components/RealtimeRefreshComponent";
import LabelComponent from "../../components/utils/getCompanyLabel";
import AWS from 'aws-sdk'
import axios from 'axios';
import { canEditHere } from "../../utils/checkPermissions";
import Excel from 'exceljs';
import { saveAs } from 'file-saver';

const workSheetName = 'Worksheet-1';

const getLabelText = key => <LabelComponent val={key}/>

class CustomersScreen extends Component {
  state = {
    customers: [],
    customerId: "",
    problemId: "",
    editable: 0,
    actions: [],
    date: "",
    feedback: "",
    rootCause: "",
    newSolution: "",
    newFix: "",
    type: "",
    teamId: 0,
    archived: false,
    screenType: "customer",
    editReviewDateId: 0,
    editReviewDate: false,
    reviewDate: "",
    redir: null
  }

  constructor (props) {
    super(props)

    this.props.togglePageLoad(true)
  }

  async componentDidMount() {
    AWS.config.update({
			region: 'us-east-1',
			accessKeyId: 'AKIASXFQ2SFJ4JRIBL5T',
			secretAccessKey: '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'
		})
    if(this.props.selectedTeam.id !== 0) {
      // if(this.props.selectedTeam.department.departmentType.level === "LVL2") {
      //   this.props.history.push("/what/value-stream-partners")
      //   return
      // }
      //
      // if(this.props.selectedTeam.department.departmentType.level !== "LVL1") {
      //   this.props.history.push("/what/team-attendance")
      //   return
      // }
      await this.fetchEntities()
      this.setState({teamId: this.props.selectedTeam.id})
      this.props.togglePageLoad(false)
    }
  }

  async componentDidUpdate(prevProps) {
    if(this.props.selectedTeam.id !== 0 && (this.props.selectedTeam.id !== prevProps.selectedTeam.id)) {
      // if(this.props.selectedTeam.department.departmentType.level === "LVL2") {
      //   this.props.history.push("/what/value-stream-partners")
      //   return
      // }
      //
      // if(this.props.selectedTeam.department.departmentType.level !== "LVL1") {
      //   this.props.history.push("/what/team-attendance")
      //   return
      // }
      this.props.togglePageLoad(true)
      await this.fetchEntities()
      this.setState({teamId: this.props.selectedTeam.id });
      this.props.togglePageLoad(false);
    }

    if(this.props.selectedTeam.id !== 0 && prevProps.refresh !== this.props.refresh && this.props.refresh) {
      // if(this.props.selectedTeam.department.departmentType.level === "LVL2") {
      //   this.props.history.push("/what/value-stream-partners")
      //   return
      // }
      //
      // if(this.props.selectedTeam.department.departmentType.level !== "LVL1") {
      //   this.props.history.push("/what/team-attendance")
      //   return
      // }
      this.props.togglePageLoad(true)
      await this.fetchEntities()
      this.setState({teamId: this.props.selectedTeam.id });
      this.props.toggleRefresh()
      this.props.toggleHasNewData()
      this.props.togglePageLoad(false)
    }
  }

  checkPermission = () => {
    return canEditHere(this.props.user, this.props.teams, this.props.selectedTeam);
  }

  async fetchEntities() {
    const { selectedTeam } = this.props
    const response = await getEntity(selectedTeam.id, "customer")
    // add External Customers
    // let customers = [...response.data.team.customers]
    if(!response.data.team.customers.length) {
      toast.error(getLabelText("define_customer"))
      this.setState({
        redir: "/teams/team-partners"
      })
      return
    }
    this.setState({ customers: response.data.team.customers })
  }

  static async getDerivedStateFromProps(nextProps, prevState) {
    if(nextProps.selectedTeam.id !== prevState.teamId) {
      const { selectedTeam } = nextProps
      const response = await getEntity(selectedTeam.id, "customer")
      // add External Customers
      // let customers = [...response.data.team.customers]
      if(response.team) {
        if(!response.data.team.customers.length) {
          toast.error(getLabelText("define_customer"))
          this.setState({
            redir: "/teams/team-partners"
          })
          return
        }
        return { customers: response.data.team.customers, teamId: selectedTeam.id, pageLoading: false }
      }

      return {pageLoading: true}
    }

    return null
  }


  addProblem = (e, id) => {
    const { customers } = this.state
    const generatedId = Math.ceil(Math.random() * 100)
    customers.map((customer) => {
      if(customer.id === id) {
        customer.assignedIssues.unshift({
          id: generatedId,
          reviewDate: new Date(),
          whatText: "",
          whyText: "",
          archived: false,
          actions: []
        })
      }

      return true
    })

    this.setState({
      customers,
      customerId: id,
      problemId: generatedId,
      date: new Date(),
      editable: 1,
      type: "create"
    })
  }

  editProblem = (customerId, problemId) => {
    const { customers } = this.state

    const customer = customers.filter((customer) => {
      return customer.id === customerId
    })[0]

    const problem = customer.assignedIssues.filter((problem) => {
      return problem.id === problemId
    })[0]

    this.setState({
      customerId: customerId,
      problemId: problemId,
      feedback: problem.whatText,
      rootCause: problem.whyText,
      actions: problem.actions,
      date: new Date(problem.reviewDate),
      editable: 1,
      type: "edit"
    })
  }

  saveAction = (type) => {
    const { newSolution, newFix, actions } = this.state;
    actions.push({description: type === "FEEDBACK" ? newSolution : newFix, status: "NONE", actionType: type})
    type === "FEEDBACK" ? this.setState({newSolution: ""}) : this.setState({newFix: ""})
  }

  saveActions = (id, actions) => {
    return Promise.all(actions.map(async(action) => {
      if(action.description !== "") {
        action.description = action.description.replaceAll(/"/g, '\\"');
        if(action.id) {
          await updateAction(action.id, action.actionType, action.status, action.description)
        } else {
          await createAction(id, action.actionType, action.status, action.description)
        }
      }
    }))
  }

  updateDepartmentTeams = async () => {
    let {customerId, actions, date, feedback, rootCause } = this.state
    const { selectedTeam } = this.props

    feedback = feedback.replaceAll(/"/g, '\\"');
    rootCause = rootCause.replaceAll(/"/g, '\\"');

    return Promise.all(this.props.teams.filter(team => team.department.id === selectedTeam.department.id && team.id !== selectedTeam.id ).map(async(team) => {
      const response = await createIssue(team.id, customerId, feedback, rootCause, date.toISOString(), "CUSTOMER_ISSUE")
      await this.saveActions(response.data.createIssue.id, actions)
    }))
  }

  saveProblem = async () => {
    let {type, customerId, problemId, actions, date, feedback, rootCause, newFix, newSolution } = this.state
    this.setLoading(problemId, "save")
    actions.push({description: newSolution, status: "NONE", actionType: "FEEDBACK"})
    actions.push({description: newFix, status: "NONE", actionType: "ROOT_CAUSE"})

    feedback = feedback.replaceAll(/"/g, '\\"');
    rootCause = rootCause.replaceAll(/"/g, '\\"');

    if(type === "create") {
      if(this.props.selectedTeam.department.teamLength > 1) {
        await this.updateDepartmentTeams()
      }
      const response = await createIssue(this.props.selectedTeam.id, customerId, feedback, rootCause, date.toISOString(), "CUSTOMER_ISSUE")
      await this.saveActions(response.data.createIssue.id, actions)
    } else {
      const response = await updateIssue(problemId, feedback, rootCause, date.toISOString())
      await this.saveActions(response.data.updateIssue.id, actions)
    }
    await this.fetchEntities()

    this.setState({
      customerId: "",
      problemId: "",
      actions: [],
      date: "",
      feedback: "",
      newSolution: "",
      newFix: "",
      rootCause: "",
      editable: 0,
      loading: false,
      loadingType: "",
      loadingId: ""
    })
  }

  deleteProblem = async (problem) => {
    if (window.confirm(getLabelText("delete_department_prompt"))) {
      this.setLoading(problem.id, "delete")
      try {
        await deleteIssue(problem.id)
        await this.fetchEntities()
        this.stopLoading()
      } catch (error) {
        this.stopLoading()
        displayErrorToast(error)
      }
    }
  }

  clearEditableState = async () => {
    this.setLoading(this.state.problemId, "cancel")
    await this.fetchEntities()
    this.setState({
      customerId: "",
      problemId: "",
      date: "",
      rootCause: "",
      actions: [],
      newSolution: "",
      newFix: "",
      feedback: "",
      editable: 0,
      loading: false,
      loadingType: "",
      loadingId: ""
    })
  }

  canArchive = (actions) => {
    let canArchive = true

    actions.map((action) => {
      if(action.status !== "COMPLETED") {
        canArchive = false
      }

      return true
    })

    return canArchive
  }

  archiveProblem = async (problemId, archived, actions) => {
    if(this.canArchive(actions)) {
      this.setLoading(problemId, "archive")

      await customerSupplierArchive(problemId, !archived, this.props.selectedTeam.id)

      await this.fetchEntities()
      this.stopLoading()
    } else {
      toast.error(getLabelText("archive_issue_error"))
    }
  }

  toggleArchived = () => {
    this.setState({
      archived: !this.state.archived
    })
  }

  toggleRelationshipStatus = async (entity) => {
    const { customers } = this.state
    const customer = customers.filter((customer) => {
      return customer.id === entity.id
    })[0]
    this.setLoading(entity.id, "relationshipStatus")
    try {
      if(customer.teamRelationshipStatuses.length < 1) {
        await createRelationship(this.props.selectedTeam.id, entity.id, false, "customer")
      } else {
        const relationship = customer.teamRelationshipStatuses[0]

        await updateRelationship(relationship.id, !relationship.status)
      }

      await this.fetchEntities()
      this.stopLoading()
    } catch (err) {
      displayErrorToast(err)
      this.stopLoading()
    }

  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleDateChange = date => {
    this.setState({
      date
    })
  }

  editAction = (key, event) => {
    const {actions} = this.state

    actions[key].description = event.target.value

    this.setState({actions})
  }

  nextStep = async (id) => {
    await nextStep(id)

    this.fetchEntities()

  }

  setLoading = (id, type) => {
    this.setState({
      loading: true,
      loadingId: id,
      loadingType: type
    })
  }

  stopLoading = () => {
    this.setState({
      loading: false,
      loadingId: "",
      loadingType: ""
    })
  }

  editDate = (entity) => {
    this.setState({
      editReviewDateId: entity.id,
      editReviewDate: true,
      reviewDate: entity.teamRelationshipStatuses.length > 0 && entity.teamRelationshipStatuses[0].reviewDate ? new Date(entity.teamRelationshipStatuses[0].reviewDate) : new Date()
    })
  }

  cancelReviewDate = () => {
    this.setState({
      editReviewDateId: 0,
      editReviewDate: false,
      reviewDate: ""
    })
  }

  reviewDateChange = date => {
    this.setState({
      reviewDate: date
    })
  }

  saveReviewDate = async (entity) => {
    const { customers, reviewDate } = this.state
    const customer = customers.filter((customer) => {
      return customer.id === entity.id
    })[0]

    this.setLoading(entity.id, "reviewDate")
    try {
      if(customer.teamRelationshipStatuses.length < 1) {
        await createReviewDate(this.props.selectedTeam.id, entity.id, new Date(reviewDate).toISOString(), "customer")
      } else {
        const relationship = customer.teamRelationshipStatuses[0]

        await updateReviewDate(relationship.id, new Date(reviewDate).toISOString())
      }

      await this.fetchEntities()
      this.cancelReviewDate()
      this.stopLoading()
    } catch (err) {
      this.stopLoading()
      displayErrorToast(err)
    }
  }

  uploadAgreement = async (files, entityId) => {
    const { customers } = this.state
    const customer = customers.filter((customer) => {
      return customer.id === entityId
    })[0]
    this.setLoading(entityId, "upload")
    const file = files[0];
    const s3 = new AWS.S3();
    const s3_bucket = 'pre.production.assets.cdi.fluenty.co.za'
    const file_name = file.name + new Date().getTime().toString()
    const file_type = file.type

    // Set up the payload of what we are sending to the S3 api.
    const s3Params = {
      Bucket: s3_bucket,
      Key: file_name,
      Expires: 500,
      ContentType: file_type,
      ACL: 'public-read'
    };

    // Make a request to the S3 API to get a signed URL
    // which we can use to upload our file.
    s3.getSignedUrl('putObject', s3Params, async (err, data) => {
      if(err){
        console.log(err)
      }
      // Data payload of what we are sending back, the url of the signedRequest and a URL
      // where we can access the content after its saved.
      const url = `https://${s3_bucket}.s3.amazonaws.com/`
      const res = {
        signedRequest: data,
        url: url
      };


      // Put the file type in the headers for the upload.
      let options = {
        headers: {
          'Content-Type': file_type
        }
      }
      axios.put(res.signedRequest, file, options)
        .then(async () => {
          try {
            const path = 'https://d32jclxwlibaks.cloudfront.net/' + file_name;
            if(customer.teamRelationshipStatuses.length < 1) {
              await createAgreement(this.props.selectedTeam.id, entityId, path, "customer")
            } else {
              const relationship = customer.teamRelationshipStatuses[0]

              await updateAgreement(relationship.id, path)
            }

            await this.fetchEntities()
            this.cancelReviewDate()
            this.stopLoading()
          } catch (err) {
            this.stopLoading()
            displayErrorToast(err)
          }

        })
        .catch(error => {
          this.stopLoading()
          displayErrorToast(error)
        })

    })

  }

  toggleLock = async (entity) => {
    const { customers } = this.state
    const customer = customers.filter((customer) => {
      return customer.id === entity.id
    })[0]

    this.setLoading(entity.id, "toggleLock")
    try {
      if(customer.teamRelationshipStatuses.length < 1) {
        await createShared(this.props.selectedTeam.id, entity.id, true, "customer")
      } else {
        const relationship = customer.teamRelationshipStatuses[0]

        await updateShared(relationship.id, !relationship.shareOpen)
      }

      await this.fetchEntities()
      this.stopLoading()
    } catch (err) {
      this.stopLoading()
      displayErrorToast(err)
    }
  }

  shareIssue = async (relationshipId, issueId) => {
    this.setLoading(issueId, "sharing")
    try {
      await createRequest(relationshipId, issueId);

      await this.fetchEntities()
      this.stopLoading()
    } catch (err) {
      this.stopLoading()
      displayErrorToast(err)
    }
  }

  postToImprovementIdeas = async (issue, action) => {
    this.setLoading(issue.id, "impIdea")

    action.description = action.description.replaceAll(/"/g, '\\"');

    await createImprovementIdea(this.props.selectedTeam.id, action.description, "", issue.reviewDate, action.id)

    await this.fetchEntities()

    this.setState({
      customerId: "",
      problemId: "",
      actions: [],
      date: "",
      feedback: "",
      newSolution: "",
      newFix: "",
      rootCause: "",
      editable: 0,
      loading: false,
      loadingType: "",
      loadingId: ""
    })
  }

  getCustomerStatus = (customer)=>
  {
    if (customer.teamRelationshipStatuses.length === 0 || customer.teamRelationshipStatuses[0].status) {
      return "BLUE";
    }
    else
    {
      return "RED";
    }
  }

  formattedDate = (date) => {
    let month = date.getMonth() + 1
    let day = date.getDate()
  
    if (month.toString().length < 2)
      month = "0" + month.toString()
  
    if (day.toString().length < 2)
      day = "0" + day.toString()
  
    return day + "/" + month + "/" + date.getFullYear()
  }

  getCustomerReviewDate = (customer) => {
    if (customer.teamRelationshipStatuses.length > 0 && customer.teamRelationshipStatuses[0].reviewDate) {
      return this.formattedDate(new Date(customer.teamRelationshipStatuses[0].reviewDate));
    }
    else {
      return "not_set";
    }
  }

  getQ1Text = (issue) => {
    let actions = issue.actions?.filter(a => a.actionType === 'FEEDBACK');
    let q1Text = '';
    if (actions?.length > 0) {
      actions.forEach(action => {
        q1Text = q1Text  + action.description + ", ";
      });
    }

    return q1Text.replace(/,\s*$/, "");
  }

  getQ3Text = (issue) => {
    let actions = issue.actions?.filter(a => a.actionType === 'ROOT_CAUSE');
    let q3Text = '';
    if (actions?.length > 0) {
      actions.forEach(action => {
        q3Text = q3Text + action.description + ", ";
      });
    }
    return q3Text.replace(/,\s*$/, "");
  }

  saveExcel = async () => {
    const workbook = new Excel.Workbook();
    try {

      const columns = [
        { header: 'Sr. No', key: 'serialNumber' },
        { header: 'Customer Name', key: 'customerName' },
        { header: 'Status', key: 'status' },
        { header: 'PA Review Date', key: 'reviewDate' },
        { header: 'What Happened', key: 'whatHappened' },
        { header: 'Date', key: 'date' },
        { header: 'Is Archived', key: 'isArchived' },
        { header: 'Q1', key: 'q1' },
        { header: 'Q2', key: 'q2' },
        { header: 'Q3', key: 'q3' },
      ];
     
       let teamName;
      if (this.props.selectedTeam.id !== 0) {
        teamName = this.props.selectedTeam.name;
      }
       const fileName = teamName + "_Customers";
      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(workSheetName);

      // add worksheet columns
      // each columns contains header and its mapping key from data
      worksheet.columns = columns;

      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true };

      // loop through all of the columns and set the alignment with width.
      worksheet.columns.forEach(column => {
        column.width = column.header.length + 15;
        column.alignment = { horizontal: 'left' };
      });

      //Prepare data 
      let allCustomers = this.state.customers;
      let allCustomersExport = [];
      allCustomers.forEach((customer, custIndex) => {
        let customerObj = {
          serialNumber: custIndex + 1,
          customerName: customer.name,
          status: this.getCustomerStatus(customer),
          reviewDate: this.getCustomerReviewDate(customer),
        };
        if (customer.assignedIssues.length > 0) {
          customer.assignedIssues.forEach((issue, index) => {
            if (index > 0) {
              customerObj = {
                serialNumber: '',
                customerName: '',
                status: '',
                reviewDate: '',
              };
            }
            else
            {
              customerObj = {
                serialNumber: custIndex + 1,
                customerName: customer.name,
                status: this.getCustomerStatus(customer),
                reviewDate: this.getCustomerReviewDate(customer),
              };
            }
           
            customerObj.whatHappened = issue.whatText;
            customerObj.date = this.formattedDate(new Date(issue.reviewDate));
            customerObj.isArchived = issue.archived ? 'Yes' : 'No';
            customerObj.q1 = this.getQ1Text(issue);
            customerObj.q2 = issue.whyText;
            customerObj.q3 = this.getQ3Text(issue);

            allCustomersExport.push(customerObj);
          })
        }
        else {
          customerObj.whatHappened = '';
          customerObj.date = '';
          customerObj.isArchived = '';
          customerObj.q1 = '';
          customerObj.q2 = '';
          customerObj.q3 = '';

          allCustomersExport.push(customerObj);
        }
      });

      allCustomersExport.forEach(singleData => {
        worksheet.addRow(singleData);
      });

      // loop through all of the rows and set the outline style.
      worksheet.eachRow({ includeEmpty: false }, row => {
        // store each cell to currentCell
        const currentCell = row._cells;

        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach(singleCell => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;

          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        });
      });

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error('Error saving file - ', error.message);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
    }
  };

  render() {
    const {customers, redir} = this.state
    if(redir) return <Redirect to={redir} />
    return (
      <Fragment>
        <SubHeaderComponent>
          <Col className="text-right childenSpace">
            <div className={"refreshBtn"}>
              <RealtimeRefreshComponent
                refresh={this.props.refresh}
                hasNewData={this.props.hasNewData}
                toggleRefresh={this.props.toggleRefresh}
              />
            </div>
          </Col>
          <Button className="ml-1" onClick={(e) => this.saveExcel()}>Export</Button>
        </SubHeaderComponent>
        
        <div className="grey-header-space container-left-minus13">
          <Row>
            <Col className="text-right pr-0">
              <ButtonGroup toggle className="ml-1">
                <ToggleButton
                    type="radio"
                    variant="secondary"
                    name="createType"
                    value="0"
                    checked={!this.state.archived}
                    onChange={() => this.toggleArchived()}
                >
                  {getLabelText("active")}
                </ToggleButton>
                <ToggleButton
                    type="radio"
                    variant="secondary"
                    name="createType"
                    value="1"
                    checked={this.state.archived}
                    onChange={() => this.toggleArchived()}
                >
                  {getLabelText("archived")}
                </ToggleButton>
                
              </ButtonGroup>
            </Col>
          </Row>
          <Row className="mx-auto justify-content-center mt-1 mb-3">
            <Col className="pr-0">
              <EntityAccordion
                  entities={customers}
                  type="customer"
                  problemId={this.state.problemId}
                  editable={this.state.editable}
                  archiveProblem={this.archiveProblem}
                  addProblem={this.addProblem}
                  handleChange={this.handleChange}
                  saveAction={this.saveAction}
                  clearEditableState={this.clearEditableState}
                  newSolution={this.state.newSolution}
                  newFix={this.state.newFix}
                  actions={this.state.actions}
                  saveProblem={this.saveProblem}
                  toggleRelationshipStatus={this.toggleRelationshipStatus}
                  editProblem={this.editProblem}
                  feedback={this.state.feedback}
                  rootCause={this.state.rootCause}
                  date={this.state.date}
                  archived={this.state.archived}
                  handleDateChange={this.handleDateChange}
                  editAction={this.editAction}
                  nextStep={this.nextStep}
                  loading={this.state.loading}
                  loadingType={this.state.loadingType}
                  loadingId={this.state.loadingId}
                  editReviewDateId={this.state.editReviewDateId}
                  editReviewDate={this.state.editReviewDate}
                  reviewDate={this.state.reviewDate}
                  reviewDateChange={this.reviewDateChange}
                  editDate={this.editDate}
                  cancelReviewDate={this.cancelReviewDate}
                  saveReviewDate={this.saveReviewDate}
                  deleteProblem={this.deleteProblem}
                  postToImprovementIdeas={this.postToImprovementIdeas}
                  toggleLock={this.toggleLock}
                  shareIssue={this.shareIssue}
                  teamId={this.props.selectedTeam.id}
                  uploadAgreement={this.uploadAgreement}
                  canEdit={this.checkPermission()}
              />
            </Col>
          </Row>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam,
  teams: state.teamsReducer.teams,
  user: state.userReducer.user
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(CustomersScreen);
