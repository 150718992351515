import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import useOnClickOutside from "use-onclickoutside";
// import { startOfWeek, lastDayOfWeek, format, setISOWeek } from "date-fns";
import { format } from "date-fns";
import { MetricStatuses } from "./resources";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { useCreateOrUpdateMetric, getPeriodValue } from "./hooks";
import LabelComponent from "../../components/utils/getCompanyLabel";

const getLabelText = key => <LabelComponent val={key}/>

const ResultInput = ({ data, goal, onClose, onSubmit, resultDate, monthValues, selectedId, metricTarget, monthList, colour }) => {
  // Initialise CRUD handlers and loading state
  const [createMetric, updateMetric, isLoading] = useCreateOrUpdateMetric();
  // Close on outside click
  const ref = React.useRef(null);
  useOnClickOutside(ref, (event) => {
    if (isLoading) return;
    if (ref.current.parentNode.contains(event.target)) return;
    onClose();
  });
  // Initialise form state
  const [selectedStatus, setSelectedStatus] = useState(data.status);
  const [inputValue, setInputValue] = useState(data.y || "");
  const [commentValue, setCommentValue] = useState(data.comment || "")
  const [targetValue, setTargetValue] = useState(metricTarget);
  const months = [
    {
      index: 0,
      name: "Jan"
    },
    {
      index: 1,
      name: "Feb"
    },
    {
      index: 2,
      name: "Mar"
    },
    {
      index: 3,
      name: "Apr"
    },
    {
      index: 4,
      name: "May"
    },
    {
      index: 5,
      name: "Jun"
    },
    {
      index: 6,
      name: "Jul"
    },
    {
      index: 7,
      name: "Aug"
    },
    {
      index: 8,
      name: "Sep"
    },
    {
      index: 9,
      name: "Oct"
    },
    {
      index: 10,
      name: "Nov"
    },
    {
      index: 11,
      name: "Dec"
    }
  ]

  // Keep form in sync with upstream data
  useEffect(() => {
    setInputValue(data.y);
    setSelectedStatus(data.status);
    setCommentValue(data.comment);
  }, [data.y, data.status, setInputValue, setSelectedStatus, setCommentValue, data.comment, setTargetValue, data.target]);


  const validateInput = () => {

    return true;
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();

    if (!validateInput()) return;
    const baseVariables = {
      value: inputValue !== "" ? parseFloat(inputValue) : null,
      comment: commentValue,
      target: targetValue !== "" ? parseFloat(targetValue) : null,
      status: selectedStatus,
    };

    if (data.id) {
      let period = "MONTH";
      const variables = {
        ...baseVariables,
        id: data.id,
        period: period,
        periodKey: (resultDate.getMonth() + 1) < monthValues[0] ? `${resultDate.getFullYear() - 1}` : `${resultDate.getFullYear()}` ,
        periodValue: getPeriodValue(data.x, monthValues),
      };

      const response = await updateMetric({ variables });

      if (response) {
        onSubmit(response.data.updateCompanyMetric);
        onClose();
      }
    } else {
      let period = "MONTH";

      const variables = {
        ...baseVariables,
        goal: goal.id,
        period: period,
        periodKey: (resultDate.getMonth() + 1) < monthValues[0] ? `${resultDate.getFullYear() - 1}` : `${resultDate.getFullYear()}` ,
        periodValue: getPeriodValue(data.x, monthValues),
        periodStatus: data.periodStatus,
        team: selectedId
      };
      const response = await createMetric({ variables });
      if (response) {
        onSubmit(response.data.createCompanyMetric);
        onClose();
      }
    }
  };

  const onSwitchChange = () => {
    setSelectedStatus((status) => {
      if (status === MetricStatuses.RED.value) return MetricStatuses.BLUE.value;
      return MetricStatuses.RED.value;
    });
  };

  return (
    <div
      ref={ref}
      className="input-result bg-white align-items-center justify-content-around border-thick border-color-grey"
      style={{ width: "500px", height: "400px" }}
    >
      <div className="row mt-5">
        <div className="col-12 text-center">
          <h6 className="font-weight-bold m-0">{getLabelText("result_for")} 
          {" "+ months[monthValues[data.x - 1] - 1].name + " " +
        (monthValues[data.x - 1] >= monthValues[0] ? 
        ( resultDate.getMonth() + 1 >= monthValues[0] ? `${resultDate.getFullYear()}`: `${resultDate.getFullYear() - 1}` ) 
        : (resultDate.getMonth() + 1 >= monthValues[0] ? `${resultDate.getFullYear() + 1}` : `${resultDate.getFullYear()}`))}</h6>
        </div>
      </div>
      <form className="justify-content-center" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
            <div className="row mt-5">
              <div className="col-1"></div>
              <div className="col-10 text-center">
                <textarea className="form-control" rows="5" onChange={(e) => setCommentValue(e.target.value)}>
                  {commentValue}
                </textarea>
              </div>
              <div className="col-1"></div>
            </div>
            <div className="row mt-3">
              <div className="col-1"></div>
              <div className="col-5">
                <label className="form-label bold">{getLabelText("target")}</label>
                <input
                  value={targetValue}
                  disabled={isLoading}
                  type="number"
                  step="0.01"
                  onChange={(e) => {
                    setTargetValue(e.target.value);
                  }}
                  style={{ float: "left" }}
                  className="controls-input w-40 text-center bg-grey border border-color-light-grey"
                />
              </div>
              <div className="col-5">
                <label className="form-label bold">{getLabelText("result")}</label>
                <input
                  value={inputValue}
                  disabled={isLoading}
                  autoFocus={true}
                  type="number"
                  step="0.01"
                  onChange={(e) => {
                    setInputValue(e.target.value);
                  }}
                    style={{ float: "right" }}
                  className="controls-input w-40 text-center bg-grey border border-color-light-grey"
                />
              </div>
              <div className="col-1"></div>
            </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-1"></div>
        <div className="col-7 text-center">
          <div className="d-flex align-items-center text-center" style={{ float: "right" }}>
          <div
              onClick={() => {
                setSelectedStatus(MetricStatuses.BLUE.value);
              }}
              className={colour === "Green" ? "border border-color-light-blue bg-green attendance-cell attendance-date-border" : "border border-color-light-blue bg-blue attendance-cell attendance-date-border"}
            ></div>
            <Switch
              disabled={isLoading}
              checkedIcon={false}
              offColor={"#005e85"}
              onColor={"#005e85"}
              uncheckedIcon={false}
              checked={selectedStatus === MetricStatuses.RED.value}
              onChange={onSwitchChange}
              className="mx-3"
            />
            <div
              onClick={() => {
                setSelectedStatus(MetricStatuses.RED.value);
              }}
              className="border border-color-light-blue bg-danger attendance-cell attendance-date-border"
              ></div>
            </div>
        </div>
        <div className="col-3 text-right">
          <Button
            type="submit"
            disabled={isLoading || !validateInput(true)}
            className="py-1 px-2"
          >
            <FontAwesomeIcon icon={faCheck} />
          </Button>
          <span>&nbsp;</span>
          <Button
            type="button"
            disabled={isLoading || !validateInput(true)}
            className="py-1 px-2"
            onClick={() => {
              onClose()
            }}
          >
            <FontAwesomeIcon icon={faWindowClose} />
          </Button>
        </div>
        <div className="col-1"></div>
      </div>
    </form>
    </div>
  );
};

export default ResultInput;
