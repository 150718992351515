import React, { useState, useEffect } from "react";
import ChartCell from "./ChartCell";
import _ from 'lodash';

const ChartBar = (props) => {
  const [to, setTo] = useState(props.to)
  const [from, setFrom] = useState(props.from)
  const [fromDate, setFromDate] = useState(new Date(props.fromDate))
  const [toDate, setToDate] = useState(new Date(props.toDate))
  const [milestone] = useState(props.milestone)
  const [color] = useState(props.color)

  useEffect(() =>{
    setTo(props.to)
    setFrom(props.from)
    setFromDate(new Date(props.fromDate))
    setToDate(new Date(props.toDate))
  }, [props, setFrom, setTo, setFromDate, setToDate])


  const getState = (index) => {
    const foundState = _.find(milestone.milestoneStates, (currentState) => {
      return currentState.key === index
    })

    return foundState
  }

  const renderBlocks = () => {
    const elements = [];
    const date = new Date();
    const total = 53;
    const years = [date.getFullYear() - 1, date.getFullYear(), date.getFullYear() + 1];
    const startYearIndex = years.indexOf(fromDate.getFullYear());
    const endYearIndex = years.indexOf(toDate.getFullYear());
    const adjustedFrom = from + (total * startYearIndex);
    const adjustedTo = to + (total * endYearIndex);
    const fill = (adjustedTo - adjustedFrom) + 1;

    /// push empty elements
    for (let index = 0; index < adjustedFrom - 1; index++) {
      elements.push(<ChartCell key={index} index={index} color={'white'} />);
    }

    for (let index = 0; index < fill; index++) {
      if (color) {
        elements.push(<ChartCell key={`${props.milestone ? props.milestone.id : 0}-${index.toString()}`} color={color} milestone={milestone} index={index} archived={props.archived} rowIndex={props.rowIndex} getImplementationTrackers={props.getImplementationTrackers} loading={props.loading} />);
      } else {
        elements.push(<ChartCell key={`${props.milestone ? props.milestone.id : 0}-${index.toString()}`} milestone={milestone} index={index} milestoneState={getState(index)} archived={props.archived} rowIndex={props.rowIndex} getImplementationTrackers={props.getImplementationTrackers} loading={props.loading} />);
      }
    }

    for (let index = elements.length; index < (3 * total); index++) {
      elements.push(<ChartCell key={Math.random()} index={index} color={'white'} />);
    }

    return elements;
  }
  return (
    <>
      {renderBlocks()}
    </>
  )
}

export default ChartBar;
