import React, { Component, Fragment } from "react";
import { Table, Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getTeamData, getTeamStats, getStartWeeks } from "../services/TeamDashboardService";
import { getVision } from '../services/GoalService'
import SubHeaderComponent from "../components/SubHeaderComponent";
import RealtimeRefreshComponent from "../components/RealtimeRefreshComponent";
import LabelComponent from "../components/utils/getCompanyLabel";
import { getLoadDetails } from "../components/utils/getLoadDetails";
import {getAllTeamsList, getTeam} from "../services/TeamService";
import MonthYearSelect from "../components/MonthYearSelect";

const getLabelText = key => <LabelComponent val={key}/>

const monthsList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

function getYearList(startMonth, isValues) {
  const array = []
  const index = monthsList.indexOf(startMonth)

  for(let i = index; i < monthsList.length; i++) {
    array.push(isValues ? (i + 1) : monthsList[i])
  }

  for(let i = 0; i < index; i++) {
    array.push(isValues ? (i + 1) : monthsList[i])
  }

  return array
}

class TeamDashboardScreen extends Component {
  state = {
    allTeams: [],
    teamMeasures: [],
    days: [],
    weeks: [],
    stats: [],
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    selectedMonth: ((new Date().getMonth() + 1) + "-" + new Date().getFullYear()),
    loading: true,
    startMonth: "",
    statsLoading: false,
    startWeeks: [],
    period: "",
    teamToUse: null,
    date: new Date(),
    periodKey: new Date().getFullYear().toString(),
    periodValue: (new Date().getMonth() + 1).toString()
  }

  constructor (props) {
    super(props)

    this.props.togglePageLoad(true)
  }

  async loadData(firstLoad) {
    const response = await getAllTeamsList()
    
    this.setState({
      allTeams: response.data.allTeams
    })
    if (!firstLoad) {
      this.props.togglePageLoad(true)
      this.pageLoading(true)
    }
    await this.fetchTeamData()
    await this.fetchTeamStats()
    await this.fetchStartWeeks()
    this.pageLoading(false)
    this.props.togglePageLoad(false)
  }

  async componentDidMount() {
    const initialLoad = getLoadDetails("INITIAL", this.props);
    if (initialLoad) {
      await this.loadData(true);
    }
  }
  async fetchTeamStats () {
    const { selectedMonth, allTeams } = this.state
    const selectedTeam = getLoadDetails("TEAM", {
      ...this.props,
      teams: [
          ...this.props.teams,
          ...allTeams
      ]
    });

    this.setState({ changedTeam: selectedTeam });
    
    const stats = await getTeamStats(selectedTeam.id, selectedMonth)

    this.setState({
      stats: stats.data.team,
    })
  }

  async fetchStartWeeks () {
    const { allTeams } = this.state
    const response = await getStartWeeks()
    let selectedTeam = getLoadDetails("TEAM", {
      ...this.props,
      teams: [
        ...this.props.teams,
        ...allTeams
      ]
    });

    if(!selectedTeam.company) {
      const teamResponse = await getTeam(selectedTeam.id)
      selectedTeam = teamResponse.data.team
    }

    const months = getYearList(selectedTeam.company.finalcialYearEnd, true)
    const period = (new Date().getMonth() + 1) < months[0] ? `${new Date().getFullYear() - 1}${new Date().getFullYear()}` : `${new Date().getFullYear()}${new Date().getFullYear() + 1}`

    this.setState({
      startWeeks: response.data.companyStartWeeks,
      period,
      teamToUse: selectedTeam,
    })
  }

  async fetchTeamData() {
    const { selectedMonth, allTeams } = this.state
    const selectedTeam = getLoadDetails("TEAM", {
      ...this.props,
      teams: [
        ...this.props.teams,
        ...allTeams
      ]
    });
    const response = await getTeamData(selectedTeam.id, selectedMonth)
    const company = await getVision()

    /*
    var filteredTeamMeasurements = response.data.teamMeasurements.filter(
      (obj, index) => {
        return response.data.teamMeasurements.findIndex((item) => item.measurement.id === obj.measurement.id) !== index
      });
    var meargedTeamMeasurements = [];
    filteredTeamMeasurements.forEach((obj, index) => {
      if (response.data.teamMeasurements.some((item) => item.measurement.id === obj.measurement.id && item.dueDate !== obj.dueDate)) {
        var secondObject = response.data.teamMeasurements.find((item) => item.measurement.id === obj.measurement.id && item.dueDate !== obj.dueDate)
        var mergedArray = [];
        obj.dashboardMetricData.forEach((item) => {
          if (secondObject.dashboardMetricData.some(val => val.periodValue === item.periodValue && val.period === item.period && val.status !== "EMPTY")) {
            mergedArray.push(secondObject.dashboardMetricData.find(val => val.periodValue === item.periodValue && val.period === item.period && val.status !== "EMPTY"))
          }
          else {
            mergedArray.push(item)
          }
        })
        obj.dashboardMetricData = mergedArray;
        meargedTeamMeasurements.push(obj);
      }
      else {
        meargedTeamMeasurements.push(obj);
      }
    }
    );
    */


    this.setState({
      teamMeasures: response.data.teamMeasurements,
      days: this.getAllWorkingDays(response.data.teamMeasurements),
      weeks: this.getAllWorkingWeeks(response.data.teamMeasurements),
      months: this.getAllWorkingMonths(response.data.teamMeasurements),
      startMonth: company.data.company.finalcialYearEnd
    })
  }

  async componentDidUpdate(prevProps) {
    const updateLoad = getLoadDetails("UPDATE", this.props, prevProps);
    if(updateLoad) {
      await this.loadData(false);
    }
  }

  pageLoading = loading => {
    this.setState({ loading })
  }

  statsLoading = loading => {
    this.setState({ statsLoading: loading })
  }


    getOptions = () => {
      const {startMonth, months} = this.state
      let array = []
      const date = new Date()
      const currentMonth = date.getMonth()
      const fiscalStartMonth = months.findIndex(month => startMonth === month)

      for(let i = fiscalStartMonth; i < months.length; i++) {
        const value = new Date((currentMonth < fiscalStartMonth ? date.getFullYear() - 1 : date.getFullYear()), (i + 1), 0)
        array.push({
          value: value.getMonth() + "-" + value.getFullYear(),
          text: months[i] + " " + value.getFullYear()
        })
      }

      for(let i = 0; i < fiscalStartMonth; i++) {
        const value = new Date((currentMonth < fiscalStartMonth ? date.getFullYear() : date.getFullYear() + 1), (i + 1), 0)
        array.push({
          value: value.getMonth() + "-" + value.getFullYear(),
          text: months[i] + " " + value.getFullYear()
        })
      }
      return array

    }

  getAllWorkingDays = (teamMeasures) => {

    let days = []

    teamMeasures.map((teamMeasure) => {
      teamMeasure.dashboardMetricData.map((data) => {
        if(data.period === "DAY" && !days.includes(data.periodValue)) {
          days.push(data.periodValue)
        }

        return true
      })
      return true
    })

   /*  days.sort(function(a, b) {
      return a - b;
    })  */

    return days
  }

  getAllWorkingMonths = (teamMeasures) => {

    let months = []

    teamMeasures.map((teamMeasure) => {
      teamMeasure.dashboardMetricData.map((data) => {
        if(data.period === "MONTH" && !months.includes(data.periodValue)) {
          months.push(data.periodValue)
        }
        return true
      })
      return true
    })
    return months
  }

  getAllWorkingWeeks = (teamMeasures) => {

    let weeks = [];
    teamMeasures.map((teamMeasure) => {
      teamMeasure.dashboardMetricData.map((data) => {
        if(data.period === "WEEK" && !weeks.includes(data.periodValue)) {
          weeks.push(data.periodValue)
        }

        return true
      })

      return true
    })

    weeks.sort(function(a, b) {
      return a - b;
    })
    let newWeeks = []
    if(weeks.includes(1)) {
      weeks.map(week => {
        if(week > 40) {
          newWeeks.push(week)
        }

        return true
      })

      weeks.map(week => {
        if(week < 40) {
          newWeeks.push(week)
        }

        return true
      })

      weeks = newWeeks
    }

    return weeks
  }

  setSelectedMonth = month => {
    this.setState({
      selectedMonth: month
    }, async () => {
      this.statsLoading(true)
      await this.fetchTeamStats()
      this.statsLoading(false)
    })
  }

  displayMetricCircle = (periodValue, metricData, period) => {
    const metric = metricData.find(data => data.periodValue === periodValue && data.period === period)
    let response = (<span className="chk chk-black mx-auto"></span>)
    if(typeof metric !== "undefined") {
      switch(metric.status) {
        case "RED":
          response = (<span className="chk chk-red mx-auto"></span>)

          break
        case "BLUE":
          response = (<span className="chk chk-blue color-selected mx-auto"></span>)

          break
        case "RO":
          response = (<span className="chk chk-red-outline mx-auto"></span>)

          break

        default:
          response = (<span></span>)

          break
      }
    }

    return response
  }

  getCalculatedDisplayWeek = week => {
    const { period, startWeeks } = this.state;
    const startWeek = startWeeks.find(startWeek => startWeek.period === period)

    if(!startWeek) return week

    if(week < startWeek.week) {
      return 52 - (startWeek.week - week) + 1
    } else {
      return week - startWeek.week + 1
    }
  }

  changeMonth = month => {
    let year = this.state.periodKey;
    let stateDate = this.state.date;
    this.setState({
      periodValue: parseInt(month),
      date: new Date(year, parseInt(month) - 1, stateDate.getDate()),
      selectedMonth: parseInt(month) + "-" + parseInt(year)
    }, async () => {
      this.statsLoading(true)
      await this.fetchTeamStats()
      this.statsLoading(false)
    })
  }

  changeYear = year => {
    let month = this.state.periodValue;
    let stateDate = this.state.date;
    this.setState({
      periodKey: parseInt(year),
      date: new Date(parseInt(year), parseInt(month) - 1, stateDate.getDate()),
      selectedMonth: parseInt(month) + "-" + parseInt(year)
    }, async () => {
      this.statsLoading(true)
      await this.fetchTeamStats()
      this.statsLoading(false)
    })
  }

  render() {
    const { teamMeasures, days, weeks, months, stats, loading, statsLoading } = this.state
    let fillerDailyTDWidth = 65;
    let fillerWeeklyTDWidth = 65;
    let fillerMonthlyTDWidth = 65;
    return (
      <Fragment>
        <SubHeaderComponent changedTeam={this.state.changedTeam}>
            <Col className="text-right childenSpace">
              <div className={"refreshBtn"}>
                <RealtimeRefreshComponent
                  refresh={this.props.refresh}
                  hasNewData={this.props.hasNewData}
                  toggleRefresh={this.props.toggleRefresh}
                />
              </div>
            </Col>
        </SubHeaderComponent>
        <div className="grey-header-space">
          {teamMeasures.filter(measurement => measurement.frequency === "Daily").length > 0
              ? (
                  <Row className="scroller-lng-tbl">
                    <Col>
                      <Table className="team-dashboard-tbl" width="100%">
                        <thead>
                        <tr className="table-heading-text table-heading-background">
                          <th colSpan={3 + days.length}>
                            {getLabelText("daily_measurements")}
                          </th>
                        </tr>
                        <tr>
                          <th width="10%">{getLabelText("category")}</th>
                          <th width="20%">{getLabelText("measurement")}</th>
                          <th width="5%">{getLabelText("target")}</th>
                          {days.map((day, key) => {
                            fillerDailyTDWidth = fillerDailyTDWidth - 3
                            return (
                                <th width="3%" key={key}>
                                  <div className="team-dashboard-header-cell">
                                    {day}
                                  </div>
                                </th>
                            )
                          })}
                          <th width={fillerDailyTDWidth + "%"}></th>
                        </tr>
                        </thead>
                        <tbody>
                        {loading
                            ? (
                                <tr>
                                  <td colSpan={3 + days.length} className="text-center">
                                    <FontAwesomeIcon icon={faSpinner} className="fa-spin fa-2x m-auto"></FontAwesomeIcon>
                                  </td>
                                </tr>
                            ) : (
                                teamMeasures.map((teamMeasurement, key) => {
                                  if(teamMeasurement.frequency === "Daily") {
                                    return (
                                        <tr key={key}>
                                          <td width="10%">{teamMeasurement.measurement.goalCategory.name}</td>
                                          <td width="20%">{teamMeasurement.measurement.measurement}</td>
                                          <td width="5%">{teamMeasurement.numberTo}</td>
                                          {days.map((day, key) => {
                                            return (
                                                <td width="3%" key={key}>
                                                  {this.displayMetricCircle(day, teamMeasurement.dashboardMetricData, "DAY")}
                                                </td>
                                            )
                                          })}
                                          <td width={fillerDailyTDWidth + "%"}></td>
                                        </tr>
                                    )
                                  }

                                  return null
                                })
                            )
                        }
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
              ) : null
          }
          {teamMeasures.filter(measurement => measurement.frequency === "Weekly").length > 0
              ? (
                  <Row className="scroller-lng-tbl">
                    <Col>
                      <Table className="team-dashboard-tbl" width="100%">
                        <thead>
                        <tr className="table-heading-text table-heading-background">
                          <th colSpan={3 + weeks.length}>
                            {getLabelText("weekly_measurements")}
                          </th>
                        </tr>
                        <tr>
                          <th width="10%">{getLabelText("category")}</th>
                          <th width="20%">{getLabelText("measurement")}</th>
                          <th width="5%">{getLabelText("target")}</th>
                          {weeks.map((week, key) => {
                            fillerWeeklyTDWidth = fillerWeeklyTDWidth - 3
                            return (
                                <th width="3%" key={key}>
                                  <div className="team-dashboard-header-cell">
                                    {this.getCalculatedDisplayWeek(week)}
                                  </div>
                                </th>
                            )
                          })}
                          <th width={fillerWeeklyTDWidth + "%"}></th>
                        </tr>
                        </thead>
                        <tbody>
                        {loading ?
                            (
                                <tr>
                                  <td colSpan={3 + weeks.length} className="text-center">
                                    <FontAwesomeIcon icon={faSpinner} className="fa-spin fa-2x m-auto"></FontAwesomeIcon>
                                  </td>
                                </tr>
                            ) : (teamMeasures.map((teamMeasurement, key) => {
                                  if(teamMeasurement.frequency === "Weekly") {
                                    return (
                                        <tr key={key}>
                                          <td width="10%">{teamMeasurement.measurement.goalCategory.name}</td>
                                          <td width="20%">{teamMeasurement.measurement.measurement}</td>
                                          <td width="5%">{teamMeasurement.numberTo}</td>
                                          {weeks.map((week, key) => {
                                            return (
                                                <td width="3%" key={key}>
                                                  {this.displayMetricCircle(week, teamMeasurement.dashboardMetricData, "WEEK")}
                                                </td>
                                            )
                                          })}
                                          <td width={fillerWeeklyTDWidth + "%"}></td>
                                        </tr>
                                    )
                                  }

                                  return null
                                })
                            )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
              ) : null
          }
          {teamMeasures.filter(measurement => measurement.frequency === "Monthly").length > 0
              ? (
                  <Row className="scroller-lng-tbl">
                    <Col>
                      <Table className="team-dashboard-tbl" width="100%">
                        <thead>
                        <tr className="table-heading-text table-heading-background">
                          <th colSpan={3 + weeks.length}>
                            {getLabelText("monthly_measurements")}
                          </th>
                        </tr>
                        <tr>
                          <th width="10%">{getLabelText("category")}</th>
                          <th width="20%">{getLabelText("measurement")}</th>
                          <th width="5%">{getLabelText("target")}</th>
                          {months.map((month, key) => {
                            fillerMonthlyTDWidth = fillerMonthlyTDWidth - 3
                            return (
                                <th width="3%" key={key}>
                                  <div className="team-dashboard-header-cell">
                                    {(monthsList[parseInt(month) - 1])?.toString()?.substring(0, 1)}
                                  </div>
                                </th>
                            )
                          })}
                        <th width={fillerMonthlyTDWidth + "%"}></th>
                        </tr>
                        </thead>
                        <tbody>
                        {loading ?
                            (
                                <tr>
                                  <td colSpan={3 + months.length} className="text-center">
                                    <FontAwesomeIcon icon={faSpinner} className="fa-spin fa-2x m-auto"></FontAwesomeIcon>
                                  </td>
                                </tr>
                            ) : (teamMeasures.map((teamMeasurement, key) => {
                                  if(teamMeasurement.frequency === "Monthly") {
                                    return (
                                        <tr key={key}>
                                          <td width="10%">{teamMeasurement.measurement.goalCategory.name}</td>
                                          <td width="20%">{teamMeasurement.measurement.measurement}</td>
                                          <td width="5%">{teamMeasurement.numberTo}</td>
                                          {months.map((month, key) => {
                                            return (
                                                <td width="3%" key={key}>
                                                  {this.displayMetricCircle(month, teamMeasurement.dashboardMetricData, "MONTH")}
                                                </td>
                                            )
                                          })}
                                          <td width={fillerWeeklyTDWidth + "%"}></td>
                                        </tr>
                                    )
                                  }

                                  return null
                                })
                            )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
              ) : null
          }

          <hr />

          <Row className="scroller-lng-tbl">
            <Col xl={3} lg={3} md={6} sm={12} xs={12} className="mt-2">
              <div className="form-group mt-3">
              <MonthYearSelect date={this.state.date} onMonthChange={(e) => this.changeMonth(e)} onYearChange={ (e) => this.changeYear(e)}
               selectedYear={this.state.periodKey} selectedMonth={this.state.periodValue}/>
              </div>
            </Col>
          </Row>
          <Row className="mt-2 mb-5 scroller column-tbl">
            <Col>
              <Table className="team-stats-widget">
                <thead>
                <tr className="table-heading-text table-heading-background">
                  <th colSpan="3">
                    {getLabelText("problem_solving_screen")}
                  </th>
                </tr>
                <tr>
                  <th>
                  </th>
                  <th>
                    {getLabelText("performance_triggers")}
                  </th>
                  <th>
                    3Qs
                  </th>
                </tr>
                </thead>
                <tbody>
                {loading || statsLoading
                    ? (
                        <tr>
                          <td colSpan="3" className="text-center">
                            <FontAwesomeIcon icon={faSpinner} className="fa-spin fa-2x m-auto"></FontAwesomeIcon>
                          </td>
                        </tr>
                    ) : (
                        <Fragment>
                          <tr>
                            <td>
                              {getLabelText("month")}
                            </td>
                            <td>{stats.triggerMonthCount}</td>
                            <td>{stats.problemSolvingMonthCount}</td>
                          </tr>
                          <tr>
                            <td>
                              {getLabelText("ytd")}
                            </td>
                            <td>{stats.triggerYearCount}</td>
                            <td>{stats.problemSolvingYearCount}</td>
                          </tr>
                        </Fragment>
                    )
                }
                </tbody>
              </Table>
            </Col>
            <Col>
              <Table className="team-stats-widget">
                <thead>
                <tr className="table-heading-text table-heading-background">
                  <th colSpan="3">
                    {getLabelText("impr_ideas_screen")}
                  </th>
                </tr>
                <tr>
                  <th>
                  </th>
                  <th>
                    {getLabelText("raised")}
                  </th>
                  <th>
                    {getLabelText("closed")}
                  </th>
                </tr>
                </thead>
                <tbody>
                {loading || statsLoading ?
                    (
                        <tr>
                          <td colSpan="3" className="text-center">
                            <FontAwesomeIcon icon={faSpinner} className="fa-spin fa-2x m-auto"></FontAwesomeIcon>
                          </td>
                        </tr>
                    ) : (
                        <Fragment>
                          <tr>
                            <td>
                              {getLabelText("month")}
                            </td>
                            <td>{stats.innovationMonthCount}</td>
                            <td>{stats.innovationMonthClosedCount}</td>
                          </tr>
                          <tr>
                            <td>
                              {getLabelText("ytd")}
                            </td>
                            <td>{stats.innovationYearCount}</td>
                            <td>{stats.innovationYearClosedCount}</td>
                          </tr>
                        </Fragment>
                    )
                }
                </tbody>
              </Table>
            </Col>
            <Col>
              <Table className="team-stats-widget">
                <thead>
                <tr className="table-heading-text table-heading-background">
                  <th colSpan="4">
                    {getLabelText("next_steps_screen")}
                  </th>
                </tr>
                <tr>
                  <th>
                  </th>
                  <th>
                    {getLabelText("raised")}
                  </th>
                  <th>
                    {getLabelText("completed_on_time")}
                  </th>
                  <th>
                    {getLabelText("completed_late")}
                  </th>
                </tr>
                </thead>
                <tbody>
                {loading || statsLoading
                    ? (
                        <tr>
                          <td colSpan="4" className="text-center">
                            <FontAwesomeIcon icon={faSpinner} className="fa-spin fa-2x m-auto"></FontAwesomeIcon>
                          </td>
                        </tr>
                    ) : (
                        <Fragment>
                          <tr>
                            <td>
                              {getLabelText("month")}
                            </td>
                            <td>{stats.nextStepsMonthCount}</td>
                            <td>{stats.nextStepsMonthOnTimeCount}</td>
                            <td>{stats.nextStepsMonthLateCount}</td>
                          </tr>
                          <tr>
                            <td>
                              {getLabelText("ytd")}
                            </td>
                            <td>{stats.nextStepsYearCount}</td>
                            <td>{stats.nextStepsYearOnTimeCount}</td>
                            <td>{stats.nextStepsYearLateCount}</td>
                          </tr>
                        </Fragment>
                    )
                }
              </tbody>
            </Table>
          </Col>
        </Row>
        <div
            style={{ bottom: (this.props.presentation.active && !this.props.presentation.paused) ? "116px" : "58px" }}
            className="bg-light-grey d-flex p-2 justify-content-around fixed-bottom legend-bottom"
        >
          <div className="td-legend">
            <span className={"d-inline-block chk chk-lg chk-blue color-selected mr-2"}></span>
            <div>{getLabelText("target_achieved")}</div>
          </div>
          <div className="td-legend">
            <span className={"d-inline-block chk chk-lg chk-red-outline mr-2"}></span>
            <div>{getLabelText("target_not_achieved")}</div>
          </div>
          <div className="td-legend">
              <span className={"d-inline-block chk chk-lg chk-red mr-2"}></span>
              <div>{getLabelText("trigger_activated")}</div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}
const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam,
  presentation: state.presentationReducer,
  teams: state.teamsReducer.teams
})

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TeamDashboardScreen)
