export const formatLargeNumber = (number) => {
    if (number >= 1000)
    {
        const formattedNumber = (number / 1000).toFixed(1);

        const formattedNumberStr = formattedNumber.replace(/\.0$/, '');

        return formattedNumberStr.replace('.', '.') + 'k';
    }
    else
    {
        return number?.toString();
    }
}