import React from "react";
import { Col } from "react-bootstrap";
import moment from "moment/moment";

const MonthYearSelect = ({
  date,
  onYearChange,
  onMonthChange,
  selectedYear,
  selectedMonth,
  containerClass = "",
}) => {

  const years = [];
  const months = [];

  for (let i = 2018; i <= new Date().getFullYear() + 1; i++) {
    years.push({ key: i, value: i });
  }

  for (let i = 1; i <= 12; i++) {
    months.push({ key: i, value: i + "-" + moment(new Date(date.getFullYear(), i - 1, 1)).format("MMM") });
  }

  return (
    <Col className={`no-select d-flex align-items-center ${containerClass}`}>
      <table className="w-100">
        <tbody>
          <tr>
            <td>
              <div>
                <h6 className="app-title">Select Year</h6>
                <select className="form-control" value={selectedYear} onChange={(e) => onYearChange(e.target.value)}>
                  {years.map((year, i) => (
                    <option value={year.key} key={i}>{year.value}</option>
                  ))}
                </select>
              </div>

            </td>
            <td>
              <div>
                <h6 className="app-title">Select Month</h6>
                <select className="form-control" value={selectedMonth} onChange={(e) => onMonthChange(e.target.value)}>
                  {months.map((month, i) => (
                    <option value={month.key} key={i}>{month.value}</option>
                  ))}
                </select>
              </div>

            </td>
          </tr>
        </tbody>
      </table>
    </Col>
  );
};

export default MonthYearSelect;
