import * as XLSX from "xlsx";
import Excel from "exceljs";
import { saveAs } from 'file-saver';

export const downloadCSV = (records, fileName) =>
{
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let downloadLink = document.createElement("a")
    const ws = XLSX.utils.json_to_sheet(records.map(att => {
        delete att.__typename
        return att
    }))

    const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
    const data = new Blob([excelBuffer], {type: fileType});
    let url = URL.createObjectURL(data);

    downloadLink.href = url;
    downloadLink.download = `${new Date()} - ${fileName}.xlsx`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

export const downloadXLSX = async (rows, fileName, workSheetName = "Sheet", colorColumn1 = {}, colorColumn2 = {}, multi = {}) =>
{
    if(!rows.length) return;

    const workbook = new Excel.Workbook();

    const xlsxFileName = `${fileName} - ${(new Date()).toLocaleDateString()}.xlsx`;

    const worksheet = workbook.addWorksheet(workSheetName);

    let multiRecords = [];
    if(multi.key)
    {
        multiRecords = rows[0][multi.key];
        delete rows[0][multi.key];
    }

    const columns = [];
    Object.keys(rows[0]).forEach(e => {
        columns.push({
            header: e,
            key: e
        })
    })

    let subColumns = [];
    if(multiRecords.length)
    {
        Object.keys(multiRecords[0]).forEach(e => {
            subColumns.push(e);
        })
    }

    worksheet.columns = columns;

    worksheet.getRow(1).font = { bold: true };

    worksheet.columns.forEach(column => {
        column.alignment = { horizontal: 'left' };
    });

    rows.forEach((ele) => {
        worksheet.addRow(ele);
        worksheet.lastRow.eachCell(function (cell, colNumber)
        {
            if(colNumber === colorColumn1.index)
            {
                let color = getColorCodeByValue(colorColumn1.key, cell.value);
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: color.bg }
                };
                cell.font = {
                    color: { argb: color.color }
                };
            }
            if(colNumber === colorColumn2.index)
            {
                let color = getColorCodeByValue(colorColumn2.key, cell.value);
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: color.bg }
                };
                cell.font = {
                    color: { argb: color.color }
                };
            }
        })
    })

    if(multi.key)
    {
        worksheet.addRow(subColumns);

        worksheet.lastRow.font = { bold: true };

        multiRecords.forEach((rec) => {
            let values = [];
            Object.values(rec).forEach(e => {
                values.push(e);
            })
            worksheet.addRow(values);
            worksheet.lastRow.eachCell(function (cell, colNumber) {
                if (colNumber === colorColumn1.index) {
                    let color = getColorCodeByValue(colorColumn1.key, cell.value);
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {argb: color.bg}
                    };
                    cell.font = {
                        color: {argb: color.color}
                    };
                }
            })
        })
    }

    worksheet.eachRow(row => {
        row.eachCell(cell => {
            cell.alignment = { vertical: "bottom" };
            cell.border = {
                left: { style: "thin" },
                right: { style: "thin" },
                top: { style: "thin" },
                bottom: { style: "thin" }
            };
        })
    })

    const buf = await workbook.xlsx.writeBuffer();

    saveAs(new Blob([buf]), xlsxFileName);
}

export const downloadWithMultipleSheetXLSX = async (data, fileName) =>
{
    if(!Object.keys(data).length) return;

    const workbook = new Excel.Workbook();

    const xlsxFileName = `${fileName} - ${(new Date()).toLocaleDateString()}.xlsx`;

    Object.keys(data).map((key, idx) => {
        const worksheet = workbook.addWorksheet(`${key.substring(0, 24)}_${idx + 1}`);

        const info = data[key];

        const columns = [];
        Object.keys(info[0]).forEach(e => {
            columns.push({
                header: e,
                key: e
            })
        })

        worksheet.columns = columns;
        worksheet.columns.forEach(column => {
            column.alignment = { horizontal: 'left' };
        });

        worksheet.getRow(1).font = { bold: true };

        worksheet.lastRow.eachCell(cell => {
            if(info[0][cell.value])
            {
                if(info[0][cell.value].status === 'TRIGGER')
                {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {argb: "fdc3545"}
                    };
                    cell.font = {
                        color: {argb: "fffffff"}
                    }
                }
                else if(info[0][cell.value].status === 'NON_WORKING')
                {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {argb: "ff343a40"}
                    };
                    cell.font = {
                        color: {argb: "fffffff"}
                    }
                }
            }
        })

        info.forEach((ele) => {
            worksheet.addRow(Object.values(ele).map(e => e.value));

            worksheet.lastRow.eachCell((cell, colNumber) => {
                if(colNumber > 2)
                {
                    let color = getColorCodeByValue(fileName, cell.value);
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: color.bg }
                    };
                    cell.font = {
                        color: { argb: color.color }
                    };
                }
            })
        })

        worksheet.eachRow(row => {
            row.eachCell(cell => {
                cell.alignment = { vertical: "bottom" };
                cell.border = {
                    left: { style: "thin" },
                    right: { style: "thin" },
                    top: { style: "thin" },
                    bottom: { style: "thin" }
                };
            })
        })
    })

    const buf = await workbook.xlsx.writeBuffer();

    saveAs(new Blob([buf]), xlsxFileName);
}

const getColorCodeByValue = (exp, value) => {
    switch (exp)
    {
        case "Approval_Status":
            switch (value)
            {
                case "APPROVED":
                    return { bg: "f005e85", color: "ffffff" };
                case "DECLINED":
                    return {bg: "ffdc3545", color: "ffffff" };
                case "NONE":
                    return {bg: "ffd9d9d9", color: "00000" };
                default:
                    return { bg: "fffffff", color: "000000" };
            }
        case "Next_Step":
            switch (value)
            {
                case "Action Completed":
                    return { bg: "f005e85", color: "ffffff" };
                case "Action Not Posted":
                    return {bg: "ffd9d9d9", color: "000000" };
                case "Action In Progress":
                    return { bg: "fffffff", color: "005e85" }
                case "None":
                    return {bg: "fffffff", color: "00000" };
                default:
                    return { bg: "fffffff", color: "000000" };
            }
        case "Coaching_Review":
            switch (value)
            {
                case 0:
                    return {bg: "f7f7f7f", color: "fffffff"}
                case 1:
                    return {bg: "fdc3545", color: "fffffff"}
                case 2:
                    return {bg: "fed7d31", color: "fffffff"}
                case 3:
                    return {bg: "f70ad47", color: "fffffff"}
                case 4:
                    return {bg: "fd9d9d9", color: "0000000"}
                case 5:
                    return {bg: "fffff00", color: "0000000"}
                default:
                    return {bg: "fffffff", color: "0000000"}
            }
        default:
            return { bg: "fffffff", color: "000000" };
    }
}
