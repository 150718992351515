import { gql } from "apollo-boost";
import { client } from "./BaseService";
// import gqlConditionalString from "../components/utils/gqlConditionalString";

const getSkills = async () => {
  return await client.query({
    query: gql`query {
      company {
        skills {
          id
          name
          hasTeams
        }
      }
    }`
  })
}


const createSkill = async ({ name }) => {
  return await client.mutate({
    mutation: gql`mutation {
      createSkill(
       name: "${name}"
     ) {
      id
      name
      }
    }`
  })
}

const deleteSkill = async (id) => {
  return await client.mutate({
    mutation: gql`mutation {
      deleteSkill(id:"${id}") {
         id
      }
    }
    `
  })
}

const getSkill = async (id) => {
  return await client.query({
    query: gql`query {
      skill (id: "${id}") {
        id
        name
      }
    }`
  })
}

const updateSkill = async (id, skill) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateSkill (
        id: "${id}"
        name: "${skill.name}"
      ) {
        id
        name
      }
    }
    `
  })
}

export {
  getSkills,
  getSkill,
  deleteSkill,
  createSkill,
  updateSkill
}
