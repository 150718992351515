import { gql } from "@apollo/client";

export const TEAM_WITH_EVENTS_QUERY = gql`
  query TeamsWithEvents($id: ID!) {
    team(id: $id) {
      id
      events {
        id
        title
        date
      }
    }
  }
`;

export const SCHEDULED_EVENTS_QUERY = gql`
  query TeamsWithEvents($id: ID!) {
    getScheduledEvents(id: $id) {
      id 
      date 
      title
      status
      isShared
      actionId
      actionArchived
      sharedStatus
      editable
      colour {
        id
        bgColour
        fgColour
      }
    }
  }
`;


export const CREATE_EVENT_MUTATION = gql`
  mutation createEvent (
    $team: String!
    $title:String!
    $date:String!
    $colour: ID!
    $isShared: Boolean!
    )
    {
      createScheduledEvent(team:$team, title:$title, date:$date, colour:$colour, isShared: $isShared)
      {
        id
        title
        date
      }
    }
`;

export const UPDATE_EVENT_MUTATION = gql`
  mutation updateEvent (
    $id: ID!
    $title:String!
    $date:String!
    $colour: ID!
    )
    {
      updateScheduledEvent(id:$id, title:$title, date:$date, colour: $colour)
      {
        id
        title
        date
      }
    }
`;

export const DELETE_EVENT_MUTATION = gql`
  mutation deleteEvent (
    $id: ID!
    $isShared: Boolean!
    )
    {
      deleteScheduledEvent(id:$id, isShared: $isShared)
      {
        id
      }
    }
`;

export const COLOURS_QUERY = gql`
  query {
    colours {
      id
      name
      bgColour
      fgColour
    }
  }
`;

export const ACTION_SHARED_EVENT = gql`
  mutation actionScheduledEvent (
    $id: ID!
    $status: SharedScheduledEventStatus!
  )
  {
    actionScheduledEvent(id:$id, status: $status)
    {
      id
    }
  }
`;