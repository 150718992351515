import { client } from "./BaseService";
import { gql } from "apollo-boost";

export const createUserLog = async(teamId, userId, standInId, url) => {
  return await client.mutate({
    mutation: gql`mutation {
      createAssignedUserLog(
        teamId: "${teamId}",
        userId: "${userId ? userId : ''}",
        standInId: "${standInId ? standInId : ''}",
        url: "${url}"
      )
      {
        id
        url
        user {
          id
        }
        standIn {
          id
        }
      }
    }`
  })
}

export const updateUserLog = async(id, userId, standInId) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateAssignedUserLog(
        id: "${id}",
        userId: "${userId ? userId : ''}",
        standInId: "${standInId ? standInId : ''}",
      )
      {
        id
        url
        user {
          id
        }
        standIn {
          id
        }
      }
    }`
  })
}
