import React, { useState, useEffect, useCallback } from "react";
import Modal from 'react-modal';
import { PERIOD_FREQUENCIES, frequencyProps, days, weeks } from "../resources/enums";
import { CREATE_TEAM_LEADERSHIP_ROUTINES_MUTATION, UPDATE_TEAM_LEADERSHIP_ROUTINES_MUTATION } from "../resources/gql";
import { useMutation } from "@apollo/react-hooks";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import displayErrorToast from "../../../components/utils/displayErrorToast";
import LabelComponent from "../../../components/utils/getCompanyLabel";

const getLabelText = key => <LabelComponent val={key}/>

const RoutineModal = ({ users, routines, selectedRoutine, teamRoutines, setTeamRoutines, showModal, setShowModal, teamId, getText }) => {
  const [id, setId] = useState(null)
  const [selectedLeadershipRoutine, setSelectedLeadershipRoutine] = useState(null)
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedFrequency, setSelectedFrequency] = useState(null)
  const [selectedDay, setSelectedDay] = useState([])
  const [selectedWeek, setSelectedWeek] = useState([])
  const [loading, setLoading] = useState(false)

  const onError = (error) => {
    displayErrorToast(error);
  };

  const reset = useCallback(
    () => {
      setId(null);
      setSelectedUser(null);
      setSelectedFrequency(null);
      setSelectedLeadershipRoutine(null);
      setSelectedDay([]);
      setSelectedWeek([]);
      setLoading(false);
    },
    [setId, setSelectedUser, setSelectedFrequency, setSelectedLeadershipRoutine, setSelectedDay, setSelectedWeek, setLoading]
  );

  useEffect(() => {
    if(!selectedRoutine) return;
    setId(selectedRoutine.id);
    setSelectedUser(selectedRoutine.user.id);
    setSelectedFrequency(selectedRoutine.frequency);
    setSelectedLeadershipRoutine(selectedRoutine.routine.id);
    setSelectedDay(selectedRoutine.days && selectedRoutine.days !== "" ? selectedRoutine.days.split(",") : []);
    setSelectedWeek(selectedRoutine.weeks && selectedRoutine.weeks !== "" ? selectedRoutine.weeks.split(",") : []);
  }, [selectedRoutine, users, setSelectedUser, setSelectedFrequency, setSelectedLeadershipRoutine, setId, setSelectedDay, setSelectedWeek]);

  const close = useCallback(
    () => {
      reset();
      setShowModal(false);
    },
    [reset, setShowModal]
  );

  const onCompleted = useCallback(
    (data) => {
      const payload = data.createTeamLeadershipRoutine ? {...data.createTeamLeadershipRoutine, metrics: []} : {...data.updateTeamLeadershipRoutine, metrics: selectedRoutine.metrics};
      setTeamRoutines(data.createTeamLeadershipRoutine ? [...teamRoutines, payload] : teamRoutines.map(routine => routine.id === payload.id ? payload : routine));
      close();
    },
    [teamRoutines, setTeamRoutines, close, selectedRoutine]
  );

  const [createRoutine] = useMutation(CREATE_TEAM_LEADERSHIP_ROUTINES_MUTATION, {
    onCompleted,
    onError
  });

  const [updateRoutine] = useMutation(UPDATE_TEAM_LEADERSHIP_ROUTINES_MUTATION, {
    onCompleted,
    onError
  });

  const onSave = () => {
    setLoading(true);

    if(id) return updateRoutine({
      variables: {
        id,
        userId: selectedUser
      }
    });

    createRoutine({
      variables: {
        teamId,
        routineId: selectedLeadershipRoutine,
        userId: selectedUser,
        frequency: selectedFrequency,
        weeks: selectedWeek.join(","),
        days: selectedDay.join(",")
      }
    });
  };

  const isValid = () => {
    let valid = true;

    if(selectedFrequency !== "DAY") {
      if(!selectedDay.length) valid = false;
      if(selectedFrequency === "MONTH" && !selectedWeek.length) valid = false;
    }
    if(!selectedUser) valid = false;
    if(!selectedFrequency) valid = false;
    if(!selectedLeadershipRoutine) valid = false;

    return valid;
  };

  const changeFrequency = (frequency) => {
    setSelectedFrequency(frequency)

    if(frequency !== "MONTH") setSelectedWeek([])
    if(frequency === "DAY") setSelectedDay([])
  }
  const toggleDaySelect = day => {
    if (selectedDay.includes(day)) return setSelectedDay(selectedDay.filter(d => d !== day));

    return setSelectedDay([...selectedDay, day]);
  }

  const toggleWeekSelect = week => {
    if (selectedWeek.includes(week)) return setSelectedWeek(selectedWeek.filter(wk => wk !== week));

    return setSelectedWeek([...selectedWeek, week]);
  }

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => setShowModal(false)}
      contentLabel="Result"
      className="standard-modal"
    >
      <div className="row mb-2">
        <div className="col-12 text-center">
          <h6 className="cdi-blue-txt ml-0 mb-1">{getLabelText("leadership_routine")}</h6>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-12 text-center">
          <select
            className={"form-control cdi-ddl w-100 ml-0" + (id ? " disabled" : "")}
            value={selectedLeadershipRoutine}
            onChange={({ target: { value } }) => setSelectedLeadershipRoutine(value)}
            autoComplete="off"
            >
            <option value="" disabled>{getText("select_routine")}</option>
            {routines.map(({ id, name }) => {
              return <option autoComplete="off" key={id} value={id}>{name}</option>
            })}
          </select>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-12 text-center">
          <select
            className="form-control cdi-ddl w-100 ml-0"
            value={selectedUser}
            onChange={({ target: { value } }) => setSelectedUser(value)}
            autoComplete="off"
            >
            <option value="" disabled>{getText("select_member")}</option>
            {users.map(({ id, firstNames, lastName }) => {
              return <option autoComplete="off" key={id} value={id}>{firstNames} {lastName}</option>
            })}
          </select>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-12 text-center">
          <select
            className={"form-control cdi-ddl w-100 ml-0" + (id ? " disabled" : "")}
            value={selectedFrequency}
            onChange={({ target: { value } }) => changeFrequency(value)}
            autoComplete="off"
            >
            <option value="" disabled>{getText("select_frequency")}</option>
            {PERIOD_FREQUENCIES.map((frequency, i) => {
              return <option autoComplete="off" key={i} value={frequency}>{getText(frequencyProps[frequency].text.toLowerCase())}</option>
            })}
          </select>
        </div>
      </div>
      {selectedFrequency !== "DAY" ? (
        <div className="row mb-1">
          <div className="col-12 text-center">
            <h6 className="cdi-blue-txt ml-0 mb-1">{getLabelText("when")}</h6>
          </div>
        </div>
      ) : null}
      {selectedFrequency === "MONTH" ? (
        <div className={"row flex-nowrap mb-2 justify-content-center" + (id ? " disabled" : "")}>
          {weeks.map((week, i) => {
            return (
              <div
                key={i}
                className={
                  "attendance-cell text-center font-weight-bold p-1 mr-2 cdi-blue-outline" +
                  (selectedWeek.includes(week.value)
                    ? " text-white bg-blue"
                    : " color-blue")
                }
                onClick={() => toggleWeekSelect(week.value)}
                autoComplete="off"
              >
                {week.text}
              </div>
            );
          })}
        </div>
      ) : null}
      {selectedFrequency !== "DAY" ? (
        <div className={"row flex-nowrap justify-content-center" + (id ? " disabled" : "")}>
          {days.map((day, i) => {
            return (
              <div
                key={i}
                className={
                  "attendance-cell text-center font-weight-bold p-1 mr-2 cdi-blue-outline" +
                  (selectedDay.includes(day.value)
                    ? " text-white bg-blue"
                    : " color-blue")
                }
                onClick={() => toggleDaySelect(day.value)}
              >
                {getLabelText(day.key)}
              </div>
            );
          })}
        </div>
      ) : null}
      <div className="row mt-4">
        <div className="col-6">
          <button className="btn btn-primary w-100" disabled={loading || !isValid()} onClick={() => onSave()}>
            {loading
              ? (
                <>{getLabelText("saving")}... <FontAwesomeIcon icon={faSpinner} className="fa-spin ml-2"></FontAwesomeIcon></>
              ) : (
                <>{getLabelText("save")}</>
              )
            }
          </button>
        </div>
        <div className="col-6">
          <button className="btn btn-default cdi-blue-outline color-blue w-100" onClick={() => close()}>{getLabelText("cancel")}</button>
        </div>
      </div>
    </Modal>
  )
}

export default RoutineModal
