import { setContext } from 'apollo-link-context';

import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';

const host = window.location.host

let uri = '';
let PUSHER_ID = '';

switch(host) {
  case "app.cdiconnect.com":
    uri = 'https://production.api.connect.cdi.fluenty.co.za'
    PUSHER_ID = '009e7dbd513ed228584f'
    break

  case "production.connect.cdi.fluenty.co.za":
    uri = 'https://production.api.connect.cdi.fluenty.co.za'
    PUSHER_ID = '009e7dbd513ed228584f'

    break

  case "connect.cdi.fluenty.co.za":
    uri = 'https://staging.api.connect.cdi.fluenty.co.za'
    PUSHER_ID = '4e29edb5675aabea4d2d'

    break

  case "pepsico.cdiconnect.com":
    uri = 'https://production.api.connect.cdi.fluenty.co.za'
    PUSHER_ID = '009e7dbd513ed228584f'
    break

  default:
    uri = process.env.REACT_APP_BACKEND_DEFAULT_URI ?? 'http://localhost:4000'
    PUSHER_ID = '4e29edb5675aabea4d2d'

    break
}

const httpLink = new HttpLink({
  uri: uri,
  headers: {
    "keep-alive": "true"
  }
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    }
  }
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache'
  },
  query: {
    fetchPolicy: 'no-cache'
  },
}

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

export {
  client,
  PUSHER_ID
}
