import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimes,
  faDotCircle,
} from "@fortawesome/free-solid-svg-icons";
import LabelComponent from "../../components/utils/getCompanyLabel";

const getLabelText = key => <LabelComponent val={key}/>

const Legend = ({ active }) => (
  <div
    style={{ bottom: active ? "116px" : "58px" }}
    className="bg-light-grey d-flex p-2 justify-content-around fixed-bottom"
  >
    <div style={{ display:"inline-flex"}}>
      <div className="attendance-cell text-center font-weight-bold p-1 mr-2 color-selected text-white max-cell-width">
        <FontAwesomeIcon icon={faCheck} />
      </div>
      <div style = {{ paddingTop: "1.5%"}}>
        {getLabelText("present_at_meeting")}
      </div>
    </div>
    <div style={{ display:"inline-flex"}}>
      <div className="attendance-cell text-center font-weight-bold p-1 mr-2 bg-color-negative text-white max-cell-width">
        L
      </div>
      <div style = {{ paddingTop: "1.5%"}}>
        {getLabelText("present_but_late")}
      </div>
    </div>
    <div style={{ display:"inline-flex"}}>
      <div className="attendance-cell text-center font-weight-bold p-1 mr-2 color-selected text-white max-cell-width">
        <FontAwesomeIcon icon={faDotCircle} />
      </div>
      <div style = {{ paddingTop: "1.5%"}}>
        {getLabelText("on_leave")}
      </div>
    </div>
    <div style={{ display:"inline-flex"}}>
      <div className="attendance-cell text-center font-weight-bold p-1 mr-2 color-selected text-white max-cell-width">
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <div style = {{ paddingTop: "1.5%"}} >
        {getLabelText("planned_non_attendance")}
      </div>
    </div>
    <div style={{ display:"inline-flex"}}>
      <div className="attendance-cell text-center font-weight-bold p-1 mr-2 bg-color-negative text-white max-cell-width">
        A
      </div>
      <div style = {{ paddingTop: "1.5%"}}>
        {getLabelText("absent")}
      </div>
    </div>
  </div>
);

export default Legend;
