import { combineReducers } from 'redux';

import authenticationReducer from './AuthenticationReducer';
import teamReducer from './TeamReducer';
import userReducer from './UserReducer';
import teamsReducer from './TeamsReducer';
import realtimeRefreshReducer from './RealtimeRefreshReducer';
import presentationReducer from './PresentationReducer'


export default combineReducers({
  authenticationReducer,
  teamReducer,
  teamsReducer,
  userReducer,
  realtimeRefreshReducer,
  presentationReducer
});
