import React, { Component, Fragment } from "react"

// bootstrap
import { Table, Button, Row, Col } from "react-bootstrap"

import { createTeam, getTeams, deleteTeam, updateTeam } from "../services/TeamService";
import { getUsers } from "../services/UserService";
import { getDepartments } from "../services/DepartmentService"
import { faCheck, faBan, faTrash, faEdit, faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from 'react-toastify'
import { connect } from "react-redux";
import { fetchTeams } from "../redux/services/TeamService";

class TeamsScreen extends Component {
  state = {
    teams: [],
    users: [],
    departments: [],
    departmentTypes: [],
    redirect: false,
    selectedId: null,
    departmentId: 0,
    name: "",
    purpose: "",
    coachId: 0,
    mode: "",
    memberIds: [],
    leaderIds: [],
    loading: false,
    loadType: "",
    deleteId: 0
  }

  async componentDidMount() {
    await this.fetchTeams()
    await this.fetchDepartments()
    await this.fetchUsers()
  }

  fetchTeams = async (to_load = true) => {
    if(to_load) this.props.togglePageLoad(true);
    const response = await getTeams()
    if(to_load) this.props.togglePageLoad(false);
    if(response.data.teams) {
      this.setState({ teams: response.data.teams })
    }
  }

  fetchUsers = async () => {
    const response = await getUsers()
    if(response.data.company.users) {
      this.setState({
        users: response.data.company.users
      })
    }
  }

  async fetchDepartments() {
    const response = await getDepartments()

    if (response.data.departments) {
      this.setState({
        departments: this.flattenDepartments(response.data.departments)
      })
    }
  }

  flattenDepartments = (array) => {
    let departments = [];
    array.some(function iter(a) {
      departments.push(a)
      return Array.isArray(a.children) && a.children.some(iter);
    });
    return departments;
  }

  toggleMode = (e, mode, id = 0) => {
    e.preventDefault()
    this.cancel()

    let options = {
      selectedId: id,
      mode: mode
    }
    let { teams } = this.state

    if (mode === "create") {
      teams.unshift({
        id: 0,
        departmentId: 0,
        name: "",
        purpose: "",
        coachId: 0
      })
    } else {
      const team = teams.filter((team) => {
        return team.id === id
      })

      options = {
        ...options,
        departmentId: team[0].department ? team[0].department.id : 0,
        name: team[0].name ? team[0].name : "",
        purpose: team[0].purpose ? team[0].purpose : "",
        coachId: team[0].coach ? team[0].coach.id : 0,
        memberIds: this.getArrayIds(team[0].members),
        leaderIds: this.getArrayIds(team[0].leaders),
      }
    }

    this.setState(options)
  }

  getArrayIds = (array) => {
    let ids = [];
    array.map((item) => {
      ids.push(item.id)
      return true
    });
    return ids;
  }

  isAllowed = (name, id) => {
    const { memberIds, leaderIds, coachId } = this.state
    if (name === "memberIds") {
      return !leaderIds.includes(id) && coachId !== id
    } else {
      return !memberIds.includes(id)
    }
  }

  cancel = () => {
    const { mode } = this.state
    let options = {
      selectedId: "",
      mode: "",
      departmentId: 0,
      name: "",
      purpose: "",
      coachId: 0,
      leaderIds: [],
      memberIds: []
    }

    if (mode === "create") {
      let { teams } = this.state

      if (teams[0].id === 0) {
        teams.shift()
      }

    }

    this.setState(options)
  }

  validate = () => {
    const {
      departmentId,
      name,
      purpose,
      coachId
    } = this.state

    return departmentId !== 0 && name.length > 0 && purpose.length > 0 && coachId !== 0
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    this.setState({
      loading: true,
      loadType: "create",
    })
    if (this.validate()) {
      try {
        const { departmentId, name, purpose, coachId, leaderIds, memberIds, mode, selectedId } = this.state
        if(mode === "create") {
          await createTeam(name, purpose, [], [], coachId, departmentId)
        } else {
          await updateTeam(selectedId, name, purpose, leaderIds, memberIds, coachId, departmentId)
        }
        await this.fetchTeams(false)
        await this.props.fetchTeams()
        this.cancel()
      } catch (error) {
        error.graphQLErrors.map(error => {
          toast.error(error.message)

          return true
        })
      }
    }

    this.setState({
      loading: false,
      loadType: ""
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  deleteItem = async (id) => {
    this.setState({
      loading: true,
      loadType: "delete",
      deleteId: id
    })
    if (window.confirm('Are you sure you wish to delete this item?')) {
      try {
        await deleteTeam(id)
        await this.fetchTeams(false)
        await this.props.fetchTeams()
      } catch (error) {
        error.graphQLErrors.map(error => {
          toast.error(error.message)

          return true
        })
      }
    }

    this.setState({
      loading: false,
      loadType: "",
      deleteId: 0
    })
  }

  teamList = (team, i) => {
    const { selectedId, departmentId, name, purpose, coachId, departments, users, loading, loadType, deleteId } = this.state
    let teamRow;
    if(selectedId === team.id) {
      teamRow = (
        <tr key={i}>
          <td>
            <select
              className="form-control cdi-dropdown"
              value={departmentId}
              onChange={(e) => this.setState({ departmentId: e.target.value })}
            >
              <option value={0} disabled>Select Department</option>
              {departments.map((department, k) => (
                <option key={k} value={department.id}>{department.name}</option>
              ))}
            </select>
          </td>
          <td>
            <input
              autoFocus
              className="form-control"
              type="text"
              required
              placeholder="Team Name"
              value={name}
              name="name"
              tabIndex="2"
              onChange={(e) => this.handleChange(e)}
              autoComplete="off"
            />
          </td>
          <td>
            <input
              autoFocus
              className="form-control"
              type="text"
              required
              placeholder="Our Purpose"
              value={purpose}
              name="purpose"
              tabIndex="3"
              onChange={(e) => this.handleChange(e)}
              autoComplete="off"
            />
          </td>
          <td>
            <select
              className="form-control cdi-dropdown"
              value={coachId}
              onChange={(e) => this.setState({ coachId: e.target.value })}
            >
              <option value={0} disabled>Select Coach</option>
              {users.map((user, k) => {
                if(this.isAllowed("coachId", user.id)) {
                  return <option key={k} value={user.id}>{user.firstNames + " " + user.lastName}</option>
                }

                return false
              })}
            </select>
          </td>
          <td>
            <Button className="btn" variant="primary" type="submit">
              {loading && loadType === "create"
                ? (
                  <Fragment>
                    <>Saving...</>
                    <FontAwesomeIcon className="ml-2 fa-spin" icon={faSpinner}/>
                  </Fragment>
                )
                : (
                  <Fragment>
                    <>Save</>
                    <FontAwesomeIcon className="ml-2" icon={faCheck} />
                  </Fragment>
                )
              }
            </Button>
            <Button className="btn ml-1" variant="danger" onClick={this.cancel}>Cancel<FontAwesomeIcon className="ml-2" icon={faBan} /></Button>
          </td>
        </tr>
      )
    } else {
      teamRow = (
        <tr key={i}>
          <td>
            {team.department ? team.department.name : "-- No Data --"}
          </td>
          <td>
            {team.name ? team.name : "-- No Data --"}
          </td>
          <td>
            {team.purpose ? team.purpose : "-- No Data --"}
          </td>
          <td>
            {team.coach ? team.coach.firstNames + " " + team.coach.lastName : "-- No Data --"}
          </td>
          <td>
            <Button className="btn" variant="primary" onClick={(e) => this.toggleMode(e, "edit", team.id)}>Edit<FontAwesomeIcon className="ml-2" icon={faEdit} /></Button>
            <Button className="btn ml-1" variant="danger" onClick={() => this.deleteItem(team.id)}>
              {loading && loadType === "delete" && deleteId === team.id
                ? (
                  <Fragment>
                    <>Deleting...</>
                    <FontAwesomeIcon className="ml-2 fa-spin" icon={faSpinner}/>
                  </Fragment>
                )
                : (
                  <Fragment>
                    <>Delete</>
                    <FontAwesomeIcon className="ml-2" icon={faTrash} />
                  </Fragment>
                )
              }
            </Button>
          </td>
        </tr>
      )
    }

    return teamRow

  }

  render() {

    return (
      <Fragment>
        <Row className="mt-20 justify-content-center">
          <Col className="text-right" xs={12}>
            <Button className="btn btn-primary" onClick={(e) => this.toggleMode(e, "create")}>Add<FontAwesomeIcon className="ml-2" icon={faPlus} /></Button>
          </Col>
        </Row>
        <Row className="mt-20 justify-content-center">
          <Col xl={12}>
            <form onSubmit={(e) => this.handleSubmit(e)}>
              <Table striped bordered hover className="users-tbl">
                <thead>
                  <tr>
                    <th width="20%">Department</th>
                    <th width="20%">Name</th>
                    <th width="20%">Purpose</th>
                    <th width="20%">Coach</th>
                    <th width="20%">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.teams.map((team, i) => this.teamList(team, i))}
                </tbody>
              </Table>
            </form>
          </Col>
        </Row>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user
})

const mapDispatchToProps = {
  fetchTeams,
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamsScreen)
