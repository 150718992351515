export const mapModuleData = (modules, moduleId, itemId, type, obj = null) => {
    switch(type) {
        case "CREATE":
            return modules.map((module) => {
                return module.id === moduleId ? {
                    ...module,
                    milestones: [obj, ...module.milestones]
                } : module
            })
        case "UPDATE":
            return modules.map((module) => {
                return module.id === moduleId ? {
                    ...module,
                    milestones: module.milestones.map(milestone => milestone.id === itemId ? obj : milestone)
                } : module
            })
        case "DELETE":
            return modules.map((module) => {
                return module.id === moduleId ? {
                    ...module,
                    milestones: module.milestones.filter(({ id }) => id !== itemId)
                } : module
            })
        default:
            return null
    }
}
