import React, { useState, useEffect, useMemo, useCallback, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  format,
  startOfMonth,
  endOfMonth,
  getDaysInMonth,
} from "date-fns";

import DayCell from "./DayCell";
import UserCell from "./UserCell";
import Legend from "./Legend";
import { connect } from 'react-redux';
import VisitorModal from "./Modal";
import IconButton from "../../components/shared/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faEdit, faSpinner } from "@fortawesome/free-solid-svg-icons";

import { TEAMS_WITH_ATTENDANCES_QUERY, CREATE_VISITOR_MUTATION, UPDATE_VISITOR_MUTATION, DELETE_VISITOR_MUTATION, GET_CSV_DATA } from "./resources/gql";

import displayErrorToast from "../../components/utils/displayErrorToast";
import classnames from "../../components/utils/classnames";
import MonthYearSelect from "../../components/MonthYearSelect";
import SubHeaderComponent from "../../components/SubHeaderComponent";
import RealtimeRefreshComponent from "../../components/RealtimeRefreshComponent";
import mapAPIData from "./resources/mapAPIData";
import * as XLSX from 'xlsx';
import LabelComponent from "../../components/utils/getCompanyLabel";

const MeetingAttendanceScreen = ({ selectedTeam, togglePageLoad, refresh, hasNewData, toggleRefresh, toggleHasNewData, presentation }) => {
  const [date, setDate] = useState(new Date());
  const [disabledDays, setDisabledDays] = useState(new Set());
  const [team, setTeam] = useState({ attendances: [] });
  const [objects, setObjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dayTotals, setDayTotals] = useState([]);
  const [level, setLevel] = useState(null);
  const [selectedVisitor, setSelectedVisitor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [exporting,setExporting] = useState(false);
  const [periodKey, setPeriodKey] = useState((date.getFullYear()).toString());
  const [periodValue, setPeriodValue] = useState((date.getMonth()+1).toString());
  const ATTENDANCE_STATUSES = [
    "NO_ACTION",
    "PRESENT",
    "PRESENT_BUT_LATE",
    "ON_LEAVE",
    "PLANNED_NON_ATTENDANCE",
    "ABSENT",
  ];
  const COACH_ATTENDANCE_STATUSES = [
    "NO_ACTION",
    "PLANNED_NON_ATTENDANCE",
    "PRESENT",
    "PRESENT_BUT_LATE",
    "ON_LEAVE",
    "ABSENT",
  ]

  const computeData = useCallback(
    (data) => {
      if (!data) return;

      const computed = mapAPIData(data, level);
      setDisabledDays(computed.disabledDays);
      setTeam(computed.team);
      setObjects(computed.objects);
      setIsLoading(false);
      
      
      togglePageLoad(false)
    },
    [setDisabledDays, setTeam, setIsLoading, togglePageLoad, level, setObjects]
  );

  const exportData = useCallback(
    (data) => {
      if (!data) return;

      if(data.team.userAttendanceCSV && data.team.userAttendanceCSV.length) {
        let downloadLink = document.createElement("a");
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const ws = XLSX.utils.json_to_sheet(data.team.userAttendanceCSV.map(att => {
          delete att.__typename

          return att
        }), {
          skipHeader: true
        });
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], {type: fileType});
        let url = URL.createObjectURL(blob);

        downloadLink.href = url;
        downloadLink.download = `[${date.toLocaleDateString('en-GB')}] ${team.name} Attendances.xlsx`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

      }

      setExporting(false);
    },
    [date, team, setExporting]
  );

  const [loadData] = useLazyQuery(TEAMS_WITH_ATTENDANCES_QUERY, {
    variables: { id: selectedTeam.id, startDate: startOfMonth(date), endDate: endOfMonth(date) },
    onCompleted: computeData,
    onError: displayErrorToast,
  });

  const [getCSVData] = useLazyQuery(GET_CSV_DATA, {
    onCompleted: exportData,
    onError: displayErrorToast
  })

  const getLabelText = key => <LabelComponent val={key}/>

  useEffect(() => {
    if(refresh) {
      setIsLoading(true)
      togglePageLoad(true)
      if (selectedTeam.id !== 0) loadData()
      toggleRefresh()
      toggleHasNewData()
    }
  }, [refresh, toggleRefresh, toggleHasNewData, selectedTeam, loadData, togglePageLoad])

  useEffect(() => {
    setIsLoading(true); 
    togglePageLoad(true)
    if (selectedTeam.id !== 0) {
      setLevel(selectedTeam.department.departmentType.level);
      loadData();
    }
  }, [selectedTeam, date, loadData, setIsLoading, togglePageLoad, setLevel]);

  const [createVisitor] = useMutation(CREATE_VISITOR_MUTATION, {
    onCompleted: ({ createVisitor: { name, id } }) => {
      setObjects([...objects, {
        name,
        id,
        type: "VISITOR",
        attendances: [],
        isCoach: false
      }]);
      cancel();
    },
  });

  const [updateVisitor] = useMutation(UPDATE_VISITOR_MUTATION, {
    onCompleted: ({ updateVisitor: { name, id } }) => {
      setObjects(objects.map(obj => obj.id === id ? {...obj, name} : obj));
      cancel();
    },
  });

  const [removeVisitor] = useMutation(DELETE_VISITOR_MUTATION, {
    onCompleted: ({ deleteVisitor: { id } }) => {
      setObjects(objects.filter(obj => obj.id !== id));
      setDeleteId(null);
      setDeleting(false);
    },
  });

  const save = name => {
    if(selectedVisitor.id === 0) {
      return createVisitor({
        variables: {
          name,
          team: selectedTeam.id,
          date
        }
      })
    }

    return updateVisitor({
      variables: {
        id: selectedVisitor.id,
        name
      }
    })
  }

  const cancel = useCallback(
    () => {
      setSelectedVisitor(null)
      setShowModal(false)
      setLoading(false)
    },
    [setSelectedVisitor, setShowModal]
  )

  const onDayCellChange = ({ index: day, state }) => {
    let totals = dayTotals
    if (state === 2) {
      const nextSet = new Set(disabledDays);
      nextSet.add(day);
      if(totals[day - 1]) {
        totals[day - 1] = {
          day: day,
          count: totals[day - 1] && totals[day - 1].count,
          visible: false
        }
      }
      setDayTotals(dayTotals)
      setDisabledDays(nextSet);
    } else {
      const nextSet = new Set(disabledDays);
      nextSet.delete(day);
      if(totals[day - 1]) {
        totals[day - 1] = {
          day: day,
          count: totals[day - 1] && totals[day - 1].count,
          visible: true
        }
      }
      setDayTotals(dayTotals)
      setDisabledDays(nextSet);
    }
  };

  const onUserCellChange = (day, status, attendance, userId) => {

    objects.map(user => {
      if(user.isCoach)
      {
        if(user.id === userId) {
          user.attendances[day] = {
            ...attendance,
            status: COACH_ATTENDANCE_STATUSES[status]
          }
        }
      }
      else
      {
        if(user.id === userId) {
          user.attendances[day] = {
            ...attendance,
            status: ATTENDANCE_STATUSES[status]
          }
        }
      }

      return true
    })

    setObjects(objects)
    const nextSet = new Set(disabledDays);
    setDisabledDays(nextSet);

  }

  const changeMonth = (month) => {
    setPeriodValue(parseInt(month));
    let year = periodKey;
    setDate(new Date(year, parseInt(month) -1, date.getDate()));

    // if (selectedTeam && selectedTeam.id !== 0) {
    //   const company = selectedTeam.company;
    //   if (company) {
    //     const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    //     let currentMonthIndex = parseInt(month); 
    //     let yearStartMonthIndex = months.indexOf(company.finalcialYearEnd); 
    //     if (currentMonthIndex >= yearStartMonthIndex) {
    //       setDate(new Date(year, parseInt(month) -1, date.getDate()));
    //     }
    //     else {
    //       setDate(new Date(year - 1, parseInt(month)-1, date.getDate()));
    //     }
    //   }
    // }
  }

  const changeYear = (year)=>{
    setPeriodKey(year)
    let month = periodValue;
    setDate(new Date(parseInt(year), parseInt(month) -1, date.getDate()));
    // if (selectedTeam && selectedTeam.id !== 0) {
    //   const company = selectedTeam.company;
    //   if (company) {
    //     const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    //     let currentMonthIndex = parseInt(month);
    //     let yearStartMonthIndex = months.indexOf(company.finalcialYearEnd);
    //     if (currentMonthIndex >= yearStartMonthIndex) {
    //       setDate(new Date(parseInt(year), parseInt(month) -1, date.getDate()));
    //     }
    //     else {
    //       setDate(new Date(parseInt(year) - 1, parseInt(month)-1, date.getDate()));
    //     }
    //   }
    // }
  };

  const dayArray = useMemo(
    () => [...Array(getDaysInMonth(date))].map((_, i) => i + 1),
    [date]
  );

  const downloadCSV = () => {
    setExporting(true);
    getCSVData({
      variables: { id: selectedTeam.id, startDate: startOfMonth(date), endDate: endOfMonth(date) }
    })
    // if(team.userAttendanceCSV && team.userAttendanceCSV.length) {
    //   let downloadLink = document.createElement("a");
    //   const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    //   const ws = XLSX.utils.json_to_sheet(team.userAttendanceCSV.map(att => {
    //     delete att.__typename
    //
    //     return att
    //   }), {
    //     skipHeader: true
    //   });
    //   const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    //   const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //   const blob = new Blob([excelBuffer], {type: fileType});
    //   let url = URL.createObjectURL(blob);
    //
    //   downloadLink.href = url;
    //   downloadLink.download = `[${date.toLocaleDateString('en-GB')}] ${team.name} Attendances.xlsx`;
    //   document.body.appendChild(downloadLink);
    //   downloadLink.click();
    //   document.body.removeChild(downloadLink);
    //
    // }
  }

  useEffect(() => {
    let array = []
    for(let day = 1; day < 32; day++) {
      let data = null
      let count = 0
      let hasAttendances = false
      objects.map(user => {
        if(user.attendances[day]) {
          let attendance = user.attendances[day]
          if(attendance.status !== "NO_ACTION") {
            hasAttendances = true
          }
          data = {
            day: day,
            count: count,
            visible: true
          }
          if(attendance.status === "PRESENT" || attendance.status === "ON_LEAVE" || attendance.status === "PLANNED_NON_ATTENDANCE") {
            count += 1
            data = {
              day: day,
              count: count,
              visible: true
            }
          }
        }
        return true
      })
      if(disabledDays.has(day) || !hasAttendances) {
        data = {
          ...data,
          visible: false
        }
      }
      array.push(data)
    }
    setDayTotals(array)
  }, [objects, disabledDays])

  const getAattendanceCount = day => {
    if((team.attendances[day] && team.attendances[day].status === "NON_WORKING_DAY") || disabledDays.has(day)) return null;
    let total = 0;
    let hasAttendances = false;
    objects.map(({ attendances }) => {
      if(attendances[day] && attendances[day].status !== "NO_ACTION") hasAttendances = true;
      if(attendances[day] && attendances[day].status === "PRESENT") return total = total + 1;

      return total;
    })

    return (total < 1 && !hasAttendances) ? "-" : total;
  }

  const getPlannedCount = day => {
    if((team.attendances[day] && team.attendances[day].status === "NON_WORKING_DAY") || disabledDays.has(day)) return null;
    let total = objects.length;

    objects.map(({ attendances }) => {
      if(attendances[day] && (attendances[day].status === "PLANNED_NON_ATTENDANCE" || attendances[day].status === "ON_LEAVE")) return total = total - 1;

      return total;
    })

    return total;
  }

  const setSelected = visitor => {
    setSelectedVisitor(visitor);
    setShowModal(true);
  }

  const deleteVisitor = id => {
    removeVisitor({
      variables: {
        id
      }
    });

    setDeleting(true);
    setDeleteId(id);
  }

  return (
    <Fragment>
      <SubHeaderComponent>
        <Col className="text-right childenSpace">
          <div className={"refreshBtn"}>
            <RealtimeRefreshComponent
              refresh={refresh}
              hasNewData={hasNewData}
              toggleRefresh={toggleRefresh}
            />
          </div>
          <button className="btn btn-primary mr-2 ml-2" onClick={() => setSelected({
            id: 0,
            name: "",
            attendances: []
          })}>
            {getLabelText("add")}
            <FontAwesomeIcon icon={faPlus} className="ml-1"></FontAwesomeIcon>
          </button>
          <button className="btn btn-primary" onClick={() => downloadCSV()}>
            {exporting ? (
              <FontAwesomeIcon icon={faSpinner} className="mx-auto fa-spin"></FontAwesomeIcon>
            ) : (
              <span>{getLabelText("export")}</span>
            )}
          </button>
        </Col>
      </SubHeaderComponent>
      <div className="grey-header-space container-left-minus22">
        <Row className="graph-dashboard-tbl ml-4 mr-0 mb-4 overflow-auto">
          <Row className="align-items-baseline w-100 mb-3">
            <MonthYearSelect date={date} onMonthChange={changeMonth} onYearChange={changeYear} selectedYear={periodKey} selectedMonth={periodValue}/>
            <Col xs={9}>
              <Row className="flex-nowrap">
                {!isLoading &&
                dayArray.map((day) => (
                    <DayCell
                        attendance={team.attendances[day]}
                        loading={isLoading}
                        teamId={team.id}
                        key={day}
                        day={day}
                        today={date}
                        onCellChange={onDayCellChange}
                    />
                ))}
              </Row>
            </Col>
          </Row>
          {isLoading ? (
              <div>
                Loading meeting attendances for <b>{format(date, "MMMM yyyy")}</b> ...
              </div>
          ) : (
              objects.map(({ name, id, isCoach, attendances, type }, i) => (
                  <Row
                      className={classnames(
                          "align-items-baseline w-100 mb-2",
                          isCoach && "bg-light-grey"
                      )}
                      key={`${id}_${date}`}
                  >
                    {type === "VISITOR" ? (
                        <Fragment>
                          <Col className="ml-2">{(i + 1) + ".  " + name}</Col>
                          <Col className="text-right">
                            <IconButton tooltip="Edit" eventHandler={() => setSelected({
                              id,
                              name,
                              attendances
                            })} classNames={selectedVisitor ? "disabled" : ""}>
                              <FontAwesomeIcon icon={faEdit} className="color-blue mr-1"></FontAwesomeIcon>
                            </IconButton>
                            <IconButton tooltip="Delete" eventHandler={() => deleteVisitor(id)} classNames={selectedVisitor ? "disabled" : ""}>
                              {deleting && deleteId === id ? (
                                  <FontAwesomeIcon icon={faSpinner} className="color-blue mr-1 fa-spin"></FontAwesomeIcon>
                              ) : (
                                  <FontAwesomeIcon icon={faTrash} className="color-blue mr-1"></FontAwesomeIcon>
                              )}
                            </IconButton>
                          </Col>
                        </Fragment>
                    ) : (
                        <Col className="ml-2">{(i + 1) + ".  " + name}</Col>
                    )}
                    <Col xs={9}>
                      <Row className="flex-nowrap">
                        {dayArray.map((day) => (
                            <UserCell
                                key={day}
                                day={day}
                                objId={id}
                                today={date}
                                teamId={team.id}
                                disabledDays={disabledDays}
                                attendance={attendances[day]}
                                dayTotals={dayTotals}
                                onCellChange={onUserCellChange}
                                type={type}
                                isCoach={isCoach}
                            />
                        ))}
                      </Row>
                    </Col>
                  </Row>
              ))
          )}
          <Row
              className={classnames(
                  "align-items-baseline w-100",
              )}
          >
            <Col className="ml-2">{getLabelText("full_attendance")}</Col>
            <Col xs={9}>
              <Row className="flex-nowrap">
                {dayArray.map((day, i) => (
                    <div key={`${day + "_" + i}`} className="attendance-cell text-center font-weight-bold p-1 mr-2">
                      {getAattendanceCount(day)}
                    </div>
                ))}
              </Row>
            </Col>
          </Row>
          <Row
              className={classnames(
                  "align-items-baseline w-100 mb-5",
              )}
          >
            <Col className="ml-2">{getLabelText("planned_attendance")}</Col>
            <Col xs={9}>
              <Row className="flex-nowrap">
                {dayArray.map((day, i) => (
                    <div key={`${day + "_" + i}`} className="attendance-cell text-center font-weight-bold p-1 mr-2">
                      {getPlannedCount(day)}
                    </div>
                ))}
              </Row>
            </Col>
          </Row>
          <Legend active={presentation.active} />
          <VisitorModal
              selectedVisitor={selectedVisitor}
              save={save}
              cancel={cancel}
              loading={loading}
              setLoading={setLoading}
              showModal={showModal}
          />
        </Row>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam,
  presentation: state.presentationReducer
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingAttendanceScreen);
