import { useState } from "react";

const useIndexedState = (initialValue, array) => {
  const [initialIndex, setInitialIndex] = useState(array.indexOf(initialValue));
  const [index, setIndex] = useState(initialIndex);

  const advance = () => {
    const nextIndex = index === array.length - 1 ? 0 : index + 1;
    setIndex(nextIndex);
    return nextIndex;
  };

  const reset = () => {
    setIndex(initialIndex);
  };

  const save = (status) => {
    setInitialIndex(array.indexOf(status));
  };

  return [
    { displayedStatus: array[index], status: array[initialIndex] },
    advance,
    reset,
    save,
  ];
};

export default useIndexedState;
