import { client } from "./BaseService";
import { gql } from "apollo-boost";

const getTeamMeasurements = async (id) => {
  return await client.query({
    query: gql`query {
      teamMeasurements(teamId: "${id}") {
        id
        frequency
        numberTo
        numberFrom
        dueDate
        period
        nextYearLink
        prevYearLink
        allMetricsCSV {
          frequency
          date
          goalCategory
          measurement
          unit
          target
          result
          resultColour
          dayStatus
        }
        measurement {
          id
          measurement
          unit {
            id
            name
          }
          goalCategory {
            id
            name
          }
        }
      }
    }`
  })
}

const createTeamMeasurement = async (teamId, measurement, unit, frequency, numberFrom, numberTo, dueDate, goalCategoryId, createType, isNewUnit, period) => {

  const measurementFields = `measurementId: "${measurement}"`

  return await client.mutate({
    mutation: gql`mutation {
        createTeamMeasurement(
          teamId: "${teamId}",
          ${measurementFields},
          frequency:"${frequency}",
          numberFrom: ${numberFrom},
          numberTo: ${numberTo},
          dueDate: "${dueDate}",
          period: "${period}"
        )
        {
          id
        }
      }`,
  });
}

const updateTeamMeasurement = async (id, measurementId, frequency, numberFrom, numberTo, dueDate) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateTeamMeasurement(
        id: "${id}",
        measurementId: "${measurementId}",
        frequency:"${frequency}",
        numberFrom: ${numberFrom},
        numberTo: ${numberTo},
        dueDate: "${dueDate}"
      )
      {
        id
      }
    }`
  })
}

const deleteTeamMeasurement = async(id) => {
  return await client.mutate({
    mutation: gql`mutation {
      deleteTeamMeasurement(
        id: "${id}"
      ) {
        id
      }
    }`
  })
}

const getTeamBusinessResults = async (teamId, period, periodKey, periodValues) => {
  return await client.query({
    query: gql`query {
      teamBusinessResults (teamId: "${teamId}", period: "${period}", periodKey: "${periodKey}") {
        id
        periodKey
        periodValue
        periodStatus
        status
        target,
        value,
        comment,
        goal
      }
    }`
  })
}

export {
  getTeamMeasurements,
  createTeamMeasurement,
  updateTeamMeasurement,
  deleteTeamMeasurement,
  getTeamBusinessResults
}
