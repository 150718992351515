import React, { useEffect, useState } from "react";
import { FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-modal';
import { getDateFromWeek, getWeekNumber } from "../resources/utils";
import DatePicker from "react-datepicker"

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '30%'
  }
};

const ProjectModal = ({ object: { name, id, from, to, user, fromDate, toDate, order }, save, cancel, showModal, type, loading, setLoading, users, getText }) => {
  const [value, setValue] = useState(name ? name : "")
  const [start, setStart] = useState(from ? from : 1)
  const [end, setEnd] = useState(to ? to : 1)
  const [startDate, setStartDate] = useState(fromDate ? fromDate : getDateFromWeek(start))
  const [endDate, setEndDate] = useState(toDate ? toDate : getDateFromWeek(end))
  const [index, setIndex] = useState(order ? order : 1)
  const [selectedUser, setSelectedUser] = useState(user ? user.id : users.length ? users[0].id : null)

  useEffect(() => {
    setValue(name)
    setStart(from)
    setEnd(to)
    setStartDate(fromDate ? fromDate : getDateFromWeek(from))
    setEndDate(toDate ? toDate : getDateFromWeek(to))
    setIndex(order)
    if(user) setSelectedUser(user.id)
    else if(users.length) setSelectedUser(users[0].id)

  }, [name, from, to, fromDate, toDate, order, user, users, setValue, setStart, setEnd, setSelectedUser, setStartDate, setEndDate, setIndex])

  const saveData = async() => {
    setLoading(true)
    save(type === "Milestone" ? {
      name: value,
      from: start,
      to: end,
      userId: selectedUser,
      fromDate: startDate,
      toDate: endDate,
      order: index
    } : {
      name: value,
      from: start,
      to: end,
      fromDate: startDate,
      toDate: endDate,
      order: index
    })
  }

  const setDateValues = (value, type) => {
    switch(type) {
      case "from":
        setStart(getWeekNumber(value));
        setStartDate(new Date(value));
        break;
      default:
        setEnd(getWeekNumber(value));
        setEndDate(value);
        break;
    }
  }

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => cancel()}
      contentLabel=""
      style={customStyles}
    >
      <div className="row">
        <div className="col-12 text-center">
          <label className="cdi-blue-txt ml-0 mb-2">{getText("add")}</label>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-12">
          <label className="cdi-blue-txt ml-2">{getText("name")}:</label>
        </div>
        <div className="col-12">
          <FormControl
            value={value}
            onChange={(e) => setValue(e.target.value)}
            autoComplete="off"
          ></FormControl>
        </div>
      </div>
      {type === "Milestone" && (
        <div className="row mb-2">
          <div className="col-12">
            <label className="cdi-blue-txt ml-2">{getText("owner")}:</label>
          </div>
          <div className="col-12">
            <select className="form-control cdi-ddl w-100 ml-0"
              name="user"
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
              autoComplete="off"
            >
              <option value="1" disabled>{getText("select_member")}</option>
              {users.map((user, i) => {
                return <option value={user.id} key={i}>{user.firstNames} {user.lastName}</option>
              })}
            </select>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-6">
          <div className="row">
            <div className="col-12">
              <label className="cdi-blue-txt ml-2">{getText("from")}:</label>
            </div>
            <div className="col-12 datepicker-100">
              <DatePicker
                name="date"
                className="form-control"
                required={true}
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                onChange={(value) => setDateValues(value, "from")}
                inline={false}
                autoComplete="off"
              />
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="row">
            <div className="col-12">
              <label className="cdi-blue-txt ml-2">{getText("to")}:</label>
            </div>
            <div className="col-12 datepicker-100">
              <DatePicker
                name="date"
                className="form-control"
                required={true}
                dateFormat="dd/MM/yyyy"
                selected={endDate}
                onChange={(value) => setDateValues(value, "end")}
                inline={false}
                autoComplete="off"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-6">
          <button className="btn btn-primary w-100" disabled={loading} onClick={() => saveData()}>
            {loading
              ? (
                <>{getText("saving")} <FontAwesomeIcon icon={faSpinner} className="fa-spin ml-2"></FontAwesomeIcon></>
              ) : (
                <>{getText("save")}</>
              )
            }
          </button>
        </div>
        <div className="col-6">
          <button className="btn btn-default cdi-blue-outline color-blue w-100" onClick={() => cancel()}>{getText("cancel")}</button>
        </div>
      </div>
    </Modal>
  )
}

export default ProjectModal
