import Constants from "./constants";

export const canEditHere = (user, teams, selectedTeam) => {
    let is_permitted = false;
    if([Constants.Permission.FunctionAdmin, Constants.Permission.CompanyAdmin, Constants.Permission.Consultant].findIndex(el => el === user?.role) > -1)
    {
      is_permitted = true;
    }
    else 
    {
      is_permitted = (teams?.find(el => el.id === selectedTeam?.id)?.inTeam !== false);
    }
    return is_permitted;
}