const FETCH_USER_STARTED = 'FETCH_USER_STARTED';
const FETCH_USER_FAILED = 'FETCH_USER_FAILED';
const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
const UPDATE_COMPANY_PAGE_PERMISSIONS = 'UPDATE_COMPANY_PAGE_PERMISSIONS';

export {
  FETCH_USER_STARTED,
  FETCH_USER_FAILED,
  FETCH_USER_SUCCESS,
  UPDATE_COMPANY_PAGE_PERMISSIONS
};
