import React, { Component, Fragment } from "react";
import { Button, FormGroup, FormControl, FormLabel, Alert, Row, Col, Container } from "react-bootstrap";
import { getUsers } from "../../services/UserService";

class InputCompanyScreen extends Component {
  state = {
    error: "",
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    countries: [
      "South Africa",
      "Zimbabwe",
      "Namibia",
      "Botswana",
      "Swaziland",
      "Lesotho"
    ],
    users: []
  }

  async componentDidMount() {
    const response = await getUsers()
    this.setState({ users: response.data.users })
  }

  downloadCSV = () => {
  
  }

  render() {

    const { error } = this.state;
    return (
      <Fragment>
        <Container>
          <Row>
            <Col xs={12}>
              <Alert className={error ? "d-block" : "d-none"} variant="danger">
                {error}
              </Alert>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={12}>
              <form className="create-user-from">
                <h3 className="page-title">Company Setup</h3>
                <Alert className={error ? "d-block" : "d-none"} variant="danger">
                  {error}
                </Alert>
                <Row>
                  <Col>
                    <FormGroup controlId="client_number">
                      <FormLabel htmlFor="client_number">Client Number</FormLabel>
                      <FormControl
                        autoFocus
                        type="text"
                        placeholder="Client Number"
                        name="client_number"
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup controlId="name">
                      <FormLabel htmlFor="client_number">Company Name</FormLabel>
                      <FormControl
                        autoFocus
                        type="text"
                        placeholder="Company Name"
                        name="name"
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup controlId="registration_number">
                      <FormLabel htmlFor="client_number">Registration Number</FormLabel>
                      <FormControl
                        autoFocus
                        type="text"
                        placeholder="Registration Number"
                        name="registration_number"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup controlId="country">
                      <FormLabel htmlFor="client_number">Country of Registration</FormLabel>
                      <select className="form-control cdi-ddl w-100 ml-0" name="country">
                        <option>Select Country</option>
                        {this.state.countries.map((country, i) => (
                          <option key={i}>{country}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup controlId="tax_number">
                      <FormLabel htmlFor="client_number">VAT/Tax Number</FormLabel>
                      <FormControl
                        autoFocus
                        type="text"
                        placeholder="VAT/Tax Number"
                        name="tax_number"
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup controlId="financial_year_month">
                      <FormLabel htmlFor="client_number">Financial Year First Month</FormLabel>
                      <select className="form-control cdi-ddl w-100 ml-0" name="financial_year_month">
                        <option>Select Month</option>
                        {this.state.months.map((month, i) => (
                          <option key={i}>{month}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup controlId="country">
                      <FormLabel htmlFor="client_number">Administrator</FormLabel>
                      <select className="form-control cdi-ddl w-100 ml-0" name="country">
                        <option>Select Administrator</option>
                        {this.state.users.map((user, i) => (
                          <option key={i}>{user.firstNames} {user.lastName}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup controlId="financial_year_month">
                      <FormLabel htmlFor="client_number">Billing Contact</FormLabel>
                      <select className="form-control cdi-ddl w-100 ml-0" name="financial_year_month">
                        <option>Select Billing Contact</option>
                        {this.state.users.map((user, i) => (
                          <option key={i}>{user.firstNames} {user.lastName}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      block
                      type="submit"
                      variant="primary"
                    >
                      Save
                      </Button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
      </Fragment>
    )
  }
}

export default InputCompanyScreen;
