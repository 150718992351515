import React, { useCallback, useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Switch from "react-switch";
import { Button } from "react-bootstrap";
import {
  faCheck,
  faSquare,
  faWindowClose,
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import classnames from "../../components/utils/classnames";
import { useMutation } from "@apollo/react-hooks";
import {
  CREATE_USER_MATRIX_MUTATION,
  UPDATE_USER_MATRIX_MUTATION,
} from "./resources/gql";
import Modal from 'react-modal';
import LabelComponent from "../../components/utils/getCompanyLabel";
import {formatLargeNumber} from "../../utils/number";

const getLabelText = key => <LabelComponent val={key}/>

const ATTENDANCE_STATUSES = [
  "RED",
  "BLUE"
];

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '300px'
  }
};

const attendanceCellProps = {
  [ATTENDANCE_STATUSES[0]]: { classes: "text-white bg-color-negative" },
  [ATTENDANCE_STATUSES[1]]: { classes: "text-white color-selected" },

};

const UserCell = ({
  day,
  userId,
  disabledDays,
  result,
  formattedDate,
  userMetrics,
  setUserMetrics,
  goalId,
  colour,
  frequency
}) => {
  const [obj, setObj] = useState(result)
  const [resultId, setResultId] = useState(obj.id);
  const [ showModal, setShowModal ] = useState(false)
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(obj.value)
  const [target, setTarget] = useState(obj.target)
  const [status, setStatus] = useState(obj.status)
  const [displayedStatus, setDisplayedStatus] = useState(obj.status)

  useEffect(() => {
    setResultId(result.id)
    setStatus(result.status)
    setValue(result.value)
    setTarget(result.target)
    setDisplayedStatus(result.status)
    setObj(result)
  }, [result, setResultId, setValue, setTarget, setStatus, setDisplayedStatus])

  const [createUserMatrix] = useMutation(CREATE_USER_MATRIX_MUTATION, {
    onCompleted: ({ createIndividualUserMetric }) => {
      setUserMetrics([...userMetrics, createIndividualUserMetric])
      setLoading(false)
      setShowModal(false)
    },
  });

  const [updateUserMatrix] = useMutation(UPDATE_USER_MATRIX_MUTATION, {
    onCompleted: ({ updateIndividualUserMetric }) => {
      setUserMetrics(userMetrics.map(metric => {
        if(metric.id === resultId) return {
          ...metric,
          status: updateIndividualUserMetric.status,
          value: updateIndividualUserMetric.value,
          target: updateIndividualUserMetric.target
        }

        return metric
      }))

      setLoading(false)
      setShowModal(false)
    },
  });

  const create = useCallback(() => {
    setLoading(true)
    createUserMatrix({
      variables: {
        goal: goalId,
        user: userId,
        status: status,
        period: obj.period,
        periodKey: frequency === 'Daily' ? obj.periodKey : (frequency === 'Weekly' ? obj.periodKey : formattedDate.split(" ")[1]),
        periodValue: obj.periodValue,
        target: parseFloat(target),
        value: parseFloat(value)
      },
    });
  }, [createUserMatrix, status, userId, goalId, setLoading, obj, target, value,frequency, formattedDate]);

  const update = useCallback(() => {
    setLoading(true)
    updateUserMatrix({
      variables: {
        id: obj.id,
        status: status,
        period: obj.period,
        periodKey: formattedDate.split(" ")[1],
        periodValue: obj.periodValue,
        target: parseFloat(target),
        value: parseFloat(value)
      },
    });
  }, [updateUserMatrix, status, obj, value, target, setLoading, formattedDate]);

  const save = () => {
    if(resultId) {
      update()
    } else {
      create()
    }
  }

  const { classes, icon } = !isNaN(obj.value) &&
    !obj.value && obj.value !== 0 ? {
    classes: "bg-transparent text-grey",
    icon: faSquare
  } : attendanceCellProps[displayedStatus]

  const visibility = disabledDays.has(day) && "invisible";

  const className = classnames(
    "attendance-cell text-center py-1 mr-2 rounded-sm",
    classes,
    visibility
  );

  return (
    <Fragment>
      <div onClick={() => setShowModal(true)} className={className}>
        <small>{formatLargeNumber(result.value)}</small>
        {icon && <FontAwesomeIcon icon={icon} />}
      </div>
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        contentLabel="Result"
        style={customStyles}
      >
      <div className="row">
        <div className="col-12 text-center">
          <h6 className="cdi-blue-txt ml-0 mb-1">{getLabelText("result_for")}<br/>{obj.user.firstNames} {obj.user.lastName}</h6>
          <h6 className="cdi-blue-txt ml-0 mb-3">{formattedDate}</h6>
        </div>
      </div>

      <form className="justify-content-center">
        <div className="row justify-content-center">
          <div className="col-10">
            <label className="form-label bold">{getLabelText("target")}</label>
            <input
              value={target}
              onChange={(e) => setTarget(e.target.value)}
              disabled={loading}
              type="number"
              step="0.01"
              className="controls-input w-100 mr-3 text-center bg-grey border border-color-light-grey"
            />
          </div>
        </div>
        <div className="row justify-content-center mt-1">
          <div className="col-10">
            <label className="form-label bold">{getLabelText("result")}</label>
            <input
              value={value}
              onChange={(e) => setValue(e.target.value)}
              disabled={loading}
              type="number"
              step="0.01"
              autoFocus={true}
              className="controls-input w-100 mr-3 text-center bg-grey border border-color-light-grey"
            />
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-12 text-center">
            <Button
              type="button"
              onClick={() => save()}
              disabled={loading}
              className="py-1 px-2"
            >
              <FontAwesomeIcon className={loading ? "fa-spin" : ""} icon={loading ? faSpinner : faCheck} />
            </Button>
            <span>&nbsp;</span>
            <Button
              type="button"
              disabled={loading}
              className="py-1 px-2"
              onClick={() => setShowModal(false)}
            >
              <FontAwesomeIcon icon={faWindowClose} />
            </Button>
          </div>
        </div>
      </form>
      <div className="row justify-content-center">
        <div className="col-8">
          <div className="d-flex align-items-center mt-2 mx-auto">
            <div
              onClick={() => setStatus(ATTENDANCE_STATUSES[1])}
              className={colour === "Green" ? "border border-color-light-blue bg-green attendance-cell attendance-date-border" : "border border-color-light-blue bg-blue attendance-cell attendance-date-border"}
            />
            <Switch
              disabled={loading}
              checkedIcon={false}
              offColor={"#005e85"}
              onColor={"#005e85"}
              uncheckedIcon={false}
              checked={status === ATTENDANCE_STATUSES[0]}
              onChange={() => setStatus(status === ATTENDANCE_STATUSES[1] ? ATTENDANCE_STATUSES[0] : ATTENDANCE_STATUSES[1])}
              className="mx-3"
            />
            <div
              onClick={() => setStatus(ATTENDANCE_STATUSES[0])}
              className="border border-color-light-blue bg-danger attendance-cell attendance-date-border"
            />
          </div>
        </div>
      </div>
      </Modal>
    </Fragment>
  );
};

export default UserCell;
