import React from "react";
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryContainer,
} from "victory";
import TickLabelComponent from "./TickLabelComponent";
import {
  lightBlue,
  useDomainBars,
  useInvertedDomainBars,
  useTickValues,
  useTargetData,
} from "./resources";
import { MetricPeriodStatuses, MetricStatuses } from "../resources";
import _ from 'lodash';
import { useDynamicDomain } from "../hooks.js"

function getDisplayData (data, maxValue, minHeight) {
  let displayData = []

  data.map(metric => displayData.push({
      ...metric,
      y: metric.y === minHeight && minHeight >= 0 ? metric.y + ((maxValue - minHeight) * 0.01) : !metric.y && minHeight >= 0 ? minHeight : metric.y,
      realMetric: metric.y
    }))

  return displayData
}

const Chart = ({
  data,
  opaque,
  handleSelectMetric,
  target,
  attendanceWeekStatuses,
  monthList,
  monthValues,
  resultDate,
  goal,
  setMetricTarget,
  handleTogglePeriodStatus,
  setActiveComment,
  colour
}) => {
  let actualData = []
  // Cleanup this mess!
  data.forEach((d) => {
    let attendanceWeekStatus = attendanceWeekStatuses.find((attendanceWeekStatus) => attendanceWeekStatus.week === d.x);
    if (!attendanceWeekStatus) {
      actualData.push(d);
    } else {
      if (attendanceWeekStatus.nonWorkingDayCount < 7) {
        actualData.push(d);
      }
    }
  });

  const tickValues = useTickValues(actualData);
  const targetData = useTargetData(target, tickValues, goal, monthValues, actualData);
  const minTarget =  _.minBy(targetData, (target) => {
    return target.y
  })
  const maxTarget =  _.maxBy(targetData, (target) => {
    return target.y
  })

  const domain = useDynamicDomain(
    data,
    [10, 0],
    [minTarget.y, maxTarget.y]
  );
  const emptyBarData = useDomainBars(actualData, domain, [minTarget.y, maxTarget.y]);
  const invertedBarData = useInvertedDomainBars(actualData, domain, [minTarget.y, maxTarget.y]);
  const maxHeight = _.maxBy(emptyBarData, (target) => {
    return target.y
  })
  const minHeight = _.minBy(invertedBarData, (target) => {
    return target.y
  })



  const barClickHandlers = [
    {
      childName: ["domain-bar", "metric-bar"],
      target: "data",
      eventHandlers: {
        onClick: (_, { index }) => {
          if (
            actualData[index].periodStatus === MetricPeriodStatuses.NON_WORKING.value
          )

            return;
          setActiveComment(data[index] && data[index].comment ? data[index].comment : "")
          handleSelectMetric(index, data[index].x);
          setMetricTarget(targetData[index] && (targetData[index].y || targetData[index].y === 0) ? `${targetData[index].y.toFixed(2)}` : null)
        },
      },
    },
  ];



  return (
    <VictoryChart
      height={500}
      maxHeight={500}
      // bar widths
      width={(data.length * 60)}
      style={{ parent: { opacity: opaque ? 0.5 : 1, maxHeight: "500px" } }}
      padding={{ top: 10, left: 60, right: 18, bottom: 50 }}
      // padding between bars
      domainPadding={{ x: [65, 10], y: 5 }}
      containerComponent={<VictoryContainer className="eyecatcher" responsive={false} />}
      events={barClickHandlers}
    >
      <VictoryAxis
        name="period-axis"
        tickValues={tickValues}
        events={[
          {
            target: "tickLabels",
            eventHandlers: {
              onClick: (_, { index }) => {
                handleTogglePeriodStatus(index);
              },
            },
          },
        ]}
        style={{
          axis: { stroke: "transparent" },
          tickLabels: { cursor: "pointer",
          fill: ({ index }) => {
            if (!data) return 'transparent'
            const { periodStatus } = data[index]
            return periodStatus === "NORMAL" ? "black" : "white"
          }
          },
        }}
        offsetY={50}
        tickLabelComponent={<TickLabelComponent data={actualData} resultDate={resultDate} bottomLabels={true} monthList={monthList} monthValues={monthValues}/>}
      />

      <VictoryAxis
        dependentAxis
        tickFormat={(v) => (v >= 1000 ? `${(v / 1000).toFixed(1)}k` : v)}
        domain={[minHeight.y, maxHeight.y]}
        y0={() => minHeight.y}
        style={{
          axis: { stroke: "transparent" },
          grid: { stroke: lightBlue, strokeWidth: 2 },
        }}
        tickLabelComponent={<TickLabelComponent monthList={monthList} monthValues={monthValues}/>}
      />
    {minHeight.y < 0 && (
      <VictoryBar
          name="domain-bar"
          data={invertedBarData}
          barRatio={0.65}
          height={500}
          style={{ data: { fill: "white", stroke: lightBlue, strokeWidth: 2 } }}
        />
    )}
      <VictoryBar
        name="domain-bar"
        data={emptyBarData}
        barRatio={0.65}
        label={10}
        y0={() => minHeight.y >= 0 ? minHeight.y : 0}
        height={500}
        style={{ data: { fill: "white", stroke: lightBlue, strokeWidth: 2, height: '5600px' } }}
      />
      <VictoryBar
        name="metric-bar"
        data={getDisplayData(actualData, maxHeight.y, minHeight.y)}
        y0={() => minHeight.y >= 0 ? minHeight.y : 0}
        barRatio={0.65}
        labels={({ datum }) => datum.periodStatus === MetricPeriodStatuses.NON_WORKING.value ? "" : datum.realMetric}
        style={{
          labels: {fill: "black"},
          fontWeight: 800,
          data: {
            fill: ({ datum: { status, periodStatus } }) => {
              if (periodStatus === MetricPeriodStatuses.NON_WORKING.value)
                return "transparent";

              return status === "RED" ? MetricStatuses[status].color : colour === "Green" ? '#28a745' : "#005e85";
            },
          },
        }}
      />


      {targetData && target[0]  !== "" && target[1] !== "" &&  !isNaN(target[0]) && !isNaN(target[1]) && (target[0] || target[0] === 0) && (target[1] || target[1] === 0) && (
        <VictoryLine
          name="target"
          data={targetData}
          style={{ data: { strokeWidth: 2 } }}
        />
      )}
    </VictoryChart>
  );
};

export default Chart;
