import { gql } from "@apollo/client";

export const GET_LEADERSHIP_ROUTINES_QUERY = gql`
  query {
    leadershipRoutines {
      id
      name
    }
  }
`;

export const GET_TEAM_LEADERSHIP_ROUTINES_QUERY = gql`
  query GetTeamLeadershipRoutines($id: ID!, $from: String!, $to: String!) {
    team(id: $id) {
      combinedMembers {
        id
        firstNames
        lastName
      }
      coach {
        id 
        firstNames
        lastName
      }
      routinePeriodMetrics(from: $from, to: $to) {
        id
        status
        date
      }
      teamLeadershipRoutines {
        id
        routine {
          id
          name
        }
        user {
          id
          firstNames
          lastName
        }
        frequency
        weeks
        days
        metrics(from: $from, to: $to) {
          id
          status
          date
        }
      }
    }
  }
`;

export const CREATE_TEAM_LEADERSHIP_ROUTINES_MUTATION = gql`
  mutation createRoutine($teamId: String!, $routineId: String!, $userId: String!, $frequency: PeriodFrequency!, $weeks: String, $days: String) {
    createTeamLeadershipRoutine(teamId: $teamId, routineId: $routineId, userId: $userId, frequency: $frequency, weeks: $weeks, days: $days) {
      id
      routine {
        id
        name
      }
      user {
        id
        firstNames
        lastName
      }
      frequency
      weeks
      days
    }
  }
`;

export const UPDATE_TEAM_LEADERSHIP_ROUTINES_MUTATION = gql`
  mutation updateRoutine($id: ID!, $userId: String!) {
    updateTeamLeadershipRoutine(id: $id, userId: $userId) {
      id
      routine {
        id
        name
      }
      user {
        id
        firstNames
        lastName
      }
      frequency
      weeks
      days
    }
  }
`;

export const DELETE_TEAM_LEADERSHIP_ROUTINES_MUTATION = gql`
  mutation deleteRoutine($id: ID!) {
    deleteTeamLeadershipRoutine(id: $id) {
      id
    }
  }
`;

export const CREATE_PERIOD_ROUTINE_MUTATION = gql`
  mutation createRoutinePeriodMetric(
    $id: String!
    $status: MetricPeriodStatus!
    $date: String!
  ) {
    createRoutinePeriodMetric(id: $id, status: $status, date: $date) {
      id
      status
      date
    }
  }
`;

export const UPDATE_PERIOD_ROUTINE_MUTATION = gql`
  mutation updateRoutinePeriodMetric(
    $id: ID!
    $status: MetricPeriodStatus!
  ) {
    updateRoutinePeriodMetric(id: $id, status: $status) {
      id
      status
      date
    }
  }
`;

export const CREATE_ROUTINE_METRIC_MUTATION = gql`
  mutation createRoutineMetric(
    $id: String!
    $status: RoutineStatus!
    $date: String!
  ) {
    createRoutineMetric(id: $id, status: $status, date: $date) {
      id
      status
      date
    }
  }
`;

export const UPDATE_ROUTINE_METRIC_MUTATION = gql`
  mutation updateRoutineMetric(
    $id: ID!
    $status: RoutineStatus!
  ) {
    updateRoutineMetric(id: $id, status: $status) {
      id
      status
      date
    }
  }
`;
