import React, { Component, Fragment } from "react";
import { Table, Row, Col, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import displayErrorToast from "../components/utils/displayErrorToast";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getMemberData, createMemberDashboardMetricData, updateMemberDashboardMetricData, createPartnerDashboardMetricData, updatePartnerDashboardMetricData } from "../services/MemberDashboardService";
import { getVision } from '../services/GoalService'
import LabelComponent from "../components/utils/getCompanyLabel";
import SubHeaderComponent from "../components/SubHeaderComponent";
import RealtimeRefreshComponent from "../components/RealtimeRefreshComponent";
import Excel from 'exceljs';
import { saveAs } from 'file-saver';

const workSheetName = 'Worksheet-1';
const columns = [
  { header: 'Full Name', key: 'fullName' },
  { header: 'Attendance', key: 'monthAttendancePercentage' },
  { header: 'Promise Recognition (Received)', key: 'valuesReceived' },
  { header: 'Promise Recognition (Raised)', key: 'valuesRaised' },
  { header: 'Innovations (Raised)', key: 'innovationsRaised' },
  { header: 'Innovations (Closed)', key: 'innovationsClosed' },
  { header: 'Next Steps (Assigned)', key: 'nextStepsAssigned' },
  { header: 'Next Steps (Completed On-Time)', key: 'nextStepsOnTime' },
  { header: 'Next Steps (Completed Late)', key: 'nextStepsLate' }
];

const getLabelText = key => <LabelComponent val={key}/>

class MemberDashboardScreen extends Component {
  state = {
    members: [],
    artners: [],
    leaders: [],
    loading: false,
    loadingId: "",
    loadingType: "",
    loadingPeriod: "",
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    selectedMonth: (new Date().getMonth() + "-" + new Date().getFullYear()),
    membersLoading: true,
    metricTypes: [
      {
        fieldName: "monthAttendanceStatus",
        fieldType: "ATTENDANCE",
        valueName: "monthAttendancePercentage"
      },
      {
        fieldName: "valuesReceivedStatus",
        fieldType: "VALUE_RECEIVED",
        valueName: "valuesReceived"
      },
      {
        fieldName: "valuesRaisedStatus",
        fieldType: "VALUE_RAISED",
        valueName: "valuesRaised"
      },
      {
        fieldName: "innovationsRaisedStatus",
        fieldType: "INNOVATION_RAISED",
        valueName: "innovationsRaised"
      },
      {
        fieldName: "innovationsClosedStatus",
        fieldType: "INNOVATION_CLOSED",
        valueName: "innovationsClosed"
      },
      {
        fieldName: "nextStepsAssignedStatus",
        fieldType: "NEXT_STEP_ASSIGNED",
        valueName: "nextStepsAssigned"
      },
      {
        fieldName: "nextStepsOnTimeStatus",
        fieldType: "NEXT_STEP_ON_TIME",
        valueName: "nextStepsOnTime"
      },
      {
        fieldName: "nextStepsLateStatus",
        fieldType: "NEXT_STEP_LATE",
        valueName: "nextStepsLate"
      }
    ],
    selectedPeriod: "MONTH",
    startMonth: "",
    meetingMembers: []
  }

  constructor (props) {
    super (props)

    this.props.togglePageLoad(true)
  }

  async componentDidMount() {
    if(this.props.selectedTeam.id) {
      await this.fetchMemberData()
      this.props.setAdditionalParam(this.state.selectedMonth)
      this.pageLoading(false)
      this.props.togglePageLoad(false)
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.selectedTeam.id !== this.props.selectedTeam.id) {
      this.props.togglePageLoad(true)
      this.pageLoading(true)
      await this.fetchMemberData()
      this.pageLoading(false)
      this.props.togglePageLoad(false)
    }

    if (this.props.refresh && prevProps.refresh !== this.props.refresh) {
      this.props.togglePageLoad(true)
      this.pageLoading(true)
      await this.fetchMemberData()
      this.props.toggleRefresh()
      this.props.toggleHasNewData()
      this.pageLoading(false)
      this.props.togglePageLoad(false)
    }
  }

  getLabelText = key => <LabelComponent val={key}/>

  fetchMemberData = async () => {
    const { selectedMonth } = this.state
    const response = await getMemberData(this.props.selectedTeam.id, selectedMonth);
    const company = await getVision()
     const members = [...response.data.team.leaders.sort((a, b) => a.firstNames.localeCompare(b.firstNames)), ...response.data.team.members.sort((a, b) => a.firstNames.localeCompare(b.firstNames))]

    this.setState({
      members: members,
      meetingMembers: response.data.team.meetingMembers,
      partners: response.data.team.valuePartners,
      leaders: response.data.team.leaders.sort((a, b) => a.firstNames.localeCompare(b.firstNames)),
      startMonth: company.data.company.finalcialYearEnd
    })
  }

  setLoading(id, type, period) {
    this.setState({
      loading: true,
      loadingId: id,
      loadingType: type,
      loadingPeriod: period
    })
  }

  cancelLoading() {
    this.setState({
      loading: false,
      loadingId: "",
      loadingType: "",
      loadingPeriod: ""
    })
  }

  pageLoading = loading => {
    this.setState({ membersLoading: loading })
  }

  setSelectedMonth = month => {
    this.setState({
      selectedMonth: month,
      selectedPeriod: month.length > 4 ? "MONTH" : "YEAR",
    }, async () => {
      this.pageLoading(true)
      await this.fetchMemberData()
      this.props.setAdditionalParam(this.state.selectedMonth)
      this.pageLoading(false)
    })
  }


  toggleMetricStatus = async(status, type, period, obj) => {
    this.setLoading(obj.id, type, period)
    const isUser = !obj.department

    try {
      if(status) {
        if(isUser) {
          await updateMemberDashboardMetricData(status.id, !status.status)
        } else {
          await updatePartnerDashboardMetricData(status.id, !status.status)
        }
      } else {
        if(isUser) {
          await createMemberDashboardMetricData(this.props.selectedTeam.id, obj.id, this.state.selectedMonth, period, type)
        } else {
          await createPartnerDashboardMetricData(this.props.selectedTeam.id, obj.id, this.state.selectedMonth, period, type)
        }
      }
      await this.fetchMemberData()
      this.cancelLoading()
    } catch (err) {
      displayErrorToast(err)
      this.cancelLoading()
    }
  }

  renderMetricToggle = (member, metric, type) => {
    const { loading, loadingId, loadingType, selectedPeriod } = this.state
    return (
      <div
        className={"toggle-" + (member.stats[metric.fieldName] && !member.stats[metric.fieldName].status ? "red" : "blue color-selected") + (loading ? " disabled" : "")}
        onClick={() => this.toggleMetricStatus(member.stats[metric.fieldName], metric.fieldType, selectedPeriod, member)}
        >
        {loading && loadingType === metric.fieldType && loadingId === member.id
          ? (
            <FontAwesomeIcon className="text-white m-auto fa-spin" icon={faSpinner} />
          ) : (
            (member.stats[metric.valueName] === null ? "0" : member.stats[metric.valueName]) + (metric.fieldType === "ATTENDANCE" ? " %" : "")
          )
        }
      </div>
    )
  }

  renderMemberRow = (member, key) => {
    const { metricTypes } = this.state
    const { selectedTeam } = this.props
    let metrics = metricTypes;
    if(selectedTeam.id !== 0 && selectedTeam.department.departmentType.level === "LVL2") {
      metrics = metrics.filter(met => met.fieldType !== "ATTENDANCE")
    }
    if (member) {
      return (
        <tr key={key}>
          <td>{member.firstNames + " " + member.lastName}</td>
          {metrics.map((metric, key) => {
            return (
              <td key={key}>
                { this.renderMetricToggle(member, metric) }
              </td>
            )
          })}
        </tr>
      )
    } else {
      return null
    }
  }

  renderPartnerRow = (partner, key, type) => {
    const { metricTypes } = this.state
    const metricType = metricTypes.find(type => type.fieldType === "ATTENDANCE")
    const name = type === "USER" ? `${partner.firstNames} ${partner.lastName}` : `${partner.department.name} (${partner.name})`
    if (partner) {
      return (
        <tr key={key}>
          <td>{name}</td>
            <td key={key}>
              { this.renderMetricToggle(partner, metricType) }
            </td>
        </tr>
      )
    } else {
      return null
    }
  }

  getOptions = () => {
    const {startMonth, months} = this.state
    let array = []
    const date = new Date()
    const currentMonth = date.getMonth()
    const fiscalStartMonth = months.findIndex(month => startMonth === month)
    // add a month of previous YTD
    const preivousYTDMonthDate = new Date((currentMonth < fiscalStartMonth ? date.getFullYear() - 1 : date.getFullYear()), (fiscalStartMonth - 1), 1)
    array.push({
      value: preivousYTDMonthDate.getMonth() + "-" + preivousYTDMonthDate.getFullYear(),
      text: months[preivousYTDMonthDate.getMonth()] + " " + preivousYTDMonthDate.getFullYear() + " (Previous Year)"
    })

    for(let i = fiscalStartMonth; i < months.length; i++) {
      const value = new Date((currentMonth < fiscalStartMonth ? date.getFullYear() - 1 : date.getFullYear()), (i + 1), 0)
      array.push({
        value: value.getMonth() + "-" + value.getFullYear(),
        text: months[i] + " " + value.getFullYear()
      })
    }

    for(let i = 0; i < fiscalStartMonth; i++) {
      const value = new Date((currentMonth < fiscalStartMonth ? date.getFullYear() : date.getFullYear() + 1), (i + 1), 0)
      array.push({
        value: value.getMonth() + "-" + value.getFullYear(),
        text: months[i] + " " + value.getFullYear()
      })
    }
    array.push({
      value: currentMonth < fiscalStartMonth ? date.getFullYear() - 1 : date.getFullYear(),
      text: "YTD"
    })
    array.push({
      value: currentMonth < fiscalStartMonth ? date.getFullYear() - 2 : date.getFullYear() -1,
      text: "YTD (Previous Financial Year)"
    })
    return array

  }

  saveExcel = async () => {
    const workbook = new Excel.Workbook();
    try {
      let allMembers = this.state.members;
      
      let teamName;
      if (this.props.selectedTeam.id !== 0) {
        teamName = this.props.selectedTeam.name;
      }
      let monthYear = this.state.selectedMonth.split("-");
      let selectMonthText = "";
      if (monthYear.length === 1) {
        selectMonthText = monthYear[0];
      }
      else {
        selectMonthText = this.state.months[monthYear[0]] + "-" + monthYear[1];
      }

      const fileName = teamName + "-" + selectMonthText + "-Member-Dashboard";
      let teamMembers = [];
      if (allMembers != null && allMembers.length > 0) {
        teamMembers = allMembers.map((member, index) => {
          let stats = member.stats;
          return {
            ...stats,
            monthAttendancePercentage: member.stats.monthAttendancePercentage + " %",
            fullName: member.firstNames + " " + member.lastName
          }
        });
      }

      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(workSheetName);

      // add worksheet columns
      // each columns contains header and its mapping key from data
      worksheet.columns = columns;

      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true };

      // loop through all of the columns and set the alignment with width.
      worksheet.columns.forEach(column => {
        column.width = column.header.length;
        column.alignment = { horizontal: 'left' };
      });

      teamMembers.forEach(singleEvent => {
        worksheet.addRow(singleEvent);
      });

      // loop through all of the rows and set the outline style.
      worksheet.eachRow({ includeEmpty: false }, row => {
        // store each cell to currentCell
        const currentCell = row._cells;

        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach(singleCell => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;

          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        });
      });

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error('Error saving file - ', error.message);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
    }
  };

  render() {
    const { members, membersLoading, selectedMonth, partners, leaders, meetingMembers } = this.state
    return (
      <Fragment>
        <SubHeaderComponent>
          <Col className="text-right childenSpace">
            <div className={"refreshBtn"}>
              <RealtimeRefreshComponent
                refresh={this.props.refresh}
                hasNewData={this.props.hasNewData}
                toggleRefresh={this.props.toggleRefresh}
              />
            </div>
            <select className="btn btn-primary mr-2 ml-2" value={selectedMonth} onChange={(e) => this.setSelectedMonth(e.target.value)}>
              {this.state.startMonth !== "" && this.getOptions().map((month) => {
                return <option value={month.value} key={month.value}>{month.text}</option>
              })}
            </select>

            <Button className="ml-1" onClick={(e) => this.saveExcel()}>Export</Button>
          </Col>
        </SubHeaderComponent>
        <div className="grey-header-space">
          {this.props.selectedTeam.id !== 0 && this.props.selectedTeam.department.departmentType.level === "LVL2" ? (
              <Row className="scroller mb-3">
                <Col className="pr-0">
                  <Table className="member-dashboard-table">
                    <thead>
                    <tr className="table-heading-text table-heading-background">
                      <th width="20%">{getLabelText("name")}</th>
                      <th width="10%">{getLabelText("attendance")}</th>
                      <th width="70%"></th>
                    </tr>
                    <tr>
                      <th></th>
                      <th width="10%"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {membersLoading
                        ? (
                            <tr>
                              <td colSpan="3" className="text-center">
                                <FontAwesomeIcon className="color-blue fa-spin fa-3x" icon={faSpinner} />
                              </td>
                            </tr>
                        ) : partners.length > 0 ? partners.map((partner, key) => this.renderPartnerRow(partner, key, "PARTNER")) : null}
                    {!membersLoading ? leaders.map((leader, key) => this.renderPartnerRow(leader, key, "USER")) : null}
                    {!membersLoading ? members.filter(member => meetingMembers.find(metMem => metMem.id === member.id)).map((leader, key) => this.renderPartnerRow(leader, key, "USER")) : null}
                    </tbody>
                  </Table>
                </Col>
              </Row>
          ) : null}
          <Row className="scroller mb-3">
            <Col className="pr-0">
              <Table className="member-dashboard-table">
                <thead>
                <tr className="table-heading-text table-heading-background">
                  <th width="20%">{getLabelText("full_name")}</th>
                  {this.props.selectedTeam.id !== 0 && this.props.selectedTeam.department.departmentType.level === "LVL2" ? null : (
                      <th width="10%">{getLabelText("attendance")}</th>
                  )}
                  <th colSpan="2" width="20%">{getLabelText("value_rewards")}</th>
                  <th colSpan="2" width="20%">{getLabelText("innovations")}</th>
                  <th colSpan="3" width="30%">{getLabelText("next_steps_screen")}</th>
                </tr>
                <tr>
                  <th></th>
                  {this.props.selectedTeam.id !== 0 && this.props.selectedTeam.department.departmentType.level === "LVL2" ? null : (
                      <th width="10%"></th>
                  )}
                  <th width="10%">{getLabelText("received")}</th>
                  <th width="10%">{getLabelText("raised")}</th>
                  <th width="10%">{getLabelText("raised")}</th>
                  <th width="10%">{getLabelText("closed")}</th>
                  <th width="10%">{getLabelText("assigned")}</th>
                  <th width="10%">{getLabelText("completed_on_time")}</th>
                  <th width="10%">{getLabelText("completed_late")}</th>
                </tr>
                </thead>
                <tbody>
                {membersLoading
                    ? (
                        <tr>
                          <td colSpan="9" className="text-center">
                            <FontAwesomeIcon className="color-blue fa-spin fa-3x" icon={faSpinner} />
                          </td>
                        </tr>
                    ) : members.length > 0 ? members.map((member, key) => this.renderMemberRow(member, key)) : null}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam,
  user: state.userReducer.user
})

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MemberDashboardScreen)
