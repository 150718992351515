import { client } from "./BaseService";
import { gql } from "apollo-boost";

export const getProblems = async (id = null) => {
    if(id) {
      return await client.query({
        query: gql`query{
          team(id: "${id}") {
            id
            name
            loggedIssues(type: PROBLEM_SOLVING_ISSUE ) {
              id
              whyText
              whatText
              archived
              reviewDate
              origin {
                id
                measurement
              }
              creator {
                id
              }
              actions {
                id
                status
                actionType
                description
                who {
                  id
                  firstNames
                }
                when
                archived
                escalated
                linkedIssue {
                  id
                  innovationStatus
                }
              }
            }
          }
        }`
      })
    } else {
      return await client.query({
        query: gql`query{
          teams {
            id
            name
            loggedIssues(type: PROBLEM_SOLVING_ISSUE ) {
              creator {
                id
                name
                department {
                  id
                  name
                }
              }
              id
              whyText
              whatText
              archived
              reviewDate
              origin {
                id
                measurement
              }
              actions {
                id
                status
                actionType
                description
                who {
                  id
                  firstNames
                }
                when
                archived
                escalated
              }
            }
          }
        }`
      })
    }
  }

  export const createProblem = async(creatorId, whatText, whyText, date, origin, metricId = null) => {
    if(metricId) {
      return await client.mutate({
        mutation: gql`mutation {
          createIssue(
            creatorId: "${creatorId}",
            reviewDate: "${date}",
            type: PROBLEM_SOLVING_ISSUE,
            measurementId: "${origin}",
            metricId: "${metricId}"
          ) {
            id
          }
        }`
      })
    } else if(origin) {
      return await client.mutate({
        mutation: gql`mutation {
          createIssue(
            creatorId: "${creatorId}",
            whatText: "${whatText}",
            whyText: "${whyText}",
            reviewDate: "${date}",
            type: PROBLEM_SOLVING_ISSUE,
            measurementId: "${origin}"
          ) {
            id
          }
        }`
      })
    } else {
      return await client.mutate({
        mutation: gql`mutation {
          createIssue(
        		creatorId: "${creatorId}",
            whatText: "${whatText}",
            whyText: "${whyText}",
            reviewDate: "${date}",
            type: PROBLEM_SOLVING_ISSUE
          ) {
            id
          }
        }`
      })
    }
  }

  export const updateProblem = async(id, whatText, whyText, date, origin) => {
    if(origin) {
      return await client.mutate({
        mutation: gql`mutation {
          updateIssue (
        		id: "${id}",
            whatText: "${whatText}",
            whyText: "${whyText}",
            reviewDate: "${date}",
            measurementId: "${origin}"
          ) {
            id
          }
        }`
      })
    } else {
      return await client.mutate({
        mutation: gql`mutation {
          updateIssue (
        		id: "${id}",
            whatText: "${whatText}",
            whyText: "${whyText}",
            reviewDate: "${date}"
          ) {
            id
          }
        }`
      })
    }
  }

  export const getMeasurements = async (id) => {
    return await client.query({
      query: gql`query {
        teamOriginMeasurements(id: "${id}") {
          id
          measurement
          hasGoals
          hasTeamMeasures
          unit {
            id
            name
          }
        }
      }`
    })
  }

  export const getProblemsIssues = async (all = false, archived = false, id = null) => {
    return await client.query({
      query: gql`query {
        getIssues(all: ${all}, archived: ${archived}, id: "${id}") 
      }`
    })
  }
