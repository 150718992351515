import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import { connect } from 'react-redux';

import Header from "./components/Header";
import Container from "./components/Container";
import SuccessModal from "./components/Modal";
import SubHeaderComponent from "../../components/SubHeaderComponent";
import RealtimeRefreshComponent from "../../components/RealtimeRefreshComponent";
import {
  GET_RECOGNITIONS,
  GET_TEAM_SUCCESSES,
  CREATE_TEAM_SUCCESS_MUTATION,
  UPDATE_TEAM_SUCCESS_MUTATION,
  DELETE_TEAM_SUCCESS_MUTATION
} from "./resources/gql";
import { getNextIndex, getPeriod, getNextPeriod, getMonthIndex, getMonthFromIndex } from "./resources/hooks";
import * as DefaultLabels from '../../assets/glossary.json';
import LabelComponent from "../../components/utils/getCompanyLabel";
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import {getTeamSuccessForExport} from '../../services/TeamService'

const workSheetName = 'Worksheet-1';
const labels = DefaultLabels.default;
const getLabelText = key => <LabelComponent val={key}/>


const TeamSuccessScreen = ({ selectedTeam, user, togglePageLoad, refresh, hasNewData, toggleRefresh, toggleHasNewData }) => {
  const [recognitions, setRecognitions] = useState([]);
  const [selectedRecognition, setSelectedRecognition] = useState(null)
  const [successes, setSuccesses] = useState([]);
  const [selectedSuccess, setSelectedSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const [loadingContent, setLoadingContent] = useState(false);

  const onRecognitionsLoadedComplete = useCallback(
    (data) => {
      setRecognitions(data.recognitionTypes);
      setSelectedRecognition(data.recognitionTypes[0]);
    },
    [setRecognitions]
  );

  useQuery(GET_RECOGNITIONS, {
    onCompleted: onRecognitionsLoadedComplete,
  });

  const onTeamSuccessesLoadedComplete = useCallback(
    (data) => {
      setSuccesses(data.teamSuccesses);
      if(refresh) {
        toggleRefresh()
        toggleHasNewData()
      }
      togglePageLoad(false);
      setLoadingContent(false);
    },
    [setSuccesses, togglePageLoad, setLoadingContent, refresh, toggleRefresh, toggleHasNewData]
  );

  const [fetchTeamSuccesses] = useLazyQuery(GET_TEAM_SUCCESSES, {
    onCompleted: onTeamSuccessesLoadedComplete
  });

  const onCancel = useCallback(
    () => {
      setSelectedSuccess(null)
      setShowModal(false)
      setDeleting(false)
    },
    [setSelectedSuccess, setShowModal, setDeleting]
  );

  const onCreateSuccessCompleted = useCallback(
    (data) => {
      setSuccesses([...successes, data.createTeamSuccess]);
      setLoading(false);
      onCancel();
    },
    [setSuccesses, setLoading, onCancel, successes]
  );

  const [createSuccess] = useMutation(CREATE_TEAM_SUCCESS_MUTATION, {
    onCompleted: onCreateSuccessCompleted
  });

  const onUpdateSuccessCompleted = useCallback(
    (data) => {
      setSuccesses(successes.map(success => {
        return success.id === data.updateTeamSuccess.id ? data.updateTeamSuccess : success
      }));
      setLoading(false);
      onCancel();
    },
    [setSuccesses, setLoading, onCancel, successes]
  );

  const [updateSuccess] = useMutation(UPDATE_TEAM_SUCCESS_MUTATION, {
    onCompleted: onUpdateSuccessCompleted
  });

  const onDeleteSuccessCompleted = useCallback(
    (data) => {
      setSuccesses(successes.filter(success => success.id !== data.deleteTeamSuccess.id));
      setLoading(false);
      onCancel();
    },
    [setSuccesses, setLoading, onCancel, successes]
  );

  const [deleteSuccess] = useMutation(DELETE_TEAM_SUCCESS_MUTATION, {
    onCompleted: onDeleteSuccessCompleted
  });

  const _onDelete = id => {
    deleteSuccess({
      variables: {
        id
      }
    });
    setDeleting(true);
  }

  const getText = val => {
    const companyId = localStorage.getItem("companyId");
    let lang = "EN";
    let langLabels = labels.find(lbl => lbl.lang === lang);
    let def = langLabels.glossary.find(label => label.key === val);

    if(user && user.companies && user.companies.length) {
      const company = user.companies.find(company => company.id === companyId);
      if(company) {
        lang = company.language;
        langLabels = labels.find(lbl => lbl.lang === lang);
        def = langLabels.glossary.find(label => label.key === val);
        if(!def) {
          lang = "EN";
          langLabels = labels.find(lbl => lbl.lang === lang);
          def = langLabels.glossary.find(label => label.key === val);
        }
      }
      if(company && company.companyGlossaries) {
        const custom = company.companyGlossaries.find(label => label.key === val);

        if(custom) return custom.value
      }
    }

    if(!def) {
      console.log(val)
    }
    return def.value
  }

  useEffect(() => {
    togglePageLoad(true);
    if(selectedTeam.id === 0) return;
    const period = getPeriod(selectedTeam.company.finalcialYearEnd);
    setSelectedPeriod(period)
  }, [selectedTeam, setSelectedPeriod, togglePageLoad]);

  useEffect(() => {
    if(selectedTeam.id === 0 || !recognitions.length || !selectedRecognition || !selectedPeriod) return;
    fetchTeamSuccesses({
      variables: {
        teamId: selectedTeam.id,
        period: selectedPeriod,
        recognitionId: selectedRecognition.id
      }
    });

  }, [selectedRecognition, selectedTeam, recognitions, fetchTeamSuccesses, selectedPeriod]);

  useEffect(() => {
    if(refresh) {
      togglePageLoad(true)
      fetchTeamSuccesses({
        variables: {
          teamId: selectedTeam.id,
          period: selectedPeriod,
          recognitionId: selectedRecognition.id
        }
      });
    }
  }, [refresh, toggleRefresh, toggleHasNewData, togglePageLoad, fetchTeamSuccesses, selectedTeam, selectedPeriod, selectedRecognition])

  const setNext = (back = false) => {
    const index = recognitions.indexOf(selectedRecognition);
    const nextIndex = getNextIndex(index, recognitions.length, back);
    setLoadingContent(true);
    setSelectedRecognition(recognitions[nextIndex]);
  }

  const setSelected = recognition => {
    setSelectedRecognition(recognition);
    setLoadingContent(true);
  }

  const setNextPeriod = (back = false) => {
    const nextPeriod = getNextPeriod(selectedPeriod, back);
    setLoadingContent(true);
    setSelectedPeriod(nextPeriod);
  }

  const onSave = (selectedUser) => {
    if(selectedSuccess.id === 0) {
      return createSuccess({
        variables: {
          teamId: selectedTeam.id,
          recognitionId: selectedRecognition.id,
          userId: selectedUser,
          period: selectedPeriod,
          month: selectedSuccess.month
        }
      })
    }

    return updateSuccess({
      variables: {
        id: selectedSuccess.id,
        userId: selectedUser
      }
    })
  }

  const getSuccessUser = (monthIndex, teamSuccesses, recognitionId) => {
    let success = teamSuccesses.filter(s => s.month === (monthIndex + 1) && s.recognition.id === recognitionId);
    if (success != null && success.length > 0) {
      return success[0].user.firstNames + " " + success[0].user.lastName;
    }
    return "";
  }

  const saveExcel = async () => {
    const workbook = new Excel.Workbook();
    try {

     let columns = [];
     columns.push({ header: "Month" , key: "month" });
     columns.push({ header: "Celebration Category" , key: "category" });
     columns.push({ header: "Name" , key: "name" });

      //selectedRecognition
      const response = await getTeamSuccessForExport(selectedTeam.id, selectedPeriod);
      let teamSuccesses = response.data.teamSuccessesForExport;
      let successStories = [];
      let startMonth = selectedTeam.id !== 0 ? selectedTeam.company.finalcialYearEnd : "January";
      let startMonthIndex = getMonthIndex(startMonth);
      //successes
      for (let r = 0; r < recognitions.length; r++) {
        for (let i = startMonthIndex; i < (startMonthIndex + 12); i++) {
          let successObject = {};
          if (i < 12) {
            successObject = {
              month: getMonthFromIndex(i),
              category: recognitions[r].name,
              name: getSuccessUser(i, teamSuccesses, recognitions[r].id)
            };
          }
          else {
            successObject = {
              month: getMonthFromIndex(i - 12),
              category: recognitions[r].name,
              name: getSuccessUser(i - 12, teamSuccesses, recognitions[r].id)
            };
          }
          successStories.push(successObject);
        }
      }

       let yearText = selectedPeriod;
       let teamName;
      if (selectedTeam.id !== 0) {
        teamName = selectedTeam.name;
      }
      const fileName = teamName + "_" + yearText + "_Celebrating_Success";
      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(workSheetName);
  
      // add worksheet columns
      // each columns contains header and its mapping key from data
      worksheet.columns = columns;
  
      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true };
  
      // loop through all of the columns and set the alignment with width.
      worksheet.columns.forEach(column => {
        column.width = 15;
        column.alignment = { horizontal: 'left' };
      });
  
      successStories.forEach(success => {
        worksheet.addRow(success);
      });
  
      // loop through all of the rows and set the outline style.
      worksheet.eachRow({ includeEmpty: false }, row => {
        // store each cell to currentCell
        const currentCell = row._cells;
  
        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach(singleCell => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;
  
          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        });
      });
  
      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();
  
      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error('Error saving file - ', error.message);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
    }
  };


  return (
    <Fragment>
      <SubHeaderComponent>
        <Col className="text-right childenSpace">
          <div className={"refreshBtn"}>
            <RealtimeRefreshComponent
              refresh={refresh}
              hasNewData={hasNewData}
              toggleRefresh={toggleRefresh}
            />
          </div>
        </Col>
        <Button className="ml-2" onClick={(e) => saveExcel()}>Export</Button>
      </SubHeaderComponent>
      <div className="grey-header-space">
        <Row style={{ marginBottom: "60px" }}>
          <Col className="pr-0">
            <Header
                recognitions={recognitions}
                selectedRecognition={selectedRecognition}
                setNext={setNext}
                setSelected={setSelected}
            />
            {selectedTeam.id !== 0 && recognitions.length && selectedPeriod ? (
                <Fragment>
                  <Container
                      successes={successes}
                      period={selectedPeriod}
                      firstMonth={selectedTeam.id !== 0 ? selectedTeam.company.finalcialYearEnd : "January"}
                      setSelectedSuccess={setSelectedSuccess}
                      setShowModal={setShowModal}
                      selectedSuccess={selectedSuccess}
                      users={selectedTeam.combinedMembers}
                      setNext={setNextPeriod}
                      loadingContent={loadingContent}
                      getLabelText={getLabelText}
                  />
                  <SuccessModal
                      selectedSuccess={selectedSuccess}
                      save={onSave}
                      users={selectedTeam.combinedMembers}
                      loading={loading}
                      setLoading={setLoading}
                      cancel={onCancel}
                      showModal={showModal}
                      deleting={deleting}
                      deleteItem={_onDelete}
                      getText={getText}
                  />
                </Fragment>
            ) : null}
          </Col>

        </Row>
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam,
  user: state.userReducer.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TeamSuccessScreen);
