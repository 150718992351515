import React, {Fragment} from 'react';
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Colours = ({colours, selectedColor, setColour}) => {
    const style = {
        height: "24px",
        width: "24px",
        margin: "4px",
        borderRadius: "2px",
        opacity: "0.9",
        cursor: "pointer"
    }

    if(!selectedColor)
    {
        selectedColor = colours[0].id;
        setColour(selectedColor);
    }

    return (
        <Fragment>
            <div className="d-flex align-items-center">
                {
                    colours.map((color) => {
                        return (
                            <div
                                key={color.id}
                                className="d-flex justify-content-center align-items-center"
                                style={{
                                    ...style,
                                    backgroundColor: color.bgColour,
                                    height: selectedColor === color.id ? style.height : style.height,
                                    width: selectedColor === color.id ? style.width : style.width,
                                    margin: selectedColor === color.id ? style.margin : style.margin,
                                    opacity: selectedColor === color.id ? "1" : style.opacity
                                }}
                                onClick={() => setColour(color.id)}
                                title={color.name}
                            >
                                {selectedColor === color.id ? <FontAwesomeIcon icon={faCheck} style={{ color: color.fgColour }}  /> : "" }
                            </div>
                        )
                    })
                }
            </div>
        </Fragment>
    )
}

export default Colours;