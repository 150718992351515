const FETCH_TEAM_STARTED = 'FETCH_TEAM_STARTED';
const FETCH_TEAM_FAILED = 'FETCH_TEAM_FAILED';
const FETCH_TEAM_SUCCESS = 'FETCH_TEAM_SUCCESS';

const FETCH_TEAMS_STARTED = 'FETCH_TEAMS_STARTED';
const FETCH_TEAMS_FAILED = 'FETCH_TEAMS_FAILED';
const FETCH_TEAMS_SUCCESS = 'FETCH_TEAMS_SUCCESS';

const FETCH_TEAM_MEASUREMENTS_STARTED = 'FETCH_TEAM_MEASUREMENTS_STARTED';
const FETCH_TEAM_MEASUREMENTS_FAILED = 'FETCH_TEAM_MEASUREMENTS_FAILED';
const FETCH_TEAM_MEASUREMENTS_SUCCESS = 'FETCH_TEAM_MEASUREMENTS_SUCCESS';

const SELECT_TEAM = 'SELECT_TEAM';

const UPDATE_PAGE_PERMISSIONS= 'UPDATE_PAGE_PERMISSIONS';

export {
  FETCH_TEAM_STARTED,
  FETCH_TEAM_FAILED,
  FETCH_TEAM_SUCCESS,
  FETCH_TEAMS_STARTED,
  FETCH_TEAMS_FAILED,
  FETCH_TEAMS_SUCCESS,
  SELECT_TEAM,
  FETCH_TEAM_MEASUREMENTS_STARTED,
  FETCH_TEAM_MEASUREMENTS_FAILED,
  FETCH_TEAM_MEASUREMENTS_SUCCESS,
  UPDATE_PAGE_PERMISSIONS
};
