import React, { Component, Fragment } from "react";
import { FormControl, Row, Col } from "react-bootstrap";
import { faEdit, faCheck, faBan, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCompany, updateCompany } from "../services/CompanyService";
import { toast } from 'react-toastify';
import CountriesList from "../components/CountriesList";
import { connect } from 'react-redux';
import { fetchUser } from '../redux/services/UserService';
import { fetchTeams } from '../redux/services/TeamService';
import SubHeaderComponent from "../components/SubHeaderComponent";
import * as languages from '../assets/languages.json';
import LabelComponent from "../components/utils/getCompanyLabel";

class CompanyScreen extends Component {
  state = {
    loading: false,
    loadType: "",
    edit: false,
    modified: false,
    company: [],
    currCompany: [],
    consultantId: "",
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    colours: [
      {
        name: "Green/Red",
        value: "Green"
      },
      {
        name: "Blue/Red",
        value: "Blue"
      }
    ],
    countries: [],
    isAdmin: true,
  }

  constructor(props) {
    super(props);

    this.props.toggleDirtyState(true);
    this.props.togglePageLoad(true);
  }

  async componentDidMount() {
    try {
      const response = await getCompany()
      const company = response.data.company
      let users = company.users;
      users = users.filter(
        (user) => {
          return user.role !== "Consultant" && !user.archived
        }
      );
      this.setState({
        company: company,
        registeredUserCount: users.length,
        colour: "#005e85"
      })

      localStorage.setItem("colour", company.color)

    } catch (error) {
      console.log(error)
      error.graphQLErrors.map(error => {
        toast.error(error.message)

        return true
      })
    }
    finally {
      this.props.togglePageLoad(false)
    }

  }

  getLabelText = key => <LabelComponent val={key}/>

  getField = (name, type, data, placeholder, array = null) => {
    const { edit, company } = this.state
    // Determine if input fields should be displayed or text fields
    if (edit) {
      switch (type) {
        case "input":
          // generate input field with given data.
          return (
            <FormControl
              autoFocus={name === "name"}
              type="text"
              disabled={name === "clientNumber"}
              placeholder={placeholder}
              name={name}
              value={data}
              onChange={(e) => this.handleChange(e)}
              autoComplete="off"
            />
          )
        case "select":
          // generate select box with given input.
          return (
            <select className="form-control cdi-ddl w-100 ml-0"
              name={name}
              value={data && data !== "null" ? data : 1}
              onChange={(e) => name === "consultant" ? this.handleConsultantChange(e.target.value) : this.handleChange(e)}
            >
              <option value="1" disabled>{placeholder}</option>
              {name === "country"
                ? <CountriesList />
              : name === "consultant" ? (
                company.users.filter(user => user.role === "Consultant").map((user, i) => (
                  <option key={i} value={user.id}>
                    {user.firstNames + " " + user.lastName}
                  </option>
                ))
              ) : (
                array.map((option, i) => (
                  <option key={i} value={option.value ? option.value : option}>
                    {option.name ? option.name : option}
                  </option>
                ))
              )
              }
            </select>
          )

        default:
          // Display value text if edit is set to false.
          return  <p className="cdi-blue-txt">{data ? data : this.getLabelText("no_data")}</p>
      }
    } else {
      // Display value text if edit is set to false.
      // why is this a string? hmmmf
      if(name === "consultant" && company.users) {
        const consultant = company.users.find(user => user.id === data)
        return <p className="cdi-blue-txt">{data && consultant ? consultant.firstNames + " " + consultant.lastName : this.getLabelText("no_data")}</p>
      }

      if(name === "language") {
        const language = array.find(lang => lang.value === data);

        return <p autoComplete="off" className="cdi-blue-txt">{data && data !== "undefined" && data !== "null" && language ? language.name : this.getLabelText("no_data")}</p>
      }

      if(name === "color") return <p className="cdi-blue-txt">{data && data !== "undefined" && data !== "null"
         ? this.state.colours.filter((colour) => {
           return colour.value === data
         })[0].name
         : this.getLabelText("no_data")}</p>

      return <p autoComplete="off" className="cdi-blue-txt">{data && data !== "undefined" && data !== "null" ? data : this.getLabelText("no_data")}</p>
    }

  }

  handleChange = (e) => {
    e.persist()
    this.setState((prevState) => ({
      company: {
        ...prevState.company,
        [e.target.name]: e.target.value
      },
      error: null,
      modified: true
    }))
  }

  handleConsultantChange = value => {
    const { company } = this.state;

    const user = company.users.find(user => user.id === value)
    this.setState((prevState) => ({
      company: {
        ...prevState.company,
        consultant: user,
      },
      consultantId: value,
      error: null,
      modified: true
    }))
  }

  handleSubmit = async () => {
    this.setState({
      loading: true,
      loadType: "edit"
    })
    try {
      const { company, consultantId } = this.state

      const response = await updateCompany(company, consultantId)
      await this.props.fetchUser()
      await this.props.fetchTeams()
      this.setState({
        edit: false,
        modified: false,
        company: response.data.updateCompany
      })
    } catch (error) {
      error.graphQLErrors.map(error => {
        toast.error(error.message)

        return true
      })
    }

    this.setState({
      loading: false,
      loadType: ""
    })
  }

  handleEnable = (enable) => {
    this.props.toggleDirtyState();

    const { company, currCompany } = this.state
    let options = {
      // Capture pre-edit company state on edit enabled in "currCompany". Set back to empty on edit Canceled.
      currCompany: enable ? company : [],
      edit: enable,
    }

    if (!enable) {
      // Set company state back to pre-edit state on cancel.
      options["company"] = currCompany;
    }

    this.setState(options)
  }

  render() {
    const { edit, company, isAdmin, colour, loading, loadType} = this.state

    return (
      <Fragment>
        <SubHeaderComponent className={"p-0"}>
          {isAdmin
            ? (
              <Row>
                {this.props.permissions.canEdit && (
                  <Col className="text-right">
                    {edit
                      ? (
                        <div className="active-edit">
                          <button className="btn btn-primary btn-nrml" disabled={!this.state.modified} onClick={this.handleSubmit} style={{ backgroundColor: colour, borderColor: colour }}>
                            {loading && loadType === "edit"
                              ? (
                                <Fragment>
                                  <span>{this.getLabelText("saving")}...</span>
                                  <FontAwesomeIcon className="ml-2 fa-spin" icon={faSpinner}/>
                                </Fragment>
                              )
                              : (
                                <Fragment>
                                  <span>{this.getLabelText("save")}</span>
                                  <FontAwesomeIcon className="ml-2" icon={faCheck} />
                                </Fragment>
                              )
                            }
                          </button>
                          <button className="btn btn-danger btn-nrml ml-2" onClick={() => this.handleEnable(false)}>{this.getLabelText("cancel")}<FontAwesomeIcon className="ml-2" icon={faBan} /></button>
                        </div>
                      )
                      : <button className="btn btn-primary btn-nrml" onClick={() => this.handleEnable(true)} style={{ backgroundColor: colour, borderColor: colour }}>{this.getLabelText("edit")}<FontAwesomeIcon className="ml-2" icon={faEdit} /></button>
                    }
                  </Col>
                )}
              </Row>
            ) : null
          }
        </SubHeaderComponent>
        <div className="grey-header-space container-left">
          <Row className="mt-20 mb-20 user-txt">
            <Col>
              <label className="cdi-txt-lbl">{this.getLabelText("client_number")}</label>
              {this.getField("clientNumber", "input", company.clientNumber, "Client Number")}
            </Col>
            <Col>
              <label className="cdi-txt-lbl">{this.getLabelText("admin_full_name")}</label>
              {this.getField("administratorFullName", "input", company.administratorFullName, "Administrator Full Name")}
            </Col>
            <Col>
              <label className="cdi-txt-lbl">{this.getLabelText("fin_year_month")}</label>
              {this.getField("finalcialYearEnd", "select", company.finalcialYearEnd, "Financial Year First Month", this.state.months)}
            </Col>
          </Row>
          <Row className="mt-20 mb-20 user-txt">
            <Col>
              <label className="cdi-txt-lbl">{this.getLabelText("company_name")}</label>
              {this.getField("name", "input", company.name, "Company Name")}
            </Col>
            <Col>
              <label className="cdi-txt-lbl">{this.getLabelText("admin_contact_number")}</label>
              {this.getField("administratorMobileNumber", "input", company.administratorMobileNumber, "Administrator Contact Number")}
            </Col>
            <Col md={4}>
              <label className="cdi-txt-lbl">{this.getLabelText("results_colour")}</label>
              {

                this.getField("color", "select", company.color, "Results Colour", this.state.colours)
              }
            </Col>
          </Row>
          <Row className="mt-20 mb-20 user-txt">
            <Col md={4}>
              <label className="cdi-txt-lbl">{this.getLabelText("country_registration")}</label>
              {this.getField("country", "select", company.country, "Country of Registration", this.state.countries)}
            </Col>
            <Col md={4}>
              <label className="cdi-txt-lbl">{this.getLabelText("admin_email")}</label>
              {this.getField("administratorEmail", "input", company.administratorEmail, "Administrator E-mail")}
            </Col>
            <Col md={4}>
              <label className="cdi-txt-lbl">{this.getLabelText("support_email")}</label>
              {this.getField("supportEmail", "input", company.supportEmail, "Support E-mail")}
            </Col>
          </Row>
          <Row className="mt-20 mb-20 user-txt">
            <Col md={4}>
              <label className="cdi-txt-lbl">{this.getLabelText("company_reg_number")}</label>
              {this.getField("countryRegistrationNumber", "input", company.countryRegistrationNumber, "Company Registration Number")}
            </Col>
            <Col md={4}>
              <label className="cdi-txt-lbl">{this.getLabelText("billing_person")}</label>
              {this.getField("billingContactPerson", "input", company.billingContactPerson, "Billing Contact Person")}
            </Col>
            <Col>
              <label className="cdi-txt-lbl">{this.getLabelText("num_reg_users")}</label>
              <p className="cdi-blue-txt">{this.state.registeredUserCount}</p>
            </Col>
          </Row>
          <Row className="mt-20 mb-20 user-txt">
            <Col md={4}>
              <label className="cdi-txt-lbl">{this.getLabelText("tax_number")}</label>
              {this.getField("vatNumber", "input", company.vatNumber, "VAT/Tax Number")}
            </Col>
            <Col md={4}>
              <label className="cdi-txt-lbl">{this.getLabelText("billing_email")}</label>
              {this.getField("billingEmail", "input", company.billingEmail, "Billing E-mail")}
            </Col>
            <Col>
              <label className="cdi-txt-lbl">{this.getLabelText("consultant")}</label>
              {this.getField("consultant", "select", (company.consultant ? company.consultant.id : null), "Consultant")}
            </Col>
          </Row>
          <Row className="mt-20 mb-20 user-txt">
            <Col md={4}>
              <label className="cdi-txt-lbl">{this.getLabelText("company_address")}</label>
              {this.getField("address", "input", company.address, "Company Address")}
            </Col>
            <Col md={4}>
              <label className="cdi-txt-lbl">{this.getLabelText("billing_number")}</label>
              {this.getField("billingContactNumber", "input", company.billingContactNumber, "Billing Contact Number")}
            </Col>
            <Col md={4}>
              <label className="cdi-txt-lbl">{this.getLabelText("language")}</label>
              {this.getField("language", "select", company.language, "Language", languages.default)}
            </Col>
          </Row>
        </div>
      </Fragment>
    )

  }
}

const mapStateToProps = () => ({
  // user: state.userReducer.user,
  // team: state.teamReducer.se,
  // isLoading: state.userReducer.isLoading,
});

const mapDispatchToProps = {
  fetchUser,
  fetchTeams
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyScreen);
