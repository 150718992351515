import React, { Fragment } from "react";
import IconButton from "../../../components/shared/IconButton";
// icons
import MoveDown from '../../../assets/ButtonIcons/Move Down Blue.svg';
import MoveUp from '../../../assets/ButtonIcons/Move Up Blue.svg';


const OrderButtons = ({ type, index, handleOrderChange, parentId = null, isLastItem, archive, getLabelText }) => archive ? null : (
  <Fragment>
    <IconButton tooltip={getLabelText("up")} eventHandler={() => handleOrderChange(type, "dec", index, parentId)} classNames={index === 0 ? "disabled" : ""}>
      <img src={MoveUp} alt="MoveUp" className="btn-icons"/>
    </IconButton>
    <IconButton tooltip={getLabelText("down")} eventHandler={() => handleOrderChange(type, "inc", index, parentId)} classNames={isLastItem ? "disabled" : ""}>
      <img src={MoveDown} alt="MoveDown" className="btn-icons"/>
    </IconButton>
  </Fragment>
);

export default OrderButtons;
