import {VictoryLabel} from "victory";
import React from "react";
import {lightBlue} from "./resources";

const ProblemSolvingLabelComponent = ({ style, data, ...props }) => {
    return (
        <VictoryLabel
            {...props}
            text={'PS'}
            backgroundPadding={{left: 4, right: 4, top: 1, bottom: 3}}
            className="xtxtxt"
            backgroundStyle={{
                backgroundColor: "transparent",
                borderRadius:  8,
                fill: ({ index }) => {
                    if (!data) return 'transparent';
                    const { issue, periodStatus } = data[index];
                    if(periodStatus !== 'TRIGGER') return 'transparent';
                    if(periodStatus === 'TRIGGER' && !issue) return '#BBBBBB';
                    if(issue)
                    {
                        if(!issue.nextStepStatus) return '#BBBBBB';
                        if(issue.nextStepStatus === 'PS_IN_PROGRESS') return "#FFFFFF";
                        if(issue.nextStepStatus === 'COMPLETED') return "#005E85";
                    }
                    return "transparent";
                },
                stroke: (data && data[props.datum - 1].issue && (data[props.datum - 1].issue.nextStepStatus == 'PS_IN_PROGRESS' || data[props.datum - 1].issue.nextStepStatus == 'COMPLETED')) ? lightBlue : null,
                strokeWidth: 1,
            }}
            style={{ ...style, fontSize: "16px" }}
        />
    );
};

export default ProblemSolvingLabelComponent;