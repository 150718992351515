import { gql } from "apollo-boost";
import { client } from "./BaseService";

const getAllCategoriesQuery = async (team) => {
    return await client.query({
        query: gql`query {
            categories(team: "${team}"){
                id
                name
                archived
            }
        }`
    })
}

const getCategoriesQuery = async (team, page, archived, download = false) => {
    return await client.query({
        query: gql`query {
            getTaskCategories(team: "${team}", page: ${page}, archived:${archived}, download:${download}) {
                meta {
                    page
                    pageLimit
                    totalPages
                    totalCount
                    nextPage
                    hasNext
                    hasPrevious
                    previousPage
                }
                categories {
                    id
                    name
                    archived
                    tasks {
                        id
                        name
                        archived
                    }
                }
            }
        }`
    })
}

const createTaskCategory = async ({ name, team }) => {
    return await client.mutate({
        mutation: gql`mutation {
            createTaskCategory(name: "${name}", team:"${team}") {
                id
                name
            }
        }`
    })
}

const updateTaskCategory = async ({ id, name, archived, deleted }) => {
    return await client.mutate({
        mutation: gql`mutation {
            updateTaskCategory(id: "${id}", name: "${name}", archived: ${archived}, deleted: ${deleted}) {
                id
                name
            }
        }`
    })
}

const createTask = async ({ name, category }) => {
    return await client.mutate({
        mutation: gql`mutation {
            createTask(name: "${name}", category:"${category}") {
                id
                name
            }
        }`
    })
}

const updateTask = async ({ id, name, archived, deleted }) => {
    return await client.mutate({
        mutation: gql`mutation {
            updateTask(id: "${id}", name: "${name}", archived: ${archived}, deleted: ${deleted}) {
                id
                name
            }
        }`
    })
}

const createTaskTracker = async (userId, teamId, taskId, frequency, weeks, days) => {
    return await client.mutate({
        mutation: gql`mutation {
            createTaskTracker(
                userId: "${userId}"
                teamId: "${teamId}"
                taskId: "${taskId}"
                frequency: ${frequency}
                weeks: "${weeks}"
                days: "${days}"
            ) {
                id
            }
        }`
    })
}

const updateTaskTracker = async (id, userId, teamId) => {
    return await client.mutate({
        mutation: gql`mutation {
            updateTaskTracker(
                id: "${id}"
                userId: "${userId}"
                teamId: "${teamId}"
            ) {
                id
            }
        }`
    })
}

const deleteTaskTracker = async (id, teamId) => {
    return await client.mutate({
        mutation: gql`mutation {
            deleteTaskTracker(
                id: "${id}"
                teamId: "${teamId}"
            ) {
                id
            }
        }`
    })
}

const getTaskTrackers = async (team, from, to) => {
    return await client.query({
        query: gql`query {
            team(id: "${team}") {
                id
                taskTrackers {
                    id
                    frequency
                    days
                    weeks
                    user {
                        id
                        firstNames
                        lastName
                    }
                    task {
                        id
                        name
                        taskCategory {
                            id
                            name
                        }
                    }
                    metrics(from: "${from}" to: "${to}") {
                        id
                        date
                        status
                    }
                }
                taskTrackersPeriodMetrics(from: "${from}" to: "${to}") {
                    id
                    date
                    status
                }
            }
        }`
    })
}

const createTaskTrackerMetric = async (taskTrackerId, status, date, teamId) => {
    return await client.mutate({
        mutation: gql`mutation {
            createTaskTrackerMetric(
                taskTrackerId: "${taskTrackerId}"
                status: ${status}
                date: "${date}"
                teamId: "${teamId}"
            ) {
                id
            }
        }`
    })
}

const CREATE_TASK_TRACKER_METRIC_MUTATION = gql`
    mutation createTaskTrackerMetric(
        $taskTrackerId: ID!
        $status: TaskTrackerStatus!
        $date: String!
        $teamId: ID!
    ) {
        createTaskTrackerMetric(taskTrackerId: $taskTrackerId, status: $status, date: $date, teamId: $teamId) {
            id
            status
            date
        }
    }
`;

const updateTaskTrackerMetric = async (id, status, teamId) => {
    return await client.mutate({
        mutation: gql`mutation {
            updateTaskTrackerMetric(
                id: "${id}"
                status: ${status}
                teamId: "${teamId}"
            ) {
                id
            }
        }`
    })
}

const UPDATE_TASK_TRACKER_METRIC_MUTATION = gql`
    mutation updateTaskTrackerMetric(
        $id: ID!
        $status: TaskTrackerStatus!
        $teamId: ID!
    ) {
        updateTaskTrackerMetric(id: $id, status: $status, teamId: $teamId) {
            id
            status
            date
        }
    }
`;

const createTaskTrackerPeriodMetric = async (taskTrackerId, status, date, teamId) => {
    return await client.mutate({
        mutation: gql`mutation {
            createTaskTrackerPeriodMetric(
                status: ${status}
                date: "${date}"
                teamId: "${teamId}"
            ) {
                id
            }
        }`
    })
}

const CREATE_TASK_TRACKER_PERIOD_MUTATION = gql`
    mutation createTaskTrackerPeriodMetric(
        $status: MetricPeriodStatus!
        $date: String!
        $teamId: ID!
    ) {
        createTaskTrackerPeriodMetric(status: $status, date: $date, teamId: $teamId) {
            id
            status
            date
        }
    }
`;

const updateTaskTrackerPeriodMetric = async (id, status, teamId) => {
    return await client.mutate({
        mutation: gql`mutation {
            updateTaskTrackerPeriodMetric(
                id: "${id}"
                status: ${status}
                teamId: "${teamId}"
            ) {
                id
            }
        }`
    })
}

const UPDATE_TASK_TRACKER_PERIOD_MUTATION = gql`
    mutation updateTaskTrackerPeriodMetric(
        $id: ID!
        $status: MetricPeriodStatus!
        $teamId: ID!
    ) {
        updateTaskTrackerPeriodMetric(id: $id, status: $status, teamId: $teamId) {
            id
            status
            date
        }
    }
`;

export {
    getAllCategoriesQuery,
    getCategoriesQuery,
    createTaskCategory,
    updateTaskCategory,
    createTask,
    updateTask,
    createTaskTracker,
    updateTaskTracker,
    deleteTaskTracker,
    getTaskTrackers,
    createTaskTrackerMetric,
    updateTaskTrackerMetric,
    createTaskTrackerPeriodMetric,
    updateTaskTrackerPeriodMetric,
    CREATE_TASK_TRACKER_PERIOD_MUTATION,
    UPDATE_TASK_TRACKER_PERIOD_MUTATION,
    CREATE_TASK_TRACKER_METRIC_MUTATION,
    UPDATE_TASK_TRACKER_METRIC_MUTATION
}