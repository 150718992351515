import React, { useState, Fragment, useEffect } from "react";
import Chart from "./Chart/Chart";
import ResultInput from "./ResultInput";
import Controls from "./Controls";
import Switch from "react-switch";
import FiscalYearDateSelector from "../../components/FiscalYearDateSelector";
import { toNextPeriodStatus } from "./resources";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import { faSpinner, faCaretSquareLeft, faCaretSquareRight } from "@fortawesome/free-solid-svg-icons";
import {
  useDynamicDomain,
  useMetricData,
  useCreateOrUpdateMetric, getPeriodKey, getPeriodDisplayText, getCalculatedCurrentTarget,
  useCreateOrUpdateTeamGoalGraphConfig,
  getPeriodValue,
  getWeeklyPeriodValue
} from "./hooks";
import displayErrorToast from "../../components/utils/displayErrorToast";
import LabelComponent from "../../components/utils/getCompanyLabel";
// icons;
import Cancel from '../../assets/ButtonIcons/Cancel Blue.svg';
import Edit from '../../assets/ButtonIcons/Edit Blue.svg';
import Loader from '../../assets/ButtonIcons/Loader Blue.svg';
import Save from '../../assets/ButtonIcons/Save Blue.svg';
import { useSelector } from "react-redux";
import {createProblem} from "../../services/ProblemSolvingService";

const getLabelText = key => <LabelComponent val={key}/>
const LOWER_TRIGGER = [0, 8];

const TeamGoalsContent = ({ date, setDate, skipToNext, skipToPrev, goal, isLoading, deltaUpdate, selectedTeam, refreshGraph, isTrend, monthList, monthValues, trendTarget, showNext, showPrev, colour, presentation, setSelectedGoalIndex, getNextIndex, setIsTrend, selectedGoalIndex, teamGoals, startWeek }) => {
  const attendanceWeekStatuses = () => {
    let data = [];

    // attendances.forEach((att) => {
    //   let date = new Date(att.date);
    //   let start = new Date(date.getFullYear(), 0, 1);
    //   let week = Math.ceil((((date - start) / 86400000) + start.getDay() + 1) / 7);
    //
    //   let currentWeekObject = data.find((x) => x.week === week);
    //   if (currentWeekObject) {
    //     if (att.status === "NON_WORKING_DAY") {
    //       currentWeekObject.week = week;
    //       currentWeekObject.nonWorkingDayCount++;
    //     }
    //   } else {
    //     let weekObject = {
    //       week: week,
    //       nonWorkingDayCount: att.status === "NON_WORKING_DAY" ? 1 : 0,
    //     }
    //
    //     data.push(weekObject);
    //   }
    // })

    return data;
  }

  const [isFinancialYear, setIsFinancialYear] = useState(false);
  
  const { user } = useSelector((state) => state.userReducer);

  useEffect(() => {
    if (user && user.companies) {
      const company = user.companies.find(c => c.id === localStorage.getItem("companyId"));
      const financialEndMonth = (new Date(`${company.finalcialYearEnd} 1, ${date.getYear()}`)).getMonth();
      setIsFinancialYear(financialEndMonth === date.getMonth() ? "Start" : (financialEndMonth - 1 === date.getMonth() ? "End" : null));
    }
  }, [user, date])

  const [isTrigger, setIsTrigger] = useState(!isTrend);
  const toggleTrigger = () => {
    setIsTrigger(!isTrigger)
    refreshGraph();
  }
  const data = useMetricData(goal, date, isTrend, monthValues);
  const [index, setIndex] = useState(null);
  const domain = useDynamicDomain(
      data,
      [0, 0],
      goal.numberTo,
      LOWER_TRIGGER[1]
  );

  let periodKey = `${date.getMonth()}${date.getFullYear()}`
  const [teamGoalGraphConfig, setTeamGoalGraphConfig] = useState(goal.teamGoalGraphConfigs.find((temaGoalGraphConfig) => {
    return periodKey === temaGoalGraphConfig.key
  }))
  const [editable, setEditable] = useState(false)
  const [savingConfig, setSavingConfig] = useState(false)
  const [currentConfig, setCurrentConfig] = useState(null)
  const [createTeamGoalGraphConfig, updateTeamGoalGraphConfig] = useCreateOrUpdateTeamGoalGraphConfig();
  const [target, setTarget] = useState([goal.numberFrom, goal.numberTo]);
  const [calculatedTarget, setCalculatedTarget] = useState(trendTarget || trendTarget === 0 ? trendTarget : getCalculatedCurrentTarget([goal.numberFrom, goal.numberTo], date, monthValues, goal.dueDate))
  const [currentTarget, setCurrentTarget] = useState(Object.assign([], [teamGoalGraphConfig && (teamGoalGraphConfig.targetFrom || teamGoalGraphConfig.targetFrom === 0) ? teamGoalGraphConfig.targetFrom : trendTarget || trendTarget === 0 ? trendTarget : getCalculatedCurrentTarget([goal.numberFrom, goal.numberTo], date, monthValues, goal.dueDate), teamGoalGraphConfig && (teamGoalGraphConfig.targetTo || teamGoalGraphConfig.targetTo === 0) ? teamGoalGraphConfig.targetTo : trendTarget || trendTarget === 0 ? trendTarget : getCalculatedCurrentTarget([goal.numberFrom, goal.numberTo], date, monthValues, goal.dueDate)]));
  const [upperTrigger, setUpperTrigger] = useState([teamGoalGraphConfig && (teamGoalGraphConfig.upperTriggerFrom || teamGoalGraphConfig.upperTriggerFrom === 0) ? teamGoalGraphConfig.upperTriggerFrom : "", teamGoalGraphConfig && (teamGoalGraphConfig.upperTriggerTo || teamGoalGraphConfig.upperTriggerTo === 0) ? teamGoalGraphConfig.upperTriggerTo : ""]);
  const [lowerTrigger, setLowerTrigger] = useState([teamGoalGraphConfig && (teamGoalGraphConfig.lowerTriggerFrom || teamGoalGraphConfig.lowerTriggerFrom) ? teamGoalGraphConfig.lowerTriggerFrom : "", teamGoalGraphConfig && (teamGoalGraphConfig.lowerTriggerTo || teamGoalGraphConfig.lowerTriggerTo === 0) ? teamGoalGraphConfig.lowerTriggerTo : ""]);
  const [frequencyTrigger, setFrequencyTrigger] = useState(teamGoalGraphConfig && (teamGoalGraphConfig.frequencyTrigger || teamGoalGraphConfig.frequencyTrigger === 0) ? teamGoalGraphConfig.frequencyTrigger : "");
  const [metricTarget, setMetricTarget] = useState(null)
  const [lowerTriggerValue, setLowerTriggerValue] = useState(null);
  const [upperTriggerValue, setUpperTriggerValue] = useState(null);
  const [createMetric, updateMetric, isRequesting] = useCreateOrUpdateMetric();
  const [period, setPeriod] = useState(getPeriodKey(monthValues[0], new Date(), "Weekly"));
  const [nextPeriod, setNextPeriod] = useState(getPeriodKey(monthValues[0], new Date(new Date().getFullYear() + 1, new Date().getMonth(), 0), "Weekly"));
  const [prevPeriod, setPrevPeriod] = useState(getPeriodKey(monthValues[0], new Date(new Date().getFullYear() - 1, new Date().getMonth(), 0), "Weekly"));

  useEffect(() => {
    setCurrentTarget(Object.assign([], [teamGoalGraphConfig && (teamGoalGraphConfig.targetFrom || teamGoalGraphConfig.targetFrom === 0) ? teamGoalGraphConfig.targetFrom : trendTarget || trendTarget === 0 ? trendTarget : getCalculatedCurrentTarget([goal.numberFrom, goal.numberTo], date, monthValues, goal.dueDate), teamGoalGraphConfig && (teamGoalGraphConfig.targetTo || teamGoalGraphConfig.targetTo === 0) ? teamGoalGraphConfig.targetTo : trendTarget || trendTarget === 0 ? trendTarget : getCalculatedCurrentTarget([goal.numberFrom, goal.numberTo], date, monthValues, goal.dueDate)]));
    setUpperTrigger([teamGoalGraphConfig && (teamGoalGraphConfig.upperTriggerFrom || teamGoalGraphConfig.upperTriggerFrom === 0) ? teamGoalGraphConfig.upperTriggerFrom : "", teamGoalGraphConfig && (teamGoalGraphConfig.upperTriggerTo || teamGoalGraphConfig.upperTriggerTo === 0) ? teamGoalGraphConfig.upperTriggerTo : ""]);
    setLowerTrigger([teamGoalGraphConfig && (teamGoalGraphConfig.lowerTriggerFrom || teamGoalGraphConfig.lowerTriggerFrom === 0) ? teamGoalGraphConfig.lowerTriggerFrom : "", teamGoalGraphConfig && (teamGoalGraphConfig.lowerTriggerTo || teamGoalGraphConfig.lowerTriggerTo === 0) ? teamGoalGraphConfig.lowerTriggerTo : ""]);
    setFrequencyTrigger(teamGoalGraphConfig && (teamGoalGraphConfig.frequencyTrigger || teamGoalGraphConfig.frequencyTrigger === 0) ? teamGoalGraphConfig.frequencyTrigger : "");
    setCalculatedTarget((isTrend && trendTarget && (trendTarget || trendTarget === 0)) ? trendTarget : getCalculatedCurrentTarget([goal.numberFrom, goal.numberTo], date, monthValues, goal.dueDate))
  }, [date, trendTarget, setTeamGoalGraphConfig, goal, monthValues, teamGoalGraphConfig, setCalculatedTarget]);

  useEffect(() => {
    let periodKey = `${date.getMonth()}${date.getFullYear()}`
    setTeamGoalGraphConfig(goal.teamGoalGraphConfigs.find((temaGoalGraphConfig) => {
      return periodKey === temaGoalGraphConfig.key
    }))
  }, [date, goal.teamGoalGraphConfigs])
  useEffect(() => {
  }, [metricTarget])
  const isLoadingOrRequesting = isLoading || isRequesting;

  const handleSelectMetric = (index) => {
    setIndex(index);
  };

  const setToNext = date => {
    setPeriod(getPeriodKey(monthValues[0], date, "Weekly"));
    setNextPeriod(getPeriodKey(monthValues[0], new Date(date.getFullYear() + 1, date.getMonth(), 0), "Weekly"));
    setPrevPeriod(getPeriodKey(monthValues[0], new Date(date.getFullYear() - 1, date.getMonth(), 0), "Weekly"));

    skipToNext(date)
  }

  const setToPrev = date => {
    setPeriod(getPeriodKey(monthValues[0], date, "Weekly"));
    setNextPeriod(getPeriodKey(monthValues[0], new Date(date.getFullYear() + 1, date.getMonth(), 0), "Weekly"));
    setPrevPeriod(getPeriodKey(monthValues[0], new Date(date.getFullYear() - 1, date.getMonth(), 0), "Weekly"));

    skipToPrev(date)
  }

  const handleTogglePeriodStatus = async (index) => {
    const metric = data[index];
    const periodStatus = toNextPeriodStatus(metric);

    if (metric.id) {
      const variables = { id: metric.id, periodStatus };

      const response = await updateMetric({ variables });
      if (response) deltaUpdate(response.data.updateMetric);
    } else {
      // If we are toggling period status before adding any value for this period,
      // we need to create a new metric record to hold the period status
      let variables = {
        goal: goal.id,
        value: metric.y,
        status: metric.status,
        period: isTrend ? "MONTH" : getPeriodDisplayText(goal.frequency).toUpperCase(),
        periodKey: getPeriodKey(monthValues[0], date, goal.frequency),
        periodValue: getPeriodDisplayText(goal.frequency).toUpperCase() === "MONTH" ? getPeriodValue(metric.x, monthValues) : goal.frequency === "Weekly" ? getWeeklyPeriodValue(metric.x, date) : metric.x,
        isTrend: isTrend,
        periodStatus,
      };

      try {
        const response = await createMetric({ variables });

        if (response) deltaUpdate(response.data.createMetric);
      } catch (e) {
        if(e.message !== "itemExists") {
          displayErrorToast(e)
        } else {
          if(window.confirm(getLabelText("metric_exists_error"))) {
            variables = {
              ...variables,
              overwrite: true
            }
            try {
              const response = await createMetric({ variables });
              if (response) deltaUpdate(response.data.createMetric);
            } catch (e) {
              displayErrorToast(e)
            }
          }
        }
      }
    }
  };

  const targetValue = (goal) => {
    if (!isTrend && goal.frequency !== "Monthly") {
      if(teamGoalGraphConfig) return currentTarget;

      // let start = currentTarget[0]
      // let end = currentTarget[1]
      // const index = monthValues.indexOf(date.getMonth() + 1)
      // if (currentTarget[0] > currentTarget[1]) {
      //   let diff = currentTarget[0] - currentTarget[1]
      //
      //   if (index !== 0) {
      //     start = currentTarget[0] - ((diff / 12) * index)
      //   }
      //
      //   if (index !== 11) {
      //     end = currentTarget[0] - ((diff / 12) * (index + 1))
      //   }
      //
      // } else if (currentTarget[0] < currentTarget[1]) {
      //   let diff = currentTarget[1] - currentTarget[0]
      //
      //   if (index !== 0) {
      //     start = currentTarget[0] + ((diff / 12) * index)
      //   }
      //
      //   if (index !== 11) {
      //     end = currentTarget[0] + ((diff / 12) * (index + 1))
      //   }
      // }

      return [currentTarget[0], currentTarget[1]]
      // if ((getMonth(date) + 1) > (getMonth(Date.parse(goal.dueDate)) + 1)) {
      //   return [currentTarget[0], currentTarget[1]];
      // } else {
      //   const value = (((target[0] - target[1]) / getMonth(Date.parse(goal.dueDate)) + 1) * (getMonth(date) + 1) + target[0])
      //   return [value, value]
      // }
    } else {
      return [target[0], target[1]]
    }
  }

  const saveConfig = async() => {
    let periodKey = `${date.getMonth()}${date.getFullYear()}`
    let variables
    setSavingConfig(true)
    try {
      if (teamGoalGraphConfig) {
        variables = {
          id: teamGoalGraphConfig.id,
          key: periodKey,
          targetFrom: currentTarget[0] !== "" ? parseFloat(currentTarget[0]) : null,
          targetTo: currentTarget[1] !== "" ? parseFloat(currentTarget[1]) : null,
          upperTriggerFrom: upperTrigger[0] !== "" ? parseFloat(upperTrigger[0]) : null,
          upperTriggerTo: upperTrigger[1] !== "" ? parseFloat(upperTrigger[1]) : null,
          lowerTriggerFrom: lowerTrigger[0] !== "" ? parseFloat(lowerTrigger[0]) : null,
          lowerTriggerTo: lowerTrigger[1] !== "" ? parseFloat(lowerTrigger[1]) : null,
          frequencyTrigger: frequencyTrigger !== "" ? parseFloat(frequencyTrigger) : null
        }
        await updateTeamGoalGraphConfig({ variables }).then((response) => {
          setTeamGoalGraphConfig(response.data.updateTeamGoalGraphConfig)
          if (response) deltaUpdate(response.data.updateTeamGoalGraphConfig, true);

        })
      } else {
        variables = {
          teamMeasurementId: goal.id,
          key: periodKey,
          targetFrom: currentTarget[0] !== "" ? parseFloat(currentTarget[0]) : null,
          targetTo: currentTarget[1] !== "" ? parseFloat(currentTarget[1]) : null,
          upperTriggerFrom: upperTrigger[0] !== "" ? parseFloat(upperTrigger[0]) : null,
          upperTriggerTo: upperTrigger[1] !== "" ? parseFloat(upperTrigger[1]) : null,
          lowerTriggerFrom: lowerTrigger[0] !== "" ? parseFloat(lowerTrigger[0]) : null,
          lowerTriggerTo: lowerTrigger[1] !== "" ? parseFloat(lowerTrigger[1]) : null,
          frequencyTrigger: frequencyTrigger !== "" ? parseFloat(frequencyTrigger) : null
        }
        await createTeamGoalGraphConfig({ variables }).then((response) => {
          setTeamGoalGraphConfig(response.data.createTeamGoalGraphConfig)
          if (response) deltaUpdate(response.data.createTeamGoalGraphConfig, true);

        })
      }
      setEditable(false)
      setSavingConfig(false)
    } catch (e) {
      console.log(e)
    }
  }

  const createProblemFunction = async (data) => {
    const res = await createProblem(selectedTeam.id, "", "", (new Date()).toISOString(), goal.measurement.id, data.id);

    if(res.data.createIssue) {
      const response = await updateMetric({ variables: { id: data.id, issue: res.data.createIssue.id } });
      if (response) deltaUpdate(response.data.updateMetric);
    }
  }

  const editConfig = () => {
    setCurrentConfig({
      currentTarget: currentTarget,
      lowerTrigger: lowerTrigger,
      upperTrigger: upperTrigger
    })

    setEditable(true)
  }

  const cancel = () => {
    setCurrentTarget(currentConfig.currentTarget)
    setUpperTrigger(currentConfig.upperTrigger)
    setLowerTrigger(currentConfig.lowerTrigger)
    setCurrentConfig(null)
    setEditable(false)
  }

  return (
      <div>
        {isLoadingOrRequesting && (
            <Fragment>
              <div className="page-loader">
                <div className="loader-box text-center">
                  <FontAwesomeIcon className="text-white fa-spin fa-3x" icon={faSpinner} />
                  <p className="text-white mt-4"><strong>Loading</strong></p>
                </div>
              </div>
            </Fragment>
        )}
        <div
            style={{ opacity: isLoadingOrRequesting ? 0.5 : 1 }}
            className="w-100 pb-3"
        >
          <div className={"team-col row goal-title-container " + (savingConfig ? " disabled" : "")}>
            <div className="col-xl-6 col-lg-9 offset-xl-3 offset-lg-0 col-xs-12 offset-xs-0 pb-2 pt-1 text-left">
              {goal && (!presentation.active || (presentation.active && presentation.paused) || (presentation.schedule.length && !presentation.schedule[presentation.index].children.length))
                  ? (
                      <Fragment>
                        <table className="w-100 h-100">
                          <tbody>
                          <tr>
                            <td valign="middle" className="pr-2">
                              <button
                                  className="button-unstyled d-inline text-white"
                                  style={{ color: "#fff!important" }}
                                  onClick={() => {
                                    setSelectedGoalIndex(getNextIndex(true)); setIsTrend(false); localStorage.setItem("selectedTeamResult", getNextIndex(true))
                                  }}
                              >
                                <FontAwesomeIcon
                                    className="text-white"
                                    size="2x"
                                    icon={faCaretSquareLeft}
                                />
                              </button>
                            </td>
                            <td valign="middle" className="text-center">
                              <Dropdown>
                                <Dropdown.Toggle className={"nav-link w-100"} variant="none" id="dropdown-basic" style={{ whiteSpace: "break-spaces" }}>
                                  <h4 className="d-inline"><b>{goal.measurement.goalCategory.name + " - " + goal.measurement.measurement} ({goal.measurement.unit.name})</b></h4>
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{ maxHeight: "400px", overflow: "auto" }}>
                                  {teamGoals.map((goal, i) => {
                                    return <Dropdown.Item key={i} value={i} onClick={(e) => { setSelectedGoalIndex(parseInt(i)); setIsTrend(false); localStorage.setItem("selectedTeamResult", i) }}>{goal.measurement.goalCategory.name + " - " + goal.measurement.measurement} ({goal.measurement.unit.name})</Dropdown.Item>
                                  })}
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            <td valign="middle" className="pl-2">
                              <button
                                  className="button-unstyled d-inline text-white"
                                  onClick={() => { setSelectedGoalIndex(getNextIndex()); setIsTrend(false); localStorage.setItem("selectedTeamResult", getNextIndex()) }}
                              >
                                <FontAwesomeIcon size="2x" icon={faCaretSquareRight} />
                              </button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </Fragment>
                  ) : goal && (
                    <div className="nav-link text-center w-100">
                      <h4 className="d-inline"><b>{goal?.measurement?.goalCategory?.name + " - " + goal?.measurement?.measurement} ({goal?.measurement?.unit?.name})</b></h4>
                    </div>
                  )
              }
            </div>
            <div className="col-lg-3 col-xs-12 text-right">

            </div>
          </div>
          <div className={"row controls-container mb-3 py-2" + (savingConfig ? " disabled" : "")}>
            <div className={!isTrend && goal.frequency !== "Monthly" ? "col-10 py-1" : "col-12 py-1"}>
              <Controls
                  resultDate={date}
                  goal={goal}
                  target={targetValue(goal)}
                  monthValues={monthValues}
                  lowerTrigger={lowerTrigger}
                  upperTrigger={upperTrigger}
                  frequencyTrigger={frequencyTrigger}
                  refreshGraph={refreshGraph}
                  showTrigger={goal.frequency !== "Monthly"}
                  setUpperTrigger={setUpperTrigger}
                  setLowerTrigger={setLowerTrigger}
                  isTrend={isTrend}
                  setFrequencyTrigger={setFrequencyTrigger}
                  setTarget={setTarget}
                  currentTarget={currentTarget}
                  setCurrentTarget={setCurrentTarget}
                  calculatedTarget={calculatedTarget}
                  editable={editable}
                  teamGoalGraphConfig={teamGoalGraphConfig}
              />
            </div>
            {!isTrend && goal.frequency !== "Monthly" && (
                <div className="col-2 pt-3">
                  {editable
                      ? (
                          <Fragment>
                            <button className={"btn-icons-container" + (editable ? " mt-2" : "")}  type="submit" onClick={() => saveConfig()}>
                              {savingConfig
                                  ? (
                                      <img src={Loader} alt="Loader" className="loader-spinner"/>
                                  ) : (
                                      <img src={Save} alt="Save" className="btn-icons"/>
                                  )
                              }
                            </button>
                            <button className={"btn-icons-container" + (editable ? " mt-2" : "")} type="submit" onClick={() => cancel()}>
                              <img src={Cancel} alt="Cancel" className="btn-icons"/>
                            </button>
                          </Fragment>
                      ) : (
                          <button className={"btn-icons-container" + (editable ? " mt-2" : "")} type="submit" onClick={() => editConfig()}>
                            <img src={Edit} alt="Edit" className="btn-icons"/>
                          </button>
                      )
                  }
                </div>
            )}
          </div>
          <div className={"row px-5" + (savingConfig ? " disabled" : "")} style={{ height: "auto"}}>
            <div className="col-12">
              <div className="chart eyecatcher" style={{maxHeight: "600px"}}>
                <Chart
                    data={data}
                    target={targetValue(goal)}
                    trendTarget={trendTarget}
                    lowerTrigger={lowerTrigger}
                    upperTrigger={upperTrigger}
                    frequencyTrigger={frequencyTrigger}
                    domain={domain}
                    opaque={index !== null}
                    handleSelectMetric={handleSelectMetric}
                    handleTogglePeriodStatus={handleTogglePeriodStatus}
                    attendanceWeekStatuses={attendanceWeekStatuses()}
                    isTrend={isTrend}
                    goal={goal}
                    monthList={monthList}
                    monthValues={monthValues}
                    resultDate={date}
                    metricTarget={metricTarget}
                    setMetricTarget={setMetricTarget}
                    lowerTriggerValue={lowerTriggerValue}
                    setLowerTriggerValue={setLowerTriggerValue}
                    upperTriggerValue={upperTriggerValue}
                    setUpperTriggerValue={setUpperTriggerValue}
                    colour={colour}
                    startWeek={startWeek}
                    currentTarget={currentTarget}
                    calculatedTarget={calculatedTarget}
                    createProblem={createProblemFunction}
                />
                {index !== null && (
                    <ResultInput
                        resultDate={date}
                        goal={goal}
                        onSubmit={deltaUpdate}
                        data={data[index]}
                        monthList={monthList}
                        monthValues={monthValues}
                        onClose={() => setIndex(null)}
                        isTrend={isTrend}
                        metricTarget={metricTarget}
                        lowerTriggerValue={lowerTriggerValue}
                        upperTriggerValue={upperTriggerValue}
                        attendanceWeekStatuses={attendanceWeekStatuses()}
                        target={targetValue(goal)}
                        colour={colour}
                        startWeek={startWeek}
                        isFinancialYear={isFinancialYear}
                    />
                )}
              </div>
            </div>
          </div>
          <div className={"row mt-2 justify-content-center" + (date.getMonth() === 1 ? " pt-3" : "") + (savingConfig ? " disabled" : "")}>
            <div className="col-6 col-sm-8 col-xs-12">
              <div className="row mt-2 justify-content-end date-selector-margin">
                <div className="col-sm-5 col-xs-12 text-center">
                  <div className="text-center">
                    <table className="w-100">
                      <tbody>
                      <tr>
                        <td width="20%">
                          {showPrev && (
                              <button
                                  className={`p-1 pointer button-unstyled d-inline arrow-btn justify-content-between text-uppercase my-2 mr-2`}
                                  onClick={() => setToPrev(new Date(date.getFullYear() - 1, date.getMonth(), date.getDate()))}
                              >
                                <FontAwesomeIcon
                                    className="text-grey"
                                    size="2x"
                                    icon={faCaretSquareLeft}
                                />
                              </button>
                          )}
                        </td>
                        <td width="60%">
                          <FiscalYearDateSelector
                              selectedValue={new Date(date.getFullYear(), date.getMonth() + 1, 0)}
                              action={newDate => {setDate(newDate)}}
                              startMonth={monthList[0]}
                              selectedPeriod={period}
                              period={period}
                              nextPeriod={nextPeriod}
                              prevPeriod={prevPeriod}
                              isChart={true}
                          />
                        </td>
                        <td width="20%">
                          {showNext && (
                              <button
                                  className={`p-1 pointer button-unstyled d-inline arrow-btn justify-content-between text-uppercase my-2 ml-2`}
                                  onClick={() => setToNext(new Date(date.getFullYear() + 1, date.getMonth(), date.getDate()))}>
                                <FontAwesomeIcon
                                    className="text-grey"
                                    size="2x"
                                    icon={faCaretSquareRight}
                                />
                              </button>
                          )}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-sm-2 col-xs-12 spacingOffSet">
                  {((goal.frequency !== "Monthly" || getPeriodDisplayText(goal.frequency).toUpperCase() !== "MONTH") && (
                      <div className="row mt-3">
                        <div className="col-12 text-right">
                          <div className="d-flex w-100 justify-content-between">
                            <span>{getLabelText("trend")}</span>
                            <Switch
                                checkedIcon={false}
                                uncheckedIcon={false}
                                onColor="#005e85"
                                offColor="#005e85"
                                checked={isTrigger}
                                onChange={toggleTrigger}

                            />
                            <span>{getLabelText("trigger")}</span>
                          </div>
                        </div>
                      </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default TeamGoalsContent;
