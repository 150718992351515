import React, { Component, Fragment } from 'react';
import { Prompt } from 'react-router'

class DirtyPrompt extends Component {
  componentDidUpdate = () => {
    if (this.props.isDirty) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }

  render() {
    return (
      <Fragment>
        <Prompt
          when={this.props.isDirty}
          message='You have unsaved changes, are you sure you want to leave?'
        />
      </Fragment>
    );
  }
}

export default DirtyPrompt;
