import { client } from "./BaseService";
import { gql } from "apollo-boost";

export const getEntity = async (id, type) => {
  if(type === "customer") {
    return await client.query({
      query: gql`query{
        team(id: "${id}") {
          id
          customers {
            id
            name
            teams {
              name
            }
            departmentType {
              name
            }
            shareRequested(teamId: "${id}", type: "${type}")
            teamRelationshipStatuses(creatorId: "${id}", type: "${type}" ) {
              id
              status
              reviewDate
              shareOpen
              agreement
            }
            assignedIssues(creatorId: "${id}", type: CUSTOMER_ISSUE ) {
              id
              whyText
              whatText
              archived
              reviewDate
              isShared
              creator {
                id
              }
              actions(withTrash: true) {
                id
                status
                actionType
                description
                who {
                  id
                  firstNames
                }
                when
                archived
                escalated
                linkedIssue {
                  id
                  innovationStatus
                }
              }
            }
          }
        }
      }`
    })
  } else {
    return await client.query({
      query: gql`query{
        team(id: "${id}") {
          id
          department {
            name
          }
          partners {
            id
            name
            teams {
              name
            }
            departmentType {
              name
            }
            shareRequested(teamId: "${id}", type: "${type}")
            teamRelationshipStatuses(creatorId: "${id}", type: "${type}" ) {
              id
              status
              reviewDate
              shareOpen
              agreement
            }
            assignedIssues(creatorId: "${id}", type: SUPPLIER_ISSUE ) {
              id
              whyText
              whatText
              creator {
                id
              }
              archived
              reviewDate
              isShared
              actions (withTrash: true) {
                id
                status
                actionType
                description
                who {
                  id
                  firstNames
                }
                when
                archived
                escalated
                linkedIssue {
                  id
                  innovationStatus
                }
              }
            }
          }
        }
      }`
    })
  }
}

export const createRelationship = async(teamId, entityId, status, type) => {
  return await client.mutate({
    mutation: gql`mutation {
      createTeamRelationshipStatus(
        teamId: "${entityId}",
        creatorId: "${teamId}"
        status: ${status},
        type: "${type}"
      ) {
        id
      }
    } `
  })
}

export const updateRelationship = async(id, status) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateTeamRelationshipStatus(
        id: "${id}",
        status: ${status}
      ) {
        id
      }
    } `
  })
}

export const createReviewDate = async(teamId, entityId, date, type) => {
  return await client.mutate({
    mutation: gql`mutation {
      createTeamRelationshipStatus(
        teamId: "${entityId}",
        creatorId: "${teamId}"
        status: true,
        type: "${type}",
        reviewDate: "${date}"
      ) {
        id
      }
    } `
  })
}

export const createShared = async(teamId, entityId, shared, type) => {
  return await client.mutate({
    mutation: gql`mutation {
      createTeamRelationshipStatus(
        teamId: "${entityId}",
        creatorId: "${teamId}"
        status: true,
        type: "${type}",
        shareOpen: ${shared}
      ) {
        id
      }
    } `
  })
}

export const updateShared = async(id, shared) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateTeamRelationshipStatus(
        id: "${id}",
        shareOpen: ${shared}
      ) {
        id
      }
    } `
  })
}

export const createAgreement = async(teamId, entityId, url, type) => {
  return await client.mutate({
    mutation: gql`mutation {
      createTeamRelationshipStatus(
        teamId: "${entityId}",
        creatorId: "${teamId}"
        status: true,
        type: "${type}",
        agreement: "${url}"
      ) {
        id
      }
    } `
  })
}

export const updateAgreement = async(id, url) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateTeamRelationshipStatus(
        id: "${id}",
        agreement: "${url}"
      ) {
        id
      }
    } `
  })
}

export const updateReviewDate = async(id, date) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateTeamRelationshipStatus(
        id: "${id}",
        reviewDate: "${date}"
      ) {
        id
      }
    } `
  })
}

export const createIssue = async(creatorId, teamId, whatText, whyText, date, type) => {

  return await client.mutate({
    mutation: gql`mutation {
      createIssue(
    		creatorId: "${creatorId}",
        teamId: "${teamId}",
        whatText: "${whatText}",
        whyText: "${whyText}",
        reviewDate: "${date}",
        type: ${type}
      ) {
        id
      }
    }`
  })
}

export const createImprovementIdea = async(creatorId, whatText, whyText, date, linkId) => {

  return await client.mutate({
    mutation: gql`mutation {
      createIssue(
        creatorId: "${creatorId}",
        whatText: "${whatText}",
        whyText: "${whyText}",
        reviewDate: "${date}",
        linkId: "${linkId}",
        type: INNOVATION_ISSUE,
        innovationStatus: NONE,
        innovationApproved: NONE
      ) {
        id
      }
    }`
  })
}

export const updateIssue = async(id, whatText, whyText, date) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateIssue (
    		id: "${id}",
        whatText: "${whatText}",
        whyText: "${whyText}",
        reviewDate: "${date}"
      ) {
        id
      }
    }`
  })
}

export const deleteIssue = async(id) => {
  return await client.mutate({
    mutation: gql`mutation {
      deleteIssue (
    		id: "${id}"
      ) {
        id
      }
    }`
  })
}

export const archiveIssue = async(id, archived) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateIssue (
    		id: "${id}",
        archived: ${archived}
      ) {
        id
      }
    }`
  })
}

export const customerSupplierArchive = async(id, archived, teamId) => {
  return await client.mutate({
    mutation: gql`mutation {
      archiveIssue (
    		id: "${id}",
        archived: ${archived},
        teamId: "${teamId}",
      )
    }`
  })
}

export const createAction = async(issueId, actionType, status, description) => {
  return await client.mutate({
    mutation: gql`mutation {
        createAction(
          issueId: "${issueId}",
          actionType: ${actionType},
          status: ${status},
          description: "${description}",
        ) {
          id
        }
      }`
  })
}

export const updateAction = async(id, actionType, status, description) => {
  return await client.mutate({
    mutation: gql`mutation {
        updateAction(
          id: "${id}",
          actionType: ${actionType},
          status: ${status},
          description: "${description}",
        ) {
          id
        }
      }`
  })
}

export const nextStep = async(id) => {
  return await client.mutate({
    mutation: gql`mutation {
        updateAction(
          id: "${id}",
          status: NEXT_STEP
        ) {
          id
        }
      }`
  })
}

export const createRequest = async(relationshipId, issueId) => {
  return await client.mutate({
    mutation: gql`mutation {
        createSharedRequest(
          relationshipId: "${relationshipId}",
          issueId: "${issueId}",
        ) {
          id
        }
      }`
  })
}
