import { getMonth } from "date-fns";
import _ from 'lodash';
export const lightBlue = "#4086A4";

export const useDomainBars = (data, domain, target) => {

  const maxTarget =  _.maxBy(data, (target) => {
    return target.target
  })

  const maxValue =  _.maxBy(data, (target) => {
    return target.y
  })

  const minTarget =  _.minBy(data, (target) => {
    return target.target
  })

  const minValue =  _.minBy(data, (target) => {
    return target.y
  })

  let values = [target[0], target[1], (maxTarget && (maxTarget.target || maxTarget.target === 0) ? maxTarget.target : ""), (maxValue && (maxValue.y || maxValue.y === 0) ? maxValue.y : "" ), (minTarget && (minTarget.target || minTarget.target === 0) ? minTarget.target : ""), (minValue && (minValue.y || minValue.y === 0) ? minValue.y : "" )]
  let filtered = values.filter(function (el) {
    return el != null && el !== "";
  });

  let max
  let min
  if(filtered.length > 0) {
    min = filtered.reduce(function (p, v) {
      return ( p < v && p !== "" ? p : v );
    });
    max = filtered.reduce(function (p, v) {
      return ( p > v && p !== "" ? p : v );
    });
  } else {
    min = 0
    max = 10
  }

  return data.map(({ x }) => ({ x, y: domain[1] > max ? parseFloat(domain[1] + ((max - min) * 0.2)) : parseFloat(max + ((max - min) * 0.2)) }));
}

export const useInvertedDomainBars = (data, domain, target) => {

  const maxTarget =  _.maxBy(data, (target) => {
    return target.target
  })

  const maxValue =  _.maxBy(data, (target) => {
    return target.y
  })

  const minTarget =  _.minBy(data, (target) => {
    return target.target
  })

  const minValue =  _.minBy(data, (target) => {
    return target.y
  })

  let values = [target[0], target[1], (maxTarget && (maxTarget.target || maxTarget.target === 0) ? maxTarget.target : ""), (maxValue && (maxValue.y || maxValue.y === 0) ? maxValue.y : "" ), (minTarget && (minTarget.target || minTarget.target === 0) ? minTarget.target : ""), (minValue && (minValue.y || minValue.y === 0) ? minValue.y : "" )]
  let filtered = values.filter(function (el) {
    return el != null && el !== "";
  });

  let min
  let max
  if(filtered.length > 0) {
    min = filtered.reduce(function (p, v) {
      return ( p < v && p !== "" ? p : v );
    });
    max = filtered.reduce(function (p, v) {
      return ( p > v && p !== "" ? p : v );
    });
  } else {
    min = 0
    max = 10
  }

  const padding = parseFloat((max - min) * 0.2)
  return data.map(({ x }) => ({ x, y: min ? min < 0 ? parseFloat(min - padding) : parseFloat(min - padding) < 0 ? 0 : parseFloat(min - padding) : 0 }));
}

export const useTickValues = (data) => data.map(({ x }) => x);

// export const useLineData = (dataTuple, tickValues) => {
//   return dataTuple[0] !== null || dataTuple[1] !== null
//     ? [
//         { y: dataTuple[0], x: tickValues[0] },
//         { y: dataTuple[1], x: tickValues[tickValues.length - 1] },
//       ]
//     : null;
// };

export const useLineData = (dataTuple, tickValues, goal, monthValues, actualData) => {

  console.log(actualData)
  console.log(dataTuple)
  console.log(tickValues)
  const month = getMonth(Date.parse(goal.dueDate));
  const monthIndex = monthValues.indexOf(month + 1)
  console.log(monthIndex)
  let data = []
  // let currentPointer = 1;
  if(dataTuple[0] !== null || dataTuple[1] !== null) {
    let climb;
    if(dataTuple[0] > dataTuple[1]) {
      climb = (dataTuple[0] - dataTuple[1]) / (monthIndex)
    } else if(dataTuple[0] < dataTuple[1]) {
      climb = (dataTuple[1] - dataTuple[0]) / (monthIndex)
    } else {
      climb = 0
    }
    actualData.map((metric, i) => {
      let y_Value
      if(actualData[i - 1] && actualData[i - 1].target) {
        y_Value = actualData[i - 1].target
      } else {
        if(i >= monthIndex) {
          y_Value = dataTuple[1]
        } else {
          if(dataTuple[0] > dataTuple[1]) {
            y_Value = dataTuple[0] - (climb * i)
          } else {
            y_Value = dataTuple[0] + (climb * i)
          }
        }
      }

      data.push({ y: parseFloat(y_Value), x: (i + 1) })

      return true
    })
  }
  console.log(data)
  return data;
}

export const useTargetData = (dataTuple, tickValues, goal, monthValues, actualData) => {

  const month = getMonth(Date.parse(goal.dueDate));
  const monthIndex = monthValues.indexOf(month + 1)

  let data = []
  // let currentPointer = 1;
  if(dataTuple[0] !== null || dataTuple[1] !== null) {
    let climb;
    if(dataTuple[0] > dataTuple[1]) {
      climb = (dataTuple[0] - dataTuple[1]) / (monthIndex)
    } else if(dataTuple[0] < dataTuple[1]) {
      climb = (dataTuple[1] - dataTuple[0]) / (monthIndex)
    } else {
      climb = 0
    }

    actualData.map((metric, i) => {
      let y_Value
      if(metric.target) {
        y_Value = metric.target
      } else {
        if(i >= monthIndex) {
          y_Value = dataTuple[1]
        } else {
          if(dataTuple[0] > dataTuple[1]) {
            y_Value = dataTuple[0] - (climb * i)
          } else {
            y_Value = dataTuple[0] + (climb * i)
          }
        }
      }

      data.push({ y: metric.target === 0 ? metric.target : parseFloat(y_Value), x: tickValues[i] })

      return true
    })
  }
  
  return data;
};
