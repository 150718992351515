import { gql } from "@apollo/client";

export const TEAMS_WITH_ATTENDANCES_QUERY = gql`
  fragment fields on User {
    id
    firstNames
    lastName
    attendances(startDate: $startDate, endDate: $endDate, teamId: $id) {
      id
      date
      status
    }
  }

  fragment partnerFields on Team {
    id
    name
    department {
      name
    }
    partnerAttendances(startDate: $startDate, endDate: $endDate, teamId: $id) {
      id
      date
      status
    }
  }

  fragment visitorFields on Visitor {
    id
    name
    attendances(startDate: $startDate, endDate: $endDate, teamId: $id) {
      id
      date
      status
    }
  }

  query TeamsWithAttendances($id: ID!, $startDate: String, $endDate: String) {
    team(id: $id) {
      id
      name
      attendances(startDate: $startDate, endDate: $endDate) {
        id
        date
        status
      }
      coach {
        ...fields
      }
      leaders {
        ...fields
      }
      members {
        ...fields
      }
      meetingMembers {
        id
      }
      valuePartners {
        ...partnerFields
      }
      visitors(startDate: $startDate, endDate: $endDate) {
        ...visitorFields
      }
    }
  }
`;

export const GET_CSV_DATA = gql`
  query GetTeamExport ($id: ID!, $startDate: String, $endDate: String) {
    team(id: $id) {
      id
      userAttendanceCSV(startDate: $startDate, endDate: $endDate) {
        member
        date
        status
        dayStatus
      }
    }
  }
`;

export const CREATE_USER_ATTENDANCE_MUTATION = gql`
  mutation CreateUserAttendance(
    $user: String!
    $date: String!
    $status: UserAttendanceStatus!
    $team: String!
  ) {
    createUserAttendance(
      user: $user
      date: $date
      status: $status
      team: $team
    ) {
      id
      user {
        id
      }
      status
      date
    }
  }
`;

export const UPDATE_USER_ATTENDANCE_MUTATION = gql`
  mutation UpdateUserAttendance($id: ID!, $status: UserAttendanceStatus!) {
    updateUserAttendance(id: $id, status: $status) {
      id
      status
    }
  }
`;

export const CREATE_PARTNER_ATTENDANCE_MUTATION = gql`
  mutation CreatePartnerAttendance(
    $partner: String!
    $date: String!
    $status: UserAttendanceStatus!
    $team: String!
  ) {
    createPartnerAttendance(
      partner: $partner
      date: $date
      status: $status
      team: $team
    ) {
      id
      partner {
        id
      }
      status
      date
    }
  }
`;

export const UPDATE_PARTNER_ATTENDANCE_MUTATION = gql`
  mutation UpdatePartnerAttendance($id: ID!, $status: UserAttendanceStatus!) {
    updatePartnerAttendance(id: $id, status: $status) {
      id
      status
    }
  }
`;

export const CREATE_VISITOR_ATTENDANCE_MUTATION = gql`
  mutation CreateVisitorAttendance(
    $visitor: String!
    $date: String!
    $status: UserAttendanceStatus!
    $team: String!
  ) {
    createVisitorAttendance(
      visitor: $visitor
      date: $date
      status: $status
      team: $team
    ) {
      id
      visitor {
        id
      }
      status
      date
    }
  }
`;

export const UPDATE_VISITOR_ATTENDANCE_MUTATION = gql`
  mutation UpdateVisitorAttendance($id: ID!, $status: UserAttendanceStatus!) {
    updateVisitorAttendance(id: $id, status: $status) {
      id
      status
    }
  }
`;

export const CREATE_TEAM_ATTENDANCE_MUTATION = gql`
  mutation CreateTeamAttendance(
    $team: String!
    $date: String!
    $status: TeamAttendanceStatus!
  ) {
    createTeamAttendance(team: $team, date: $date, status: $status) {
      id
      team {
        id
      }
      status
      date
    }
  }
`;

export const UPDATE_TEAM_ATTENDANCE_MUTATION = gql`
  mutation UpdateTeamAttendance($id: ID!, $status: TeamAttendanceStatus!) {
    updateTeamAttendance(id: $id, status: $status) {
      id
      status
    }
  }
`;

export const CREATE_VISITOR_MUTATION = gql`
  mutation CreateVisitor(
    $team: String!
    $date: String!
    $name: String!
    ) {
    createVisitor(team: $team, date: $date, name: $name) {
      id
      name
      date
    }
  }
`;

export const UPDATE_VISITOR_MUTATION = gql`
  mutation UpdateVisitor($id: ID!, $name: String!) {
    updateVisitor(id: $id, name: $name) {
      id
      name
    }
  }
`;

export const DELETE_VISITOR_MUTATION = gql`
  mutation DeleteVisitor($id: ID!) {
    deleteVisitor(id: $id) {
      id
      name
    }
  }
`;
