import React, { useCallback, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faCircle
} from "@fortawesome/free-solid-svg-icons";
import classnames from "../../../components/utils/classnames";
import { useMutation } from "@apollo/react-hooks";
import {
  CREATE_ROUTINE_METRIC_MUTATION,
  UPDATE_ROUTINE_METRIC_MUTATION,
} from "../resources/gql";
import { ROUTINE_STATES } from "../resources/enums";
import displayErrorToast from "../../../components/utils/displayErrorToast";

const routineCellProps = {
  [ROUTINE_STATES[0]]: { classes: "bg-grey", loadingClasses: "fa-spin text-black" },
  [ROUTINE_STATES[1]]: { classes: "color-selected", loadingClasses: "fa-spin text-white" },
  [ROUTINE_STATES[2]]: { classes: "color-selected", icon: faCircle, loadingClasses: "fa-spin text-white" },
  [ROUTINE_STATES[3]]: { classes: "bg-red", loadingClasses: "fa-spin text-white" },
};

const RoutineCell = ({
  day,
  today,
  routineId,
  disabledDays,
  result,
  isMetricApplicable
}) => {
  const [obj, setObj] = useState(result);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(obj.status);
  const [currDate, setCurrDate] = useState(today);
  const [displayedStatus, setDisplayedStatus] = useState(obj.status);

  const onError = (error) => {
    setDisplayedStatus(status);
    displayErrorToast(error);
    setLoading(false);
  };

  useEffect(() => {
    if((currDate.getMonth() - today.getMonth()) === 0) return;
    setStatus(result.status);
    setDisplayedStatus(result.status);
    setObj(result);
    setCurrDate(today);
  }, [result, today, currDate, setCurrDate, setStatus, setDisplayedStatus]);

  const [createMetric] = useMutation(CREATE_ROUTINE_METRIC_MUTATION, {
    onCompleted: ({ createRoutineMetric }) => {
      setObj(createRoutineMetric)
      setLoading(false)
    },
    onError
  });

  const [updateMetric] = useMutation(UPDATE_ROUTINE_METRIC_MUTATION, {
    onCompleted: ({ updateRoutineMetric }) => {
      setObj(updateRoutineMetric)
      setLoading(false)
    },
    onError
  });

  const create = useCallback((nextStatus) => {
    createMetric({
      variables: {
        id: routineId,
        status: nextStatus,
        date: obj.date
      },
    });
  }, [createMetric, routineId, obj]);

  const update = useCallback((nextStatus) => {
    updateMetric({
      variables: {
        id: obj.id,
        status: nextStatus,
        date: obj.date
      },
    });
  }, [updateMetric, obj]);

  const toggleStatus = () => {
    const nextIndex = ROUTINE_STATES.indexOf(displayedStatus) === 3 ? 0 : (ROUTINE_STATES.indexOf(displayedStatus) + 1);

    setDisplayedStatus(ROUTINE_STATES[nextIndex]);

    return ROUTINE_STATES[nextIndex];
  }

  const onClick = () => {
    setLoading(true);
    const nextStatus = toggleStatus();

    if(obj.id) update(nextStatus)
    else create(nextStatus);
  }

  const { classes, icon } = routineCellProps[displayedStatus]

  const visibility = (disabledDays.has(day) || !isMetricApplicable) && "invisible";

  const className = classnames(
    "attendance-cell text-center font-weight-bold p-1 mr-2",
    classes,
    visibility,
    (loading ? " disabled" : "")
  );

  return (
    <div onClick={onClick} className={className}>
      {loading ? <FontAwesomeIcon icon={faSpinner} className={routineCellProps[displayedStatus].loadingClasses} /> : icon ? <FontAwesomeIcon icon={icon} className="text-white"/> : null}
    </div>
  );
};

export default RoutineCell;
