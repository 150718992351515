import React, { useEffect, useState, useCallback } from "react";
import { useMutation } from "@apollo/react-hooks";
import { useDebounce } from "use-debounce/lib";

import classnames from "../../components/utils/classnames";
import displayErrorToast from "../../components/utils/displayErrorToast";

import { PERIOD_STATES } from "./resources/statuses";
import { getCalculatedDisplayWeek } from "./resources/hooks";
import {
  UPDATE_PERIOD_MATRIX_MUTATION,
  CREATE_PERIOD_MATRIX_MUTATION,
} from "./resources/gql";

const dateCellProps = {
  [PERIOD_STATES[0]]: { classes: "" },
  [PERIOD_STATES[1]]: { classes: "bg-danger text-white" },
  [PERIOD_STATES[2]]: { classes: "bg-dark text-white" },
};

const PeriodCell = ({
  day,
  index,
  onCellChange,
  loading,
  frequency,
  monthList,
  result,
  goalId,
  startWeek
}) => {
  const [resultId, setResultId] = useState(result.id);
  const [status, setStatus] = useState(result.status)
  const [displayedStatus, setDisplayedStatus] = useState(result.status)

  const [nextStatus] = useDebounce(displayedStatus, 1000);

  const onError = (error) => {
    setDisplayedStatus(status)

    displayErrorToast(error);
  };

  useEffect(() => {
    setResultId(result.id)
    setStatus(result.status)
    setDisplayedStatus(result.status)
  }, [result, setResultId, setDisplayedStatus, setStatus])

  const [updatePeriodMatrix] = useMutation(UPDATE_PERIOD_MATRIX_MUTATION, {
    onError,
    onCompleted: ({ updateIndividualPeriodMetric }) => {
      setStatus(updateIndividualPeriodMetric.status);
    },
  });

  const [createPeriodMatrix] = useMutation(CREATE_PERIOD_MATRIX_MUTATION, {
    onError,
    onCompleted: ({ createIndividualPeriodMetric }) => {
      setStatus(createIndividualPeriodMetric.status);
      setResultId(createIndividualPeriodMetric.id);

    },
  });

  const onClick = () => {
    if (loading) return;
    setDisplayedStatus(PERIOD_STATES[PERIOD_STATES.indexOf(displayedStatus) + 1] ? PERIOD_STATES[PERIOD_STATES.indexOf(displayedStatus) + 1] : PERIOD_STATES[0])
    setChange((PERIOD_STATES[PERIOD_STATES.indexOf(displayedStatus) + 1] ? PERIOD_STATES[PERIOD_STATES.indexOf(displayedStatus) + 1] : PERIOD_STATES[0]))
  };

  const create = useCallback(() => {
    createPeriodMatrix({
      variables: {
        goal: goalId,
        status: displayedStatus,
        period: result.period,
        periodKey: result.periodKey,
        periodValue: result.periodValue
      },
    });
  }, [createPeriodMatrix, displayedStatus, goalId, result]);

  const update = useCallback(() => {
    updatePeriodMatrix({
      variables: {
        status: displayedStatus,
        id: resultId,
      },
    });
  }, [updatePeriodMatrix, displayedStatus, resultId]);

  const setChange = (status) => {
    onCellChange({ state: PERIOD_STATES.indexOf(status), index: day });
  }

  useEffect(() => {
    if (nextStatus === displayedStatus) return;

    if (resultId) update();
    else create();
  }, [ nextStatus, displayedStatus, resultId, update, create]);


  const className = classnames(
    "attendance-cell attendance-date-border text-center p-1 border mr-2",
    dateCellProps[displayedStatus].classes
  );

  return (
    <div onClick={onClick} className={className}>
      {frequency === "Monthly" ? monthList[index].substr(0, 1) : frequency === "Weekly" ? getCalculatedDisplayWeek(day, startWeek ? startWeek.week : null) : day}
    </div>
  );
};

export default PeriodCell;
