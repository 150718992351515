import { gql } from "@apollo/client";

export const TEAM_WITH_SKILLS_QUERY = gql`
query TeamsWithSkills($id: ID!) {
  team(id: $id) {
    id
    teamSkills {
      id
      name
    }
    userSkills {
      id
      status
      dueDate
      skill {
        id
      }
      user {
        id
      }
    }
    combinedMembers {
      id
      firstNames
      lastName
    }
  }
}
`;

export const ALL_SKILLS_QUERY = gql`
  query Skills {
    skills {
      id
      name
    }
  }
`;

export const CREATE_SKILL_MATRIX_MUTATION = gql`
  mutation createSkillsMatrix (
    $user: String!
    $status:SkillsMatrixStatus!
    $dueDate:String!
    $team:String!
    $skill:String!
    )
    {
      createSkillsMatrix(user:$user, status:$status, dueDate:$dueDate, team:$team, skill:$skill)
      {
        id
        status
        dueDate
        skill {
          id
        }
        user {
          id
        }
      }
    }
`;

export const UPDATE_SKILL_MATRIX_MUTATION = gql`
  mutation updateSkillsMatrix (
    $id:ID!
    $status:SkillsMatrixStatus!
    $dueDate:String!
  )
  {
    updateSkillsMatrix(id:$id, status:$status, dueDate:$dueDate)
    {
      id
      status
      dueDate
      skill {
        id
      }
      user {
        id
      }
    }
  }
`;

export const CREATE_TEAM_SKILL_MUTATION = gql`
  mutation linkSkill (
    $id:String!
    $skill:String!
  )
  {
    linkSkill(id:$id, skill:$skill)
    {
      id
      name
    }
  }
`;

export const DELETE_TEAM_SKILL_MUTATION = gql`
  mutation unlinkSkill (
    $id:String!
    $skill:String!
  )
  {
    unlinkSkill(id:$id, skill:$skill)
    {
      id
      name
    }
  }
`;

// export const UPDATE_TEAM_SKILL_MUTATION = gql`

// `;
