import React from "react";
import { VictoryLabel } from "victory";
import { lightBlue } from "./resources";
import { MetricPeriodStatuses } from "../resources";
import {
  getWeeklyPeriodValue,
  getCalculatedDisplayWeek
} from "../hooks";

function getLabelList(months) {
  let array = []

  months.map((month) => array.push(month.charAt(0)))

  return array
}


const TickLabelComponent = ({ style, data, monthList, resultDate, startWeek = null, ...props }) => {
  const dates = getLabelList(monthList);

  let text = props.bottomLabels && props.ticks.length === 12 ? dates[props.text -1] : props.bottomLabels && props.ticks.length < 12 ? getCalculatedDisplayWeek(getWeeklyPeriodValue(props.text, resultDate), startWeek ? startWeek.week : null) :props.text;

  return (
    <VictoryLabel
      {...props}
      text={text}
      backgroundPadding={{ top: 2, left: 10, right: 10, bottom: 4 }}
      backgroundStyle={{
        fill: ({ index }) => {
          if (!data) return '#FFF'
          const { periodStatus } = data[index]
          const { color } = MetricPeriodStatuses[periodStatus]
          return color;
        },
        stroke: lightBlue,
        strokeWidth: 1,
      }}
      style={{ ...style, fontSize: "18px" }}
    />
  );
};

export default TickLabelComponent;
