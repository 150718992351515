import React, { Component, Fragment } from "react";
import { Button, FormGroup, FormControl, FormLabel, Alert, Row, Col, Container } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { deleteGoalCategory, updateGoalCategory, createGoalCategory, getGoalCategory } from "../../services/GoalCategoryService";

class InputGoalCategoryScreen extends Component {
  state = {
    title: "Create Goal Category",
    edit: false,
    goalCategoryId: 0,
    goalCategory: {
      name: "",
    },
    error: "",
    redirect: false
  }

  validateForm = () => {
    let valid = true
    const { goalCategory } = this.state

    if (goalCategory.name.length < 1)
      valid = false


    return valid
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    if (this.validateForm()) {
      const { edit, goalCategory, goalCategoryId } = this.state
      try {
        edit ? await updateGoalCategory(goalCategoryId, goalCategory) : await createGoalCategory(goalCategory)
        this.setState({
          redirect: true
        })
      } catch (error) {
        this.setState({
          error: error.message
        })
      }
    } else {
      this.setState({
        error: "Validation Failed"
      })
    }
  }

  async componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ title: "Edit Goal Category", edit: true })
      try {
        const response = await getGoalCategory(this.props.match.params.id)
        this.setState({
          goalCategory: response.data.goalCategory,
          goalCategoryId: this.props.match.params.id
        })
      } catch (error) {
        this.setState({
          error: error.message
        })
      }
    }
  }

  deleteGoalCategory = async (e) => {
    e.preventDefault()
    if (window.confirm('Are you sure you wish to delete this item?')) {
      const { goalCategoryId } = this.state
      try {
        await deleteGoalCategory(goalCategoryId)
        this.setState({
          redirect: true
        })
      } catch (error) {
        this.setState({
          error: error.message
        })
      }
    }
  }

  handleChange = (e) => {
    e.persist()
    this.setState((prevState) => ({
      goalCategory: {
        ...prevState.goalCategory,
        [e.target.name]: e.target.value
      },
      error: null
    }))
  }

  handleCheck = (key) => {
    this.setState((prevState) => ({
      goalCategory: {
        ...prevState.goalCategory,
        [key]: !prevState.goalCategory[key]
      },
      error: null
    }))
  }

  render() {
    const { goalCategory, error, redirect, title } = this.state

    if (redirect) return <Redirect to="/dashboard/goal-categories" />

    return (
      <Fragment>
        <Container>
          <Row className="justify-content-center">
            <Col xl={6} md={8} sm={12}>

              <form onSubmit={(e) => this.handleSubmit(e)} className="create-goalCategory-from">
                <Row>
                  <Col className="text-left">
                    <h2 className="page-title pull-left mt-0">{title}</h2>
                  </Col>
                  <Col className="text-right">
                    {this.state.edit
                      ? <button className="btn btn-danger" onClick={(e) => this.deleteGoalCategory(e)}>Delete Goal Category</button>
                      : null
                    }
                  </Col>
                </Row>
                <Alert className={error ? "d-block" : "d-none"} variant="danger">
                  {error}
                </Alert>
                <FormGroup>
                  <FormLabel htmlFor="name">Name</FormLabel>
                  <FormControl
                    autoFocus
                    type="text"
                    required
                    placeholder="Name"
                    value={goalCategory.name}
                    name="name"
                    tabIndex="1"
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>

                <Button
                  block
                  type="submit"
                  variant="primary"
                >
                  {this.state.edit ? "Submit" : "Create"}
                </Button>
              </form>
            </Col>
          </Row>
        </Container>
      </Fragment>
    )
  }
}

export default InputGoalCategoryScreen;;
