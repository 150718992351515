import { gql } from "@apollo/client";

export const MODULES_QUERY = gql`
  query {
    reviewModules {
      id
      name
      orderByString
      canDelete
      subjects {
        id
        name
        canDelete
      }
    }
  }
`;


export const CREATE_SUBJECT_MUTATION = gql`
  mutation createSubject (
    $name: String!
    $module: String!
    )
    {
      createReviewSubject(name: $name, module: $module)
      {
        id
        name
        canDelete
      }
    }
`;

export const UPDATE_SUBJECT_MUTATION = gql`
  mutation updateSubject (
    $id: ID!
    $name: String!
    )
    {
      updateReviewSubject(id:$id, name: $name)
      {
        id
        name
        canDelete
      }
    }
`;

export const DELETE_SUBJECT_MUTATION = gql`
  mutation deleteSubject (
    $id: ID!
    )
    {
      deleteReviewSubject(id:$id)
      {
        id
      }
    }
`;
