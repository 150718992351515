export const getLoadDetails = (selector, props, prevProps = null) => {
  // get the required information from props and prevProps
  const { selectedTeam, presentation, refresh, teams } = props;
  const prevTeam = prevProps ? prevProps.selectedTeam : null,
        prevPresentation = prevProps ? prevProps.presentation : null,
        prevRefresh = prevProps ? prevProps.refresh : null;


  // Helper functions for a lot of conditions.
  const teamChanged = prevTeam && selectedTeam.id !== prevTeam.id;
  const activeChanged = prevPresentation && presentation.active !== prevPresentation.active;
  const pauseChanged = prevPresentation && presentation.paused !== prevPresentation.paused;
  const indexChanged = prevPresentation && presentation.index !== prevPresentation.index;
  const subIndexChanged = prevPresentation && presentation.subIndex !== prevPresentation.subIndex;
  const teamIsSet = selectedTeam.id !== 0;
  const subordianteIsSelected = presentation.childActive && teams.length > 0;
  const isPaused = presentation.paused;
  const presentationActive = presentation.active;
  const refreshChanged = refresh !== prevRefresh;
  const loadSubordinate = presentationActive && subordianteIsSelected && teamIsSet && !isPaused;
  const presentationChanged = activeChanged || pauseChanged || indexChanged || subIndexChanged;

  let response = null;
  // switch case on selector to determine what condition to check and what to return (bool or object).
  switch(selector) {
    case "PRESENTATION":
      // Determine if the presentation is active.
      response = presentationActive;
      break;
    case "INITIAL":
      // Determine if initial load is requied.
      response = teamIsSet;
      break;
    case "UPDATE":
      // Determine if on component update load is needed.
      response = (teamChanged || refreshChanged || presentationChanged) && teamIsSet;
      break;
    case "TEAM":
      // Get team required for query.
      response = selectedTeam;

      if(loadSubordinate) {
        response = teams.find(({ id }) => {
          return id === presentation.schedule[presentation.index].children[presentation.subIndex].id;
        })
      }

      break;
    default:
      response = selectedTeam;

      if(loadSubordinate) {
        response = teams.find(({ id }) => {
          return id === presentation.schedule[presentation.index].children[presentation.subIndex].id;
        })
      }

      break;
  }

  return response;
}
