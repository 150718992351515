import React, { Component, Fragment } from "react"

// bootstrap
import { Button, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap"

import { getValuePartners, linkValuePartner, getAllTeamsSummary } from "../services/TeamService";
import { faCheck, faBan, faEdit, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from 'react-redux';
import displayErrorToast from '../components/utils/displayErrorToast'
import SubHeaderComponent from "../components/SubHeaderComponent";
import Select from 'react-select';
import LabelComponent from "../components/utils/getCompanyLabel";
import * as DefaultLabels from '../assets/glossary.json';

// icons
import Delete from '../assets/ButtonIcons/Delete Blue.svg';
import Loader from '../assets/ButtonIcons/Loader Blue.svg';
import { canEditHere } from "../utils/checkPermissions";
const labels = DefaultLabels.default;

const getLabelText = key => <LabelComponent val={key}/>

function renderTooltip(props, text) {
  return (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );
}

class TeamValuePartnersScreen extends Component {
  constructor(props) {
    super(props)

    this.props.toggleDirtyState(true)
    this.state = {
      partners: [],
      allPartners: [],
      teams: [],
      selectedTeam: 0,
      loading: false,
      loadType: "",
      adding: false,
      edit: false,
      modified: false,
    }

    this.props.togglePageLoad(true)
  }

  async componentDidMount() {
    await this.fetchTeams()
    if(this.props.selectedTeam.id) {
      // if(this.props.selectedTeam.department.departmentType.level === "LVL1") {
      //   this.props.history.push("/teams/team-partners")
      //   return
      // }
      //
      // if(this.props.selectedTeam.department.departmentType.level !== "LVL2") {
      //   this.props.history.push("/dashboard/goals")
      //   return
      // }
      await this.fetchPartners()
      this.props.togglePageLoad(false)
    }
  }

  async componentDidUpdate(prevProps)  {
    if(prevProps.selectedTeam.id !== this.props.selectedTeam.id) {
      // if(this.props.selectedTeam.department.departmentType.level === "LVL1") {
      //   this.props.history.push("/teams/team-partners")
      //   return
      // }
      //
      // if(this.props.selectedTeam.department.departmentType.level !== "LVL2") {
      //   this.props.history.push("/dashboard/goals")
      //   return
      // }
      this.props.togglePageLoad(true)
      await this.fetchPartners()
      this.props.togglePageLoad(false)
    }
  }

  fetchTeams = async () => {
    const response = await getAllTeamsSummary()

    this.setState({
      teams: response.data.allTeams.filter(team => team.id !== this.props.selectedTeam.id).sort((a,b) => a.department.name.localeCompare(b.department.name))
    })
  }

  fetchPartners = async () => {
    const response = await getValuePartners(this.props.selectedTeam.id)

    if(response.data.team) {
      this.setState({
        partners: response.data.team.valuePartners
      })
    }
  }

  // fetchSkillCount = async () => {
  //   const response = await getSkills()
  //
  //   this.setState({
  //     totalPages: (Math.ceil(response.data.company.skills.length / 10))
  //   })
  // }

  // setPage = async (page) => {
  //   this.setState({
  //     paginateDisabled: true
  //   })
  //   const firstIndex = (parseInt(page) * 10) - 10
  //   const lastIndex = (parseInt(page) * 10) - 1
  //
  //   this.setState({
  //     paginateDisabled: false,
  //     page: page,
  //     firstIndex: firstIndex,
  //     lastIndex: lastIndex
  //
  //   })
  // }

  addPartner = () => {
    const { teams, partners } = this.state
    const jointPartners = [partners]
    this.setState({
      adding: true,
      selectedTeam: teams.filter(team => !jointPartners.find(partner => partner.id === team.id))[0].id
    })
  }

  getText = val => {
    const { user } = this.props;

    const companyId = localStorage.getItem("companyId");
    let lang = "EN";
    let langLabels = labels.find(lbl => lbl.lang === lang);
    let def = langLabels.glossary.find(label => label.key === val);

    if(user && user.companies && user.companies.length) {
      const company = user.companies.find(company => company.id === companyId);
      if(company) {
        lang = company.language;
        langLabels = labels.find(lbl => lbl.lang === lang);
        def = langLabels.glossary.find(label => label.key === val);
        if(!def) {
          lang = "EN";
          langLabels = labels.find(lbl => lbl.lang === lang);
          def = langLabels.glossary.find(label => label.key === val);
        }
      }
      if(company && company.companyGlossaries) {
        const custom = company.companyGlossaries.find(label => label.key === val);

        if(custom) return custom.value
      }
    }

    if(!def) {
      console.log(val)
    }
    return def.value
  }

  cancel = () => {
    this.setState({
      edit: false,
      modified: false,
      allPartners: []
    })
  }

  startLoading = (type, id = 0) => {
    this.setState({
      loading: true,
      loadType: type,
      loadingId: id
    })
  }

  stopLoading = () => {
    this.setState({
      loading: false,
      loadType: "",
      loadingId: ""
    })
  }

  handleSubmit = async () => {
    this.startLoading("create")

    try {
      const { allPartners } = this.state
      let partnerIds = []

      allPartners.map(({ id }) => {
        return partnerIds.push(id)
      })

      await linkValuePartner(this.props.selectedTeam.id, partnerIds)

      await this.fetchPartners()

      this.cancel()
    } catch (error) {
      displayErrorToast(error)
    }

    this.stopLoading()
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  deleteItem = async (id) => {
    const { allPartners } = this.state
    if (window.confirm(this.getText("delete_department_prompt"))) {
      this.setState({
        allPartners: allPartners.filter(({ id: partnerId }) => partnerId !== id),
        modified: true
      })
    }
  }

  setEdit = () => {
    const { partners } = this.state
    this.setState({
      edit: true,
      modified: false,
      allPartners: partners
    })
  }

  partnerList = (partner, i) => {
    const { loading, loadType, loadingId } = this.state

    return (
      <table className="w-100 mb-20">
        <tbody>
          <tr key={i}>
            <td width="90%">
              <select className="form-control cdi-ddl w-100 ml-0"
                disabled
              >
                <option>{partner.department.name} ({partner.name})</option>
              </select>
            </td>
            <td className="text-right">
              <OverlayTrigger
                placement="top"
                delay={{ show: 25, hide: 40 }}
                overlay={(props) => renderTooltip({...props}, getLabelText("delete"))}
              >
                <Button className={"btn-icons-container"} variant="danger" onClick={() => this.deleteItem(partner.id)}>
                  {loading && loadType === "delete" && loadingId === partner.id
                    ? (
                      <Fragment>
                        <img src={Loader} alt="Loader" className="loader-spinner"/>
                      </Fragment>
                    )
                    : (
                      <Fragment>
                        <img src={Delete} alt="Delete" className="btn-icons"/>
                      </Fragment>
                    )
                  }
                </Button>
              </OverlayTrigger>
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  handleSelect = (id) => {
    const { teams, allPartners } = this.state

    const team = teams.find(({ id: teamId }) => teamId === id)

    if(team) {
      this.setState({
        allPartners: [...allPartners, team],
        modified: true
      })
    }
  }

  getTeamSelect = () => {
    const { teams, allPartners } = this.state

    const arr = teams.filter(team => !allPartners.find(partner => partner.id === team.id) && team.id !== this.props.selectedTeam.id).map(team => {
      return {
        value: team.id,
        label: `${team.department.name} (${team.name})`
      }
    })

    return (
      <>
      {allPartners.map((partner, i) => this.partnerList(partner, i))}
      <Select
        options={arr}
        onChange={({value}) => this.handleSelect(value)}
        value="Select..."
        />
      </>
    )
  }

  getTeamList = () => {
    const { edit, partners } = this.state

    if(edit) {
      return this.getTeamSelect()
    } else {
      return partners.length ? partners.map((partner, i) => {
        return <p style={{ fontWeight: "600" }}>{partner.department.name} ({partner.name})</p>
      }) : <p style={{ fontWeight: "600" }}>-- No Data --</p>
    }
  }

  canEdit = () => {
    return canEditHere(this.props.user, this.props.teams, this.props.selectedTeam);
  }

  render() {
    const { loading, loadType, edit, modified } = this.state

    return (
      <Fragment>
        <SubHeaderComponent>
          <Row className="justify-content-center">
            <Col className="text-right" xs={12}>
              {!edit ? 
                <>
                  {
                    this.canEdit() ? 
                    (
                      <Button 
                        className={"btn btn-primary btn-nrml"} 
                        onClick={() => this.setEdit()}
                      >
                          {getLabelText("edit")}
                          <FontAwesomeIcon className="ml-2" icon={faEdit} />
                      </Button>
                    ) : null
                  }
                </>
                : (
                  <>
                    <Button className={"btn btn-primary btn-nrml" + ((loading && loadType === "create") || !modified ? " disabled" : "")} onClick={() => this.handleSubmit()}>
                      {loading && loadType === "create"
                        ? (
                          <Fragment>
                            {getLabelText("saving")}... <FontAwesomeIcon className="text-white fa-spin mr-1" icon={faSpinner}/>
                          </Fragment>
                        )
                        : (
                          <Fragment>
                            {getLabelText("save")}<FontAwesomeIcon className="ml-2" icon={faCheck} />
                          </Fragment>
                        )
                      }

                    </Button>
                    <Button variant="danger" className={"btn btn-danger ml-2 btn-nrml"} onClick={() => this.cancel()}>{getLabelText("cancel")}<FontAwesomeIcon className="ml-2" icon={faBan} /></Button>
                  </>
                )
              }
            </Col>
          </Row>
        </SubHeaderComponent>
        <div className="grey-header-space container-left">
          <Row className="">
            <Col xl={2} xs={12}>
              <Row className="">
                <Col>
                  <label className="cdi-txt-lbl">{getLabelText("our_partners")}</label>
                </Col>
              </Row>
            </Col>
            <Col xl={4} xs={12}>
              <Row className="">
                <Col>
                  {this.getTeamList()}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam,
  teams: state.teamsReducer.teams,
  user: state.userReducer.user
})

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(TeamValuePartnersScreen);
