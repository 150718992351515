import React, { useEffect, useState, useCallback } from "react";
import { useMutation } from "@apollo/react-hooks";
import { useDebounce } from "use-debounce/lib";

import classnames from "../../components/utils/classnames";
import displayErrorToast from "../../components/utils/displayErrorToast";
import {
  UPDATE_PERIOD_MUTATION,
  CREATE_PERIOD_MUTATION,
} from "./resources/gql";
import { PERIOD_STATES } from "./resources/statuses";

const dateCellProps = {
  [PERIOD_STATES[0]]: { classes: "" },
  [PERIOD_STATES[1]]: { classes: "bg-danger text-white" },
  [PERIOD_STATES[2]]: { classes: "bg-dark text-white" },
};

const PeriodCell = ({
  day,
  index,
  onCellChange,
  loading,
  frequency,
  monthList,
  result,
  moduleId,
  teamId,
  metrics,
  setPeriodMetrics
}) => {
  const [selectedId, setSelectedId] = useState(moduleId)
  const [resultId, setResultId] = useState(result.id)
  const [status, setStatus] = useState(result.periodStatus)
  const [displayedStatus, setDisplayedStatus] = useState(result.periodStatus)
  const [eventFired, setEventFired] = useState(false)

  const [nextStatus] = useDebounce(displayedStatus, 1000)

  const onError = (error) => {
    setDisplayedStatus(status)

    displayErrorToast(error)
  };

  const moduleChanged = useCallback(() => {
    setSelectedId(moduleId)
    setResultId(result.id)
    setStatus(result.periodStatus)
    setDisplayedStatus(result.periodStatus)
  }, [result, setDisplayedStatus, setStatus, setResultId, moduleId, setSelectedId])

  useEffect(() => {
    if(moduleId !== selectedId) moduleChanged()
  }, [moduleId, selectedId, moduleChanged])

  const [updatePeriod] = useMutation(UPDATE_PERIOD_MUTATION, {
    onError,
    onCompleted: ({ updateCoachingReviewPeriodMetric }) => {
      setStatus(updateCoachingReviewPeriodMetric.periodStatus);
      setPeriodMetrics(metrics.map(metric => {
        return metric.id !== resultId ? metric : {
          ...metric,
          periodStatus: updateCoachingReviewPeriodMetric.periodStatus
        }
      }))
    },
  });

  const [createPeriod] = useMutation(CREATE_PERIOD_MUTATION, {
    onError,
    onCompleted: ({ createCoachingReviewPeriodMetric }) => {
      setStatus(createCoachingReviewPeriodMetric.periodStatus);
      setResultId(createCoachingReviewPeriodMetric.id);
      setPeriodMetrics(metrics.map(metric => {
        return metric.periodValue !== result.periodValue ? metric : {
          ...metric,
          periodStatus: createCoachingReviewPeriodMetric.periodStatus,
          id: createCoachingReviewPeriodMetric.id
        }
      }))
    },
  });

  const onClick = () => {
    if (loading) return;
    setDisplayedStatus(PERIOD_STATES[PERIOD_STATES.indexOf(displayedStatus) + 1] ? PERIOD_STATES[PERIOD_STATES.indexOf(displayedStatus) + 1] : PERIOD_STATES[0])
    setChange((PERIOD_STATES[PERIOD_STATES.indexOf(displayedStatus) + 1] ? PERIOD_STATES[PERIOD_STATES.indexOf(displayedStatus) + 1] : PERIOD_STATES[0]))
    setEventFired(true)
  };

  const create = useCallback(() => {
    createPeriod({
      variables: {
        module: moduleId,
        team: teamId,
        periodStatus: displayedStatus,
        periodKey: `${result.periodKey}`,
        periodValue: `${result.periodValue}`
      },
    });
  }, [createPeriod, displayedStatus, teamId, moduleId, result]);

  const update = useCallback(() => {
    updatePeriod({
      variables: {
        periodStatus: displayedStatus,
        id: resultId,
      },
    });
  }, [updatePeriod, displayedStatus, resultId]);

  const setChange = (status) => {
    onCellChange({ state: PERIOD_STATES.indexOf(status), index: day });
  }

  useEffect(() => {
    if(eventFired) {
      setEventFired(false)
      if (resultId) update();
      else create();
    }
  }, [ nextStatus, displayedStatus, resultId, update, create, eventFired, setEventFired]);


  const className = classnames(
    "attendance-cell attendance-date-border text-center p-1 border mr-3",
    dateCellProps[displayedStatus].classes
  );

  return (
    <div onClick={() => onClick()} className={className}>
      {monthList[index].substr(0, 1)}
    </div>
  );
};

export default PeriodCell;
