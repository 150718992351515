import { gql } from "apollo-boost";
import { client } from "./BaseService";


const getCompany = async() => {
  return await client.query({
    query: gql`query {
      company {
        id
        name
        clientNumber
        country
        countryRegistrationNumber
        vatNumber
        address
        administratorFullName
        administratorMobileNumber
        administratorEmail
        supportEmail
        billingContactPerson
        billingEmail
        billingContactNumber
        finalcialYearEnd
        vision
        color
        consultant {
          id
          firstNames
          lastName
        }
        language
        users {
          id
          email
          firstNames
          lastName
          role
          archived
        }
      }
    }`
  })
}

const updateCompany = async(company, consultantId) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateCompany(
        id: "${company.id}"
        name: "${company.name}"
        country: "${company.country}"
        countryRegistrationNumber: "${company.countryRegistrationNumber}"
        vatNumber: "${company.vatNumber}"
        address: "${company.address}"
        vision: "${company.vision}"
        administratorFullName: "${company.administratorFullName}"
        administratorMobileNumber: "${company.administratorMobileNumber}"
        administratorEmail: "${company.administratorEmail}"
        supportEmail: "${company.supportEmail}"
        billingContactPerson: "${company.billingContactPerson}"
        billingEmail: "${company.billingEmail}"
        billingContactNumber: "${company.billingContactNumber}"
        finalcialYearEnd: "${company.finalcialYearEnd}"
        color: "${company.color}",
        consultantId: "${consultantId}"
        language: ${company.language}
     ) {
       id
       name
       clientNumber
       country
       countryRegistrationNumber
       vatNumber
       address
       administratorFullName
       administratorMobileNumber
       administratorEmail
       supportEmail
       billingContactPerson
       billingEmail
       billingContactNumber
       finalcialYearEnd
       vision
       color
       consultant {
         id
         firstNames
         lastName
       }
       language
       users {
         id
         email
         firstNames
         lastName
         role
         archived
       }
      }
    }
    `
  })
}

export {
  getCompany,
  updateCompany
}
