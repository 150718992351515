import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import LabelComponent from "../../../components/utils/getCompanyLabel";

const getLabelText = key => <LabelComponent val={key}/>

const Legend = ({ active, includeCoach = true }) => (
  <div
    style={{ bottom: active ? "116px" : "58px" }}
    className="bg-light-grey d-flex p-2 justify-content-around fixed-bottom"
  >
    <div style={{ display:"inline-flex"}}>
      <div className="attendance-cell text-center font-weight-bold p-1 mr-2 bg-grey max-cell-width">
      </div>
      <div style = {{ paddingTop: "1.5%"}}>
        {getLabelText("scheduled")}
      </div>
    </div>
    <div style={{ display:"inline-flex"}}>
      <div className="attendance-cell text-center font-weight-bold p-1 mr-2 color-selected text-white max-cell-width">

      </div>
      <div style = {{ paddingTop: "1.5%"}} >
        {getLabelText("completed")}
      </div>
    </div>
    {includeCoach && (
        <div style={{ display:"inline-flex"}}>
          <div className="attendance-cell text-center font-weight-bold p-1 mr-2 color-selected text-white max-cell-width">
            <FontAwesomeIcon icon={faCircle} />
          </div>
          <div style = {{ paddingTop: "1.5%"}}>
            {getLabelText("confirmed_by_coach")}
          </div>
        </div>
    )}
    <div style={{ display:"inline-flex"}}>
      <div className="attendance-cell text-center font-weight-bold p-1 mr-2 bg-color-negative text-white max-cell-width">
      </div>
      <div style = {{ paddingTop: "1.5%"}}>
        {getLabelText("not_done")}
      </div>
    </div>
  </div>
);

export default Legend;
