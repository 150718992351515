import React, {Component, createRef, Fragment} from "react";
import { toast } from 'react-toastify';
import displayErrorToast from "../../components/utils/displayErrorToast";
import SubHeaderComponent from "../../components/SubHeaderComponent";
import RealtimeRefreshComponent from "../../components/RealtimeRefreshComponent";
import { connect } from "react-redux";
import {Row, Col, Accordion, Card, ToggleButton, ButtonGroup, Button, Dropdown} from "react-bootstrap";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IssueComponent from "./IssueComponent";
import { createAction, updateAction, archiveIssue, nextStep, deleteIssue, createImprovementIdea } from "../../services/CustomerSupplierService";
import { createProblem, updateProblem, getMeasurements, getProblemsIssues } from "../../services/ProblemSolvingService";
import LabelComponent from "../../components/utils/getCompanyLabel";
import * as DefaultLabels from '../../assets/glossary.json';
// icons
import Add from '../../assets/ButtonIcons/Add Blue.svg';
import {downloadProblemSolvingData} from "../../utils/exportUtils";
const labels = DefaultLabels.default;

const getLabelText = key => <LabelComponent val={key}/>

const sortByFields = [
  {key: "newest", value: "Newest"},
  {key: "oldest", value: "Oldest"},
  {key: "origin(a-z)", value: "Origin (A-Z)"},
  {key: "origin(z-a)", value: "Origin (Z-A)"},
]

class ProblemSolvingScreen extends Component {
  state = {
    issues: [],
    measurements: [],
    loading: false,
    loadingId: "",
    loadingType: "",
    whoId: "",
    when: "",
    editable: false,
    actionId: "",
    archived: false,
    problemId: "",
    actions: [],
    date: "",
    feedback: "",
    rootCause: "",
    newSolution: "",
    newFix: "",
    type: "",
    origin: null,
    teamId: 0,
    screenType: "customer",
    selectedMeasurement: "all",
    teamFilter: "current",
    highlightProblemId: null,
    sortBy: sortByFields[0].key
  }

  constructor (props) {
    super (props)

    this.targetRowRef = createRef();

    this.props.togglePageLoad(true)
  }

  scrollToTargetRow = () => {
    if (this.targetRowRef.current)
    {
      this.targetRowRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    }
  };

  async componentDidMount() {
    if (this.props.selectedTeam.id !== 0) {
      await this.fetchMeasurements()
      const response = await getProblemsIssues(false, false, this.props.selectedTeam.id);

      const problem = this.getProblemId();
      this.setState({
        issues: JSON.parse(response.data.getIssues)[0]?.loggedIssues ?? [],
        highlightProblemId: problem.problemId
      })

      if(this.state.archived === false && problem.isArchived)
      {
        await this.toggleArchived(true);
      }

      this.handleSort("newest");

      this.props.togglePageLoad(false)

      this.scrollToTargetRow();
    }
  }

  getProblemId = () => {
    const queryParams = new URLSearchParams(window.location.search);
    let problemId = queryParams.get('issue');
    let isArchived = queryParams.get('archived') === 'true';

    return { problemId, isArchived };
  }

  async fetchMeasurements() {
    const response = await getMeasurements(this.props.selectedTeam.id)
    let array = [];
    response.data.teamOriginMeasurements.map(measure => {
      const exists = array.find(arr => arr.id === measure.id)

      if(!exists) {
        array.push(measure)
      }

      return true
    });

    this.setState({
      measurements: array.sort((a, b) => a.measurement.localeCompare(b.measurement))
    })
  }

  getText = val => {
    const { user } = this.props;

    const companyId = localStorage.getItem("companyId");
    let lang = "EN";
    let langLabels = labels.find(lbl => lbl.lang === lang);
    let def = langLabels.glossary.find(label => label.key === val);

    if(user && user.companies && user.companies.length) {
      const company = user.companies.find(company => company.id === companyId);
      if(company) {
        lang = company.language;
        langLabels = labels.find(lbl => lbl.lang === lang);
        def = langLabels.glossary.find(label => label.key === val);
        if(!def) {
          lang = "EN";
          langLabels = labels.find(lbl => lbl.lang === lang);
          def = langLabels.glossary.find(label => label.key === val);
        }
      }
      if(company && company.companyGlossaries) {
        const custom = company.companyGlossaries.find(label => label.key === val);

        if(custom) return custom.value
      }
    }

    return def.value
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.selectedTeam.id !== this.props.selectedTeam.id) {
      this.props.togglePageLoad(true);
      await this.fetchMeasurements();
      const response = await getProblemsIssues(this.state.teamFilter === 'current' ? false : true, this.state.archived, this.props.selectedTeam.id);

      const problem = this.getProblemId();

      this.setState({
        issues: JSON.parse(response.data.getIssues)[0]?.loggedIssues ?? [],
        filterTeam: "current",
        highlightProblemId: problem.problemId
      })

      if(this.state.archived === false && problem.isArchived)
      {
        await this.toggleArchived(true);
      }

      this.handleSort("newest");

      this.props.togglePageLoad(false)

      this.scrollToTargetRow();
    }

    if (prevProps.refresh !== this.props.refresh && this.props.refresh) {
      this.props.togglePageLoad(true);
      await this.fetchMeasurements();
      const response = await getProblemsIssues(this.state.teamFilter === 'current' ? false : true, this.state.archived, this.props.selectedTeam.id);

      this.setState({
        issues: JSON.parse(response.data.getIssues)[0]?.loggedIssues ?? [],
        filterTeam: "current"
      })

      this.handleSort("newest");

      this.props.toggleRefresh()
      this.props.toggleHasNewData()
      this.props.togglePageLoad(false)
    }
  }

  async fetchIssues(is_archive = this.state.archived, filter = this.state.teamFilter) {
    let issues = [];
    this.setLoading(this.props.selectedTeam.id, "team");

    if(filter === "current") {
      const response = await getProblemsIssues(filter === 'current' ? false : true, is_archive, this.props.selectedTeam.id);

      issues = JSON.parse(response.data.getIssues)[0]?.loggedIssues ?? [];
    } else {
      const response = await getProblemsIssues(filter === 'current' ? false : true, is_archive, this.props.selectedTeam.id);

      const teams = JSON.parse(response.data.getIssues);
      teams.filter(team => team.id === this.props.selectedTeam.id).map(team => issues.push(...team.loggedIssues))
      teams.filter(team => team.id !== this.props.selectedTeam.id).sort((a,b) => {
        return a.name.localeCompare(b.name)
      }).map(team => issues.push(...team.loggedIssues))
    }

    this.setState({
      issues: issues
    });

    this.handleSort("newest");

    this.stopLoading();
  }

  addProblem = () => {
    const { issues } = this.state
    const generatedId = Math.ceil(Math.random() * 100)

    issues.unshift({
      id: generatedId,
      reviewDate: new Date(),
      whatText: "",
      whyText: "",
      archived: false,
      origin: this.state.selectedMeasurement === ("all" || "other") ? null : {
        id: this.state.selectedMeasurement
      },
      actions: [],
      creator: {
        id: this.props.selectedTeam.id,
        name: this.props.selectedTeam.name
      }
    })
    this.setState({
      issues,
      problemId: generatedId,
      editable: 1,
      date: new Date(),
      type: "create",
      origin: this.state.selectedMeasurement === ("all" || "other") ? null : this.state.selectedMeasurement
    })

  }

  editProblem = (problemId) => {
    const { issues } = this.state

    const problem = issues.filter((problem) => {
      return problem.id === problemId
    })[0]

    this.setState({
      problemId: problemId,
      feedback: problem.whatText,
      rootCause: problem.whyText,
      actions: problem.actions?.length > 0 ? problem.actions[0]?.id ? problem.actions : [] : [],
      date: new Date(problem.reviewDate),
      editable: 1,
      type: "edit",
      origin: problem.origin ? problem.origin.id : null
    })
  }

  saveAction = (type) => {
    const { newSolution, newFix, actions } = this.state;
    actions.push({description: type === "FEEDBACK" ? newSolution : newFix, status: "NONE", actionType: type})
    type === "FEEDBACK" ? this.setState({newSolution: ""}) : this.setState({newFix: ""})
  }

  saveActions = (id, actions) => {
    return Promise.all(actions.map(async(action) => {
      if(action.description !== "") {
        if(action.id) {
          await updateAction(action.id, action.actionType, action.status, action.description)
        } else {
          await createAction(id, action.actionType, action.status, action.description)
        }
      }
    }))
  }

  saveProblem = async () => {
    try {
      const {type,  problemId, actions, date, feedback, rootCause, newSolution, newFix, origin } = this.state
      this.setLoading(problemId, "save")
      actions.push({description: newSolution, status: "NONE", actionType: "FEEDBACK"})
      actions.push({description: newFix, status: "NONE", actionType: "ROOT_CAUSE"})
      if(type === "create") {
        const response = await createProblem(this.props.selectedTeam.id, feedback, rootCause, date.toISOString(), (origin === "other" ? null : origin))
        await this.saveActions(response.data.createIssue.id, actions)
      } else {
        const response = await updateProblem(problemId, feedback, rootCause, date.toISOString(), (origin === "other" ? null : origin))
        await this.saveActions(response.data.updateIssue.id, actions)
      }
      await this.fetchIssues()
    } catch(e) {
      console.log(e)
    } finally {
      this.setState({
        customerId: "",
        problemId: "",
        actions: [],
        date: "",
        feedback: "",
        newSolution: "",
        newFix: "",
        rootCause: "",
        editable: 0,
        loading: false,
        loadingType: "",
        loadingId: "",
        type: "",
        origin: null
      })
    }
  }

  deleteProblem = async (problem) => {
    let hasNextSteps = false

    problem.actions.filter((a) => a.action_deleted === false).map((action) => {
      if(action.status !== "NONE") {
        hasNextSteps = true
      }

      return true
    })

    if(!hasNextSteps) {
      if (window.confirm(this.getText("delete_department_prompt"))) {
        this.setLoading(problem.id, "delete")
        try {
          await deleteIssue(problem.id)
          await this.fetchIssues()
          this.stopLoading()
        } catch (error) {
          this.stopLoading()
          displayErrorToast(error)
        }
      }
    } else {
      toast.error(getLabelText("delete_issue_error"))
    }
  }

  clearEditableState = async () => {
    this.setLoading(this.state.problemId, "cancel")
    await this.fetchIssues()
    this.setState({
      problemId: "",
      date: "",
      rootCause: "",
      actions: [],
      newSolution: "",
      newFix: "",
      feedback: "",
      editable: 0,
      loading: false,
      loadingType: "",
      loadingId: "",
      origin: null,
      type: ""
    })
  }

  canArchive = (actions) => {
    let canArchive = true

    actions.map((action) => {
      if(action.id && action.status !== "COMPLETED") {
        canArchive = false
      }
      return true
    })

    return canArchive
  }

  archiveProblem = async (problemId, archived, actions) => {
    if(this.canArchive(actions)) {
      this.setLoading(problemId, "archive")

      await archiveIssue(problemId, !archived)

      await this.fetchIssues()

      this.stopLoading()
    } else {
      toast.error(getLabelText("archive_issue_error"))
    }
  }

  toggleArchived = async () => {
    const nextArchived = !this.state.archived;
    this.setState({
      archived: nextArchived
    });
    await this.fetchIssues(nextArchived);
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleDateChange = date => {
    this.setState({
      date
    })
  }

  editAction = (key, event) => {
    const {actions} = this.state

    actions[key].description = event.target.value

    this.setState({actions})
  }

  nextStep = async (id) => {
    await nextStep(id)

    await this.fetchIssues()

  }

  setLoading = (id, type) => {
    this.setState({
      loading: true,
      loadingId: id,
      loadingType: type
    })
  }

  stopLoading = () => {
    this.setState({
      loading: false,
      loadingId: "",
      loadingType: ""
    })
  }

  toggleTeamFilter = (filter) => {
    this.setLoading(this.props.selectedTeam.id, "team")
    this.setState({ teamFilter: filter, issues: [] });
    this.fetchIssues(this.state.archived, filter);
  }

  postToImprovementIdeas = async (issue, action) => {
    this.setLoading(issue.id, "impIdea")

    await createImprovementIdea(this.props.selectedTeam.id, action.description, "", issue.reviewDate, action.id)

    await this.fetchIssues()

    this.setState({
      customerId: "",
      problemId: "",
      actions: [],
      date: "",
      feedback: "",
      newSolution: "",
      newFix: "",
      rootCause: "",
      editable: 0,
      loading: false,
      loadingType: "",
      loadingId: ""
    })
  }

  problemIsApplicable = ({ id, origin, archived: problemArchived }) => {
    const { selectedMeasurement, type, problemId, archived, teamFilter, measurements } = this.state;
    let valid = false;

    if(problemArchived === archived) {
      if(selectedMeasurement === "all") {
        valid = !(teamFilter === "all") || !origin || !!measurements.find(measurement => measurement.id === origin.id)
      } else if(selectedMeasurement === "other") {
        valid = !origin
      } else {
        valid = origin && origin.id === selectedMeasurement
      }
    }

    if(type === "create" && problemId === id) valid = true;

    return valid;
  }

  downloadXLSX = async () => {
    let issues = this.state.issues.filter(problem => this.problemIsApplicable(problem));
    await downloadProblemSolvingData(issues);
  }

  handleSort = (sortKey) => {
    let issues = this.state.issues;
    let sorted = [];
    let remaining = [];
    switch (sortKey)
    {
      case "newest":
        sorted = this.state.issues.filter(issue => issue.reviewDate).sort((a, b) => new Date(b.reviewDate).setHours(24, 0, 0, 0) - new Date(a.reviewDate).setHours(24, 0, 0, 0));
        issues = [...sorted];
        break;
      case "oldest":
        sorted = this.state.issues.filter(issue => issue.reviewDate).sort((a, b) => new Date(a.reviewDate).setHours(24, 0, 0, 0) - new Date(b.reviewDate).setHours(24, 0, 0, 0));
        issues = [...sorted];
        break;
      case "origin(a-z)":
        sorted = this.state.issues.filter(issue => issue.origin?.measurement).sort((a, b) => a.origin?.measurement?.trim()?.localeCompare(b.origin?.measurement?.trim()));
        remaining = this.state.issues.filter(issue => !issue.origin?.measurement);
        issues = [...sorted, ...remaining];
        break;
      case "origin(z-a)":
        sorted = this.state.issues.filter(issue => issue.origin?.measurement).sort((a, b) => b.origin?.measurement?.trim()?.localeCompare(a.origin?.measurement?.trim()));
        remaining = this.state.issues.filter(issue => !issue.origin?.measurement);
        issues = [...sorted, ...remaining];
        break;
    }

    this.setState({
      sortBy: sortKey,
      issues: issues
    })
  }

  render () {
    return (
      <Fragment>
        <SubHeaderComponent>
          <Col className="text-right childenSpace">
            <div className={"refreshBtn"}>
              <RealtimeRefreshComponent
                refresh={this.props.refresh}
                hasNewData={this.props.hasNewData}
                toggleRefresh={this.props.toggleRefresh}
              />
            </div>
          </Col>
        </SubHeaderComponent>
        <div className="grey-header-space container-left-minus13 mb-3">
          <Row className="direction-btn">
            <Col xs={3} className="text-right px-4">
              <ButtonGroup toggle className="ml-1 w-100">
                <ToggleButton
                    type="radio"
                    variant="secondary"
                    name="createType"
                    className="padding-btn w-50"
                    value="0"
                    checked={this.state.teamFilter === "current"}
                    onChange={() => this.toggleTeamFilter("current")}
                >
                  {this.state.loading && this.state.loadingType === "team" && this.state.teamFilter === "current"
                      ? (
                          <FontAwesomeIcon className="text-white fa-spin" icon={faSpinner} />
                      ) : getLabelText("current_team")
                  }
                </ToggleButton>
                <ToggleButton
                    type="radio"
                    variant="secondary"
                    name="createType"
                    className="padding-btn w-50"
                    value="1"
                    checked={this.state.teamFilter === "all"}
                    onChange={() => this.toggleTeamFilter("all")}
                >
                  {this.state.loading && this.state.loadingType === "team" && this.state.teamFilter === "all"
                      ? (
                          <FontAwesomeIcon className="text-white fa-spin" icon={faSpinner} />
                      ) : getLabelText("all_teams")
                  }
                </ToggleButton>
              </ButtonGroup>
            </Col>
            <Col xs={5} className="pl-3">
              <table className="w-100">
                <tbody>
                <tr>
                  <td width="30%">
                    <label className="cdi-blue-txt d-inline">{getLabelText("select_origin")}:</label>
                  </td>
                  <td width="70%">
                    <select
                        className="form-control cdi-ddl ml-0 w-100 d-inline"
                        value={this.state.selectedMeasurement}
                        onChange={(e) => this.setState({ selectedMeasurement: e.target.value })}
                    >
                      <option value="all">All</option>
                      {this.state.measurements.map((measurement, i) => <option key={i} value={measurement.id}>{measurement.measurement}</option>)}
                      <option value="other">Other</option>
                    </select>
                  </td>
                </tr>
                </tbody>
              </table>
            </Col>
            <Col xs={4} className="text-right pr-0">
              <ButtonGroup toggle className="ml-1">
                <ToggleButton
                    type="radio"
                    variant="secondary"
                    name="createType"
                    value="0"
                    checked={!this.state.archived}
                    onChange={() => this.toggleArchived()}
                >
                  {getLabelText("active")}
                </ToggleButton>
                <ToggleButton
                    type="radio"
                    variant="secondary"
                    name="createType"
                    value="1"
                    checked={this.state.archived}
                    onChange={() => this.toggleArchived()}
                >
                  {getLabelText("archived")}
                </ToggleButton>
                <Button className={"btn btn-secondary"} onClick={() => this.downloadXLSX()}>{getLabelText("export")}</Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row className="mx-auto justify-content-center mt-1">
            <Col className="pr-0">
              <Accordion defaultActiveKey="0">
                <Card key="0">
                  <Card.Header>
                    <div className="d-flex justify-content-end">
                      <div>
                        <select className="form-control" value={this.state.sortBy} onChange={(e) => this.handleSort(e.target.value)}>
                            { sortByFields.map(sortBy => (
                                  <option key={sortBy.key} value={sortBy.key}>{sortBy.value}</option>
                            ))}
                        </select>
                      </div>
                      <div className="ml-3 d-flex align-items-center justify-content-end">
                        {!this.state.archived && (
                            <button className={"btn-icons-container" + (this.state.editable === 1 ? " disabled" : "")} type="submit" disabled={this.state.archived} onClick={this.addProblem}>
                              <img src={Add} alt="Add" className="btn-icons"/>
                            </button>
                        )}
                      </div>
                    </div>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Fragment>
                      {this.state.issues.filter(problem => this.problemIsApplicable(problem)).length ? this.state.issues.filter(problem => this.problemIsApplicable(problem)).map((problem, i) => (
                          <table className="customer-supplier-tbl" key={i} ref={this.state.highlightProblemId === problem.id ? this.targetRowRef : null}>
                            <tbody className={this.state.highlightProblemId === problem.id ? "bg-highlight-light" : ""}>
                              <IssueComponent
                                  problem={problem}
                                  type={"problemSolving"}
                                  problemId={this.state.problemId}
                                  editable={this.state.editable}
                                  archiveProblem={this.archiveProblem}
                                  addProblem={this.addProblem}
                                  handleChange={this.handleChange}
                                  saveAction={this.saveAction}
                                  clearEditableState={this.clearEditableState}
                                  newSolution={this.state.newSolution}
                                  newFix={this.state.newFix}
                                  actions={this.state.actions}
                                  saveProblem={this.saveProblem}
                                  editProblem={this.editProblem}
                                  feedback={this.state.feedback}
                                  rootCause={this.state.rootCause}
                                  date={this.state.date}
                                  handleDateChange={this.handleDateChange}
                                  editAction={this.editAction}
                                  nextStep={this.nextStep}
                                  loading={this.state.loading}
                                  loadingType={this.state.loadingType}
                                  loadingId={this.state.loadingId}
                                  deleteProblem={this.deleteProblem}
                                  measurements={this.state.measurements}
                                  selectedTeam={this.props.selectedTeam}
                                  teamFilter={this.state.teamFilter}
                                  origin={this.state.origin}
                                  postToImprovementIdeas={this.postToImprovementIdeas}
                              />
                            </tbody>
                          </table>
                      )) :
                        this.state.loading ? 
                        <div className="my-2 py-3 text-center">
                          <FontAwesomeIcon fontSize={36} className="fa-spin cdi-blue" icon={faSpinner} />  
                        </div> : <h4 className="my-2 py-3 text-center">No Issues Found</h4>
                      }
                    </Fragment>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
          </Row>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ProblemSolvingScreen);
