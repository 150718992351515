export const PERIOD_FREQUENCIES = [
  "DAY",
  "WEEK",
  "MONTH"
];

export const PERIOD_STATES = [
  "NORMAL",
  "TRIGGER",
  "NON_WORKING",
];

export const ROUTINE_STATES = [
  "SCHEDULED",
  "COMPLETE",
  "CONFIRMED",
  "INCOMPLETE",
];

export const frequencyProps = {
  [PERIOD_FREQUENCIES[0]]: { text: "Daily" },
  [PERIOD_FREQUENCIES[1]]: { text: "Weekly" },
  [PERIOD_FREQUENCIES[2]]: { text: "Monthly" }
};

export const days = [
  {
    value: "monday",
    text: "M",
    key: "m_day"
  },
  {
    value: "tuesday",
    text: "T",
    key: "t_day"
  },
  {
    value: "wednesday",
    text: "W",
    key: "w_day"
  },
  {
    value: "thursday",
    text: "T",
    key: "tu_day"
  },
  {
    value: "friday",
    text: "F",
    key: "f_day"
  },
  {
    value: "saturday",
    text: "S",
    key: "s_day"
  },
  {
    value: "sunday",
    text: "S",
    key: "su_day"
  },
];

export const weeks = [
  {
    value: "wk1",
    text: "WK 1",
  },
  {
    value: "wk2",
    text: "WK 2",
  },
  {
    value: "wk3",
    text: "WK 3",
  },
  {
    value: "wk4",
    text: "WK 4",
  },
  {
    value: "lw",
    text: "WK L",
  },
];
