import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useMutation, useQuery } from "@apollo/react-hooks";
import displayErrorToast from "../../components/utils/displayErrorToast";
import PaginationComponent from "../../components/PaginationComponent";
import SubHeaderComponent from "../../components/SubHeaderComponent";
import EditRow from "./EditRow";
import DisplayRow from "./DisplayRow";
import {
  RECOGNITION_TYPES_QUERY,
  CREATE_RECOGNITION_TYPE_MUTATION,
  UPDATE_RECOGNITION_TYPE_MUTATION,
  DELETE_RECOGNITION_TYPE_MUTATION
} from "./resources/gql";
import * as DefaultLabels from '../../assets/glossary.json';
import LabelComponent from "../../components/utils/getCompanyLabel";
import Constants from "../../utils/constants";
const labels = DefaultLabels.default;

const getLabelText = key => <LabelComponent val={key}/>


const RecognitionTypesScreen = ({ togglePageLoad, user, ...props}) => {
  const { data, loading } = useQuery(RECOGNITION_TYPES_QUERY)
  const [recognitionTypes, setRecognitionTypes] = useState([]);
  const [isCreatingNewItem, setIsCreatingNewItem] = useState(false);
  const [itemBeingEdited, setItemBeingEdited] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [firstIndex, setFirstIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(9);

  const reset = () => {
    setIsCreatingNewItem(false);
    setItemBeingEdited("");
    props.toggleDirtyState()
  };

  const onError = (error) => {
    displayErrorToast(error);
    reset();
  };

  const getText = val => {
    const companyId = localStorage.getItem("companyId");
    let lang = "EN";
    let langLabels = labels.find(lbl => lbl.lang === lang);
    let def = langLabels.glossary.find(label => label.key === val);

    if(user && user.companies && user.companies.length) {
      const company = user.companies.find(company => company.id === companyId);
      if(company) {
        lang = company.language;
        langLabels = labels.find(lbl => lbl.lang === lang);
        def = langLabels.glossary.find(label => label.key === val);
        if(!def) {
          lang = "EN";
          langLabels = labels.find(lbl => lbl.lang === lang);
          def = langLabels.glossary.find(label => label.key === val);
        }
      }
      if(company && company.companyGlossaries) {
        const custom = company.companyGlossaries.find(label => label.key === val);

        if(custom) return custom.value
      }
    }

    if(!def) {
      console.log(val)
    }
    return def.value
  }

  useEffect(() => {
    if(data && data.recognitionTypes) {
      setRecognitionTypes(data.recognitionTypes)
      setTotalPages((data.recognitionTypes.length ? Math.ceil(data.recognitionTypes.length / 10) : 1))
    }
    togglePageLoad(loading)
  }, [data, loading, setRecognitionTypes, togglePageLoad])

  const [createRecognition] = useMutation(CREATE_RECOGNITION_TYPE_MUTATION, {
    onError,
    onCompleted: ({ createRecognitionType }) => {
      setRecognitionTypes([createRecognitionType, ...recognitionTypes]);
      reset();
    },
  });

  const [updateRecognition] = useMutation(UPDATE_RECOGNITION_TYPE_MUTATION, {
    onError,
    onCompleted: ({ updateRecognitionType }) => {
      const nextRecognitionTypes = recognitionTypes.map((e) =>
        e.id === updateRecognitionType.id ? updateRecognitionType : e
      );
      setRecognitionTypes(nextRecognitionTypes);
      reset();
    },
  });

  const [deleteRecognition] = useMutation(DELETE_RECOGNITION_TYPE_MUTATION, {
    onError,
    onCompleted: ({ deleteRecognitionType }) => {
      setRecognitionTypes(
        recognitionTypes.filter((e) => e.id !== deleteRecognitionType.id)
      );
      reset();
    },
  });

  const onOpenCreate = () => {
    setIsCreatingNewItem(true);
    props.toggleDirtyState()
    setItemBeingEdited("");
  };

  const onOpenEdit = (id) => {
    setIsCreatingNewItem(false);
    props.toggleDirtyState()
    setItemBeingEdited(id);
  };

  const onDelete = (id) => {
    deleteRecognition({ variables: { id } });
  };

  const onSave = ({ id, name }) => {
    if (id) return updateRecognition({ variables: { id, name } });
    createRecognition({ variables: { name } });
  };

  function setPagination (page) {
    setFirstIndex((parseInt(page) * 10) - 10)
    setLastIndex((parseInt(page) * 10) - 1)
    setPage(page)
  }
  
  function checkRole() {
    return user.role === Constants.Permission.Consultant || user.role === Constants.Permission.CompanyAdmin;
  }

  return (
    <>
      <SubHeaderComponent>
        <Row className="justify-content-center">
          <Col className="text-right" xs={12}>
            <Button onClick={onOpenCreate} disabled={!checkRole()} className={"btn-nrml" + (isCreatingNewItem || itemBeingEdited !== "" ? " disabled" : "")}>
              {getLabelText("add")}
              <FontAwesomeIcon className="ml-2" icon={faPlus} />
            </Button>
          </Col>
        </Row>
      </SubHeaderComponent>
      <div className="grey-header-space container-left">
        <Row className="justify-content-center">
          <Col xl={12}>
            <form>
              <Table striped bordered hover className="table-heading-background">
                <thead>
                <tr>
                  <th width="80%" className="text-center">{getLabelText("recognition_screen")}</th>
                  <th width="20%" className="text-center"></th>
                </tr>
                </thead>
                {recognitionTypes && (
                    <tbody>
                    {isCreatingNewItem ? (
                        <EditRow onSave={onSave} onCancel={reset} />
                    ) : null}
                    {recognitionTypes.map(({ name, id }, i) =>
                        i >= firstIndex && i <= lastIndex ? (
                            id === itemBeingEdited ? (
                                <EditRow
                                    key={id}
                                    name={name}
                                    id={id}
                                    onSave={onSave}
                                    onCancel={reset}
                                />
                            ) : (
                                <DisplayRow
                                    onOpenEdit={onOpenEdit}
                                    onDelete={onDelete}
                                    getText={getText}
                                    canDelete={user?.role === Constants.Permission.CompanyAdmin || user?.role === Constants.Permission.Consultant}
                                    canEdit={user?.role === Constants.Permission.CompanyAdmin || user?.role === Constants.Permission.Consultant}
                                    key={id}
                                    name={name}
                                    id={id}
                                    disabled={isCreatingNewItem || itemBeingEdited !== ""}
                                    checkRole={checkRole}
                                />
                            )
                        ) : null
                    )}
                    </tbody>
                )}
              </Table>
              {totalPages > 1 && (
                  <PaginationComponent
                      active={page}
                      disabled={false}
                      setPage={setPagination}
                      total={totalPages}
                  />
              )}
            </form>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.userReducer.user
})

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(RecognitionTypesScreen);
