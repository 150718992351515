const changeListItemOrder = (list, dir, index, overrideReplaceIndex) => {
  // Bail if operation is at an edge limit
  if (
    (dir === "dec" && index < 1) ||
    (dir === "inc" && index >= list.length - 1)
  )
    return list;

  let replaceIndex = overrideReplaceIndex

  if (!replaceIndex) replaceIndex = dir === "dec" ? index - 1 : index + 1;

  const sortedList = list.map((e, i) => {
    if (i === index) return list[replaceIndex];
    if (i === replaceIndex) return list[index];
    return e;
  });

  return sortedList;
};

export default changeListItemOrder;
