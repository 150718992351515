import React, { Component, Fragment } from "react";

// bootstrap
import { Table, Button, Row, Col, OverlayTrigger, Tooltip, ButtonGroup, ToggleButton } from "react-bootstrap";

import { createUser, getUsers, deleteUser, updateUser, importUsers, clearPassword, updateArchived, getTeamExport } from "../services/UserService";
import { faPlus, faSpinner} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { fetchUser } from '../redux/services/UserService';
import displayErrorToast from '../components/utils/displayErrorToast';
import PaginationComponent from "../components/PaginationComponent";
import SubHeaderComponent from "../components/SubHeaderComponent";
import CSVReader from 'react-csv-reader';
import * as XLSX from 'xlsx';
import LabelComponent from "../components/utils/getCompanyLabel";
import * as DefaultLabels from '../assets/glossary.json';

// icons
import Save from '../assets/ButtonIcons/Save Blue.svg';
import Cancel from '../assets/ButtonIcons/Cancel Blue.svg';
import Edit from '../assets/ButtonIcons/Edit Blue.svg';
import Reset from '../assets/ButtonIcons/Reset Blue.svg';
import Archive from '../assets/ButtonIcons/Archive Blue.svg';
import Delete from '../assets/ButtonIcons/Delete Blue.svg';
import PutBack from '../assets/ButtonIcons/Put Back Blue.svg';
import Loader from '../assets/ButtonIcons/Loader Blue.svg';
const labels = DefaultLabels.default;

function renderTooltip(props, text) {
  return (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );
}

class UserScreen extends Component {
  constructor(props) {
    super(props)

    this.props.toggleDirtyState(true)
    this.state = {
      loading: false,
      loadType: "",
      users: [],
      allUsers: [],
      searchUserText: "",
      teams: [],
      data: [],
      newUsers: [],
      redirect: false,
      selectedId: null,
      firstNames: "",
      lastName: "",
      role: "",
      email: "",
      nickName: "",
      isAdmin: false,
      password: "",
      deleteId: "",
      page: 1,
      totalPages: 0,
      firstIndex: 0,
      lastIndex: 9,
      roles: [
        {
          value: "Team Member",
          text: "User"

        },
        {
          value:"Coach",
          text: "Team Admin"
        },
        {
          value:"Function Admin",
          text: "Function Admin"
        },
        {
          value: "Administrator",
          text: "Company Admin"
        },
        {
          value: "Consultant",
          text: "Consultant"
        },
      ],
      archived: false,
    }

    this.setPage = this.setPage.bind(this)
    this.props.togglePageLoad(true)
  }

  async componentDidMount() {
    await this.fetchUsers()
    /* await this.fetchUserExport() */
    this.props.togglePageLoad(false)

    if(this.props.user.role !== "Consultant") {
      this.state.roles = this.state.roles.filter((role) => {
        return role.value !== 'Consultant';
      })
    }

  }

  getLabelText = key => <LabelComponent val={key}/>

  getText = val => {
    const { user } = this.props;

    const companyId = localStorage.getItem("companyId");
    let lang = "EN";
    let langLabels = labels.find(lbl => lbl.lang === lang);
    let def = langLabels.glossary.find(label => label.key === val);

    if(user && user.companies && user.companies.length) {
      const company = user.companies.find(company => company.id === companyId);
      if(company) {
        lang = company.language;
        langLabels = labels.find(lbl => lbl.lang === lang);
        def = langLabels.glossary.find(label => label.key === val);
        if(!def) {
          lang = "EN";
          langLabels = labels.find(lbl => lbl.lang === lang);
          def = langLabels.glossary.find(label => label.key === val);
        }
      }
      if(company && company.companyGlossaries) {
        const custom = company.companyGlossaries.find(label => label.key === val);

        if(custom) return custom.value
      }
    }

    if(!def) {
      console.log(val)
    }
    return def.value
  }

  /*
    fetchUserExport = async () => {
      const response = await getTeamExport();

      this.setState({
        exports: response.data.company.userCSV
      })
    }
  */

  fetchUsers = async (newUser = null) => {
    const { newUsers, archived } = this.state
    const response = await getUsers()
    let users = response.data.company.users.filter(user => !newUsers.find(newUser => newUser.id === user.id))

    if(newUser) {
      users = users.filter(user => user.id !== newUser.id)
    }

    if(response.data.company) {
      this.setState({
        users: users,
        allUsers: users,
        searchUserText : "",
        totalPages: (Math.ceil(response.data.company.users.filter(user => user.archived === archived).length / 10))
      })
    }
  }

  fetchUserCount = async () => {
    const { archived } = this.state
    const response = await getUsers()

    this.setState({
      totalPages: (Math.ceil(response.data.company.users.filter(user => user.archived === archived).length / 10))
    })
  }

  setUserCount = (archived) => {
    const { users } = this.state

    this.setState({
      totalPages: (Math.ceil(users.filter(user => user.archived === archived).length / 10))
    })
  }

  setPage = async (page) => {
    this.setState({
      paginateDisabled: true
    })
    const firstIndex = (parseInt(page) * 10) - 10
    const lastIndex = (parseInt(page) * 10) - 1

    this.setState({
      paginateDisabled: false,
      page: page,
      firstIndex: firstIndex,
      lastIndex: lastIndex

    })
  }

  toggleMode = (e, mode, id = 0) => {
    e.preventDefault()
    const activeMode = this.state.mode
    if(activeMode !== "") {
      this.props.toggleDirtyState()

    }
    this.cancel()
    let options = {
      selectedId: id,
      mode: mode
    }
    let { users, newUsers } = this.state
    const jointUsers = [...newUsers, ...users]

    if (mode === "create") {
      newUsers.unshift({
        id: 0,
        firstNames: "",
        lastName: "",
        nickName: "",
        email: "",
        password: "",
        isAdmin: false,
        role: "",
      })

      options = {
        ...options,
        role: "Team Member",
      }

    } else {
      const user = jointUsers.filter((user) => {
        return user.id === id
      })

      options = {
        ...options,
        firstNames: user[0].firstNames,
        lastName: user[0].lastName,
        email: user[0].email,
        nickName: user[0].nickName,
        mobileNumber: user[0].mobileNumber,
        password: "",
        isAdmin: user[0].isAdmin,
        role: user[0].role,
      }
    }

    this.setState(options)
    if (mode === "create") this.setPage(1)
  }


  toggleArchived() {
    this.setUserCount(!this.state.archived)

    this.setState({
      archived: !this.state.archived,
      paginateDisabled: false,
      page: 1,
      firstIndex: 0,
      lastIndex: 9
    })
  }

  setLoading(id, type) {
    this.setState({
      loading: true,
      deleteId: id,
      loadingType: type
    })
  }

  cancel = () => {
    const { mode } = this.state
    this.props.toggleDirtyState();
    let options = {
      selectedId: "",
      mode: "",
      firstNames: "",
      lastName: "",
      nickName: "",
      email: "",
      password: "",
      mobileNumber: "",
      isAdmin: false,
      role: ""
    }

    if (mode === "create") {
      let { newUsers } = this.state

      if (newUsers[0].id === 0) {
        newUsers.shift()
      }

    }

    this.setState(options)
  }

  archive = async (id, archived, hasTeams) => {
    // this.setLoading(id, "archive")
    if(!hasTeams) {
      this.setState({
        loading: true,
        loadType: "archive",
        deleteId: id
      })
      try {
        await updateArchived(id, !archived)
        await this.fetchUsers()
        this.setState({
          loading: false,
          loadType: "",
          deleteId: null
        })
      } catch (err) {
        displayErrorToast(err)
        this.setState({
          loading: false,
          loadType: "",
          deleteId: null
        })
      }
    } else {
      toast.error(this.getText('delete_users_error'))
    }
  }

  validate = () => {
    const {
      firstNames,
      lastName,
      role
    } = this.state

    return firstNames.length > 0 && lastName.length > 0 && role.length > 0
  }

  handleCheck = (key) => {
    this.setState((prevState) => ({
      [key]: !prevState[key]
    }))
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    this.setState({
      loading: true,
      loadType: "create"
    })
    if (this.validate()) {
      try {
        const { firstNames, lastName, email, nickName, password, mobileNumber, role, mode, selectedId, newUsers } = this.state
        let newUser = null
        if(mode === "create") {
          const response = await createUser({
            lastName: lastName,
            firstNames: firstNames,
            email: email,
            password: password,
            mobileNumber: mobileNumber,
            nickName: nickName,
            isAdmin: role === "Administrator",
            role: role
          })

          newUser = response.data.createUser
        } else {
          const response = await updateUser(selectedId,{
            lastName: lastName,
            firstNames: firstNames,
            email: email,
            password: password,
            mobileNumber: mobileNumber,
            nickName: nickName,
            isAdmin: role === "Administrator",
            role: role
          })
          if(newUsers.find(user => user.id === selectedId)) {
            newUsers[newUsers.findIndex(user => user.id === selectedId)] = response.data.updateUser
          }
        }
        await this.fetchUsers(newUser)
        if(selectedId === this.props.user.id) {
          await fetchUser()
        }
        this.cancel()
        if(newUser) {
          newUsers.unshift(newUser)
        }
        this.setState({
          newUsers
        })
      } catch (error) {
        displayErrorToast(error)
      }
    }

    this.setState({
      loading: false,
      loadType: ""
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  searchUsers = (e) => {
    let archived = this.state.archived;
    if (e.target.value.trim().length > 0) {
      let allUsers = this.state.allUsers;
      
      let tempUsers = allUsers.filter(usr => usr.firstNames?.toLowerCase().includes(e.target.value.toLowerCase()) 
      || usr.lastName?.toLowerCase().includes(e.target.value.toLowerCase()) || usr.email?.toLowerCase().includes(e.target.value.toLowerCase()))
      this.setState({
        users: tempUsers,
        totalPages: (Math.ceil(tempUsers.filter(usr => usr.archived === archived).length / 10))
      })
    }
    else
    {
      let allUsers = this.state.allUsers;
      this.setState({
        users: allUsers,
        totalPages: (Math.ceil(allUsers.filter(usr => usr.archived === archived).length / 10))
      })
    }
    this.setPage(1)
  }

  deleteItem = async (id) => {
    const { newUsers } = this.state
    this.setState({
      loading: true,
      loadType: "delete",
      deleteId: id
    })
    if (window.confirm(this.getText('delete_department_prompt'))) {
      try {
        await deleteUser(id)
        if(newUsers.find(user => user.id === id)) {
          newUsers.splice(newUsers.findIndex(user => user.id === id), 1)
        }
        await this.fetchUsers()
      } catch (error) {
        error.graphQLErrors.map(error => {
          toast.error(error.message)

          return true
        })
      }
    }

    this.setState({
      loading: false,
      loadType: "",
      deleteId: ""
    })
  }

  resetPassword = async (id, email) => {
    this.setState({
      loading: true,
      loadType: "reset",
      deleteId: id
    })
    await clearPassword(id)

    this.setState({
      loading: false,
      loadType: "",
      deleteId: ""
    })
  }

  importUsers = async (data) => {
    const { roles, newUsers } = this.state

    this.setState({
      loading: true,
      loadType: "import"
    })

    const users = data.filter((user, i) => user[0] !== "" && i > 0).map(user => {
      return {
        lastName: user[0],
        firstNames: user[1],
        email: user[2],
        mobileNumber: user[3],
        role: roles.find(role => role.text === user[4]) && user[4] !== "Consultant" ? roles.find(role => role.text === user[4]).value : "Team Member"
      }
    })

    const response = await importUsers(users)

    const self = this
    this.setState({
      newUsers: [...response.data.importUsers, ...newUsers]
    }, async () => {
      await self.fetchUsers()
      self.setState({
        loading: false,
        loadType: ""
      })
    })

  }

  downloadCSV = async () => {
    this.setState({
      loading: true,
      loadType: "export"
    });
    
    let response = await getTeamExport(); 
    const exports = response.data.company.userCSV;

    this.setState({
      loading: false,
      loadType: ""
    });

    if(exports.length) {
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let downloadLink = document.createElement("a")
      const ws = XLSX.utils.json_to_sheet(exports.map(att => {
        delete att.__typename

        return att
      }))
      const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
      const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
      const data = new Blob([excelBuffer], {type: fileType});
      let url = URL.createObjectURL(data);

      downloadLink.href = url;
      downloadLink.download = `${new Date()} - Teams Export.xlsx`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

    }
  }

  userList = (user, i) => {
    const { selectedId, firstNames, lastName, email, mobileNumber, role, loading, loadType, deleteId, roles } = this.state
    let userRow;
    if(selectedId === user.id) {
      userRow = (
        <tr key={i}>
          <td>
            <input
              autoFocus
              className="form-control"
              type="text"
              required
              placeholder="Last Name"
              value={lastName}
              name="lastName"
              tabIndex="1"
              onChange={(e) => this.handleChange(e)}
              autoComplete="off"
            />
            <small><b>*{this.getLabelText("required")}</b></small>
          </td>
          <td>
            <input
              className="form-control"
              type="text"
              required
              placeholder="Name(s)"
              value={firstNames}
              name="firstNames"
              tabIndex="2"
              onChange={(e) => this.handleChange(e)}
              autoComplete="off"
            />
            <small><b>*{this.getLabelText("required")}</b></small>
          </td>
          <td>
            <input
              className="form-control"
              type="text"
              placeholder="Username (E-mail if possible)"
              value={email || ''}
              name="email"
              tabIndex="3"
              onChange={(e) => this.handleChange(e)}
              autoComplete="off"
              required
            />
            <small><b>*{this.getLabelText("required")}</b></small>
          </td>
          <td>
            <input
              className="form-control"
              type="text"
              placeholder="Mobile Number"
              value={mobileNumber || ''}
              name="mobileNumber"
              tabIndex="4"
              onChange={(e) => this.handleChange(e)}
              autoComplete="off"
            />
          </td>
          <td>
            <select className="form-control" name="role" value={role} onChange={(e) => this.handleChange(e)}>
              {roles.map((role, i) => {
                return <option key={i} value={role.value}>{role.text}</option>
              })}
            </select>
          </td>
          <td className="text-right users-col">
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, this.getLabelText("save"))}
            >
              <button className="btn-icons-container" type="submit">
                {loading && loadType === "create" && selectedId === user.id
                  ? (
                    <Fragment>
                      <img src={Loader} alt="Loader" className="loader-spinner"/>
                    </Fragment>
                  )
                  : (
                    <Fragment>
                      <img src={Save} alt="Save" className="btn-icons"/>
                    </Fragment>
                  )
                }
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, this.getLabelText("cancel"))}
            >
              <Button className="btn-icons-container" variant="danger" onClick={this.cancel}><img src={Cancel} alt="Cancel" className="btn-icons"/></Button>
            </OverlayTrigger>
          </td>
        </tr>
      )
    } else {
      userRow = (
        <tr key={i}>
          <td>
            {user.lastName}
          </td>
          <td>
            {user.firstNames}
          </td>
          <td>
            {user.email}
          </td>
          <td>
            {user.mobileNumber}
          </td>
          <td>
            {roles.find(role => role.value === user.role) ? roles.find(role => role.value === user.role).text : ""}
          </td>
          <td className="text-right users-col">
            {!this.state.archived && this.props.permissions.canEdit && (
              <OverlayTrigger
                placement="top"
                delay={{ show: 25, hide: 40 }}
                overlay={(props) => renderTooltip({...props}, this.getLabelText("edit"))}
              >
                <Button
                  className={"btn-icons-container" + (selectedId || typeof(selectedId) === "number" ? " disabled" : "")}
                  variant="primary"
                  onClick={(e) => this.toggleMode(e, "edit", user.id)}
                >
                  <img src={Edit} alt="Edit" className="btn-icons"/>
                </Button>
              </OverlayTrigger>
            )}
            {this.state.archived && this.props.permissions.canEdit && (
              <OverlayTrigger
                placement="top"
                delay={{ show: 25, hide: 40 }}
                overlay={(props) => renderTooltip({...props}, this.getLabelText("delete"))}
              >
                <Button className={"btn-icons-container" + (selectedId || typeof(selectedId) === "number" ? " disabled" : "")} variant="danger" onClick={() => this.deleteItem(user.id)}>
                  {loading && loadType === "delete" && deleteId === user.id
                    ? (
                      <Fragment>
                        <img src={Loader} alt="Loader" className="loader-spinner"/>
                      </Fragment>
                    )
                    : (
                      <Fragment>
                        <img src={Delete} alt="Delete" className="btn-icons"/>
                      </Fragment>
                    )
                  }
                </Button>
              </OverlayTrigger>
            )}
            {!this.state.archived && this.props.permissions.canEdit && (
              <OverlayTrigger
                placement="top"
                delay={{ show: 25, hide: 40 }}
                overlay={(props) => renderTooltip({...props}, this.getLabelText("reset_password"))}
              >
                <Button
                  className={"btn-icons-container" + (selectedId || typeof(selectedId) === "number" || !user.email || user.email === "" ? " disabled" : "")}
                  variant="danger"
                  onClick={() => this.resetPassword(user.id)}>
                  {loading && loadType === "reset" && deleteId === user.id
                    ? (
                      <Fragment>
                        <img src={Loader} alt="Loader" className="loader-spinner"/>
                      </Fragment>
                    )
                    : (
                      <Fragment>
                        <img src={Reset} alt="Reset" className="btn-icons"/>
                      </Fragment>
                    )
                  }
                </Button>
              </OverlayTrigger>
            )}
            {this.props.permissions.canEdit && (
              <OverlayTrigger
                placement="top"
                delay={{ show: 25, hide: 40 }}
                overlay={(props) => renderTooltip({...props}, (this.state.archived ? this.getLabelText('put_back') : this.getLabelText('archive')))}
              >
                <Button
                  className={"btn-icons-container"  + (selectedId || typeof(selectedId) === "number" || user.hasTeams ? " disabled" : "")}
                  onClick={() => this.archive(user.id, user.archived, user.hasTeams)}
                >
                  {loading && loadType === "archive" && deleteId === user.id
                    ? (
                      <Fragment>
                        <img src={Loader} alt="Loader" className="loader-spinner"/>
                      </Fragment>
                    ) : user.archived ? (
                      <Fragment>
                        <img src={PutBack} alt="PutBack" className="btn-icons"/>
                      </Fragment>
                    ):(
                      <Fragment>
                        <img src={Archive} alt="Archive" className="btn-icons"/>
                      </Fragment>
                    )
                  }
                </Button>
              </OverlayTrigger>
            )}
          </td>
        </tr>
      )
    }

    return userRow
  }

  render() {
    const { users, newUsers, firstIndex, lastIndex, loading, loadType } = this.state
    users.sort(function(a, b){
      if(a.lastName.toLowerCase().trim() < b.lastName.toLowerCase().trim()) { return -1; }
      if(a.lastName.toLowerCase().trim() > b.lastName.toLowerCase().trim()) { return 1; }

      if(a.lastName === b.lastName) {
        if(a.firstNames.toLowerCase().trim() < b.firstNames.toLowerCase().trim()) { return -1; }
        if(a.firstNames.toLowerCase().trim() > b.firstNames.toLowerCase().trim()) { return 1; }
        return 0;
      }
      return 0;
    })

    const jointUsers = [...newUsers, ...users]

    return (
      <Fragment>
        <SubHeaderComponent>
          <Fragment>
            {!this.state.archived && this.props.permissions.canEdit && (
              <Button
                className={"btn btn-primary btn-nrml ml-2" + (this.state.selectedId || typeof(this.state.selectedId) === "number" ? " disabled" : "")}
                onClick={(e) => this.toggleMode(e, "create")}
              >
                {this.getLabelText("add")}<FontAwesomeIcon className="ml-2" icon={faPlus} />
              </Button>
            )}
            {!this.state.archived && this.props.permissions.canEdit && (
              <Fragment>
                <button className="btn btn-primary btn-nrml ml-2" onClick={() => document.getElementById('react-csv-reader-input').click()}>
                  {loading & loadType === "import" ? (
                      <span className="form-label cdi-blue-txt ml-0">{this.getLabelText("importing")}<img src={Loader} alt="Loader" className="loader-spinner"/>...</span>
                    ) : (
                      <span>{this.getLabelText("import")}</span>
                    )}
                </button>
                <CSVReader onFileLoaded={(data, fileInfo) => this.importUsers(data, fileInfo)} />
              </Fragment>
            )}
            <button
              className="btn btn-primary btn-nrml ml-2"
              onClick={() => this.downloadCSV()}
            >
              {loading & loadType === "export" ? (
                <FontAwesomeIcon className="text-white fa-spin" icon={faSpinner} />
              ) : this.getLabelText("export")}
            </button>
          </Fragment>
        </SubHeaderComponent>
        <div className="grey-header-space container-left">
          <Row>
            <Col className="text-left pr-0" xs={3}>
            <input
              className="form-control"
              type="text"
              placeholder="Search user by name or email..."
              name="userSearch"
              onChange={(e) => this.searchUsers(e)}
              autoComplete="off"
            />     
            </Col>
            <Col className="text-right pr-0" xs={9}>
              <ButtonGroup toggle className="ml-1">
                <ToggleButton
                    type="radio"
                    variant="secondary"
                    name="createType"
                    value="0"
                    checked={!this.state.archived}
                    onChange={() => this.toggleArchived()}
                >
                  {this.getLabelText("active")}
                </ToggleButton>
                <ToggleButton
                    type="radio"
                    variant="secondary"
                    name="createType"
                    value="1"
                    checked={this.state.archived}
                    onChange={() => this.toggleArchived()}
                >
                  {this.getLabelText("archived")}
                </ToggleButton>
              </ButtonGroup>
            </Col>
          </Row>
          <Row className="mt-20 justify-content-center">
            <Col xl={12} className="pr-0">
              <form onSubmit={(e) => this.handleSubmit(e)}>
                <Table striped bordered hover className="standard-tbl standard-users-tbl table-heading-background user-text">
                  <thead>
                  <tr>
                    <th width="21%">{this.getLabelText("last_name")}</th>
                    <th width="21%">{this.getLabelText("first_name")}</th>
                    <th width="18%">{this.getLabelText("username_prefer_email")}</th>
                    <th width="12.125%">{this.getLabelText("mobile_number")}</th>
                    <th width="12.125%">{this.getLabelText("user_role")}</th>
                    <th width="10.75%" className="users-col"></th>
                  </tr>
                  </thead>
                  <tbody>
                  {jointUsers.filter(user => !user.archived !== this.state.archived).map((user, i) => {
                    if(i >= firstIndex && i <= lastIndex){
                      return this.userList(user, i)
                    }

                    return null
                  })}
                  </tbody>
                </Table>
                {
                  this.state.totalPages > 0 ?
                  <div className="pagination_container">
                      <PaginationComponent
                          active={this.state.page}
                          disabled={this.state.paginateDisabled}
                          setPage={this.setPage}
                          total={this.state.totalPages}
                      />
                  </div>
                      : null
                }
              </form>
            </Col>
          </Row>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user
});

const mapDispatchToProps = {
  fetchUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserScreen);
