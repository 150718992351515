import toDateAttendanceMap from "./toDateAttendanceMap";

function alphabetizeUsers(array) {
  return array.sort(function(a, b) {
    var textA = a.firstNames.toUpperCase();
    var textB = b.firstNames.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
}

function alphabetizePartners(array) {
  return array.sort(function(a, b) {
    var textA = a.department.name.toUpperCase();
    var textB = b.department.name.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
}

function alphabetizeVisitors(array) {
  return array.sort(function(a, b) {
    var textA = a.name.toUpperCase();
    var textB = b.name.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
}

const mapAPIData = (data, level) => {
  const team = {
    ...data.team,
    attendances: toDateAttendanceMap(data.team.attendances),
  };

  let allObjects = [...alphabetizeUsers(team.leaders)];

  if(level !== "LVL2") {
    allObjects = [...allObjects, ...alphabetizeUsers(team.members)]
  } else {
    const meetingMembers = team.members.filter(member => team.meetingMembers.find(metMem => metMem.id === member.id))
    console.log(meetingMembers)
    allObjects = [...allObjects, ...alphabetizePartners(team.valuePartners), ...alphabetizeUsers(meetingMembers)]
  }

  if (team.coach) {

    if(allObjects.find(user => user.id === team.coach.id)) {
      const index = allObjects.findIndex(user => user.id === team.coach.id)

      allObjects.splice(index, 1)
    }

    allObjects.push({ ...team.coach, isCoach: true });
  }

  allObjects = [...allObjects, ...alphabetizeVisitors(team.visitors)]

  const objects = allObjects.map((obj) => {
    const type = obj.department ? "PARTNER" : obj.firstNames ? "USER" : "VISITOR"
    const name = type === "USER" ? `${obj.firstNames} ${obj.lastName}` : type === "PARTNER" ? `${obj.department.name} (${obj.name})` : obj.name

    const attendances = toDateAttendanceMap(type !== "PARTNER" ? obj.attendances : obj.partnerAttendances);
    const data = {
      name,
      id: obj.id,
      type,
      attendances,
      isCoach: obj.isCoach
    }
    return { ...data, attendances };
  });

  const disabledDays = Object.entries(team.attendances).reduce(
    (all, [day, { status }]) => {
      if (status === "NON_WORKING_DAY") all.add(parseInt(day));
      return all;
    },
    new Set()
  );

  return { team, objects, disabledDays };
};

export default mapAPIData;
