import { client } from "./BaseService";
import { gql } from "apollo-boost";

const getLoggedNextStepActions = async (id, isArchived, isMine = false) => {
  return await client.query({
    query: gql`
      query {
          getNextStepActions(creatorId: "${id}", archived:${isArchived}, isMine: ${isMine})
      }
    `
  })
}

const getLoggedIssues = async (id, isArchived, isMine = false) => {
  return await client.query({
    query: gql`query {
      getNextStepActions(creatorId: "${id}", archived:${isArchived}, isMine: ${isMine} ) {
        id
        whyText
        whatText
        reviewDate
        creator {
          name
        }
        actions {
          id
          status
          escalated
          archived
          escalateArchived
          shared
          feedback
          whenChanged
          actionType
          postponedCount
          when
          description
          who {
            id
            firstNames
            lastName
          }
        }
      }
    }`
  })
}

export const createIssue = async (creatorId, whatText, whyText, date, type = "UNASSIGNED_ISSUE") => {

  return await client.mutate({
    mutation: gql`mutation {
      createIssue(
        creatorId: "${creatorId}",
        whatText: "${whatText}",
        whyText: "${whyText}",
        reviewDate: "${date}",
        type: ${type}
      ) {
        id
      }
    }`
  })
}

const updateActionStatus = async (id, status) => {
  return await client.mutate({
    mutation: gql`mutation {
        updateAction(
          id: "${id}",
          status: ${status}
        ) {
          id
        }
      }`
  })
}

const revertActionStatus = async (id) => {
  return await client.mutate({
    mutation: gql`mutation {
        updateAction(
          id: "${id}",
          status: NONE
        ) {
          id
        }
      }`
  })
}

const escalateAction = async (id, escalated) => {
  return await client.mutate({
    mutation: gql`mutation {
        updateAction(
          id: "${id}",
          escalated: ${escalated}
        ) {
          id
        }
      }`
  })
}

export const createAction = async (issueId, actionType, status, description, whoId, when) => {
  if (when) {
    return await client.mutate({
      mutation: gql`mutation {
          createAction(
            issueId: "${issueId}",
            actionType: ${actionType},
            status: ${status},
            description: "${description}",
            whoId: "${whoId}",
            when: "${when}"
          ) {
            id
          }
        }`
    })
  } else {
    return await client.mutate({
      mutation: gql`mutation {
          createAction(
            issueId: "${issueId}",
            actionType: ${actionType},
            status: ${status},
            description: "${description}",
            whoId: "${whoId}",
          ) {
            id
          }
        }`
    })
  }
}

const UPDATE_ACTION = gql`
  mutation UpdateAction(
    $id: ID!
    $actionType: ActionType
    $status: ActionStatus
    $description: String
    $whoId: String
    $when: String
    $archived: Boolean
    $escalated: Boolean
    $escalateArchived: Boolean
    $feedback: String
    $shared: Boolean
    $whenChanged: Boolean
    $innovationStatus: InnovationStatus
    $innovationApproved: InnovationApprovalStatus
    $suggestedById: String
    $postponedCount: Int
  ) {
    updateAction(
      id: $id
      actionType: $actionType
      status: $status
      description: $description
      whoId: $whoId
      when: $when
      archived: $archived
      escalated: $escalated
      escalateArchived: $escalateArchived
      feedback: $feedback
      shared: $shared
      whenChanged: $whenChanged
      innovationStatus: $innovationStatus
      innovationApproved: $innovationApproved
      suggestedById: $suggestedById
      postponedCount: $postponedCount
    ) {
      id
    }
  }
`;

const updateAction = async (id, description, whoId, when, changed, postponedCount = null) => {
  let options = {
    id: id,
    description: description,
    whoId: whoId,
    whenChanged: changed
  };

  if (when) options = { ...options, when };
  if (postponedCount) options = { ...options, postponedCount };

  return await client.mutate({
    mutation: UPDATE_ACTION,
    variables: options
  });
}

const archiveAction = async (id, archived) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateAction(
        id: "${id}",
        archived: ${archived}
      ) {
        id
      }
    }`
  })
}

const deleteAction = async (id) => {
  return await client.mutate({
    mutation: gql`mutation {
      deleteAction(
        id: "${id}"
      ) {
        id
      }
    }`
  })
}


export {
  getLoggedIssues,
  updateActionStatus,
  escalateAction,
  updateAction,
  archiveAction,
  revertActionStatus,
  deleteAction,
  getLoggedNextStepActions
}
