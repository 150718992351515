import {
  startOfMonth,
  endOfMonth,
  getDaysInMonth,
  getWeekOfMonth,
  addDays,
  subDays
} from "date-fns";

const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday"
];

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const lightBlue = "#4086A4";
export const MetricPeriodStatuses = {
  NORMAL: {
    value: "NORMAL",
    color: "#fff",
    tickLabelColor: "#005e85"
  },
  TRIGGER: {
    value: "TRIGGER",
    color: "#dc3545",
    tickLabelColor: "#dc3545",
  },
  NON_WORKING: {
    value: "NON_WORKING",
    color: "#212529",
    tickLabelColor: "#fff",
  },
};

export const MetricStatuses = {
  RED: {
    value: "RED",
    color: "#dc3545",
  },
  BLUE: {
    value: "BLUE",
    color: localStorage.getItem("colour") === "Green" ? '#28a745' : "#005e85"
  },
};

export const toNextPeriodStatus = (metric) => {
  const { NORMAL, TRIGGER, NON_WORKING } = MetricPeriodStatuses;

  switch (metric.periodStatus) {
    case NORMAL.value:
      return TRIGGER.value;

    case TRIGGER.value:
      return NON_WORKING.value;

    case NON_WORKING.value:
      return NORMAL.value;

    default:
      return TRIGGER.value;
  }
};

export const getDisabledDays = metrics => {
  const disabledDays = Object.entries(metrics).reduce(
    (all, [period, { status, date }]) => {

      if (status === "NON_WORKING") {
        const periodValue = getDateDay(date);
        all.add(parseInt(periodValue));
      }
      return all;
    },
    new Set()
  );

  return disabledDays;
};

export const getFirstDay = date => {
  let firstDay = startOfMonth(date);
  while((firstDay.getMonth() - date.getMonth()) !== 0) {
    firstDay = addDays(firstDay, 1);
  };
  return firstDay;
};

export const getLastDay = date => {
  let lastDay = endOfMonth(date);
  while((lastDay.getMonth() - date.getMonth()) !== 0) {
    lastDay = subDays(lastDay, 1);
  };
  return lastDay;
};

export const getDateDay = date => new Date(date).getDate();

export const isApplicableForWeekly = (day, date) => {
  const dayArr = day.split(",");
  const indexArr = dayArr.map(d => days.indexOf(d));

  const index = new Date(date).getDay();
  return indexArr.includes(index);
};

export const isApplicableForMonthly = (week, day, cellDate, date) => {
  const dayIndex = new Date(cellDate).getDay();
  const weekIndex = getWeekOfMonth(new Date(cellDate), { weekStartsOn: 1 });
  const finalWeek = getWeekOfMonth(getLastDay(new Date(date)), { weekStartsOn: 1 });
  const dayArr = day.split(",");
  const dayIndexArr = dayArr.map(d => days.indexOf(d));
  const weekArr = week.split(",");

  return weekArr.includes((weekIndex > 4 || (weekIndex === 4 && finalWeek === 4) ? "lw" : `wk${weekIndex}`)) && dayIndexArr.includes(dayIndex);
};

export const getDateFromPeriod = (period, date) => new Date(date.getFullYear(), date.getMonth(), period)

export const getDaysInMonthArray = (date) => {
  let days = [];
  var x = getDaysInMonth(date);

  for (let index = 0; index < x; index++) {
    days.push(index + 1);
  }

  return days;
}

export const getMonthTextByIndex = (index) => {
  return months[index];
}
