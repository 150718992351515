import "./App.css";
import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import { PublicRoute } from "./components/AuthGuard.js";
import { Provider } from 'react-redux';
import { store } from './redux/store';
// Containers
import DashboardContainer from "./containers/DashboardContainer";

// Screens
import LoginScreen from "./screens/LoginScreen";
import ConfirmPasswordScreen from "./screens/ConfirmPasswordScreen";
import { logo } from "./services/BaseService.js";

const App = () => {
  useEffect(() => {
    const iconLink = document.getElementById('dynamic-icon');
    iconLink.href = logo === "pepsico" ? '/pepsico-icon.png' : '/cdi-icon-spot.png';
  }, [])

  return (
    <Provider store={store}>
      <Switch>
        <PublicRoute
          exact
          path="/"
          component={LoginScreen}
        />
        <PublicRoute
            exact
            path="/confirm-password"
            component={ConfirmPasswordScreen}
         />
        <DashboardContainer/>
      </Switch>
    </Provider>
  );
}

export default App;
