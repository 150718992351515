import { gql } from "apollo-boost";

// TODO: remove hardcoded value
export const FETCH_IMPLEMENTATION_TRACKER = gql`
  query ImplementationTracker($teamId: ID!) {
    team(id: $teamId) {
      id
      implementationTrackers {
        id
        name
        from
        to
        order
        fromDate
        toDate
        archived
        milestones {
            id
            name
            archived
            from
            to
            order
            fromDate
            toDate
            user {
              id 
              firstNames
              lastName
            }
            issue {
              id
              actions {
                id
                status
                archived
              }
            }
            milestoneStates {
                id
                key
                state
            }
        }
      }
    }
  }
`;

export const GET_START_WEEK = gql`
  query {
    companyStartWeeks {
      id
      period
      week
      date
    }
  }
`

export const CREATE_IMPLEMENTATION_TRACKER_MODULE_MUTATION = gql`
  mutation createImplementationTrackerModule($team: ID! $name: String! $from: Int! $to: Int!, $fromDate: String!, $toDate: String!, $order: Int!, $implementationModule: ID, $milestones: String) {
    createImplementationTrackerModule(team: $team, name: $name, from: $from, to: $to, fromDate: $fromDate, toDate: $toDate, order: $order, implementationModule: $implementationModule, milestones: $milestones) {
      id
      name
    }
  }
`;

export const UPDATE_IMPLEMENTATION_TRACKER_MODULE_MUTATION = gql`
  mutation updateImplementationTrackerModule($id: ID!, $name: String!, $to: Int!, $from: Int!, $fromDate: String!, $toDate: String!, $order: Int!) {
    updateImplementationTrackerModule(id:$id, name: $name, to: $to, from: $from, fromDate: $fromDate, toDate: $toDate, order: $order) {
      id
      name
    }
  }
`;

export const ARCHIVE_IMPLEMENTATION_TRACKER_MODULE_MUTATION = gql`
  mutation updateImplementationTrackerModule($id: ID!, $name: String!, $to: Int!, $from: Int!, $fromDate: String!, $toDate: String!, $order: Int!, $archived: Boolean) {
    updateImplementationTrackerModule(id:$id, name: $name, to: $to, from: $from, fromDate: $fromDate, toDate: $toDate, order: $order, archived: $archived) {
      id
      name
    }
  }
`;

export const DELETE_IMPLEMENTATION_TRACKER_MODULE_MUTATION = gql`
  mutation updateImplementationTrackerModule($id: ID!, $name: String!, $to: Int!, $from: Int!, $fromDate: String!, $toDate: String!, $order: Int!) {
    updateImplementationTrackerModule(id:$id, name: $name, to: $to, from: $from, fromDate: $fromDate, toDate: $toDate, order: $order, deleted: true) {
      id
      name
    }
  }
`;

export const CREATE_IMPLEMENTATION_TRACKER_MILESTONE_MUTATION = gql`
  mutation createImplementationTrackerMilestone($implementationModule: ID! $name: String! $from: Int! $to: Int! $user: ID!, $fromDate: String!, $toDate: String!, $order: Int!) {
    createImplementationTrackerMilestone(implementationModule: $implementationModule name: $name, from: $from, to: $to, user: $user, fromDate: $fromDate, toDate: $toDate, order: $order) {
      id
      name
      from
      to
      fromDate
      toDate
      order
      user {
        id
      }
    }
  }
`;

export const UPDATE_IMPLEMENTATION_TRACKER_MILESTONE_MUTATION = gql`
  mutation updateImplementationTrackerMilestone($id: ID!, $name: String! $from: Int! $to: Int! $user: ID! $fromDate: String! $toDate: String! $order: Int!) {
    updateImplementationTrackerMilestone(id:$id, name: $name, to: $to, from: $from, user: $user, fromDate: $fromDate, toDate: $toDate, order: $order) {
      id
      name
    }
  }
`;

export const DELETE_IMPLEMENTATION_TRACKER_MILESTONE_MUTATION = gql`
  mutation updateImplementationTrackerMilestone($id: ID!, $name: String! $from: Int! $to: Int! $user: ID $fromDate: String! $toDate: String! $order: Int!) {
    updateImplementationTrackerMilestone(id:$id, name: $name, to: $to, from: $from, user: $user, fromDate: $fromDate, toDate: $toDate, order: $order, deleted: true) {
      id
      name
    }
  }
`;

export const ARCHIVE_IMPLEMENTATION_TRACKER_MILESTONE_MUTATION = gql`
  mutation updateImplementationTrackerMilestone($id: ID!, $name: String! $from: Int! $to: Int! $user: ID $fromDate: String! $toDate: String! $order: Int! $archived: Boolean) {
    updateImplementationTrackerMilestone(id:$id, name: $name, to: $to, from: $from, user: $user, fromDate: $fromDate, toDate: $toDate, order: $order, archived: $archived) {
      id
      name
    }
  }
`;

export const UPDATE_MILESTONE_STATE_MUTATION = gql`
  mutation updateImplementationMilestoneState($id: ID!, $state: Int!) {
    updateImplementationMilestoneState(id:$id, state: $state) {
      id
      key
      state
    }
  }
`;

export const CREATE_MILESTONE_STATE_MUTATION = gql`
  mutation createImplementationMilestoneState($milestoneId: ID!, $key: Int!, $state: Int!) {
    createImplementationMilestoneState(milestoneId: $milestoneId, key: $key, state: $state) {
      id
      key
      state
    }
  }
`;

export const CREATE_ISSUE_MUTATION = gql`
  mutation createIssue($creatorId: String, $whatText: String, $reviewDate: String, $type: IssueType, $milestoneId: String, $scheduleId: String, $sharedScheduleId: String, $metricId: String, $measurementId: String, $implementationMilestoneId: ID) {
    createIssue(creatorId: $creatorId, whatText: $whatText, reviewDate: $reviewDate, type: $type, milestoneId: $milestoneId, scheduleId: $scheduleId, sharedScheduleId: $sharedScheduleId, metricId: $metricId, measurementId: $measurementId, implementationMilestoneId: $implementationMilestoneId) {
      id
    }
  }
`

export const CREATE_ACTION_MUTATION = gql`
  mutation createAction($issueId: String!, $actionType: ActionType!, $status: ActionStatus!, $description: String) {
    createAction(issueId: $issueId, actionType: $actionType, status: $status, description: $description) {
      id
    }
  }
`

export const SET_IMPLEMENTATION_TRACKER_MILESTONES_ORDER = gql`
  mutation updateImplementationTrackerMilestoneOrder($values: [ID]!) {
    updateImplementationTrackerMilestoneOrder(values: $values)
  }
`
