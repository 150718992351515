import * as realtimeRefreshTypes from '../types/RealtimeRefreshTypes';

const initialState = {
  refresh: false
};

const realtimeRefreshReducer = (state = initialState, action) => {
  switch (action.type) {
    case realtimeRefreshTypes.REFRESH_ENABLED:
      return { refresh: true };
    case realtimeRefreshTypes.REFRESH_DISABLED:
      return { refresh: false };
    default:
      return state;
  }
};

export default realtimeRefreshReducer;
