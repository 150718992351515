import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import {
  faEdit,
  faCheck,
  faBan,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDepartmentsForPartners } from "../../services/DepartmentService";
import {
  updatePartners,
  createDepartment
} from "../../services/TeamPartnerService";
import displayErrorToast from "../../components/utils/displayErrorToast";
import SubHeaderComponent from "../../components/SubHeaderComponent";
import { connect } from 'react-redux';
import getArrayIds from '../../components/utils/getArrayIds'
import PartnerRow from "./PartnerRow";
import { fetchTeams } from "../../redux/services/TeamService"
import LabelComponent from "../../components/utils/getCompanyLabel";
import { canEditHere } from "../../utils/checkPermissions";

class TeamPartnersScreen extends Component {
  state = {
    loading: false,
    loadType: "",
    edit: false,
    modified: false,
    partners: [],
    customers: [],
    teams: [],
    allTeams: [],
    team: { partners: [] },
    id: "",
    error: "",
    creatableLoading: false,
    creatableType: ""
  }

  constructor(props) {
    super(props)

    this.props.toggleDirtyState(true)
    this.props.togglePageLoad(true)
  }

  async componentDidMount() {
    if(this.props.selectedTeam.id !== 0) {
      // if(this.props.selectedTeam.department.departmentType.level === "LVL2") {
      //   this.props.history.push("/team/value-stream-partners")
      //   return
      // }
      //
      // if(this.props.selectedTeam.department.departmentType.level !== "LVL1") {
      //   this.props.history.push("/dashboard/goals")
      //   return
      // }
      await this.fetchTeams();
      this.props.togglePageLoad(false)
    }
  }

  async componentDidUpdate(prevProps)  {
    if(prevProps.selectedTeam.id !== this.props.selectedTeam.id) {
      // if(this.props.selectedTeam.department.departmentType.level === "LVL2") {
      //   this.props.history.push("/team/value-stream-partners")
      //   return
      // }
      //
      // if(this.props.selectedTeam.department.departmentType.level !== "LVL1") {
      //   this.props.history.push("/dashboard/goals")
      //   return
      // }
      await this.fetchTeams();
      this.props.togglePageLoad(false)
    }
  }

  getLabelText = key => <LabelComponent val={key}/>

  deleteItem = (id, name) => {
    if (window.confirm("Are you sure you wish to delete this item?")) {
      const { team } = this.state
      const array = team[name];

      const index = array.indexOf(id);
      if (index > -1) {
        array.splice(index, 1);
      }

      this.setState({
        team: {
          ...team,
          [name]: array
        },
        modified: true,
      });
    }
  };

  setTeamPartners = (arr, name) => {
    const { modified } = this.state;
    this.setState({
      teams: arr,
    })

    if(!modified) this.handleEnable(false);
  }
  
  flattenDepartments = (array) => {
    let departments = [];
    array.some(function iter(a) {
      departments.push(a)
      return Array.isArray(a.children) && a.children.some(iter);
    });
    return departments.filter(department => department.type === "INTERNAL" || (department.type === "EXTERNAL" && department.creator && department.creator.id === this.props.selectedTeam.id));
  }
  
  fetchTeams = async () => {
    const response = await getDepartmentsForPartners(this.props.selectedTeam.id);
    
    this.setState({
      teams: [...response.data.departmentsForPartners.all, ...response.data.departmentsForPartners.internal],
      allTeams: response.data.departmentsForPartners.internal
    });
  };


  getDisplayName = (id, data) => {
    const item = data.filter((option) => {
      return option.id === id;
    });
    let label = ""

    if(item.length > 0 && item[0].department) {
      label = item[0].department.name + " (" + item[0].name +")"
    } else if(item.length > 0) {
      label = item[0].name
    } else {
      label = this.getLabelText("no_data")
    }

    return label
  };

  handleArray = (name, value) => {
    const data = this.state.team[name];

    data.push(value);
    this.setState({
      [name]: data,
      error: null,
      modified: true,
    });
  };

  handleCreate = async (name, value) => {
    this.setCreatableSelectLoading(true, name)
    const { id } = this.state.team.department.departmentType
    const response = await createDepartment(value, id, this.props.selectedTeam.id)

    await this.fetchTeams()

    const data = this.state.team[name];

    data.push(response.data.createDepartment.id);

    this.setState({
      [name]: data,
      error: null,
      modified: true,
    });

    this.setCreatableSelectLoading(false)
  }

  setCreatableSelectLoading = (loading, type = "") => {
    this.setState({
      creatableLoading: loading,
      creatableType: type
    })
  }

  updateDepartmentTeams = async () => {
    const selectedTeam = this.state.team
    return Promise.all(this.props.teams.filter(team => team.department.id === selectedTeam.department.id && team.id !== selectedTeam.id ).map(async(team) => {
      await updatePartners(
        team.id,
        selectedTeam.customers,
        selectedTeam.partners,
        selectedTeam.department.id,
      );
    }))
  }

  handleSubmit = async () => {
    this.setState({
      loading: true,
      loadType: "edit",
    });
    try {
      const {
        team
      } = this.state;

      if(team.department.teamLength > 1) {
        await this.updateDepartmentTeams()
      }
      await updatePartners(
        team.id,
        team.customers,
        team.partners,
        team.department.id,
      );
      await this.fetchTeams();
      await this.props.fetchTeams()
      this.props.toggleDirtyState(true);
    } catch (error) {
      displayErrorToast(error);
    }

    this.setState({
      loading: false,
      loadType: "",
      edit: false,
      modified: false,
    });
  };

  handleEnable = (enable) => {
    const { selectedTeam } = this.props
    this.props.toggleDirtyState()
    let options = {
      edit: enable,
    };

    if(enable) {
      options = {
        ...options,
        team: {
          ...selectedTeam,
          customers: selectedTeam.customers.length > 0 ? getArrayIds(selectedTeam.customers) : [],
          partners: selectedTeam.partners.length > 0 ? getArrayIds(selectedTeam.partners) : [],
        }
      }
    } else {
      options = {
        ...options,
        team: []
      }
    }
    this.setState(options);
  };

  canEdit = () => {
    return canEditHere(this.props.user, this.props.teams, this.props.selectedTeam);
  }

  render() {
    const { edit, loading, loadType, creatableLoading, creatableType } = this.state;
    let team
    if(edit) {
      team = this.state.team
    } else {
      team = this.props.selectedTeam
    }
    const multiSelectProps = {
      handleArray: this.handleArray,
      deleteItem: this.deleteItem,
      handleCreate: this.handleCreate,
      getDisplayName: this.getDisplayName,
      edit,
      team,
      creatableLoading,
      creatableType
    };


    if(!this.props.selectedTeam) return <>Select a team</>

    return (
      <Fragment>
        <SubHeaderComponent>
          {this.canEdit() ? (
            <Row className="">
              <Col className="text-right">
                {edit ? (
                  <div className="active-edit">
                    <button
                      className="btn btn-primary btn-nrml"
                      disabled={!this.state.modified}
                      onClick={this.handleSubmit}
                    >
                      {loading && loadType === "edit" ? (
                        <Fragment>
                          <span>{this.getLabelText('saving')}...</span>
                          <FontAwesomeIcon
                            className="ml-2 fa-spin"
                            icon={faSpinner}
                          />
                        </Fragment>
                      ) : (
                        <Fragment>
                          <span>{this.getLabelText('save')}</span>
                          <FontAwesomeIcon className="ml-2" icon={faCheck} />
                        </Fragment>
                      )}
                    </button>
                    <button
                      className="btn btn-danger ml-2 btn-nrml"
                      onClick={() => this.handleEnable(false)}
                    >
                      {this.getLabelText('cancel')}
                      <FontAwesomeIcon className="ml-2" icon={faBan} />
                    </button>
                  </div>
                  ) : (
                    <button
                        disabled={loading}
                        className="btn btn-primary btn-nrml"
                        onClick={() => this.handleEnable(true)}
                      >
                        {this.getLabelText('edit')}
                        <FontAwesomeIcon className="ml-2" icon={faEdit} />
                    </button>
                  )
                }
              </Col>
            </Row>
          ) : null}
        </SubHeaderComponent>
        <div className="grey-header-space container-left">
          <PartnerRow
              title={this.getLabelText("our_customers")}
              selectPlaceholder={`${this.getLabelText('our_customers')}`}
              name="customers"
              data={team.customers}
              array={this.state.teams}
              fullArr={this.state.allTeams}
              isEdit={edit}
              multiSelectProps={multiSelectProps}
              getDisplayName={this.getDisplayName}
              fetchReduxTeams={this.props.fetchTeams}
              setTeamPartners={this.setTeamPartners}
          />
          <PartnerRow
              title={this.getLabelText("our_suppliers")}
              selectPlaceholder={`${this.getLabelText('our_suppliers')}`}
              name="partners"
              data={team.partners}
              array={this.state.teams}
              fullArr={this.state.allTeams}
              isEdit={edit}
              multiSelectProps={multiSelectProps}
              getDisplayName={this.getDisplayName}
              fetchReduxTeams={this.props.fetchTeams}
              setTeamPartners={this.setTeamPartners}
          />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam,
  teams: state.teamsReducer.teams,
  user: state.userReducer.user
});

const mapDispatchToProps = {
  fetchTeams
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamPartnersScreen);
