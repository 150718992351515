import React, { useEffect, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import Carousel from "./components/Carousel";
import { connect } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faCamera } from "@fortawesome/free-solid-svg-icons";
import SubHeaderComponent from "../../components/SubHeaderComponent";
import LabelComponent from "../../components/utils/getCompanyLabel";

const getLabelText = key => <LabelComponent val={key}/>

const WelcomeScreen = ({ selectedTeam, togglePageLoad }) => {

  useEffect(() => {
    togglePageLoad(true);
    if(selectedTeam.id ===0) return;
    togglePageLoad(false);
  }, [togglePageLoad, selectedTeam])

  return (
    <Fragment>
        <SubHeaderComponent />
        <div className="grey-header-space container-left">
          <div>
            {selectedTeam.id !== 0 ? (
                <Row>
                  <Col md={6} xs={12} className="pl-3">
                    <table>
                      <tbody>
                      <tr>
                        <td>
                          <label className="cdi-txt-lbl d-inline">{getLabelText("team_name")}</label>
                        </td>
                        <td>
                          <p autoComplete="off" className="cdi-blue-txt d-inline" style={{ color: "black" }}>{selectedTeam.name}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label className="cdi-txt-lbl d-inline">{getLabelText("vision")}</label>
                        </td>
                        <td>
                          <p autoComplete="off" className="cdi-blue-txt d-inline" style={{ color: "black" }}>{selectedTeam.company.vision}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label className="cdi-txt-lbl d-inline">{getLabelText("our_purpose")}</label>
                        </td>
                        <td>
                          <p autoComplete="off" className="cdi-blue-txt d-inline" style={{ color: "black" }}>{selectedTeam.purpose}</p>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </Col>
                  <Col md={6} xs={12} className="pr-3 text-left">
                    {selectedTeam.logo && selectedTeam.logo.length ? (
                        <img src={selectedTeam.logo} style={{ maxWidth: "400px" }} alt="Team Logo"/>
                    ) : (
                        <FontAwesomeIcon icon={faImage} className="ml-2 fa-4x" style={{ color: "darkgrey" }}></FontAwesomeIcon>
                    )}
                  </Col>
                </Row>
            ) : null}
            <Row className="mt-5">
              <Col></Col>
              <Col xs={10}>
                <Carousel show={5}>
                  {selectedTeam.combinedMembers.map((member, i) => {
                    return (
                        <div style={{ padding: 8 }}>
                          <table className="w-100 h-100">
                            <tbody>
                            <tr>
                              <td className="text-center">
                                {member.avatar && member.avatar !== "" ? (
                                    <img src={member.avatar} alt="placeholder" style={{ maxWidth: "150px", maxHeight: "150px" }}/>
                                ) : (
                                    <FontAwesomeIcon icon={faCamera} className="ml-2 fa-4x" style={{ color: "darkgrey" }}></FontAwesomeIcon>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-center" valign="bottom">
                                <label><b>{member.firstNames}</b></label>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                    )
                  })}
                </Carousel>
              </Col>
              <Col></Col>
            </Row>
          </div>
        </div>
    </Fragment>

  )
}

const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam
})

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeScreen)
