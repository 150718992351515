import React, { useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import LabelComponent from "../../components/utils/getCompanyLabel";
// icons
import Cancel from '../../assets/ButtonIcons/Cancel Blue.svg';
import Loader from '../../assets/ButtonIcons/Loader Blue.svg';
import Save from '../../assets/ButtonIcons/Save Blue.svg';

function renderTooltip(props, text) {
  return (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );
}

const EditRow = ({ name: initialName = "", id = "", level: initialLevel = "DEP", onSave, onCancel, levelTypes }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(initialName);
  const [level, setLevel] = useState(initialLevel)

  const getLabelText = key => <LabelComponent val={key}/>

  const isValid = !isLoading && name.trim().length;

  const _onSave = () => {
    if (!isValid) return;
    setIsLoading(true);
    onSave({ name, id, level });
  };

  return (
    <tr>
      <td>
        <input
          autoFocus
          className="form-control"
          type="text"
          required
          placeholder="Name"
          value={name}
          name="name"
          tabIndex="1"
          disabled={isLoading}
          onChange={(e) => setName(e.target.value)}
          autoComplete="off"
        />
      </td>
      <td>
        <select
          autoFocus
          className="form-control w-100"
          required
          name="level"
          tabIndex="2"
          disabled={isLoading}
          onChange={(e) => setLevel(e.target.value)}
          value={level}
          >
          {levelTypes.map((type, i) => {
            return <option value={type.value} key={i}>{type.text}</option>
          })}
        </select>
      </td>
      <td className="text-right">
        <OverlayTrigger
          placement="top"
          delay={{ show: 25, hide: 40 }}
          overlay={(props) => renderTooltip({...props}, getLabelText('save'))}
        >
          <Button className="btn-icons-container" disabled={!isValid} variant="primary" onClick={_onSave}>
            <>
            {isLoading
              ? (
                <img src={Loader} alt="Loader" className="loader-spinner"/>
              ) : (
                <img src={Save} alt="Save" className="btn-icons"/>
              )
            }
              />
            </>
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          delay={{ show: 25, hide: 40 }}
          overlay={(props) => renderTooltip({...props}, getLabelText('cancel'))}
        >
          <Button
            disabled={isLoading}
            variant="danger"
            className="btn-icons-container"
            onClick={onCancel}
            >
            <img src={Cancel} alt="Cancel" className="btn-icons"/>
          </Button>
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default EditRow;
