import React from "react";
import { Row, Col, ButtonGroup, ToggleButton } from "react-bootstrap";


const ArchiveToggle = ({ archive, setArchive, getLabelText }) => (
  <Row className="my-2">
    <Col className="text-right pr-0">
      <ButtonGroup toggle>
        <ToggleButton
          type="radio"
          variant="secondary"
          name="createType"
          value="0"
          checked={!archive}
          onChange={() => setArchive(false)}
        >
          {getLabelText("active")}
        </ToggleButton>
        <ToggleButton
          type="radio"
          variant="secondary"
          name="createType"
          value="1"
          checked={archive}
          onChange={() => setArchive(true)}
        >
          {getLabelText("archived")}
        </ToggleButton>
      </ButtonGroup>
    </Col>
  </Row>
);

export default ArchiveToggle;
