import React, { Fragment, useState, useEffect } from "react";
import { Col, FormGroup } from "react-bootstrap";
import CreatableSelect from 'react-select/creatable';
import PartnerField from './PartnerField';
import {
  updatePartnerName
} from "../../services/TeamPartnerService";
import LabelComponent from "../../components/utils/getCompanyLabel";

function buildOptions (array, team, data) {
  return array.filter((option) => !data.includes(option.id) && option.id !== team.department.id).map(({ id, name }) => ({ value: id, label: name }))
}

const MultiSelect = ({
  handleArray,
  deleteItem,
  getDisplayName,
  edit,
  team,
  name,
  data,
  placeholder,
  handleCreate,
  array,
  creatableLoading,
  creatableType,
  fetchReduxTeams,
  setTeamPartners,
  fullArr
}) => {
  const [editable, setEditable] = useState(false)
  const [editId, setEditId] = useState(null)
  const [options, setOptions] = useState(buildOptions(array, team, data))

  const getLabelText = key => <LabelComponent val={key}/>

  useEffect(() => {
    setOptions(buildOptions(array, team, data))
  }, [array, team, data, setOptions])

  const setEdit = id => {
    setEditId(id)
    setEditable(true)
  }

  const cancel = () => {
    setEditId(null)
    setEditable(false)
  }

  const save = async (id, partner) => {
    await updatePartnerName(id, partner);
    await fetchReduxTeams();
    const arr = array.map(dep => dep.id === id ? {...dep, name: partner} : dep);
    setTeamPartners(arr, name);
    cancel();
  }

  if (edit) {
    return (
      <Fragment>
        {data && data.length > 0
          ? data.map((item, i) => (
              <FormGroup className="row" key={i}>
                <Col>
                  <table className="w-100">
                    <tbody>
                      <PartnerField
                        editId={editId}
                        editable={editable}
                        item={item}
                        array={array}
                        setEdit={setEdit}
                        cancel={cancel}
                        save={save}
                        deleteItem={deleteItem}
                        type={name}
                        fullArr={fullArr}
                        />
                    </tbody>
                  </table>
                </Col>
              </FormGroup>
            ))
          : null}
          <FormGroup className="row">
            <Col>
              <CreatableSelect
                className={"font-weight-normal" + (creatableLoading ? " disabled" : "")}
                name={name}
                onChange={({value}) => handleArray(name, value)}
                options={options}
                onCreateOption={async(value) => await handleCreate(name, value)}
                isLoading={creatableLoading && creatableType === name}
                value="Select..."
              />
            <small>{getLabelText("message_create_external_team_partners")}</small>
            </Col>
          </FormGroup>
      </Fragment>
    );
  }

  return data && data.length > 0 ? (
    data.map((item, i) => (
      <p className="cdi-blue-txt team-identity-text" key={i}>
        {item ? item.name : (`${getLabelText("no_data")}`)}
      </p>
    ))
  ) : (
    <p className="cdi-blue-txt team-identity-text">{getLabelText("no_data")}</p>
  );
};

export default MultiSelect;
