import { gql } from "@apollo/client";
import { client } from "../../../services/BaseService";

export const MODULES_QUERY = gql`
  query {
    reviewModules {
      id
      name
      orderByString
      subjects {
        id
        name
      }
    }
  }
`;

export const getTeamCoachingReviews = async (teamIds, periodKey, periodValue, moduleId) => {
  return await client.query({
    query:gql`query fetchChildrenCoachingReviews(
      $teamIds: [String!], 
      $periodKey: String!,
      $periodValue: String!,
      $moduleId: String!
    ){
      fetchChildrenCoachingReviews(
        teamIds: $teamIds, 
        periodKey: $periodKey,
        periodValue: $periodValue,
        moduleId: $moduleId) {
        subject {
          id
          name
        }
        team {
          id
        }
        id
        periodKey
        periodValue
        value
      }}
`,
variables: {
  teamIds,
  periodKey,
  periodValue,
  moduleId
}},
)};

export const fetchTeamsQuery = async (teamIds) => {
  return await client.query({
    query:gql`query fetchTeams(
      $teamIds: [String!]
    ){
      fetchTeams(teamIds: $teamIds) {
        id
        modules{
          id
          name
          subjects{
            id
            name
          }
          
        }
      }}
`,
variables: {
  teamIds
}},
)};


export const TEAM_MODULES_QUERY = gql`
  query TeamsWithModules($id: ID!, $periodKey: String!) {
    team(id: $id) {
      id
      modules {
        id
        name
        periodMetrics(team: $id, periodKey: $periodKey) {
          id
          periodStatus
          periodKey
          periodValue
        }
        subjects {
          id
          name
          metrics(team: $id, periodKey: $periodKey) {
            id
            value
            periodKey
            periodValue
          }
        }
      }
    }
  }
`;

export const LINK_MODULE_TEAM_MUTATION = gql`
  mutation LinkModuleTeam(
    $id: ID!
    $modules: [String]!
    $periodKey: String!
  ) {
    linkModuleTeam(id: $id, modules: $modules) {
      modules {
        id
        name
        periodMetrics(team: $id, periodKey: $periodKey) {
          id
          periodStatus
          periodKey
          periodValue
        }
        subjects {
          id
          name
          metrics(team: $id, periodKey: $periodKey) {
            id
            value
            periodKey
            periodValue
          }
        }
      }
    }
  }
`;

export const CREATE_PERIOD_MUTATION = gql`
  mutation CreatePeriod(
    $module: String!
    $periodStatus: MetricPeriodStatus!
    $team: String!
    $periodValue: String!
    $periodKey: String!
  ) {
    createCoachingReviewPeriodMetric(module: $module, periodStatus: $periodStatus, team: $team, periodValue: $periodValue, periodKey: $periodKey) {
      id
      periodStatus
      periodValue
      periodKey
    }
  }
`;

export const UPDATE_PERIOD_MUTATION = gql`
  mutation UpdatePeriod($id: ID!, $periodStatus: MetricPeriodStatus!) {
    updateCoachingReviewPeriodMetric(id: $id, periodStatus: $periodStatus) {
      id
      periodStatus
    }
  }
`;

export const CREATE_METRIC_MUTATION = gql`
  mutation CreateMetric(
    $subject: String!
    $team: String!
    $value: Int!
    $periodValue: String!
    $periodKey: String!
  ) {
    createCoachingReviewMetric(subject: $subject, value: $value, team: $team, periodValue: $periodValue, periodKey: $periodKey) {
      id
      value
      periodValue
      periodKey
    }
  }
`;

export const UPDATE_METRIC_MUTATION = gql`
  mutation UpdateMetric($id: ID!, $value: Int!) {
    updateCoachingReviewMetric(id: $id, value: $value) {
      id
      value
    }
  }
`;
