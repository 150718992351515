import React, { Component, Fragment } from 'react'
import { Table, Tooltip, OverlayTrigger, Row, Col, ButtonGroup, ToggleButton, Button, Form } from 'react-bootstrap'
import { connect } from 'react-redux'
import { getCompanyValues } from '../services/CompanyValueService'
import { getBehaviours, getTeamBehaviours, createBehaviour, updateBehaviour, deleteBehaviour } from '../services/BehaviourService'
import { getSelectedValueLog, createSelectedValueLog, updateSelectedValueLog } from '../services/SelectedValueLogService'
import { getConsequences,getTeamConsequences, createConsequence, updateConsequence, deleteConsequence } from '../services/ConsequenceService'
import { getRecognitions,getTeamRecognitions, createRecognition, updateRecognition, deleteRecognition, updateArchived } from '../services/RecognitionService'
import displayErrorToast from "../components/utils/displayErrorToast";
import { toast } from "react-toastify";
import { faSpinner, faPlus, faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker"
import Modal from 'react-modal';
import "react-datepicker/dist/react-datepicker.css"
import SubHeaderComponent from "../components/SubHeaderComponent";
import RealtimeRefreshComponent from "../components/RealtimeRefreshComponent";
import LabelComponent from "../components/utils/getCompanyLabel";
import * as DefaultLabels from '../assets/glossary.json';
// icons
import Archive from '../assets/ButtonIcons/Archive Blue.svg';
import Cancel from '../assets/ButtonIcons/Cancel Blue.svg';
import Delete from '../assets/ButtonIcons/Delete Blue.svg';
import Edit from '../assets/ButtonIcons/Edit Blue.svg';
import Loader from '../assets/ButtonIcons/Loader Blue.svg';
import PutBack from '../assets/ButtonIcons/Put Back Blue.svg';
import Save from '../assets/ButtonIcons/Save Blue.svg';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';

const labels = DefaultLabels.default;

const getLabelText = key => <LabelComponent val={key}/>

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '700px'
  }
};

function renderTooltip(props, text) {
  return (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );
}

export class ValuesBehavioursScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedTeam: [],
      values: [],
      behaviours: [],
      consequences: [],
      recognitions: [],
      members: [],
      selectedValueId: "",
      behaviour: [],
      consequence: [],
      recognition: [],
      loading: false,
      loadingType: "",
      loadingId: "",
      editable: false,
      editType: "",
      type: "",
      loadingBehaviours: true,
      loadingConsequence: true,
      loadingRecognitions: true,
      valueLogId: 0,
      loadingValue: false,
      sortBy: "",
      orderBy: "",
      actions: [],
      archived: false,
      deleteId: "",
      issueDate: "",
      showModal: false,
      modalTitle: "Select Export Options",
      agreedBehaviours: true,
      agreedConsequences : false,
      observationRecognition : false,
      showDateFilters: false,
      filterFromDate: new Date(),
      filterToDate: new Date(),
      exportCompleted: true,
    }

    this.props.togglePageLoad(true)
  }

  async componentDidMount() {
    await this.fetchCompanyValues()
    if (this.props.user.id) {
      await this.fetchSelectedValue()
    }

    if (this.props.selectedTeam.id) {
      await this.fetchBehaviours()
      await this.fetchConsequences()
      await this.fetchRecognitions()

      let users = [...this.props.selectedTeam.members, ...this.props.selectedTeam.leaders]

      this.setState({
        members: users.sort((a, b) => a.firstNames.localeCompare(b.firstNames)),
        loadingBehaviours: false,
        loadingConsequence: false,
        loadingRecognitions: false
      })
      this.props.togglePageLoad(false)
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.user.id !== this.props.user.id) {
      this.fetchSelectedValue()
    }

    if (prevProps.refresh !== this.props.refresh && this.props.refresh) {
      const {sortBy} = this.state
      this.props.togglePageLoad(true)
      this.setState({
        loadingBehaviours: true,
        loadingConsequence: true,
        loadingRecognitions: true
      })
      await this.fetchBehaviours()
      await this.fetchConsequences()
      await this.fetchRecognitions()

      let users = [...this.props.selectedTeam.members, ...this.props.selectedTeam.leaders]

      this.setState({
        members: users.sort((a, b) => a.firstNames.localeCompare(b.firstNames)),
        loadingBehaviours: false,
        loadingConsequence: false,
        loadingRecognitions: false
      })

      this.props.toggleRefresh()
      this.props.toggleHasNewData()
      this.props.togglePageLoad(false)
      this.handleSort(sortBy)
    }

    if (prevProps.selectedTeam.id !== this.props.selectedTeam.id) {
      this.props.togglePageLoad(true)
      this.setState({
        loadingBehaviours: true,
        loadingConsequence: true,
        loadingRecognitions: true
      })
      await this.fetchBehaviours()
      await this.fetchConsequences()
      await this.fetchRecognitions()

      let users = [...this.props.selectedTeam.members, ...this.props.selectedTeam.leaders]

      this.setState({
        members: users.sort((a, b) => a.firstNames.localeCompare(b.firstNames)),
        loadingBehaviours: false,
        loadingConsequence: false,
        loadingRecognitions: false
      })
      this.props.togglePageLoad(false)
    }
  }

  fetchCompanyValues = async () => {
    const response = await getCompanyValues()

    this.setState({
      values: response.data.companyValues.sort((a, b) => a.order - b.order)
    })
  }

  fetchSelectedValue = async () => {
    const {values} = this.state

    const response = await getSelectedValueLog(this.props.user.id)
    this.props.setAdditionalParam(response.data.selectedValueLog ? response.data.selectedValueLog.value.id : values[0].id)
    this.setState({
      selectedValueId: response.data.selectedValueLog ? response.data.selectedValueLog.value.id : values[0].id,
      valueLogId: response.data.selectedValueLog ? response.data.selectedValueLog.id : 0
    })
  }

  fetchBehaviours = async () => {
    const {selectedValueId} = this.state

    const response = await getBehaviours(this.props.selectedTeam.id, selectedValueId)

    this.setState({
      behaviours: response.data.behaviours.sort((a, b) => a.description.localeCompare(b.description))
    })
  }

  fetchConsequences = async () => {
    const {selectedValueId} = this.state

    const response = await getConsequences(this.props.selectedTeam.id, selectedValueId)

    this.setState({
      consequences: response.data.consequences.sort((a, b) => a.description.localeCompare(b.description))
    })
  }

  fetchRecognitions = async () => {
    const {selectedValueId} = this.state
    const response = await getRecognitions(this.props.selectedTeam.id, selectedValueId)

    this.setState({
      recognitions: response.data.recognitions
    })
  }

  getSelectedValueDefinitionText = () => {
    const {values, selectedValueId} = this.state

    let valueText = ""

    values.map((value) => {
      if (value.id === selectedValueId) {
        valueText = value.definition
      }

      return true
    })

    return valueText
  }

  setSelectedValue = (e) => {
    this.setState({selectedValueId: e.target.value}, async () => {
      const {valueLogId, selectedValueId} = this.state
      this.setState({
        loadingBehaviours: true,
        loadingConsequences: true,
        loadingRecognitions: true,
        loadingValue: true
      })
      valueLogId !== 0 ? await updateSelectedValueLog(valueLogId, selectedValueId) : await createSelectedValueLog(this.props.user.id, selectedValueId)
      this.setState({
        loadingValue: false
      })
      await this.fetchBehaviours()
      await this.fetchConsequences()
      await this.fetchRecognitions()
      this.props.setAdditionalParam(selectedValueId)
      this.setState({
        loadingBehaviours: false,
        loadingConsequences: false,
        loadingRecognitions: false
      })
    })
  }

  toggleArchived() {
    this.setState({
      archived: !this.state.archived
    })
  }

  archive = async (id, archived, hasRecognitions = false) => {
    if (!hasRecognitions) {
      this.setState({
        loading: true,
        loadingType: "archive",
        deleteId: id
      })
      try {
        await updateArchived(id, !archived)
        await this.fetchRecognitions()
        this.setState({
          loading: false,
          loadingType: "",
          deleteId: null
        })
      } catch (err) {
        displayErrorToast(err)
        this.setState({
          loading: false,
          loadingType: "",
          deleteId: null
        })
      }
    } else {
      toast.error(getLabelText("archive_recognition_error"))
    }
  }

  renderValueSelectionRow = () => {
    const { values, loadingValue, selectedValueId } = this.state
    return (
      <Row className="grey-header-space">
        <Col xl={3} lg={3} md={4} sm={6} xs={6}>
          <select className="form-control" onChange={(e) => this.setSelectedValue(e)} value={selectedValueId}>
            {values.map((value, i) => {
              return <option value={value.id} key={i}>{value.value}</option>
            })}
          </select>
        </Col>
        <Col xl={9} lg={9} md={8} sm={6} xs={6}>
          {loadingValue
            ? <img src={Loader} alt="Loader" className="loader-spinner"/>
            : <h5><b>{this.getSelectedValueDefinitionText()}</b></h5>
          }
        </Col>
      </Row>
    )
  }

  setLoading(id, type) {
    this.setState({
      loading: true,
      loadingId: id,
      loadingType: type,
      deleteId: id,
    })
  }

  cancelLoading() {
    this.setState({
      loading: false,
      loadingId: "",
      loadingType: ""
    })
  }

  createObj = async() => {
    const { type, selectedValueId } = this.state

    let message = ""
    if(type ==="behaviour") {
      const { behaviour } = this.state
      this.setLoading(behaviour.id, "save")
      await createBehaviour(this.props.selectedTeam.id, selectedValueId, behaviour.description)
      message = "Behaviour created!"
    } else if (type ==="consequence") {
      const { consequence } = this.state
      this.setLoading(consequence.id, "save")
      await createConsequence(this.props.selectedTeam.id, selectedValueId, consequence.description)
      message = "Consequence created!"
    } else {
      const { recognition } = this.state
      this.setLoading(recognition.id, "save")
      await createRecognition(this.props.selectedTeam.id, selectedValueId, recognition.date.toISOString(), recognition.observation, recognition.whoId, recognition.nominatorId)
      message = "Recognition created!"
    }

    return message
  }

  updateObj = async() => {
    const { type } = this.state
    let message = ""
    if(type ==="behaviour") {
      const { behaviour } = this.state
      this.setLoading(behaviour.id, "save")
      await updateBehaviour(behaviour.id, behaviour.description)
      message = "Behaviour updated!"
    } else if(type ==="consequence") {
      const { consequence } = this.state
      this.setLoading(consequence.id, "save")
      await updateConsequence(consequence.id, consequence.description)
      message = "Consequence updated!"
    } else {
      const { recognition } = this.state
      this.setLoading(recognition.id, "save")
      await updateRecognition(recognition.id, recognition.date.toISOString(), recognition.observation, recognition.whoId, recognition.nominatorId)
      message = "Recognition updated!"
    }

    return message
  }

  async save() {
    const { editType, type } = this.state

    try {
      if(editType === "create") {
        await this.createObj()

      } else {
        await this.updateObj()
      }
      type === "behaviour" ? await this.fetchBehaviours() : type === "consequence" ?  await this.fetchConsequences() : await this.fetchRecognitions()
      this.setState({
        editable: false,
        editType: "",
        type: "",
        behaviour: {},
        recognition: {},
        consequence: {}
      },() => {
        this.cancelLoading()
      })
    } catch (err) {
      displayErrorToast(err)
      this.cancelLoading()
    }
  }

  async delete(type, id) {
    if(window.confirm(this.getText("delete_department_prompt"))) {
      this.setLoading(id, "delete")
      try {
        type === "behaviour" ? await deleteBehaviour(id) : type === "consequence" ? await deleteConsequence(id) : await deleteRecognition(id)
        type === "behaviour" ? await this.fetchBehaviours() : type === "consequence" ? await this.fetchConsequences() : await this.fetchRecognitions()

        this.cancelLoading()
      } catch (err) {
        this.cancelLoading()
        displayErrorToast(err)
      }
    }
  }

  addBehaviour = (behaviour) => {
    const { editType } = this.state
    let options = {
      behaviour: {
        id: behaviour.id,
        description: behaviour.description
      }
    }

    if(editType === "create") {
      const { behaviours } = this.state

      behaviours.unshift(behaviour)

      options = {
        ...options,
        behaviours
      }
    }
    this.setState(options)
  }

  addConsequence = (consequence) => {
    const { editType } = this.state
    let options = {
      consequence: {
        id: consequence.id,
        description: consequence.description,
      }
    }

    if(editType === "create") {
      const { consequences } = this.state

      consequences.unshift(consequence)

      options = {
        ...options,
        consequences
      }
    }
    this.setState(options)
  }

  addRecognition = (recognition) => {
    const { editType } = this.state
    let options = {
      recognition: {
        id: recognition.id,
        date: recognition.date,
        observation: recognition.observation,
        whoId: recognition.whoId,
        nominatorId: recognition.nominatorId,
        archived: recognition.archived
      }
    }

    if(editType === "create") {
      const { recognitions } = this.state

      recognitions.unshift({
        id: recognition.id,
        date: recognition.date,
        observation: recognition.observation,
        who: null,
        nominatorId: null,
        archived: false
      })

      options = {
        ...options,
        recognitions
      }
    }

    this.setState(options)
  }

  setCreate = (type) => {
    const generatedId = Math.ceil(Math.random() * 100)
    const { editable } = this.state
    if(editable) {
      this.cancel()
    }

    this.setState({
      editable: true,
      editType: "create",
      type: type,
      archived: false
    }, () => {
      if(type === "behaviour") {
        this.addBehaviour({
          id: generatedId,
          description: "",
        })
      } else if(type === "consequence") {
        this.addConsequence({
          id: generatedId,
          description: "",
        })
      } else {
        this.addRecognition({
          id: generatedId,
          date: new Date(),
          observation: "",
          whoId: 0,
          nominatorId: 0,
          archived: false
        })
      }
    })

  }

  setEdit = (type, obj) => {
    const { editable } = this.state
    if(editable) {
      this.cancel()
    }
    this.setState({
      editable: true,
      editType: "edit",
      type: type
    }, () => {
      if(type === "behaviour") {
        this.addBehaviour({
          id: obj.id,
          description: obj.description,
        })
      } else if(type === "consequence") {
        this.addConsequence({
          id: obj.id,
          description: obj.description,
        })
      } else {
        this.addRecognition({
          id: obj.id,
          date: new Date(obj.date),
          observation: obj.observation,
          whoId: obj.who.id,
          nominatorId: obj.nominator.id,
          archived: obj.archived
        })
      }
    })
  }

  cancel = () => {
    const { editType, type } = this.state

    let options = {
      editable: false,
      editType: "",
      type: "",
      behaviour: {},
      consequence: {},
      recognition: {}
    }

    if(editType === "create") {
      const { behaviours, recognitions, consequences } = this.state

      type === "behaviour" ? behaviours.shift() : type === "consequence" ? consequences.shift() : recognitions.shift()

      options = {
        ...options,
        behaviours,
        consequences,
        recognitions
      }
    }

    this.setState(options)
  }

  handleChange = (e) => {
    const { type, behaviour, recognition, consequence } = this.state

    let options = {}
    if(type === "behaviour") {
      options = {
        behaviour: {
          ...behaviour,
          [e.target.name]: e.target.value
        }
      }
    } else if(type === "consequence") {
      options = {
        consequence: {
          ...consequence,
          [e.target.name]: e.target.value
        }
      }
    } else {
      options = {
        recognition: {
          ...recognition,
          [e.target.name]: e.target.value
        }
      }
    }

    this.setState(options)
  }

  handleDateChange = date => {
    const { recognition } = this.state

    this.setState({
      recognition: {
        id: recognition.id,
        observation: recognition.observation,
        whoId: recognition.whoId,
        nominatorId: recognition.nominatorId,
        date: date,
        issueDate: date
      }
    })
  }

  getBehaviourText = (behaviour) => {
    const { editable, type } = this.state
    const selectedBehaviour = this.state.behaviour

    if(editable && type === "behaviour" && behaviour.id === selectedBehaviour.id) {
      return (
        <div className="form-group row">
          <Col xs={12}>
            <input
              autoFocus
              value={selectedBehaviour.description}
              className="form-control"
              name="description"
              placeholder="Behaviour"
              onChange={(e) => this.handleChange(e)}
              autoComplete="off"
            />
          </Col>
        </div>
      )
    } else {
      return behaviour.description
    }
  }

  renderBehaviourButtons = (behaviour) => {
    const { editable, type, loading, loadingType, loadingId } = this.state
    const selectedBehaviour = this.state.behaviour

    if(editable && type === "behaviour" && behaviour.id === selectedBehaviour.id) {
      return (
        <Fragment>
          <OverlayTrigger
            placement="top"
            delay={{ show: 25, hide: 40 }}
            overlay={(props) => renderTooltip({...props}, getLabelText("cancel"))}
          >
            <button className="btn-icons-container" type="submit" onClick={this.cancel}>
              <img src={Cancel} alt="Cancel" className="btn-icons"/>
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            delay={{ show: 25, hide: 40 }}
            overlay={(props) => renderTooltip({...props}, getLabelText("save"))}
          >
            <button className="btn-icons-container" type="submit" onClick={() => this.save()}>
              {loading && loadingType === "save" && loadingId === behaviour.id
                ? (
                  <img src={Loader} alt="Loader" className="loader-spinner"/>
                ) : (
                  <img src={Save} alt="Save" className="btn-icons"/>
                )
              }
            </button>
          </OverlayTrigger>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <OverlayTrigger
            placement="top"
            delay={{ show: 25, hide: 40 }}
            overlay={(props) => renderTooltip({...props}, getLabelText("edit"))}
          >
            <button className={"btn-icons-container" + (this.state.editable ? " disabled" : "")} type="submit" onClick={() => this.setEdit("behaviour", behaviour)}>
              <img src={Edit} alt="Edit" className="btn-icons"/>
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            delay={{ show: 25, hide: 40 }}
            overlay={(props) => renderTooltip({...props}, getLabelText("delete"))}
          >
            <button className={"btn-icons-container" + (this.state.editable ? " disabled" : "")} type="submit" onClick={() => this.delete("behaviour", behaviour.id)}>
              {loading && loadingType === "delete" && loadingId === behaviour.id
                ? (
                  <img src={Loader} alt="Loader" className="loader-spinner"/>
                ) : (
                  <img src={Delete} alt="delete" className="btn-icons"/>
                )
              }
            </button>
          </OverlayTrigger>
        </Fragment>
      )
    }
  }

  renderBehavioursRow = () => {
    const { behaviours, loadingBehaviours } = this.state
    return (
      <Fragment>
          <Row className="mt-2 justify-content-center column">
            <Col className="text-right text-left pr-0" xl={12} lg={12} md={12} sm={12} xs={12}>
              <Button
                className={"btn btn-primary" + (this.state.editable ? " disabled" : "")}
                onClick={() => this.setCreate("behaviour")}
              >
                {getLabelText("add")}<FontAwesomeIcon className="ml-2" icon={faPlus} />
              </Button>
            </Col>
          </Row>
          <Row className="mt-3 justify-content-center column">
            <Col xl={12} lg={12} md={12} sm={12} xs={12} className="pr-0">
              <Table striped bordered hover className="mt-1 standard-tbl standard-value-behaviours-behaviours-tbl table-heading-background user-text">
              <thead>
                <tr>
                  <th width="75%">
                    <b>{getLabelText("agreed_behaviours")}</b>
                  </th>
                  <th width="25%"></th>
                </tr>
                </thead>
                <tbody>
                {loadingBehaviours
                    ? (
                        <tr>
                          <td colSpan="3" className="text-center">
                            <img src={Loader} alt="Loader" className="loader-spinner"/>
                          </td>
                        </tr>
                    )
                    : behaviours.length > 0
                        ? behaviours.map((behaviour, key) => (
                            <tr key={key}>
                              <td>
                                {this.getBehaviourText(behaviour)}
                              </td>
                              <td>
                                {this.renderBehaviourButtons(behaviour)}
                              </td>
                            </tr>
                        )) : (
                            <tr>
                              <td colSpan="3" className="text-center">
                                {getLabelText("no_behaviours_error")}
                              </td>
                            </tr>
                        )
                }
                </tbody>
              </Table>
            </Col>
          </Row>
      </Fragment>
    )
  }

  getConsequenceText = (consequence) => {
    const { editable, type } = this.state
    const selectedConsequence = this.state.consequence

    if(editable && type === "consequence" && consequence.id === selectedConsequence.id) {
      return (
        <div className="form-group column">
          <Col xs={12}>
            <input
              autoFocus
              value={selectedConsequence.description}
              className="form-control"
              name="description"
              placeholder="Consequence"
              onChange={(e) => this.handleChange(e)}
              autoComplete="off"
            />
          </Col>
        </div>
      )
    } else {
      return consequence.description
    }
  }

  renderConsequenceButtons = (consequence) => {
    const { editable, type, loading, loadingType, loadingId } = this.state
    const selectedConsequence = this.state.consequence

    if(editable && type === "consequence" && consequence.id === selectedConsequence.id) {
      return (
        <Fragment>
          <OverlayTrigger
            placement="top"
            delay={{ show: 25, hide: 40 }}
            overlay={(props) => renderTooltip({...props}, getLabelText("cancel"))}
          >
            <button className="btn-icons-container" type="submit" onClick={this.cancel}>
              <img src={Cancel} alt="Cancel" className="btn-icons"/>
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            delay={{ show: 25, hide: 40 }}
            overlay={(props) => renderTooltip({...props}, getLabelText("save"))}
          >
            <button className="btn-icons-container" type="submit" onClick={() => this.save()}>
              {loading && loadingType === "save" && loadingId === consequence.id
                ? (
                  <img src={Loader} alt="Loader" className="loader-spinner"/>
                ) : (
                  <img src={Save} alt="Save" className="btn-icons"/>
                )
              }
            </button>
          </OverlayTrigger>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <OverlayTrigger
            placement="top"
            delay={{ show: 25, hide: 40 }}
            overlay={(props) => renderTooltip({...props}, getLabelText("edit"))}
          >
            <button className={"btn-icons-container" + (this.state.editable ? " disabled" : "")} type="submit" onClick={() => this.setEdit("consequence", consequence)}>
              <img src={Edit} alt="Edit" className="btn-icons"/>
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            delay={{ show: 25, hide: 40 }}
            overlay={(props) => renderTooltip({...props}, getLabelText("delete"))}
          >
            <button className={"btn-icons-container" + (this.state.editable ? " disabled" : "")} type="submit" onClick={() => this.delete("consequence", consequence.id)}>
              {loading && loadingType === "delete" && loadingId === consequence.id
                ? (
                  <img src={Loader} alt="Loader" className="loader-spinner"/>
                ) : (
                  <img src={Delete} alt="Delete" className="btn-icons"/>
                )
              }
            </button>
          </OverlayTrigger>
        </Fragment>
      )
    }
  }

  renderConsequencesRow = () => {
    const { consequences, loadingConsequences } = this.state
    return (
      <Fragment>
        <Row className="mt-2 justify-content-center column">
          <Col className="text-right text-left pr-0" xl={12} lg={12} md={12} sm={12} xs={12}>
            <Button
              className={"btn btn-primary" + (this.state.editable ? " disabled" : "")}
              onClick={() => this.setCreate("consequence")}
            >
              {getLabelText("add")}<FontAwesomeIcon className="ml-2" icon={faPlus} />
            </Button>
          </Col>
        </Row>
        <Row className="mt-3 justify-content-center column">
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="pr-0">
            <Table striped bordered hover className="mt-1 standard-tbl standard-value-behaviours-consequences-tbl table-heading-background user-text">
              <thead>
                <tr>
                  <th width="75%">
                    <b>{getLabelText("agreed_concequences")}</b>
                  </th>
                  <th width="25%"></th>
                </tr>
              </thead>
              <tbody>
                {loadingConsequences
                ? (
                    <tr>
                      <td colSpan="3" className="text-center">
                        <img src={Loader} alt="Loader" className="loader-spinner"/>
                      </td>
                    </tr>
                )
                : consequences.length > 0
                  ? consequences.map((consequence, key) => (
                    <tr key={key}>
                      <td>
                        {this.getConsequenceText(consequence)}
                      </td>
                      <td>
                        {this.renderConsequenceButtons(consequence)}
                      </td>
                    </tr>
                  )) : (
                    <tr>
                      <td colSpan="3" className="text-center">
                        {getLabelText("no_consequences_error")}
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </Table>
          </Col>
        </Row>
      </Fragment>
    )
  }

  formattedDate(date) {
    let month = date.getMonth() + 1
    let day = date.getDate()

    if(month.toString().length < 2)
      month = "0" + month.toString()

    if(day.toString().length < 2)
      day = "0" + day.toString()

    return day + "/" + month + "/" + date.getFullYear()
  }

  getRecognitionDate = (recognition) => {
    const { editable, type, editType } = this.state
    const selectedRecognition = this.state.recognition

    if(editable && type === "recognition" && recognition.id === selectedRecognition.id) {
      return (
        <DatePicker
          name="date"
          className={"form-control" + (editType === "" ? " disabled" : "")}
          required={true}
          dateFormat="yyyy/MM/dd"
          showYearDropdown
          selected={selectedRecognition.date}
          onChange={this.handleDateChange}
          tabIndex="0"
          autoComplete="off"
        />
      )
    } else {
      return this.formattedDate(new Date(recognition.date))
    }
  }

  getObservation = (recognition) => {
    const { editable, type } = this.state
    const selectedRecognition = this.state.recognition

    if(editable && type === "recognition" && recognition.id === selectedRecognition.id) {
      return (
        <div>
          <Col xs={12}>
            <input
              value={selectedRecognition.observation}
              className="form-control"
              name="observation"
              placeholder="Observation"
              onChange={(e) => this.handleChange(e)}
              autoComplete="off"
            />
          </Col>
        </div>
      )
    } else {
      return recognition.observation
    }
  }

  getNominated = (recognition) => {
    const { editable, type, members } = this.state
    const selectedRecognition = this.state.recognition

    if(editable && type === "recognition" && recognition.id === selectedRecognition.id) {
      return (
        <select className="form-control cdi-ddl w-100 ml-0"
          name="whoId"
          value={selectedRecognition.whoId ? selectedRecognition.whoId : 1}
          required={true}
          onChange={(e) => this.handleChange(e)}
        >
          <option value="1" disabled>{this.getText("select_member")}</option>
          {members.map((user, index) => {
            return <option value={user.id} key={index}>{user.firstNames + " " + user.lastName}</option>
          })}
        </select>
      )
    } else {
      return recognition.who ? recognition.who.firstNames + " " + recognition.who.lastName : ""
    }
  }
  getNominator = (recognition) => {
    const { editable, type, members } = this.state;
    const { selectedTeam } = this.props;
    const selectedRecognition = this.state.recognition;
    let teamList = members;

    if(selectedTeam && selectedTeam.coach) {
      const exists = teamList.find(user => user.id === selectedTeam.coach.id)

      if(!exists) {
        teamList = [...teamList, selectedTeam.coach].sort((a, b) => a.firstNames.localeCompare(b.firstNames));
      }
    }

    if(editable && type === "recognition" && recognition.id === selectedRecognition.id) {
      return (
        <select className="form-control cdi-ddl w-100 ml-0"
          name="nominatorId"
          value={selectedRecognition.nominatorId ? selectedRecognition.nominatorId : 1}
          required={true}
          onChange={(e) => this.handleChange(e)}
        >
          <option value="1" disabled>{this.getText("select_member")}</option>
          {teamList.map((user, index) => {
            return <option value={user.id} key={index}>{user.firstNames + " " + user.lastName}</option>
          })}
        </select>
      )
    } else {
      return recognition.who ? recognition.nominator.firstNames + " " + recognition.nominator.lastName : ""
    }

  }

  renderRecognitionButtons = (recognition) => {
    const { editable, type, loading, loadingType, loadingId, deleteId } = this.state
    const selectedRecognition = this.state.recognition

    if(editable && type === "recognition" && recognition.id === selectedRecognition.id) {
      return (
        <Fragment>
          <OverlayTrigger
            placement="top"
            delay={{ show: 25, hide: 40 }}
            overlay={(props) => renderTooltip({...props}, getLabelText("cancel"))}
          >
            <button className="btn-icons-container" type="submit" onClick={this.cancel}>
              <img src={Cancel} alt="Cancel" className="btn-icons"/>
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            delay={{ show: 25, hide: 40 }}
            overlay={(props) => renderTooltip({...props}, getLabelText("save"))}
          >
            <button className="btn-icons-container" type="submit" onClick={() => this.save()}>
              {loading && loadingType === "save" && loadingId === recognition.id
                ? (
                  <img src={Loader} alt="Loader" className="loader-spinner"/>
                ) : (
                  <img src={Save} alt="Save" className="btn-icons"/>
                )
              }
            </button>
          </OverlayTrigger>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          {!this.state.archived && (
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, getLabelText("edit"))}
            >
              <button
                className={"btn-icons-container" + (this.state.editable ? " disabled" : "")}
                type="submit"
                onClick={() => this.setEdit("recognition", recognition)}
              >
                <img src={Edit} alt="Edit" className="btn-icons"/>
              </button>
            </OverlayTrigger>
          )}
          {this.state.archived && (
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, getLabelText("delete"))}
            >
              <button className={"btn-icons-container" + (this.state.editable ? " disabled" : "")} type="submit" onClick={() => this.delete("recognition", recognition.id)}>
                {loading && loadingType === "delete" && loadingId === recognition.id
                  ? (
                    <img src={Loader} alt="Loader" className="loader-spinner"/>
                  ) : (
                    <img src={Delete} alt="Delete" className="btn-icons"/>
                  )
                }
              </button>
            </OverlayTrigger>
          )}
          <OverlayTrigger
            placement="top"
            delay={{ show: 25, hide: 40 }}
            overlay={(props) => renderTooltip({...props}, (this.state.archived ? getLabelText("put_back") : getLabelText("archive")))}
          >
            <Button
              className={"btn-icons-container"  + (selectedRecognition.id || typeof(selectedRecognition.id) === "number" ? " disabled" : "")}
              onClick={() => this.archive(recognition.id, recognition.archived)}
            >
              {loading && loadingType === "archive" && deleteId === recognition.id
                ? (
                  <Fragment>
                    <img src={Loader} alt="Loader" className="loader-spinner"/>
                  </Fragment>
                ) : (
                  <Fragment>
                    {recognition.archived
                      ? (
                        <img src={PutBack} alt="PutBack" className="btn-icons"/>
                      ) : (
                        <img src={Archive} alt="Archive" className="btn-icons"/>
                      )
                    }
                  </Fragment>
                )
              }
            </Button>
          </OverlayTrigger>
        </Fragment>
      )
    }
  }

  handleSort = (type) => {
    const { recognitions, sortBy, orderBy } = this.state
    let newActions = recognitions
    let newOrder = "asc"

    switch(type) {
      case "date":
        if(type === sortBy) {
          if (orderBy === "desc") {
            newActions.sort((a, b) => new Date(b.date).setHours(24,0,0,0) - new Date(a.date).setHours(24,0,0,0))
          } else if (orderBy === "asc") {
            newOrder = "desc"
            newActions.sort((a, b) => new Date(a.date).setHours(24,0,0,0) - new Date(b.date).setHours(24,0,0,0))
          }
        } else {
          newActions.sort((a, b) => new Date(b.date).setHours(24,0,0,0) - new Date(a.date).setHours(24,0,0,0))
        }
        break;
      case "recognition":
        if(type === sortBy) {
          if (orderBy === "desc") {
            newActions.sort((a, b) => a.observation.localeCompare(b.observation))
          } else if (orderBy === "asc") {
            newOrder = "desc"
            newActions.sort((a, b) => b.observation.localeCompare(a.observation))
          }
        } else {
          newActions.sort((a, b) => a.observation.localeCompare(b.observation))
        }
        break;
      case "who":
        if(type === sortBy) {
          if (orderBy === "asc") {
            newOrder = "desc"
            newActions = newActions.sort((a, b) => a.who ? a.who.firstNames.localeCompare(b.who ? b.who.firstNames : "") : false)

          } else if(orderBy === "desc") {
            newActions = newActions.sort((a, b) => b.who ? b.who.firstNames.localeCompare(a.who ? a.who.firstNames : "") : false)

          }
        } else {
          newActions = newActions.sort((a, b) => b.who ? b.who.firstNames.localeCompare(a.who ? a.who.firstNames : "") : false)

        }
        break;
      case "nominated":
        if(type === sortBy) {
          if (orderBy === "asc") {
            newOrder = "desc"
            newActions = newActions.sort((a, b) => a.nominator ? a.nominator.firstNames.localeCompare(b.nominator ? b.nominator.firstNames : "") : false)

          } else if(orderBy === "desc") {
            newActions = newActions.sort((a, b) => b.nominator ? b.nominator.firstNames.localeCompare(a.nominator ? a.nominator.firstNames : "") : false)

          }
        } else {
          newActions = newActions.sort((a, b) => b.nominator ? b.nominator.firstNames.localeCompare(a.nominator ? a.nominator.firstNames : "") : false)

        }
        break;

      default:

        break;
    }

    this.setState({
      orderBy: newOrder,
      sortBy: newOrder === "" ? "" : type,
      actions: newActions
    })
  }

  getText = val => {
    const { user } = this.props;

    const companyId = localStorage.getItem("companyId");
    let lang = "EN";
    let langLabels = labels.find(lbl => lbl.lang === lang);
    let def = langLabels.glossary.find(label => label.key === val);

    if(user && user.companies && user.companies.length) {
      const company = user.companies.find(company => company.id === companyId);
      if(company) {
        lang = company.language;
        langLabels = labels.find(lbl => lbl.lang === lang);
        def = langLabels.glossary.find(label => label.key === val);
        if(!def) {
          lang = "EN";
          langLabels = labels.find(lbl => lbl.lang === lang);
          def = langLabels.glossary.find(label => label.key === val);
        }
      }
      if(company && company.companyGlossaries) {
        const custom = company.companyGlossaries.find(label => label.key === val);

        if(custom) return custom.value
      }
    }

    if(!def) {
      console.log(val)
    }
    return def.value
  }

  openModel = () => {
    this.setState({ showModal: true });
  }

  renderRecognitionsRow = () => {
    const { recognitions, loadingRecognitions } = this.state;
    return (
      <Fragment>
        <SubHeaderComponent>
          <Col className="text-right childenSpace">
            <div className={"refreshBtn"}>
              <RealtimeRefreshComponent
                refresh={this.props.refresh}
                hasNewData={this.props.hasNewData}
                toggleRefresh={this.props.toggleRefresh}
              />
            </div>
            <Button className="ml-1" onClick={(e) => this.openModel()}>Export</Button>
          </Col>
        </SubHeaderComponent>
        <Col className="text-right px-0">
          <ButtonGroup toggle className="ml-1">
            <ToggleButton
              type="radio"
              variant="secondary"
              name="createType"
              value="0"
              checked={!this.state.archived}
              onChange={() => this.toggleArchived()}
            >
              {getLabelText("active")}
            </ToggleButton>
            <ToggleButton
              type="radio"
              variant="secondary"
              name="createType"
              value="1"
              checked={this.state.archived}
              onChange={() => this.toggleArchived()}
            >
              {getLabelText("archived")}
            </ToggleButton>
          </ButtonGroup>
        </Col>
        {!this.state.archived && (
          <Row className="mt-2 column">
            <Col className="text-right text-left pr-0">
              <Button
                className={"btn btn-primary" + (this.state.editable ? " disabled" : "")}
                onClick={() => this.setCreate("recognition")}
              >
                {getLabelText("add")}<FontAwesomeIcon className="ml-2" icon={faPlus} />
              </Button>
            </Col>
          </Row>
        )}
        <Row className="mt-1 column">
          <Col className="pr-0">
            <Table striped bordered hover className="mt-2 standard-tbl standard-value-behaviours-tbl table-heading-background user-text">
              <thead>
                <tr>
                  <th
                    width="15%"
                    onClick={() => this.handleSort("date")}
                  >
                    {getLabelText("date")} {this.state.sortBy === "date" && (
                      (this.state.orderBy === "asc" && (
                        <FontAwesomeIcon className="ml-2" icon={faChevronUp} />
                      )) || (this.state.orderBy === "desc" && (
                        <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
                      ))
                    )}
                  </th>
                  <th
                    width="40%"
                    onClick={() => this.handleSort("recognition")}
                  >
                    {getLabelText("observation_recognition")} {this.state.sortBy === "recognition" && (
                      (this.state.orderBy === "asc" && (
                        <FontAwesomeIcon className="ml-2" icon={faChevronUp} />
                      )) || (this.state.orderBy === "desc" && (
                        <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
                      ))
                    )}
                  </th>
                  <th
                    width="15%"
                    onClick={() => this.handleSort("who")}
                  >
                    {getLabelText("who")} {this.state.sortBy === "who" && (
                      (this.state.orderBy === "asc" && (
                        <FontAwesomeIcon className="ml-2" icon={faChevronUp} />
                      )) || (this.state.orderBy === "desc" && (
                        <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
                      ))
                    )}
                  </th>
                  <th
                    width="15%"
                    onClick={() => this.handleSort("nominated")}
                  >
                    {getLabelText("nominated_by")} {this.state.sortBy === "nominated" && (
                      (this.state.orderBy === "asc" && (
                        <FontAwesomeIcon className="ml-2" icon={faChevronUp} />
                      )) || (this.state.orderBy === "desc" && (
                        <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
                      ))
                    )}
                  </th>
                  <th width="15%"></th>
                </tr>
              </thead>
              <tbody>
                {loadingRecognitions
                  ? (
                    <tr>
                      <td colSpan="5" className="text-center">
                        <FontAwesomeIcon className="ml-2 fa-spin fa-2x" icon={faSpinner} />
                      </td>
                    </tr>
                  ) : recognitions.length > 0
                    ? recognitions.filter(recognition => recognition.archived === this.state.archived).map((recognition, key) => (
                      <tr key={key}>
                        <td>
                          {this.getRecognitionDate(recognition)}
                        </td>
                        <td>
                          {this.getObservation(recognition)}
                        </td>
                        <td>
                          {this.getNominated(recognition)}
                        </td>
                        <td>
                          {this.getNominator(recognition)}
                        </td>
                        <td>
                          {this.renderRecognitionButtons(recognition)}
                        </td>
                      </tr>
                    )) : (
                      <tr>
                        <td colSpan="5" className="text-center">
                          {getLabelText("no_recognition_error")}
                        </td>
                      </tr>
                    )
                }
              </tbody>
            </Table>
          </Col>
        </Row>
      </Fragment>
    )
  }

  renderCombinedRows = () => {
    return (
      <Row className={"column pr-0"}>
        <Col className="pr-0">
          {this.renderBehavioursRow()}
        </Col>
        <Col className="pr-0">
          {this.renderConsequencesRow()}
        </Col>
      </Row>
    )
  }

  closeModal = () => {
    this.setState({ 
      showModal: false,
      agreedBehaviours: true,
      agreedConsequences : false,
      observationRecognition : false,
      showDateFilters: false,
      filterFromDate: new Date(),
      filterToDate: new Date()
    });
  }

  exportAgreedBehaviours = async (workbook) => {
    const workSheetName = 'AgreedBehaviours';
    try {

      const response = await getTeamBehaviours(this.props.selectedTeam.id);
      if (response && response.data.teamBehaviours != null && response.data.teamBehaviours.length > 0) {
        const columns = [
          { header: 'Category', key: 'category' },
          { header: 'Agreed Behaviours', key: 'behaviour' }
        ];

        let allBehaviours = [];
        response.data.teamBehaviours.forEach(behaviour => {
          let newBehaviour = {
            category: behaviour.value?.value,
            behaviour: behaviour.description
          }
          allBehaviours.push(newBehaviour);
        });

        allBehaviours.sort((a, b) => {
          return a.category.localeCompare(b.category)
        })

        // let allScheduledEvents = mapEventExportData(events);
        // let filteredEvents = [];

        // creating one worksheet in workbook
        const worksheet = workbook.addWorksheet(workSheetName);

        // add worksheet columns
        // each columns contains header and its mapping key from data
        worksheet.columns = columns;

        // updated the font for first row.
        worksheet.getRow(1).font = { bold: true };

        // loop through all of the columns and set the alignment with width.
        worksheet.columns.forEach(column => {
          column.width = column.header.length + 15;
          column.alignment = { horizontal: 'left' };
        });

        allBehaviours.forEach(behaviour => {
          worksheet.addRow(behaviour);
        });

        // loop through all of the rows and set the outline style.
        worksheet.eachRow({ includeEmpty: false }, row => {
          // store each cell to currentCell
          const currentCell = row._cells;

          // loop through currentCell to apply border only for the non-empty cell of excel
          currentCell.forEach(singleCell => {
            // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
            const cellAddress = singleCell._address;

            // apply border
            worksheet.getCell(cellAddress).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          });
        });

       return workbook;
      }
    } catch (error) {
      console.error('Error in exportAgreedBehaviours - ', error.message);
    } finally {
    }
  }

  exportAgreedConsequences = async (workbook) => {
    const workSheetName = 'AgreedConsequences';

    try {

      const response = await getTeamConsequences(this.props.selectedTeam.id);
      if (response && response.data.teamConsequences != null && response.data.teamConsequences.length > 0) {
        const columns = [
          { header: 'Category', key: 'category' },
          { header: 'Agreed Consequences', key: 'consequence' }
        ];

        let allConsequences = [];
        response.data.teamConsequences.forEach(consequence => {
          let newConsequence = {
            category: consequence.value?.value,
            consequence: consequence.description
          }
          allConsequences.push(newConsequence);
        });

        allConsequences.sort((a, b) => {
          return a.category.localeCompare(b.category)
        })

        // creating one worksheet in workbook
        const worksheet = workbook.addWorksheet(workSheetName);

        // add worksheet columns
        // each columns contains header and its mapping key from data
        worksheet.columns = columns;

        // updated the font for first row.
        worksheet.getRow(1).font = { bold: true };

        // loop through all of the columns and set the alignment with width.
        worksheet.columns.forEach(column => {
          column.width = column.header.length + 15;
          column.alignment = { horizontal: 'left' };
        });

        allConsequences.forEach(consequence => {
          worksheet.addRow(consequence);
        });

        // loop through all of the rows and set the outline style.
        worksheet.eachRow({ includeEmpty: false }, row => {
          // store each cell to currentCell
          const currentCell = row._cells;

          // loop through currentCell to apply border only for the non-empty cell of excel
          currentCell.forEach(singleCell => {
            // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
            const cellAddress = singleCell._address;

            // apply border
            worksheet.getCell(cellAddress).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          });
        });

       return workbook;
      }
    } catch (error) {
      console.error('Error in exportAgreedConsequences - ', error.message);
    } finally {
    }
  }

  exportObservationRecognition = async (workbook, fromDate, toDate) => {
    const workSheetName = 'ObservationRecognition';
    
    try {
      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(workSheetName);

      const columns = [
        { header: 'Date', key: 'date' },
        { header: 'Category', key: 'category' },
        { header: 'Observation/Recognition', key: 'recognition' },
        { header: 'Who', key: 'who' },
        { header: 'Nominated By', key: 'nominatedBy' },
        { header: 'Is Archived', key: 'isArchived' },
      ];

      worksheet.columns = columns;

      const response = await getTeamRecognitions(this.props.selectedTeam.id, fromDate, toDate);

      if (response && response.data.teamRecognitions != null && response.data.teamRecognitions.length > 0) {
        let allRecognitions = [];
        response.data.teamRecognitions.forEach(recognition => {
          let newRecognition = {
            date: this.formattedDate(new Date(recognition.date)),
            category: recognition.value?.value,
            recognition: recognition.observation,
            who: recognition.who.firstNames + " " + recognition.who.lastName,
            nominatedBy: recognition.nominator.firstNames + " " + recognition.nominator.lastName,
            isArchived: recognition.archived ? "Yes" : "No"
          }
          allRecognitions.push(newRecognition);
        });

        allRecognitions.sort((a, b) => {
          return a.category.localeCompare(b.category)
        })

        // updated the font for first row.
        worksheet.getRow(1).font = { bold: true };

        // loop through all of the columns and set the alignment with width.
        worksheet.columns.forEach(column => {
          column.width = column.header.length + 15;
          column.alignment = { horizontal: 'left' };
        });

        allRecognitions.forEach(consequence => {
          worksheet.addRow(consequence);
        });

        // loop through all of the rows and set the outline style.
        worksheet.eachRow({ includeEmpty: false }, row => {
          // store each cell to currentCell
          const currentCell = row._cells;

          // loop through currentCell to apply border only for the non-empty cell of excel
          currentCell.forEach(singleCell => {
            // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
            const cellAddress = singleCell._address;

            // apply border
            worksheet.getCell(cellAddress).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          });
        });
        return workbook;
      }
      return workbook;
    } catch (error) {
      console.error('Error in exportObservationRecognition - ', error.message);
    }
  }

  exportExcel = async () => {
    let workbook = new Excel.Workbook();
    try {

      this.setState({
        exportCompleted: false
      })

      if (this.state.agreedBehaviours) {
        workbook = await this.exportAgreedBehaviours(workbook);
      }
      if (this.state.agreedConsequences) {
        workbook = await this.exportAgreedConsequences(workbook);
      }
      if (this.state.observationRecognition) {
        workbook = await this.exportObservationRecognition(workbook, this.state.filterFromDate, this.state.filterToDate);
      }

      let teamName;
      if (this.props.selectedTeam.id !== 0) {
        teamName = this.props.selectedTeam.name;
      }
      const fileName = teamName + "-PromisesBehaviours";

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
     
    } catch (error) {
      console.error('Error saving file - ', error.message);
    } finally {
      this.setState({
        exportCompleted: true
      })
    }
  }

  selectExportCategory = (category) => {
    if (category==='agreed-Behaviours') {
      this.setState({ agreedBehaviours: !this.state.agreedBehaviours });
    }
    else if (category==='agreed-Consequences') {
      this.setState({ agreedConsequences: !this.state.agreedConsequences });
    }
    else if (category==='observation-Recognition') {
      this.setState({ observationRecognition: !this.state.observationRecognition });
      if (!this.state.observationRecognition) {
        this.setState({ showDateFilters: true });
      }
      else
      {
        this.setState({ showDateFilters: false });
      }
    }
  }

  isCategoryIncluded = (isIncluded) => {
    return !isIncluded;
  }

  handleFromDate = date => {
    if (!date || date === "") return false;

    this.setState({ filterFromDate: date });
  }

  handleToDate = date => {
    if (!date || date === "") return false;

    this.setState({ filterToDate: date });
  }

  renderExportModel = () =>{
    return (
      <div className="grey-header-space container-left-plus6">
        <Row className="width justify-content-center">
          <Modal
            isOpen={this.state.showModal}
            onRequestClose={() => this.closeModal()}
            contentLabel={this.state.modalTitle}
            className="standard-modal"
            style={customStyles}
          >
            <Row>
              <Col className="text-left">
                <label className="cdi-blue-txt ml-0 mb-2">{this.state.modalTitle}</label>
              </Col>
            </Row>
            <Row>
              <Table className="w-100">
                <tbody>
                  <tr>
                    <td width="33%">
                      <Form.Check inline type="checkbox" checked={this.state.agreedBehaviours}
                        onChange={() => this.selectExportCategory('agreed-Behaviours')}
                        label={"Agreed Behaviours"} disabled={!this.state.exportCompleted} />
                    </td>
                    <td width="33%">
                      <Form.Check inline type="checkbox" checked={this.state.agreedConsequences}
                        onChange={() => this.selectExportCategory('agreed-Consequences')}
                        label={"Agreed Consequences"} disabled={!this.state.exportCompleted}/>
                    </td>
                    <td width="33%">
                      <Form.Check inline type="checkbox" checked={this.state.observationRecognition}
                        onChange={() => this.selectExportCategory('observation-Recognition')}
                        label={"Observation/Recognition"} disabled={!this.state.exportCompleted}/>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>

            {this.state.showDateFilters && (
              <div className="mt-20">
                <Row className="mb-2">
                  <Col xs={3}>
                    <label className="cdi-blue-txt ml-2">From Date:</label>
                  </Col>
                  <Col xs={9} className="datepicker-100">
                    <DatePicker
                      name="date"
                      className="form-control w-100"
                      required={true}
                      dateFormat="yyyy/MM/dd"
                      showYearDropdown
                      selected={this.state.filterFromDate}
                      onChange={this.handleFromDate}
                      inline={false}
                      autoComplete="off"
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs={3}>
                    <label className="cdi-blue-txt ml-2">To Date:</label>
                  </Col>
                  <Col xs={9} className="datepicker-100">
                    <DatePicker
                      name="date"
                      className="form-control w-100"
                      required={true}
                      dateFormat="yyyy/MM/dd"
                      showYearDropdown
                      selected={this.state.filterToDate}
                      onChange={this.handleToDate}
                      inline={false}
                      autoComplete="off"
                    />
                  </Col>
                </Row>
              </div>
            )}

            {!this.state.exportCompleted && (
              <Row className="mt-10">
                <Col xs={6} className="text-right">
                  <img src={Loader} alt="Loader" className="loader-spinner" />
                </Col>
              </Row>
            )}

            <Row className="mb-4 mt-4 width">
              <Col xs={6} className="text-right">
                <Button variant="primary" className={"btn-scheduled"} onClick={() => this.exportExcel()} disabled={!this.state.exportCompleted}>
                  Export
                </Button>
              </Col>
              <Col xs={6} className="text-left">
                <Button variant="default" className="btn-scheduled cdi-blue-outline color-blue"
                 onClick={() => this.closeModal()} disabled={!this.state.exportCompleted}>Cancel</Button>
              </Col>
            </Row>
          </Modal>

        </Row>
        </div>
    )
  }

  render() {
    return [
      this.renderValueSelectionRow(),
      this.renderCombinedRows(),
      this.renderRecognitionsRow(),
      this.renderExportModel()
    ]
  }
}

const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam,
  user: state.userReducer.user
})

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ValuesBehavioursScreen)
