import { gql } from "@apollo/client";

export const MODULES_QUERY = gql`
  query {
    reviewModules {
      id
      name
      orderByString
      canDelete
    }
  }
`;


export const CREATE_MODULE_MUTATION = gql`
  mutation createModule (
    $name: String!
    )
    {
      createReviewModule(name: $name)
      {
        id
        name
        orderByString
        canDelete
      }
    }
`;

export const UPDATE_MODULE_MUTATION = gql`
  mutation updateModule (
    $id: ID!
    $name: String!
    )
    {
      updateReviewModule(id:$id, name: $name)
      {
        id
        name
        orderByString
        canDelete
      }
    }
`;

export const DELETE_MODULE_MUTATION = gql`
  mutation deleteModule (
    $id: ID!
    )
    {
      deleteReviewModule(id:$id)
      {
        id
      }
    }
`;
