import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import useOnClickOutside from "use-onclickoutside";
// import { startOfWeek, lastDayOfWeek, format, setISOWeek } from "date-fns";
import { MetricStatuses } from "./resources";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { useCreateOrUpdateMetric, getTotal, getMaxValue, getMinValue, getAverage, countRedDays, countTriggers, getPeriodValue, getWeeklyPeriodValue, getWeeklyPeriodKey, getCalculatedDisplayWeek } from "./hooks";
import displayErrorToast from "../../components/utils/displayErrorToast";
import LabelComponent from "../../components/utils/getCompanyLabel";

const getLabelText = key => <LabelComponent val={key}/>

function getResultForText(frequency, isTrend, data, resultDate, monthList, monthValues, startWeek, isFinancialYear, currentFY) {
  const months = [
    {
      index: 0,
      name: "Jan"
    },
    {
      index: 1,
      name: "Feb"
    },
    {
      index: 2,
      name: "Mar"
    },
    {
      index: 3,
      name: "Apr"
    },
    {
      index: 4,
      name: "May"
    },
    {
      index: 5,
      name: "Jun"
    },
    {
      index: 6,
      name: "Jul"
    },
    {
      index: 7,
      name: "Aug"
    },
    {
      index: 8,
      name: "Sep"
    },
    {
      index: 9,
      name: "Oct"
    },
    {
      index: 10,
      name: "Nov"
    },
    {
      index: 11,
      name: "Dec"
    }
  ]
  if(frequency === "Daily" && !isTrend) {
    return data.x + " " + months[resultDate.getMonth()].name
  } else if(frequency === "Weekly" && !isTrend) {
    let year = currentFY.substring(0, 4);
    if (isFinancialYear === "Start" && [51, 52].indexOf(getCalculatedDisplayWeek(getWeeklyPeriodValue(data.x, resultDate), startWeek ? startWeek.week : null)) > -1) {
      year = (parseInt(year) - 1).toString();
    } else if (isFinancialYear === "End" && [1, 2].indexOf(getCalculatedDisplayWeek(getWeeklyPeriodValue(data.x, resultDate), startWeek ? startWeek.week : null)) > -1) {
      year = (parseInt(year) + 1).toString();
    }

    return "Wk " + getCalculatedDisplayWeek(getWeeklyPeriodValue(data.x, resultDate), startWeek ? startWeek.week : null) + " " + year
  } else {
     //return months[monthValues[data.x - 1] - 1].name + " " + (monthValues[data.x - 1] < monthValues[0] ? `${resultDate.getFullYear() - 1}` : `${resultDate.getFullYear()}`)
    return months[monthValues[data.x - 1] - 1].name + " " + ((resultDate.getMonth() + 1) < monthValues[0] 
    ? (monthValues[data.x - 1] < monthValues[0] ? `${resultDate.getFullYear()}` : `${resultDate.getFullYear() - 1}`) 
    : (monthValues[data.x - 1] < monthValues[0] ? `${resultDate.getFullYear() + 1}`: `${resultDate.getFullYear()}`))
  }
}

const ResultInput = ({ data, goal, onClose, onSubmit, resultDate, isTrend, monthValues, metricTarget, lowerTriggerValue, upperTriggerValue, monthList, colour, startWeek, isFinancialYear }) => {
  // Initialise CRUD handlers and loading state
  const [createMetric, updateMetric, isLoading] = useCreateOrUpdateMetric();
  // Close on outside click
  const ref = React.useRef(null);
  useOnClickOutside(ref, (event) => {
    if (isLoading) return;
    if (ref.current.parentNode.contains(event.target)) return;
    onClose();
  });
  // Initialise form state
  const [selectedStatus, setSelectedStatus] = useState(data.status);
  const [inputValue, setInputValue] = useState(data.y || "");
  const [targetValue, setTargetValue] = useState(metricTarget);
  const [lowerTrigger, setLowerTrigger] = useState(lowerTriggerValue);
  const [upperTrigger, setUpperTrigger] = useState(upperTriggerValue);
  // const [averages, setAverages] = useState({});

  // Keep form in sync with upstream data
  useEffect(() => {
    setInputValue(data.y);
    setSelectedStatus(data.status);
  }, [data.y, data.status, setInputValue, setSelectedStatus, setTargetValue]);


  // const dateString = useMemo(() => {
  //   const date = setISOWeek(new Date(), data.x);
  //   const first = format(startOfWeek(date), "d");
  //   const last = format(lastDayOfWeek(date), "d");
  //   const month = format(date, "MMM");
  //   const year = date.getFullYear();
  //   return `(${first}-${last} ${month} ${year})`;
  // }, [data.x]);

  const validateInput = () => {

    return true;
  };


  let dates = [];
  for (let index = 0; index < 12; index++) {
    const element = monthValues[index];

    dates.push({
      key: index +1,
      originalKey: element
    })

  }


  const handleSubmit = async (e) => {
    if (e) e.preventDefault();

    if (!validateInput()) return;
    const baseVariables = {
      value: inputValue !== "" ? parseFloat(inputValue) : null,
      status: selectedStatus,
      target: targetValue !== "" ? parseFloat(targetValue) : null,
      lowerTrigger: lowerTrigger !== "" ? parseFloat(lowerTrigger) : null,
      upperTrigger: upperTrigger !== "" ? parseFloat(upperTrigger) : null,
    };
    if (data.id) {
      const variables = {
        ...baseVariables,
        id: data.id,
      };
      const response = await updateMetric({ variables });

      if (response) {
        onSubmit(response.data.updateMetric);
        onClose();
      }
    } else {
      let period = "";
      let periodKey = "";
      if (isTrend) {
        period = "MONTH"
        periodKey = (resultDate.getMonth() + 1) < monthValues[0] ? `${resultDate.getFullYear() - 1}` : `${resultDate.getFullYear()}`
      } else {
        switch (goal.frequency) {
          case "Daily":
            period = "DAY"
            periodKey = `${resultDate.getMonth() + 1}${resultDate.getFullYear()}`
            break;
          case "Weekly":
            period = "WEEK"
            periodKey = `${resultDate.getFullYear()}`
            break;
          default:
            period = "MONTH"
            periodKey = (resultDate.getMonth() + 1) < monthValues[0] ? `${resultDate.getFullYear() - 1}` : `${resultDate.getFullYear()}`
        }
      }
      
      let variables = {
        ...baseVariables,
        goal: goal.id,
        period: period,
        periodKey: period === "WEEK" ? getWeeklyPeriodKey(data.x, resultDate, periodKey) : periodKey,
        periodValue: period === "MONTH" ? getPeriodValue(data.x, monthValues) : period === "WEEK" ? getWeeklyPeriodValue(data.x, resultDate) : data.x,
        periodStatus: data.periodStatus,
        isTrend: isTrend,
        resultDate: resultDate
      };

      try {
        const response = await createMetric({ variables });

        if (response) {
          onSubmit(response.data.createMetric);
          onClose();
        }
      } catch (e) {
        if(e.message !== "itemExists") {
          displayErrorToast(e)
        } else {
          if(window.confirm(getLabelText("metric_exists_error"))) {
            variables = {
              ...variables,
              overwrite: true
            }
            try {
              const response = await createMetric({ variables });
              if (response) {
                onSubmit(response.data.createMetric);
                onClose();
              }
            } catch (e) {
              displayErrorToast(e)
            }
          }
        }
      }


    }
  };

  const onSwitchChange = () => {
    setSelectedStatus((status) => {
      if (status === MetricStatuses.RED.value) return MetricStatuses.BLUE.value;
      return MetricStatuses.RED.value;
    });
  };


  return(
    <div
      ref = { ref }
      className = "input-result bg-white d-flex flex-column align-items-center justify-content-around border-thick border-color-grey"
          >
      <h6 className="font-weight-bold m-0">{getLabelText("result_for")} {getResultForText(goal.frequency, isTrend, data, resultDate, monthList, monthValues, startWeek, isFinancialYear, goal.period)}</h6>
      {/* <div className="d-flex flex-column align-items-center">
        <span>{isTrend ? "Month" : getPeriodDisplayText(goal.frequency)} {data.x}</span>
      </div> */}
      { isTrend && (
      <div>
        <div className="d-flex flex-column align-items-center">
          {getLabelText("total")}: {getTotal(goal.allMetrics, data.x, goal.frequency, monthValues, resultDate)}
        </div>
        <div className="d-flex flex-column align-items-center">
          {getLabelText("min")}: {getMinValue(goal.allMetrics, data.x, goal.frequency, monthValues, resultDate)}
        </div>
        <div className="d-flex flex-column align-items-center">
          {getLabelText("max")}: {getMaxValue(goal.allMetrics, data.x, goal.frequency, monthValues, resultDate)}
        </div>
        <div className="d-flex flex-column align-items-center">
          {getLabelText("avg")}: {getAverage(goal.allMetrics, data.x, goal.frequency, monthValues, resultDate)}
        </div>
        <div className="d-flex flex-column align-items-center">
          {getLabelText("triggers")}: {countTriggers(goal.allMetrics, data.x, goal.frequency, monthValues, resultDate)}
        </div>
        <div className="d-flex flex-column align-items-center">
          {getLabelText("red_bars")}: {countRedDays(goal.allMetrics, data.x, goal.frequency, monthValues, resultDate)}
        </div>
      </div>
    )}
      <form className="justify-content-center" onSubmit={handleSubmit}>
        <div className="row justify-content-center">
          <div className="col-10">
            <label className="form-label bold">{getLabelText("target")}</label>
            <input
              value={targetValue}
              disabled={isLoading}
              type="number"
              step="0.01"
              onChange={(e) => {
                setTargetValue(e.target.value)
              }}
              autoComplete="off"
              className="controls-input w-100 mr-3 text-center bg-grey border border-color-light-grey"
            />
          </div>
          {
            !isTrend && (
                <>
                  <div className="col-10">
                    <label className="form-label bold">{getLabelText("lower_trigger")}</label>
                    <input
                        value={lowerTrigger}
                        disabled={isLoading}
                        type="number"
                        step="0.01"
                        onChange={(e) => {
                          setLowerTrigger(e.target.value)
                        }}
                        autoComplete="off"
                        className="controls-input w-100 mr-3 text-center bg-grey border border-color-light-grey"
                    />
                  </div>
                  <div className="col-10">
                    <label className="form-label bold">{getLabelText("upper_trigger")}</label>
                    <input
                        value={upperTrigger}
                        disabled={isLoading}
                        type="number"
                        step="0.01"
                        onChange={(e) => {
                          setUpperTrigger(e.target.value)
                        }}
                        autoComplete="off"
                        className="controls-input w-100 mr-3 text-center bg-grey border border-color-light-grey"
                    />
                  </div>
                </>
              )
          }
        </div>
        <div className="row justify-content-center mt-1">
          <div className="col-10">
            <label className="form-label bold">{getLabelText("result")}</label>
            <input
              value={inputValue}
              disabled={isLoading}
              type="number"
              step="0.01"
              autoFocus={true}
              onChange={(e) => {
                setInputValue(e.target.value)
              }}
              autoComplete="off"
              className="controls-input w-100 mr-3 text-center bg-grey border border-color-light-grey"
            />
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-12 text-center">
            <Button
              type="submit"
              disabled={isLoading}
              className="py-1 px-2"
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
            <span>&nbsp;</span>
            <Button
              type="button"
              disabled={isLoading}
              className="py-1 px-2"
              onClick={() => {
                onClose()
              }}
            >
              <FontAwesomeIcon icon={faWindowClose} />
            </Button>
          </div>
        </div>
      </form>
      <div className="d-flex align-items-center">
        <div
          onClick={() => {
            setSelectedStatus(MetricStatuses.BLUE.value);
          }}
          className={colour === "Green" ? "border border-color-light-blue bg-green attendance-cell attendance-date-border" : "border border-color-light-blue bg-blue attendance-cell attendance-date-border"}
        />
        <Switch
          disabled={isLoading}
          checkedIcon={false}
          offColor={"#005e85"}
          onColor={"#005e85"}
          uncheckedIcon={false}
          checked={selectedStatus === MetricStatuses.RED.value}
          onChange={onSwitchChange}
          className="mx-3"
        />
        <div
          onClick={() => {
            setSelectedStatus(MetricStatuses.RED.value);
          }}
          className="border border-color-light-blue bg-danger attendance-cell attendance-date-border"
        />
      </div>
    </div >
  );
};

export default ResultInput;
