import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Dropdown } from 'react-bootstrap';
import { fetchTeams, selectTeam } from '../redux/services/TeamService';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LabelComponent from "./utils/getCompanyLabel";

const getLabelText = key => <LabelComponent val={key}/>


export class TeamSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      dropwownSize: 5
    }
  }

  componentDidMount() {
    this.props.fetchTeams();
  }

  selectTeam(id) {
    this.props.selectTeam(id);
  }

  render() {

    if(this.props.presentation.active) {
      return null
    }

    if (this.props.isLoading) {
      return (
        <Dropdown className="team-select-dropdown">
          <Dropdown.Toggle className="nav-link nav-pill team-select"  disabled={true} variant="none" id="dropdown-basic">
            <FontAwesomeIcon className="color-blue mr-2 fa-spin" icon={faSpinner} /> {getLabelText("loading_teams")}
          </Dropdown.Toggle>
        </Dropdown>
      )
    } else {
      const filteredTeams = this.props.teams.filter(team =>
        !this.state.search ||
        team.department.name?.toLowerCase().includes(this.state.search?.toLowerCase()) ||
        team.name?.toLowerCase().includes(this.state.search?.toLowerCase()) 
      );
      return (
        this.props.teams.length > 0 && (
          <Dropdown className="team-select-dropdown">
            <Dropdown.Toggle className="nav-link nav-pill team-select" variant="none" id="dropdown-basic">
              {this.props.selectedTeam.department.name} ({this.props.selectedTeam.name})
            </Dropdown.Toggle>
            <Dropdown.Menu className='w-100'>
              {this.props.teams.length > this.state.dropwownSize && (
                <>
                  <div className='mx-2 position-sticky'>
                    <input type="text" className='form-control' autoFocus placeholder='Search Teams' value={this.state.search} onChange={(e) => this.setState({ search: e.target.value })} />
                  </div>
                </>
              )}
              {filteredTeams.sort((a, b) => a.department.name.localeCompare(b.department.name)).map((team, index) => {
                return <Dropdown.Item key={index} onClick={() => this.selectTeam(team.id)}>{team.department.name} ({team.name})</Dropdown.Item>
              })}
            </Dropdown.Menu>
          </Dropdown>
        )
      )
    }
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.teamsReducer.isLoading,
  teams: state.teamsReducer.teams,
  selectedTeam: state.teamsReducer.selectedTeam,
  user: state.userReducer.user,
  presentation: state.presentationReducer
})

const mapDispatchToProps = {
  fetchTeams,
  selectTeam,
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamSelector);
