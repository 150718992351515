import { client } from "./BaseService";
import { gql } from "apollo-boost";

const getUnits = async (page = null) => {
  if(page) {
    return await client.query({
      query: gql`query {
        units(
          page: ${page}
        ) {
          id
          name
          orderByString
          hasMeasurements
        }
      }`
    })
  } else {
    return await client.query({
      query: gql`query {
        units {
          id
          name
          orderByString
          hasMeasurements
        }
      }`
    })
  }
}

const createUnit = async (name) => {
  return await client.mutate({
    mutation: gql`mutation {
      createUnit(
       name: "${name}",

     ) {
       id
       name
       orderByString
       hasMeasurements
      }
    }`
  })
}

const deleteUnit = async (id) => {
  return await client.mutate({
    mutation: gql`mutation {
      deleteUnit(id:"${id}") {
         id
      }
    }
    `
  })
}

const getUnit = async (id) => {
  return await client.query({
    query: gql`query {
      unit (id: "${id}") {
        id
        name
        orderByString
        hasMeasurements
      }
    }`
  })
}

const updateUnit = async (id, name) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateUnit (
        id: "${id}"
        name: "${name}"
      ) {
        id
        name
        orderByString
        hasMeasurements
      }
    }
    `
  })
}

const getUnitsCount = async () => {
  return await client.query({
    query: gql`query {
      unitCount
    }
    `
  })
}

export {
  getUnits,
  updateUnit,
  createUnit,
  deleteUnit,
  getUnitsCount,
  getUnit,
}
