import { client } from "./BaseService";
import { gql } from "apollo-boost";
import toGqlStrArray from "../components/utils/toGqlStrArray";

const getGoalCategories = async ({ orderBy, page = null } = {}) => {
  const method = orderBy ? `goalCategories(orderBy: "${orderBy}", page: ${page})` : 'goalCategories'
  return await client.query({
    query: gql`query {
      ${method} {
        id
        name
        orderByString
        hasGoals
        hasMeasurements
        measurements {
          id
          measurement
          unit {
            id
            name
          }
        }
      }
    }`
  })
}

const createGoalCategory = async (name) => {
  return await client.mutate({
    mutation: gql`mutation {
      createGoalCategory(
       name: "${name}",

     ) {
        id
       name
       orderByString
       hasGoals
       hasMeasurements
      }
    }`
  })
}

const deleteGoalCategory = async (id) => {
  return await client.mutate({
    mutation: gql`mutation {
      deleteGoalCategory(id:"${id}") {
         id
      }
    }
    `
  })
}

const getGoalCategory = async (id) => {
  return await client.query({
    query: gql`query {
      goalCategory (id: "${id}") {
        id
        name
        orderByString
        hasGoals
        hasMeasurements
      }
    }`
  })
}

const updateGoalCategory = async (id, name) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateGoalCategory (
        id: "${id}"
        name: "${name}"
      ) {
        id
        name
        orderByString
        hasGoals
        hasMeasurements
      }
    }
    `
  })
}

const updateGoalCategoriesOrder = async(goalCategories) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateGoalCategoriesOrder (
        goalCategories: ${toGqlStrArray(goalCategories)}
      )
    }`
  })
}

const getGoalCategoryCount = async() => {
  return await client.query({
    query: gql`query {
       goalCategoryCount
    }`
  })
}

export {
  getGoalCategories,
  updateGoalCategory,
  updateGoalCategoriesOrder,
  createGoalCategory,
  deleteGoalCategory,
  getGoalCategory,
  getGoalCategoryCount
}
