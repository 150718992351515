import React, {Component} from 'react';
import { isPasswordValid } from '../utils/validator/passwordValidator'
import Logo from '../assets/logo.png';
import {Alert, Button, FormControl, FormGroup} from 'react-bootstrap';
import {Redirect} from "react-router-dom";
import {setPassword} from '../redux/services/AuthenticationService';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import ToastComponent from '../components/ToastComponent';
import { toast } from "react-toastify";

import Pepsico from "../assets/pepsico.png";
import { logo } from '../services/BaseService';

class ConfirmPasswordScreen extends Component {
    state = {
        password: "",
        confirmPassword: "",
        error: null,
    };

    validateForm = () => {
      const { password, confirmPassword } = this.state;
      const checkPassword = isPasswordValid.validate(password);
      const checkConfirmPassword = isPasswordValid.validate(confirmPassword);
      const passwordsMatch = password === confirmPassword

      const isValid = checkPassword && checkConfirmPassword && passwordsMatch
      switch (true) {
        case isValid:
          return true;
        case !passwordsMatch:
          toast.error('Passwords do not match.')
          return false;
        case !checkPassword || !checkConfirmPassword:
          toast.error('Please ensure password consists of uppercase, lowercase, digits and special characters with a minimum of 8 characters.')
          return false;
        default:
          toast.error('Error, an unknown validation error has occured')
          return false;
      }
    }

    cleanInput = (input) => {
        let cleaned = input.trim();
        return cleaned;
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const { user } = this.props

        if (this.validateForm()) {
            this.props.setPassword(user.id, this.cleanInput(this.state.password), this.cleanInput(this.state.confirmPassword));
        }
    }

    render() {
        const {error, password, confirmPassword} = this.state;
        const {isLoading, user} = this.props;
        if(!user) return <Redirect to="/" />;
        if (user && user.hasPassword && ["Consultant", "Administrator"].includes(user.role)) return <Redirect to="/dashboard"/>;
        if (user && user.hasPassword && !["Consultant", "Administrator"].includes(user.role)) {
            if (user.teams.length > 0) {
                return <Redirect to="/what/team-attendance"/>
            } else {
                return <Redirect to="/dashboard/goals"/>
            }
        }

        return (
            <>
                <div className="login">
                    <form onSubmit={this.handleSubmit}>
                        <img src={logo === "pepsico" ? Pepsico : Logo} alt="logo" width={400}/>
                        <Alert className={error ? "d-block" : "d-none"} variant="danger">
                            {error}
                        </Alert>
                        <FormGroup className='form-group' controlId="password">
                            <FormControl
                                className="mt-2"
                                value={password}
                                placeholder="Password"
                                type="password"
                                onChange={(e) => this.setState({password: e.target.value, error: null})}
                                autoComplete="off"
                            />
                        </FormGroup>
                        <FormGroup className='form-group' controlId="confirmPassword">
                            <FormControl
                                value={confirmPassword}
                                placeholder="Confirm Password"
                                type="password"
                                onChange={(e) => this.setState({confirmPassword: e.target.value, error: null})}
                                autoComplete="off"
                            />
                        </FormGroup>
                        <Button
                            block
                            type="submit"
                        >
                            {isLoading
                                ? <FontAwesomeIcon icon={faSpinner} className="fa fa-spin"></FontAwesomeIcon>
                                : <span>Save</span>
                            }
                        </Button>
                    </form>
                </div>
                <ToastComponent />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.authenticationReducer.user,
    isLoading: state.authenticationReducer.isLoading,
});

const mapDispatchToProps = {
    setPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPasswordScreen);
