import React from "react"
import { Button, Table } from "react-bootstrap";
import DisplayRow from "./DisplayRow"
import EditRow from "./EditRow"
import LabelComponent from "../../components/utils/getCompanyLabel";
// icons
import Add from '../../assets/ButtonIcons/Add Blue.svg';
import Edit from '../../assets/ButtonIcons/Edit Blue.svg';
import Delete from "../../assets/ButtonIcons/Delete Blue.svg";


const ModuleRow = ({ module, edit, create, save, onDelete, itemId, moduleId, isCreatingNewItem, reset, editModule, onDeleteModule }) => {

    const getLabelText = key => <LabelComponent val={key}/>

    const getMilestoneRow = ({ id, name }) => {
        if(!isCreatingNewItem && itemId === id)
            return <EditRow
                key={id}
                name={name}
                id={id}
                onSave={save}
                onCancel={reset}
            />
        else
            return <DisplayRow
                onOpenEdit={edit}
                onDelete={onDelete}
                key={id}
                name={name}
                id={id}
                disabled={isCreatingNewItem || itemId !== ""}
                canDelete={true}
                moduleId={module.id}
            />
    }

    return (
        <tr key={module.id}>
            <td width="90%" valign="top" className="bordered-fix">
                <div className="table-sub-heading w-100 mb-3">
                    <h5 className="d-inline">{module.name}</h5>
                    <div style={{ float: "right" }} className="d-inline">
                        <Button onClick={() => create(module.id)} className={"btn-icons-container"  + (isCreatingNewItem || itemId !== "" ? " disabled" : "")} variant="primary">
                            <img src={Add} alt="Add" className="btn-icons"/>
                        </Button>
                        <Button onClick={() => editModule(module.id, module.name)} className={"btn-icons-container"  + (isCreatingNewItem || itemId !== "" ? " disabled" : "")} variant="primary">
                            <img src={Edit} alt="Add" className="btn-icons"/>
                        </Button>
                        <Button disabled={module.milestones.filter(m => !m.deleted).length} variant="danger" className={"btn-icons-container"  + (isCreatingNewItem || itemId !== "" ? " disabled" : "")} onClick={() => onDeleteModule(module.id, module.name)}>
                            <img src={Delete} alt="Delete" className="btn-icons"/>
                        </Button>
                    </div>
                </div>
                <form>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th width="90%" className="text-center">{getLabelText("criteria_description")}</th>
                            <th width="10%" className="text-center"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {isCreatingNewItem && moduleId === module.id && (
                            <EditRow autoComplete="off" onSave={save} onCancel={reset} moduleId={moduleId}/>
                        )}
                        {module.milestones.filter(m => !m.deleted).length > 0 && (
                            module.milestones.filter(m => !m.deleted).map(milestone => getMilestoneRow(milestone))
                        )}
                        {!module.milestones.filter(m => !m.deleted).length && !(isCreatingNewItem && moduleId === module.id) && (
                            <tr>
                                <td colSpan={2} className="text-center">
                                    {getLabelText("no_data_implementation_module")}
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </form>
            </td>
        </tr>
    )
}

export default ModuleRow
