import { client } from "./BaseService";
import { gql } from "apollo-boost";

export const getRecognitions = async (teamId, valueId) => {
  return await client.query({
    query: gql`query{
      recognitions(teamId: "${teamId}", valueId: "${valueId}") {
        id
        observation
        date
        nominator {
          id
          firstNames
          lastName
        }
        who {
          id
          firstNames
          lastName
        }
        archived
        hasRecognitions
      }
    }`
  })
}

export const getTeamRecognitions = async (teamId, fromDate, toDate) => {
  return await client.query({
    query: gql`query{
      teamRecognitions(teamId: "${teamId}", fromDate: "${fromDate}", toDate: "${toDate}") {
        id
        observation
        date
        nominator {
          id
          firstNames
          lastName
        }
        who {
          id
          firstNames
          lastName
        }
        value {
          id
          value
        }
        archived
        hasRecognitions
      }
    }`
  })
}

export const createRecognition = async(teamId, valueId, date, observation, whoId, nominatorId) => {
  return await client.mutate({
    mutation: gql`mutation {
      createRecognition(
        teamId: "${teamId}",
        valueId: "${valueId}",
        date: "${date}",
        observation: "${observation}",
        whoId: "${whoId}",
        nominatorId: "${nominatorId}"
      )
      {
        id
        archived
      }
    }`
  })
}

export const updateRecognition = async(id, date, observation, whoId, nominatorId, recognition) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateRecognition (
        id: "${id}",
        date: "${date}",
        observation: "${observation}",
        whoId: "${whoId}",
        nominatorId: "${nominatorId}"
      )
      {
        id
        archived
      }
    }`
  })
}

export const deleteRecognition = async(id) => {
  return await client.mutate({
    mutation: gql`mutation {
      deleteRecognition(id: "${id}")
      {
        id
      }
    }`
  })
}

export const updateArchived = async(id, archived) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateRecognition(
        id: "${id}",
        archived: ${archived}
      )
      {
        id
        archived
      }
    }`
  })
}