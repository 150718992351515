import { gql } from "@apollo/client";

export const TEAMS_WITH_ATTENDANCES_QUERY = gql`
  fragment fields on User {
    id
    firstNames
    lastName
  }

  query TeamsWithAttendances($id: ID!, $startDate: String, $endDate: String) {
    team(id: $id) {
      id
      attendances(startDate: $startDate, endDate: $endDate) {
        id
        date
        status
      }
      coach {
        ...fields
      }
      leaders {
        ...fields
      }
      members {
        ...fields
      }
    }
  }
`;

export const COMPANY_START_WEEKS = gql`
  query {
    companyStartWeeks {
      id
      period
      week
    }
  }
`;

export const CREATE_USER_ATTENDANCE_MUTATION = gql`
  mutation CreateUserAttendance(
    $user: String!
    $date: String!
    $status: UserAttendanceStatus!
    $team: String!
  ) {
    createUserAttendance(
      user: $user
      date: $date
      status: $status
      team: $team
    ) {
      id
      user {
        id
      }
      status
      date
    }
  }
`;

export const UPDATE_USER_ATTENDANCE_MUTATION = gql`
  mutation UpdateUserAttendance($id: ID!, $status: UserAttendanceStatus!) {
    updateUserAttendance(id: $id, status: $status) {
      id
      status
    }
  }
`;

export const CREATE_TEAM_ATTENDANCE_MUTATION = gql`
  mutation CreateTeamAttendance(
    $team: String!
    $date: String!
    $status: TeamAttendanceStatus!
  ) {
    createTeamAttendance(team: $team, date: $date, status: $status) {
      id
      team {
        id
      }
      status
      date
    }
  }
`;

export const UPDATE_TEAM_ATTENDANCE_MUTATION = gql`
  mutation UpdateTeamAttendance($id: ID!, $status: TeamAttendanceStatus!) {
    updateTeamAttendance(id: $id, status: $status) {
      id
      status
    }
  }
`;

// -----------------------------------------------------------------------------------------------------------------------


export const TEAM_IDIVIDUAL_GOALS_LIST_QUERY = gql`
  fragment fields on User {
    id
    firstNames
    lastName
  }

  query IndividualGoalsList($id: ID!) {
    team(id: $id) {
      individualGoals {
        id
        frequency
        period
        nextYearLink
        prevYearLink
        measurement {
          id
          measurement
          unit {
            id
            name
          }
          goalCategory {
            id
            name
            orderByString
            order
          }
        }
      }
      coach {
        ...fields
      }
      leaders {
        ...fields
      }
      members {
        ...fields
      }
    }
  }
`;

export const TEAM_IDIVIDUAL_GOALS_DATA_QUERY = gql`
  query IndividualGoalsData(
    $id: ID!
    $period: MetricPeriod!
    $periodValues: [Int!]!
    $periodKey: [String!]!
  ) {
    individualGoal(id: $id) {
      id
      frequency
      numberTo
      numberFrom
      dueDate
      period
      nextYearLink
      prevYearLink
      measurement {
        id
        measurement
        unit {
          id
          name
        }
        goalCategory {
          id
          name
          orderByString
          order
        }
      }
      userMetrics(period: $period, periodValues: $periodValues, periodKey: $periodKey) {
        id
        value
        status
        periodValue
        periodKey
        target
        user {
          id
          firstNames
          lastName
        }
      }
      periodMetrics(period: $period, periodValues: $periodValues, periodKey: $periodKey) {
        id
        status
        periodValue
        periodKey
      }
      users {
        id
        firstNames
        lastName
      }
      allUserMetrics{
        id
        value
        status
        periodValue
        periodKey
        target
        period
        user {
          id
          firstNames
          lastName
        }
      }
      allPeriodMetrics{
        id
        status
        periodValue
        periodKey
        period
      }
      measurement {
        id
        measurement
        unit {
          id
          name
        }
        goalCategory {
          id
          name
        }
      }
    }
  }
`;

export const LINK_INDIVIDUAL_GOAL_USER_MUTATION = gql`
  mutation LinkIndividualGoalUser(
    $id: ID!
    $users: [String]!
  ) {
    linkIndividualGoalUser(id: $id, users: $users) {
      users {
        id
        firstNames
        lastName
      }
    }
  }
`;

export const CREATE_PERIOD_MATRIX_MUTATION = gql`
  mutation CreatePeriodMatrix(
    $goal: String!
    $status: MetricPeriodStatus!
    $period: MetricPeriod!
    $periodValue: Int!
    $periodKey: String!
  ) {
    createIndividualPeriodMetric(goal: $goal, status: $status, period: $period, periodValue: $periodValue, periodKey: $periodKey) {
      id
      status
      periodValue
      periodKey
      period
    }
  }
`;

export const UPDATE_PERIOD_MATRIX_MUTATION = gql`
  mutation UpdatePeriodMatrix($id: ID!, $status: MetricPeriodStatus) {
    updateIndividualPeriodMetric(id: $id, status: $status) {
      id
      status
    }
  }
`;

export const CREATE_USER_MATRIX_MUTATION = gql`
  mutation CreateUserMatrix(
    $goal: String!
    $user: String!
    $status: MetricStatus!
    $period: MetricPeriod!
    $periodValue: Int!
    $periodKey: String!
    $value: Float
    $target: Float
  ) {
    createIndividualUserMetric(goal: $goal, user: $user,status: $status, period: $period, periodValue: $periodValue, periodKey: $periodKey, target: $target, value: $value) {
      id
      status
      periodValue
      periodKey
      period
      target
      value
      user {
        id
        firstNames
        lastName
      }
    }
  }
`;

export const UPDATE_USER_MATRIX_MUTATION = gql`
  mutation UpdateUserMatrix($id: ID!, $status: MetricStatus, $value: Float, $target: Float) {
    updateIndividualUserMetric(id: $id, status: $status, target: $target, value: $value) {
      id
      status
      target
      value
      user {
        id
        firstNames
        lastName
      }
    }
  }
`;
