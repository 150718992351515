import React, { useEffect, useCallback, useState } from "react";
import { useDebounce } from "use-debounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimes,
  faDotCircle,
  faSquare,
} from "@fortawesome/free-solid-svg-icons";

import classnames from "../../components/utils/classnames";
import { useMutation } from "@apollo/react-hooks";
import {
  CREATE_USER_ATTENDANCE_MUTATION,
  UPDATE_USER_ATTENDANCE_MUTATION,
  CREATE_PARTNER_ATTENDANCE_MUTATION,
  UPDATE_PARTNER_ATTENDANCE_MUTATION,
  CREATE_VISITOR_ATTENDANCE_MUTATION,
  UPDATE_VISITOR_ATTENDANCE_MUTATION,
} from "./resources/gql";
import { format, set } from "date-fns";
import displayErrorToast from "../../components/utils/displayErrorToast";
import useIndexedState from "./resources/useIndexedState";

const ATTENDANCE_STATUSES = [
  "NO_ACTION",
  "PRESENT",
  "PRESENT_BUT_LATE",
  "ON_LEAVE",
  "PLANNED_NON_ATTENDANCE",
  "ABSENT",
];

const COACH_ATTENDANCE_STATUSES = [
  "NO_ACTION",
  "PLANNED_NON_ATTENDANCE",
  "PRESENT",
  "PRESENT_BUT_LATE",
  "ON_LEAVE",
  "ABSENT",
];

const attendanceCellProps = {
  [ATTENDANCE_STATUSES[0]]: {
    classes: "bg-transparent text-grey",
    icon: faSquare,
  },
  [ATTENDANCE_STATUSES[1]]: { classes: "text-white color-selected", icon: faCheck },
  [ATTENDANCE_STATUSES[2]]: { classes: "text-white bg-color-negative", text: "L" },
  [ATTENDANCE_STATUSES[3]]: { classes: "text-white color-selected", icon: faDotCircle },
  [ATTENDANCE_STATUSES[4]]: { classes: "text-white color-selected", icon: faTimes },
  [ATTENDANCE_STATUSES[5]]: { classes: "text-white bg-color-negative", text: "A" },
};

const UserCell = ({
  day,
  objId,
  disabledDays,
  teamId,
  today,
  onCellChange,
  type,
  isCoach,
  attendance = { status: ATTENDANCE_STATUSES[0], id: null },
}) => {
  const [attendanceId, setAttendanceId] = useState(attendance.id);
  const [
    { displayedStatus, status },
    toggleStatus,
    reset,
    save,
  ] = useIndexedState(attendance.status, isCoach ? COACH_ATTENDANCE_STATUSES : ATTENDANCE_STATUSES);

  const [nextStatus] = useDebounce(displayedStatus, 1000);

  const onError = (error) => {
    reset();
    displayErrorToast(error);
  };

  const [createUserAttendance] = useMutation(CREATE_USER_ATTENDANCE_MUTATION, {
    onError,
    onCompleted: ({ createUserAttendance: { status, id } }) => {
      save(status);
      setAttendanceId(id);
    },
  });

  const [updateUserAttendance] = useMutation(UPDATE_USER_ATTENDANCE_MUTATION, {
    onError,
    onCompleted: ({ updateUserAttendance: { status } }) => {
      save(status);
    },
  });

  const [createPartnerAttendance] = useMutation(CREATE_PARTNER_ATTENDANCE_MUTATION, {
    onError,
    onCompleted: ({ createPartnerAttendance: { status, id } }) => {
      save(status);
      setAttendanceId(id);
    },
  });

  const [updatePartnerAttendance] = useMutation(UPDATE_PARTNER_ATTENDANCE_MUTATION, {
    onError,
    onCompleted: ({ updatePartnerAttendance: { status } }) => {
      save(status);
    },
  });

  const [createVisitorAttendance] = useMutation(CREATE_VISITOR_ATTENDANCE_MUTATION, {
    onError,
    onCompleted: ({ createVisitorAttendance: { status, id } }) => {
      save(status);
      setAttendanceId(id);
    },
  });

  const [updateVisitorAttendance] = useMutation(UPDATE_VISITOR_ATTENDANCE_MUTATION, {
    onError,
    onCompleted: ({ updateVisitorAttendance: { status } }) => {
      save(status);
    },
  });

  const create = useCallback(() => {
    const options = {
      status: nextStatus,
      team: teamId,
      date: format(set(today, { date: day }), "yyyy-MM-dd")
    }
    if(type === "USER") {
      createUserAttendance({
        variables: {
          user: objId,
          ...options,
        },
      });
    } else if(type === "PARTNER") {
      createPartnerAttendance({
        variables: {
          partner: objId,
          ...options
        },
      });
    } else {
      createVisitorAttendance({
        variables: {
          visitor: objId,
          ...options
        },
      });
    }
  }, [createUserAttendance,createPartnerAttendance, createVisitorAttendance, nextStatus, objId, teamId, today, day, type]);

  const update = useCallback(() => {
    const variables = {
      status: nextStatus,
      id: attendanceId,
    };
    if(type === "USER") {
      updateUserAttendance({
        variables
      });
    } else if(type === "PARTNER") {
      updatePartnerAttendance({
        variables
      });
    } else {
      updateVisitorAttendance({
        variables
      });
    }
  }, [updateUserAttendance, updatePartnerAttendance, nextStatus, attendanceId, type, updateVisitorAttendance]);

  useEffect(() => {
    if (nextStatus === status) return;
    if (attendanceId) update();
    else create();
  }, [nextStatus, status, attendanceId, create, update]);

  const { classes, icon, text } = attendanceCellProps[displayedStatus];

  const visibility = disabledDays.has(day) && "invisible";

  const className = classnames(
    "attendance-cell text-center font-weight-bold p-1 mr-2",
    classes,
    visibility
  );

  const onClick = () => {
    const response = toggleStatus();
    onCellChange(day, response, attendance, objId)

  };

  return (
    <div onClick={onClick} className={className}>
      {icon && <FontAwesomeIcon icon={icon} />}
      {!!text && text}
    </div>
  );
};

export default UserCell;
