import React, { Component, Fragment } from "react";
import { Navbar, Nav, NavDropdown, Dropdown } from "react-bootstrap";
import { withRouter } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import DropdownLink from './DropdownLink';
import { fetchUser } from '../redux/services/UserService';
import { createUserLog, updateUserLog, } from "../services/AssignedUserLogService"
import { connect } from 'react-redux';
import Icon from "../assets/cdi-icon.png";
import _ from 'lodash';
import * as Roles from '../assets/roles.json';
import CompanySelector from './CompanySelector';
import LabelComponent from "./utils/getCompanyLabel";

import { logo } from "../services/BaseService";
import PepsicoIcon from "../assets/pepsico-icon.png";

class HeaderComponent extends Component {
  constructor(props) {
    super(props)
    this.handleDrawer = this.handleDrawer.bind(this)
    this.handleCloseDrawer = this.handleCloseDrawer.bind(this)
    this.state = {
      open: false,
      selectedUserId: "",
      selectedStandInId: "",
      pages: [
        {
          url: "/dashboard",
          title: "Company",
          key: "company_screen"
        },
        {
          url: "/dashboard/structure",
          title: "Structure",
          key: "structure_screen"
        },
        {
          url: "/dashboard/business-results",
          title: "Business Results"
        },
        {
          url: "/dashboard/users",
          title: "Users",
          key: "users_screen"
        },
        {
          url: "/dashboard/goals",
          title: "True North",
          key: "true_north_screen"
        },
        {
          url: "/dashboard/measurements",
          title: "Measurements",
          key: "measurements_screen"
        },
        {
          url: "/dashboard/goal-categories",
          title: "Goal Categories",
          key: "goal_categories_screen"
        },
        {
          url: "/dashboard/units",
          title: "Units",
          key: "units_screen"
        },
        {
          url: "/dashboard/team-types",
          title: "Team Types",
          key: "team_types_screen"
        },
        {
          url: "/dashboard/meeting-view",
          title: "Meeting View",
          key: "meeting_view_screen"
        },
        {
          url: "/dashboard/multi-level-meeting-view",
          title: "MLM View",
          key: "mlm_view_screen"
        },
        {
          url: "/dashboard/skills",
          title: "Skills",
          key: "skills_screen"
        },
        {
          url: "/dashboard/modules",
          title: "Modules",
          key: "modules_screen"
        },
        {
          url: "/dashboard/criteria",
          title: "Review Criteria",
          key: "review_criteria_screen"
        },
        {
          url: "/dashboard/recognitions",
          title: "Recognition",
          key: "recognition_screen"
        },
        {
          url: "/dashboard/leadership-routines",
          title: "Leadership Routines",
          key: "leadership_routines_setup_screen"
        },
        {
          url: "/dashboard/task-list",
          title: "Task List",
          key: "task_list_screen"
        },
        {
          url: "/teams",
          title: "Teams"
        },
        {
          url: "/teams/team-identity",
          title: "Team Identity",
          key: "team_identity_screen"
        },
        {
          url: "/teams/team-partners",
          title: "Team Partners (Customer & Suppliers)",
          key: "team_partners_cs_screen"
        },
        {
          url: "/team/value-stream-partners",
          title: "Team Partners (Value Stream Partners)",
          key: "team_partners_vsp_screen"
        },
        {
          url: "/teams/team-measures",
          title: "Team Goals",
          key: "team_goals_screen"
        },
        {
          url: "/teams/individual-goals",
          title: "Individual Goals",
          key: "individual_goals_screen"
        },
        {
          url: "/what/welcome",
          title: "Welcome"
        },
        {
          url: "/what/team-attendance",
          title: "Meeting Attendance",
          key: "meeting_attendance_screen"
        },
        {
          url: "/what/team-goals",
          title: "Team Results",
          key: "team_results_screen"
        },
        {
          url: "/so-what/project-tracker",
          title: "Project Tracker",
          key: "project_tracker_screen"
        },
        {
          url: "/what/customers",
          title: "Customers",
          key: "customers_screen"
        },
        {
          url: "/what/suppliers",
          title: "Suppliers",
          key: "suppliers_screen"
        },
        {
          url: "/what/business-results",
          title: "Business Results",
          key: "business_results_screen"
        },
        {
          url: "/what/individual-results",
          title: "Individual Results",
          key: "individual_results_screen"
        },
        {
          url: "/what/coaching-reviews",
          title: "Coaching Reviews",
          key: "coaching_reviews_screen"
        },
        {
          url: "/what/coaching-reviews-dashboard",
          title: "CR-Dashboard",
          key: "coaching_reviews_dashboard_screen"
        },
        {
          url: "/what/skills-matrix",
          title: "Skills Matrix",
          key: "skills_matrix_screen"
        },
        {
          url: "/what/value-stream-partners",
          title: "Value Stream Partners",
          key: "value_stream_partners_screen"
        },
        {
          url: "/what/leadership-routines",
          title: "Leadership Routines",
          key: "leadership_routines_screen"
        },
        {
          url: "/what/scheduled-events",
          title: "Scheduled Events",
          key: "scheduled_events_screen"
        },
        {
          url: "/now-what/actions",
          title: "Next Steps",
          key: "next_steps_screen"
        },
        {
          url: "/me/my-actions",
          title: "My Next Steps",
          key: "my_next_steps_screen"
        },
        {
          url: "/now-what/escalations",
          title: "Escalations & Feedback",
          key: "escalations_screen"
        },
        {
          url: "/now-what/coaches-feedback",
          title: "Coaches Feedback",
          key: "coaches_feedback_screen"
        },
        {
          url: "/so-what/problem-solving",
          title: "Problem Solving",
          key: "problem_solving_screen"
        },
        {
          url: "/so-what/improvement-ideas",
          title: "Improvement Ideas",
          key: "impr_ideas_screen"
        },
        {
          url: "/what/values-behaviours",
          title: "Values Behaviours",
          key: "values_behaviours"
        },
        {
          url: "/now-what/team-dashboard",
          title: "Team Dashboard",
          key: "team_dashboard_screen"
        },
        {
          url: "/now-what/member-dashboard",
          title: "Member Dashboard",
          key: "member_dashboard_screen"
        },
        {
          url: "/now-what/celebrating-success",
          title: "Celebrating Success",
          key: "celebrating_success_screen"
        },
        {
          url: "",
          title: "Contact-CDI",
          key: "contact_us"
        },
        {
          url: "#",
          title: "Terms & Conditions",
          key: "terms_and_conditions"
        },
      ],
      windowWidth: 0
    }
  }

  canView(screen) {
    const roleData = Roles.default;
    const screenData = _.find(roleData, (data) => data.screen === screen);
    let canView = false;
    if (screenData.canView.includes(this.props.user.role) || screenData.canEdit.includes(this.props.user.role)) {
      canView = true;
    }
    return canView;
  }

  handleResize = (e) => {
    const { windowWidth } = this.state;
    if(windowWidth === window.innerWidth) return;
    this.setState({windowWidth: window.innerWidth});
  }

  componentDidMount() {
    this.setState({windowWidth: window.innerWidth});
    window.addEventListener('resize', this.handleResize);
    this.props.fetchUser()
    if (this.props.team.id !== 0) {
      this.getSelectedUser()
      this.getSelectedStandIn()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.team.id !== 0 && (this.props.location.pathname !== prevProps.location.pathname || this.props.team.id !== prevProps.team.id)) {
      this.getSelectedUser()
      this.getSelectedStandIn()
    }
  }

  getLabelText = key => <LabelComponent val={key}/>

  getSelectedUser = () => {
    const { combinedMembers, assignedUserLogs } = this.props.team

    if (combinedMembers.length > 0) {
      let userId = combinedMembers[0] ? combinedMembers[0].id : combinedMembers[1].id
      const assignedUser = assignedUserLogs.find(log => log && log.url === window.location.pathname)

      if (assignedUser) {
        userId = assignedUser.user.id
      }
      this.setState({
        selectedUserId: userId
      })
    }
  }

  getSelectedStandIn = () => {
    const { combinedMembers, assignedUserLogs } = this.props.team

    if (combinedMembers.length > 0) {
      let standInId = combinedMembers[0] ? combinedMembers[0].id : combinedMembers[1].id
      const assignedUser = assignedUserLogs.find(log => log && log.url === window.location.pathname)
      if (assignedUser && assignedUser.standIn) {
        standInId = assignedUser.standIn.id
      }
      this.setState({
        selectedStandInId: standInId
      })
    }
  }

  logout = () => {
    localStorage.clear()

    window.location.href = "/"
  }

  getPageTitle = () => {
    const activeUrl = window.location.pathname
    const { pages } = this.state
    const page = pages.filter(page => {
      return page.url === activeUrl
    })[0]
    if(!page) return "No Data";

    return page.key ? this.getLabelText(page.key) : page.title
  }

  setAssignedUser = async value => {
    const { selectedStandInId } = this.state;
    const { assignedUserLogs, id } = this.props.team
    const assignedUser = assignedUserLogs.find(log => log && log.url === window.location.pathname)

    try {
      this.setState({
        selectedUserId: value,
      })
      if (assignedUser) {
        await updateUserLog(assignedUser.id, value, selectedStandInId)
        assignedUser.user.id = value
      } else {
        const response = await createUserLog(id, value, selectedStandInId, window.location.pathname)
        assignedUserLogs.push(response.data.createAssignedUserLog)
      }
    } catch (err) {
      console.log(err)
    }
  }

  setAssignedStandIn = async value => {
    const { selectedUserId } = this.state;
    const { assignedUserLogs, id } = this.props.team
    const assignedUser = assignedUserLogs.find(log => log && log.url === window.location.pathname)

    try {
      this.setState({
        selectedStandInId: value,
      })
      if (assignedUser) {
        await updateUserLog(assignedUser.id, selectedUserId, value)
        assignedUser.standIn = {id: value}
      } else {
        const response = await createUserLog(id, selectedUserId, value, window.location.pathname)
        assignedUserLogs.push(response.data.createAssignedUserLog)
      }
    } catch (err) {
      console.log(err)
    }
  }

  isActiveItem = (type) => {
    let section = this.props.location.pathname

    section.indexOf(1)

    section.toLowerCase()

    section = section.split("/")[1]

    return section === type
  }

  goBack = () => {

  }

  handleDrawer() {
    this.setState({ open: !this.state.open})
  }

  handleCloseDrawer = (url) => {
    this.props.history.push(url)
    this.setState({ open: false })
  }

  customRedirect = (url) => {
    this.props.history.push(url)
  }

  renderMenuComponent = () => {
    const { team, user } = this.props;
    const { companies } = user;
    const selectedCompanyId = localStorage.getItem("companyId");
    const company = companies ? companies.find(({ id }) => id === selectedCompanyId) : null;

    return (
      <Fragment>
        <li
          className={"nav-item" + (this.props.teams.length < 1 ? " ml-4 mr-0" : "")}>
          <Dropdown>
            <Dropdown.Toggle className={"nav-link" + (this.isActiveItem("dashboard") || this.isActiveItem("teams") || this.isActiveItem("team") ? " nav-pill" : "")} variant="none" id="dropdown-basic">
              {this.getLabelText("setup")}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {this.canView("Company") && (
                <DropdownLink href="/dashboard">{this.getLabelText("company_screen")}</DropdownLink>
              )}
              {this.canView("Structure") && (
                <DropdownLink href="/dashboard/structure">{this.getLabelText("structure_screen")}</DropdownLink>
              )}
              {this.canView("Users") && (
                <DropdownLink href="/dashboard/users">{this.getLabelText("users_screen")}</DropdownLink>
              )}
              {user && company && company.hasUsers && company.hasMeasurements && this.canView("TrueNorth") && (
                <DropdownLink href="/dashboard/goals">{this.getLabelText("true_north_screen")}</DropdownLink>
              )}
              {user && company && this.canView("Teams") && (
                  <DropdownLink href="/dashboard/teams">{this.getLabelText("teams")}</DropdownLink>
              )}
              <DropdownLink href="/teams/team-identity">{this.getLabelText("team_identity_screen")}</DropdownLink>
              {this.props.team.id !== 0 && (
                  <DropdownLink href="/teams/team-partners">{this.getLabelText("team_partners_cs_screen")}</DropdownLink>
              )}
              {this.props.team.id !== 0 && (
                  <DropdownLink href="/team/value-stream-partners">{this.getLabelText("team_partners_vsp_screen")}</DropdownLink>
              )}
              {user && team.id !== 0 && company && company.hasMeasurements && company.hasUsers && this.canView("TeamMeasures") && (
                  <DropdownLink href="/teams/team-measures">{this.getLabelText("team_goals_screen")}</DropdownLink>
              )}
              {user && team.id !== 0 && company && company.hasMeasurements && company.hasUsers && this.canView("IndividualGoals") && (
                  <DropdownLink href="/teams/individual-goals">{this.getLabelText("individual_goals_screen")}</DropdownLink>
              )}
              {user && team.id !== 0 && company && company.hasMeasurements && company.hasUsers && this.canView("MeetingView") && (
                  <DropdownLink href="/dashboard/meeting-view">{this.getLabelText("meeting_view_screen")}</DropdownLink>
              )}
              {user && team.id !== 0 && company && company.hasMeasurements && company.hasUsers && this.canView("MeetingView") && (
                  <DropdownLink href="/dashboard/multi-level-meeting-view">{this.getLabelText("mlm_view_screen")}</DropdownLink>
              )}
              {this.canView("Measurements") && (
                <DropdownLink href="/dashboard/measurements">{this.getLabelText("measurements_screen")}</DropdownLink>
              )}
              <DropdownLink href="/dashboard/skills">{this.getLabelText("skills_screen")}</DropdownLink>
              {user && this.canView("LeadershipRoutines") && (
                  <DropdownLink href="/dashboard/leadership-routines">{this.getLabelText("leadership_routines_setup_screen")}</DropdownLink>
              )}
              <DropdownLink href="/dashboard/task-list">{this.getLabelText("task_list_screen")}</DropdownLink>
              <DropdownLink href="/dashboard/recognitions">{this.getLabelText("recognition_screen")}</DropdownLink>
              {this.canView("TeamTypes") && (
                <DropdownLink href="/dashboard/team-types">{this.getLabelText("team_types_screen")}</DropdownLink>
              )}
              {this.canView("GoalCategories") && (
                <DropdownLink href="/dashboard/goal-categories">{this.getLabelText("goal_categories_screen")}</DropdownLink>
              )}
              {this.canView("Units") && (
                <DropdownLink href="/dashboard/units">{this.getLabelText("units_screen")}</DropdownLink>
              )}

              {/*{this.canView("Modules") && (*/}
              {/*  <DropdownLink href="/dashboard/modules">{this.getLabelText("modules_screen")}</DropdownLink>*/}
              {/*)}*/}

              {this.canView("Criteria") && (
                <DropdownLink href="/dashboard/criteria">{this.getLabelText("review_criteria_screen")}</DropdownLink>
              )}
              {this.canView("ImplementationModule") && (
                  <DropdownLink href="/dashboard/implementation-module">{this.getLabelText("implementation_module_screen")}</DropdownLink>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </li>
        {this.props.teams.length > 0 && (
          <Fragment>
            <li
              className="nav-item">
              <Dropdown>
                <Dropdown.Toggle className={"nav-link" + (this.isActiveItem("what") || this.props.location.pathname === "/what/welcome" ? " nav-pill" : "")} variant="none" id="dropdown-basic">
                  {this.getLabelText("what")}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <DropdownLink href="/what/welcome">{this.getLabelText("welcome_screen")}</DropdownLink>
                  <DropdownLink href="/what/team-attendance">{this.getLabelText("meeting_attendance_screen")}</DropdownLink>
                    {user && team.id !== 0 && company && team.hasTeamGoals && (
                      <DropdownLink href="/what/team-goals">{this.getLabelText("team_results_screen")}</DropdownLink>
                    )}
                    {user && team.id !== 0 && company && team.hasIndividualGoals && (
                        <DropdownLink href="/what/individual-results">{this.getLabelText("individual_results_screen")}</DropdownLink>
                    )}
                    {this.props.team.id !== 0 && (
                      <>
                        {user && team.id !== 0 && company && team.hasCustomers && (
                          <DropdownLink href="/what/customers">{this.getLabelText("customers_screen")}</DropdownLink>
                        )}
                        {user && team.id !== 0 && company && team.hasSuppliers && (
                          <DropdownLink href="/what/suppliers">{this.getLabelText("suppliers_screen")}</DropdownLink>
                        )}
                      </>
                    )}
                    {team.id !== 0 && team.hasValuePartners && (
                        <DropdownLink href="/what/value-stream-partners">{this.getLabelText("value_stream_partners_screen")}</DropdownLink>
                    )}
                    <DropdownLink href="/what/scheduled-events">{this.getLabelText("scheduled_events_screen")}</DropdownLink>
                    {team.id !== 0 && team.department.departmentType.level !== "LVL1" && company && company.hasRoutines && (
                        <DropdownLink href="/what/leadership-routines">{this.getLabelText("leadership_routines_screen")}</DropdownLink>
                    )}
                    {user && team.id !== 0 && team.hasTaskTracker && (
                        <DropdownLink href="/what/task-tracker">{this.getLabelText("task_tracker_screen")}</DropdownLink>
                    )}
                    {user && team.id !== 0 && team.hasImplementationModule && (
                        <DropdownLink href="/what/implementation-tracker">{this.getLabelText("implementation_tracker_screen")}</DropdownLink>
                    )}
                    {user && team.id !== 0 && company && company.hasCompanyValues && (
                      <DropdownLink href="/what/values-behaviours">{this.getLabelText("values_behaviours")}</DropdownLink>
                    )}
                    {user && team.id !== 0 && company && company.hasBusinessGoals && (
                      <DropdownLink href="/what/business-results">{this.getLabelText("business_results_screen")}</DropdownLink>
                    )}
                    {user && team.id !== 0 && company && company.hasSkills && (
                      <DropdownLink href="/what/skills-matrix">{this.getLabelText("skills_matrix_screen")}</DropdownLink>
                    )}
                    {user && team.id !== 0 && company && company.hasReviewCriteria && (
                      <DropdownLink href="/what/coaching-reviews">{this.getLabelText("coaching_reviews_screen")}</DropdownLink>
                    )}
                    {user && team.id !== 0 && company && company.hasReviewCriteria && company.hasBusinessGoals && (
                      <DropdownLink href="/what/coaching-reviews-dashboard">{this.getLabelText("coaching_reviews_dashboard_screen")}</DropdownLink>
                    )}
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li
              className="nav-item">
              <Dropdown>
                <Dropdown.Toggle className={"nav-link" + (this.isActiveItem("so-what") ? " nav-pill" : "")} variant="none" id="dropdown-basic">
                  {this.getLabelText("so_what")}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <DropdownLink href="/so-what/problem-solving">{this.getLabelText("problem_solving_screen")}</DropdownLink>
                  <DropdownLink href="/so-what/improvement-ideas">{this.getLabelText("impr_ideas_screen")}</DropdownLink>
                  <DropdownLink href="/so-what/project-tracker">{this.getLabelText("project_tracker_screen")}</DropdownLink>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li
              className="nav-item">
              <Dropdown>
                <Dropdown.Toggle className={"nav-link" + (this.isActiveItem("now-what") ? " nav-pill" : "")} variant="none" id="dropdown-basic">
                  {this.getLabelText("now_what")}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <DropdownLink href="/now-what/actions">{this.getLabelText("next_steps_screen")}</DropdownLink>
                  <DropdownLink href="/now-what/escalations">{this.getLabelText("escalations_screen")}</DropdownLink>
                  <DropdownLink href="/now-what/coaches-feedback">{this.getLabelText("coaches_feedback_screen")}</DropdownLink>
                  <DropdownLink href="/now-what/team-dashboard">{this.getLabelText("team_dashboard_screen")}</DropdownLink>
                  <DropdownLink href="/now-what/member-dashboard">{this.getLabelText("member_dashboard_screen")}</DropdownLink>
                  <DropdownLink href="/now-what/celebrating-success">{this.getLabelText("celebrating_success_screen")}</DropdownLink>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </Fragment>
        )}
        <li
          className={"nav-item" + (this.props.teams.length < 1 ? " ml-4 mr-0" : "")}>
          <Dropdown>
            <Dropdown.Toggle className={"nav-link" + (this.isActiveItem("help") ? " nav-pill" : "")} variant="none" id="dropdown-basic">
              {this.getLabelText("help")}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {user && company && company.supportEmail && company.consultant ? (
                <a className={"dropdown-item"} href={`mailto:${company.supportEmail}?cc=${company.consultant.email}`}>{this.getLabelText("contact_us")}</a>
              ) : user && company && company.consultant ? (
                <a className={"dropdown-item"} href={`mailto:support@cdiconnect.com?cc=${company.consultant.email}`}>{this.getLabelText("contact_us")}</a>
              ) : (
                <a className={"dropdown-item"} href={`mailto:support@cdiconnect.com`}>{this.getLabelText("contact_us")}</a>
              )}
              <a className={"dropdown-item"} href="terms-conditions-privacy-policy.pdf" target="_blank">{this.getLabelText("terms_and_conditions")}</a>
            </Dropdown.Menu>
          </Dropdown>
        </li>
        <li
          className={"nav-item" + (this.props.teams.length < 1 ? " ml-4 mr-0" : "")}>
          <Dropdown>
            <Dropdown.Toggle className={"nav-link" + (this.isActiveItem("me") ? " nav-pill" : "")} variant="none" id="dropdown-basic">
              <b className={"user-name"}>{this.props.user.nickName || this.props.user.firstNames}</b>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <DropdownLink href="/me/my-actions">{this.getLabelText("my_next_steps_screen")}</DropdownLink>
              <b className="dropdown-item logout" onClick={this.logout}>{this.getLabelText("logout")}</b>
            </Dropdown.Menu>
          </Dropdown>
        </li>
      </Fragment>
    )
  }

  render() {
    const titleStyle = {

      float: "left"
    }

    const {  team, user } = this.props;
    const { companies } = user;
    const selectedCompanyId = localStorage.getItem("companyId");
    const company = companies ? companies.find(({ id }) => id === selectedCompanyId) : null;
    //console.log(!!company)

    return (
      <Fragment>
        {(!this.props.presentation.active || (this.props.presentation.active && this.props.presentation.paused)) && (
          <Nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="menu-bar">
              <a className="navbar-brand" href="/">
                <img src={logo === "pepsico" ? PepsicoIcon : Icon} alt="logo" className="header-ico" />
              </a>
              <div className={"collapse navbar-collapse"} id="navbarNav">
                <ul className="navbar-nav" style={titleStyle}>
                  {this.renderMenuComponent()}
                </ul>
                {/*{this.state.windowWidth > 991 ? <div id="google_translate_element"></div> : null}*/}
              </div>
              <div className={'float-right company-name'}>
                <CompanySelector/>
              </div>
            </div>
            <div className="d-lg-none col-md-10 show-up company-name">
              <CompanySelector/>
            </div>
            <div className="d-lg-none">
                <div className="menu-navbar">
                  <div className="col-md-12 menu-col">
                    <Router>
                      <Navbar
                          onToggle={this.handleDrawer}
                          expanded={this.state.open}
                          className={"menu"+ (this.props.teams.length < 1 ? " ml-4 mr-0" : "")}
                          bg="dark"
                          variant="dark"
                          expand="lg"
                          sticky="top">
                          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                          <Navbar.Collapse id="basic-navbar-nav">
                            <Nav>
                              {this.props.teams.length > 0 && (
                                <NavDropdown
                                  title={this.props.user.nickName || this.props.user.firstNames}
                                  className={"dropdown-toggle menu-styles nav-link" + (this.isActiveItem("help") ? " pill-menu" : "")}
                                  variant="none" id="dropdown-basic"
                                  data-toggle="dropdown"
                                >
                                  <NavDropdown.Item
                                    onClick={this.logout}
                                  >
                                    <b className="dropdown-item logout"
                                    >
                                      {this.getLabelText("logout")}
                                    </b>
                                  </NavDropdown.Item>
                                </NavDropdown>
                              )}
                              <NavDropdown
                                title="Setup"
                                className={"dropdown-toggle menu-styles nav-link" + (this.isActiveItem("dashboard") || this.isActiveItem("teams") ? " pill-menu" : "")}
                                variant="none"
                                id="collasible-nav-dropdown"
                                data-toggle="dropdown"
                              >
                                {this.canView("Company") && (
                                  <NavDropdown.Item
                                    onClick={() => this.handleCloseDrawer("/dashboard")}
                                  >
                                    {this.getLabelText("company_screen")}
                                  </NavDropdown.Item>
                                )}
                                {this.canView("Structure") && (
                                  <NavDropdown.Item
                                    onClick={() => this.handleCloseDrawer("/dashboard/structure")}
                                  >
                                    {this.getLabelText("structure_screen")}
                                  </NavDropdown.Item>
                                )}
                                {this.canView("Users") && (
                                  <NavDropdown.Item
                                    onClick={() => this.handleCloseDrawer("/dashboard/users")}
                                  >
                                    {this.getLabelText("users_screen")}
                                  </NavDropdown.Item>
                                )}
                                {user && company && company.hasUsers && company.hasMeasurements && this.canView("TrueNorth") && (
                                  <NavDropdown.Item
                                    onClick={() => this.handleCloseDrawer("/dashboard/goals")}
                                  >
                                    {this.getLabelText("true_north_screen")}
                                  </NavDropdown.Item>
                                )}
                                <NavDropdown.Item
                                    onClick={() => this.handleCloseDrawer("/teams/team-identity")}
                                >
                                  {this.getLabelText("team_identity_screen")}
                                </NavDropdown.Item>
                                {this.props.team.id !== 0 && (
                                    <NavDropdown.Item
                                        onClick={() => this.handleCloseDrawer("/teams/team-partners")}
                                    >
                                      {this.getLabelText("team_partners_cs_screen")}
                                    </NavDropdown.Item>
                                )}
                                {this.props.team.id !== 0 && (
                                    <NavDropdown.Item
                                        onClick={() => this.handleCloseDrawer("/team/value-stream-partners")}
                                    >
                                      {this.getLabelText("team_partners_vsp_screen")}
                                    </NavDropdown.Item>
                                )}
                                {user && team.id !== 0 && company && company.hasMeasurements && company.hasUsers && this.canView("TeamMeasures") && (
                                    <NavDropdown.Item
                                        onClick={() => this.handleCloseDrawer("/teams/team-measures")}
                                    >
                                      {this.getLabelText("team_goals_screen")}
                                    </NavDropdown.Item>
                                )}
                                {user && team.id !== 0 && company && company.hasMeasurements && company.hasUsers && this.canView("IndividualGoals") && (
                                    <NavDropdown.Item
                                        onClick={() => this.handleCloseDrawer("/teams/individual-goals")}
                                    >
                                      {this.getLabelText("individual_goals_screen")}
                                    </NavDropdown.Item>
                                )}
                                {user && team.id !== 0 && company && company.hasMeasurements && company.hasUsers && this.canView("MeetingView") && (
                                    <NavDropdown.Item
                                        onClick={() => this.handleCloseDrawer("/dashboard/meeting-view")}
                                    >
                                      {this.getLabelText("meeting_view_screen")}
                                    </NavDropdown.Item>
                                )}
                                {user && team.id !== 0 && company && company.hasMeasurements && company.hasUsers && this.canView("MeetingView") && (
                                    <NavDropdown.Item
                                        onClick={() => this.handleCloseDrawer("/dashboard/multi-level-meeting-view")}
                                    >
                                      {this.getLabelText("mlm_view_screen")}
                                    </NavDropdown.Item>
                                )}
                                {this.canView("Measurements") && (
                                    <NavDropdown.Item
                                        onClick={() => this.handleCloseDrawer("/dashboard/measurements")}
                                    >
                                      {this.getLabelText("measurements_screen")}
                                    </NavDropdown.Item>
                                )}
                                <NavDropdown.Item
                                    onClick={() => this.handleCloseDrawer("/dashboard/skills")}
                                >
                                  {this.getLabelText("skills_screen")}
                                </NavDropdown.Item>
                                {user && this.canView("LeadershipRoutines") && (
                                    <NavDropdown.Item
                                        onClick={() => this.handleCloseDrawer("/dashboard/leadership-routines")}
                                    >
                                      {this.getLabelText("leadership_routines_setup_screen")}
                                    </NavDropdown.Item>
                                )}
                                <NavDropdown.Item
                                    onClick={() => this.handleCloseDrawer("/dashboard/recognitions")}
                                >
                                  {this.getLabelText("recognition_screen")}
                                </NavDropdown.Item>
                                {this.canView("TeamTypes") && (
                                    <NavDropdown.Item
                                        onClick={() => this.handleCloseDrawer("/dashboard/team-types")}
                                    >
                                      {this.getLabelText("team_types_screen")}
                                    </NavDropdown.Item>
                                )}
                                {this.canView("GoalCategories") && (
                                    <NavDropdown.Item
                                        onClick={() => this.handleCloseDrawer("/dashboard/goal-categories")}
                                    >
                                      {this.getLabelText("goal_categories_screen")}
                                    </NavDropdown.Item>
                                )}
                                {this.canView("Units") && (
                                    <NavDropdown.Item
                                        onClick={() => this.handleCloseDrawer("/dashboard/units")}
                                    >
                                      {this.getLabelText("units_screen")}
                                    </NavDropdown.Item>
                                )}
                                {this.canView("Modules") && (
                                    <NavDropdown.Item
                                        onClick={() => this.handleCloseDrawer("/dashboard/modules")}
                                    >
                                      {this.getLabelText("modules_screen")}
                                    </NavDropdown.Item>
                                )}
                                {this.canView("Criteria") && (
                                    <NavDropdown.Item
                                        onClick={() => this.handleCloseDrawer("/dashboard/criteria")}
                                    >
                                      {this.getLabelText("review_criteria_screen")}
                                    </NavDropdown.Item>
                                )}
                              </NavDropdown>
                              {this.props.teams.length > 0 && (
                                <NavDropdown
                                  title="What?"
                                  className={"dropdown-toggle menu-styles nav-link" + (this.isActiveItem("what") || this.props.location.pathname === "/what/welcome" ? " pill-menu" : "")}
                                  variant="none"
                                  id="dropdown-basic"
                                  data-toggle="dropdown"
                                >
                                  <NavDropdown.Item
                                    onClick={() => this.handleCloseDrawer("/what/welcome")}
                                  >
                                    {this.getLabelText("welcome_screen")}
                                  </NavDropdown.Item>
                                  <NavDropdown.Item
                                    onClick={() => this.handleCloseDrawer("/what/team-attendance")}
                                  >
                                    {this.getLabelText("meeting_attendance_screen")}
                                  </NavDropdown.Item>
                                  {user && team.id !== 0 && company && team.hasTeamGoals && (
                                    <NavDropdown.Item
                                      onClick={() => this.handleCloseDrawer("/what/team-goals")}
                                    >
                                      {this.getLabelText("team_results_screen")}
                                    </NavDropdown.Item>
                                  )}
                                  {user && team.id !== 0 && company && team.hasIndividualGoals && (
                                      <NavDropdown.Item
                                          onClick={() => this.handleCloseDrawer("/what/individual-results")}
                                      >
                                        {this.getLabelText("individual_results_screen")}
                                      </NavDropdown.Item>
                                  )}
                                  {this.props.team.id !== 0 && (
                                    <>
                                    {user && team.id !== 0 && company && team.hasCustomers && (
                                      <NavDropdown.Item
                                        onClick={() => this.handleCloseDrawer("/what/customers")}
                                      >
                                        {this.getLabelText("customers_screen")}
                                      </NavDropdown.Item>
                                    )}
                                    {user && team.id !== 0 && company && team.hasSuppliers && (
                                      <NavDropdown.Item
                                        onClick={() => this.handleCloseDrawer("/what/suppliers")}
                                      >
                                        {this.getLabelText("suppliers_screen")}
                                      </NavDropdown.Item>
                                    )}
                                    </>
                                  )}
                                  {team.id !== 0 && team.hasValuePartners && (
                                      <NavDropdown.Item
                                          onClick={() => this.handleCloseDrawer("/what/value-stream-partners")}
                                      >
                                        {this.getLabelText("value_stream_partners_screen")}
                                      </NavDropdown.Item>
                                  )}
                                  <NavDropdown.Item
                                      onClick={() => this.handleCloseDrawer("/what/scheduled-events")}
                                  >
                                    {this.getLabelText("scheduled_events_screen")}
                                  </NavDropdown.Item>
                                  {team.id !== 0 && team.department.departmentType.level !== "LVL1" && company && company.hasRoutines && (
                                      <NavDropdown.Item
                                          onClick={() => this.handleCloseDrawer("/what/leadership-routines")}
                                      >
                                        {this.getLabelText("leadership_routines_screen")}
                                      </NavDropdown.Item>
                                  )}
                                  {user && team.id !== 0 && company && company.hasCompanyValues && (
                                    <NavDropdown.Item
                                      onClick={() => this.handleCloseDrawer("/what/values-behaviours")}
                                    >
                                      {this.getLabelText("values_behaviours")}
                                    </NavDropdown.Item>
                                  )}
                                  {user && team.id !== 0 && company && company.hasBusinessGoals && (
                                    <NavDropdown.Item
                                      onClick={() => this.handleCloseDrawer("/what/business-results")}
                                    >
                                      {this.getLabelText("business_results_screen")}
                                    </NavDropdown.Item>
                                  )}
                                  {user && team.id !== 0 && company && company.hasSkills && (
                                    <NavDropdown.Item
                                      onClick={() => this.handleCloseDrawer("/what/skills-matrix")}
                                    >
                                      {this.getLabelText("skills_matrix_screen")}
                                    </NavDropdown.Item>
                                  )}
                                  {user && team.id !== 0 && company && company.hasReviewCriteria && (
                                    <NavDropdown.Item
                                      onClick={() => this.handleCloseDrawer("/what/coaching-reviews")}
                                    >
                                      {this.getLabelText("coaching_reviews_screen")}
                                    </NavDropdown.Item>
                                  )}
                                  {user && team.id !== 0 && company && company.hasReviewCriteria && (
                                    <NavDropdown.Item
                                      onClick={() => this.handleCloseDrawer("/what/coaching-reviews-dashboard")}
                                    >
                                      {this.getLabelText("coaching_reviews_dashboard_screen")}
                                    </NavDropdown.Item>
                                  )}
                                </NavDropdown>
                              )}
                              {this.props.teams.length > 0 && (
                                <NavDropdown
                                    title="So What?"
                                    className={"dropdown-toggle menu-styles nav-link" + (this.isActiveItem("so-what") ? " pill-menu" : "")}
                                    variant="none"
                                    id="dropdown-basic"
                                    data-toggle="dropdown"
                                >
                                  <NavDropdown.Item
                                      onClick={() => this.handleCloseDrawer("/so-what/problem-solving")}
                                  >
                                    {this.getLabelText("problem_solving_screen")}
                                  </NavDropdown.Item>
                                  <NavDropdown.Item
                                      onClick={() => this.handleCloseDrawer("/so-what/improvement-ideas")}
                                  >
                                    {this.getLabelText("impr_ideas_screen")}
                                  </NavDropdown.Item>
                                  <NavDropdown.Item
                                      onClick={() => this.handleCloseDrawer("/so-what/project-tracker")}
                                  >
                                    {this.getLabelText("project_tracker_screen")}
                                  </NavDropdown.Item>
                                </NavDropdown>
                              )}
                              {this.props.teams.length > 0 && (
                                <NavDropdown
                                    title="Now What?"
                                    className={"dropdown-toggle menu-styles nav-link" + (this.isActiveItem("now-what") ? " pill-menu" : "")}
                                    variant="none" id="dropdown-basic"
                                    data-toggle="dropdown"
                                >
                                  <NavDropdown.Item
                                      onClick={() => this.handleCloseDrawer("/now-what/actions")}
                                  >
                                    {this.getLabelText("next_steps_screen")}
                                  </NavDropdown.Item>
                                  <NavDropdown.Item
                                      onClick={() => this.handleCloseDrawer("/now-what/escalations")}
                                  >
                                    {this.getLabelText("escalations_screen")}
                                  </NavDropdown.Item>
                                  <NavDropdown.Item
                                      onClick={() => this.handleCloseDrawer("/now-what/coaches-feedback")}
                                  >
                                    {this.getLabelText("coaches_feedback_screen")}
                                  </NavDropdown.Item>
                                  <NavDropdown.Item
                                      onClick={() => this.handleCloseDrawer("/now-what/team-dashboard")}
                                  >
                                    {this.getLabelText("team_dashboard_screen")}
                                  </NavDropdown.Item>
                                  <NavDropdown.Item
                                      onClick={() => this.handleCloseDrawer("/now-what/member-dashboard")}
                                  >
                                    {this.getLabelText("member_dashboard_screen")}
                                  </NavDropdown.Item>
                                  <NavDropdown.Item
                                      onClick={() => this.handleCloseDrawer("/now-what/celebrating-success")}
                                  >
                                    {this.getLabelText("celebrating_success_screen")}
                                  </NavDropdown.Item>
                                </NavDropdown>
                              )}
                              {this.props.teams.length > 0 && (
                                <NavDropdown
                                  title="HELP"
                                  className={"dropdown-toggle menu-styles nav-link" + (this.isActiveItem("help") ? " pill-menu" : "")}
                                  variant="none" id="dropdown-basic"
                                  data-toggle="dropdown"
                                >
                                  {user && company && company.supportEmail && company.consultant ? (
                                    <a className={"dropdown-item"} href={`mailto:${company.supportEmail}?cc=${company.consultant.email}`}>{this.getLabelText("contact_us")}</a>
                                  ) : user && company && company.consultant ? (
                                    <a className={"dropdown-item"} href={`mailto:support@cdiconnect.com?cc=${company.consultant.email}`}>{this.getLabelText("contact_us")}</a>
                                  ) : (
                                    <a className={"dropdown-item"} href={`mailto:support@cdiconnect.com`}>{this.getLabelText("contact_us")}</a>
                                  )}
                                  <a
                                      className={"dropdown-item"}
                                      href="terms-conditions-privacy-policy.pdf"
                                      target="_blank">
                                    {this.getLabelText("terms_and_conditions")}
                                  </a>
                                </NavDropdown>
                              )}
                                {/*this.state.windowWidth < 992 ? <div className="google-trans" id="google_translate_element"></div> : null*/}
                            </Nav>
                          </Navbar.Collapse>
                        <br/>
                      </Navbar>
                    </Router>
                  </div>
                </div>
            </div>
          </Nav>
        )}
        {/*window.location.pathname !== "/what/individual-results" && window.location.pathname !== "/what/business-results" && window.location.pathname !== "/what/team-goals" && (
          <Row className="w-100 app-title-row">
            <Col xl={4} md={6}>
              <table className="w-100 h-100">
                <tbody>
                  <tr>
                    <td className="text-left" valign="middle">
                      <h4 className="app-title ml-2 d-inline mr-3" style={titleStyle}>
                        {this.getPageTitle()}
                      </h4>
                      {!this.isActiveItem("dashboard") && !this.isActiveItem("teams") && (
                        <RealtimeRefreshComponent
                          refresh={refresh}
                          hasNewData={hasNewData}
                          toggleRefresh={this.props.toggleRefresh}
                        />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col xl={4} md={6} className="text-center">
              {!this.isActiveItem("dashboard") && !this.isActiveItem("teams") && this.props.team.combinedMembers[0] !== null && this.props.team.combinedMembers.length > 0 && (
                <table className="w-100 h-100">
                  <tbody>
                    <tr>
                      <td width="10%" valign="middle">
                        <h6 className="app-title">Owners</h6>
                      </td>
                      <td width="45%" valign="middle" className="pl-1">
                        <select className="form-control" value={this.state.selectedUserId} onChange={(e) => this.setAssignedUser(e.target.value)}>
                          {this.props.team.combinedMembers.map((member, i) => (
                            <option value={member.id} key={i}>{member.firstNames} {member.lastName}</option>
                          ))}
                        </select>
                      </td>
                      <td width="45%" valign="middle" className="pl-1">
                        <select className="form-control" value={this.state.selectedStandInId} onChange={(e) => this.setAssignedStandIn(e.target.value)}>
                          {this.props.team.combinedMembers.map((member, i) => (
                            <option value={member.id} key={i}>{member.firstNames} {member.lastName}</option>
                          ))}
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </Col>
            <Col xl={4} md={12} className="text-right">
              {this.isActiveItem("dashboard") || this.isActiveItem("teams")
                ? null
                : (<table className="w-100 h-100">
                    <tbody>
                      <tr>
                        <td valign="middle" className="p-2">
                          <span style={signOutStyle}>
                            {window.location.pathname === "/so-what/problem-solving"
                              ? (
                                <button className="nav-pill-btn-1 bg-grey no-outline rounded-pill border-0 outline-0 px-2 py-0 cdi-blue-txt-1 font-weight-bold mr-2" onClick={() => this.props.history.goBack()}>

                                  <img src={BackBlue} alt="Back" className="backBtn" />
                                </button>
                              ) : (
                                <Link to="/so-what/problem-solving" className="nav-pill-btn bg-grey no-outline rounded-pill border-0 outline-0 px-2 py-0 font-weight-bold cdi-blue-txt mr-2">
                                  <span className="cdi-blue-txt m-0 font-weight-bold" >PS</span>
                                </Link>
                              )
                            }
                            {window.location.pathname === "/now-what/actions"
                              ? (
                                <button className="nav-pill-btn bg-grey no-outline rounded-pill border-0 outline-0 px-2 py-0 cdi-blue-txt-1 font-weight-bold mr-2" onClick={() => this.props.history.goBack()}>

                                  <img src={BackBlue} alt="Back" className="backBtn" />
                                </button>
                              ) : (
                                <Link to="/now-what/actions" className="nav-pill-btn bg-grey no-outline rounded-pill border-0 outline-0 px-2 py-0 font-weight-bold cdi-blue-txt mr-2">
                                  <span className="cdi-blue-txt m-0 font-weight-bold">NS</span>
                                </Link>
                              )
                            }
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>)
              }
            </Col>
          </Row>
        )*/}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  team: state.teamsReducer.selectedTeam,
  isLoading: state.userReducer.isLoading,
  teams: state.teamsReducer.teams,
  presentation: state.presentationReducer
});

const mapDispatchToProps = {
  fetchUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderComponent));
