import React, { Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import Tile from "./Tile";
import { mapTileData, checkLatestPeriod } from "../resources/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight, faSpinner } from "@fortawesome/free-solid-svg-icons";

const Container = ({ successes, period, firstMonth, setSelectedSuccess, setShowModal, selectedSuccess, users, setNext, loadingContent, getLabelText }) => {
  const data = mapTileData(successes, period, firstMonth)
  const isLatestPeriod = checkLatestPeriod(period, firstMonth)

  const setData = (item) => {
    setSelectedSuccess(item.data ? item.data : {
      id: 0,
      period: item.period,
      month: item.month,
      user: users[0]
    })
    setShowModal(true)
  }
  return (
    <Fragment>
      {loadingContent && (
        <Fragment>
          <div className="page-loader">
            <div className="loader-box text-center">
              <FontAwesomeIcon className="text-white fa-spin fa-3x" icon={faSpinner} />
              <p className="text-white mt-4"><strong>{getLabelText("loading")}</strong></p>
            </div>
          </div>
        </Fragment>
      )}
      <Row>
        <Col xs={1}>
          <table className="w-100 h-100">
            <tbody>
              <tr>
                <td valign="middle" className="text-center">
                  <button
                    className="button-unstyled d-inline text-white"
                    style={{ color: "#fff!important" }}
                    onClick={() => setNext(true)}
                  >
                    <FontAwesomeIcon
                      className="text-grey"
                      size="4x"
                      icon={faCaretLeft}
                    />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
        <Col>
          <Row className={loadingContent ? "disabled" : null}>
            {data.map((item, i) => <Tile key={i} item={item} setData={setData} disabled={!!selectedSuccess} getLabelText={getLabelText} />)}
          </Row>
        </Col>
        <Col xs={1}>
          <table className="w-100 h-100">
            <tbody>
              <tr>
                <td valign="middle" className="text-center">
                  {!isLatestPeriod ? (
                    <button
                      className="button-unstyled d-inline text-white"
                      style={{ color: "#fff!important" }}
                      onClick={() => setNext()}
                    >
                      <FontAwesomeIcon
                        className="text-grey"
                        size="4x"
                        icon={faCaretRight}
                      />
                    </button>
                  ) : null}
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
    </Fragment>
  )
}

export default Container;
