import React, { createContext, useContext } from 'react';

export const initial = {
  active: false,
  pasued: false,
  type: "",
  schedule: [],
  index: 0,
  subIndex: 0,
  childActive: false,
  childLastIndex: 0,
  lastPage: "",
}

const SessionContext = createContext({
  session: localStorage.getItem("session") ? JSON.parse(localStorage.getItem("session")) : initial,
  setSession: () => { }
})

export const SessionContextProvider = (props) => {
    const { session } = useContext(SessionContext)
    console.log(session)
    return (
      <SessionContext.Provider value={{
        session: session,
      }}>
        {!session.active && (
          props.children
        )}
      </SessionContext.Provider>
    );
}

export default SessionContext
