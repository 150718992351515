import { gql } from "@apollo/client";

export const TEAM_WITH_PARTNERS_QUERY = gql`
query TeamsWithPartners($id: ID!) {
  team(id: $id) {
    id
    name
    department {
      id
      name
    }
    valuePartners {
      id
      name
      department {
        id
        name
      }
    }
    partnerStatuses {
      id
      status
      customer {
        id
      }
      supplier {
        id
      }
    }
  }
}
`;


export const CREATE_PARTNER_STATUS_MUTATION = gql`
  mutation createPartnerStatus (
    $teamId: String!
    $customerId: String!
    $supplierId: String!
    $status:PartnerStatus!
    )
    {
      createPartnerValueStatus(teamId:$teamId, status:$status, customerId:$customerId, supplierId:$supplierId)
      {
        id
        status
        supplier {
          id
        }
        customer {
          id
        }
      }
    }
`;

export const UPDATE_PARTNER_STATUS_MUTATION = gql`
  mutation updatePartnerStatus (
    $id:ID!
    $status:PartnerStatus!
  )
  {
    updatePartnerValueStatus(id:$id, status:$status)
    {
      id
      status
      supplier {
        id
      }
      customer {
        id
      }
    }
  }
`;
