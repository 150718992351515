import React, { useEffect, useState } from "react";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
// icons
import Loader from '../assets/ButtonIcons/Loader Blue.svg';
import RefreshBlue from '../assets/ButtonIcons/Refresh Blue.svg';
import RefreshWhite from '../assets/ButtonIcons/Refresh White.svg';

function renderTooltip(props, text) {
  return (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );
}
const RealtimeRefreshComponent = (props) => {
  const [hasNewData, setHasNewData] = useState(props.hasNewData)
  // const [hasNewData, setHasNewData] = useState(true)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setHasNewData(props.hasNewData)
  }, [setHasNewData, props.hasNewData])

  useEffect(() => {
    setLoading(props.refresh)
  }, [setLoading, props.refresh])


  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 25, hide: 40 }}
      overlay={(props) => renderTooltip({...props}, "Refresh")}
    >
      <button className={"pulse-btn btn-icons-container" + (hasNewData ? " text-white active" : "")} type="submit" onClick={() => props.toggleRefresh(true)}>
        {loading
          ? (
            <img src={Loader} alt="Loader" className="loader-spinner"/>
          ) : hasNewData
            ? (
              <img src={RefreshWhite} alt="RefreshWhite" className="btn-icons"/>
            ) : (
              <img src={RefreshBlue} alt="RefreshBlue" className="btn-icons"/>
            )
        }
      </button>
    </OverlayTrigger>
  )
}



export default RealtimeRefreshComponent;
