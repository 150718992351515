import React, { Component } from "react";
import { connect } from 'react-redux';
import { fetchUser } from '../redux/services/UserService';
import { changeCompany } from '../redux/services/AuthenticationService';
import { Dropdown } from 'react-bootstrap';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import displayErrorToast from "./utils/displayErrorToast";

export class CompanySelector extends Component {

  componentDidMount() {
    this.props.fetchUser();
  }

  state = {
    loadingCompany: false
  }

  async selectCompany(id) {
    this.setState({
      loadingCompany: true
    })
    try {
      await this.props.changeCompany(id)
      localStorage.setItem("selectedTeamResult", "")
      localStorage.setItem("selectedBusinessResult", "")
      window.location.reload()
    } catch (err) {
      displayErrorToast(err)
      this.setState({
        loadingCompany: false
      })
    }
  }



  logout = () => {
    localStorage.clear()

    window.location.href = "/"
  }

  getCompanyInput(user) {
    const companyId = localStorage.getItem("companyId")

    const { companies } = user

    const company = companies.find(company => company.id === companyId)

    if(companies.length > 1) {
      return (
        <Dropdown className="company-select-dropdown">
          <Dropdown.Toggle className="nav-link nav-pill team-select-text" variant="none" id="dropdown-basic">
            {this.state.loadingCompany
              ? <FontAwesomeIcon className="color-blue mx-auto fa-spin" icon={faSpinner} />
              :  company.name
            }
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {companies.map((comp, index) => {
              return <Dropdown.Item key={index} onClick={() => this.selectCompany(comp.id)}>{comp.name}</Dropdown.Item>
            })}
          </Dropdown.Menu>
        </Dropdown>
      )
    } else {
      return <div>{company.name}</div>
    }
  }
  render() {
    return (
      <>
        {!this.props.isLoading && (
          <div>
            {!this.props.presentation.active && (this.getCompanyInput(this.props.user))}
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  team: state.teamReducer.team,
  isLoading: state.userReducer.isLoading,
  presentation: state.presentationReducer
});

const mapDispatchToProps = {
  fetchUser,
  changeCompany
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanySelector);
