import React, { Fragment } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faUsers
} from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-modal';
import LabelComponent from "../../components/utils/getCompanyLabel";

const getLabelText = key => <LabelComponent val={key}/>


const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '500px'
  }
};


const TeamModuleModal = ({ modules, selectedModules, setSelectedModules, modulesLoading, team, linkLoading, saveModuleLinks, showModal, setShowModal, downloadCSV }) => {

  const isModuleIncluded = (id) => {
    return selectedModules.includes(id)
  }

  const manageModules = (id) => {
    if(selectedModules.includes(id)) {
      setSelectedModules(selectedModules.filter(moduleId => moduleId !== id))

    } else {
      setSelectedModules([...selectedModules, id])
    }
  }

  return (
    <Fragment>
      <Button className="ml-2" variant="primary" onClick={() => setShowModal(true)}>{getLabelText("manage")}<FontAwesomeIcon className="ml-2" icon={faUsers} /></Button>
      <button className="btn btn-primary ml-2" onClick={() => downloadCSV()}>
        {getLabelText("export")}
      </button>
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        contentLabel="Result"
        style={customStyles}
      >
        <div className="row">
          <div className="col-12 text-center">
            <h6 className="cdi-blue-txt ml-0 mb-1">{getLabelText("modules_for")}</h6>
            <h6 className="cdi-blue-txt ml-0 mb-3">{team.department.name} ({team.name})</h6>
          </div>
        </div>
        <div className="row include-users-content">
          <div className="col-12 text-center">
            <Table striped bordered hover className="w-100">
              <thead>
                <tr>
                  <td width="10%"><b>{getLabelText("included")}</b></td>
                  <td width="90%" className="text-left"><b>{getLabelText("modules_name")}</b></td>
                </tr>
              </thead>
              <tbody>
                {modules.map((module, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <Form.Check type="checkbox" checked={isModuleIncluded(module.id)} onChange={() => manageModules(module.id)}/>
                      </td>
                      <td className="text-left">
                        { module.name }
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-6">
            <button className="btn btn-primary w-100" disabled={linkLoading} onClick={() => saveModuleLinks()}>
              {linkLoading
                ? (
                  <>{getLabelText("saving")} <FontAwesomeIcon icon={faSpinner} className="fa-spin ml-2"></FontAwesomeIcon></>
                ) : (
                  <>{getLabelText("save")}</>
                )
              }
            </button>
          </div>
          <div className="col-6">
            <button className="btn btn-default cdi-blue-outline color-blue w-100" onClick={() => setShowModal(false)}>{getLabelText("cancel")}</button>
          </div>
        </div>
      </Modal>
    </Fragment>
  )
}

export default TeamModuleModal
