const putOtherLast = (array) => {
  const otherOption = array.filter(({name}) => {
    return (name.toLowerCase() === "other")
  })

  if(otherOption) {
    array.push(array.splice(array.indexOf(otherOption[0]), 1)[0])
  }

  return array
}

export default putOtherLast
