import React, { Component, Fragment } from 'react';
import { Table, Tooltip, OverlayTrigger, Row, Col, ButtonGroup, ToggleButton, FormControl, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createIssue, deleteAction } from '../services/IssueService';
import { getAllTeamsList, getTeam } from "../services/TeamService";

import displayErrorToast from "../components/utils/displayErrorToast";
import SubHeaderComponent from "../components/SubHeaderComponent";
import RealtimeRefreshComponent from "../components/RealtimeRefreshComponent";
import { toast } from "react-toastify";
import { faSpinner, faPlus, faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getActions, archiveFeedback, updateSharedStatus, updateAction, createAction } from "../services/EscalationsService";
import { createAction as nextStepAction } from '../services/InnovationsService';
import { updateIssue } from "../services/CustomerSupplierService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "react-switch";
import { Link } from "react-router-dom";
import * as DefaultLabels from '../assets/glossary.json';
import LabelComponent from "../components/utils/getCompanyLabel";
import { getLoadDetails } from "../components/utils/getLoadDetails";
// icons
import Archive from '../assets/ButtonIcons/Archive Blue.svg';
import Cancel from '../assets/ButtonIcons/Cancel Blue.svg';
import Delete from '../assets/ButtonIcons/Delete Blue.svg';
import Edit from '../assets/ButtonIcons/Edit Blue.svg';
import Loader from '../assets/ButtonIcons/Loader Blue.svg';
import PutBack from '../assets/ButtonIcons/Put Back Blue.svg';
import Save from '../assets/ButtonIcons/Save Blue.svg';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import NextStep from "../components/shared/NextStep";

const workSheetName = 'Worksheet-1';
const columns = [
  { header: 'Date', key: 'reviewDate' },
  { header: 'Escalation Issue', key: 'whatText' },
  { header: 'Feedback', key: 'feedback' },
  { header: 'Shared', key: 'shared' },
  { header: 'Next Step Status', key: 'nextStepStatus' },
];

const labels = DefaultLabels.default;
const getLabelText = key => <LabelComponent val={key}/>
const STATUSES = [
  "NEXT_STEP",
  "COMPLETED",
  "EMPTY",
  "NONE"
]


function renderTooltip(props, text) {
  return (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );
}

export class EscalationsScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      issues: [],
      allTeams: [],
      actions: [],
      loading: false,
      loadingId: "",
      loadingType: "",
      feedback: "",
      editable: false,
      whenChanged: false,
      actionId: "",
      archived: false,
      editType: false,
      sortBy:"",
      orderBy: "",
      teamToUse: null,
    }

    this.renderRows = this.renderRows.bind(this)
    this.getNextStepsStatus = this.getNextStepsStatus.bind(this)
    this.props.togglePageLoad(true)
  }

  async loadData(firstLoad, prevProps = null) {
    if (!firstLoad) this.props.togglePageLoad(true);
    const response = await getAllTeamsList()
    this.setState({
      allTeams: response.data.allTeams
    })
    let selectedTeam = getLoadDetails("TEAM", {
      ...this.props,
      teams: [
          ...this.props.teams,
          ...response.data.allTeams
      ]
    });
    await this.fetchActions();
    if(!selectedTeam.company) {
      const teamResponse = await getTeam(selectedTeam.id)
      selectedTeam = teamResponse.data.team
    }

    let users = [...selectedTeam.members, ...selectedTeam.leaders]

    if(selectedTeam.coach) {
      users.push(selectedTeam.coach)
    }

    this.setState({
      users: users,
      teamToUse: selectedTeam,
    })
    this.props.togglePageLoad(false)
  }


  async componentDidMount() {
    const initialLoad = getLoadDetails("INITIAL", this.props);
    if (initialLoad) {
      await this.loadData(true);
    }
  }


  async componentDidUpdate(prevProps) {
    const updateLoad = getLoadDetails("UPDATE", this.props, prevProps);
    if(updateLoad) {
      await this.loadData(false, prevProps);
    }
  }

  getText = val => {
    const { user } = this.props;

    const companyId = localStorage.getItem("companyId");
    let lang = "EN";
    let langLabels = labels.find(lbl => lbl.lang === lang);
    let def = langLabels.glossary.find(label => label.key === val);

    if(user && user.companies && user.companies.length) {
      const company = user.companies.find(company => company.id === companyId);
      if(company) {
        lang = company.language;
        langLabels = labels.find(lbl => lbl.lang === lang);
        def = langLabels.glossary.find(label => label.key === val);
        if(!def) {
          lang = "EN";
          langLabels = labels.find(lbl => lbl.lang === lang);
          def = langLabels.glossary.find(label => label.key === val);
        }
      }
      if(company && company.companyGlossaries) {
        const custom = company.companyGlossaries.find(label => label.key === val);

        if(custom) return custom.value
      }
    }

    if(!def) {
      console.log(val)
    }
    return def.value
  }

  async fetchActions() {
    const { allTeams } = this.state
    const selectedTeam = getLoadDetails("TEAM", {
      ...this.props,
      teams: [
          ...this.props.teams,
          ...allTeams
      ]
    });
    const response = await getActions(selectedTeam.id);
    const actions = this.handleSort({ actions: response.data.actions });
    this.setState({
      actions,
    })
  }

  toggleArchived() {
    this.setState({
      archived: !this.state.archived
    })
  }

  getIssueText(issue, action) {
    const { editable, actionId, issueText } = this.state

    if(editable && actionId === action.id) {
      return <input
        type="text"
        value={issueText}
        className="form-control"
        onChange={(e) => this.setState({issueText: e.target.value}) }
        autoComplete="off"
      />
    }
    return action.actionType === "ROOT_CAUSE" ? issue.whyText : issue.whatText
  }

  getUserInput(action) {
    const { editable, actionId, whoId } = this.state

    if(editable && actionId === action.id) {
      return (
        <select className="form-control cdi-ddl w-100 ml-0"
          name="who"
          value={whoId ? whoId : 1}
          required={true}
          onChange={(e) => this.setState({ whoId: e.target.value})}
          autoComplete="off"
        >
          <option value="1" disabled>{this.getText("select_member")}</option>
          {this.state.users.map((user, index) => {
            return <option autoComplete="off" value={user.id} key={index}>{user.firstNames + " " + user.lastName}</option>
          })}
        </select>
      )
    }
    return action.who ? action.who.firstNames + " " + action.who.lastName : getLabelText("none_assigned")
  }

  getWhenInput(action) {
    const { editable, actionId, when, whenChanged } = this.state

    if(editable && actionId === action.id) {
      return (
        <Fragment>
          <DatePicker
            name="date"
            className="form-control"
            required={true}
            dateFormat="dd/MM/yyyy"
            showYearDropdown
            selected={when}
            onChange={this.handleDateChange}
            tabIndex="0"
            autoComplete="off"
          />
          <div className="pt-2 d-inline">
            <Switch
              className="ml-2"
              checkedIcon={false}
              uncheckedIcon={false}
              offColor="#005e85"
              onColor="#c00000"
              checked={whenChanged}
              onChange={this.whenChanged}
              autoComplete="off"
            />
          </div>
        </Fragment>
      )
    }
    return <span className={action.whenChanged ? "color-red" : "color-blue"}>
      {action.when ? this.formattedDate(new Date(action.when)) : getLabelText("none_assigned")}
    </span>
  }

  getFeedbackInput(action) {
    const { editable, actionId } = this.state
    if(editable && actionId === action.id) {
      return (
        <FormControl
          autoFocus="true"
          type="text"
          placeholder="Feedback"
          name="feedback"
          value={this.state.feedback}
          onChange={(e) => this.setState({ feedback: e.target.value })}
          autoComplete="off"
        />
      )
    } else {
      return action.feedback ? action.feedback : ""
    }
  }

  getSharedStatus(action) {
    const {loading, loadingId, loadingType, editType, actionId} = this.state
    if(editType === "create" && actionId === action.id) return null
    if(loading && loadingType === "share" && loadingId === action.id) {
      return (<span className="indicator chk chk-white">
        <FontAwesomeIcon className="color-blue mx-auto fa-spin m-auto" icon={faSpinner} />
      </span>)
    } else {
      return <span className={"indicator chk chk-" + (action.shared ? "blue color-selected" : "white") + (this.state.editable ? " disabled" : "")} onClick={() => this.share(action.id, action.shared)}></span>
    }

  }

  handleReviewDateChange = date => {
    this.setState({
      issueDate: date
    })
  }

  whenChanged = () => {
    const { whenChanged } = this.state

    this.setState({
      whenChanged: !whenChanged
    })
  }

  getReviewDate = (date, action) => {
    const { editable, actionId, issueDate } = this.state

    if(editable && actionId === action.id) {
      return (
        <DatePicker
          name="date"
          className="form-control"
          required={true}
          dateFormat="dd/MM/yyyy"
          showYearDropdown
          selected={issueDate}
          onChange={this.handleReviewDateChange}
          tabIndex="0"
          autoComplete="off"
        />
      )
    }

    return this.formattedDate(date)
  }

  formattedDate(date) {
    let month = date.getMonth() + 1
    let day = date.getDate()

    if(month.toString().length < 2)
      month = "0" + month.toString()

    if(day.toString().length < 2)
      day = "0" + day.toString()

    return day + "/" + month + "/" + date.getFullYear()
  }

  async nextSteps(id) {
    this.setLoading(id, "nextSteps")
    try {
      await nextStepAction(id, "FEEDBACK", "NEXT_STEP")
      await this.fetchActions()
      this.cancelLoading()
    } catch (err) {
      displayErrorToast(err)
      this.cancelLoading()
    }
  }

  getNextStepsStatus(issue, action) {
    const nextStepCount = issue.nextStepCount;
    const completedCount = issue.completedCount;
    const totalNextSteps = issue.totalNextSteps;
    const isEscalatedIssue = action.actionType === "ESCALATION";
    let status;

    if(isEscalatedIssue && nextStepCount > 0 && completedCount !== totalNextSteps) status = "NEXT_STEP";
    else if(isEscalatedIssue && (completedCount !== totalNextSteps || totalNextSteps === 0)) status = "NONE";
    else if(isEscalatedIssue && totalNextSteps > 0 && completedCount === totalNextSteps) status = "COMPLETED";
    else status = "EMPTY";

    return status;
  }

  getNextStepsButton(issue, action)
  {
    const { loading, loadingType, loadingId, editType, actionId } = this.state;

    return (
        <NextStep
            loading={loading}
            loadingType={loadingType}
            loadingId={loadingId}
            editType={editType}
            issue={issue}
            action={action}
            actionId={actionId}
            status={this.getNextStepsStatus(issue, action)}
            nextSteps={() => this.nextSteps(issue.id, "NEXT_STEP")}
        />
    )
  }

  setLoading(id, type) {
    this.setState({
      loading: true,
      loadingId: id,
      loadingType: type
    })
  }

  cancelLoading() {
    this.setState({
      loading: false,
      loadingId: "",
      loadingType: ""
    })
  }

  async archive(id, archived) {
    this.setLoading(id, "archive")
    try {
      await archiveFeedback(id, !archived)
      await this.fetchActions()
      this.cancelLoading()
    } catch (err) {
      displayErrorToast(err)
      this.cancelLoading()
    }
  }

  setEdit(issue, action) {
    this.setState({
      editable: true,
      actionId: action.id,
      issueDate: new Date(issue.reviewDate),
      issueText: action.actionType === "ROOT_CAUSE" ? issue.whyText : issue.whatText,
      actionText: action.description,
      whoId: action.who ? action.who.id : "",
      when: action.when ? new Date(action.when) : new Date(),
      feedback: action.feedback,
      editType: "edit"
    })

  }

  setCreate() {
    const { actions } = this.state
    const generatedId = Math.ceil(Math.random() * 100)

    actions.unshift({
      id: generatedId,
      description: "",
      actionType: "ESCALATION",
      status: "NONE",
      feedback: "",
      shared: false,
      archived: false,
      escalated: true,
      escalateArchived: false,
      issue: {
        id: generatedId,
        reviewDate: "",
        whatText: "",
        whyText: "",
        archived: false,
      }
    })

    this.setState({
      actions,
      editable: true,
      actionId: generatedId,
      issueDate: new Date(),
      actionText: "",
      issueText: "",
      whoId: "",
      when: new Date(),
      editType: "create"
    })
  }

  cancel = () => {
    const { editType } = this.state
    let options = {
      editable: false,
      actionId: "",
      whoId: "",
      when: "",
      whenChanged: false,
      issueDate: "",
      issueText: "",
      actionText: "",
      feedback: ""
    }

    if(editType === "create") {
      const { actions } = this.state
      actions.shift()
      options = {
        ...options,
        actions
      }
    }
    this.setState(options)
  }

  save = async(issue, action) => {
    const {feedback, editType, whoId, issueText, issueDate, when, whenChanged} = this.state
    this.setLoading(action.id, "save")
    try {
      if(editType === "create") {
        const response = await createIssue(this.props.selectedTeam.id, issueText, "", issueDate.toISOString(), "ESCALATION_ISSUE")
        await createAction(response.data.createIssue.id, "ESCALATION", "NONE", whoId, when.toISOString(), whenChanged, feedback)
      } else {
        await updateIssue(issue.id, action.actionType === "ROOT_CAUSE" ? "" : issueText, action.actionType === "ROOT_CAUSE" ? issueText : "", issueDate.toISOString())
        await updateAction(action.id, whoId, when.toISOString(), whenChanged, feedback)
      }
      await this.fetchActions()
      this.setState({
        editable: false,
        actionId: "",
        whoId: "",
        when: "",
        whenChanged: false,
        issueDate: "",
        issueText: "",
        actionText: "",
        feedback: ""
      })
      this.cancelLoading()
    } catch (err) {
      await this.fetchActions()
      this.cancelLoading()
      displayErrorToast(err)
    }
  }

  async delete (action) {
    if(action.status === "COMPLETED") {
      if (window.confirm(this.getText("delete_department_prompt"))) {
        try {
          this.setLoading(action.id, "delete")
          await deleteAction(action.id)
          await this.fetchActions()
        } catch (error) {
          error.graphQLErrors.map(error => {
            toast.error(error.message)

            return true
          })
        } finally {
          this.cancelLoading()
        }
      }
    } else {
      toast.error(getLabelText("delete_esc_error"))
    }
  }

  getInputButtons(issue, action) {
    const { editable, actionId, loading, loadingId, loadingType } = this.state
    let buttons;
    if(editable && actionId === action.id) {
      buttons = (
        <Fragment>
          <OverlayTrigger
            placement="top"
            delay={{ show: 25, hide: 40 }}
            overlay={(props) => renderTooltip({...props}, getLabelText("save"))}
          >
            <button className="btn-icons-container" type="submit" onClick={() => this.save(issue, action)}>
              {loading && loadingType === "save" && loadingId === action.id
                ? (
                  <img src={Loader} alt="Loader" className="loader-spinner"/>
                ) : (
                  <img src={Save} alt="Save" className="btn-icons"/>
                )
              }
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            delay={{ show: 25, hide: 40 }}
            overlay={(props) => renderTooltip({...props}, getLabelText("cancel"))}
          >
            <button className="btn-icons-container" type="submit" onClick={this.cancel}>
              <img src={Cancel} alt="Cancel" className="btn-icons"/>
            </button>
          </OverlayTrigger>
        </Fragment>
      )
    } else {
      buttons = (
        <Fragment>
          {!this.state.archived && (
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, getLabelText("edit"))}
            >
              <button className={"btn-icons-container" + (this.state.editable ? " disabled" : "")} disabled={action.escalateArchived} type="submit" onClick={() => this.setEdit(issue, action)}>
                <img src={Edit} alt="Edit" className="btn-icons"/>
              </button>
            </OverlayTrigger>
          )}
          <OverlayTrigger
            placement="top"
            delay={{ show: 25, hide: 40 }}
            overlay={(props) => renderTooltip({...props}, (this.state.archived ? getLabelText("put_back") : getLabelText("archive")))}
          >
            <button
              className={"btn-icons-container" + (this.state.editable ? " disabled" : "")}
              type="submit"
              onClick={() => this.archive(action.id, action.escalateArchived)}
            >
              {loading && loadingType === "archive" && loadingId === action.id
                ? (
                  <img src={Loader} alt="Loader" className="loader-spinner"/>
                ) : action.escalateArchived
                  ? (
                    <img src={PutBack} alt="PutBack" className="btn-icons"/>
                  ) : (
                    <img src={Archive} alt="Archive" className="btn-icons"/>
                  )
              }
            </button>
          </OverlayTrigger>
          {this.state.archived && (
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, getLabelText("delete"))}
            >
              <button className={"btn-icons-container" + (this.state.editable ? " disabled" : "")} type="submit" onClick={() => this.delete(action)}>
                  {loading && loadingType === "delete" && loadingId === action.id
                    ? (
                      <img src={Loader} alt="Loader" className="loader-spinner"/>
                    ) : (
                      <img src={Delete} alt="Delete" className="btn-icons"/>
                    )
                  }
              </button>
            </OverlayTrigger>
          )}
        </Fragment>
      )
    }

    return buttons
  }

  async share(id, shared) {
    this.setLoading(id, "share")
    try {
      await updateSharedStatus(id, !shared)
      await this.fetchActions()
      this.cancelLoading()
    } catch (err) {
      this.cancelLoading()
      displayErrorToast(err)
    }
  }

  handleSort = ({ type = this.state.sortBy, action = false, actions = this.state.actions }) => {
    const { sortBy, orderBy } = this.state;
    const sortDesc = (action && orderBy === "asc") || (!action && orderBy === "desc") || type !== sortBy;
    const sortAsc = (action && orderBy === "desc") || (!action && orderBy === "asc");
    let newOrder = (sortAsc ? "asc" : "desc");

    let newActions = actions;
    switch(type) {
      case "date":
        newActions.sort((a, b) => {
          const aDate = new Date(a.issue.reviewDate).setHours(24,0,0,0);
          const bDate = new Date(b.issue.reviewDate).setHours(24,0,0,0);
          if(sortDesc) return aDate - bDate;
          else if(sortAsc) return bDate - aDate;
          else return false;
        });

        break;
      case "issue":
        newActions.sort((a, b) => {
          const aText = a.actionType === "ROOT_CAUSE" ? a.issue.whyText : a.issue.whatText;
          const bText = b.actionType === "ROOT_CAUSE" ? b.issue.whyText : b.issue.whatText;
          if(sortDesc) return bText.localeCompare(aText);
          else if(sortAsc) return aText.localeCompare(bText);
          else return false;
        });
        break;
      case "feedback":
        newActions.sort((a, b) => {
          const aText = a.feedback ? a.feedback : "";
          const bText = b.feedback ? b.feedback : "";
          if(sortDesc) return aText.localeCompare(bText);
          else if(sortAsc) return bText.localeCompare(aText);
          else return false;
        });
        break;
      case "shared":
        newActions.sort(function(a, b) {
            const aBool = a.shared;
            const bBool = b.shared;
            if(sortDesc) return (aBool === bBool) ? 0 : aBool ? -1 : 1;
            else if(sortAsc) return (aBool === bBool) ? 0 : aBool ? 1 : -1;
            else return false;
        });
        break;

      case "nextSteps":
        const getNextStepsStatus = this.getNextStepsStatus;
        newActions.sort(function(a, b) {
            const aStatusIndex = STATUSES.indexOf(getNextStepsStatus(a.issue, a));
            const bStatusIndex = STATUSES.indexOf(getNextStepsStatus(b.issue, b));
            if(sortDesc) return aStatusIndex - bStatusIndex;
            else if(sortAsc) return bStatusIndex - aStatusIndex;
            else return false;
        });
        break;

      default:
        newActions.sort((a, b) => {
          const aDate = new Date(a.issue.reviewDate).setHours(24,0,0,0);
          const bDate = new Date(b.issue.reviewDate).setHours(24,0,0,0);
          if(sortDesc) return aDate - bDate;
          else if(sortAsc) return bDate - aDate;
          else return false;
        });
        break;
    }
    if(!action) return newActions;

    this.setState({
      orderBy: newOrder,
      sortBy: type,
      actions: newActions
    })
  }

  renderToggleButtons() {
    const { archived } = this.state;
    return (
      <ButtonGroup toggle className="ml-1">
        <ToggleButton
            type="radio"
            variant="secondary"
            name="createType"
            value="0"
            checked={!archived}
            onChange={() => this.toggleArchived()}
        >
          {getLabelText("active")}
        </ToggleButton>
        <ToggleButton
            type="radio"
            variant="secondary"
            name="createType"
            value="1"
            checked={archived}
            onChange={() => this.toggleArchived()}
        >
          {getLabelText("archived")}
        </ToggleButton>
        <Button onClick={(e) => this.saveExcel()}>Export</Button>
      </ButtonGroup>
    )
  }

  renderHeader() {
    const { sortBy } = this.state;
    return (
      <tr>
        <th width="10%" onClick={() => this.handleSort({ type: "date", action: true })}>{getLabelText("date")} {this.renderSortingChevron("date")}</th>
        <th width="35%" onClick={() => this.handleSort({ type: "issue", action: true })}>{getLabelText("escalation_issue")} {this.renderSortingChevron("issue")}</th>
        <th width="35%" onClick={() => this.handleSort({ type: "feedback", action: true })}>{getLabelText("feedback")} {this.renderSortingChevron("feedback")}</th>
        <th width="5%" onClick={() => this.handleSort({ type: "shared", action: true })}>{sortBy === "shared" ? "" : getLabelText("shared")} {this.renderSortingChevron("shared")}</th>
        <th width="5%" onClick={() => this.handleSort({ type: "nextSteps", action: true })}>{this.renderSortingChevron("nextSteps")}</th>
        <th width="10%"></th>
      </tr>
    )
  }

  renderRows(issue, action, index) {
    return (
      <tr key={index}>
        <td>{this.getReviewDate(new Date(issue.reviewDate), action)}</td>
        <td>{this.getIssueText(issue, action)}</td>
        <td>{this.getFeedbackInput(action)}</td>
        <td className={"text-center" + (action.escalateArchived ? " disabled" : "")}>{this.getSharedStatus(action)}</td>
        <td className={"text-center" + (action.escalateArchived ? " disabled" : "")}>{action.actionType === "ESCALATION" ? this.getNextStepsButton(issue, action) : null}</td>
        <td>{this.getInputButtons(issue, action)}</td>
      </tr>
    )
  }

  renderSortingChevron(type) {
    const { sortBy, orderBy } = this.state;

    if(type === sortBy) {
      if(orderBy === "asc") return <FontAwesomeIcon className="ml-2" icon={faChevronUp} />
      else if(orderBy === "desc") return <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
    }
    return null;
  }

  getFeedbackStatus(nextStepCount, completedCount, totalNextSteps, shared) {
    if (!shared && nextStepCount > 0 && completedCount !== totalNextSteps) {
      return "NEXT STEP";
    } else if (!shared && (completedCount !== totalNextSteps || totalNextSteps === 0)) {
      return "NONE";
    } else if (!shared && totalNextSteps > 0 && completedCount === totalNextSteps) {
      return "COMPLETED";
    } else {
      if (shared && nextStepCount > 0 && completedCount !== totalNextSteps) {
        return "NEXT STEP";
      }
      else if (shared && (completedCount !== totalNextSteps || totalNextSteps === 0)) {
        return "NONE";
      }
      else {
        return "COMPLETED";
      }
    }
  }
  
  saveExcel = async () => {
    const workbook = new Excel.Workbook();
    try {
      let allActions = this.state.actions;
      if (allActions != null && allActions.length > 0) {
        allActions = allActions.filter(a => a.escalateArchived === this.state.archived);
      }
      let teamName;
      if (this.props.selectedTeam.id !== 0) {
        teamName = this.props.selectedTeam.name;
      }
      let exportTypeText = this.state.archived ? '_Archived': '_Active';
      const fileName = teamName + "_EsclationsAndFeedbacks" + exportTypeText;
      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(workSheetName);

      // add worksheet columns
      // each columns contains header and its mapping key from data
      worksheet.columns = columns;

      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true };

      // loop through all of the columns and set the alignment with width.
      worksheet.columns.forEach(column => {
        column.width = column.header.length + 15;
        column.alignment = { horizontal: 'left' };
      });

      //Prepare data 
      let actions = allActions.map((action, index) => ({
        reviewDate: this.formattedDate(new Date(action.issue?.reviewDate)),
        whatText: action.issue?.whatText,
        feedback: action.feedback,
        nextStepStatus: action.actionType === "ESCALATION"? this.getFeedbackStatus(action.issue?.nextStepCount,action.issue?.completedCount,
            action.issue?.totalNextSteps,action.shared): "",
        shared: action.shared ? "Yes" : "No"
      }));

      actions.sort((a, b) => {
        return (new Date(b.reviewDate).setHours(24, 0, 0, 0) - new Date(a.reviewDate).setHours(24, 0, 0, 0))
      })
      actions.forEach(singleData => {
        worksheet.addRow(singleData);
      });

      // loop through all of the rows and set the outline style.
      worksheet.eachRow({ includeEmpty: false }, row => {
        // store each cell to currentCell
        const currentCell = row._cells;

        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach(singleCell => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;

          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        });
      });

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error('Error saving file - ', error.message);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
    }
  };

  render() {
    return (
      <Fragment>
        <SubHeaderComponent teamToUse={this.state.teamToUse}>
          <Col className="text-right childenSpace">
            <div className={"refreshBtn"}>
              <RealtimeRefreshComponent
                refresh={this.props.refresh}
                hasNewData={this.props.hasNewData}
                toggleRefresh={this.props.toggleRefresh}
              />
            </div>
            {!this.state.archived && (
              <Button className={"ml-2 btn btn-primary" + (this.state.editable ? " disabled" : "")} onClick={() => this.setCreate()}>{getLabelText("add")}<FontAwesomeIcon className="ml-2" icon={faPlus} /></Button>
            )}
          </Col>
        </SubHeaderComponent>
        <div className="grey-header-space">
          <Row className="mb-3">
            <Col className="text-right pr-0">
              {this.renderToggleButtons()}
            </Col>
          </Row>
          <Row>
            <Col className="pr-0">
              <Table striped bordered hover className="standard-tbl standard-escalations-tbl mt-2 table-heading-background user-text pr-0">
                <thead>
                  {this.renderHeader()}
                </thead>
                <tbody>
                {this.state.actions.map(({ issue, ...action }, index) => {
                  if(action.escalateArchived === this.state.archived) {
                    return this.renderRows(issue, action, index);
                  }
                  return null
                })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam,
  presentation: state.presentationReducer,
  teams: state.teamsReducer.teams,
  user: state.userReducer.user
})

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EscalationsScreen)
