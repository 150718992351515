import React from "react";
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryContainer,
} from "victory";
import TickLabelComponent from "./TickLabelComponent";
import {
  lightBlue,
  useDomainBars,
  useTickValues,
  useLineData,
  useTargetData,
  useInvertedDomainBars
} from "./resources";
import { MetricPeriodStatuses, MetricStatuses } from "../resources";
import {
  useDynamicDomain
} from "../hooks";
import _ from 'lodash';
import ProblemSolvingLabelComponent from "./ProblemSolvingLabelComponent";
import {useHistory} from "react-router-dom";
import {formatLargeNumber} from "../../../utils/number";

function getDisplayData (data, maxValue, minHeight) {
  let displayData = []

  data.map(metric => displayData.push({
      ...metric,
      y: metric.y === minHeight && minHeight >= 0 ? metric.y + ((maxValue - minHeight) * 0.01) : !metric.y && minHeight >= 0 ? minHeight : metric.y,
      realMetric: metric.y
    }))
  return displayData
}

const Chart = ({
  // domain,
  data,
  opaque,
  handleSelectMetric,
  handleTogglePeriodStatus,
  target,
  upperTrigger,
  lowerTrigger,
  attendanceWeekStatuses,
  isTrend,
  goal,
  monthList,
  monthValues,
  resultDate,
  setMetricTarget,
  setLowerTriggerValue,
  setUpperTriggerValue,
  colour,
  startWeek,
  trendTarget,
  currentTarget,
  calculatedTarget,
  createProblem
}) => {
  const router = useHistory();

  let actualData = []
  if(!isTrend) {
    if(currentTarget[0] === '' && currentTarget[1] === '') {
      target = [calculatedTarget, calculatedTarget];
    }
  }

  // Cleanup this mess!
  data.forEach((d) => {
    let attendanceWeekStatus = attendanceWeekStatuses.find((attendanceWeekStatus) => attendanceWeekStatus.week === d.x);
    if (!attendanceWeekStatus) {
      actualData.push(d);
    } else {
      if (attendanceWeekStatus.nonWorkingDayCount < 12) {
        actualData.push(d);
      }
    }
  });
  const tickValues = useTickValues(actualData);

  let targetData = useTargetData(target, tickValues, goal, isTrend, monthValues, actualData, resultDate, trendTarget, "target");

  let lowerTriggerData = useTargetData(lowerTrigger, tickValues, goal, isTrend, monthValues, actualData, resultDate, trendTarget, "lowerTrigger");
  let upperTriggerData = useTargetData(upperTrigger, tickValues, goal, isTrend, monthValues, actualData, resultDate, trendTarget, "upperTrigger");

  const minTarget =  _.minBy(targetData, (target) => {
    return target.y
  })
  const maxTarget =  _.maxBy(targetData, (target) => {
    return target.y
  })
  const domain = useDynamicDomain(
    data,
    [10, 0],
    [minTarget ? minTarget.y : 0, maxTarget ? maxTarget.y : 10],
    lowerTrigger,
    upperTrigger
  );

  if(domain[1] === 0) domain[1] = 1;

  const emptyBarData = useDomainBars([minTarget ? minTarget.y : 0, maxTarget ? maxTarget.y : 10], actualData, domain, upperTrigger, lowerTrigger, target, isTrend);

  const maxHeight = _.maxBy(emptyBarData, (target) => {
    return target.y
  })
  const invertedBarData = useInvertedDomainBars([minTarget ? minTarget.y : 0, maxTarget ? maxTarget.y : 10], actualData, upperTrigger, lowerTrigger, target, isTrend);
  let minHeight = _.minBy(invertedBarData, (target) => {
    return target.y
  })

  if(!minHeight) {
    minHeight = {
      y: 0
    }
  }

  /*
    const upperTriggerData = useLineData(upperTrigger, tickValues);
    const lowerTriggerData = useLineData(lowerTrigger, tickValues);
  */

  const barClickHandlers = [
    {
      childName: ["domain-bar", "metric-bar"],
      target: "data",
      eventHandlers: {
        onClick: (_, { index }) => {
          if (actualData[index].periodStatus === MetricPeriodStatuses.NON_WORKING.value)
            return;

          handleSelectMetric(index);
          setMetricTarget(targetData[index] && (targetData[index].y || targetData[index].y === 0) ? `${targetData[index].y.toFixed(2)}` : null)
          setLowerTriggerValue(actualData[index] && (actualData[index].lowerTrigger || actualData[index].lowerTrigger === 0) ? `${actualData[index].lowerTrigger.toFixed(2)}` : null)
          setUpperTriggerValue(actualData[index] && (actualData[index].upperTrigger || actualData[index].upperTrigger === 0) ? `${actualData[index].upperTrigger.toFixed(2)}` : null)
        },
      },
    },
  ];

  const targetCondition = targetData && target[0]  !== "" && target[1] !== "" &&  !isNaN(target[0]) && !isNaN(target[1]) && (target[0] || target[0] === 0) && (target[1] || target[1] === 0);
  const lowerCondition = lowerTriggerData && lowerTrigger[0] !== "" && lowerTrigger[1] !== "" && !isNaN(lowerTrigger[0]) && !isNaN(lowerTrigger[1]) && (lowerTrigger[0] || lowerTrigger[0] === 0) && (lowerTrigger[1] || lowerTrigger[1] === 0);
  const upperCondition = upperTriggerData && upperTrigger[0] !== "" && upperTrigger[1] !== "" && !isNaN(upperTrigger[0]) && !isNaN(upperTrigger[1]) && (upperTrigger[0] || upperTrigger[0] === 0) && (upperTrigger[1] || upperTrigger[1] === 0);

  if(!lowerCondition)
  {
    lowerTriggerData = []
  }
  if(!upperCondition)
  {
    upperTriggerData = []
  }
  if(!targetCondition)
  {
    targetData = [];
  }
  
  return (
    <VictoryChart
      height={500}
      maxHeight={600}
      // bar widths
      width={!isTrend && goal.frequency === "Weekly" ? (data.length * 90) : (data.length * 60)}
      style={{ parent: { opacity: opaque ? 0.5 : 1, maxHeight: "600px" } }}
      padding={{ top: 10, left: 70, right: 18, bottom: 50 }}
      // padding between bars
      domainPadding={{ x: [65, 10], y: 5 }}
      containerComponent={<VictoryContainer className="eyecatcher" responsive={!isTrend && goal.frequency === "Daily"} preserveAspectRatio={!isTrend && goal.frequency === "Daily"} />}
      events={barClickHandlers}
    >
      <VictoryAxis
        name="period-axis"
        tickValues={tickValues}
        events={!isTrend ? [
          {
            target: "tickLabels",
            eventHandlers: {
              onClick: (_, { index }) => {
                if(!data[index].issue) {
                  handleTogglePeriodStatus(index);
                }
              },
            },
          },
        ] : null}
        style={{
          axis: { stroke: "transparent" },
          tickLabels: { cursor: "pointer",
          fill: ({ index }) => {
            if (!data) return 'transparent'
            const { periodStatus } = data[index]
            return periodStatus === "NORMAL" ? "black" : "white"
          }
          },
        }}
        offsetY={55}
        y0={() => minHeight && minHeight.y ? minHeight.y : 0}
        tickLabelComponent={<TickLabelComponent data={actualData} resultDate={resultDate} bottomLabels={true} monthList={monthList} monthValues={monthValues} startWeek={startWeek}/>}
      />
      <VictoryAxis
        dependentAxis
        tickFormat={(v) => (v >= 1000 ? `${(v / 1000).toFixed(1)}k` : v)}
        domain={minHeight && maxHeight ? minHeight.y !== maxHeight.y ? [(minHeight && minHeight.y ? minHeight.y : 0), (maxHeight && maxHeight.y ? maxHeight.y : 10)] : [minHeight.y - (minHeight.y * 0.1), maxHeight.y + (maxHeight.y * 0.1)] : [0, 10]}
        y0={() => minHeight && minHeight.y ? minHeight.y : 0}
        style={{
          axis: { stroke: "transparent" },
          grid: { stroke: lightBlue, strokeWidth: 2 },
        }}
        tickLabelComponent={<TickLabelComponent monthList={monthList} monthValues={monthValues}/>}
      />
    {minHeight.y < 0 && (
        <VictoryBar
          name="domain-bar"
          data={invertedBarData}
          barRatio={0.65}
          height={500}
          style={{ data: { fill: "white", stroke: lightBlue, strokeWidth: 2 } }}
        />
      )}
      <VictoryBar
        name="domain-bar"
        data={emptyBarData}
        barRatio={0.65}
        label={10}
        height={500}
        y0={() => minHeight && minHeight.y >= 0 ? minHeight.y : 0}
        style={{ data: { fill: "white", stroke: lightBlue, strokeWidth: 2, height: '5600px' } }}
      />
      <VictoryBar
        name="metric-bar"
        data={getDisplayData(actualData, (maxHeight && maxHeight.y ? maxHeight.y : 10), (minHeight && minHeight.y ? minHeight.y : 0))}
        barRatio={0.65}
        y0={() => minHeight && minHeight.y >= 0 ? minHeight.y : 0}
        labels={({ datum }) => datum.periodStatus === MetricPeriodStatuses.NON_WORKING.value ? "" : formatLargeNumber(datum.realMetric)}
        style={{
          labels: {fill: "black"},
          fontWeight: 800,
          data: {
            fill: ({ datum: { status, periodStatus } }) => {
              if (periodStatus === MetricPeriodStatuses.NON_WORKING.value)
                return "transparent";

              return status === "RED" ? MetricStatuses[status].color : colour === "Green" ? '#28a745' : "#005e85";
            },
          },
        }}
      />

      {lowerCondition && (
        <VictoryLine
          name="lower-trigger"
          data={isTrend ? [] : lowerTriggerData}
          style={{ data: { strokeDasharray: 5, strokeWidth: 2 } }}
        />
      )}

      {upperCondition &&  (
        <VictoryLine
          name="upper-trigger"
          data={isTrend ? [] : upperTriggerData}
          style={isTrend ? { data: { strokeDasharray: 0, strokeWidth: 0 }}: { data: { strokeDasharray: 5, strokeWidth: 2 }}}
        />
      )}

      {targetCondition && (
        <VictoryLine
          name="target"
          data={targetCondition ? targetData : []}
          style={{ data: { strokeWidth: 2 } }}
        />
      )}

      <VictoryAxis
          name="period-axis"
          offsetY={30}
          tickValues={tickValues}
          style={{
            axis: { stroke: "transparent" },
            tickLabels: {
              angle: 0,
              cursor: "pointer",
              backgroundColor: "transparent",
              borderRadius: "8px",
              fill: ({ index }) => {
                if (!data || !data.length) return 'transparent';
                const { issue, periodStatus } = data[index];
                if(periodStatus !== 'TRIGGER') return 'transparent';
                if(periodStatus === 'TRIGGER' && !issue) return '#000000';
                if(issue)
                {
                  if(!issue.nextStepStatus) return '#000000';
                  if(issue.nextStepStatus === 'PS_IN_PROGRESS') return "#005E85";
                  if(issue.nextStepStatus === 'COMPLETED') return "#FFFFFF";
                }
                return "transparent";
              }}
          }}
          events={!isTrend ? [
            {
              target: "tickLabels",
              eventHandlers: {
                onClick: (_, { index }) => {
                  const metric = data[index];
                  if(!metric.issue || (metric.issue && metric.issue.nextStepStatus === null)) {
                    createProblem(data[index]);
                  } else {
                    router.push(`/so-what/problem-solving?issue=${metric.issue?.id}&archived=${metric.issue?.archived}`)
                  }
                },
              },
            },
          ] : null}
          tickLabelComponent={<ProblemSolvingLabelComponent data={actualData} monthList={monthList} monthValues={monthValues} />}
      />
    </VictoryChart>
  );
};

export default Chart;
