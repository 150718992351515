import React, { useState, useEffect, useCallback } from "react";
import LabelComponent from "./utils/getCompanyLabel";
import { createUserLog, updateUserLog, } from "../services/AssignedUserLogService"
import { Row, Col } from "react-bootstrap";
import { connect } from 'react-redux';
import BackWhite from "../assets/back-white.png"
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import { getAssignedTeamUsers } from "../services/TeamService";

const pages = [
  {
    url: "/dashboard",
    title: "Company",
    key: "company_screen"
  },
  {
    url: "/dashboard/structure",
    title: "Structure",
    key: "structure_screen"
  },
  {
    url: "/dashboard/business-results",
    title: "Business Results"
  },
  {
    url: "/dashboard/users",
    title: "Users",
    key: "users_screen"
  },
  {
    url: "/dashboard/goals",
    title: "True North",
    key: "true_north_screen"
  },
  {
    url: "/dashboard/measurements",
    title: "Measurements",
    key: "measurements_screen"
  },
  {
    url: "/dashboard/goal-categories",
    title: "Goal Categories",
    key: "goal_categories_screen"
  },
  {
    url: "/dashboard/units",
    title: "Units",
    key: "units_screen"
  },
  {
    url: "/dashboard/team-types",
    title: "Team Types",
    key: "team_types_screen"
  },
  {
    url: "/dashboard/meeting-view",
    title: "Meeting View",
    key: "meeting_view_screen"
  },
  {
    url: "/dashboard/multi-level-meeting-view",
    title: "MLM View",
    key: "mlm_view_screen"
  },
  {
    url: "/dashboard/skills",
    title: "Skills",
    key: "skills_screen"
  },
  {
    url: "/dashboard/modules",
    title: "Modules",
    key: "modules_screen"
  },
  {
    url: "/dashboard/criteria",
    title: "Review Modules & Criteria",
    key: "review_criteria_screen"
  },
  {
    url: "/dashboard/implementation-module",
    title: "Implementation Modules",
    key: "implementation_module_screen"
  },
  {
    url: "/dashboard/recognitions",
    title: "Recognition",
    key: "recognition_screen"
  },
  {
    url: "/dashboard/leadership-routines",
    title: "Leadership Routines",
    key: "leadership_routines_setup_screen"
  },
  {
    url: "/dashboard/task-list",
    title: "Task List",
    key: "task_list_screen"
  },
  {
    url: "/teams",
    title: "Teams"
  },
  {
    url: "/teams/team-identity",
    title: "Team Identity",
    key: "team_identity_screen"
  },
  {
    url: "/teams/team-partners",
    title: "Team Partners (Customer & Suppliers)",
    key: "team_partners_cs_screen"
  },
  {
    url: "/team/value-stream-partners",
    title: "Team Partners (Value Stream Partners)",
    key: "team_partners_vsp_screen"
  },
  {
    url: "/teams/team-measures",
    title: "Team Goals",
    key: "team_goals_screen"
  },
  {
    url: "/teams/individual-goals",
    title: "Individual Goals",
    key: "individual_goals_screen"
  },
  {
    url: "/what/welcome",
    title: "Welcome",
    key: "welcome_screen"
  },
  {
    url: "/what/team-attendance",
    title: "Meeting Attendance",
    key: "meeting_attendance_screen"
  },
  {
    url: "/what/team-goals",
    title: "Team Results",
    key: "team_results_screen"
  },
  {
    url: "/so-what/project-tracker",
    title: "Project Tracker",
    key: "project_tracker_screen"
  },
  {
    url: "/what/customers",
    title: "Customers",
    key: "customers_screen"
  },
  {
    url: "/what/suppliers",
    title: "Suppliers",
    key: "suppliers_screen"
  },
  {
    url: "/what/business-results",
    title: "Business Results",
    key: "business_results_screen"
  },
  {
    url: "/what/individual-results",
    title: "Individual Results",
    key: "individual_results_screen"
  },
  {
    url: "/what/coaching-reviews",
    title: "Coaching Reviews",
    key: "coaching_reviews_screen"
  },
  {
    url: "/what/coaching-reviews-dashboard",
    title: "CR Dashboard",
    key: "coaching_reviews_dashboard_screen"
  },
  {
    url: "/what/skills-matrix",
    title: "Skills Matrix",
    key: "skills_matrix_screen"
  },
  {
    url: "/what/value-stream-partners",
    title: "Value Stream Partners",
    key: "value_stream_partners_screen"
  },
  {
    url: "/what/leadership-routines",
    title: "Leadership Routines",
    key: "leadership_routines_screen"
  },
  {
    url: "/what/task-tracker",
    title: "Task Tracker",
    key: "task_tracker_screen"
  },
  {
    url: "/what/implementation-tracker",
    title: "Implementation Tracker",
    key: "implementation_tracker_screen"
  },
  {
    url: "/what/scheduled-events",
    title: "Scheduled Events",
    key: "scheduled_events_screen"
  },
  {
    url: "/now-what/actions",
    title: "Next Steps",
    key: "next_steps_screen"
  },
  {
    url: "/me/my-actions",
    title: "My Next Steps",
    key: "my_next_steps_screen"
  },
  {
    url: "/now-what/escalations",
    title: "Escalations & Feedback",
    key: "escalations_screen"
  },
  {
    url: "/now-what/coaches-feedback",
    title: "Coaches Feedback",
    key: "coaches_feedback_screen"
  },
  {
    url: "/so-what/problem-solving",
    title: "Problem Solving",
    key: "problem_solving_screen"
  },
  {
    url: "/so-what/improvement-ideas",
    title: "Improvement Ideas",
    key: "impr_ideas_screen"
  },
  {
    url: "/what/values-behaviours",
    title: "Values Behaviours",
    key: "values_behaviours"
  },
  {
    url: "/now-what/team-dashboard",
    title: "Team Dashboard",
    key: "team_dashboard_screen"
  },
  {
    url: "/now-what/member-dashboard",
    title: "Member Dashboard",
    key: "member_dashboard_screen"
  },
  {
    url: "/now-what/celebrating-success",
    title: "Celebrating Success",
    key: "celebrating_success_screen"
  },
  {
    url: "",
    title: "Contact-CDI",
    key: "contact_us"
  },
  {
    url: "#",
    title: "Terms & Conditions",
    key: "terms_and_conditions"
  },
];

const SubHeaderComponent = ({ selectedTeam: currentTeam, user, presentation, toggleRefresh = null, refresh = null, hasNewData = null, children, teamToUse = null, changedTeam = null, includeCoach = false, ...props }) => {
  const [selectedUserId, setSelectedUserId] = useState("")
  const [selectedStandInId, setSelectedStandInId] = useState("")
  const [selectedTeam, setSelectedTeam] = useState(currentTeam)

  const getLabelText = key => <LabelComponent val={key}/>
  
  useEffect(() => {
    setSelectedTeam(teamToUse ?? currentTeam)
  }, [teamToUse, setSelectedTeam, currentTeam])

  const getPageTitle = () => {
    const activeUrl = window.location.pathname
    const page = pages.filter(page => {
      return page.url === activeUrl
    })[0]
    if(!page) return "No Data";

    return page.key ? getLabelText(page.key) : page.title
  }

  const getSelectedUser = useCallback(
    () => {
      const { combinedMembers, assignedUserLogs } = selectedTeam;

      if (combinedMembers.length > 0) {
        let userId = "";
        const assignedUser = assignedUserLogs.find(log => log && log.url === window.location.pathname)

        if (assignedUser) {
          userId = assignedUser.user.id
        }
        setSelectedUserId(userId)
      }
    },
    [setSelectedUserId, selectedTeam]
  )

  const getSelectedStandIn = useCallback(
    () => {
      const { combinedMembers, assignedUserLogs } = selectedTeam;

      if (combinedMembers.length > 0) {
        let standInId = "";
        const assignedUser = assignedUserLogs.find(log => log && log.url === window.location.pathname)
        if (assignedUser && assignedUser.standIn) {
          standInId = assignedUser.standIn.id
        }
        setSelectedStandInId(standInId)
      }
    },
    [setSelectedStandInId, selectedTeam]
  )

  const getAssignedTeamUsersList = async (id) => {
    const res = await getAssignedTeamUsers(id);
    setSelectedTeam(res.data.team);
  }

  useEffect(() => {
    if (changedTeam !== null) getAssignedTeamUsersList(changedTeam.id);
  }, [changedTeam])

  useEffect(() => {
    if(selectedTeam.id === 0) return;

    getSelectedUser();
    getSelectedStandIn();

  }, [getSelectedUser, getSelectedStandIn, changedTeam]);

  const setAssignedUser = async value => {
    const { assignedUserLogs, id } = selectedTeam;
    const assignedUser = assignedUserLogs.find(log => log && log.url === window.location.pathname);
    try {
      if (assignedUser) {
        await updateUserLog(assignedUser.id, value, selectedStandInId)
        assignedUser.user.id = value
      } else {
        const response = await createUserLog(id, value, selectedStandInId, window.location.pathname)
        assignedUserLogs.push(response.data.createAssignedUserLog)
      }
      setSelectedUserId(value)
    } catch (err) {
      console.log(err)
    }
  }

  const setAssignedStandIn = async value => {
    const { assignedUserLogs, id } = selectedTeam;
    const assignedUser = assignedUserLogs.find(log => log && log.url === window.location.pathname)

    try {
      if (assignedUser) {
        await updateUserLog(assignedUser.id, selectedUserId, value)
        assignedUser.standIn = {id: value}
      } else {
        const response = await createUserLog(id, selectedUserId, value, window.location.pathname)
        assignedUserLogs.push(response.data.createAssignedUserLog)
      }
      setSelectedStandInId(value)
    } catch (err) {
      console.log(err)
    }
  }

  const isActiveItem = (type) => {
    let section = props.location.pathname

    section.indexOf(1)

    section.toLowerCase()

    section = section.split("/")[1]

    return section === type
  }

  const signOutStyle = {

    float: "right"
  }
  const titleStyle = {

    float: "left"
  }

  const onlyTitleScreen = isActiveItem("dashboard") || isActiveItem("teams") || isActiveItem("team")
  const welcome = isActiveItem("welcome")
  const top = presentation.active && !presentation.paused ? "0px" : "100px";

  let usersToUse = [];
  if(includeCoach) {
    usersToUse = [selectedTeam.coach, ...selectedTeam.combinedMembers]
  } else {
    usersToUse = selectedTeam.combinedMembers;
  }

  return (
    <Row className="w-100 app-title-row" style={{ top }}>
      <Col
        xl={onlyTitleScreen ? 6 : 4}
        md={onlyTitleScreen ? 6 : 4}
        xs={onlyTitleScreen ? 6 : 4}
        className="pl-2"
      >
        <table className="w-100 h-100">
          <tbody>
            <tr>
              <td className="text-left" valign="middle">
                <h4 className="app-title d-inline mr-3" style={titleStyle}>
                  {getPageTitle()}
                </h4>
              </td>
            </tr>
          </tbody>
        </table>
      </Col>
      {!onlyTitleScreen && !welcome && (
        <Col xl={4} md={4} xs={4} className="text-center">
          {selectedTeam.combinedMembers[0] !== null &&
            selectedTeam.combinedMembers.length > 0 && (
              <table className="w-100 h-100">
                <tbody>
                  <tr>
                    <td width="10%" valign="middle">
                      <h6 className="app-title">{getLabelText("owners")}</h6>
                    </td>
                    <td width="45%" valign="middle" className="pl-1">
                      <select
                        className="form-control"
                        value={selectedUserId || ""}
                        onChange={(e) => setAssignedUser(e.target.value)}
                      >
                        <option value="" disabled>
                          Not Assigned
                        </option>
                        {usersToUse.map((member, i) => (
                          <option value={member.id} key={i}>
                            {member.firstNames} {member.lastName}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td width="45%" valign="middle" className="pl-1">
                      <select
                        className="form-control"
                        value={selectedStandInId || ""}
                        onChange={(e) => setAssignedStandIn(e.target.value)}
                      >
                        <option value="" disabled>
                          Not Assigned
                        </option>
                        {usersToUse.map((member, i) => (
                          <option value={member.id} key={i}>
                            {member.firstNames} {member.lastName}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
        </Col>
      )}
      <Col
        xl={onlyTitleScreen ? 6 : 4}
        md={onlyTitleScreen ? 6 : 4}
        xs={onlyTitleScreen ? 6 : 4}
        className={"w-100 px-0"}
      >
        <Row className="w-100 text-right pl-2 pr-1 noRow">
          {children}
          {onlyTitleScreen ? null : (
            <table className="ml-2">
              <tbody>
                <tr>
                  {!welcome && (
                    <td valign="middle" className="p-0">
                      <span style={signOutStyle}>
                        {window.location.pathname ===
                        "/so-what/problem-solving" ? (
                          <button
                            className="btn btn-primary mr-2 backBtn-container"
                            onClick={() => props.history.goBack()}
                          >
                            <img
                              src={BackWhite}
                              alt="Back"
                              className="backBtn"
                            />
                          </button>
                        ) : (
                          <Link
                            to="/so-what/problem-solving"
                            className="btn btn-primary mr-2"
                          >
                            <span className="m-0 font-weight-bold">PS</span>
                          </Link>
                        )}
                        {window.location.pathname === "/now-what/actions" ? (
                          <button
                            className="btn btn-primary backBtn-container"
                            onClick={() => props.history.goBack()}
                          >
                            <img
                              src={BackWhite}
                              alt="Back"
                              className="backBtn"
                            />
                          </button>
                        ) : (
                          <Link
                            to="/now-what/actions"
                            className="btn btn-primary"
                          >
                            <span className="m-0 font-weight-bold">NS</span>
                          </Link>
                        )}
                      </span>
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          )}
        </Row>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  selectedTeam: state.teamsReducer.selectedTeam,
  isLoading: state.userReducer.isLoading,
  presentation: state.presentationReducer
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SubHeaderComponent));
