export const ATTENDANCE_STATUSES = [
  "NO_ACTION",
  "PRESENT",
  "PRESENT_BUT_LATE",
  "ON_LEAVE",
  "PLANNED_NON_ATTENDANCE",
  "ABSENT",
];

export const DAY_ATTENDANCE_STATES = [
  "NORMAL_DAY",
  "TRIGGER_DAY",
  "NON_WORKING_DAY",
];
