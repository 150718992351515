import { client } from "./BaseService";
import { gql } from "apollo-boost";

export const LOAD_DEPARTMENT_TYPES_QUERY = gql`
  query($page: Int) {
    departmentTypes(page: $page) {
      id
      name
      orderByString
      hasDepartments
      level
    }
  }
`;

export const GET_DEPARTMENT_TYPES_COUNT = gql`
  query {
    departmentTypesCount
  }
`;

export const CREATE_DEPARTMENT_TYPE_MUTATION = gql`
  mutation CreateDepartmentType($name: String!, $level: DepartmentLevel) {
    createDepartmentType(name: $name, level: $level) {
      id
      name
      level
    }
  }
`;

export const UPDATE_DEPARTMENT_TYPE_MUTATION = gql`
  mutation UpdateDepartmentType($id: ID!, $name: String!, $level: DepartmentLevel) {
    updateDepartmentType(id: $id, name: $name, level: $level) {
      id
      name
      level
    }
  }
`;

export const DELETE_DEPARTMENT_TYPE_MUTATION = gql`
  mutation DeleteDepartmentType($id: ID!) {
    deleteDepartmentType(id: $id) {
      id
    }
  }
`;

export const getDepartmentTypes = async (page = null) => {
  return await client.query({
    query: LOAD_DEPARTMENT_TYPES_QUERY,
    page: page
  });
};
