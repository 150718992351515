import React, { Fragment, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import {
  faCaretSquareLeft,
  faCaretSquareRight
} from "@fortawesome/free-solid-svg-icons";

const Header = ({
  presentation,
  selectedModule,
  changeLoadedModule,
  teamModules,
  loadedModule
}) => {

  const next = useCallback(
    () => {
      const index = teamModules.indexOf(loadedModule);
      const nextIndex = index === (teamModules.length - 1) ? 0 : (index + 1);
      changeLoadedModule(teamModules[nextIndex].id);
    },
    [teamModules, loadedModule, changeLoadedModule]
  )

  const prev = useCallback(
    () => {
      const index = teamModules.indexOf(loadedModule);
      const nextIndex = index === 0 ? teamModules.length - 1 : (index - 1);
      changeLoadedModule(teamModules[nextIndex].id);
    },
    [teamModules, loadedModule, changeLoadedModule]
  )

  return (
    <Fragment>
      <div className={"team-col row coaching-review-title-container"}>
        <div className="col-xl-6 col-lg-9 offset-xl-3 offset-lg-0 col-xs-12 offset-xs-0 pb-2 pt-1 text-left">
          {loadedModule && (!presentation.active || (presentation.active && presentation.paused) || !presentation.schedule[presentation.index].children.length)
            ? (
              <Fragment>
                <table className="w-100 h-100">
                  <tbody>
                    <tr>
                      <td valign="middle" className="pr-2">
                        <button
                          className="button-unstyled d-inline text-white"
                          style={{ color: "#fff!important" }}
                          onClick={() => prev()}
                        >
                          <FontAwesomeIcon
                            className="text-white"
                            size="2x"
                            icon={faCaretSquareLeft}
                          />
                        </button>
                      </td>
                      <td valign="middle" className="text-center cr-header">
                        <Dropdown>
                          <Dropdown.Toggle className={"nav-link w-100"} variant="none" id="dropdown-basic" style={{ whiteSpace: "break-spaces" }}>
                            <h4 className="d-inline"><b>{loadedModule ? loadedModule.name : ""}</b></h4>
                          </Dropdown.Toggle>
                          <Dropdown.Menu style={{ maxHeight: "400px", overflow: "auto" }}>
                            {teamModules.map(({ id, name }, i) => {
                              return <Dropdown.Item key={i} value={i} onClick={(e) => changeLoadedModule(id)}>{name}</Dropdown.Item>
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                      <td valign="middle" className="pl-2">
                        <button
                          className="button-unstyled d-inline text-white"
                          onClick={() => next()}
                        >
                          <FontAwesomeIcon size="2x" icon={faCaretSquareRight} />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Fragment>
            ) : null
          }
        </div>
        <div className="col-lg-3 col-xs-12 text-right">

        </div>
      </div>
    </Fragment>
  )
}

export default Header
