import React, { Component, Fragment } from "react"
import { connect } from 'react-redux'
// bootstrap
import { Table, Button, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap"

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Cancel from '../assets/ButtonIcons/Cancel Blue.svg';
import Delete from '../assets/ButtonIcons/Delete Blue.svg';
import Edit from '../assets/ButtonIcons/Edit Blue.svg';
import Loader from '../assets/ButtonIcons/Loader Blue.svg';
import Save from '../assets/ButtonIcons/Save Blue.svg';

// Service methods
import { deleteUnit, updateUnit, createUnit, getUnits, getUnitsCount } from "../services/UnitService"
import { toast } from 'react-toastify'
import PaginationComponent from "../components/PaginationComponent"
import SubHeaderComponent from "../components/SubHeaderComponent";
import * as DefaultLabels from '../assets/glossary.json';
import LabelComponent from "../components/utils/getCompanyLabel";
const labels = DefaultLabels.default;

function renderTooltip(props, text) {
  return (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );
}

class UnitScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      units: [],
      newUnits: [],
      selectedId: null,
      mode: "",
      unit: "",
      loading: false,
      loadType: "",
      deleteId: 0,
      page: 1,
      totalPages: 0,
      paginateDisabled: false,
      firstIndex: 0,
      lastIndex: 9,
    }
    this.props.toggleDirtyState(true)
    this.props.togglePageLoad(true)
    this.setPage = this.setPage.bind(this)
  }

  async componentDidMount() {
    await this.fetchUnits()
    this.props.togglePageLoad(false)
  }

  fetchUnits = async (newUnit = null) => {
    const { newUnits } = this.state
    const response = await getUnits()
    let units = response.data.units.filter(unit => !newUnits.find(newUnit => newUnit.id === unit.id))

    if(newUnit) {
      units = units.filter(unit => unit.id !== newUnit.id)
    }

    this.setState({
      units: units,
      totalPages: (Math.ceil(response.data.units.length / 10))
    })
  }

  fetchUnitsCount = async () => {
    const response = await getUnitsCount()

    this.setState({
      totalPages: (Math.ceil(response.data.unitCount / 10))
    })
  }

  getLabelText = key => <LabelComponent val={key}/>

  getText = val => {
    const { user } = this.props;

    const companyId = localStorage.getItem("companyId");
    let lang = "EN";
    let langLabels = labels.find(lbl => lbl.lang === lang);
    let def = langLabels.glossary.find(label => label.key === val);

    if(user && user.companies && user.companies.length) {
      const company = user.companies.find(company => company.id === companyId);
      if(company) {
        lang = company.language;
        langLabels = labels.find(lbl => lbl.lang === lang);
        def = langLabels.glossary.find(label => label.key === val);
        if(!def) {
          lang = "EN";
          langLabels = labels.find(lbl => lbl.lang === lang);
          def = langLabels.glossary.find(label => label.key === val);
        }
      }
      if(company && company.companyGlossaries) {
        const custom = company.companyGlossaries.find(label => label.key === val);

        if(custom) return custom.value
      }
    }

    if(!def) {
      console.log(val)
    }
    return def.value
  }

  setPage = async (page) => {
    this.setState({
      paginateDisabled: true
    })
    const firstIndex = (parseInt(page) * 10) - 10
    const lastIndex = (parseInt(page) * 10) - 1

    this.setState({
      paginateDisabled: false,
      page: page,
      firstIndex: firstIndex,
      lastIndex: lastIndex

    })
  }


  toggleMode = (e, mode, id = 0) => {
    e.preventDefault()
    this.cancel()

    let options = {
      selectedId: id,
      mode: mode
    }
    let { units, newUnits } = this.state
    const jointUnits = [...newUnits, ...units]
    if (mode === "create") {
      newUnits.unshift({ name: "", id: 0 })
    } else {
      const unit = jointUnits.filter((unit) => {
        return unit.id === id
      })

      options = {
        ...options,
        name: unit[0].name
      }
    }

    this.setState(options)
    if (mode === "create") this.setPage(1)
  }

  cancel = () => {
    const { mode } = this.state
    this.props.toggleDirtyState()
    let options = {
      selectedId: "",
      mode: "",
      name: ""
    }

    if (mode === "create") {
      let { newUnits } = this.state
      if (newUnits[0].id === 0) {
        newUnits.shift()
      }

    }

    this.setState(options)
  }

  validate = () => {
    const { name } = this.state

    return name.length > 0
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    this.setState({
      loading: true,
      loadType: "create"
    })
    if (this.validate()) {
      try {
        const { name, mode, selectedId, newUnits } = this.state
        let newUnit = null
        if(mode === "create") {
          const response = await createUnit(name)
          newUnit = response.data.createUnit
        } else {
          await updateUnit(selectedId, name)
          if(newUnits.find(newUnit => newUnit.id === selectedId)) {
            const index = newUnits.findIndex(newUnit => newUnit.id === selectedId)
            newUnits[index].name = name
          }
        }

        await this.fetchUnits(newUnit)
        this.cancel()
        if(newUnit) {
          newUnits.unshift(newUnit)
        }
      } catch (error) {
        error.graphQLErrors.map(error => {
          toast.error(error.message)

          return true
        })
      }
    }
    this.setState({
      loading: false,
      loadType: ""
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  deleteItem = async (id) => {
    const { newUnits } = this.state

    this.setState({
      loading: true,
      loadType: "delete",
      deleteId: id
    })
    if (window.confirm(this.getText("delete_department_prompt"))) {
      try {
        await deleteUnit(id)
        await this.fetchUnits()
        if(newUnits.find(unit => unit.id === id)) {
          newUnits.splice(newUnits.findIndex(unit => unit.id === id), 1)
        }
      } catch (error) {
        error.graphQLErrors.map(error => {
          toast.error(error)

          return true
        })
      }
    }

    this.setState({
      loading: false,
      loadType: "",
      deleteId: 0
    })
  }

  unitList = (unit, i) => {
    const { selectedId, name, loading, loadType, deleteId } = this.state
    let unitRow;
    if (selectedId === unit.id) {
      unitRow = (
        <tr key={i}>
          <td>
            <input
              autoFocus
              className="form-control"
              type="text"
              required
              placeholder="Name"
              value={name}
              name="name"
              tabIndex="1"
              onChange={(e) => this.handleChange(e)}
              autoComplete="off"
            />
          </td>
          <td className="text-right">
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, this.getLabelText('save'))}
            >
              <Button className="btn-icons-container" variant="primary" type="submit">
                {loading && loadType === "create"
                  ? (
                    <Fragment>
                      <img src={Loader} alt="Loader" className="loader-spinner"/>
                    </Fragment>
                  )
                  : (
                    <Fragment>
                      <img src={Save} alt="Save" className="btn-icons"/>
                    </Fragment>
                  )
                }
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, this.getLabelText('cancel'))}
            >
              <Button
                className="btn-icons-container"
                variant="danger"
                onClick={this.cancel}
              >
                <img src={Cancel} alt="Cancel" className="btn-icons"/>
              </Button>
            </OverlayTrigger>
          </td>
        </tr>
      )
    } else {
      unitRow = (
        <tr key={i}>
          <td>
            {unit.name}
          </td>
          <td className="text-right">
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, this.getLabelText('edit'))}
            >
              <Button
                className={"btn-icons-container" + (this.state.mode !== "" ? " disabled" : "")}
                variant="primary"
                onClick={(e) => this.toggleMode(e, "edit", unit.id)}
              >
                <img src={Edit} alt="Edit" className="btn-icons"/>
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, this.getLabelText('delete'))}
            >
              <Button className={"btn-icons-container" + (this.state.mode !== "" || unit.hasMeasurements ? " disabled" : "")} variant="danger" onClick={() => this.deleteItem(unit.id)}>
                {loading && loadType === "delete" && deleteId === unit.id
                  ? (
                    <Fragment>
                      <img src={Loader} alt="Loader" className="loader-spinner"/>
                    </Fragment>
                  )
                  : (
                    <Fragment>
                      <img src={Delete} alt="Delete" className="btn-icons"/>
                    </Fragment>
                  )
                }
              </Button>
            </OverlayTrigger>
          </td>
        </tr>
      )
    }

    return unitRow
  }

  render() {
    const { units, newUnits, firstIndex, lastIndex } = this.state

    const jointUnits = [...newUnits, ...units]
    return (
      <Fragment>
        <SubHeaderComponent>
          <Row className="justify-content-center">
            <Col className="text-right" xs={12}>
              <Button className={"btn btn-primary btn-nrml" + (this.state.mode !== "" ? " disabled" : "")} onClick={(e) => this.toggleMode(e, "create")}>{this.getLabelText("add")}<FontAwesomeIcon className="ml-2" icon={faPlus} /></Button>
            </Col>
          </Row>
        </SubHeaderComponent>
        <div className="grey-header-space container-left">
          <Row className="justify-content-center">
            <Col xl={12}>
              <form onSubmit={(e) => this.handleSubmit(e)}>
                <Table striped bordered hover className="standard-tbl standard-units-tbl table-heading-background user-text">
                  <thead>
                  <tr>
                    <th width="85%">{this.getLabelText("units_description")}</th>
                    <th width="15%"></th>
                  </tr>
                  </thead>
                  <tbody>
                  {jointUnits.map((unit, i) => {
                    if(i >= firstIndex && i <= lastIndex) {
                      return this.unitList(unit, i)
                    }

                    return null
                  })}
                  </tbody>
                </Table>
                {
                  this.state.totalPages > 0 ?
                  <div className="pagination_container">
                    <PaginationComponent
                        active={this.state.page}
                        disabled={this.state.paginateDisabled}
                        setPage={this.setPage}
                        total={this.state.totalPages}
                    />
                  </div>
                      : null
                }
              </form>
            </Col>
          </Row>
        </div>
      </Fragment>
    )

  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user
})

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(UnitScreen);
