import React, { useCallback, useEffect, useState, useRef, Fragment } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import { Container, Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useMutation, useLazyQuery, useQuery } from "@apollo/react-hooks";
import debounce from 'lodash/debounce'
import SubHeaderComponent from "../../components/SubHeaderComponent";
import RealtimeRefreshComponent from "../../components/RealtimeRefreshComponent";
// Custom Components
import { ChartHeader, ChartBar, ProjectModal } from "./components";
import ArchiveToggle from "./components/ArchiveToggle";
import ButtonList from "./components/ButtonList";
import OrderButtons from "./components/OrderButtons";
import {
    FETCH_IMPLEMENTATION_TRACKER,
    CREATE_IMPLEMENTATION_TRACKER_MILESTONE_MUTATION,
    UPDATE_IMPLEMENTATION_TRACKER_MILESTONE_MUTATION,
    DELETE_IMPLEMENTATION_TRACKER_MILESTONE_MUTATION,
    CREATE_IMPLEMENTATION_TRACKER_MODULE_MUTATION,
    UPDATE_IMPLEMENTATION_TRACKER_MODULE_MUTATION,
    DELETE_IMPLEMENTATION_TRACKER_MODULE_MUTATION,
    CREATE_ISSUE_MUTATION,
    CREATE_ACTION_MUTATION,
    ARCHIVE_IMPLEMENTATION_TRACKER_MODULE_MUTATION,
    ARCHIVE_IMPLEMENTATION_TRACKER_MILESTONE_MUTATION,
    SET_IMPLEMENTATION_TRACKER_MILESTONES_ORDER,
    GET_START_WEEK
} from "./resources/gql";
import {IMPLEMENTATION_MODULES_QUERY} from "../ImplementationModule/gql";

import { getWeekNumber, getDateFromWeek } from "./resources/utils";
import changeListItemOrder from '../../components/utils/changeListItemOrder';
import * as DefaultLabels from '../../assets/glossary.json';
import LabelComponent from "../../components/utils/getCompanyLabel";
import {exportImplementationTracker} from "../../utils/exportUtils";
import {getStartWeeks} from "../../services/GoalService";
import NextStep from "../../components/shared/NextStep";


const labels = DefaultLabels.default;
const getLabelText = key => <LabelComponent val={key}/>

function getYearList(startMonth, isValues) {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const array = []
    const index = months.indexOf(startMonth)

    for (let i = index; i < months.length; i++) {
        array.push(isValues ? (i + 1) : months[i])
    }

    for (let i = 0; i < index; i++) {
        array.push(isValues ? (i + 1) : months[i])
    }

    return array
}

function renderTooltip(props, text) {
    return (
        <Tooltip id="button-tooltip" {...props}>
            {text}
        </Tooltip>
    );
}

const ImplementationTrackerScreen = ({ selectedTeam, user, togglePageLoad, refresh, hasNewData, toggleRefresh, toggleHasNewData, ...props }) => {
    const [implementations, setImplementations] = useState([]);
    const [currentObject, setCurrentObject] = useState({});
    const [currentType, setCurrentType] = useState(null)
    const [loading, setLoading] = useState(false);
    const [loadingType, setLoadingType] = useState(null)
    const [loadingId, setLoadingId] = useState(0)
    const [modalLoading, setModalLoading] = useState(false)
    const [scrollPosition, setScrollPosition] = useState(0)
    const scrollRef = useRef()
    const activeRef = useRef()
    const headerRef = useRef()
    const [showModal, setShowModal] = useState(false)
    const [users, setUsers] = useState([])
    const [firstLoad, setFirstLoad] = useState(true);
    const [archive, setArchive] = useState(false);
    const [startWeeks, setStartWeeks] = useState([]);
    const [currentStartWeek, setCurrentStartWeek] = useState(null)

    const [modules, setModules] = useState([])

    const { data: implementationModules } = useQuery(IMPLEMENTATION_MODULES_QUERY)

    const starWeeksLoaded = useCallback((data) => {
        setStartWeeks(data.companyStartWeeks);
    }, [setStartWeeks]);

    useQuery(GET_START_WEEK, {
        onCompleted: starWeeksLoaded,
    });

    useEffect(() => {
        if(selectedTeam.id === 0) return;
        const { company } = selectedTeam;

        const months = getYearList(company.finalcialYearEnd, true)
        const period = (new Date().getMonth() + 1) < months[0] ? `${new Date().getFullYear() - 1}${new Date().getFullYear()}` : `${new Date().getFullYear()}${new Date().getFullYear() + 1}`
        const startWeek = startWeeks.find(startWeek => startWeek.period === period)
        setCurrentStartWeek(startWeek)
    }, [selectedTeam, startWeeks, setCurrentStartWeek]);

    useEffect(() => {
        if (!selectedTeam || selectedTeam.id === 0) return;

        let userArr = []

        userArr = [...userArr, ...selectedTeam.combinedMembers]

        if (selectedTeam.coach && !(userArr.find(usr => usr.id === selectedTeam.coach.id))) userArr.push(selectedTeam.coach)

        setUsers(userArr)

    }, [selectedTeam, setUsers])

    const getText = val => {

        const companyId = localStorage.getItem("companyId");
        let lang = "EN";
        let langLabels = labels.find(lbl => lbl.lang === lang);
        let def = langLabels.glossary.find(label => label.key === val);

        if(user && user.companies && user.companies.length) {
            const company = user.companies.find(company => company.id === companyId);
            if(company) {
                lang = company.language;
                langLabels = labels.find(lbl => lbl.lang === lang);
                def = langLabels.glossary.find(label => label.key === val);
                if(!def) {
                    lang = "EN";
                    langLabels = labels.find(lbl => lbl.lang === lang);
                    def = langLabels.glossary.find(label => label.key === val);
                }
            }
            if(company && company.companyGlossaries) {
                const custom = company.companyGlossaries.find(label => label.key === val);

                if(custom) return custom.value
            }
        }

        if(!def) {
            console.log(val)
        }
        return def.value
    }

    const centerActiveItem = useCallback(() => {
        // eslint-disable-next-line react/no-find-dom-node
        const scrollContainer = ReactDOM.findDOMNode(scrollRef.current);
        /* const headerContainer = ReactDOM.findDOMNode(headerRef.current); */

        // eslint-disable-next-line react/no-find-dom-node
        const activeItem = ReactDOM.findDOMNode(activeRef.current);

        if (!activeItem) {
            return;
        }

        const scrollRect = scrollContainer.getBoundingClientRect();
        /* const headerRect = headerContainer.getBoundingClientRect(); */
        const activeRect = activeItem.getBoundingClientRect();
        setScrollPosition(activeRect.left - scrollRect.left)
    },[setScrollPosition])

    const startLoading = (id, type) => {
        setLoading(true)
        setLoadingType(type)
        setLoadingId(id)
    }

    const stopLoading = () => {
        setLoading(false)
        setLoadingType(null)
        setLoadingId(0)
    }

    // Fetch the Projects created by the team
    const onImplementationTrackersLoadCompleted = useCallback((data) => {
        setImplementations(data.team.implementationTrackers);
        togglePageLoad(false);
        setModalLoading(false);
        stopLoading();
        cancel();
    }, [setImplementations, togglePageLoad, setModalLoading]);

    const [fetchImplementationTrackers] = useLazyQuery(FETCH_IMPLEMENTATION_TRACKER, {
        variables: {
            teamId: selectedTeam.id
        },
        onCompleted: onImplementationTrackersLoadCompleted,
    });

    useEffect(() => {
        if (selectedTeam.id === 0) return;

        fetchImplementationTrackers();
    }, [selectedTeam, fetchImplementationTrackers])

    // Implementation Trackers CRUD

    const onProjectMutationCompleted = useCallback(() => {
        fetchImplementationTrackers();
    }, [fetchImplementationTrackers])

    const [saveImplementationTrackerModule] = useMutation(CREATE_IMPLEMENTATION_TRACKER_MODULE_MUTATION, {
        onCompleted: onProjectMutationCompleted
    });

    const [modifyImplementationTrackerModule] = useMutation(UPDATE_IMPLEMENTATION_TRACKER_MODULE_MUTATION, {
        onCompleted: onProjectMutationCompleted,
    });

    const [archiveImplementationTrackerModule] = useMutation(ARCHIVE_IMPLEMENTATION_TRACKER_MODULE_MUTATION, {
        onCompleted: () => {
            fetchImplementationTrackers()
        }
    });

    const [removeImplementationTrackerModule] = useMutation(DELETE_IMPLEMENTATION_TRACKER_MODULE_MUTATION, {
        onCompleted: onProjectMutationCompleted
    });

    const createImplementationTrackerModule = ({ name, from, to, fromDate, toDate, order, implementationModule, milestones }) => {
        saveImplementationTrackerModule({
            variables: {
                team: selectedTeam.id,
                name: name,
                from: from,
                to: to,
                fromDate: fromDate,
                toDate: toDate,
                order: order,
                implementationModule: implementationModule,
                milestones: JSON.stringify(milestones)
            }
        })
    }

    const updateImplementationTrackerModule = ({ name, from, to, fromDate, toDate, order }) => {
        modifyImplementationTrackerModule({
            variables: {
                id: currentObject.id,
                name: name,
                from: from,
                to:to,
                fromDate: fromDate,
                toDate: toDate,
                order: order
            }
        })
    }

    const deleteImplementationTrackerModule = ({ id, name, from, to, fromDate, toDate, order }) => {
        startLoading(id, "delete")
        removeImplementationTrackerModule({
            variables: {
                id: id,
                name: name,
                from: from,
                to:to,
                fromDate: fromDate,
                toDate: toDate,
                order: order
            }
        })
    }

    const archiveImplementationTrackerModuleItem = ({ id, name, from, to, fromDate, toDate, order, archived }) => {
        startLoading(id, "archive");
        archiveImplementationTrackerModule({
            variables: {
                id: id,
                name: name,
                from: from,
                to:to,
                fromDate: fromDate,
                toDate: toDate,
                order: order,
                archived: !archived
            }
        });
    }

    // Implementation Tracker Milestone CRUD

    const onMilestoneMutationCompleted = useCallback(() => {
        fetchImplementationTrackers();
    }, [fetchImplementationTrackers])

    const [saveMilestone] = useMutation(CREATE_IMPLEMENTATION_TRACKER_MILESTONE_MUTATION, {
        onCompleted: onMilestoneMutationCompleted
    });

    const [updateMilestone] = useMutation(UPDATE_IMPLEMENTATION_TRACKER_MILESTONE_MUTATION, {
        onCompleted: onMilestoneMutationCompleted,
    });

    const [archiveMilestone] = useMutation(ARCHIVE_IMPLEMENTATION_TRACKER_MILESTONE_MUTATION, {
        onCompleted: () => {
            fetchImplementationTrackers()
        }
    });

    const [removeMilestone] = useMutation(DELETE_IMPLEMENTATION_TRACKER_MILESTONE_MUTATION, {
        onCompleted: onMilestoneMutationCompleted
    });

    const createMilestone = ({ name, from, to, userId, fromDate, toDate, order }) => {
        saveMilestone({
            variables: {
                implementationModule: currentObject.project.id,
                teamId: selectedTeam.id,
                name: name,
                from: from,
                to: to,
                user: userId,
                fromDate: fromDate,
                toDate: toDate,
                order: order
            }
        })
    }

    const editMilestone = ({ name, from, to, userId, fromDate, toDate, order }) => {
        updateMilestone({
            variables: {
                id: currentObject.id,
                name: name,
                from: from,
                to: to,
                user: userId,
                fromDate: fromDate,
                toDate: toDate,
                order: order
            }
        })
    }

    const archiveMilestoneItem = ({ id, name, from, to, user, fromDate, toDate, order, archived }) => {
        startLoading(id, "archive");
        archiveMilestone({
            variables: {
                id: id,
                name: name,
                from: from,
                to: to,
                user: user.id,
                fromDate: fromDate,
                toDate: toDate,
                order: order,
                archived: !archived
            }
        });
    }

    const deleteMilestone = ({id, name, from, to, user, fromDate, toDate, order }) => {
        startLoading(id, "delete")
        removeMilestone({
            variables: {
                id: id,
                name: name,
                from: from,
                to: to,
                user: user?.id,
                fromDate: fromDate,
                toDate: toDate,
                order: order
            }
        })
    }

    const [setMilestonesOrder] = useMutation(SET_IMPLEMENTATION_TRACKER_MILESTONES_ORDER)

    const submitMilestonesOrder = debounce(async (values) => {
        setMilestonesOrder({
            variables: {
                values : values
            }
        });
    }, 1000);

    useEffect(() => {
        togglePageLoad(true)
        if (!selectedTeam || selectedTeam.id === 0) return
        fetchImplementationTrackers();
    }, [fetchImplementationTrackers, selectedTeam, togglePageLoad]);

    useEffect(() => {
        if(!implementations.length) return;
        if(firstLoad) {
            setFirstLoad(false);
            centerActiveItem();
        }
    }, [centerActiveItem, firstLoad, setFirstLoad, implementations]);

    useEffect(() => {
        // eslint-disable-next-line react/no-find-dom-node
        const scrollContainer = ReactDOM.findDOMNode(scrollRef.current);
        /* const headerContainer = ReactDOM.findDOMNode(headerRef.current); */
        if (scrollContainer) {
            scrollContainer.scrollLeft += scrollPosition;
            /* headerContainer.scrollLeft += scrollPosition; */
        }
    }, [scrollPosition])

    const addProject = () => {
        const newProject = {
            id: 0,
            from: getWeekNumber(new Date()),
            to: (getWeekNumber(new Date()) + 1),
            fromDate: new Date(),
            toDate: getDateFromWeek(getWeekNumber(new Date()) + 1),
            order: implementations.length,
            milestones: [],
            name: "",
            implementationModule: ""
        }

        setCurrentObject(newProject)
        setCurrentType("ImplementationModule")
        setShowModal(true)
    }

    const saveObject = (obj) => {
        if (currentType === "Milestone") {
            if (currentObject.id === 0) {
                createMilestone(obj)
            } else {
                editMilestone(obj)
            }
        } else {
            if (currentObject.id === 0) {
                createImplementationTrackerModule(obj)
            } else {
                updateImplementationTrackerModule(obj)
            }
        }
    }

    const [createIssue] = useMutation(CREATE_ISSUE_MUTATION, {
        onCompleted: ({ createIssue }) => {
            createAction({
                variables: {
                    issueId: createIssue.id,
                    actionType: "IMPLEMENTATION_MILESTONE",
                    status: "NEXT_STEP",
                    description: ""
                }
            })
        }
    });

    const [createAction] = useMutation(CREATE_ACTION_MUTATION, {
        onCompleted: () => {
            fetchImplementationTrackers()
        }
    });

    const postToNextSteps = ({ id, name }) => {
        startLoading(id, "nextSteps");
        createIssue({
            variables: {
                creatorId: selectedTeam.id,
                whatText: name,
                reviewDate: new Date(),
                type: "IMPLEMENTATION_MILESTONE_ISSUE",
                implementationMilestoneId: id,
            }
        });
    }

    const getImplementationTrackers = (id) => {
        if(id) {
            startLoading(id, "cell");
        }
        fetchImplementationTrackers();
    }

    const exportImplementation = async (id) => {
        const implementation = implementations.find(ele => ele.id === id);

        const response = await getStartWeeks();

        await exportImplementationTracker(implementation, response.data.companyStartWeeks);
    }

    const handleOrderChange = (type, dir, index, parentId = null) => {
        let updated;
        let array = [];
        switch(type) {
            default:
                // eslint-disable-next-line no-case-declarations
                const project = implementations.find(({ id }) => id === parentId)

                if(!project) return;

                updated = changeListItemOrder(project.milestones, dir, index);
                setImplementations(implementations.map(item => {
                    return item.id === parentId ? {
                        ...item,
                        milestones: updated
                    } : item
                }));
                updated.map(({ id }) => array.push(id));
                submitMilestonesOrder(array);
                break;
        }
    }

    const cancel = () => {
        setCurrentObject({});
        setCurrentType(null);
        setShowModal(false);
    }

    const getMilestoneUser = (user) => {
        if (user) return `${user.firstNames} ${user.lastName}`

        return null
    }

    const getIndex = (project, milestone = null) => {
        let index = 0;
        if(milestone) {
            project.milestones.map(({ id }, i) => {
                if(id === milestone.id)
                    index = i;

                return true;
            });

            return index;
        }

        implementations.map(({ id }, i) => {
            if(id === project.id)
                index = i;

            return true;
        });

        return index;
    }

    const getNextStepsButton = (milestone) => {
        const { issue } = milestone;

        let status = '';
        if (issue && issue.actions.length && (issue.actions.length !== issue.actions.filter(action => action.status === "COMPLETED").length))
        {
            status = 'NEXT_STEP';
        }
        else if (!issue || !issue.actions.length)
        {
            status = 'NONE';
        }
        else
        {
            status = 'COMPLETED';
        }

        return (
            <NextStep
                loading={loading}
                loadingType={loadingType}
                loadingId={loadingId}
                editType={null}
                issue={issue}
                action={issue?.actions?.length ? issue?.actions[0] : {}}
                actionId={milestone.id}
                status={status}
                nextSteps={() => postToNextSteps(milestone)}
            />
        )
    }

    useEffect(() => {
        if(implementationModules && implementationModules.implementationModules)
        {
            setModules(implementationModules.implementationModules)
        }
    }, [implementationModules])

    const renderMilestone = (milestone, project) => {
        return (
            <Col lg={12} xs={12} className="pr-0">
                <Row>
                    <Col lg={5} xs={5} className="pl-1 pr-1 d-flex align-items-center">
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 25, hide: 40 }}
                            overlay={(props) => renderTooltip({...props}, milestone.name)}
                        >
                            <div className="pt-overflow-container mx-0">
                                <p className="mile-proj m-0">{milestone.name}</p>
                            </div>
                        </OverlayTrigger>
                    </Col>
                    <Col lg={1} xs={1} className={"pl-0 d-flex justify-content-start align-items-center" + (archive ? " disabled" : "")}>
                        {getNextStepsButton(milestone)}
                    </Col>
                    <Col lg={2} xs={2} className="px-0 d-flex align-items-center justify-content-center text-center">
                        {getMilestoneUser(milestone.user)}
                    </Col>
                    <Col lg={4} xs={4} className="tracker-btn align-items-center pl-0 pr-1">
                        {!archive ? (
                            <OrderButtons
                                type="milestone"
                                parentId={project.id}
                                handleOrderChange={handleOrderChange}
                                index={getIndex(project, milestone)}
                                isLastItem={getIndex(project, milestone) === (project.milestones.length - 1)}
                                archive={archive}
                                getLabelText={getLabelText}
                            />
                        ) : null}
                        <ButtonList
                            type="milestone"
                            obj={milestone}
                            setCurrentObject={setCurrentObject}
                            setCurrentType={setCurrentType}
                            setShowModal={setShowModal}
                            deleteItem={deleteMilestone}
                            loading={loading}
                            loadingType={loadingType}
                            loadingId={loadingId}
                            itemIndex={getIndex(project, milestone)}
                            archiveItem={archiveMilestoneItem}
                            archive={archive}
                            getLabelText={getLabelText}
                        />
                    </Col>
                </Row>
            </Col>
        )
    }

    const renderProject = (project, index) => {
        return (
            <Col lg={12} xs={12} className="pr-0">
                <Row>
                    <Col lg={5} xs={5} className="pl-1 pr-0 d-flex align-items-center">
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 25, hide: 40 }}
                            overlay={(props) => renderTooltip({...props}, project.name)}
                        >
                            <div className="pt-overflow-container mx-0">
                                <p className="project-titles m-0">{project.name}</p>
                            </div>
                        </OverlayTrigger>
                    </Col>
                    <Col lg={1} xs={1} className="px-0">

                    </Col>
                    <Col lg={1} xs={1} className="px-0">

                    </Col>
                    <Col className={"line-btn pl-0 pr-1 d-flex align-items-center justify-content-end"} lg={5} xs={5}>
                        <ButtonList
                            type="project"
                            obj={project}
                            setCurrentObject={setCurrentObject}
                            setCurrentType={setCurrentType}
                            setShowModal={setShowModal}
                            deleteItem={deleteImplementationTrackerModule}
                            exportProject={exportImplementation}
                            loading={loading}
                            loadingType={loadingType}
                            loadingId={loadingId}
                            itemIndex={index}
                            archiveItem={archiveImplementationTrackerModuleItem}
                            archive={archive}
                            getLabelText={getLabelText}
                        />
                    </Col>
                </Row>
            </Col>
        )
    }

    const handleScroll = (isFirst) => {
        if (headerRef.current && scrollRef.current) {
            if(isFirst)
            {
                const scrollLeft = headerRef.current.scrollLeft;
                scrollRef.current.scrollLeft = scrollLeft;
            }
            else
            {
                const scrollLeft = scrollRef.current.scrollLeft;
                headerRef.current.scrollLeft = scrollLeft;
            }
        }
    };

    return (
        <Fragment>
            <SubHeaderComponent>
                <Col className="text-right childenSpace">
                    <div className={"refreshBtn"}>
                        <RealtimeRefreshComponent
                            refresh={refresh}
                            hasNewData={hasNewData}
                            toggleRefresh={toggleRefresh}
                        />
                    </div>
                    {!props.archive ?
                        (
                            <button className="btn btn-primary ml-1" onClick={() => addProject()}>
                                {getLabelText("add")} <FontAwesomeIcon icon={faPlus} className="text-white ml-1"></FontAwesomeIcon>
                            </button>
                        ) : null}
                </Col>
            </SubHeaderComponent>
            <div className="grey-header-space">
                <Col className={"text-right px-0"} xs={12}>
                    <ArchiveToggle archive={archive} setArchive={setArchive} getLabelText={getLabelText}/>
                </Col>
                <Container className="project-tracker-container ps-2 pr-0" fluid>
                    <Row className="py-2">
                        <Col lg={6} xs={6} style={{ overflowX: 'auto', overflowY: "hidden" }}>
                            <ChartHeader type="keys" addProject={addProject} archive={archive} getLabelText={getLabelText}/>
                        </Col>
                        <Col lg={6} xs={6} style={{ overflowX: 'auto' }} className="hide-track" ref={headerRef} onScroll={() => handleScroll(true)}>
                            <ChartHeader type="dates" activeRef={activeRef} archive={archive} startWeek={currentStartWeek}/>
                        </Col>
                    </Row>
                    <Row className="tracker" style={{maxHeight: "62.5vh", overflowY: "auto"}}>
                        <Col lg={6} xs={6} style={{ overflow: 'auto' }}>
                            {implementations.filter(({ archived }) => {
                                return archived === archive || archive === true;
                            }).map((project, projectIndex) => {
                                let found = false;
                                if(archive === true)
                                {
                                    project.milestones.forEach(e => {
                                        if(e.archived === true) {
                                            found = true;
                                        }
                                    });
                                }
                                let toRed = false;
                                if(archive === false)
                                {
                                    for (let i = 0; i < project.milestones.length; i++) {
                                        let milestonesStatus = false;
                                        const e = project.milestones[i];
                                        if (e.archived === false) {
                                            if (e.milestoneStates.length > 0) {
                                                e.milestoneStates.sort((a, b) => a.key - b.key);
                                                e.milestoneStates.forEach(state => {
                                                    if (state.state !== 0) {
                                                        milestonesStatus = !(state.state < 2);
                                                    }
                                                })
                                            }
                                        }
                                        toRed = milestonesStatus === true;
                                        if (toRed === true) {
                                            break;
                                        }
                                    }
                                }

                                if(found || project.archived === archive)
                                    return (
                                        <Row className="pt-2 tracker ml-2" key={'prj-1-' + projectIndex}>
                                            <Col lg={12} xs={12} className="px-0">
                                                <>
                                                    <Row className="project-parent-row" style={{ backgroundColor: toRed ? "#C00000" : "#005e85", alignItems: 'center', textJustify: 'center', height: 50, marginTop: projectIndex === 0? "-2px" : null }}>
                                                        {renderProject(project, projectIndex, toRed)}
                                                    </Row>
                                                    {project.milestones && project.milestones.filter(({ archived }) => {
                                                        if(archive === false) {
                                                            return archived === archive;
                                                        }
                                                        return archived === archive || project.archived === archive;
                                                    }).map((milestone, milestoneIndex) => (
                                                        <Row key={'mil-1-' + milestoneIndex.toString()} style={{ alignItems: 'center', marginTop: 10, textJustify: 'center', height: 50 }}>
                                                            {renderMilestone(milestone, project)}
                                                        </Row>
                                                    ))}
                                                </>
                                            </Col>
                                        </Row>
                                    )})}
                        </Col>
                        <Col lg={6} xs={6} style={{ overflow: 'auto', paddingBottom: 10 }} ref={scrollRef} onScroll={() => handleScroll(false)}>
                            {implementations.filter(({ archived }) => {
                                return archived === archive || archive === true;
                            }).map((project, projectIndex) => {
                                let found = false;
                                if(archive === true)
                                {
                                    project.milestones.forEach(e => {
                                        if(e.archived === true) {
                                            found = true;
                                        }
                                    });
                                }
                                if(found || project.archived === archive)
                                    return (
                                        <>
                                            <Row className="mt-2" style={{ height: 50 }} key={'prj-2-' + projectIndex}>
                                                <Col lg={12} xs={12} style={{ height: 50 }}>
                                                    <div style={{ display: 'inline-block', whiteSpace: 'nowrap', height: 50 }}>
                                                        <div style={{ height: 50 }}>
                                                            <div style={{ display: 'inline-block', whiteSpace: 'nowrap', height: 50 }}>
                                                                <ChartBar
                                                                    key={project.id}
                                                                    from={project.from}
                                                                    to={project.to}
                                                                    archived={project.archived}
                                                                    color="#005e85"
                                                                    fromDate={project.fromDate}
                                                                    toDate={project.toDate}
                                                                    rowIndex={projectIndex}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {project.milestones && project.milestones.filter(({ archived }) => {
                                                if(archive === false) {
                                                    return archived === archive;
                                                }
                                                return archived === archive || project.archived === archive;
                                            }).map((milestone, milestoneIndex) => (
                                                <Row key={milestone.id + '-mil-2-' + milestoneIndex.toString()} style={{ alignItems: 'center', marginTop: 10, marginLeft: 10, textJustify: 'center', height: 50 }}>
                                                    <Col lg={8} xs={8}>
                                                        <Row style={{ height: 50 }}>
                                                            <Col lg={12} xs={12}>
                                                                <div style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
                                                                    <div key={milestone.id + '-mil-2-' + milestoneIndex.toString()}>
                                                                        <div style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
                                                                            <ChartBar
                                                                                rowIndex={milestoneIndex}
                                                                                milestone={milestone}
                                                                                from={currentObject.from && currentObject.id === milestone.id ? currentObject.from : milestone.from}
                                                                                to={currentObject.to && currentObject.id === milestone.id ? currentObject.to : milestone.to}
                                                                                fromDate={currentObject.fromDate && currentObject.id === milestone.id ? currentObject.fromDate : milestone.fromDate}
                                                                                toDate={currentObject.toDate && currentObject.id === milestone.id ? currentObject.toDate : milestone.toDate}
                                                                                archived={project.archived}
                                                                                getImplementationTrackers={getImplementationTrackers}
                                                                                loading={{loading: loading, loadingType: loadingType, loadingId: loadingId}}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            ))}
                                        </>
                                    )})}
                        </Col>
                    </Row>
                    <ProjectModal
                        object={currentObject}
                        save={saveObject}
                        cancel={cancel}
                        showModal={showModal}
                        type={currentType}
                        loading={modalLoading}
                        setLoading={setModalLoading}
                        users={users}
                        getText={getText}
                        modules={modules}
                    />
                </Container >
            </div>
        </Fragment>
    )
};

const mapStateToProps = (state) => ({
    selectedTeam: state.teamsReducer.selectedTeam,
    teams: state.teamsReducer.teams,
    user: state.userReducer.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ImplementationTrackerScreen);
