import { client } from "./BaseService";
import { gql } from "apollo-boost";

const getIndividualGoals = async (id) => {
  return await client.query({
    query: gql`query {
      individualGoals(teamId: "${id}") {
        id
        frequency
        numberTo
        numberFrom
        dueDate
        period
        allMetricsCSV {
          frequency
          date
          goalCategory
          measurement
          unit
          target
          result
          resultColour
          dayStatus
        }
        measurement {
          id
          measurement
          unit {
            id
            name
          }
          goalCategory {
            id
            name
          }
        }
      }
    }`
  })
}

const createIndividualGoal = async (teamId, measurement, unit, frequency, numberFrom, numberTo, dueDate, goalCategoryId, createType, isNewUnit, period) => {

  const measurementFields = `measurementId: "${measurement}"`

  return await client.mutate({
    mutation: gql`mutation {
        createIndividualGoal(
          teamId: "${teamId}",
          ${measurementFields},
          frequency:"${frequency}",
          numberFrom: ${numberFrom},
          numberTo: ${numberTo},
          dueDate: "${dueDate}",
          period: "${period}"
        )
        {
          id
        }
      }`,
  });
}

const updateIndividualGoal = async (id, measurementId, frequency, numberFrom, numberTo, dueDate) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateIndividualGoal(
        id: "${id}",
        measurementId: "${measurementId}",
        frequency:"${frequency}",
        numberFrom: ${numberFrom},
        numberTo: ${numberTo},
        dueDate: "${dueDate}"
      )
      {
        id
      }
    }`
  })
}

const deleteIndividualGoal = async(id) => {
  return await client.mutate({
    mutation: gql`mutation {
      deleteIndividualGoal(
        id: "${id}"
      ) {
        id
      }
    }`
  })
}

export {
  getIndividualGoals,
  createIndividualGoal,
  updateIndividualGoal,
  deleteIndividualGoal
}
