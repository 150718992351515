import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { updatePermissions } from '../../redux/services/UserService';
import { Row, Col, Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useQuery, useMutation } from "@apollo/react-hooks";
import displayErrorToast from "../../components/utils/displayErrorToast";
import PaginationComponent from "../../components/PaginationComponent";
import SubHeaderComponent from "../../components/SubHeaderComponent";
import EditRow from "./EditRow";
import DisplayRow from "./DisplayRow";
import {
  CREATE_LEADERSHIP_ROUTINE_MUTATION,
  UPDATE_LEADERSHIP_ROUTINE_MUTATION,
  DELETE_LEADERSHIP_ROUTINE_MUTATION,
  GET_LEADERSHIP_ROUTINES_QUERY_PG,
} from "./resources/gql";
import * as DefaultLabels from '../../assets/glossary.json';
import LabelComponent from "../../components/utils/getCompanyLabel";
const labels = DefaultLabels.default;

const getLabelText = key => <LabelComponent val={key}/>

const LeadershipRoutinesScreen = ({ togglePageLoad, user,  ...props}) => {
  const { loading: loadingData, error, data } = useQuery(GET_LEADERSHIP_ROUTINES_QUERY_PG);
  const [routines, setRoutines] = useState([]);
  const [isCreatingNewItem, setIsCreatingNewItem] = useState(false);
  const [itemBeingEdited, setItemBeingEdited] = useState("");
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [firstIndex, setFirstIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(9);

  const reset = useCallback(
    () => {
      setIsCreatingNewItem(false);
      setItemBeingEdited("");
    },
    [setIsCreatingNewItem, setItemBeingEdited]
  );

  const onError = useCallback(
    (error) => {
      displayErrorToast(error);
      reset();
    },
    [reset]
  );

  const onLoaded = useCallback(
    ({ leadershipRoutinesPG }) => {
      setRoutines(leadershipRoutinesPG)
      setCount(leadershipRoutinesPG.length)
      togglePageLoad(false)
    },
    [setRoutines, togglePageLoad, setCount]
  );

  useEffect(() => {
    if(loadingData) return;
    if(data) onLoaded(data)
    if(error) onError(error)
  }, [loadingData, data, error, onError, onLoaded])

  const getText = val => {
    const companyId = localStorage.getItem("companyId");
    let lang = "EN";
    let langLabels = labels.find(lbl => lbl.lang === lang);
    let def = langLabels.glossary.find(label => label.key === val);

    if(user && user.companies && user.companies.length) {
      const company = user.companies.find(company => company.id === companyId);
      if(company) {
        lang = company.language;
        langLabels = labels.find(lbl => lbl.lang === lang);
        def = langLabels.glossary.find(label => label.key === val);
        if(!def) {
          lang = "EN";
          langLabels = labels.find(lbl => lbl.lang === lang);
          def = langLabels.glossary.find(label => label.key === val);
        }
      }
      if(company && company.companyGlossaries) {
        const custom = company.companyGlossaries.find(label => label.key === val);

        if(custom) return custom.value
      }
    }

    if(!def) {
      console.log(val)
    }
    return def.value
  }


  useEffect(() => {
    togglePageLoad(true)
  }, [togglePageLoad])

  useEffect(() => {
    setTotalPages(Math.ceil(count / 10))
  }, [count, setTotalPages])

  const [createRoutine] = useMutation(CREATE_LEADERSHIP_ROUTINE_MUTATION, {
    onError,
    onCompleted: ({ createLeadershipRoutine }) => {
      setRoutines([createLeadershipRoutine, ...routines]);
      setCount(count + 1)
      reset();
      props.updatePermissions("hasRoutines", true);
    },
  });

  const [updateRoutine] = useMutation(UPDATE_LEADERSHIP_ROUTINE_MUTATION, {
    onError,
    onCompleted: ({ updateLeadershipRoutine }) => {
      const nextRoutines = routines.map((e) =>
        e.id === updateLeadershipRoutine.id ? updateLeadershipRoutine : e
      );
      setRoutines(nextRoutines);
      reset();
    },
  });

  const [deleteRoutine] = useMutation(DELETE_LEADERSHIP_ROUTINE_MUTATION, {
    onError,
    onCompleted: ({ deleteLeadershipRoutine }) => {
      setRoutines(
        routines.filter((e) => e.id !== deleteLeadershipRoutine.id)
      );
      setCount(count - 1);
      reset();
    },
  });

  const onOpenCreate = () => {
    setIsCreatingNewItem(true);
    props.toggleDirtyState()
    setItemBeingEdited("");
  };

  const onOpenEdit = (id) => {
    setIsCreatingNewItem(false);
    props.toggleDirtyState()
    setItemBeingEdited(id);
  };

  const onDelete = (id) => {
    deleteRoutine({ variables: { id } });
  };

  const onSave = ({ id, name }) => {
    if (id) {
      updateRoutine({ variables: { id, name } })
    } else {
      createRoutine({ variables: { name } })
    }
  };

  function setPagination (page) {
    setFirstIndex((parseInt(page) * 10) - 10)
    setLastIndex((parseInt(page) * 10) - 1)
    setPage(page)
  }

  return (
    <>
      <SubHeaderComponent>
        <Row className="justify-content-center">
          <Col className="text-right" xs={12}>
            <Button onClick={onOpenCreate} className={"btn-nrml" + (isCreatingNewItem || itemBeingEdited !== "" ? " disabled" : "")}>
              {getLabelText("add")}
              <FontAwesomeIcon className="ml-2" icon={faPlus} />
            </Button>
          </Col>
        </Row>
      </SubHeaderComponent>
      <div className="grey-header-space container-left">
        <Row className="justify-content-center">
          <Col xl={12}>
            <form>
              <Table striped bordered hover className="skills-tbl table-heading-background user-text">
                <thead>
                <tr>
                  <th width="25%" className="text-center">{getLabelText("leadership_routine")}</th>
                  <th width="15%"></th>
                </tr>
                </thead>
                <tbody>
                <>
                  {isCreatingNewItem ? (
                      <EditRow onSave={onSave} onCancel={reset} />
                  ) : null}
                </>
                {routines && routines.length ? (
                    routines.map(({ name, id, hasTeamRoutines }, i) =>
                        i >= firstIndex && i <= lastIndex ? (
                            id === itemBeingEdited ? (
                                <EditRow
                                    key={id}
                                    name={name}
                                    id={id}
                                    onSave={onSave}
                                    onCancel={reset}
                                />
                            ) : (
                                <DisplayRow
                                    onOpenEdit={onOpenEdit}
                                    onDelete={onDelete}
                                    getText={getText}
                                    key={id}
                                    name={name}
                                    id={id}
                                    disabled={isCreatingNewItem || itemBeingEdited !== ""}
                                    hasTeamRoutines={hasTeamRoutines}
                                />
                            )
                        ) : null
                    )
                ) : (
                    <tr>
                      <td colSpan={2} className="text-center">
                        <label className="cdi-blue-txt">{getLabelText("no_leadership_routine")}</label>
                      </td>
                    </tr>
                )}
                </tbody>
              </Table>
              {totalPages > 1
                  ? (
                      <PaginationComponent
                          active={page}
                          disabled={false}
                          setPage={setPagination}
                          total={totalPages}
                      />
                  ) : null
              }
            </form>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam,
  user: state.userReducer.user
})

const mapDispatchToProps = {
  updatePermissions
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadershipRoutinesScreen);
