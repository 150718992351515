import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControl } from "react-bootstrap";
import {
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-modal';
import LabelComponent from "../../components/utils/getCompanyLabel";

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '30%'
  }
};

const VisitorModal = ({ selectedVisitor, save, cancel, loading, setLoading, showModal }) => {
  const [name, setName] = useState(null);

  useEffect(() => {
    if(!selectedVisitor) return;
    setName(selectedVisitor.name)
  }, [selectedVisitor, setName])

  const saveData = async() => {
    setLoading(true)
    save(name)
  }

  const getLabelText = key => <LabelComponent val={key}/>

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => cancel()}
      contentLabel=""
      style={customStyles}
    >
      <div className="row">
        <div className="col-12 text-center">
          <label className="cdi-blue-txt ml-0 mb-2">{getLabelText("add_visitor")}</label>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <FormControl
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoComplete="off"
          ></FormControl>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-6">
          <button className="btn btn-primary w-100" disabled={loading} onClick={() => saveData()}>
            {loading
              ? (
                <>{getLabelText("saving")} <FontAwesomeIcon icon={faSpinner} className="fa-spin ml-2"></FontAwesomeIcon></>
              ) : (
                <>{getLabelText("save")}</>
              )
            }
          </button>
        </div>
        <div className="col-6">
          <button className="btn btn-default cdi-blue-outline color-blue w-100" onClick={() => cancel()}>{getLabelText("cancel")}</button>
        </div>
      </div>
    </Modal>
  )
}

export default VisitorModal
