import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { useLazyQuery } from "@apollo/react-hooks";
import PartnerCell from "./PartnerCell";
import { connect } from 'react-redux';
import { TEAM_WITH_PARTNERS_QUERY } from "./resources/gql";
import displayErrorToast from "../../components/utils/displayErrorToast";
import classnames from "../../components/utils/classnames";
import SubHeaderComponent from "../../components/SubHeaderComponent";
import RealtimeRefreshComponent from "../../components/RealtimeRefreshComponent";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import LabelComponent from "../../components/utils/getCompanyLabel";
import { canEditHere } from "../../utils/checkPermissions";



const ValueStreamPartnersScreen = ({ selectedTeam, togglePageLoad, refresh, hasNewData, toggleRefresh, toggleHasNewData, user, teams }) => {
  const [partners, setPartners] = useState([]);
  const [partnerStatuses, setPartnerStatuses] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [redir, setRedir] = useState(null)

  const getLabelText = key => <LabelComponent val={key}/>

  const computeData = useCallback(
    (data) => {
      if (!data) return;
      if (!data.team.valuePartners.length) {
        setRedir("/team/value-stream-partners")
        toast.error(getLabelText("error_define_value_stream_partner"))
      }
      setPartners(data.team.valuePartners)
      setPartnerStatuses(data.team.partnerStatuses)
      setPageLoad(false);
      setIsLoading(false);
      togglePageLoad(false)
      if(refresh) {
        toggleRefresh()
        toggleHasNewData()
        togglePageLoad(false)
      }
    },
    [refresh, setIsLoading, togglePageLoad, toggleRefresh, toggleHasNewData, setPartners, setPartnerStatuses, setRedir]
  );

  const [loadData] = useLazyQuery(TEAM_WITH_PARTNERS_QUERY, {
    variables: { id: selectedTeam.id },
    onCompleted: computeData,
    onError: displayErrorToast,
  });

  // useEffect(() => {
  //   if(selectedTeam.id !== 0) {
  //     if(selectedTeam.department.departmentType.level === "LVL1") {
  //       setRedir("/what/customers")
  //       return
  //     }
  //
  //     if(selectedTeam.department.departmentType.level !== "LVL2") {
  //       setRedir("/what/team-attendance")
  //       return
  //     }
  //   }
  // }, [setRedir, selectedTeam])

  useEffect(() => {
    if(refresh) {
      setIsLoading(true)
      togglePageLoad(true)
      if (selectedTeam.id !== 0) loadData()
    }
  }, [refresh, toggleRefresh, toggleHasNewData, selectedTeam, loadData, togglePageLoad])

  useEffect(() => {
    setIsLoading(true);
    togglePageLoad(true)
    if (selectedTeam.id !== 0) loadData();
  }, [selectedTeam, loadData, setIsLoading, togglePageLoad]);

  const getPartnerStatus = (supplierId, customerId) => {
    const partnerStatus = partnerStatuses.find(status => status.customer.id === customerId && status.supplier.id === supplierId)

    return partnerStatus ? partnerStatus : {
      id: 0,
      status: "NOT_APPLICABLE",
      customer: {
        id: customerId
      },
      supplier: {
        id: supplierId
      }
    }
  }

  const canEdit = () => {
    return canEditHere(user, teams, selectedTeam);
  }

  if(redir) {
    return <Redirect to={redir} />
  }


  partners.sort(function(a, b){
    if(a.name.toLowerCase().trim() < b.name.toLowerCase().trim()) { return -1; }
    if(a.name.toLowerCase().trim() > b.name.toLowerCase().trim()) { return 1; }
    return 0;
  })

  return (
    <Fragment>
      <SubHeaderComponent>
        <Col className="text-right childenSpace">
          <div className={"refreshBtn"}>
            <RealtimeRefreshComponent
              refresh={refresh}
              hasNewData={hasNewData}
              toggleRefresh={toggleRefresh}
            />
          </div>
        </Col>
      </SubHeaderComponent>
      <div className="grey-header-space container-left-minus13">
        <Col  className="col-sm-12 d-flex justify-content-between mb-3 vsp-space">
          <Col className="col-sm-1 text-right vsp-title">
            <Col className="title-vertical-180 vsp-titles">{getLabelText("suppliers")}</Col>
          </Col>
          <Col className="col-sm-11 px-0">
            <Row className="overflow-auto vsp-table-space">
              <Col className="vsp-titles" xs={{ span: 9, offset: 3 }}>{getLabelText("customers")}</Col>
              <Row className="align-items-center w-100 mb-3">
                <Col xs={{ span: 9, offset: 3 }}>
                  <Row className="flex-nowrap">
                    {pageLoad ? (
                        <div>
                          Loading Partners ...
                        </div>
                    ) : (
                      partners.map((partner) => (
                          <div key={partner.id} className="ml-0 text-vertical-180 p-2 mr-0">
                            {partner.name}
                          </div>
                      ))
                    )}
                  </Row>
                </Col>
              </Row>
              {pageLoad ? (
                  <div>
                    Loading Partners ...
                  </div>
              ) : (
                  partners.filter(partner => partner.id !== selectedTeam.id).map((y_Partner: partner) => (

                      <Row
                          className={classnames(
                              "align-items-center w-100 mb-2"
                          )}
                          key={`${y_Partner.id}_y`}
                      >
                        <Col className="ml-2">{y_Partner.name}</Col>
                        <Col xs={9}>
                          <Row className="flex-nowrap">
                            {/* For each user, we need either an existing matrix or an empty matrix object */}
                            {partners.map((x_Partner: partner) => (
                                <PartnerCell
                                    key={x_Partner.id}
                                    partner={x_Partner}
                                    partnerStatus={getPartnerStatus(x_Partner.id, y_Partner.id)}
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                    teamId={selectedTeam.id}
                                    canEdit={canEdit}
                                />
                            ))}
                          </Row>
                        </Col>
                      </Row>
                  ))
              )}
            </Row>
          </Col>
        </Col>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam,
  teams: state.teamsReducer.teams,
  user: state.userReducer.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ValueStreamPartnersScreen);
