import { client } from "./BaseService";
import { gql } from "apollo-boost";

export const getMemberData = async (id, period) => {
  return await client.query({
    query: gql`query {
      team(id: "${id}") {
        id
        name
        meetingMembers {
          id
        }
        valuePartners {
          id
          name
          department {
            id
            name
          }
          monthAttendancePercentage(teamId: "${id}", period: "${period}")
          YTDAttendancePercentage(teamId: "${id}", period: "${period}")
          monthAttendanceStatus(teamId: "${id}", period: "${period}") {
            id
            status
          }
          YTDAttendanceStatus(teamId: "${id}", period: "${period}") {
            id
            status
          }
        }
        leaders {
          id
          firstNames
          lastName
          stats(teamId: "${id}", period: "${period}") {
            valuesRaised
            valuesReceived
            innovationsRaised
            innovationsClosed
            nextStepsAssigned
            nextStepsOnTime
            nextStepsLate
            monthAttendancePercentage
            monthAttendanceStatus {
              id  
              status
            }
            YTDAttendanceStatus {
              id  
              status
            }
            valuesRaisedStatus{
              id  
              status
            }
            valuesReceivedStatus{
              id  
              status
            }
            innovationsRaisedStatus{
              id  
              status
            }
            innovationsClosedStatus{
              id  
              status
            }
            nextStepsAssignedStatus{
              id  
              status
            }
            nextStepsOnTimeStatus{
              id  
              status
            }
            nextStepsLateStatus{
              id  
              status
            }
          }
        }
        members {
          id
          firstNames
          lastName
          stats(teamId: "${id}", period: "${period}") {
            valuesRaised
            valuesReceived
            innovationsRaised
            innovationsClosed
            nextStepsAssigned
            nextStepsOnTime
            nextStepsLate
            monthAttendancePercentage
            monthAttendanceStatus {
              id  
              status
            }
            YTDAttendanceStatus {
              id  
              status
            }
            valuesRaisedStatus{
              id  
              status
            }
            valuesReceivedStatus{
              id  
              status
            }
            innovationsRaisedStatus{
              id  
              status
            }
            innovationsClosedStatus{
              id  
              status
            }
            nextStepsAssignedStatus{
              id  
              status
            }
            nextStepsOnTimeStatus{
              id  
              status
            }
            nextStepsLateStatus{
              id  
              status
            }
          }
        }
      }
    }`
  })
}

export const createMemberDashboardMetricData = async(teamId, memberId, periodKey, period, type) => {
  return await client.mutate({
    mutation: gql`mutation {
      createMemberDashboardMetricData(
        teamId: "${teamId}",
        memberId: "${memberId}",
        periodKey: "${periodKey}",
        period: "${period}",
        type: "${type}"
      )
      {
        id
      }
    }`
  })
}

export const updateMemberDashboardMetricData = async(id, status) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateMemberDashboardMetricData(
        id: "${id}",
        status: ${status}
      )
      {
        id
      }
    }`
  })
}

export const createPartnerDashboardMetricData = async(teamId, partnerId, periodKey, period, type) => {
  return await client.mutate({
    mutation: gql`mutation {
      createPartnerDashboardMetricData(
        teamId: "${teamId}",
        partnerId: "${partnerId}",
        periodKey: "${periodKey}",
        period: "${period}",
        type: "${type}"
      )
      {
        id
      }
    }`
  })
}

export const updatePartnerDashboardMetricData = async(id, status) => {
  return await client.mutate({
    mutation: gql`mutation {
      updatePartnerDashboardMetricData(
        id: "${id}",
        status: ${status}
      )
      {
        id
      }
    }`
  })
}
