import React, { Component, Fragment } from 'react';
import { updateTeamPermissions } from '../redux/services/TeamService';
// services
import { getMeasurements, createMeasurement } from '../services/MeasurementService'
import { getUnits, createUnit } from "../services/UnitService"
import { createTeamMeasurement, updateTeamMeasurement, deleteTeamMeasurement, getTeamMeasurements } from '../services/TeamMeasureService'
import { getVision } from '../services/GoalService'
// bootstrap
import { Row, Button, Col, OverlayTrigger, Tooltip, Table, ButtonGroup, ToggleButton } from "react-bootstrap"
import displayErrorToast from '../components/utils/displayErrorToast'
import putOtherLast from "../components/utils/putOtherLast"
// import { fetchTeamMeasurements } from '../redux/services/TeamService';
import { connect } from 'react-redux';
import CreatableSelect from 'react-select/creatable'
import alphabeticalSort from '../components/utils/alphabeticalSort'
import FiscalYearDateSelector from "../components/FiscalYearDateSelector"
import LabelComponent from "../components/utils/getCompanyLabel";
import SubHeaderComponent from "../components/SubHeaderComponent";
import * as XLSX from 'xlsx';
import * as DefaultLabels from '../assets/glossary.json';
// icons
import Add from '../assets/ButtonIcons/Add Blue.svg';
import Cancel from '../assets/ButtonIcons/Cancel Blue.svg';
import Delete from '../assets/ButtonIcons/Delete Blue.svg';
import Edit from '../assets/ButtonIcons/Edit Blue.svg';
import Export from '../assets/ButtonIcons/Export Blue.svg';
import Loader from '../assets/ButtonIcons/Loader Blue.svg';
import PostNextYear from '../assets/ButtonIcons/Post to Next Year Blue.svg';
import Save from '../assets/ButtonIcons/Save Blue.svg';
const labels = DefaultLabels.default;

function renderTooltip(props, text) {
  return (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );
}

class TeamMeasuresScreen extends Component {

  state = {
    goalCategories: [],
    units: [],
    intervals: [
      "Daily",
      "Weekly",
      "Monthly"
    ],
    months: [
      {
        index: 0,
        name: "Jan"
      },
      {
        index: 1,
        name: "Feb"
      },
      {
        index: 2,
        name: "Mar"
      },
      {
        index: 3,
        name: "Apr"
      },
      {
        index: 4,
        name: "May"
      },
      {
        index: 5,
        name: "Jun"
      },
      {
        index: 6,
        name: "Jul"
      },
      {
        index: 7,
        name: "Aug"
      },
      {
        index: 8,
        name: "Sep"
      },
      {
        index: 9,
        name: "Oct"
      },
      {
        index: 10,
        name: "Nov"
      },
      {
        index: 11,
        name: "Dec"
      }
    ],
    interval: "",
    measurement: "",
    measurements: [],
    teamMeasurements: [],
    allMeasurements: [],
    team: [],
    category: "",
    unit: "",
    numberFrom: 0,
    numberTo: 0,
    dueDate: new Date(),
    editId: 0,
    loading: false,
    loadType: "",
    deleteId: 0,
    loadingId: "",
    createType: "",
    pageLoading: true,
    mode: "",
    startMonth: "",
    fixedUnit: false,
    period: "",
    prevPeriod: "",
    nextPeriod: "",
    selectedPeriod: ""
  }

  constructor(props) {
    super(props)

    this.props.toggleDirtyState(true)
    this.props.togglePageLoad(true)
  }

  async componentDidMount() {
    await this.fetchUnits()
    await this.fetchMeasurements()
    if(this.props.selectedTeam.id) {
      await this.featchTeam()
      await this.fetchTeamMeasurements()
      this.pageLoading(false)
      this.props.togglePageLoad(false)
    }

  }
  async componentDidUpdate(prevProps)  {
    if(prevProps.selectedTeam.id !== this.props.selectedTeam.id) {
      this.props.togglePageLoad(true)
      this.pageLoading(true)
      await this.fetchTeamMeasurements()
      this.pageLoading(false)
      this.props.togglePageLoad(false)
    }
  }

  getText = val => {
    const { user } = this.props;

    const companyId = localStorage.getItem("companyId");
    let lang = "EN";
    let langLabels = labels.find(lbl => lbl.lang === lang);
    let def = langLabels.glossary.find(label => label.key === val);

    if(user && user.companies && user.companies.length) {
      const company = user.companies.find(company => company.id === companyId);
      if(company) {
        lang = company.language;
        langLabels = labels.find(lbl => lbl.lang === lang);
        def = langLabels.glossary.find(label => label.key === val);
        if(!def) {
          lang = "EN";
          langLabels = labels.find(lbl => lbl.lang === lang);
          def = langLabels.glossary.find(label => label.key === val);
        }
      }
      if(company && company.companyGlossaries) {
        const custom = company.companyGlossaries.find(label => label.key === val);

        if(custom) return custom.value
      }
    }

    if(!def) {
      console.log(val)
    }
    return def.value
  }

  pageLoading = pageLoading => {
    this.setState({ pageLoading })
  }

  getPeriod = (startMonth, type) => {
    const { months } = this.state

    const fiscalMonth = months.find(month => startMonth.includes(month.name))
    const currentMonth = new Date().getMonth()

    switch(type) {
      case "next":
        return currentMonth < fiscalMonth.index ? `${new Date().getFullYear()}${new Date().getFullYear() + 1}` : `${new Date().getFullYear() + 1}${new Date().getFullYear() + 2}`
      case "prev":
        return currentMonth < fiscalMonth.index ? `${new Date().getFullYear() - 2}${new Date().getFullYear() - 1}` : `${new Date().getFullYear() - 1}${new Date().getFullYear()}`
      default:
        return currentMonth < fiscalMonth.index ? `${new Date().getFullYear() - 1}${new Date().getFullYear()}` : `${new Date().getFullYear()}${new Date().getFullYear() + 1}`
    }

  }

  async fetchMeasurements() {
    const { selectedPeriod } = this.state
    const response = await getMeasurements()
    const company = await getVision()

    this.setState({
      goalCategories: response.data.goalCategories,
      startMonth: company.data.company.finalcialYearEnd,
      period: this.getPeriod(company.data.company.finalcialYearEnd, "current"),
      nextPeriod: this.getPeriod(company.data.company.finalcialYearEnd, "next"),
      prevPeriod: this.getPeriod(company.data.company.finalcialYearEnd, "prev"),
      selectedPeriod: selectedPeriod && selectedPeriod !== "" ? selectedPeriod : this.getPeriod(company.data.company.finalcialYearEnd, "current")
    })
  }

  async fetchTeamMeasurements() {
    const id = this.props.selectedTeam.id
    const { selectedPeriod } = this.state
    const response = await getTeamMeasurements(id)
    const measurements = response.data.teamMeasurements
    this.setState({
      teamMeasurements: measurements.filter(goal => goal.period === selectedPeriod).sort((a, b) => alphabeticalSort(a, b, 'measurement.measurement')),
      allMeasurements: measurements
    })
  }

  setPeriod = (period) => {
    const { allMeasurements } = this.state

    this.setState({
      selectedPeriod: period,
      teamMeasurements: allMeasurements.filter(goal => goal.period === period).sort((a, b) => alphabeticalSort(a, b, 'measurement.measurement')),
    })
  }

  async fetchUnits () {
    const response = await getUnits()

    this.setState({
      units: putOtherLast(response.data.units)
    })
  }

  async featchTeam() {
    // const response = await getTeams()

    this.setState({
      team: this.props.selectedTeam
    })
  }

  toggleCreateMode = (type) => {
    this.setState({
      createType: type,
      measurement: "",
      unit: ""
    })
  }

  getDefaultDate = () => {
    const { startMonth, months, selectedPeriod } = this.state
    const fiscalMonth = months.find(month => startMonth.includes(month.name))
    const EndMonth = months.find(month => month.index === (fiscalMonth.index === 0 ? 11 : fiscalMonth.index - 1))

    return new Date(parseInt(selectedPeriod.substr(selectedPeriod.length - 4)), EndMonth.index + 1, 0);
  }

  toggleAdd = (id, mode) => {
    this.cancel()

    if (mode === "create") {
      this.setState({
        category: id,
        measurment: "",
        unit: "",
        numberFrom: 0,
        numberTo: 0,
        dueDate: this.getDefaultDate(),
        mode: "create",
        createType: "create"
      })
    } else {
      const teamMeasurement = this.state.teamMeasurements.filter((measurement) => {
        return measurement.id === id
      });
      this.setState({
        category: teamMeasurement[0].measurement.goalCategory.id,
        measurement: teamMeasurement[0].measurement.id,
        unit: teamMeasurement[0].measurement.unit.id,
        numberFrom: teamMeasurement[0].numberFrom,
        numberTo: teamMeasurement[0].numberTo,
        dueDate: new Date(teamMeasurement[0].dueDate),
        interval: teamMeasurement[0].frequency,
        mode: "edit",
        editId: teamMeasurement[0].id,
        createType: "exist"
      })

    }
  }

  getLabelText = key => <LabelComponent val={key}/>

  cancel = () => {
    this.props.toggleDirtyState()
    this.setState({
      mode: "",
      category: "",
      measurement: "",
      editId: 0
    })
  }

  validForm = () => {
    const { measurement, interval } = this.state

    return measurement.length > 0 && interval.length > 0
  }

  downloadCSV = (measurement) => {
    const csvData = measurement.allMetricsCSV;

    if(csvData && csvData.length) {
      let downloadLink = document.createElement("a");
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const ws = XLSX.utils.json_to_sheet(csvData.map(metric => {
        delete metric.__typename

        return metric
      }), {
        skipHeader: true
      });
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], {type: fileType});
      let url = URL.createObjectURL(blob);

      downloadLink.href = url;
      downloadLink.download = `[${new Date().toLocaleDateString('en-GB')}] ${measurement.measurement.goalCategory.name + " - " + measurement.measurement.measurement} (${measurement.measurement.unit.name}).xlsx`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

    }
  }



  getUnitName = (measurements) => {
    const { measurement } = this.state
    const item = measurements.filter((option) => {
      return option.id === measurement
    })
    return item.length > 0 ? item[0].unit.name : this.getLabelText("no_data")
  }

  validate = () => {
    const { category, measurement, unit, dueDate } = this.state
    return category.length > 0 && measurement.length > 0 && dueDate.toISOString().length > 0 && unit.length > 0
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    const { category, measurement, units, unit, numberFrom, numberTo, interval, dueDate, editId, createType, goalCategories, selectedPeriod } = this.state
    this.setState({
      loading: true,
      loadType: "create"
    })
    if (this.validate()) {
      try {
        let isNewUnit = false
        let isNewMeasurement = false
        let selectedUnit = unit
        let selectedMeasurement = measurement
        let selectedCategory = goalCategories.filter(goalCategory => goalCategory.id === category)[0]
        if(units.every(({ id }) => id !== unit)) {
          const response = await createUnit(unit)
          selectedUnit = response.data.createUnit.id
          isNewUnit = true
        }

        if(selectedCategory.measurements.every(({ id }) => id !== measurement)) {
          const response = await createMeasurement(category, measurement, selectedUnit)
          selectedMeasurement = response.data.createMeasurement.id
          isNewMeasurement = true
        }
        if (editId !== 0) {
          await updateTeamMeasurement(editId, selectedMeasurement, interval, parseFloat(numberFrom), parseFloat(numberTo), dueDate.toISOString())
        } else {
          await createTeamMeasurement(this.props.selectedTeam.id, selectedMeasurement, selectedUnit, interval, parseFloat(numberFrom), parseFloat(numberTo), dueDate.toISOString(), category, createType, false, selectedPeriod)
        }
        if(createType === "create") await this.fetchMeasurements()
        if (isNewUnit) await this.fetchUnits()
        if (isNewMeasurement) await this.fetchMeasurements()
        await this.fetchTeamMeasurements()
        this.cancel()
        this.props.updateTeamPermissions("hasTeamGoals", true)
      } catch (error) {
        displayErrorToast(error)
      }
    }

    this.setState({
      loading: false,
      loadType: ""
    })
  }

  getSelectText = (array, value) => {
    const selected = array.filter(option => option.value === value)
    return selected.length > 0 ? selected[0].label : value
  }

  handleCreatbleChange = (value, category) => {
    let options = {
      measurement: value
    }

    const measurement = category.measurements.find(measurement => measurement.id === value)

    if(typeof measurement === "undefined") {
      options = {
        ...options,
        fixedUnit: false,
        unit: ""
      }
    } else {
      options = {
        ...options,
        fixedUnit: true,
        unit: measurement.unit.id
      }
    }

    this.setState(options)

  }

  getMeasurementInput = (category) => {
    let measurementInput;
    const { units, measurement, unit, createType, fixedUnit } = this.state
    let selectedUnit = {label: unit, value: unit}
    const unitOptions = units.map(({id, name}) => {
      if(id === unit) {
        selectedUnit = { value: id, label: name }
      }

      return { value: id, label: name }
    })

    let selectedMeasurement = {label: measurement, value: measurement}
    const measurementOptions = category.measurements.map((measure) => {
      if(measure.id === measurement) {
        selectedMeasurement = { value: measure.id, label: measure.measurement }
      }
      return { value: measure.id, label: measure.measurement }
    })

    if(createType === "create") {
      measurementInput = (
        <Fragment>
          <td className="pt-1">
            <CreatableSelect
              className="font-weight-normal"
              onChange={({ value }) => this.handleCreatbleChange(value, category)}
              options={measurementOptions}
              value={selectedMeasurement}
              autoComplete="off"
            />
          <small>{this.getLabelText("create_new_measurement_true_north")}</small>
          </td>
          <td className="pt-1">
            <CreatableSelect
              className={"font-weight-normal" + (fixedUnit ? " disabled" : "")}
              onChange={({ value }) => this.setState({ unit: value })}
              options={unitOptions}
              value={selectedUnit}
              autoComplete="off"
            />
          <small>{this.getLabelText("create_new_unit_true_north")}</small>
          </td>
        </Fragment>
      )
    } else {
      measurementInput = (
        <Fragment>
          <td className="pt-1">
            <select
              className="form-control cdi-dropdown"
              value={this.state.measurement}
              required={true}
              onChange={(e) => this.setState({ measurement: e.target.value })}
              autoComplete="off"
            >
              <option value="" disabled>Select Measurement</option>
              {category.measurements.map((measurement) => (
                <option value={measurement.id} key={measurement.id}>{measurement.measurement}</option>
              ))}
            </select>
          </td>
          <td className="pt-1">
            {this.getUnitName(category.measurements)}
          </td>
        </Fragment>
      )
    }
    return measurementInput
  }

  getFormattedDateString = dueDate => {
    const date = new Date(dueDate)
    const { months }  = this.state
    return months.filter(month => {
      return month.index === date.getMonth()
    })[0].name + " " + date.getFullYear()
  }

  handleDateChange = date => {
    this.setState({ dueDate: new Date(date.getFullYear(), date.getMonth() + 1, 0) })
  }

  renderAddMeasurement = (id, j) => {
    const { loading, loadType } = this.state
    const category = this.state.goalCategories.filter((option) => {
      return option.id === id
    })
    if (this.state.category === id) {
      return (
        <tr key={j}>
          {this.getMeasurementInput(category[0])}
          <td className="pt-1">
            <select
              className="cdi-dropdown form-control"
              name="interval"
              required={true}
              value={this.state.interval}
              onChange={(e) => this.setState({ interval: e.target.value })}
              autoComplete="off"
            >
              <option disabled value="">{this.getText("select_frequency")}</option>
              {this.state.intervals.map((interval, i) => (
                <option key={i}>{interval}</option>
              ))}
            </select>
          </td>
          <td className="pt-1">
            <input
              className="form-control"
              name="numberFrom"
              type="number"
              step="0.01"
              required={true}
              value={this.state.numberFrom}
              onChange={(e) => this.setState({ numberFrom: e.target.value })}
              autoComplete="off"
            ></input>
          </td>
          <td className="pt-1">
            <input
              className="form-control"
              name="numberTo"
              type="number"
              step="0.01"
              required={true}
              value={this.state.numberTo}
              onChange={(e) => this.setState({ numberTo: e.target.value })}
              autoComplete="off"
            ></input>
          </td>
          <td className="pt-1">
            <FiscalYearDateSelector
              action={this.handleDateChange}
              startMonth={this.state.startMonth}
              selectedValue={this.state.dueDate}
              selectedPeriod={this.state.selectedPeriod}
              period={this.state.period}
              nextPeriod={this.state.nextPeriod}
              prevPeriod={this.state.prevPeriod}
              autoComplete="off"
              />
          </td>
          <td className="pt-1 text-right">
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, this.getLabelText("save"))}
            >
              <Button className="btn-icons-container" variant="primary" type="submit">
                {loading && loadType === "create"
                  ? (
                    <img src={Loader} alt="Loader" className="loader-spinner"/>
                  )
                  : (
                    <img src={Save} alt="Save" className="btn-icons"/>
                  )
                }
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, this.getLabelText("cancel"))}
            >
              <Button className="btn-icons-container" variant="primary" onClick={() => this.cancel()}>
                <img src={Cancel} alt="Cancel" className="btn-icons"/>
              </Button>
            </OverlayTrigger>
          </td>
        </tr>
      )
    }
  }

  postToCurrent = async measurement => {
    const { period } = this.state
    this.setState({
      loadingId: measurement.id,
      loadType: "postNext",
      loading: true
    })

    if (window.confirm(this.getText("post_to_current_prompt"))) {
      try {
        await createTeamMeasurement(
          this.props.selectedTeam.id,
          measurement.measurement.id,
          measurement.measurement.unit.id,
          measurement.frequency,
          parseFloat(measurement.numberFrom),
          parseFloat(measurement.numberTo),
          new Date(new Date(measurement.dueDate).getFullYear(), new Date(measurement.dueDate).getMonth(), new Date(measurement.dueDate).getDate()).toISOString(),
          measurement.measurement.goalCategory.id,
          "create",
          false,
          period
        )

        await this.fetchTeamMeasurements()
      } catch (err) {
        displayErrorToast(err)
      }
    }
    this.setState({
      deleteId: 0,
      loadType: "",
      loading: false
    })
  }

  postToNext = async measurement => {
    const { nextPeriod } = this.state
    this.setState({
      loadingId: measurement.id,
      loadType: "postNext",
      loading: true
    })

    if (window.confirm(this.getText("post_to_next_prompt"))) {
      try {
        await createTeamMeasurement(
          this.props.selectedTeam.id,
          measurement.measurement.id,
          measurement.measurement.unit.id,
          measurement.frequency,
          parseFloat(measurement.numberFrom),
          parseFloat(measurement.numberTo),
          new Date(new Date(measurement.dueDate).getFullYear() + 1, new Date(measurement.dueDate).getMonth(), new Date(measurement.dueDate).getDate()).toISOString(),
          measurement.measurement.goalCategory.id,
          "create",
          false,
          nextPeriod
        )

        await this.fetchTeamMeasurements()
      } catch (err) {
        displayErrorToast(err)
      }
    }
    this.setState({
      deleteId: 0,
      loadType: "",
      loading: false
    })
  }

  deleteItem = async id => {
    this.setState({
      deleteId: id,
      loadType: "delete",
      loading: true
    })
    if (window.confirm(this.getText("delete_department_prompt"))) {
      try {
        await deleteTeamMeasurement(id)
        await this.fetchTeamMeasurements()
      } catch (error) {
        displayErrorToast(error)
      }
    }
    this.setState({
      deleteId: 0,
      loadType: "",
      loading: false
    })
  }

  setEdit = (id, measurement) => {
    this.setState({
      editId: measurement.id,
      category: id,
      measurement: measurement.measurement,
      unit: measurement.units
    })
  }

  getCategoryMeasurements = (id) => {
    const {teamMeasurements} = this.state
    const category = this.state.goalCategories.filter((option) => {
      return option.id === id
    })
    const measurements = category[0].measurements

    const array = []
    teamMeasurements.map((measurement) => {
      if(measurements.filter(option => option.id === measurement.measurement.id).length > 0) {
        array.push(measurement)
      }

      return true
    })

    return array
  }

  renderMeasurementRow = (j, teamMeasurement, categoryId) => {
    const { loading, loadType, deleteId, loadingId, period, selectedPeriod, prevPeriod } = this.state
    const category = this.state.goalCategories.filter((option) => {
      return option.id === categoryId
    })
    const measurements = category[0].measurements

    const item = measurements.filter((option) => {
      return option.id === teamMeasurement.measurement.id
    })
    const measurement = item[0]

    return (
      <tr key={j}>
        <td className="pt-1" valign="middle"><p className="cdi-blue-txt">{teamMeasurement.measurement.measurement}</p></td>
        <td className="pt-1" valign="middle"><p className="cdi-blue-txt">{measurement.unit.name}</p></td>
        <td className="pt-1" valign="middle"><p className="cdi-blue-txt">{teamMeasurement.frequency}</p></td>
        <td className="pt-1" valign="middle"><p className="cdi-blue-txt">{teamMeasurement.numberFrom}</p></td>
        <td className="pt-1" valign="middle"><p className="cdi-blue-txt">{teamMeasurement.numberTo}</p></td>
        <td className="pt-1" valign="middle"><p className="cdi-blue-txt">{this.getFormattedDateString(teamMeasurement.dueDate)}</p></td>
        {this.props.permissions.canEdit && (<td className="pt-1 text-right" valign="middle">
          {teamMeasurement.period !== this.state.nextPeriod ? (
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, this.getLabelText("export"))}
            >
              <Button className={"btn-icons-container" + (this.state.mode !== "" || !teamMeasurement.allMetricsCSV || (teamMeasurement.allMetricsCSV && !teamMeasurement.allMetricsCSV.length) ? " disabled" : "")} variant="primary" onClick={() => this.downloadCSV(teamMeasurement)}>
                <img src={Export} alt="Export" className="btn-icons"/>
              </Button>
            </OverlayTrigger>
          ) : null}
          {teamMeasurement.period !== this.state.prevPeriod ? (
          <OverlayTrigger
            placement="top"
            delay={{ show: 25, hide: 40 }}
            overlay={(props) => renderTooltip({...props}, this.getLabelText("edit"))}
          >
            <Button className={"btn-icons-container" + (this.state.mode !== "" ? " disabled" : "")} variant="primary" onClick={() => this.toggleAdd(teamMeasurement.id, "edit")}>
              <img src={Edit} alt="Editt" className="btn-icons"/>
            </Button>
          </OverlayTrigger>
          ) : null}
          {teamMeasurement.period !== this.state.prevPeriod ? (
          <OverlayTrigger
            placement="top"
            delay={{ show: 25, hide: 40 }}
            overlay={(props) => renderTooltip({...props}, this.getLabelText("delete"))}
          >
            <Button className={"btn-icons-container" + (this.state.mode !== "" ? " disabled" : "")} variant="primary" onClick={() => this.deleteItem(teamMeasurement.id)}>
              {loading && loadType === "delete" && deleteId === teamMeasurement.id
                ? (
                  <img src={Loader} alt="Loader" className="loader-spinner"/>
                )
                : (
                  <img src={Delete} alt="Delete}" className="btn-icons"/>
                )
              }
            </Button>
          </OverlayTrigger>
          ) : null}
          {selectedPeriod === period && (
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, this.getLabelText("post_to_next"))}
            >
              <Button className={"btn-icons-container" + (loading && loadType === "postNext" && loadingId === teamMeasurement.id ? " disabled" : "")} variant="primary" onClick={() => this.postToNext(teamMeasurement)}>
                {loading && loadType === "postNext" && loadingId === teamMeasurement.id
                  ? (
                    <img src={Loader} alt="Loader" className="loader-spinner"/>
                  )
                  : (
                    <img src={PostNextYear} alt="PostNextYear}" className="btn-icons"/>
                  )
                }
              </Button>
            </OverlayTrigger>
          )}
          {selectedPeriod === prevPeriod && (
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, this.getLabelText("post_to_current"))}
            >
              <Button className={"btn-icons-container" + (loading && loadType === "postNext" && loadingId === teamMeasurement.id ? " disabled" : "")} variant="primary" onClick={() => this.postToCurrent(teamMeasurement)}>
                {loading && loadType === "postNext" && loadingId === teamMeasurement.id
                  ? (
                    <img src={Loader} alt="Loader" className="loader-spinner"/>
                  )
                  : (
                    <img src={PostNextYear} alt="PostNextYear" className="btn-icons"/>
                  )
                }
              </Button>
            </OverlayTrigger>
          )}
        </td>)}
      </tr>
    )
  }


  render() {
    const { pageLoading } = this.state
    return (
      <Fragment>
        <SubHeaderComponent>
          <Row className="justify-content-center">
            <Col className="text-right">
              <ButtonGroup toggle className="ml-1">
                <ToggleButton
                  type="radio"
                  variant="secondary"
                  name="createType"
                  value="0"
                  checked={this.state.selectedPeriod === this.state.prevPeriod}
                  onChange={() => this.setPeriod(this.state.prevPeriod)}
                >
                  {this.getLabelText("previous")}
                </ToggleButton>
                <ToggleButton
                  type="radio"
                  variant="secondary"
                  name="createType"
                  value="0"
                  checked={this.state.selectedPeriod === this.state.period}
                  onChange={() => this.setPeriod(this.state.period)}
                >
                  {this.getLabelText("current")}
                </ToggleButton>
                <ToggleButton
                  type="radio"
                  variant="secondary"
                  name="createType"
                  value="1"
                  checked={this.state.selectedPeriod === this.state.nextPeriod}
                  onChange={() => this.setPeriod(this.state.nextPeriod)}
                >
                  {this.getLabelText("next")}
                </ToggleButton>
              </ButtonGroup>
            </Col>
          </Row>
        </SubHeaderComponent>
        <div className="grey-header-space container-left">
          <Row className="justify-content-center">
            <Col xs="12">
              <Table bordered className="table-heading-background bordered-minus-2">
                <tbody>
                {pageLoading
                    ? null: (
                        this.state.goalCategories.map((category, i) => (
                            <Fragment key={i}>
                              <tr>
                                <td width="90%" valign="top" className="bordered-fix">
                                  <div className="w-100 mb-3 table-sub-heading">
                                    <h5 className="d-inline">{category.name}</h5>
                                    {this.props.permissions.canEdit && (<div className="d-inline" style={{ float: "right" }}>
                                      {this.state.selectedPeriod !== this.state.prevPeriod ? (
                                        <OverlayTrigger
                                          placement="top"
                                          delay={{ show: 25, hide: 40 }}
                                          overlay={(props) => renderTooltip({...props}, this.getLabelText("add"))}
                                        >
                                          <Button className={"btn-icons-container"  + (this.state.mode !== "" ? " disabled" : "")} variant="primary" onClick={() => this.toggleAdd(category.id, "create")}>
                                            <img src={Add} alt="Add" className="btn-icons"/>
                                          </Button>
                                        </OverlayTrigger>
                                      ) : null}
                                    </div>)}
                                  </div>
                                  <form onSubmit={(e) => this.handleSubmit(e)}>
                                    <Table striped bordered hover className="standard-tbl standard-goal-tbl user-txt">
                                      <thead>
                                      <tr>
                                        <th width="25%">{this.getLabelText("measurement")}</th>
                                        <th width="10%">{this.getLabelText("unit")}</th>
                                        <th width="10%">{this.getLabelText("frequency")}</th>
                                        <th width="10%">{this.getLabelText("from")}</th>
                                        <th width="10%">{this.getLabelText("to")}</th>
                                        <th width="10%">{this.getLabelText("by")}</th>
                                        {this.props.permissions.canEdit && (<th width="15%"></th>)}
                                      </tr>
                                      </thead>
                                      <tbody>
                                      {this.state.mode === "create" ? this.renderAddMeasurement(category.id, 0) : null}
                                      {this.getCategoryMeasurements(category.id).length > 0
                                          ? (
                                              this.getCategoryMeasurements(category.id).map((measurement, j) => (
                                                  this.state.editId === measurement.id
                                                      ? this.renderAddMeasurement(category.id, j)
                                                      : this.renderMeasurementRow(j, measurement, category.id)
                                              ))
                                          ) : (
                                              <tr>
                                                <td colSpan="7" className="text-center">
                                                  <p className="cdi-blue-txt">{this.getLabelText("no_measurements_error")}</p>
                                                </td>
                                              </tr>
                                          )
                                      }
                                      </tbody>
                                    </Table>
                                  </form>

                                </td>
                              </tr>
                            </Fragment>
                        ))
                    )
                }
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam,
  user: state.userReducer.user
})

const mapDispatchToProps = {
  updateTeamPermissions
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamMeasuresScreen);
