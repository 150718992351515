import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';

import rootReducer from './reducers/RootReducer';

const store = createStore(rootReducer, composeWithDevTools(
  applyMiddleware(thunk),
));

export {
  store,
};
