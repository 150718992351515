export const ATTENDANCE_STATUSES = [
  "RED",
  "BLUE"
];

export const PERIOD_STATES = [
  "NORMAL",
  "TRIGGER",
  "NON_WORKING",
];

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];
