import { client } from "./BaseService";
import { gql } from "apollo-boost";

export const createUserLog = async(teamId, userId, standInId, teamMeasurementId, periodKey) => {
  return await client.mutate({
    mutation: gql`mutation {
      createTeamGoalAssignedUserLog(
        teamId: "${teamId}",
        userId: "${userId ? userId : ''}",
        standInId: "${standInId ? standInId : ''}",
        teamMeasurementId: "${teamMeasurementId}",
        periodKey: "${periodKey}"
      )
      {
        id
        periodKey
        teamMeasurement {
          id
        }
        user {
          id
        }
        standIn {
          id
        }
      }
    }`
  })
}

export const updateUserLog = async(id, userId, standInId) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateTeamGoalAssignedUserLog(
        id: "${id}",
        userId: "${userId ? userId : ''}",
        standInId: "${standInId ? standInId : ''}"
      )
      {
        id
        periodKey
        teamMeasurement {
          id
        }
        user {
          id
        }
        standIn {
          id
        }
      }
    }`
  })
}
