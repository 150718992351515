import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-sortable-tree/style.css"; // This only needs to be imported once in your app
import "./index.css";
import App from "./App";
import { client } from "./services/BaseService";

// there is a better way to do this
if (!window.location.host.includes("localhost")) {
  Sentry.init({
    dsn:
      "https://2e4e3aab86f141638a03bd9adae9e151@o294823.ingest.sentry.io/5244698",
  });
}
ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
);
