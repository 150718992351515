import { gql } from "apollo-boost";

export const GET_RECOGNITIONS = gql`
  query {
    recognitionTypes {
      id
      name
    }
  }
`;

export const GET_TEAM_SUCCESSES = gql`
  query getTeamSuccesses($teamId: String!, $period: String!, $recognitionId: String!) {
    teamSuccesses (teamId: $teamId, period: $period, recognitionId: $recognitionId) {
      id
      user {
        id
        firstNames
        lastName
        avatar
      }
      period
      month
    }
  }
`;

export const CREATE_TEAM_SUCCESS_MUTATION = gql`
  mutation createTeamSuccessMutation($teamId: String!, $userId: String!, $recognitionId: String!, $period: String!, $month: Int!) {
    createTeamSuccess (teamId: $teamId, userId: $userId, recognitionId: $recognitionId, period: $period, month: $month) {
      id
      user {
        id
        firstNames
        lastName
        avatar
      }
      period
      month
    }
  }
`;

export const UPDATE_TEAM_SUCCESS_MUTATION = gql`
  mutation updateTeamSuccessMutation($id: ID!, $userId: String!) {
    updateTeamSuccess (id: $id, userId: $userId) {
      id
      user {
        id
        firstNames
        lastName
        avatar
      }
      period
      month
    }
  }
`;

export const DELETE_TEAM_SUCCESS_MUTATION = gql`
  mutation deleteTeamSuccessMutation($id: ID!) {
    deleteTeamSuccess (id: $id) {
      id
      user {
        id
        firstNames
        lastName
        avatar
      }
      period
      month
    }
  }
`;
