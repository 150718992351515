import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Row, Col, Button } from "react-bootstrap";
import TeamGoalsContent from "./TeamGoalsContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import {
  COMPANY_MEASUREMENTS_LIST_QUERY,
  COMPANY_MEASUREMENT_DATA_QUERY,
  TEAM_ATTENDANCE_DATA_QUERY,
} from "./gql";
import { connect } from 'react-redux';
import { getPeriodKey } from "./hooks";
import { createUserLog, updateUserLog } from "../../services/BusinessResultAssignedUserLogService"
import RealtimeRefreshComponent from "../../components/RealtimeRefreshComponent";
import LabelComponent from "../../components/utils/getCompanyLabel";
import ToastComponent from '../../components/ToastComponent';
import { toast } from "react-toastify";
import { getTeamBusinessResults} from "../../services/TeamMeasureService";
import Excel from 'exceljs';
import { saveAs } from 'file-saver';

const workSheetName = 'Worksheet-1';
const getLabelText = key => <LabelComponent val={key}/>

function getYearList(startMonth, isValues) {
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const array = []
  const index = months.indexOf(startMonth)

  for(let i = index; i < months.length; i++) {
    array.push(isValues ? (i + 1) : months[i])
  }

  for(let i = 0; i < index; i++) {
    array.push(isValues ? (i + 1) : months[i])
  }

  return array
}

function getYearValues(startMonth) {
  let array = []
  const monthValues = getYearList(startMonth, true)
  monthValues.map((month, index) => {
    let date = new Date(new Date().getFullYear(), (monthValues[0] - 1) + index, 2)

    array.push(date.getFullYear())

    return true
  })

  return array
}

function setSelectedIndex(presentation) {
  return localStorage.getItem("selectedBusinessResult") && localStorage.getItem("selectedBusinessResult") !== "" ? localStorage.getItem("selectedBusinessResult") : 0
}

function sortMeasures(measures, presentation, firstMonth, date) {
  const months = getYearList(firstMonth, true)
  const period = (date.getMonth() + 1) < months[0] ? `${date.getFullYear() - 1}${date.getFullYear()}` : `${date.getFullYear()}${date.getFullYear() + 1}`

  return measures.filter(measure => measure.period === period).sort((a,b) => a.measurement.goalCategory.order - b.measurement.goalCategory.order)
}

const BusinessResultsScreen = ({ selectedTeam, user, presentation, refresh, hasNewData, toggleRefresh, toggleHasNewData, setAdditionalParam, togglePageLoad }) => {
  const [date, setDate] = useState(new Date());
  const [teamGoals, setTeamGoals] = useState([]);
  const [teamAttendances, setTeamAttendances] = useState([]);
  const [period, setPeriod] = useState('MONTHLY');
  const [periodValues, setPeriodValues] = useState([]);
  const [monthList, setMonthList] = useState(["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]);
  const [monthValues, setMonthValues] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [selectedGoalIndex, setSelectedGoalIndex] = useState();
  const [loadedGoal, setLoadedGoal] = useState(null);
  const [loadedGoals, setLoadedGoals] = useState([]);
  const [isTrend, setIsTrend] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState()
  const [selectedStandInId, setSelectedStandInId] = useState()
  const [selectedId, setSelectedId] = useState("")
  const [fiscalYear, setFiscalYear] = useState(1)
  const [selectedCompany] = useState(localStorage.getItem("companyId"))
  const [hasNext, setHasNext] = useState(false)
  const [hasPrev, setHasPrev] = useState(false)
  const [showNext, setShowNext] = useState(false)
  const [showPrev, setShowPrev] = useState(false)
  const [linkedId, setLinkedId] = useState("")
  const [colour, setColour] = useState(selectedTeam.company.color)
  const [goalsLoadedComplete, setGoalsLoadedComplete] = useState(false)
  const [error, setEerror] = useState("");

  useEffect(() => {
    setColour(selectedTeam.company.color)
  }, [selectedTeam, setColour])

  const onListComplete = useCallback(
    (data) => 
    {
      if (data.goals) {
        setLoadedGoals(data.goals);

        let sortedMeasures = sortMeasures(data.goals, presentation, selectedTeam.company.finalcialYearEnd, date);
        if (sortedMeasures.length > 0) {
          setTeamGoals(sortedMeasures)
        }
        else {
          setGoalsLoadedComplete(true);
          toast.error('No goals found for this year.')
        }
      }
    },
    [setTeamGoals, presentation, selectedTeam, date]
  );

  const onTeamAttendanceDataComplete = useCallback(
    (data) => setTeamAttendances(data.company ? data.company.attendances : []),
    [setTeamAttendances]
  );

  useQuery(TEAM_ATTENDANCE_DATA_QUERY, {
    // set the team id so we get the correct attendances for the correct team.
    variables: { id: selectedTeam.id },
    onCompleted: onTeamAttendanceDataComplete,
  });

  useQuery(COMPANY_MEASUREMENTS_LIST_QUERY, {
    onCompleted: onListComplete,
  });

  const onLoadComplete = useCallback(
    (data) => {
      setLoadedGoal(data.goal)
      if(refresh) {
        toggleRefresh()
        toggleHasNewData()
        togglePageLoad(false)
      }
    },
    [setLoadedGoal,toggleRefresh,toggleHasNewData, refresh, togglePageLoad]
  );

  const [getGoal, { loading: isLoadingGoal }] = useLazyQuery(
    COMPANY_MEASUREMENT_DATA_QUERY,
    {
      onCompleted: onLoadComplete,
    }
  );

  const updateGoalMetricsFromDelta = (metricDelta) => {
    setLoadedGoal((goal) => {
      const companyMetrics = [...goal.companyMetrics];
      const index = goal.companyMetrics.findIndex(m => m.periodValue === metricDelta.periodValue)
      if (index > -1) companyMetrics[index] = { ...companyMetrics[index], ...metricDelta }
      else if (index === -1) companyMetrics.push(metricDelta)
      return { ...goal, companyMetrics };
    });
  };

  const setSelectedUser = useCallback(() => {
    const { businessResultAssignedUserLogs, combinedMembers } = selectedTeam
    if(selectedTeam.id && loadedGoal) {
      let userId = combinedMembers[0]?.id
      let standInId = combinedMembers[0]?.id
      const assignedUser = businessResultAssignedUserLogs.find(log => log.goal && log.goal.id === loadedGoal.id)
      if(assignedUser) {
        userId = assignedUser.user ? assignedUser.user.id : userId
        standInId = assignedUser.standIn ? assignedUser.standIn.id : standInId
      }
      setSelectedUserId(userId)
      setSelectedStandInId(standInId)
    }
  }, [selectedTeam, loadedGoal])

  const setAssignedUser = async value => {
    const { businessResultAssignedUserLogs, id } = selectedTeam
    const assignedUser = businessResultAssignedUserLogs.find(log => log.goal && log.goal.id === loadedGoal.id)

    try {
      setSelectedUserId(value)
      if(assignedUser) {
        await updateUserLog(assignedUser.id, value, selectedStandInId)
        assignedUser.user.id = value
      } else {
        const response = await createUserLog(id, value, selectedStandInId, loadedGoal.id, `${date.getFullYear()}`)
        businessResultAssignedUserLogs.push(response.data.createBusinessResultAssignedUserLog)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const setAssignedStandIn = async value => {
    const { businessResultAssignedUserLogs, id } = selectedTeam
    const assignedUser = businessResultAssignedUserLogs.find(log => log.goal && log.goal.id === loadedGoal.id)

    try {
      setSelectedStandInId(value)
      if(assignedUser) {
        await updateUserLog(assignedUser.id, selectedUserId, value)
        assignedUser.standIn.id = value
      } else {
        const response = await createUserLog(id, selectedUserId, value, loadedGoal.id, `${date.getFullYear()}`)
        businessResultAssignedUserLogs.push(response.data.createBusinessResultAssignedUserLog)
      }
    } catch (err) {
      console.log(err)
    }
  }
  
  const loadGoal = useCallback(() => {
    if(linkedId && linkedId !== "") {
      getGoal({
        variables: {
          id: linkedId,
          teamId: selectedTeam.id,
          period: "MONTH",
          periodKey: getPeriodKey(fiscalYear, date),
          periodValues: [1,2,3,4,5,6,7,8,9,10,11,12],
        },
      });
    } else {
      getGoal({
        variables: {
          id: teamGoals[selectedGoalIndex] ? teamGoals[selectedGoalIndex].id : teamGoals[0].id,
          teamId: selectedTeam.id,
          period: "MONTH",
          periodKey: getPeriodKey(fiscalYear, date),
          periodValues: [1,2,3,4,5,6,7,8,9,10,11,12],
        },
      });
    }

    if(user && user.companies && user.companies.length > 0) {
      const company = user.companies.find(comp => comp.id === selectedCompany)

      if(company) {
        setMonthList(getYearList(company.finalcialYearEnd, false))
        setMonthValues(getYearList(company.finalcialYearEnd, true))
        setYearList(getYearValues(company.finalcialYearEnd))
      }
    }
  }, [date, getGoal, teamGoals, selectedGoalIndex, user, selectedTeam, fiscalYear, selectedCompany, linkedId])

  useEffect(() => {
    if(loadedGoal && loadedGoal.nextYearLink) {
      setHasNext(true)
    } else {
      setHasNext(false)
    }

    if(loadedGoal && loadedGoal.prevYearLink) {
      setHasPrev(true)
    } else {
      setHasPrev(false)
    }

  }, [loadedGoal, setHasPrev, setHasNext])

  useEffect(() => {
    setShowNext(hasNext)
    setShowPrev(hasPrev)

  }, [date, setShowPrev, setShowNext, hasNext, hasPrev])

  useEffect(() => {
    if (!teamGoals.length) return;
    let index = setSelectedIndex(presentation)
    setSelectedGoalIndex(index)
    loadGoal();

  }, [selectedTeam, period, periodValues, teamGoals, setSelectedGoalIndex, loadGoal, presentation]);

  useEffect(() => {
    if (!teamGoals.length) return;
    setSelectedUser()
  }, [selectedTeam, period, periodValues, loadedGoal, setSelectedUser, teamGoals]);

  useEffect(() => {
    if(refresh) {
      togglePageLoad(true)
      setSelectedUser()
      loadGoal();
    }
  }, [refresh, toggleRefresh, toggleHasNewData, setSelectedUser, togglePageLoad, loadGoal])

  useEffect(() => {
    if(loadedGoal) {
      setAdditionalParam(loadedGoal.id)
    }
  }, [loadedGoal, setAdditionalParam])

  useEffect(() => {
    setSelectedId(selectedTeam.id)
  }, [selectedTeam, setSelectedId])

  useEffect(() => {
  if(!monthValues.length) return

  setFiscalYear(monthValues[0])
}, [setFiscalYear, monthValues])

  const getNextIndex = (goBackwards = false) => {
    const nextIndex = goBackwards
      ? parseInt(selectedGoalIndex) - 1
      : parseInt(selectedGoalIndex) + 1;
    if (!teamGoals[nextIndex]) return goBackwards ? teamGoals.length - 1 : 0;
    return nextIndex;
  };

  const storeDate = async (date, next = false) => {
   // let hasLinkId = false;
    if(loadedGoal.nextYearLink && next) {
      if((selectedGoalIndex && loadedGoal.nextYearLink === teamGoals[selectedGoalIndex].id) || (loadedGoal.nextYearLink === teamGoals[0].id)) {
        setLinkedId("")
      } else {
        setLinkedId(loadedGoal.nextYearLink)
        //hasLinkId = true;
      }
    }else if(loadedGoal.prevYearLink && !next) {
      if((selectedGoalIndex && loadedGoal.prevYearLink === teamGoals[selectedGoalIndex].id) || (loadedGoal.prevYearLink === teamGoals[0].id)) {
        setLinkedId("")
      } else {
        setLinkedId(loadedGoal.prevYearLink)
        //hasLinkId = true;
      }
    }

    setDate(date)
    setLoadedGoal(null);
  }

  useEffect(() => {
    // only for next and previous year link
    // Empty Array ([]) is TRUE, So, need to check with it's length
    if (loadedGoals.length > 0) {
      let sortedMeasures = sortMeasures(loadedGoals, presentation, selectedTeam.company.finalcialYearEnd, date);
      if (sortedMeasures.length > 0) {
        setTeamGoals(sortedMeasures)
        setSelectedGoalIndex(0);
      }
      else {
        setGoalsLoadedComplete(true);
        toast.error('No goals found for this year.')
      }
    }
  }, [date])

  const refreshGraph = () => {
    setPeriodValues([1,2,3,4,5,6,7,8,9,10,11,12])
    setPeriod('MONTH')
    setIsTrend(!isTrend)
  }

  const geResultsForGoal = (goal, teamBusinessResults) => {
    let teamBusinessGoalResults = teamBusinessResults.filter(g => g.goal === goal.id);
    if (teamBusinessGoalResults != null && teamBusinessGoalResults.length > 0) {
      return teamBusinessGoalResults.sort((a, b) => a.periodValue - b.periodValue);
    }
    return [];
  }

  const saveExcel = async () => {
    const workbook = new Excel.Workbook();
    try {

      let columns = [];
      columns.push({ header: "Month", key: "month" });
      columns.push({ header: "Goal Category", key: "category" });
      columns.push({ header: "Measurement", key: "measurement" });
      columns.push({ header: "Unit", key: "unit" });
      columns.push({ header: "Target", key: "target" });
      columns.push({ header: "Result", key: "result" });
      columns.push({ header: "Result Color", key: "color" });
      columns.push({ header: "Comment", key: "comment" });

      let tempPeriodkey = getPeriodKey(fiscalYear, date);
      if (selectedTeam.id !== 0) {
        let response = await getTeamBusinessResults(selectedTeam.id, "MONTH", tempPeriodkey);

        let businessResults = [];
        if (response != null && response.data.teamBusinessResults.length > 0) {
          let allGoals = teamGoals;

          allGoals.forEach(goal => {
            let goalResults = geResultsForGoal(goal, response.data.teamBusinessResults);
            goalResults.forEach(result => {
              let businessResult = {
                month: result.periodValue,
                category: goal.measurement.goalCategory.name,
                measurement: goal.measurement.measurement,
                unit: goal.measurement.unit.name,
                target: result.target,
                result: result.value,
                color: result.status,
                comment: result.comment
              };

              businessResults.push(businessResult);
            });
          });
        }


        let teamName = selectedTeam.name;
        const fileName = teamName + "_" + tempPeriodkey + "_Business_Result";
        // creating one worksheet in workbook
        const worksheet = workbook.addWorksheet(workSheetName);

        // add worksheet columns
        // each columns contains header and its mapping key from data
        worksheet.columns = columns;

        // updated the font for first row.
        worksheet.getRow(1).font = { bold: true };

        // loop through all of the columns and set the alignment with width.
        worksheet.columns.forEach(column => {
          column.width = 15;
          column.alignment = { horizontal: 'left' };
        });

        businessResults.forEach(result => {
          worksheet.addRow(result);
        });

        // loop through all of the rows and set the outline style.
        worksheet.eachRow({ includeEmpty: false }, row => {
          // store each cell to currentCell
          const currentCell = row._cells;

          // loop through currentCell to apply border only for the non-empty cell of excel
          currentCell.forEach(singleCell => {
            // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
            const cellAddress = singleCell._address;

            // apply border
            worksheet.getCell(cellAddress).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          });
        });

        // write the content using writeBuffer
        const buf = await workbook.xlsx.writeBuffer();

        // download the processed file
        saveAs(new Blob([buf]), `${fileName}.xlsx`);
      }
    } catch (error) {
      console.error('Error saving file - ', error.message);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
    }
  };

  const signOutStyle = {

    float: "right"
  }
  const titleStyle = {

    float: "left"
  }

  return (
    <div className="result-container-fluid">
      <Row className="result-title-row extend-container">
        <Col xl={4} md={4} xs={4}>
          <table className="w-100 h-100">
            <tbody>
              <tr>
                <td valign="middle" className="text-center">
                  <h4 className="app-title ml-2 mr-3 d-inline" style={titleStyle}>
                    {getLabelText("business_results_screen")}
                  </h4>
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
        <Col xl={4} md={4} xs={4}>
          {loadedGoal
            ? (
              <Fragment>
                <table className="w-100 h-100">
                  <tbody>
                    <tr>
                      <td width="10%" valign="middle">
                        <h6 className="app-title">{getLabelText("owners")}</h6>
                      </td>
                      <td width="45%" valign="middle" className="pl-1">
                        <select className="form-control" value={selectedUserId} onChange={(e) => setAssignedUser(e.target.value)}>
                          {selectedTeam.combinedMembers.map((member, i) =>(
                            <option value={member.id} key={i}>{member.firstNames} {member.lastName}</option>
                          ))}
                        </select>
                      </td>
                      <td width="45%" valign="middle" className="pl-1">
                        <select className="form-control" value={selectedStandInId} onChange={(e) => setAssignedStandIn(e.target.value)}>
                          {selectedTeam.combinedMembers.map((member, i) =>(
                              <option value={member.id} key={i}>{member.firstNames} {member.lastName}</option>
                          ))}
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Fragment>
            ) : null
          }
        </Col>
        <Col xl={4} md={4} xs={4}>
          <table className="w-100 h-100">
            <tbody>
              <tr>
                {/*<td valign="baseline">*/}
                <td valign="middle">
                  <span style={signOutStyle}>
                    <Col className="refreshBtn">
                      <div className={"refreshBtn"}>
                      <RealtimeRefreshComponent
                        refresh={refresh}
                        hasNewData={hasNewData}
                        toggleRefresh={toggleRefresh}
                      />
                    </div>
                    <button className="btn btn-primary mr-2 ml-2">
                      <Link className="btn-text-Wht m-0 font-weight-bold" to="/so-what/problem-solving">PS</Link>
                    </button>
                    <button className="btn btn-primary mr-2">
                      <Link className="btn-text-Wht m-0 font-weight-bold" to="/now-what/actions">NS</Link>
                    </button>
                    <Button className="font-weight-bold" onClick={(e) => saveExcel()}>Export</Button>
                    </Col>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
      {loadedGoal ? (
        <TeamGoalsContent
          date={date}
          setDate={storeDate}
          isLoading={isLoadingGoal}
          key={loadedGoal.id}
          goal={loadedGoal}
          selectedGoalIndex={selectedGoalIndex}
          teamGoals={teamGoals}
          setSelectedGoalIndex={setSelectedGoalIndex}
          getNextIndex={getNextIndex}
          setIsTrend={setIsTrend}
          deltaUpdate={updateGoalMetricsFromDelta}
          attendances={teamAttendances}
          refreshGraph={refreshGraph}
          isTrend={isTrend}
          monthList={monthList}
          monthValues={monthValues}
          yearList={yearList}
          selectedId={selectedId}
          fiscalYear={fiscalYear}
          colour={colour}
          showNext={showNext}
          showPrev={showPrev}
          setLinkedId={setLinkedId}
        />
      ) : ( !goalsLoadedComplete && 
        (<Fragment>
          <div className="overlay">
          </div>
          <div className="page-loader">
            <div className="loader-box text-center">
              <FontAwesomeIcon className="text-white fa-spin fa-3x" icon={faSpinner} />
              <p className="text-white mt-4"><strong>Loading</strong></p>
            </div>
          </div>
        </Fragment>)
      )}
       <ToastComponent />
    </div>
  );
};
const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam,
  user: state.userReducer.user,
  presentation: state.presentationReducer
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessResultsScreen);
