import { gql } from "@apollo/client";

export const RECOGNITION_TYPES_QUERY = gql`
  query {
    recognitionTypes {
      id
      name
    }
  }
`;


export const CREATE_RECOGNITION_TYPE_MUTATION = gql`
  mutation createRecognitionType (
    $name: String!
    )
    {
      createRecognitionType(name: $name)
      {
        id
        name
      }
    }
`;

export const UPDATE_RECOGNITION_TYPE_MUTATION = gql`
  mutation updateRecognitionType (
    $id: ID!
    $name: String!
    )
    {
      updateRecognitionType(id:$id, name: $name)
      {
        id
        name
      }
    }
`;

export const DELETE_RECOGNITION_TYPE_MUTATION = gql`
  mutation deleteRecognitionType (
    $id: ID!
    )
    {
      deleteRecognitionType(id:$id)
      {
        id
      }
    }
`;
