import React from "react";
import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretSquareRight,
  faCaretSquareLeft,
} from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";

const MonthSelect = ({
  date,
  onChange,
  iconSize = "lg",
  containerClass = "",
  buttonClass = "",
  showPrev = true,
  showNext = true,
  isMonth = false
}) => {
  const buttonClassName = `p-1 pointer button-unstyled d-inline arrow-btn ${buttonClass}`;
  const dateText = isMonth ? format(date,"yyyy") : format(date, "MMMM yyyy")
  return (
    <Col className={`no-select d-flex align-items-center ${containerClass}`}>
      <table className="w-100">
        <tbody>
          <tr>
            <td width="20%" className="text-left">
              {showPrev && (
                <button
                  className={`${buttonClassName} mr-2`}
                  onClick={() => onChange(isMonth ? -12 : -1)}
                >
                  <FontAwesomeIcon
                    className="text-grey"
                    size={iconSize}
                    icon={faCaretSquareLeft}
                  />
                </button>
              )}
            </td>
            <td width="60%" className="text-center"><h6 className="font-weight-bold m-0">{dateText}</h6></td>
            <td width="20%" className="text-right">
              {showNext && (
                <button className={`${buttonClassName} ml-2`} onClick={() => onChange(isMonth ? 12 : 1)}>
                  <FontAwesomeIcon
                    className="text-grey"
                    size={iconSize}
                    icon={faCaretSquareRight}
                  />
                </button>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </Col>
  );
};

export default MonthSelect;