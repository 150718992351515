import React, {Component} from "react";
import {Alert, Button, FormControl, FormGroup} from "react-bootstrap";
// import { login } from "../services/AuthenticationService";
import {Redirect} from "react-router-dom";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from 'react-redux';
import {login} from '../redux/services/AuthenticationService';
import ToastComponent from "../components/ToastComponent"
import Logo from "../assets/logo.png"
import Pepsico from "../assets/pepsico.png"
import { logo } from "../services/BaseService";

class LoginScreen extends Component {
  state = {
    email: "",
    password: "",
    error: null,
  };

  validateForm = () => {
    // Validate that the email && password are not empty (NOTE: To Be Imporved)
    return this.state.email.length > 0
}

  cleanInput = (input) => {
    // assigned a sanitation function incase more sanitation is desired in future.
    return input.trim();
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    if (this.validateForm()) {
      this.props.login(this.cleanInput(this.state.email), this.cleanInput(this.state.password));
    }
  }

  render() {
    const { error, email, password } = this.state;
    const { isLoading, user } = this.props;

    if(user && !(user.hasPassword)) return <Redirect to="/confirm-password" />;
    if (user && user.hasPassword && ["Consultant", "Administrator"].includes(user.role)) return <Redirect to="/dashboard" />;
    if (user && user.hasPassword && !["Consultant", "Administrator"].includes(user.role)) {
      if(user.teams.length > 0) {
        return <Redirect to="/what/team-attendance" />
      } else {
         return <Redirect to="/dashboard/goals" />
      }
    }

    return (
      <>
        <div className="login">
          <form onSubmit={this.handleSubmit}>
            <img src={logo === "pepsico" ? Pepsico : Logo} alt="logo" width={400} />
            <Alert className={error ? "d-block" : "d-none"} variant="danger">
              {error}
            </Alert>
            <FormGroup className='form-group' controlId="email">
              <FormControl
                autoFocus
                type="text"
                placeholder="Username (E-mail Address)"
                required
                value={email}
                onChange={(e) => this.setState({ email: e.target.value, error: null })}
              />
            </FormGroup>
            <FormGroup className='form-group' controlId="password">
              <FormControl
                value={password}
                placeholder="Password"
                onChange={(e) => this.setState({ password: e.target.value, error: null })}
                type="password"
              />
            </FormGroup>
            <Button
              block
              type="submit"
            >
              {isLoading
                ? <FontAwesomeIcon icon={faSpinner} className="fa fa-spin"></FontAwesomeIcon>
                : <span>Login</span>
              }
            </Button>
          </form>
        </div>
        <ToastComponent />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.authenticationReducer.user,
  isLoading: state.authenticationReducer.isLoading,
});

const mapDispatchToProps = {
  login
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
