import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom";
import { faSpinner, faPlay, faPause, faStop, faBackward, faForward } from "@fortawesome/free-solid-svg-icons";
import { getMeetingItems } from "../services/MeetingViewService"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { startPresentation, updatePresentation, endPresentation } from '../redux/services/PresentationService';
import { toast } from "react-toastify";

import { connect } from 'react-redux';
import { changeIndex, setPaused } from "./utils/sessionResources"

function getYearList(startMonth, isValues) {
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const array = []
  const index = months.indexOf(startMonth)

  for (let i = index; i < months.length; i++) {
    array.push(isValues ? (i + 1) : months[i])
  }

  for (let i = 0; i < index; i++) {
    array.push(isValues ? (i + 1) : months[i])
  }

  return array
}

const SessionControls = (props) => {

  const [current, setCurrent] = useState(props.presentation)
  const [ loadingSession, setLoadingSession ] = useState(false)
  const history = useHistory()

  const storeSession = (newSession) => {
    localStorage.setItem("session", JSON.stringify(newSession))
    setCurrent({ ...newSession, schedule: newSession.schedule })
    props.updatePresentation(newSession)
  }

  useEffect(() => {
    setCurrent(props.presentation)
  }, [setCurrent, props.presentation])

  const getChildId = (child, period) => {
    switch(child.type) {
      case "BUSINESS":

        return child.goal.id
      case "TEAM":
        if(child.measure.period !== period) {
          return child.measure.latestYearLink
        }
        return child.measure.id
      case "LVL2":

        return child.selected.id
      default:
        if(child.individualGoal.period !== period) {
          return child.individualGoal.latestYearLink
        }
        return child.individualGoal.id
    }
  }

  const getContentType = (child) => {
    switch(child.type) {
      case "BUSINESS":

        return `${child.goal.measurement.measurement} (${child.goal.measurement.unit.name})`
      case "TEAM":

        return `${child.measure.measurement.measurement} (${child.measure.measurement.unit.name})`
      case "LVL2":

        return `${child.selected.department.name}(${child.selected.name})`
      default:

        return `${child.individualGoal.measurement.measurement} (${child.individualGoal.measurement.unit.name})`
    }
  }

  const startSession = async() => {
    const months = getYearList(props.team.company.finalcialYearEnd, true)
    const period = (new Date().getMonth() + 1) < months[0] ? `${new Date().getFullYear() - 1}${new Date().getFullYear()}` : `${new Date().getFullYear()}${new Date().getFullYear() + 1}`

    setLoadingSession(true)
    const type = "NORMAL"
    const response = await getMeetingItems(props.team.id, type, true)

    let schedule = []
    let session
    response.data.teamMeetingItems.sort((a,b) => a.index - b.index).map(item => {
      if((item.screen === "/what/individual-results" || item.screen === "/what/team-goals") && !item.currentChildren.length) return false
      if((item.screen === "/now-what/escalations" || item.screen === "/now-what/team-dashboard") && item.target === "OTHER" && !item.currentChildren.length) return false

      let obj = {
        screen: item.screen,
        children: []
      }

      if(item.currentChildren && item.currentChildren.length > 0 && item.screen !== "/what/business-results") {
          let children = []

          item.currentChildren.sort((a,b) => a.index - b.index).map(child => {
            if(getChildId(child, period)) {
              children.push({
                id: getChildId(child, period),
                title: getContentType(child)
              })
            }

            return true
          })

          obj = {
            ...obj,
            children
          }
      }

      schedule.push(obj)
      return true
    })

    if(schedule.length < 1) {
      setLoadingSession(false)
      toast.error("No items scheduled for today.")
      return false
    }

    const page = window.location.pathname

    session = {
      ...props.presentation,
      type,
      schedule,
      active: true,
      lastPage: page,
      childActive: schedule[0].children.length > 0,
      childLastIndex: schedule[0].children.length > 0 ? schedule[0].children.length - 1 : 0
    }

    setLoadingSession(false)
    props.startPresentation()
    storeSession(session)

    history.push(schedule[0].screen)
  }

  const togglePaused = () => {
    const newSession = setPaused(current)

    storeSession(newSession)
    history.push(newSession.paused ? newSession.lastPage : newSession.schedule[newSession.index].screen)
  }

  const setIndex = (next = false) => {
    const newSession = changeIndex(current, next)

    storeSession(newSession)

    history.push(newSession.schedule[newSession.index].screen)
  }

  const stop = () => {
    let lastPage = current.lastPage
    localStorage.removeItem("session")
    props.endPresentation()
    setCurrent({
      active: false,
      pasued: false,
      type: "",
      schedule: [],
      index: 0,
      subIndex: 0,
      childActive: false,
      childLastIndex: 0,
      lastPage: "",
    })
    history.push(lastPage)
  }
  return (
    <table className="w-50 mx-auto">
      <tbody>
        <tr>
          <td>
            {props.presentation.screen}
          </td>
          {current.active && !current.paused && (
            <td className="text-center" style={{ cursor: "pointer" }}><FontAwesomeIcon className="mx-auto text-white fa-2x" icon={faBackward} onClick={() => setIndex()} /></td>
          )}
          {current.active && !current.paused && (
            <td className="text-center" style={{ cursor: "pointer" }} onClick={() => togglePaused()}><FontAwesomeIcon className="mx-auto text-white fa-2x" icon={faPause} /></td>
          )}
          {(!current.active || (current.active && current.paused)) && (
            <>
            {loadingSession ? (
              <td className="text-center" style={{ cursor: "pointer" }}><FontAwesomeIcon className="mx-auto fa-spin text-white fa-2x" icon={faSpinner} /></td>
            ) : (
              <td className="text-center" style={{ cursor: "pointer" }} onClick={() => current.paused ? togglePaused() : startSession()}><FontAwesomeIcon className="mx-auto text-white fa-2x" icon={faPlay} /></td>
            )}
            </>
          )}
          {current.active && (
            <td className="text-center" style={{ cursor: "pointer" }} onClick={() => stop()}><FontAwesomeIcon className="mx-auto text-white fa-2x" icon={faStop} /></td>
          )}
          {current.active && !current.paused && (
            <td className="text-center" style={{ cursor: "pointer" }}><FontAwesomeIcon className="mx-auto text-white fa-2x" icon={faForward} onClick={() => setIndex(true)} /></td>
          )}
        </tr>
      </tbody>
    </table>
  )
}

const mapStateToProps = (state) => ({
  presentation: state.presentationReducer,
  team: state.teamsReducer.selectedTeam
});

const mapDispatchToProps = {
  startPresentation,
  updatePresentation,
  endPresentation
};


export default connect(mapStateToProps, mapDispatchToProps)(SessionControls);
