import React, { Component, Fragment } from "react"

import { connect } from 'react-redux'
// bootstrap
import { Table, Button, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap"

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Cancel from '../assets/ButtonIcons/Cancel Blue.svg';
import Delete from '../assets/ButtonIcons/Delete Blue.svg';
import Edit from '../assets/ButtonIcons/Edit Blue.svg';
import Loader from '../assets/ButtonIcons/Loader Blue.svg';
import Save from '../assets/ButtonIcons/Save Blue.svg';

// Service methods
import { deleteGoalCategory, updateGoalCategory, createGoalCategory, getGoalCategories, getGoalCategoryCount } from "../services/GoalCategoryService"

import displayErrorToast from "../components/utils/displayErrorToast"
import PaginationComponent from "../components/PaginationComponent"
import SubHeaderComponent from "../components/SubHeaderComponent";
import * as DefaultLabels from '../assets/glossary.json';
import LabelComponent from "../components/utils/getCompanyLabel";
const labels = DefaultLabels.default;

function renderTooltip(props, text) {
  return (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );
}

class GoalCategoryScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      goalCategories: [],
      newGoalCategories: [],
      selectedId: null,
      mode: "",
      name: "",
      loading: false,
      loadType: "",
      deleteId: 0,
      totalPages: 0,
      page: 1,
      firstIndex: 0,
      lastIndex: 9,
    }
    this.props.toggleDirtyState(true)
    this.setPage = this.setPage.bind(this)
    this.props.togglePageLoad(true)

  }

  async componentDidMount() {
    await this.fetchGoalCategories()
    this.props.togglePageLoad(false)
  }

  getText = val => {
    const { user } = this.props;

    const companyId = localStorage.getItem("companyId");
    let lang = "EN";
    let langLabels = labels.find(lbl => lbl.lang === lang);
    let def = langLabels.glossary.find(label => label.key === val);

    if(user && user.companies && user.companies.length) {
      const company = user.companies.find(company => company.id === companyId);
      if(company) {
        lang = company.language;
        langLabels = labels.find(lbl => lbl.lang === lang);
        def = langLabels.glossary.find(label => label.key === val);
        if(!def) {
          lang = "EN";
          langLabels = labels.find(lbl => lbl.lang === lang);
          def = langLabels.glossary.find(label => label.key === val);
        }
      }
      if(company && company.companyGlossaries) {
        const custom = company.companyGlossaries.find(label => label.key === val);

        if(custom) return custom.value
      }
    }

    if(!def) {
      console.log(val)
    }
    return def.value
  }

  fetchGoalCategories = async (newGoalCategory = null) => {
    const { newGoalCategories } = this.state
    const response = await getGoalCategories({ orderBy: 'orderByString_ASC' })
    let goalCategories = response.data.goalCategories.filter(goalCategory => !newGoalCategories.find(newGoalCategory => newGoalCategory.id === goalCategory.id))

    if(newGoalCategory) {
      goalCategories = goalCategories.filter(goalCategory => goalCategory.id !== newGoalCategory.id)
    }

    this.setState({
      goalCategories: goalCategories.sort((a,b) => a.name.localeCompare(b.name)),
      totalPages: (Math.ceil(response.data.goalCategories.length / 10))
    })
  }

  fetchGoalCategoryCount = async () => {
    const response = await getGoalCategoryCount()

    this.setState({
      totalPages: (Math.ceil((response.data.goalCategoryCount - 1) / 10))
    })
  }

  getLabelText = key => <LabelComponent val={key}/>

  setPage = async (page) => {
    this.setState({
      paginateDisabled: true
    })

    const firstIndex = (parseInt(page) * 10) - 10
    const lastIndex = (parseInt(page) * 10) - 1

    this.setState({
      paginateDisabled: false,
      page: page,
      firstIndex: firstIndex,
      lastIndex: lastIndex
    })
  }

  toggleMode = (e, mode, id = 0) => {
    e.preventDefault()
    this.cancel()

    let options = {
      selectedId: id,
      mode: mode
    }
    let { goalCategories, newGoalCategories } = this.state
    const jointCategories = [...newGoalCategories, ...goalCategories]
    if (mode === "create") {
      newGoalCategories.unshift({ name: "", id: 0 })
    } else {
      const category = jointCategories.filter((category) => {
        return category.id === id
      })

      options = {
        ...options,
        name: category[0].name
      }
    }

    this.setState(options)
    if (mode === "create") this.setPage(1)
  }

  cancel = () => {
    const { mode } = this.state
    this.props.toggleDirtyState()
    let options = {
      selectedId: "",
      mode: "",
      name: ""
    }

    if (mode === "create") {
      let { newGoalCategories } = this.state

      if (newGoalCategories[0].id === 0) {
        newGoalCategories.shift()
      }

    }

    this.setState(options)
  }

  validate = () => {
    const { name } = this.state

    return name.length > 0
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    this.setState({
      loading: true,
      loadType: "create"
    })
    if (this.validate()) {
      try {
        const { name, mode, selectedId, newGoalCategories } = this.state
        let newGoal = null
        if(mode === "create") {
          const response = await createGoalCategory(name)
          newGoal = response.data.createGoalCategory
        } else {
          await updateGoalCategory(selectedId, name)
          if(newGoalCategories.find(newGoalCategory => newGoalCategory.id === selectedId)) {
            const index = newGoalCategories.findIndex(newGoalCategory => newGoalCategory.id === selectedId)
            newGoalCategories[index].name = name
          }
        }
        await this.fetchGoalCategories(newGoal)
        this.cancel()
        if(newGoal) {
          newGoalCategories.unshift(newGoal)
        }
      } catch (error) {
        displayErrorToast(error)
      }
    }

    this.setState({
      loading: false,
      loadType: ""
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  deleteItem = async (id) => {
    const { newGoalCategories } = this.state
    this.setState({
      loading: true,
      loadType: "delete",
      deleteId: id
    })
    if (window.confirm(this.getText("delete_department_prompt"))) {
      try {
        await deleteGoalCategory(id)
        if(newGoalCategories.find(goalCategory => goalCategory.id === id)) {
          newGoalCategories.splice(newGoalCategories.findIndex(goalCategory => goalCategory.id === id), 1)
        }
        await this.fetchGoalCategories()
      } catch (error) {
        displayErrorToast(error)
      }
    }
    this.setState({
      loading: false,
      loadType: "",
      deleteId: 0
    })
  }

  goalCategoryList = (goalCategory, i) => {
    const { selectedId, name, loading, loadType, deleteId, mode } = this.state
    let category;
    if (selectedId === goalCategory.id) {
      category = (
        <tr key={i}>
          <td>
            <input
              autoFocus
              className="form-control"
              type="text"
              required
              placeholder="Name"
              value={name}
              name="name"
              tabIndex="1"
              onChange={(e) => this.handleChange(e)}
              autoComplete="off"
            />
          </td>
          <td className="text-right">
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, this.getLabelText('save'))}
            >
              <Button className="btn-icons-container" variant="primary" type="submit">
                {loading && loadType === "create"
                  ? (
                    <img src={Loader} alt="Loader" className="loader-spinner"/>
                  )
                  : (
                    <img src={Save} alt="Save" className="btn-icons"/>
                  )
                }
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, this.getLabelText('cancel'))}
            >
              <Button variant="danger" className="btn-icons-container" onClick={this.cancel}>
                <img src={Cancel} alt="Cancel" className="btn-icons"/>
              </Button>
            </OverlayTrigger>
          </td>
        </tr>
      )
    } else {
      category = (
        <tr key={i}>
          <td>
            {goalCategory.name}
          </td>
          <td className="text-right">
              <OverlayTrigger
                placement="top"
                delay={{ show: 25, hide: 40 }}
                overlay={(props) => renderTooltip({...props}, this.getLabelText('edit'))}
              >
                <Button className="btn-icons-container" variant="primary" disabled={mode !== ""} onClick={(e) => this.toggleMode(e, "edit", goalCategory.id)}>
                  <img src={Edit} alt="Edit" className="btn-icons"/>
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                delay={{ show: 25, hide: 40 }}
                overlay={(props) => renderTooltip({...props}, this.getLabelText('delete'))}
              >
                <Button
                  variant="danger"
                  disabled={goalCategory.hasGoals || goalCategory.hasMeasurements || mode !== ""}
                  className="btn-icons-container" onClick={() => this.deleteItem(goalCategory.id)}
                >
                  {loading && loadType === "delete" && deleteId === goalCategory.id
                    ? (
                      <img src={Loader} alt="Loader" className="loader-spinner"/>
                    )
                    : (
                      <img src={Delete} alt="Delete" className="btn-icons"/>
                  )
                }
              </Button>
            </OverlayTrigger>
          </td>
        </tr>
      )
    }

    return category
  }

  render() {
    const { goalCategories, newGoalCategories, firstIndex, lastIndex } = this.state

    const jointGoalCategories = [...newGoalCategories, ...goalCategories]
    return (
      <Fragment>
        <SubHeaderComponent>
          <Row className="justify-content-center">
            <Col className="text-right" xs={12}>
              <Button className={"btn btn-primary btn-nrml" + (this.state.mode !== "" ? " disabled" : "")} onClick={(e) => this.toggleMode(e, "create")}>{this.getLabelText('add')}<FontAwesomeIcon className="ml-1" icon={faPlus} /></Button>
            </Col>
          </Row>
        </SubHeaderComponent>
        <div className="grey-header-space container-left">
          <Row className="justify-content-center">
            <Col xl={12}>
              <form onSubmit={(e) => this.handleSubmit(e)}>
                <Table striped bordered hover className="standard-tbl standard-goal-cat-tbl table-heading-background user-text">
                  <thead>
                  <tr>
                    <th width="25%">{this.getLabelText("goal_category_description")}</th>
                    <th width="15%"></th>
                  </tr>
                  </thead>
                  <tbody>
                  {jointGoalCategories.map((goalCategory, i, { length }) => {
                    if(i >= firstIndex && i <= lastIndex) {
                      return this.goalCategoryList(goalCategory, i, length)
                    }

                    return null
                  })}
                  </tbody>
                </Table>
                {
                  this.state.totalPages > 0 ?
                  <div className="pagination_container">
                    <PaginationComponent
                        active={this.state.page}
                        disabled={this.state.paginateDisabled}
                        setPage={this.setPage}
                        total={this.state.totalPages}
                    />
                  </div>
                      : null
                }
              </form>
            </Col>
          </Row>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user
})

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(GoalCategoryScreen);
