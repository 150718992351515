import React, { useEffect, useState, useRef } from "react"
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { updatePresentation } from '../../redux/services/PresentationService';
const screens =  [
  {
    url: "/what/team-attendance",
    title: "Meeting Attendance"
  },
  {
    url: "/what/team-goals",
    title: "Team Results"
  },
  {
    url: "/what/customers",
    title: "Customers"
  },
  {
    url: "/what/suppliers",
    title: "Suppliers"
  },
  {
    url: "/what/values-behaviours",
    title: "Values Behaviours"
  },
  {
    url: "/what/business-results",
    title: "Business Results"
  },
  {
    url: "/what/individual-results",
    title: "Individual Results"
  },
  {
    url: "/what/skills-matrix",
    title: "Skills Matrix"
  },
  {
    url: "/what/scheduled-events",
    title: "Scheduled Events"
  },
  {
    url: "/so-what/project-tracker",
    title: "Project Tracker",
  },
  {
    url: "/what/coaching-reviews",
    title: "Coaching Reviews",
  },
  {
    url: "/what/leadership-routines",
    title: "Leadership Routines"
  },
  {
    url: "/what/value-stream-partners",
    title: "Value Stream Partners",
  },
  {
    url: "/now-what/coaches-feedback",
    title: "Coaches Feedback"
  },
  {
    url: "/so-what/problem-solving",
    title: "Problem Solving"
  },
  {
    url: "/so-what/improvement-ideas",
    title: "Improvement Ideas"
  },
  {
    url: "/now-what/actions",
    title: "Next Steps"
  },
  {
    url: "/now-what/escalations",
    title: "Escalations & Feedback"
  },

  {
    url: "/now-what/team-dashboard",
    title: "Team Dashboard"
  },
  {
    url: "/now-what/member-dashboard",
    title: "Member Dashboard"
  },
  {
    url: "/now-what/celebrating-success",
    title: "Celebrating Success"
  },
]

const SessionLegendMenu = ({ presentation, updatePresentation }) => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const [session, setSession] = useState(presentation)
  const scrollRef = useRef()
  const activeRef = useRef()
  const history = useHistory()

  const	centerActiveItem = () => {
		const scrollContainer = ReactDOM.findDOMNode(scrollRef.current);
		const activeItem = ReactDOM.findDOMNode(activeRef.current);
    if(!activeItem) {
      return;
    }

    const scrollRect = scrollContainer.getBoundingClientRect();
		const activeRect = activeItem.getBoundingClientRect();
		setScrollPosition(activeRect.left - scrollRect.left - (scrollRect.width / 2) + (activeRect.width / 2))
	}

  useEffect(() => {
    setSession(presentation)
    centerActiveItem()
  }, [setSession, presentation, scrollRef, activeRef])

  useEffect(() => {
    const scrollContainer = ReactDOM.findDOMNode(scrollRef.current);
		if (scrollContainer) {
			scrollContainer.scrollLeft += scrollPosition;
		}
  }, [scrollPosition])

  const storeSession = (newSession) => {
    localStorage.setItem("session", JSON.stringify(newSession))
    updatePresentation(newSession)
  }

  const setSessionItem = (index, isChild = false, parentIndex = 0) => {
    let obj = {
      ...session
    }

    if(isChild) {
      obj = {
        ...obj,
        childActive: true,
        childLastIndex: session.schedule[parentIndex].children.length - 1,
        subIndex: index,
        index: parentIndex
      }
    } else {
      obj = {
        ...obj,
        childActive: false,
        childLastIndex: 0,
        subIndex: 0,
        index: index
      }
    }

    storeSession(obj)
    history.push(obj.schedule[obj.index].screen)
  }

  return (
    <div className={"scrollmenu" + (presentation.schedule.length > 0 && presentation.schedule[presentation.index].children.length > 0 ? " bg-white" : " bg-light-grey")} ref={scrollRef}>
      {presentation.schedule.map((item, i) => {
        if(item.children.length > 0) {
          return item.children.map((child, k) => {
            return <span ref={presentation.index === i && presentation.subIndex === k ? activeRef : null} className={presentation.index === i && presentation.subIndex === k ? "active" : ""} key={`${k}-${i}`} onClick={() => setSessionItem(k, true, i)}>{screens.find(screen => screen.url === item.screen).title} - {child.title}</span>
          })
        } else {
          return <span ref={presentation.index === i ? activeRef : null} key={i} className={presentation.index === i ? "active" : ""} onClick={() => setSessionItem(i)}>{screens.find(screen => screen.url === item.screen).title}</span>
        }
      })}
    </div>
  )
}

const mapStateToProps = (state) => ({
  presentation: state.presentationReducer
});

const mapDispatchToProps = {
  updatePresentation
};


export default connect(mapStateToProps, mapDispatchToProps)(SessionLegendMenu);
