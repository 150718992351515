import { client } from "./BaseService";
import { gql } from "apollo-boost";

const getActions = async (id) => {
  return await client.query({
    query: gql`query {
      actions(creatorId: "${id}", type: "ESCALATION") {
        id
        status
        escalated
        archived
        escalateArchived
        shared
        feedback
        whenChanged
        actionType
        who {
          id
          firstNames
          lastName
        }
        issue {
          id
          whyText
          whatText
          reviewDate
          nextStepCount
          completedCount
          totalNextSteps
        }
        when
        description
      }
    }`
  })
}

const updateSharedStatus = async (id, status) => {
  return await client.mutate({
    mutation: gql`mutation {
        updateAction(
          id: "${id}",
          shared: ${status}
        ) {
          id
        }
      }`
  })
}

const archiveFeedback = async (id, archived) => {
  return await client.mutate({
    mutation: gql`mutation {
        updateAction(
          id: "${id}",
          escalateArchived: ${archived}
        ) {
          id
        }
      }`
  })
}

const createAction = async(issueId, actionType, status, whoId, when, whenChanged, feedback) => {
  return await client.mutate({
    mutation: gql`mutation {
        createAction(
          issueId: "${issueId}",
          actionType: ${actionType},
          status: ${status},
          whoId: "${whoId}",
          when: "${when}",
          whenChanged: ${whenChanged},
          feedback: "${feedback}",
          escalated: true
        ) {
          id
        }
      }`
  })
}

const updateAction = async(id, whoId, when, whenChanged, feedback) => {
  return await client.mutate({
    mutation: gql`mutation {
        updateAction(
          id: "${id}",
          whoId: "${whoId}",
          when: "${when}",
          whenChanged: ${whenChanged},
          feedback: "${feedback}"
        ) {
          id
        }
      }`
  })
}

const getIssue = async (id) => {
  return await client.query({
    query: gql`query {
      issue(
        id: "${id}"
      ) {
        id
        actions {
          id
          archived
          actionType
        }
      }
    }`
  })
}

export {
  archiveFeedback,
  updateSharedStatus,
  updateAction,
  createAction,
  getActions,
  getIssue
}
