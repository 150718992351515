import { gql } from "apollo-boost";

export const GET_LEADERSHIP_ROUTINES_QUERY = gql`
  query {
    leadershipRoutines {
      id
      name
    }
  }
`;

export const GET_LEADERSHIP_ROUTINES_QUERY_PG = gql`
  query {
    leadershipRoutinesPG {
      id
      name
      hasTeamRoutines
    }
  }
`;

export const CREATE_LEADERSHIP_ROUTINE_MUTATION = gql`
  mutation CreateLeadershipRoutine($name: String!) {
    createLeadershipRoutine(name: $name) {
      id
      name
      hasTeamRoutines
    }
  }
`;

export const UPDATE_LEADERSHIP_ROUTINE_MUTATION = gql`
  mutation UpdateLeadershipRoutine($id: ID!, $name: String!) {
    updateLeadershipRoutine(id: $id, name: $name) {
      id
      name
      hasTeamRoutines
    }
  }
`;

export const DELETE_LEADERSHIP_ROUTINE_MUTATION = gql`
  mutation DeleteLeadershipRoutine($id: ID!) {
    deleteLeadershipRoutine(id: $id) {
      id
    }
  }
`;
