import { client } from "./BaseService";
import { gql } from "apollo-boost";
import toGqlStrArray from "../components/utils/toGqlStrArray";

const getGoals = async () => {
  return await client.query({
    query: gql`query {
      goals {
        id
        measurement {
          measurement
          id
          unit {
            id
            name
          }
        }
        numberFrom
        numberTo
        dueDate
        goalCategory {
          id
          name
        }
        period
        allMetricsCSV {
          frequency
          date
          goalCategory
          measurement
          unit
          target
          result
          resultColour
          dayStatus
        }
      }
    }`
  })
}

const getVision = async () => {
  return await client.query({
    query: gql`query {
      company {
        id
        vision
        name
        finalcialYearEnd
      }
    }`
  })
}

const updateVision = async (id, vision, name) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateCompany(
        id: "${id}"
        vision: "${vision}"
        name: "${name}"
     ) {
         id
         vision
      }
    }
    `
  })
}

const createGoal = async (goalCategoryId, measurement, unitId, dueDate, numberFrom, numberTo, period, options) => {
  const measurementKey = options.isNewMeasurement ? 'measurement' : 'measurementId'
  const unitKey = options.isNewUnit ? 'unit' : 'unitId'
  const categoryKey = options.isNewCategory ? 'goalCategory' : 'goalCategoryId'

  return await client.mutate({
    mutation: gql`mutation {
      createGoal(
        ${categoryKey}: "${goalCategoryId}",
        ${measurementKey}: "${measurement}",
        ${unitKey}: "${unitId}",
        dueDate:"${dueDate}",
        numberFrom: ${numberFrom},
        numberTo: ${numberTo},
        period: "${period}"
      )
      {
        id
      }
    }`,
  });
}

const updateGoal = async({ id, goalCategoryId, measurement, measurementId, unit, dueDate, numberFrom, numberTo, options }) => {
  const unitKey = options.isNewUnit ? 'unit' : 'unitId'
  const categoryKey = options.isNewCategory ? 'goalCategory' : 'goalCategoryId'

  return await client.mutate({
    mutation: gql`mutation {
      updateGoal(
        id: "${id}",
        ${categoryKey}: "${goalCategoryId}",
        ${!options.isNewMeasurement ? `measurementId: "${measurementId}"` : `measurement: "${measurement}"`},
        ${unitKey}:"${unit}",
        dueDate:"${dueDate}",
        numberFrom: ${numberFrom},
        numberTo: ${numberTo},
      )
      {
        id
      }
    }`,
  });
}

const updateGoalsOrder = async (goals) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateGoalsOrder (
        goals: ${toGqlStrArray(goals)}
      )
    }`
  })
}

const deleteGoal = async(id) => {
  return await client.mutate({
    mutation: gql`mutation {
      deleteGoal(
        id: "${id}"
      )
      {
        id
      }
    }`
  })
}

const getStartWeeks = async () => {
  return await client.query({
    query: gql`query {
      companyStartWeeks {
        id
        period
        week
        date
      }
    }`
  })
}

const createCompanyStartWeek = async (period, week, date) => {
  return await client.mutate({
    mutation: gql`mutation {
      createCompanyStartWeek(
        period: "${period}",
        week: ${week},
        date: "${date}"
      )
      {
        id
      }
    }`,
  });
}

const updateCompanyStartWeek = async(id, week, date) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateCompanyStartWeek(
        id: "${id}",
        week: ${week},
        date: "${date}",
      )
      {
        id
      }
    }`,
  });
}

export {
  getGoals,
  createGoal,
  getVision,
  updateVision,
  updateGoal,
  updateGoalsOrder,
  deleteGoal,
  createCompanyStartWeek,
  updateCompanyStartWeek,
  getStartWeeks
}
