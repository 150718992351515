import React from "react";
import { Row, Col, FormGroup } from "react-bootstrap";

import MultiSelect from "./MultiSelect";

const PartnerRow = ({
  selectPlaceholder,
  name,
  title,
  data,
  array,
  multiSelectProps,
  fetchReduxTeams,
  setTeamPartners,
  fullArr
}) => {
  return (
    <Row className="mb-20 user-txt">
      <Col xl={2} md={12}>
        <FormGroup className="row">
          <Col>
            <label className="cdi-txt-lbl">{title}</label>
          </Col>
        </FormGroup>
      </Col>

      <Col xl={4} md={12}>
        <FormGroup className="row">
          <Col>
            <MultiSelect
              name={name}
              data={data}
              placeholder={selectPlaceholder}
              array={array}
              fullArr={fullArr}
              fetchReduxTeams={fetchReduxTeams}
              setTeamPartners={setTeamPartners}
              {...multiSelectProps}
            />
          </Col>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default PartnerRow;
