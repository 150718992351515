import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Button } from "react-bootstrap";
import {
  getTeamCoachingReviews,
  fetchTeamsQuery,
} from "./resources/gql";
import { connect } from 'react-redux';

import SubHeaderComponent from "../../components/SubHeaderComponent";
import RealtimeRefreshComponent from "../../components/RealtimeRefreshComponent";
import Header from "./Header";
import LabelComponent from "../../components/utils/getCompanyLabel";
import MonthYearSelect from "../../components/MonthYearSelect";
import { getSingleDepartment } from "../../services/DepartmentService";
import classnames from "../../components/utils/classnames";
import Excel from 'exceljs';
import { saveAs } from 'file-saver';

const workSheetName = 'Worksheet-1';
const getLabelText = key => <LabelComponent val={key} />

const STATUSES = [
  "DKGRY",
  "RED",
  "ORNG",
  "SLVR",
  "GLD"
]

const criteriaCellProps = {
  [STATUSES[0]]: { classes:"text-white bg-dk-grey", value: 0 },
  [STATUSES[1]]: { classes:"text-white bg-color-negative", value: 1 },
  [STATUSES[2]]: { classes:"text-white bg-orange", value: 2 },
  [STATUSES[3]]: { classes:"text-white bg-green", value: 3 },
  [STATUSES[4]]: { classes:"text-black bg-silver", value: 4 },
  [STATUSES[5]]: { classes:"text-black bg-gold", value: 5 },
}

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

const CoachingReviewDashboardScreen = ({ selectedTeam, user, togglePageLoad, refresh, hasNewData, toggleRefresh, presentation }) => {
  const [date, setDate] = useState(new Date());
  const [departmentTeams, setDepartmentTeams] = useState([]);
  const [teamsLabels, setTeamsLabels] = useState([]);
  const [periodKey, setPeriodKey] = useState(null);
  const [periodValue, setPeriodValue] = useState((date.getMonth()+1).toString());
  const [allModules, setAllModules] = useState([]);
  const [activeModule, setActiveModule] = useState();
  const [activeSubjects, setActiveSubjects] = useState();
  const [coachingReviewMetrics, setCoachingReviewMetrics] = useState([])
  
  const fetchCoachingReviewMetric = useCallback(async () => {
    const resp = periodKey && activeModule && await getTeamCoachingReviews(
      departmentTeams, periodKey.toString(), periodValue, activeModule.id)
    let teamsData = []
    // Extract team metric data
    resp && resp.data.fetchChildrenCoachingReviews.forEach((metric) => {
      const exists = teamsData.findIndex((team) =>
          team.id === metric.team.id)

      if (exists === -1) {
        teamsData.push({
          id: metric.team.id,
          name: metric.team.name,
          metricData: [{
            value: metric.value,
            month: metric.periodValue,
            year: metric.periodKey,
            subjectId: metric.subject.id
          }],
        })
      } else {
        const newMetric = {
          ...teamsData[exists],
          metricData: [
            ...teamsData[exists].metricData,
            {
              value: metric.value,
              subjectId: metric.subject.id,
              month: metric.periodValue,
              year: metric.periodKey,
            }

          ],
        }
        teamsData[exists] = newMetric
      }
    })
    // Add teams that have no value
    departmentTeams.forEach(teamId => {
      const exists = teamsData.some((kt) => kt.id === teamId)
      if (!exists) {
        teamsData.push({
          id: teamId,
          name: '',
          metricData: null,
        })
      }
    });
    
    let teamsDataInSequence = [];
    departmentTeams.forEach(teamId => {
      const teamfilterData = teamsData.filter((t) => t.id === teamId);
      if (teamfilterData) {
        teamsDataInSequence.push(teamfilterData[0])
      }
    });

    setCoachingReviewMetrics(teamsDataInSequence)
    togglePageLoad(false)
  }, [departmentTeams, togglePageLoad, periodKey, periodValue, activeModule, setCoachingReviewMetrics]);

  const changeModule = (id) => {
    if (activeModule?.id !== id) {
      let actModule = allModules.filter((m) => m.id === id);
      if(actModule)
        {
          setActiveModule(actModule[0]);
          setActiveSubjects(actModule[0].subjects);
        }
    }
  };

  useEffect(() => {
    let yearStartMonthIndex = months.indexOf(selectedTeam.company.finalcialYearEnd);

    if (periodValue < yearStartMonthIndex) {
      setPeriodKey(parseInt(date.getFullYear() - 1));
    }
    else {
      setPeriodKey(parseInt(date.getFullYear()));
    }
  }, []);

  const changeYear = (year)=>{
    setPeriodKey(year)

    let currentMonthIndex = periodValue;
    let yearStartMonthIndex = months.indexOf(selectedTeam.company.finalcialYearEnd);

    if (currentMonthIndex < yearStartMonthIndex) {
      setPeriodKey(parseInt(year - 1));
    }
    else {
      setPeriodKey(parseInt(year));
    }
  };

  const changeMonth = (month) => {
     setPeriodValue(month)

    let currentMonthIndex = parseInt(month);
    let year = periodKey;
    let yearStartMonthIndex = months.indexOf(selectedTeam.company.finalcialYearEnd);

    if (currentMonthIndex < yearStartMonthIndex) {
      setPeriodKey(parseInt(year - 1));
    }
    else {
      setPeriodKey(parseInt(year));
    }

  };

  const fetchDepartmentDetails = useCallback(async () => {
    togglePageLoad(true);
    // Get department details
    const resp = selectedTeam.department?.id &&
      await getSingleDepartment(selectedTeam.department.id);
    let teamIds = [];
    let teamLabels = [];
    let teamModules = [];
    const getDepartmenTeams = (department) => {
    if (department.children)
        department.children.forEach(child => {
          child.teams.forEach(team => {
            if (team && team.name.length > 0) {
              teamIds.push(team.id);
              teamLabels.push(team.name)
              teamModules.push(team.modules)
            }
          });
          getDepartmenTeams(child);
        });
    };
    getDepartmenTeams(resp.data.department);
    setDepartmentTeams(teamIds)
    setTeamsLabels(teamLabels)

  }, [selectedTeam, date, togglePageLoad])

  const fetchTeamModules = useCallback(async () => {
    const moduleData = []
    const resp = await fetchTeamsQuery(departmentTeams)
    resp.data.fetchTeams.forEach((team) => {
      team.modules.forEach((module) => {
        const exists = moduleData.some((item) =>
          item.id === module.id)
        if (!exists) {
          moduleData.push({ ...module })
        }
      })
    })
    setAllModules(moduleData)
    moduleData && setActiveModule(moduleData[0])
    moduleData && moduleData[0]?.subjects && setActiveSubjects(moduleData[0].subjects)
  }, [departmentTeams])

  const getCoachingReviewCount = (subject, teamId) => {
    let total = 0;
    coachingReviewMetrics && coachingReviewMetrics.map((data, i) => 
    {
      if (teamId == data.id) {
        if (data.metricData !== null) {
          const valuesList = data.metricData.filter((item => item.subjectId === subject.id))
          const initialValue = 0;
          total = valuesList.slice(0, 1).reduce(
            (accumulator, currentValue) => accumulator + currentValue.value,
            initialValue
          );
        }
      }
    })
    return total;
  }

  const getColorCodeByValue = (value) => {
    if(value === 0)
    {
      return "ff949494";
    }
    else if(value === 1)
    {
      return "ffdc3545";
    }
    else if(value === 2)
    {
      return "ffed7d31";
    }
    else if(value === 3)
    {
      return "ff28a745";
    }
    else if(value === 4)
    {
      return "ffd9d9d9";
    }
    else if(value === 5)
    {
      return "ffffff00";
    }
    return "ffffffff";
  }

  

  const saveExcel = async () => {
    const workbook = new Excel.Workbook();
    try {

     let columns = [];
     columns.push({ header: "Subject No" , key: "subjectNo" });
     columns.push({ header: "Subject" , key: "subjectText" });
     columns.push({ header: "Module" , key: "module" });

      if (teamsLabels != null && teamsLabels.length > 0) {
        teamsLabels.forEach(label => {
          columns.push({ header: label, key: "team-" + label  });
        })
      }

      let allCoachingReviews = [];
      let allSubjects = activeSubjects;
      let cnt = 0;
      if (allSubjects != null && allSubjects.length > 0) {
        allSubjects.forEach(subject => {
          cnt = cnt + 1;
          let coachingReview = {
            subjectNo: cnt,
            subjectText : subject.name,
            module : activeModule.name
          };

          let teamCnt = 0;
          teamsLabels.forEach(label => {
            coachingReview["team-"+ label] = getCoachingReviewCount(subject, departmentTeams[teamCnt]);
            teamCnt = teamCnt + 1;
          })
          allCoachingReviews.push(coachingReview);
        });
      }

       let monthText =  periodValue;
       let yearText = periodKey;
      let teamName;
      if (selectedTeam.id !== 0) {
        teamName = selectedTeam.name;
      }
       const fileName = teamName + "_" + monthText + "_" + yearText + "_CR_Dashboard";
      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(workSheetName);
  
      // add worksheet columns
      // each columns contains header and its mapping key from data
      worksheet.columns = columns;
  
      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true };
  
      // loop through all of the columns and set the alignment with width.
      worksheet.columns.forEach(column => {
        column.width = 15;
        column.alignment = { horizontal: 'left' };
      });
  
      allCoachingReviews.forEach(routine => {
        worksheet.addRow(routine);
        let lastRow = worksheet.lastRow;
        lastRow.eachCell(function (cell, colNumber) {
          if (colNumber > 3 && colNumber <= (teamsLabels.length + 3)) {
            let val = cell.value;
            let colorCode = getColorCodeByValue(val);
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: colorCode }
            };
          }
        });
      });
  
      // loop through all of the rows and set the outline style.
      worksheet.eachRow({ includeEmpty: false }, row => {
        // store each cell to currentCell
        const currentCell = row._cells;
  
        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach(singleCell => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;
  
          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        });
      });
  
      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();
  
      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error('Error saving file - ', error.message);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
     }
  };


  useEffect(() => {
    fetchDepartmentDetails()
  }, [fetchDepartmentDetails])

  useEffect(() => {
    fetchTeamModules()
  }, [fetchTeamModules])

  useEffect(() => {
    fetchCoachingReviewMetric()
  }, [fetchCoachingReviewMetric])

  useEffect(() => {
    setDate(new Date())
  }, [setDate])

  return (
    <>
      <SubHeaderComponent>
        <Col className="text-right childenSpace">
          <div className={"refreshBtn"}>
            <RealtimeRefreshComponent
              refresh={refresh}
              hasNewData={hasNewData}
              toggleRefresh={toggleRefresh}
            />
          </div>
          <Button className="ml-2" onClick={(e) => saveExcel()}>Export</Button>
        </Col>
      </SubHeaderComponent>
      <div className="grey-header-space container-left">
        <Header
          presentation={presentation}
          selectedModule={activeModule}
          changeLoadedModule={changeModule}
          teamModules={allModules}
          loadedModule={activeModule}
        /> 
        <Row className="mt-3 mb-5 coaching-rev-scroller">
          <Col>
            {activeModule ? (
              <>
                <Row className={"align-items-baseline w-100 mb-2"}>
                  <Col xs={7}>
                    <MonthYearSelect date={date} onYearChange={changeYear} selectedYear={periodKey} selectedMonth={periodValue}
                     onMonthChange={changeMonth} />
                  </Col>
                  <Col xs={5}>
                    <Row className="flex-nowrap" style={{ textAlign: 'center' }}>
                      <div  style={{ display: 'flex', justifyContent: 'space-between', height:'100px', marginLeft: '-10px' }}>
                      {teamsLabels && teamsLabels.map((label, key) => (
                        <div key={key} className={"ml-0 text-vertical-180 p-2 mr-0"} style={{width: '70px',
                          display: 'flex', alignItems: 'center'}}>{label}</div>
                      ))}
                      </div>
                    </Row>
                  </Col>
                  <Col xs={5}>

                  </Col>
                </Row>
                {activeModule && activeSubjects &&
                  activeSubjects.map((subject, i) => (
                    <Row
                      className={"align-items-center w-100 mb-2"}
                      key={i}
                    >
                      <Col xs={7}>
                        <table className="w-100 basic-tbl">
                          <tbody>
                            <tr>
                              <td width="5%" className="py-1 px-2">
                                {(i + 1)}.
                              </td>
                              <td className="py-1 px-2">
                                {subject.name}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                      <Col xs={5}>
                        <Row className="flex-nowrap" style={{ textAlign: 'center' }}>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {coachingReviewMetrics && coachingReviewMetrics.map((data, i) => {
                              let total = 0;
                              //Get team value total per subject
                              if (data.metricData !== null) {
                                const valuesList = data.metricData.filter((item => item.subjectId === subject.id))
                                const initialValue = 0;
                                total = valuesList.slice(0, 1).reduce(
                                  (accumulator, currentValue) => accumulator + currentValue.value,
                                  initialValue
                                );
                              }
                              const { classes } = criteriaCellProps[STATUSES[total]]

                              const className = classnames(
                                  "review-cell text-center font-weight-bold px-1 mr-3 attendance-date-border",
                                  classes
                              );
                              return (<div key={i} style={{display: 'flex', justifyContent: 'center', width:'70px'}}><div className={className} style={{width: '10px'}}>{total}</div></div>)
                            })}
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  ))}
                <Row
                  className={"align-items-center w-100 mb-2"}
                >
                  <Col xs={7}>
                  </Col>
                  <Col xs={5}>
                    <Row className="flex-nowrap">
                    </Row>
                  </Col>
                </Row>
              </>
            ) : (<Row className={"align-items-center w-100 mb-2"}>
              <Col>
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td className="text-cente">
                        <label className="cdi-blue-txt ml-0">{getLabelText("no_modules_error")}</label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>)}
          </Col>
        </Row>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam,
  user: state.userReducer.user,
  presentation: state.presentationReducer
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CoachingReviewDashboardScreen);
