import React, { useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import LabelComponent from "../../components/utils/getCompanyLabel";
// icons
import Delete from '../../assets/ButtonIcons/Delete Blue.svg';
import Edit from '../../assets/ButtonIcons/Edit Blue.svg';
import Loader from '../../assets/ButtonIcons/Loader Blue.svg';

function renderTooltip(props, text) {
  return (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );
}

const DisplayRow = ({ name, id, onOpenEdit, onDelete, disabled,canDelete, getText }) => {
  const [isLoading, setIsLoading] = useState(false);

  const getLabelText = key => <LabelComponent val={key}/>

  const _onDelete = () => {
    if (window.confirm(getText('delete_department_prompt'))) {
      setIsLoading(true);
      onDelete(id);
    }
  };
  return (
    <tr>
      <td>{name}</td>
      <td className="text-right">
        <OverlayTrigger
          placement="top"
          delay={{ show: 25, hide: 40 }}
          overlay={(props) => renderTooltip({...props}, getLabelText('edit'))}
        >
          <Button
            disabled={isLoading || disabled}
            variant="primary"
            className="btn-icons-container"
            onClick={() => onOpenEdit(id)}
            >
            <img src={Edit} alt="Edit" className="btn-icons"/>
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          delay={{ show: 25, hide: 40 }}
          overlay={(props) => renderTooltip({...props}, getLabelText('delete'))}
        >
          <Button
            disabled={isLoading || disabled || !canDelete}
            variant="danger"
            className="btn-icons-container"
            onClick={_onDelete}
            >
            {isLoading
              ? (
                <img src={Loader} alt="Loader" className="loader-spinner"/>
              ) : (
                <img src={Delete} alt="Delete" className="btn-icons"/>
              )
            }
          </Button>
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default DisplayRow;
