import { client } from "./BaseService";
import { gql } from "apollo-boost";

const updateSharedStatus = async (id, status) => {
  return await client.mutate({
    mutation: gql`mutation {
        updateIssue(
          id: "${id}",
          shared: ${status}
        ) {
          id
        }
      }`
  })
}

const archiveFeedback = async (id, archived) => {
  return await client.mutate({
    mutation: gql`mutation {
        updateIssue(
          id: "${id}",
          archived: ${archived}
        ) {
          id
        }
      }`
  })
}

const createAction = async(issueId, actionType, status, whoId, when, whenChanged, feedback) => {
  return await client.mutate({
    mutation: gql`mutation {
        createAction(
          issueId: "${issueId}",
          actionType: ${actionType},
          status: ${status},
          whoId: "${whoId}",
          when: "${when}",
          whenChanged: ${whenChanged},
          feedback: "${feedback}",
          escalated: true
        ) {
          id
        }
      }`
  })
}

const updateAction = async(id, whoId, when, whenChanged, feedback) => {
  return await client.mutate({
    mutation: gql`mutation {
        updateAction(
          id: "${id}",
          whoId: "${whoId}",
          when: "${when}",
          whenChanged: ${whenChanged},
          feedback: "${feedback}"
        ) {
          id
        }
      }`
  })
}

export {
  archiveFeedback,
  updateSharedStatus,
  updateAction,
  createAction
}
