import { gql } from "apollo-boost";

// TODO: remove hardcoded value
export const TEAM_ATTENDANCE_DATA_QUERY = gql`
  query TeamAttendance($id: ID!) {
    team(id: $id) {
      id
      attendances {
        id
        date
        status
      }
    }
  }
`;

export const COMPANY_START_WEEKS = gql`
  query {
    companyStartWeeks {
      id
      period
      week
    }
  }
`;

export const GET_TEAM_MEMBERS = gql`
    query TeamMemberList($id: ID!) {
      team(id: $id) {
        teamGoalAssignedUserLogs {
          id
          periodKey
          user {
            id
          }
          standIn {
            id
          }
          teamMeasurement {
            id
          }
        }
      }
    }
`;

export const TEAM_MEASUREMENTS_LIST_QUERY = gql`
  query TeamMeasurementsList($id: ID!) {
    team(id: $id) {
      measures {
        id
        frequency
        period
        nextYearLink
        prevYearLink
        teamGoalGraphConfigs {
          id
          key
          targetTo
          targetFrom
          upperTriggerTo
          upperTriggerFrom
          lowerTriggerTo
          lowerTriggerFrom
          frequencyTrigger
        }
        measurement {
          id
          measurement
          unit {
            id
            name
          }
          goalCategory {
            id
            name
            orderByString
            order
          }
        }
      }
    }
  }
`;

export const TEAM_MEASUREMENT_DATA_QUERY = gql`
  query TeamMeasurementData(
    $id: ID!
    $period: MetricPeriod!
    $periodValues: [Int!]!
    $periodKey: String!
    $isTrend: Boolean!
    $teamId: String
  ) {
    teamMeasurement(id: $id) {
      id
      frequency
      numberTo
      numberFrom
      dueDate
      period
      nextYearLink
      prevYearLink
      teamGoalGraphConfigs {
        id
        key
        targetFrom
        targetTo
        upperTriggerTo
        upperTriggerFrom
        lowerTriggerTo
        lowerTriggerFrom
        frequencyTrigger
      }
      metrics(period: $period, periodValues: $periodValues, periodKey: $periodKey, isTrend: $isTrend, teamId: $teamId) {
        id
        value
        status
        periodValue
        periodStatus
        periodKey
        target
        lowerTrigger
        upperTrigger
        issue {
          id
          archived
          nextStepStatus
        }
      }
      allMetrics{
        id
        value
        status
        periodValue
        periodStatus
        periodKey
        target
        lowerTrigger
        upperTrigger
        period
      }
      measurement {
        id
        measurement
        unit {
          id
          name
        }
        goalCategory {
          id
          name
        }
      }
    }
  }
`;

export const GET_TREND_TARGET = gql`
  query TeamMeasurementData(
    $id: ID!
    $period: MetricPeriod!
    $periodValues: [Int!]!
    $periodKey: String!
  ) {
    teamMeasurement(id: $id) {
      id
      teamGoalGraphConfigs {
        id
        key
        targetFrom
        targetTo
        upperTriggerTo
        upperTriggerFrom
        lowerTriggerTo
        lowerTriggerFrom
        frequencyTrigger
      }
      metrics(period: $period, periodValues: $periodValues, periodKey: $periodKey) {
        id
        target
        lowerTrigger
        upperTrigger
      }
    }
  }
`;

export const CREATE_METRIC = gql`
  mutation CreateMetric(
    $goal: String!
    $period: MetricPeriod!
    $periodValue: Int!
    $periodKey: String!
    $periodStatus: MetricPeriodStatus!
    $value: Float
    $status: MetricStatus!
    $isTrend: Boolean!
    $target: Float
    $lowerTrigger: Float
    $upperTrigger: Float
    $overwrite: Boolean,
    $resultDate: String
  ) {
    createMetric(
      goal: $goal
      period: $period
      periodValue: $periodValue
      periodKey: $periodKey
      periodStatus: $periodStatus
      value: $value
      status: $status
      isTrend: $isTrend
      target: $target
      lowerTrigger: $lowerTrigger
      upperTrigger: $upperTrigger
      overwrite: $overwrite
      resultDate: $resultDate
    ) {
      id
      value
      status
      periodValue
      periodStatus
      target
      lowerTrigger
      upperTrigger
      issue {
        id
        nextStepStatus
      }
    }
  }
`;

export const UPDATE_METRIC = gql`
  mutation UpdateMetric(
    $id: ID!
    $value: Float
    $status: MetricStatus
    $periodStatus: MetricPeriodStatus
    $target: Float
    $lowerTrigger: Float
    $upperTrigger: Float
    $issue: String
  ) {
    updateMetric(
      id: $id
      value: $value
      status: $status
      periodStatus: $periodStatus
      target: $target
      lowerTrigger: $lowerTrigger
      upperTrigger: $upperTrigger
      issue: $issue
    ) {
      id
      value
      status
      periodValue
      periodStatus
      target
      lowerTrigger
      upperTrigger
      issue {
        id
        nextStepStatus
      }
    }
  }
`;



export const UPDATE_TEAM_GOAL_GRAPH_CONFIG = gql`
  mutation UpdateTeamGoalGraphConfig(
    $id: ID!
    $targetTo: Float
    $targetFrom: Float
    $upperTriggerTo: Float
    $upperTriggerFrom: Float
    $lowerTriggerTo: Float
    $lowerTriggerFrom: Float
    $frequencyTrigger: Float
  ) {
    updateTeamGoalGraphConfig(
      id: $id
      targetTo: $targetTo
      targetFrom: $targetFrom
      upperTriggerTo: $upperTriggerTo
      upperTriggerFrom: $upperTriggerFrom
      lowerTriggerTo: $lowerTriggerTo
      lowerTriggerFrom: $lowerTriggerFrom
      frequencyTrigger: $frequencyTrigger
    ) {
      id
      key
      targetFrom
      targetTo
      upperTriggerTo
      upperTriggerFrom
      lowerTriggerTo
      lowerTriggerFrom
      frequencyTrigger
    }
  }
`;

export const CREATE_TEAM_GOAL_GRAPH_CONFIG = gql`
  mutation CreateTeamGoalGraphConfig(
    $key: String!
    $teamMeasurementId: String!
    $targetTo: Float
    $targetFrom: Float
    $upperTriggerTo: Float
    $upperTriggerFrom: Float
    $lowerTriggerTo: Float
    $lowerTriggerFrom: Float
    $frequencyTrigger: Float
  ) {
    createTeamGoalGraphConfig(
      key: $key
      teamMeasurementId: $teamMeasurementId
      targetTo: $targetTo
      targetFrom: $targetFrom
      upperTriggerTo: $upperTriggerTo
      upperTriggerFrom: $upperTriggerFrom
      lowerTriggerTo: $lowerTriggerTo
      lowerTriggerFrom: $lowerTriggerFrom
      frequencyTrigger: $frequencyTrigger
    ) {
      id
      key
      targetFrom
      targetTo
      upperTriggerFrom
      upperTriggerTo
      lowerTriggerFrom
      lowerTriggerTo
      frequencyTrigger
    }
  }
`;