import { client } from "./BaseService";
import { gql } from "apollo-boost";

const getMeasurements = async () => {
  return await client.query({
    query: gql`query {
      goalCategories {
        id
        name
        hasGoals
        measurements {
          id
          measurement
          unit {
            id
            name
          }
        }
      }
    }`
  })
}

const createMeasurement = async (goalCategoryId, measurement, unitId) => {
  return await client.mutate({
    mutation: gql`mutation {
      createMeasurement(
        goalCategoryId: "${goalCategoryId}",
        measurement: "${measurement}",
        unitId:"${unitId}"
      )
      {
        id
      }
    }`
  })
}

const updateMeasurement = async (id, goalCategoryId, measurement, unitId) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateMeasurement(
        id: "${id}",
        goalCategoryId: "${goalCategoryId}",
        measurement: "${measurement}",
        unitId:"${unitId}"
      )
      {
        id
      }
    }`
  })
}

const deleteMeasurement = async(id) => {
  return await client.mutate({
    mutation: gql`mutation {
      deleteMeasurement(
        id: "${id}"
      )
      {
        id
      }
    }`
  })
}

export {
  getMeasurements,
  createMeasurement,
  updateMeasurement,
  deleteMeasurement
}
