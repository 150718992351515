import React, { Component, Fragment } from "react"
import { Table, Tooltip, OverlayTrigger, Row, Col, ButtonGroup, ToggleButton, Button } from 'react-bootstrap'
import { faSpinner, faPlus, faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { connect } from "react-redux";
import { updateIssue, deleteIssue } from '../services/CustomerSupplierService';

import { getInnovations, createAction } from '../services/InnovationsService';
import { createIssue } from '../services/IssueService';
import { archiveFeedback, updateSharedStatus } from '../services/CoachesFeedbackService';
import displayErrorToast from "../components/utils/displayErrorToast";
import SubHeaderComponent from "../components/SubHeaderComponent";
import RealtimeRefreshComponent from "../components/RealtimeRefreshComponent";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import LabelComponent from "../components/utils/getCompanyLabel";
import * as DefaultLabels from '../assets/glossary.json';
// icons
import Archive from '../assets/ButtonIcons/Archive Blue.svg';
import Cancel from '../assets/ButtonIcons/Cancel Blue.svg';
import Delete from '../assets/ButtonIcons/Delete Blue.svg';
import Edit from '../assets/ButtonIcons/Edit Blue.svg';
import Loader from '../assets/ButtonIcons/Loader Blue.svg';
import PutBack from '../assets/ButtonIcons/Put Back Blue.svg';
import Save from '../assets/ButtonIcons/Save Blue.svg';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import NextStep from "../components/shared/NextStep";

const workSheetName = 'Worksheet-1';
const columns = [
  { header: 'Date', key: 'reviewDate' },
  { header: 'Feedback', key: 'whatText' },
  { header: 'Shared', key: 'shared' },
  { header: 'Next Step Status', key: 'nextStepStatus' },
];
const labels = DefaultLabels.default;

const getLabelText = key => <LabelComponent val={key}/>

function renderTooltip(props, text) {
  return (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );
}

class CoachesFeedbackScreen extends Component {
  state = {
    innovations: [],
    loading: false,
    loadingId: "",
    loadingType: "",
    loadingKey: "",
    date: "",
    text: "",
    editable: false,
    feedbackId: "",
    archived: false,
    users: [],
    type: "",
    button: true,
  }

  constructor(props) {
    super(props)

    this.props.togglePageLoad(true)
  }

  async componentDidMount() {
    if (this.props.selectedTeam.id !== 0) {
      await this.fetchCoachesFeedback()
      let users = [...this.props.selectedTeam.members, ...this.props.selectedTeam.leaders]

      this.setState({
        users: users.sort((a, b) => a.firstNames.localeCompare(b.firstNames))
      })
      this.props.togglePageLoad(false)
    }
  }


  async componentDidUpdate(prevProps) {
    if (prevProps.selectedTeam.id !== this.props.selectedTeam.id) {
      this.props.togglePageLoad(true)
      await this.fetchCoachesFeedback()
      let users = [...this.props.selectedTeam.members, ...this.props.selectedTeam.leaders]

      this.setState({
        users: users.sort((a, b) => a.firstNames.localeCompare(b.firstNames))
      })
      this.props.togglePageLoad(false)
    }

    if (this.props.refresh && prevProps.refresh !== this.props.refresh) {
      this.props.togglePageLoad(true)
      await this.fetchCoachesFeedback()
      let users = [...this.props.selectedTeam.members, ...this.props.selectedTeam.leaders]

      this.setState({
        users: users.sort((a, b) => a.firstNames.localeCompare(b.firstNames))
      })
      this.props.toggleRefresh()
      this.props.toggleHasNewData()
      this.props.togglePageLoad(false)
    }
  }

  getText = val => {
    const { user } = this.props;

    const companyId = localStorage.getItem("companyId");
    let lang = "EN";
    let langLabels = labels.find(lbl => lbl.lang === lang);
    let def = langLabels.glossary.find(label => label.key === val);

    if(user && user.companies && user.companies.length) {
      const company = user.companies.find(company => company.id === companyId);
      if(company) {
        lang = company.language;
        langLabels = labels.find(lbl => lbl.lang === lang);
        def = langLabels.glossary.find(label => label.key === val);
        if(!def) {
          lang = "EN";
          langLabels = labels.find(lbl => lbl.lang === lang);
          def = langLabels.glossary.find(label => label.key === val);
        }
      }
      if(company && company.companyGlossaries) {
        const custom = company.companyGlossaries.find(label => label.key === val);

        if(custom) return custom.value
      }
    }

    if(!def) {
      console.log(val)
    }
    return def.value
  }

  async fetchCoachesFeedback() {
    const response = await getInnovations(this.props.selectedTeam.id, "COACHES_FEEDBACK_ISSUE");

    this.setState({
      innovations: response.data.team.loggedIssues
    })
  }

  getSharedStatus(feedback) {
    const { loading, loadingId, loadingType } = this.state

    if(loading && loadingType === "approved" && loadingId === feedback.id) {
      return (<span className="status chk chk-white"><FontAwesomeIcon className="fa-spin ml-3 mt-2" icon={faSpinner}></FontAwesomeIcon></span>)
    }

    if(!feedback.shared) {
      return (<span className="status chk chk-white" onClick={() => this.toggleShared(feedback.shared, feedback.id)}></span>)
    } else {
        return <span className={"status chk chk-blue color-selected"} onClick={() => this.toggleShared(feedback.shared, feedback.id)}></span>
    }

  }

  async toggleShared(shared, id) {
    this.setLoading(id, "approved")


    if(shared) {
    }

    try {
      await updateSharedStatus(id, !shared)
      await this.fetchCoachesFeedback()
      this.cancelLoading()
    } catch(err) {
      this.cancelLoading()
      displayErrorToast(err)
    }


  }

  setLoading(id, type, key = "") {
    this.setState({
      loading: true,
      loadingId: id,
      loadingType: type,
      loadingKey: key
    })
  }

  cancelLoading() {
    this.setState({
      loading: false,
      loadingId: "",
      loadingType: ""
    })
  }

  setEdit(issue) {
    this.setState({
      editable: true,
      feedbackId: issue.id,
      whoId: issue.suggestedBy ? issue.suggestedBy.id : 0,
      date: issue.reviewDate ? new Date(issue.reviewDate) : new Date(),
      text: issue.whatText
    })
  }

  cancel = () => {
    const { type, innovations } = this.state
    if(type === "create") {
      innovations.shift()
    }
    this.setState({
      editable: false,
      feedbackId: "",
      whoId: "",
      date: "",
      text: "",
      type: "",
      innovations
    })
  }

  create = () => {
    const { innovations } = this.state
    const generatedId = Math.ceil(Math.random() * 100)

    innovations.unshift({
      id: generatedId,
      reviewDate: "",
      whatText: "",
      whyText: "",
      archived: false,
      innovationArchived: false,
      innovationStatus: "NONE",
      innovationApproved: "NONE",
      actions: []
    })

    this.setState({
      editable: true,
      feedbackId: generatedId,
      date: new Date(),
      innovations,
      type: "create"
    })
  }

  async archive(id, archived) {
    this.setLoading(id, "archive")
    try {
      await archiveFeedback(id, !archived)
      await this.fetchCoachesFeedback()
      this.cancelLoading()
    } catch (err) {
      displayErrorToast(err)
      this.cancelLoading()
    }
  }

  async nextSteps(id) {
    this.setLoading(id, "nextSteps")
    try {
      await createAction(id, "FEEDBACK", "NEXT_STEP")
      await this.fetchCoachesFeedback()
      this.cancelLoading()
    } catch (err) {
      displayErrorToast(err)
      this.cancelLoading()
    }
  }

  toggleArchived() {
    this.setState({
      archived: !this.state.archived
    })
  }

  handleDateChange = date => {
    this.setState({
      date
    })
  }

  validateInput = () => {
    const { text} = this.state
    let valid = true

    if(text === "") {
      valid = false
    }

    return valid
  }

  async save(issue) {
    const {type,  date, text} = this.state
    if(this.validateInput()) {
      this.setLoading(issue.id, "save")
      try {
        if(type === "create") {
          await createIssue(this.props.selectedTeam.id, text, "", date.toISOString(), "COACHES_FEEDBACK_ISSUE")

        } else {
          await updateIssue(issue.id, text, "", date.toISOString())
        }
        await this.fetchCoachesFeedback()
        this.cancelLoading()
        this.setState({
          editable: false,
          feedbackId: "",
          date: "",
          text: "",
          type: ""
        })
      } catch (err) {
        displayErrorToast(err)
        this.cancelLoading()
      }
    }
  }

  async delete (feedback) {
    if (window.confirm(this.getText("delete_department_prompt"))) {
      try {
        this.setLoading(feedback.id, "delete")
        await deleteIssue(feedback.id)
        await this.fetchCoachesFeedback()
      } catch (error) {
        error.graphQLErrors.map(error => {
          toast.error(error.message)

          return true
        })
      } finally {
        this.cancelLoading()
      }
    }
  }

  formattedDate(date) {
    let month = date.getMonth() + 1
    let day = date.getDate()

    if(month.toString().length < 2)
      month = "0" + month.toString()

    if(day.toString().length < 2)
      day = "0" + day.toString()

    return day + "/" + month + "/" + date.getFullYear()
  }

  handleClick() {
    this.setState({
      button: !this.state.button
    });
  }

  getNextStepsButton(issue) {
    const { loading, loadingType, loadingId, editType, actionId } = this.state;
    return (
        <NextStep
            loading={loading}
            loadingType={loadingType}
            loadingId={loadingId}
            editType={editType}
            issue={issue}
            action={issue.actions?.length ? issue.actions[0] : {}}
            actionId={actionId}
            status={this.getNextStepsStatus(issue)}
            nextSteps={() => this.nextSteps(issue.id, "NEXT_STEP")}
        />
    )
  }

  handleSort = (type) => {
    const { innovations, sortBy, orderBy } = this.state
    let newActions = innovations
    let newOrder = "asc"

    switch(type) {
      case "reviewDate":
        if(type === sortBy) {
          if (orderBy === "desc") {
            newActions.sort((a, b) => new Date(a.reviewDate).setHours(24,0,0,0) - new Date(b.reviewDate).setHours(24,0,0,0))
          } else if (orderBy === "asc") {
            newOrder = "desc"
            newActions.sort((a, b) => new Date(b.reviewDate).setHours(24,0,0,0) - new Date(a.reviewDate).setHours(24,0,0,0))
          }
        } else {
          newActions.sort((a, b) => new Date(a.reviewDate).setHours(24,0,0,0) - new Date(b.reviewDate).setHours(24,0,0,0))
        }
        break;
      case "feedback":
        if(type === sortBy) {
          if (orderBy === "desc") {
            newActions.sort((a, b) => a.whatText?.localeCompare(b.whatText))
          } else if (orderBy === "asc") {
            newOrder = "desc"
            newActions.sort((a, b) => b.whatText?.localeCompare(a.whatText))
          }
        } else {
          newActions.sort((a, b) => a.whatText?.localeCompare(b.whatText))
        }
        break;
      case "shared":
        if(type === sortBy) {
          if (orderBy === "asc") {
            newOrder = "desc";
            newActions.sort(function(a, b) {
              return (a.shared === b.shared)? 0 : a.shared? -1 : 1;
            });

          } else if(orderBy === "desc") {
            newActions.sort(function(a, b) {
              return (a.shared === b.shared)? 0 : a.shared? 1 : -1;
            });

          }
        } else {
          newActions.sort(function(a, b) {
            return (a.shared === b.shared)? 0 : a.shared? 1 : -1;
          });

        }
        break;

      case "nextSteps":
        const noActions = newActions.filter(action => action.shared);
        const postableActions = newActions.filter(action => !action.shared && !action.actions.length);
        const inProgressActions = newActions.filter(action => !action.shared && action.actions.length && action.actions.filter(ac => ac.status !== "COMPLETED").length > 0)
        const completedActions = newActions.filter(action => !action.shared && action.actions.length && action.actions.filter(ac => ac.status !== "COMPLETED").length === 0)

        if(type === sortBy) {
          if (orderBy === "asc") {
            newOrder = "desc";
            newActions = [...inProgressActions, ...completedActions, ...noActions, ...postableActions];
          } else if(orderBy === "desc") {
            newActions = [...postableActions, ...noActions, ...completedActions, ...inProgressActions];
          }
        } else {
          newActions = [...postableActions, ...noActions, ...completedActions, ...inProgressActions];
        }
        break;

      default:

        break;
    }

    this.setState({
      orderBy: newOrder,
      sortBy: newOrder === "" ? "" : type,
      innovations: newActions
    })
  }

  getNextStepsStatus(issue) {
    const nextStepCount = issue.actions.filter(action => action.status === "NEXT_STEP" || action.status === "TRIGGERED").length
    const completedCount = issue.actions.filter(action => action.status === "COMPLETED").length
    const totalNextSteps = issue.actions.filter(action => action.status !== "NONE").length
    if (!issue.shared && nextStepCount > 0 && completedCount !== totalNextSteps) {
      return "NEXT_STEP";
    } else if (!issue.shared && (completedCount !== totalNextSteps || totalNextSteps === 0)) {
      return "NONE";
    } else if (!issue.shared && totalNextSteps > 0 && completedCount === totalNextSteps) {
      return "COMPLETED";
    } else {
      if (issue.shared && nextStepCount > 0 && completedCount !== totalNextSteps) {
        return "NEXT_STEP";
      }
      else if (issue.shared && (completedCount !== totalNextSteps || totalNextSteps === 0)) {
        return "NONE";
      }
      else {
        return "COMPLETED";
      }
    }
  }
  
  saveExcel = async () => {
    const workbook = new Excel.Workbook();
    try {
      let allLoggedIssues = this.state.innovations;
      if (allLoggedIssues != null && allLoggedIssues.length > 0) {
        allLoggedIssues = allLoggedIssues.filter(a => a.archived === this.state.archived);
      }
      let teamName;
      if (this.props.selectedTeam.id !== 0) {
        teamName = this.props.selectedTeam.name;
      }
      let exportTypeText = this.state.archived ? '_Archived': '_Active';
      const fileName = teamName + "_CoachesFeedback" + exportTypeText;
      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(workSheetName);

      // add worksheet columns
      // each columns contains header and its mapping key from data
      worksheet.columns = columns;

      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true };

      // loop through all of the columns and set the alignment with width.
      worksheet.columns.forEach(column => {
        column.width = column.header.length + 15;
        column.alignment = { horizontal: 'left' };
      });

      //Prepare data 
      let allIssues = allLoggedIssues.map((issue, index) => ({
        reviewDate: this.formattedDate(new Date(issue.reviewDate)),
        whatText: issue.whatText,
        nextStepStatus: this.getNextStepsStatus(issue),
        shared: issue.shared ? "Yes" : "No"
      }));

      allIssues.sort((a, b) => {
        return (new Date(b.reviewDate).setHours(24, 0, 0, 0) - new Date(a.reviewDate).setHours(24, 0, 0, 0))
      })
      allIssues.forEach(singleData => {
        worksheet.addRow(singleData);
      });

      // loop through all of the rows and set the outline style.
      worksheet.eachRow({ includeEmpty: false }, row => {
        // store each cell to currentCell
        const currentCell = row._cells;

        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach(singleCell => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;

          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        });
      });

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error('Error saving file - ', error.message);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
    }
  };

  renderRow(issue, i) {
    const { editable, feedbackId, date, text  } = this.state

    if(editable && feedbackId === issue.id) {
      return (
        <tr key={i}>
          <td>
            <DatePicker
              name="date"
              className="form-control"
              required={true}
              dateFormat="yyyy/MM/dd"
              showYearDropdown
              selected={date}
              onChange={this.handleDateChange}
              tabIndex="0"
              autoComplete="off"
            />
          </td>
          <td>
            <input
              tabIndex="1"
              type="text"
              className="form-control"
              value={text}
              onChange={(e) => this.setState({text: e.target.value})}
              autoComplete="off"
            ></input>
          </td>
          <td className="disabled">
              {this.getSharedStatus(issue)}
          </td>
          <td className="disabled d-flex align-items-center justify-content-start border-0">
            {this.getNextStepsButton(issue)}
          </td>
          <td>
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, getLabelText("save"))}
            >
              <button className={"btn-icons-container" + (!this.validateInput() ? " disabled" : "")} type="submit" onClick={() => this.save(issue)}>
                {this.state.loading && this.state.loadingType === "save" && this.state.loadingId === issue.id
                  ? (
                    <img src={Loader} alt="Loader" className="loader-spinner"/>
                  ) : (
                    <img src={Save} alt="Save" className="btn-icons"/>
                  )
                }
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, getLabelText("cancel"))}
            >
              <button className="btn-icons-container" type="submit" onClick={this.cancel}>
                <img src={Cancel} alt="Cancel" className="btn-icons"/>
              </button>
            </OverlayTrigger>
          </td>
        </tr>
      )
    } else {
      return (
        <tr key={i}>
          <td>{this.formattedDate(new Date(issue.reviewDate))}</td>
          <td>{issue.whatText}</td>
          <td className={this.state.archived || this.state.editable ? "disabled" : ""}>
              {this.getSharedStatus(issue)}
          </td>
          <td className={this.state.archived || this.state.editable ? "disabled" : "" + " d-flex justify-content-start align-items-center border-0"}>
            {this.getNextStepsButton(issue)}
          </td>
          <td>
            {!this.state.archived && (
              <OverlayTrigger
                placement="top"
                delay={{ show: 25, hide: 40 }}
                overlay={(props) => renderTooltip({...props}, getLabelText("edit"))}
              >
                <button className={"btn-icons-container" + (this.state.editable ? " disabled" : "")} disabled={this.state.archived} type="submit" onClick={() => this.setEdit(issue)}>
                  <img src={Edit} alt="Edit" className="btn-icons"/>
                </button>
              </OverlayTrigger>
            )}
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, issue.archived ? getLabelText("put_back") : getLabelText("archive"))}
            >
              <button className={"btn-icons-container" + (this.state.editable ? " disabled" : "")} type="submit" onClick={() => this.archive(issue.id, issue.archived)}>
                {this.state.loading && this.state.loadingType === "archive" && this.state.loadingId === issue.id
                  ? (
                    <img src={Loader} alt="Loader" className="loader-spinner"/>
                  ) : issue.archived
                    ? (
                      <img src={PutBack} alt="PutBack" className="btn-icons"/>
                    ) : (
                      <img src={Archive} alt="Archive" className="btn-icons"/>
                    )
                }
              </button>
            </OverlayTrigger>
            {this.state.archived && (
              <OverlayTrigger
                placement="top"
                delay={{ show: 25, hide: 40 }}
                overlay={(props) => renderTooltip({...props}, getLabelText("delete"))}
              >
                <button className="btn-icons-container" type="submit" onClick={() => this.delete(issue)}>
                  {this.state.loading && this.state.loadingType === "delete" && this.state.loadingId === issue.id
                    ? (
                      <img src={Loader} alt="Loader" className="loader-spinner"/>
                    ) : (
                      <img src={Delete} alt="Delete" className="btn-icons"/>
                    )
                  }
                </button>
              </OverlayTrigger>
            )}
          </td>
        </tr>
      )
    }
  }
  render () {
    return (
      <Fragment>
        <SubHeaderComponent includeCoach={true}>
          <Col className="text-right childenSpace">
            <div className={"refreshBtn"}>
              <RealtimeRefreshComponent
                refresh={this.props.refresh}
                hasNewData={this.props.hasNewData}
                toggleRefresh={this.props.toggleRefresh}
              />
            </div>
            {!this.state.archived && (
              <Button className={"ml-2 btn btn-primary" + (this.state.editable ? " disabled" : "")} onClick={() => this.create()}>{getLabelText("add")}<FontAwesomeIcon className="ml-2" icon={faPlus} /></Button>
            )}
          </Col>
        </SubHeaderComponent>
        <div className="grey-header-space">
          <Row className="mb-3">
            <Col className="text-right pr-0">
              <ButtonGroup toggle className="ml-1">
                <ToggleButton
                    type="radio"
                    variant="secondary"
                    name="createType"
                    value="0"
                    checked={!this.state.archived}
                    onChange={() => this.toggleArchived()}
                >
                  {getLabelText("active")}
                </ToggleButton>
                <ToggleButton
                    type="radio"
                    variant="secondary"
                    name="createType"
                    value="1"
                    checked={this.state.archived}
                    onChange={() => this.toggleArchived()}
                >
                  {getLabelText("archived")}
                </ToggleButton>
                <Button onClick={(e) => this.saveExcel()}>Export</Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row className="pb-5">
            <Col className="pr-0">
              <Table striped bordered hover className="standard-tbl standard-coaches-feedback-tbl mt-2 table-heading-background user-text">
                <thead>
                <tr>
                  <th width="20%" onClick={() => this.handleSort("reviewDate")}>{getLabelText("date")} {this.state.sortBy === "reviewDate" && (
                      (this.state.orderBy === "asc" && (
                          <FontAwesomeIcon className="ml-2" icon={faChevronUp} />
                      )) || (this.state.orderBy === "desc" && (
                          <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
                      ))
                  )}</th>
                <th width="50%" onClick={() => this.handleSort("feedback")}>{getLabelText("feedback")} {this.state.sortBy === "feedback" && (
                      (this.state.orderBy === "asc" && (
                          <FontAwesomeIcon className="ml-2" icon={faChevronUp} />
                      )) || (this.state.orderBy === "desc" && (
                          <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
                      ))
                  )}</th>
                <th width="10%" onClick={() => this.handleSort("shared")}>{getLabelText("shared")} {this.state.sortBy === "shared" && (
                      (this.state.orderBy === "asc" && (
                          <FontAwesomeIcon className="ml-2" icon={faChevronUp} />
                      )) || (this.state.orderBy === "desc" && (
                          <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
                      ))
                  )}</th>
                  <th width="10%" onClick={() => this.handleSort("nextSteps")}> {this.state.sortBy === "nextSteps" && (
                      (this.state.orderBy === "asc" && (
                          <FontAwesomeIcon className="ml-2" icon={faChevronUp} />
                      )) || (this.state.orderBy === "desc" && (
                          <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
                      ))
                  )}</th>
                  <th width="10%"></th>
                </tr>
                </thead>
                <tbody>
                {this.state.innovations.map((issue, k) => {
                  if(issue.archived === this.state.archived) {
                    return this.renderRow(issue, k)
                  }
                  return null
                })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam,
  user: state.userReducer.user
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(CoachesFeedbackScreen);
