import { toast } from "react-toastify";

const displayErrorToast = (error = {}) => {
  console.log(error.graphQLErrors);
  if (error.graphQLErrors && error.graphQLErrors.length) {
    error.graphQLErrors.forEach((error) => {
      toast.error(error.message);
    });
  } else {
    // console.error(error);
    const message =
      process.env.NODE_ENV === "production"
        ? "Oops, something went wrong!"
        : error.message;
    toast.error(message);
  }
};

export default displayErrorToast;
