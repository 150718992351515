import * as presentationActions from '../actions/PresentationActions';

export const startPresentation = () => async (dispatch) => {
  dispatch(presentationActions.presentationStarted());
};

export const endPresentation = () => async (dispatch) => {
  dispatch(presentationActions.presentationEnded());
};

export const updatePresentation = (payload) => async (dispatch) => {
  dispatch(presentationActions.presentationUpdated(payload));
};
