import React from 'react';
import { Col, Row } from "react-bootstrap";
import { getWeekNumber, getCalculatedDisplayWeek } from '../resources/utils';

const ChartHeader = ({getLabelText, ...props}) => {
  const weeksInYear = (year) => {
    return 53;
  }

  const renderWeeks = () => {
    const items = [];
    const date = new Date();
    const total = weeksInYear();
    const years = [date.getFullYear() - 1, date.getFullYear(), date.getFullYear() + 1];
    for(let i = 0; i < years.length; i++) {
      for (let index = 0; index < total; index++) {
        items.push(
          <div style={{ textAlign: 'center', display: 'inline-block', width: 100 }} ref={(index + 1) === getWeekNumber(date) && date.getFullYear() === years[i] ? props.activeRef : null} key={`${index}${years[i]}`}>{`week ${getCalculatedDisplayWeek((index + 1), props.startWeek ? props.startWeek.week : null)}`}</div>
        )
      }
    }

    return items;
  };

  if (props.type === "keys") {
    return (
      <Col lg={12} xs={12}>
        <Row className="width-scroller" style={{ marginLeft: -15 }}>
          <Col lg={5} xs={5} className="pl-1 pr-0 text-left"><b>{getLabelText("project_milestone")}</b></Col>
          <Col lg={1} md={1} xs={1} className="px-0"></Col>
          <Col lg={2} md={2} xs={2} className="px-0 text-center"><b>{getLabelText("owner")}</b></Col>
          <Col xs={4} className="pr-1 pl-0"></Col>
        </Row>
      </Col>
    )
  } else {
    return (
      <Col lg={12} xs={12}>
        <Row>
          <Col lg={12} xs={12}>
            <div style={{ display: 'inline-block', overflow: 'auto', whiteSpace: 'nowrap' }}>
              {renderWeeks()}
            </div>
          </Col>
        </Row>
      </Col>
    )
  }
}

export default ChartHeader;
