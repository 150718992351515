import React, { useEffect, useState, Fragment  } from "react"
import {Row, Col, Table, Button} from "react-bootstrap";
import { connect } from "react-redux";
import Modal from 'react-modal';
import {
  MODULES_QUERY,
  CREATE_SUBJECT_MUTATION,
  UPDATE_SUBJECT_MUTATION,
  DELETE_SUBJECT_MUTATION
} from "./resources/gql";
import { useMutation, useQuery } from "@apollo/react-hooks";
import ModuleRow from "./ModuleRow"
import displayErrorToast from "../../components/utils/displayErrorToast";
import { mapModuleData } from "./resources/mapModuleData"
import SubHeaderComponent from "../../components/SubHeaderComponent";
import * as DefaultLabels from '../../assets/glossary.json';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import LabelComponent from "../../components/utils/getCompanyLabel";
import {
  CREATE_MODULE_MUTATION,
  DELETE_MODULE_MUTATION,
  UPDATE_MODULE_MUTATION
} from "../ReviewModulesScreen/resources/gql";
const labels = DefaultLabels.default;

const getLabelText = key => <LabelComponent val={key}/>

const ReviewSubjectsScreen = ({ togglePageLoad, user, ...props }) => {
  const { loading, data } = useQuery(MODULES_QUERY)
  const [modules, setModules] = useState([])
  const [isCreatingNewItem, setIsCreatingNewItem] = useState(false);
  const [itemBeingEdited, setItemBeingEdited] = useState("");
  const [moduleId, setModuleId] = useState(null)
  const [moduleName, setModuleName] = useState("");
  const [openModuleModal, setOpenModuleModal] = useState(false);

  const reset = () => {
    setIsCreatingNewItem(false);
    setItemBeingEdited("");
    setModuleId(null);
    setModuleName("");
    setOpenModuleModal(false);
    props.toggleDirtyState()
  };

  const onError = (error) => {
    displayErrorToast(error);
    reset();
  };

  useEffect(() => {
    if(data && data.reviewModules) setModules(data.reviewModules)
    togglePageLoad(loading)
  }, [loading, data, setModules, togglePageLoad])

  const [createSubject] = useMutation(CREATE_SUBJECT_MUTATION, {
    onError,
    onCompleted: ({ createReviewSubject }) => {
      setModules(mapModuleData(modules, moduleId, itemBeingEdited, "CREATE", createReviewSubject));
      reset();
    },
  });

  const [updateSubject] = useMutation(UPDATE_SUBJECT_MUTATION, {
    onError,
    onCompleted: ({ updateReviewSubject }) => {
      setModules(mapModuleData(modules, moduleId, itemBeingEdited, "UPDATE", updateReviewSubject));
      reset();
    },
  });

  const [deleteSubject] = useMutation(DELETE_SUBJECT_MUTATION, {
    onError,
    onCompleted: ({ deleteReviewSubject }) => {
      setModules(mapModuleData(modules, moduleId, deleteReviewSubject.id, "DELETE"));
      reset();
    },
  });

  const onOpenCreate = (id) => {
    setIsCreatingNewItem(true);
    setModuleId(id)
    props.toggleDirtyState()
    setItemBeingEdited("");
  };

  const onOpenEdit = (id, moduleId) => {
    setIsCreatingNewItem(false);
    setModuleId(moduleId)
    props.toggleDirtyState()
    setItemBeingEdited(id);
  };

  const onDelete = (id, moduleId) => {
    setModuleId(moduleId);
    deleteSubject({ variables: { id } });
  };

  const onSave = ({ id, name }) => {
    if (id) return updateSubject({ variables: { id, name } });
    createSubject({ variables: { name, module: moduleId } });
  };

  const getText = val => {
    const companyId = localStorage.getItem("companyId");
    let lang = "EN";
    let langLabels = labels.find(lbl => lbl.lang === lang);
    let def = langLabels.glossary.find(label => label.key === val);

    if(user && user.companies && user.companies.length) {
      const company = user.companies.find(company => company.id === companyId);
      if(company) {
        lang = company.language;
        langLabels = labels.find(lbl => lbl.lang === lang);
        def = langLabels.glossary.find(label => label.key === val);
        if(!def) {
          lang = "EN";
          langLabels = labels.find(lbl => lbl.lang === lang);
          def = langLabels.glossary.find(label => label.key === val);
        }
      }
      if(company && company.companyGlossaries) {
        const custom = company.companyGlossaries.find(label => label.key === val);

        if(custom) return custom.value
      }
    }

    if(!def) {
      console.log(val)
    }
    return def.value
  }

  const editModule = (id, name) => {
    setModuleId(id);
    setModuleName(name);
    setOpenModuleModal(true);
  }

  const saveModule = () => {
    if(!moduleId) {
      createModule({
        variables: {
          name: moduleName
        }
      })
    } else {
      updateModule({
        variables: {
          id: moduleId,
          name: moduleName
        }
      })
    }
  }

  const onDeleteModule = (id) => {
    deleteModule({
      variables: {
        id: id
      }
    })
  }

  const [createModule] = useMutation(CREATE_MODULE_MUTATION, {
    onError,
    onCompleted: ({ createReviewModule }) => {
      setModules([{...createReviewModule, subjects: []}, ...modules]);
      reset();
    },
  });

  const [updateModule] = useMutation(UPDATE_MODULE_MUTATION, {
    onError,
    onCompleted: ({ updateReviewModule }) => {
      const nextModules = modules.map((e) =>
          e.id === updateReviewModule.id ? {...updateReviewModule, subjects: []} : e
      );
      setModules(nextModules);
      reset();
    },
  });

  const [deleteModule] = useMutation(DELETE_MODULE_MUTATION, {
    onError,
    onCompleted: ({ deleteReviewModule }) => {
      setModules(
          modules.filter((e) => e.id !== deleteReviewModule.id)
      );
      reset();
    },
  });

  return (
    <Fragment>
      <SubHeaderComponent>
        <Row>
          <Button className={"btn btn-primary btn-nrml ml-2"} onClick={() => setOpenModuleModal(true)}>{getLabelText("add")}<FontAwesomeIcon className="ml-2" icon={faPlus} /></Button>
        </Row>
      </SubHeaderComponent>
      <div className="standardise-space-between-grey-header-and-content standardise-space-left-and-left-align-logo-page-title-and-content">
        <Row>
          <Col>
            <Table bordered className="table-heading-background bordered-minus-1">
              <tbody>
              {modules.map(
                  module => (
                      <ModuleRow
                          key={module.id}
                          module={module}
                          create={onOpenCreate}
                          save={onSave}
                          edit={onOpenEdit}
                          onDelete={onDelete}
                          getText={getText}
                          itemId={itemBeingEdited}
                          moduleId={moduleId}
                          isCreatingNewItem={isCreatingNewItem}
                          reset={reset}
                          editModule={editModule}
                          onDeleteModule={onDeleteModule}
                      />
                  )
              )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>

      <Modal
          isOpen={openModuleModal}
          onRequestClose={() => reset()}
          contentLabel="Result"
          className="standard-modal standard-modal-modules"
      >
        <div className="d-flex mb-2">
          <div className="col-12 text-center">
            <h6 className="cdi-blue-txt ml-0 mb-1">Add Module</h6>
          </div>
        </div>
        <Row className="mt-3">
          <Col lg={3} className="d-flex align-items-center">
            <label className="m-0">Module</label>
          </Col>
          <Col lg={9}>
            <input className="form-control" value={moduleName} onChange={(e) => setModuleName(e.target.value)} />
          </Col>
        </Row>
        <Row className="mt-4 width">
          <Col xs={6} className="text-right">
            <Button variant="secondary" className={"btn-scheduled"} onClick={() => reset()}>
              Cancel
            </Button>
          </Col>
          <Col xs={6} className="text-left">
            <Button variant="primary" disabled={!moduleName} className={"btn-scheduled"} onClick={() => saveModule()}>
              Save
            </Button>
          </Col>
        </Row>
      </Modal>
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user
})

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewSubjectsScreen);
