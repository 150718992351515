import React, { useEffect, useState, useCallback } from "react";
import { useMutation } from "@apollo/react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import classnames from "../../components/utils/classnames";
import displayErrorToast from "../../components/utils/displayErrorToast";
import {
    CREATE_TASK_TRACKER_PERIOD_MUTATION,
    UPDATE_TASK_TRACKER_PERIOD_MUTATION
} from "../../services/TaskCategoryService";

const PERIOD_STATES = [
    "NORMAL",
    "TRIGGER",
    "NON_WORKING",
];

const dateCellProps = {
    [PERIOD_STATES[0]]: { classes: "", loadingClasses: "fa-spin text-black" },
    [PERIOD_STATES[1]]: { classes: "bg-danger text-white", loadingClasses: "fa-spin text-white" },
    [PERIOD_STATES[2]]: { classes: "bg-dark text-white", loadingClasses: "fa-spin text-white" },
};

const TaskTrackerPeriodCell = ({
    result,
    teamId,
    day,
    today,
    onCellChange
}) => {
    const [resultId, setResultId] = useState(result.id);
    const [status, setStatus] = useState(result.status);
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(result.date);
    const [currDate, setCurrDate] = useState(today);
    const [displayedStatus, setDisplayedStatus] = useState(result.status);

    const onError = (error) => {
        setDisplayedStatus(status);
        displayErrorToast(error);
        setLoading(false);
    };

    useEffect(() => {
        if(currDate.getMonth() === today.getMonth()) return;

        setResultId(result.id);
        setStatus(result.status);
        setDisplayedStatus(result.status);
        setDate(result.date);
        setCurrDate(today);
    }, [result, setResultId, setDisplayedStatus, setStatus, currDate, setCurrDate, today, setDate]);

    const [updateTaskTrackerPeriod] = useMutation(UPDATE_TASK_TRACKER_PERIOD_MUTATION, {
        onError,
        onCompleted: ({ updateTaskTrackerPeriodMetric }) => {
            setChange(updateTaskTrackerPeriodMetric.status);
            setStatus(updateTaskTrackerPeriodMetric.status);
            setLoading(false);
        },
    });

    const [createTaskTrackerPeriod] = useMutation(CREATE_TASK_TRACKER_PERIOD_MUTATION, {
        onError,
        onCompleted: ({ createTaskTrackerPeriodMetric }) => {
            setChange(createTaskTrackerPeriodMetric.status);
            setStatus(createTaskTrackerPeriodMetric.status);
            setResultId(createTaskTrackerPeriodMetric.id);
            setLoading(false);
        },
    });

    const create = useCallback((newStatus) => {
        createTaskTrackerPeriod({
            variables: {
                id: teamId,
                status: newStatus,
                date: date,
                teamId: teamId
            },
        });
    }, [createTaskTrackerPeriod, teamId, date]);

    const update = useCallback((newStatus) => {
        updateTaskTrackerPeriod({
            variables: {
                status: newStatus,
                id: resultId,
                teamId: teamId
            },
        });
    }, [updateTaskTrackerPeriod, resultId]);

    const setChange = (status) => {
        onCellChange({ state: PERIOD_STATES.indexOf(status), index: day });
    }

    const toggleStatus = () => {
        const nextIndex = PERIOD_STATES.indexOf(displayedStatus) === 2 ? 0 : (PERIOD_STATES.indexOf(displayedStatus) + 1);

        setDisplayedStatus(PERIOD_STATES[nextIndex]);

        return PERIOD_STATES[nextIndex];
    }

    const onClick = () => {
        setLoading(true);
        const newStatus = toggleStatus();
        if(resultId) return update(newStatus);
        create(newStatus);
    };

    const className = classnames(
        "attendance-cell attendance-date-border text-center p-1 border mr-2",
        dateCellProps[displayedStatus].classes,
        (loading ? " disabled" : "")
    );

    return (
        <div onClick={onClick} className={className}>
            {loading
                ? (<FontAwesomeIcon icon={faSpinner} className={dateCellProps[displayedStatus].loadingClasses} />)
                : <span>{day}</span>
            }
        </div>
    );
};

export default TaskTrackerPeriodCell;
