import { gql } from "@apollo/client";

export const IMPLEMENTATION_MODULES_QUERY = gql`
    query {
        implementationModules {
            id
            name
            milestones {
                id
                name
            }
        }
    }
`;


export const CREATE_IMPLEMENTATION_MODULE_MUTATION = gql`
    mutation createImplementationModule (
        $name: String!
    )
    {
        createImplementationModule(name: $name)
        {
            id
            name
        }
    }
`;

export const UPDATE_IMPLEMENTATION_MODULE_MUTATION = gql`
    mutation updateImplementationModule (
        $id: ID!
        $name: String!
        $deleted: Boolean!
    )
    {
        updateImplementationModule(id:$id, name: $name, deleted: $deleted)
        {
            id
            name
            deleted
        }
    }
`;

export const CREATE_IMPLEMENTATION_MILESTONE_MUTATION = gql`
    mutation createImplementationMilestone (
        $implementationModule: ID!
        $name: String!
    )
    {
        createImplementationMilestone(implementationModule:$implementationModule, name: $name)
        {
            id
            name
            deleted
        }
    }
`;

export const UPDATE_IMPLEMENTATION_MILESTONE_MUTATION = gql`
    mutation updateImplementationMilestone (
        $id: ID!
        $name: String!
        $deleted: Boolean!
    )
    {
        updateImplementationMilestone(id:$id, name: $name, deleted: $deleted)
        {
            id
            name
            deleted
        }
    }
`;
