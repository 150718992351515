import _ from 'lodash';
import { getMonth } from "date-fns";
import {
  getISOWeeksInMonth,
  getCalculatedCurrentTarget
} from "../hooks";


export const lightBlue = "#4086A4";

export const useDomainBars = (range, data, domain, upperTrigger = null, lowerTrigger = null, target = null, isTrend = false) => {
  if(isTrend) {
    const maxTarget =  _.maxBy(data, (target) => {
      return target.target
    })

    const maxValue =  _.maxBy(data, (target) => {
      return target.y
    })

    const minTarget =  _.minBy(data, (target) => {
      return target.target
    })

    const minValue =  _.minBy(data, (target) => {
      return target.y
    })

    let values = [target[0], target[1], (maxTarget && (maxTarget.target || maxTarget.target === 0) ? maxTarget.target : ""), (maxValue && (maxValue.y || maxValue.y === 0) ? maxValue.y : "" ), (minTarget && (minTarget.target || minTarget.target === 0) ? minTarget.target : ""), (minValue && (minValue.y || minValue.y === 0) ? minValue.y : "" ), range[0], range[1]]
    let filtered = values.filter(function (el) {
      return el != null && el !== "";
    });

    let max
    let min
    if(filtered.length > 0) {
      max = filtered.reduce(function (p, v) {
        return ( p > v && p !== "" ? p : v );
      });
      min = filtered.reduce(function (p, v) {
        return ( p < v && p !== "" ? p : v );
      });
    } else {
      max = 10
      min = 0
    }

    if(isNaN(min)) {
      min = 0
    }

    if(isNaN(max)) {
      max = 10
    }
    return data.map(({ x }) => ({ x, y: domain[1] > max ? parseFloat(domain[1] + (min !== max ? ((max - min) * 0.2) : (max * 0.2))) : parseFloat(max + (min !== max ? ((max - min) * 0.2) : (max * 0.2))) }));
  }
  const maxTarget =  _.maxBy(data, (target) => {
    return target.target
  })

  const maxValue =  _.maxBy(data, (target) => {
    return target.y
  })

  const minTarget =  _.minBy(data, (target) => {
    return target.target
  })

  const minValue =  _.minBy(data, (target) => {
    return target.y
  })

  let values = [upperTrigger[0], lowerTrigger[0], target[0], upperTrigger[1], lowerTrigger[1], target[1], (maxTarget && (maxTarget.target || maxTarget.target === 0) ? maxTarget.target : ""), (maxValue && (maxValue.y || maxValue.y === 0) ? maxValue.y : "" ), (minTarget && (minTarget.target || minTarget.target === 0) ? minTarget.target : ""), (minValue && (minValue.y || minValue.y === 0) ? minValue.y : "" ), range[0], range[1]]
  let filtered = values.filter(function (el) {
    return el != null && el !== "";
  });

  let max
  let min
  if(filtered.length > 0) {
    max = filtered.reduce(function (p, v) {
      return ( p > v && p !== "" ? p : v );
    });
    min = filtered.reduce(function (p, v) {
      return ( p < v && p !== "" ? p : v );
    });
  } else {
    max = 10
    min = 0
  }

  if(isNaN(min)) {
    min = 0
  }

  if(isNaN(max)) {
    max = 10
  }

  return data.map(({ x }) => ({ x, y: domain[1] > max ? parseFloat(domain[1] + (min !== max ? ((max - min) * 0.2) : (max * 0.2))) : parseFloat(max + (min !== max ? ((max - min) * 0.2) : (max * 0.2))) }));
}

export const useInvertedDomainBars = (range, data, upperTrigger = null, lowerTrigger = null, target = null, isTrend = false) => {
  if(isTrend) {
    const minTarget =  _.minBy(data, (target) => {
      return target.target
    })

    const minValue =  _.minBy(data, (target) => {
      return target.y
    })

    const maxTarget =  _.maxBy(data, (target) => {
      return target.target
    })

    const maxValue =  _.maxBy(data, (target) => {
      return target.y
    })

    let values = [target[0], target[1], (minTarget && (minTarget.target || minTarget.target === 0) ? minTarget.target : ""), (minValue && (minValue.y || minValue.y === 0) ? minValue.y : "" ), (maxTarget && (maxTarget.target || maxTarget.target === 0) ? maxTarget.target : ""), (maxValue && (maxValue.y || maxValue.y === 0) ? maxValue.y : "" ), range[0], range[1]]
    let filtered = values.filter(function (el) {
      return el != null && el !== "";
    });

    let min
    let max
    if(filtered.length > 0) {
      min = filtered.reduce(function (p, v) {
        return ( p < v && p !== "" ? p : v );
      });
      max = filtered.reduce(function (p, v) {
        return ( p > v && p !== "" ? p : v );
      });
    } else {
      min = 0
      max = 10
    }

    if(isNaN(min)) {
      min = 0
    }

    if(isNaN(max)) {
      max = 10
    }

    const padding = parseFloat((min !== max ? (max - min) * 0.2 : min * 0.2))

    return data.map(({ x }) => ({ x, y: min ? min < 0 ? parseFloat(min - padding) : parseFloat(min - padding) < 0 ? 0 : parseFloat(min - padding) : 0 }));
  }
  const minTarget =  _.minBy(data, (target) => {
    return target.target
  })

  const minValue =  _.minBy(data, (target) => {
    return target.y
  })
  const maxTarget =  _.maxBy(data, (target) => {
    return target.target
  })

  const maxValue =  _.maxBy(data, (target) => {
    return target.y
  })

  let values = [upperTrigger[0], lowerTrigger[0], target[0], upperTrigger[1], lowerTrigger[1], target[1], (minTarget && (minTarget.target || minTarget.target === 0) ? minTarget.target : ""), (minValue && (minValue.y || minValue.y === 0) ? minValue.y : "" ), (maxTarget && (maxTarget.target || maxTarget.target === 0) ? maxTarget.target : ""), (maxValue && (maxValue.y || maxValue.y === 0) ? maxValue.y : "" ), range[0], range[1]]
  let filtered = values.filter(function (el) {
    return el != null && el !== "";
  });

  let min
  let max
  if(filtered.length > 0) {
    min = filtered.reduce(function (p, v) {
      return ( p < v && p !== "" ? p : v );
    });
    max = filtered.reduce(function (p, v) {
      return ( p > v && p !== "" ? p : v );
    });
  } else {
    min = 0
    max = 10
  }

  if(isNaN(min)) {
    min = 0
  }

  if(isNaN(max)) {
    max = 10
  }

  const padding = parseFloat((min !== max ? (max - min) * 0.2 : min * 0.2))
  return data.map(({ x }) => ({ x, y: min ? min < 0 ? parseFloat(min - padding) : parseFloat(min - padding) < 0 ? 0 : parseFloat(min - padding) : 0 }));

}

export const useTickValues = (data) => data.map(({ x }) => {
  return x
});

export const useLineData = (dataTuple, tickValues) => {

  return dataTuple[0] !== null || dataTuple[1] !== null || !isNaN(dataTuple[0]) || !isNaN(dataTuple[1])
    ? [
      { y: dataTuple[0], x: 1 },
      { y: dataTuple[1], x: tickValues.length },
    ]
    : null;
};

export const getWeeklyTarget = (goal, date, trendTarget, monthValues) => {
  const periodKey = `${date.getMonth()}${date.getFullYear()}`;
  const teamGoalGraphConfig = goal.teamGoalGraphConfigs.find((temaGoalGraphConfig) => {
    return periodKey === temaGoalGraphConfig.key
  });
  const target = Object.assign([], [teamGoalGraphConfig && (teamGoalGraphConfig.targetFrom || teamGoalGraphConfig.targetFrom === 0) ? teamGoalGraphConfig.targetFrom : trendTarget || trendTarget === 0 ? trendTarget : getCalculatedCurrentTarget([goal.numberFrom, goal.numberTo], date, monthValues, goal.dueDate), teamGoalGraphConfig && (teamGoalGraphConfig.targetTo || teamGoalGraphConfig.targetTo === 0) ? teamGoalGraphConfig.targetTo : trendTarget || trendTarget === 0 ? trendTarget : getCalculatedCurrentTarget([goal.numberFrom, goal.numberTo], date, monthValues, goal.dueDate)])

  return target;
}

export const useTargetData = (dataTuple, tickValues, goal, isTrend, monthValues, actualData, resultDate, trendTarget, key) => {

  if (isTrend || goal.frequency === "Monthly") {
    const month = getMonth(Date.parse(goal.dueDate));
    const monthIndex = monthValues.indexOf(month + 1)

    let data = []
    // let currentPointer = 1;
    if(dataTuple[0] !== null || dataTuple[1] !== null) {
      let climb;
      if(dataTuple[0] > dataTuple[1]) {
        climb = (dataTuple[0] - dataTuple[1]) / (monthIndex)
      } else if(dataTuple[0] < dataTuple[1]) {
        climb = (dataTuple[1] - dataTuple[0]) / (monthIndex)
      } else {
        climb = 0
      }

      actualData.map((metric, i) => {
        let y_Value
        if(metric[key]) {
          y_Value = metric[key]
        } else {
          if(i >= monthIndex) {
            y_Value = dataTuple[1]
          } else {
            if(dataTuple[0] > dataTuple[1]) {
              y_Value = dataTuple[0] - (climb * i)
            } else {
              y_Value = dataTuple[0] + (climb * i)
            }
          }
        }

        data.push({ y: metric[key] === 0 ? metric[key] : parseFloat(y_Value), x: tickValues[i] })

        return true
      })
    }

    return data;
  } else if(goal.frequency === "Weekly") {
    const prevDate = new Date(resultDate.getFullYear(), resultDate.getMonth() - 1, resultDate.getDate());
    const nextDate = new Date(resultDate.getFullYear(), resultDate.getMonth() + 1, resultDate.getDate());
    const prevWeeks = getISOWeeksInMonth(prevDate)
    const nextWeeks = getISOWeeksInMonth(nextDate)
    const prevTarget = getWeeklyTarget(goal, prevDate, trendTarget, monthValues)
    const nextTarget = getWeeklyTarget(goal, nextDate, trendTarget, monthValues)
    let data = [];
    // On weekly measures the first 2 weeks are from the previous month and the last 2 from the next month, so we need to get the actual
    // number of weeks for the month, not the amount being displayed.
    let actualLength = actualData.length - 4;
    if(dataTuple[0] !== null || dataTuple[1] !== null) {
      let climb;
      if(dataTuple[0] > dataTuple[1]) {
        climb = (dataTuple[0] - dataTuple[1]) / (actualLength - 1)
      } else if(dataTuple[0] < dataTuple[1]) {
        climb = (dataTuple[1] - dataTuple[0]) / (actualLength - 1)
      } else {
        climb = 0
      }
      actualData.map((metric, i) => {
        if(i !== 0 && i !== 1 && i !== (actualData.length - 1) && i !== (actualData.length - 2)) {
          let y_Value
          let index = i - 2;
          if(dataTuple[0] > dataTuple[1]) {
            y_Value = metric[key] ? metric[key] : dataTuple[0] - (climb * index)
          } else {
            y_Value = metric[key] ? metric[key] : dataTuple[0] + (climb * index)
          }
          data.push({ y: metric[key] === 0 ? metric[key] : parseFloat(y_Value), x: tickValues[i] })
        } else {
          let y_Value
          let index
          let isPrev
          if(i === 0) {
            index = prevWeeks.length - 2
            isPrev = true;
          } else if (i === 1) {
            index = prevWeeks.length - 1
            isPrev = true;
          } else if (i === (actualData.length - 1)) {
            index = 1;
            isPrev = false;
          } else if(i === (actualData.length - 2)) {
            index = 0;
            isPrev = false;
          }
          if(isPrev) {
            if(prevTarget[0] === null && prevTarget[1] === null) {
              data.push({ y: metric[key] === 0 ? metric[key] : null, x: tickValues[i] })
            } else {
              let prevClimb;
              if(prevTarget[0] > prevTarget[1]) {
                prevClimb = (prevTarget[0] - prevTarget[1]) / (prevWeeks.length - 1)
              } else if(prevTarget[0] < prevTarget[1]) {
                prevClimb = (prevTarget[1] - prevTarget[0]) / (prevWeeks.length - 1)
              } else {
                prevClimb = 0
              }

              if(prevTarget[0] > prevTarget[1]) {
                y_Value = metric[key] ? metric[key] : prevTarget[0] - (prevClimb * index)
              } else {
                y_Value = metric[key] ? metric[key] : prevTarget[0] + (prevClimb * index)
              }

              data.push({ y: metric[key] === 0 ? metric[key] : parseFloat(y_Value), x: tickValues[i] })
            }
          } else {
            if(nextTarget[0] === null && nextTarget[1] === null) {
              data.push({ y: metric[key] === 0 ? metric[key] : null, x: tickValues[i] })
            } else {
              let nextClimb;
              if(nextTarget[0] > nextTarget[1]) {
                nextClimb = (nextTarget[0] - nextTarget[1]) / (nextWeeks.length - 1)
              } else if(nextTarget[0] < nextTarget[1]) {
                nextClimb = (nextTarget[1] - nextTarget[0]) / (nextWeeks.length - 1)
              } else {
                nextClimb = 0
              }

              if(nextTarget[0] > nextTarget[1]) {
                y_Value = metric[key] ? metric[key] : nextTarget[0] - (nextClimb * index)
              } else {
                y_Value = metric[key] ? metric[key] : nextTarget[0] + (nextClimb * index)
              }

              data.push({ y: metric[key] === 0 ? metric[key] : parseFloat(y_Value), x: tickValues[i] })
            }
          }

        }

        return true
      })
    } else {
      data = null
    }

      return data;
  } else {
    let data = [];
    if(dataTuple[0] !== null || dataTuple[1] !== null) {
      let climb;
      if(dataTuple[0] > dataTuple[1]) {
        climb = (dataTuple[0] - dataTuple[1]) / (actualData.length - 1)
      } else if(dataTuple[0] < dataTuple[1]) {
        climb = (dataTuple[1] - dataTuple[0]) / (actualData.length - 1)
      } else {
        climb = 0
      }
      actualData.map((metric, i) => {
        let y_Value
        if(dataTuple[0] > dataTuple[1]) {
          y_Value = metric[key] ? metric[key] : dataTuple[0] - (climb * i)
        } else {
          y_Value = metric[key] ? metric[key] : dataTuple[0] + (climb * i)
        }
        data.push({ y: metric[key] === 0 ? metric[key] : parseFloat(y_Value), x: tickValues[i] })

        return true
      })
    } else {
      data = null
    }

    return data;
  }
};
