import { getDayOfYear } from 'date-fns';
import moment from 'moment';

export const getDay = (date) => {
  return getDayOfYear(new Date(date));
}

export const getDate = (day) => {
  const date = new Date(2021, 0, day);
  return moment(date).format('DD MMM');
}

export const getDateForPicker = (day) => {
  const date = new Date(2021, 0, day);
  return moment(date).toDate()
}

export const getCurrentDate = () => {
  const date = new Date()
  return moment(date).format('DD MMM');
}

export const getWeekNumber = (date) => {
  var d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  var dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
  return Math.ceil((((d - yearStart) / 86400000) + 1)/7);
}

export const getDateFromWeek = (week) => {
    var simple = new Date(new Date().getFullYear(), 0, 1 + (week - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4)
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
}

export const getCalculatedDisplayWeek = (week, startWeek) => {
  if(!startWeek) return week

  if(week < startWeek) {
    return 53 - (startWeek - week) + 1
  } else {
    return week - startWeek + 1
  }
}
