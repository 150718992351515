import React, { Component, Fragment } from "react"
import { Table, Tooltip, OverlayTrigger, Row, Col, ButtonGroup, ToggleButton, Button } from 'react-bootstrap'
import {faSpinner, faPlus, faChevronUp, faChevronDown, faBars} from "@fortawesome/free-solid-svg-icons";
import BackWhite from "../assets/back-white.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { connect } from "react-redux";
import { updateInnovationStatus, updateApprovalStatus, archiveInnovation, createIssue, createAction, updateIssue, getInnovationsPG } from '../services/InnovationsService';
import displayErrorToast from "../components/utils/displayErrorToast";
import SubHeaderComponent from "../components/SubHeaderComponent";
import RealtimeRefreshComponent from "../components/RealtimeRefreshComponent";
import { toast } from "react-toastify";
import * as DefaultLabels from '../assets/glossary.json';
import LabelComponent from "../components/utils/getCompanyLabel";
import Pagination from "../components/shared/Pagination";

// icons
import Archive from '../assets/ButtonIcons/Archive Blue.svg';
import Cancel from '../assets/ButtonIcons/Cancel Blue.svg';
import Delete from '../assets/ButtonIcons/Delete Blue.svg';
import Edit from '../assets/ButtonIcons/Edit Blue.svg';
import Loader from '../assets/ButtonIcons/Loader Blue.svg';
import PutBack from '../assets/ButtonIcons/Put Back Blue.svg';
import Save from '../assets/ButtonIcons/Save Blue.svg';
import {getDownloadInnovationsData} from "../utils/exportUtils";
import { deleteIssue } from "../services/CustomerSupplierService";
import NextStep from "../components/shared/NextStep";

const labels = DefaultLabels.default;
const getLabelText = key => <LabelComponent val={key}/>

function renderTooltip(props, text) {
  return (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );
}

class ImprovementIdeasScreen extends Component {

  presentation
  state = {
    innovations: [],
    loading: false,
    loadingId: "",
    loadingType: "",
    loadingKey: "",
    whoId: "",
    date: "",
    text: "",
    editable: false,
    innovationId: "",
    archived: false,
    users: [],
    type: "",
    statuses: [
      "NONE",
      "PLANNED",
      "IMPLEMENTED",
      "ASSESSED",
      "STANDARDISED"
    ],
    meta: {},
    page: 1,
    sortField: null,
    sortDirection: null,
    fetching: false
  }

  constructor(props) {
    super(props)

    this.props.togglePageLoad(true)
  }

  async componentDidMount() {
    if (this.props.selectedTeam.id !== 0) {
      await this.fetchInnovations()
      let users = [...this.props.selectedTeam.members, ...this.props.selectedTeam.leaders]

      this.setState({
        users: users.sort((a, b) => a.firstNames.localeCompare(b.firstNames))
      })
      this.props.togglePageLoad(false)
    }
  }


  async componentDidUpdate(prevProps) {
    if (prevProps.selectedTeam.id !== this.props.selectedTeam.id) {
      this.props.togglePageLoad(true)
      await this.fetchInnovations()
      let users = [...this.props.selectedTeam.members, ...this.props.selectedTeam.leaders]

      this.setState({
        users: users.sort((a, b) => a.firstNames.localeCompare(b.firstNames))
      })
      this.props.togglePageLoad(false)
    }

    if (this.props.refresh && prevProps.refresh !== this.props.refresh) {
      this.props.togglePageLoad(true)
      await this.fetchInnovations()
      let users = [...this.props.selectedTeam.members, ...this.props.selectedTeam.leaders]

      this.setState({
        users: users.sort((a, b) => a.firstNames.localeCompare(b.firstNames))
      })
      this.props.toggleRefresh()
      this.props.toggleHasNewData()
      this.props.togglePageLoad(false)
    }
  }

  setPage = async (page) => {
    await this.fetchInnovations(this.state.archived, page, false, this.state.sortField, this.state.sortDirection);
    this.setState({
      page: page 
    })
  }

  async fetchInnovations(archived = this.state.archived, page = this.state.page, download = false, sortField = this.state.sortField, sortDirection = this.state.sortDirection) {
    this.setState({
      fetching: true
    });

    let res = await getInnovationsPG(this.props.selectedTeam.id, archived, page, download, sortField, sortDirection);
    res = JSON.parse(res.data.getInnovations);

    if(download === true)
    {
      getDownloadInnovationsData(res.data[0].loggedIssues);
    }
    else
    {
      this.setState({
        innovations: res.data.length ? res.data[0].loggedIssues : [],
        meta: res.meta,
        fetching: false
      })
    }
  }

  getText = val => {
    const { user } = this.props;

    const companyId = localStorage.getItem("companyId");
    let lang = "EN";
    let langLabels = labels.find(lbl => lbl.lang === lang);
    let def = langLabels.glossary.find(label => label.key === val);

    if(user && user.companies && user.companies.length) {
      const company = user.companies.find(company => company.id === companyId);
      if(company) {
        lang = company.language;
        langLabels = labels.find(lbl => lbl.lang === lang);
        def = langLabels.glossary.find(label => label.key === val);
        if(!def) {
          lang = "EN";
          langLabels = labels.find(lbl => lbl.lang === lang);
          def = langLabels.glossary.find(label => label.key === val);
        }
      }
      if(company && company.companyGlossaries) {
        const custom = company.companyGlossaries.find(label => label.key === val);

        if(custom) return custom.value
      }
    }

    if(!def) {
      console.log(val)
    }
    return def.value
  }

  renderStatusToggle(innovation) {
    let buttons = []
    for (let i = 0; i < 4; i++) {
      buttons.push(this.getStatusToggleButton(i, innovation))
    }

    return buttons
  }

  getStatusToggleButton(i, innovation) {
    const letters = [
      "P",
      "D",
      "C",
      "A"
    ]

    const { statuses, loading, loadingType, loadingKey, loadingId } = this.state
    if(loading && loadingType === "status" && loadingId === innovation.id && loadingKey === i) {
      return (<span className={"cdi-blue-txt d-inline-block chk chk-white"}  key={i}>
                <div>
                  <FontAwesomeIcon icon={faSpinner} className="fa-spin"></FontAwesomeIcon>
                </div>
            </span>)
    }

    return (<span className={"cdi-blue-txt d-inline-block chk-lg chk chk-" + ((i + 1) <= statuses.indexOf(innovation.innovationStatus) ? "blue color-selected text-white" : "white")} onClick={() => this.setStatusToggle(i, innovation)} key={i}>
      <div style={{marginTop: "-4px", marginLeft: "4px", cursor: 'pointer'}}>
        {letters[i]}
      </div>
    </span>)
  }

  async setStatusToggle(key, {id, innovationStatus}) {
    const { statuses } = this.state
    let status

    if(statuses[key + 1] === innovationStatus) {
      status = statuses[key]
    } else {
      if(statuses.indexOf(innovationStatus) >> key) {
        status = statuses[key]
      } else {
        status = statuses[key + 1]
      }
    }

    this.setLoading(id, "status", key)

    try {
      await updateInnovationStatus(id, status)
      await this.fetchInnovations()
      this.cancelLoading()
    } catch(err) {
      this.cancelLoading()
      displayErrorToast(err)
    }
  }

  getInnovationApproval(innovation) {
    const { loading, loadingId, loadingType } = this.state

    if(loading && loadingType === "approved" && loadingId === innovation.id) {
      return (<span className="status chk chk-white"><FontAwesomeIcon className="fa-spin ml-3 mt-2 mr-3 mb-2 " icon={faSpinner}></FontAwesomeIcon></span>)
    }
    if(innovation.innovationApproved === "NONE") {
      return (<span className="status chk chk-white" onClick={() => this.toggleApproved(innovation.innovationApproved, innovation.id)}></span>)
    } else {
        return <span className={"status chk chk-" + (innovation.innovationApproved === "APPROVED" ? "blue color-selected" : "red")} onClick={() => this.toggleApproved(innovation.innovationApproved, innovation.id)}></span>
    }

  }

  async toggleApproved(approved, id) {
    this.setLoading(id, "approved")

    let status = "NONE"

    if(approved === "NONE") {
      status = "APPROVED"
    } else if (approved === "APPROVED") {
      status = "DECLINED"
    }

    try {
      await updateApprovalStatus(id, status)
      await this.fetchInnovations()
      this.cancelLoading()
    } catch(err) {
      this.cancelLoading()
      displayErrorToast(err)
    }


  }

  setLoading(id, type, key = "") {
    this.setState({
      loading: true,
      loadingId: id,
      loadingType: type,
      loadingKey: key
    })
  }

  cancelLoading() {
    this.setState({
      loading: false,
      loadingId: "",
      loadingType: ""
    })
  }

  setEdit(issue) {
    this.setState({
      editable: true,
      innovationId: issue.id,
      whoId: issue.suggestedBy ? issue.suggestedBy.id : 0,
      date: issue.reviewDate ? new Date(issue.reviewDate) : new Date(),
      text: issue.whatText
    })
  }

  cancel = () => {
    const { type, innovations } = this.state
    if(type === "create") {
      innovations.shift()
    }
    this.setState({
      editable: false,
      innovationId: "",
      whoId: "",
      date: "",
      text: "",
      type: "",
      innovations
    })
  }

  create = () => {
    const { innovations } = this.state
    const generatedId = Math.ceil(Math.random() * 100)

    innovations.unshift({
      id: generatedId,
      reviewDate: "",
      whatText: "",
      whyText: "",
      archived: false,
      innovationArchived: false,
      innovationStatus: "NONE",
      innovationApproved: "NONE",
      actions: []
    })

    this.setState({
      editable: true,
      innovationId: generatedId,
      date: new Date(),
      innovations,
      type: "create"
    })
  }

  async archive(id, archived) {
    this.setLoading(id, "archive")
    try {
      await archiveInnovation(id, !archived)
      await this.fetchInnovations()
      this.cancelLoading()
    } catch (err) {
      displayErrorToast(err)
      this.cancelLoading()
    }
  }

  async nextSteps(id) {
    this.setLoading(id, "nextSteps")
    try {
      await createAction(id, "INNOVATION", "NEXT_STEP")
      await this.fetchInnovations()
      this.cancelLoading()
    } catch (err) {
      displayErrorToast(err)
      this.cancelLoading()
    }
  }

  async toggleArchived () {
    await this.fetchInnovations(!this.state.archived, 1);
    this.setState({
      archived: !this.state.archived,
      page: 1
    })
  }

  handleDateChange = date => {
    this.setState({
      date
    })
  }

  validateInput = () => {
    const { whoId, text} = this.state
    let valid = true

    if(whoId === "") {
      valid = false
    }

    if(text === "") {
      valid = false
    }

    return valid
  }

  async save(issue) {
    const {type, whoId, date, text} = this.state
    if(this.validateInput()) {
      this.setLoading(issue.id, "save")
      try {
        if(type === "create") {
          await createIssue(this.props.selectedTeam.id, text.replaceAll(/"/g, '\\"'), "", date.toISOString(), whoId)
        } else {
          await updateIssue(issue.id, text.replaceAll(/"/g, '\\"'), "", date.toISOString(), whoId)
        }
        await this.fetchInnovations()
        this.cancelLoading()
        this.setState({
          editable: false,
          innovationId: "",
          whoId: "",
          date: "",
          text: "",
          type: ""
        })
      } catch (err) {
        displayErrorToast(err)
        this.cancelLoading()
      }
    }
  }

  async delete (innovation) {
    let is_delete = true;
    innovation.actions.forEach(ele => {
      if(ele.status !== 'COMPLETED') {
        is_delete = false;
      }
    });
    if(is_delete) {
      if (window.confirm(this.getText("delete_department_prompt"))) {
        try {
          this.setLoading(innovation.id, "delete")
          await deleteIssue(innovation.id)
          await this.fetchInnovations()
        } catch (error) {
          error.graphQLErrors.map(error => {
            toast.error(error.message)
            return true
          })
        } finally {
          this.cancelLoading()
        }
      }
    } else {
      toast.error(getLabelText("delete_inno_error"))
    }
  }

  formattedDate(date) {
    let month = date.getMonth() + 1
    let day = date.getDate()

    if(month.toString().length < 2)
      month = "0" + month.toString()

    if(day.toString().length < 2)
      day = "0" + day.toString()

    return day + "/" + month + "/" + date.getFullYear()
  }

  getNextStepsButton(issue) {
    const {loading, loadingType, loadingId} = this.state;

    let status = '';
    const nextStepCount = issue.actions.filter(action => action.status === "NEXT_STEP" || action.status === "TRIGGERED").length
    const completedCount = issue.actions.filter(action => action.status === "COMPLETED").length
    const totalNextSteps = issue.actions.filter(action => action.status !== "NONE").length

    if(issue.innovationStatus !== "STANDARDISED" && nextStepCount > 0 && completedCount !== totalNextSteps)
    {
      status = 'NEXT_STEP';
    }
    else if(issue.innovationStatus !== "STANDARDISED" && totalNextSteps > 0 && completedCount === totalNextSteps)
    {
      status = 'COMPLETED';
    }
    else if(issue.innovationStatus !== "STANDARDISED" && (completedCount !== totalNextSteps || totalNextSteps === 0))
    {
      status = 'NONE';
    }

    return (
        <NextStep
            loading={loading}
            loadingType={loadingType}
            loadingId={loadingId}
            editType={null}
            issue={issue}
            action={issue?.actions?.length ? issue?.actions[0] : {}}
            actionId={null}
            status={status}
            nextSteps={() => this.nextSteps(issue.id, "NEXT_STEP")}
        />
    )
  }

  handleSorting = async (field, direction) => {
    if(field !== 'approved') {
      direction = direction % 2;
    } else {
      direction = direction % 3;
    }
    this.setState({
      sortField: field,
      sortDirection: direction,
      page: 1
    })
    await this.fetchInnovations(this.state.archived, 1, false, field, direction);
  }

  renderRow(issue, i) {
    const { editable, innovationId, date, whoId, users, text } = this.state

    if(editable && innovationId === issue.id) {
      return (
        <tr key={i}>
          <td>
            <DatePicker
              name="date"
              className="form-control"
              required={true}
              dateFormat="yyyy/MM/dd"
              showYearDropdown
              selected={date}
              onChange={this.handleDateChange}
              tabIndex="0"
              autoComplete="off"
            />
          </td>
          <td>
            <div>
              <input
                tabIndex="1"
                type="text"
                className="form-control"
                value={text}
                onChange={(e) => this.setState({text: e.target.value})}
                autoComplete="off"
              ></input>
            </div>
          </td>
          <td>
            <select className="form-control cdi-ddl w-100 ml-0"
              name="who"
              value={whoId}
              required={true}
              tabIndex="2"
              onChange={(e) => this.setState({ whoId: e.target.value})}
              autoComplete="off"
            >
              <option value="" disabled>{this.getText("select_member")}:</option>
              {users.map((user, index) => {
                return <option value={user.id} key={index}>{user.firstNames + " " + user.lastName}</option>
              })}
            </select>
          </td>
          <td className="disabled">
              {this.getInnovationApproval(issue)}
          </td>
          <td className="disabled">
            {this.renderStatusToggle(issue)}
          </td>
          <td className="disabled">
            {this.getNextStepsButton(issue)}
          </td>
          <td>
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, getLabelText("save"))}
            >
              <button className={"btn-icons-container" + (!this.validateInput() ? " disabled" : "")} type="submit" onClick={() => this.save(issue)}>
                {this.state.loading && this.state.loadingType === "save" && this.state.loadingId === issue.id
                  ? (
                    <img src={Loader} alt="Loader" className="loader-spinner"/>
                  ) : (
                    <img src={Save} alt="Save" className="btn-icons"/>
                  )
                }
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, getLabelText("cancel"))}
            >
              <button className="btn-icons-container" type="submit" onClick={this.cancel}>
                <img src={Cancel} alt="Cancel" className="btn-icons"/>
              </button>
            </OverlayTrigger>
          </td>
        </tr>
      )
    } else {
      return (
        <tr key={i}>
          <td>{this.formattedDate(new Date(issue.reviewDate))}</td>
          <td>{issue.whatText}</td>
          <td>{issue.suggestedBy ? issue.suggestedBy.firstNames + " " + issue.suggestedBy.lastName : getLabelText("none_assigned")}</td>
          <td className={this.state.archived || this.state.editable ? "disabled" : ""}>
              {this.getInnovationApproval(issue)}
          </td>
          <td className={this.state.archived || this.state.editable ? "disabled" : ""}>
            {this.renderStatusToggle(issue)}
          </td>
          <td className={this.state.archived || this.state.editable ? "disabled" : ""}>
            {this.getNextStepsButton(issue)}
          </td>
          <td>
            {!this.state.archived && (
              <OverlayTrigger
                placement="top"
                delay={{ show: 25, hide: 40 }}
                overlay={(props) => renderTooltip({...props}, getLabelText("edit"))}
              >
                <button className={"btn-icons-container" + (this.state.editable ? " disabled" : "")} disabled={this.state.archived} type="submit" onClick={() => this.setEdit(issue)}>
                  <img src={Edit} alt="Edit" className="btn-icons"/>
                </button>
              </OverlayTrigger>
            )}
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, issue.innovationArchived ? getLabelText("put_back") : getLabelText("archive"))}
            >
              <button className={"btn-icons-container" + (this.state.editable ? " disabled" : "")} type="submit" onClick={() => this.archive(issue.id, issue.innovationArchived)}>
                {this.state.loading && this.state.loadingType === "archive" && this.state.loadingId === issue.id
                  ? (
                    <img src={Loader} alt="Loader" className="loader-spinner"/>
                  ) : issue.innovationArchived
                    ? (
                      <img src={PutBack} alt="PutBack" className="btn-icons"/>
                    ) : (
                      <img src={Archive} alt="Archive" className="btn-icons"/>
                    )
                }
              </button>
            </OverlayTrigger>
            {this.state.archived && (
              <OverlayTrigger
                placement="top"
                delay={{ show: 25, hide: 40 }}
                overlay={(props) => renderTooltip({...props}, getLabelText("delete"))}
              >
                <button className="btn-icons-container" type="submit" onClick={() => this.delete(issue)}>
                  {this.state.loading && this.state.loadingType === "delete" && this.state.loadingId === issue.id
                    ? (
                      <img src={Loader} alt="Loader" className="loader-spinner"/>
                    ) : (
                      <img src={Delete} alt="Delete" className="btn-icons"/>
                    )
                  }
                </button>
              </OverlayTrigger>
            )}
          </td>
        </tr>
      )
    }
  }
  render () {
    return (
      <Fragment>
        <SubHeaderComponent>
          <Col className="text-right childenSpace">
            <div className={"refreshBtn"}>
              <RealtimeRefreshComponent
                refresh={this.props.refresh}
                hasNewData={this.props.hasNewData}
                toggleRefresh={this.props.toggleRefresh}
              />
            </div>
            <button className="btn btn-primary mr-2 ml-2 backBtn-container" onClick={() => this.props.history.goBack()}>
              <img src={BackWhite} alt="Back" className="backBtn" />
            </button>
            {!this.state.archived && (
              <Button className={"btn btn-primary" + (this.state.editable ? " disabled" : "")} onClick={() => this.create()}>{getLabelText("add")}<FontAwesomeIcon className="ml-2" icon={faPlus} /></Button>
            )}
          </Col>
        </SubHeaderComponent>
        <div className="grey-header-space pb-4">
          <Row className="mb-3">
            <Col className="text-right pr-0">
              <ButtonGroup toggle className="ml-1">
                <ToggleButton
                    type="radio"
                    variant="secondary"
                    name="createType"
                    value="0"
                    checked={!this.state.archived}
                    onChange={() => this.toggleArchived()}
                >
                  {getLabelText("active")}
                </ToggleButton>
                <ToggleButton
                    type="radio"
                    variant="secondary"
                    name="createType"
                    value="1"
                    checked={this.state.archived}
                    onChange={() => this.toggleArchived()}
                >
                  {getLabelText("archived")}
                </ToggleButton>
                <Button className={"btn btn-secondary"} onClick={() => this.fetchInnovations(this.state.archived, null, true)}>{getLabelText("export")}</Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row>
            <Col className="pr-0">
              <Table striped bordered hover className="standard-tbl standard-improvement-ideas-tbl mt-1 table-heading-background user-text">
                <thead>
                <tr>
                  <th
                      width="10%"
                      onClick={() => this.handleSorting("date", this.state.sortDirection + 1)}
                  >
                    {getLabelText("date")}
                    {this.state.sortField === 'date' && this.state.sortDirection === 0 && <FontAwesomeIcon className="ml-2" icon={faChevronUp} />}
                    {this.state.sortField === 'date' && this.state.sortDirection === 1 && <FontAwesomeIcon className="ml-2" icon={faChevronDown} />}
                  </th>
                  <th
                      width="25%"
                      onClick={() => this.handleSorting("innovation_impr_idea", this.state.sortDirection + 1)}
                  >
                    {getLabelText("innovation_impr_idea")}
                    {this.state.sortField === 'innovation_impr_idea' && this.state.sortDirection === 0 && <FontAwesomeIcon className="ml-2" icon={faChevronUp} />}
                    {this.state.sortField === 'innovation_impr_idea' && this.state.sortDirection === 1 && <FontAwesomeIcon className="ml-2" icon={faChevronDown} />}
                  </th>
                  <th
                      width="15%"
                      onClick={() => this.handleSorting("suggested_by", this.state.sortDirection + 1)}
                  >
                    {getLabelText("suggested_by")}
                    {this.state.sortField === 'suggested_by' && this.state.sortDirection === 0 && <FontAwesomeIcon className="ml-2" icon={faChevronUp} />}
                    {this.state.sortField === 'suggested_by' && this.state.sortDirection === 1 && <FontAwesomeIcon className="ml-2" icon={faChevronDown} />}
                  </th>
                  <th
                      width="8%"
                      onClick={() => this.handleSorting("approved", this.state.sortDirection + 1)}
                  >
                    {getLabelText("approved")}
                    {this.state.sortField === 'approved' && this.state.sortDirection === 0 && <FontAwesomeIcon className="ml-2" icon={faChevronUp} />}
                    {this.state.sortField === 'approved' && this.state.sortDirection === 1 && <FontAwesomeIcon className="ml-2" icon={faBars} />}
                    {this.state.sortField === 'approved' && this.state.sortDirection === 2 && <FontAwesomeIcon className="ml-2" icon={faChevronDown} />}
                  </th>
                  <th
                      width="12%"
                      onClick={() => this.handleSorting("status", this.state.sortDirection + 1)}
                  >
                    {getLabelText("status")}
                    {this.state.sortField === 'status' && this.state.sortDirection === 0 && <FontAwesomeIcon className="ml-2" icon={faChevronUp} />}
                    {this.state.sortField === 'status' && this.state.sortDirection === 1 && <FontAwesomeIcon className="ml-2" icon={faChevronDown} />}
                  </th>
                  <th width="5%"></th>
                  <th width="10%"></th>
                </tr>
                </thead>
                {
                  !this.state.fetching ? (
                      <tbody>
                        {this.state.innovations.map((issue, k) => {
                          if(issue.innovationArchived === this.state.archived) {
                            return this.renderRow(issue, k)
                          }
                          return null
                        })}
                      </tbody>
                  ) : (
                      <tbody>
                        <tr>
                          <td colSpan={7} rowSpan={5} className="text-center py-5 my-5 bg-white">
                            <FontAwesomeIcon fontSize={36} className="fa-spin cdi-blue" icon={faSpinner} />
                          </td>
                        </tr>
                      </tbody>
                  )
                }
              </Table>
            </Col>
          </Row>
          {Object.keys(this.state.meta).length > 0 && (
              <Row className="pb-5">
                <Pagination current={this.state.page} totalPages={this.state.meta.totalPages} setPage={this.setPage} />
              </Row>
          )}
        <div
          style={{ bottom: this.props.presentation.active ? "116px" : "58px" }}
          className="bg-light-grey d-flex p-2 justify-content-around fixed-bottom legend"
        >
          <div>
            <span className="font-weight-bold mr-2 color-blue">
              <span className={"cdi-blue-txt d-inline-block chk chk-lg chk-blue color-selected text-white"}>
                <div style={{marginTop: "-4px", marginLeft: "4px", padding: "1px 0px 0px 0px"}}>
                  P
                </div>
              </span>
            </span>
              {getLabelText("implementation_planned")}
            </div>
            <div>
            <span className="font-weight-bold mr-2 color-blue">
              <span className={"cdi-blue-txt d-inline-block chk chk-lg chk-blue color-selected text-white"}>
                <div style={{marginTop: "-4px", marginLeft: "4px", padding: "1px 0px 0px 0px"}}>
                  D
                </div>
              </span>
            </span>
              {getLabelText("plan_implemented")}
            </div>
            <div>
            <span className="font-weight-bold mr-2 color-blue">
              <span className={"cdi-blue-txt d-inline-block chk chk-lg chk-blue color-selected text-white"}>
                <div style={{marginTop: "-4px", marginLeft: "4px", padding: "1px 0px 0px 0px"}}>
                  C
                </div>
              </span>
            </span>
              {getLabelText("outcome_assessed")}
            </div>
            <div>
            <span className="font-weight-bold mr-2 color-blue">
              <span className={"cdi-blue-txt d-inline-block chk chk-lg chk-blue color-selected text-white"}>
                <div style={{marginTop: "-4px", marginLeft: "4px", padding: "1px 0px 0px 0px"}}>
                  A
                </div>
              </span>
            </span>
              {getLabelText("method_standardised")}
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam,
  presentation: state.presentationReducer,
  user: state.userReducer.user
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ImprovementIdeasScreen);
