import * as userTypes from '../types/UserTypes';

const initialState = {
  user: {
    id: 0
  },
  isLoading: true,
  error: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userTypes.FETCH_USER_STARTED:
      return { ...state, error: null, isLoading: true };
    case userTypes.FETCH_USER_SUCCESS:
      return { ...state, ...action.payload, isLoading: false, error: null };
    case userTypes.FETCH_USER_FAILED:
      return { ...state, error: action.payload.error, isLoading: false, };
    case userTypes.UPDATE_COMPANY_PAGE_PERMISSIONS:
      const companyId = localStorage.getItem("companyId");
      return {...state, user: {
        ...state.user,
        companies: state.user.companies.map(comp => {
          return comp.id === companyId ? {
            ...comp,
            [action.payload.name]: action.payload.value
          } : comp
        })
      }}
    default:
      return state;
  }
};

export default userReducer;
