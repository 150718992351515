import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-modal';
import IconButton from "../../../components/shared/IconButton";
// icons
import Delete from '../../../assets/ButtonIcons/Delete Blue.svg';
import Loader from '../../../assets/ButtonIcons/Loader Blue.svg';


const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '30%'
  }
};

const SuccessModal = ({ selectedSuccess, users, save, cancel, loading, setLoading, showModal, deleting, deleteItem, getText  }) => {
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    if(!selectedSuccess) return;
    setSelectedUser(selectedSuccess.user.id)
  }, [selectedSuccess, setSelectedUser])

  const saveData = async() => {
    setLoading(true)
    save(selectedUser)
  }

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => cancel()}
      contentLabel=""
      style={customStyles}
    >
      <div className="row">
        <div className="col-6 text-left">
          <label className="cdi-blue-txt ml-0 mb-2">{getText("select_member")}</label>
        </div>
        <div className="col-6 text-right">
          <IconButton tooltip={getText("delete")} eventHandler={() => deleteItem(selectedSuccess.id)} classNames={deleting ? "disabled" : ""}>
            {deleting ? (
              <img src={Loader} alt="Loader" className="loader-spinner"/>
            ) : (
              <img src={Delete} alt="Delete" className="btn-icons"/>
            )}
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <select className="form-control cdi-ddl w-100 ml-0"
            name="user"
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
            autoComplete="off"
          >
            <option value="1" disabled>{getText("select_member")}</option>
            {users.map((user, i) => {
              return <option value={user.id} key={i}>{user.firstNames} {user.lastName}</option>
            })}
          </select>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-6">
          <button className="btn btn-primary w-100" disabled={loading} onClick={() => saveData()}>
            {loading
              ? (
                <>{getText("saving")} <FontAwesomeIcon icon={faSpinner} className="fa-spin ml-2"></FontAwesomeIcon></>
              ) : (
                <>{getText("save")}</>
              )
            }
          </button>
        </div>
        <div className="col-6">
          <button className="btn btn-default cdi-blue-outline color-blue w-100" onClick={() => cancel()}>{getText("cancel")}</button>
        </div>
      </div>
    </Modal>
  )
}

export default SuccessModal
