import React, { useEffect, useState, Fragment  } from "react"
import {Row, Col, Table, Button} from "react-bootstrap";
import { connect } from "react-redux";
import Modal from 'react-modal';

import { useMutation, useQuery } from "@apollo/react-hooks";
import displayErrorToast from "../../components/utils/displayErrorToast";
import { mapModuleData } from "./mapModuleData"
import SubHeaderComponent from "../../components/SubHeaderComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import LabelComponent from "../../components/utils/getCompanyLabel";
import {
    IMPLEMENTATION_MODULES_QUERY,
    CREATE_IMPLEMENTATION_MODULE_MUTATION,
    UPDATE_IMPLEMENTATION_MODULE_MUTATION,
    CREATE_IMPLEMENTATION_MILESTONE_MUTATION,
    UPDATE_IMPLEMENTATION_MILESTONE_MUTATION
} from "./gql";
import ModuleRow from "./ModuleRow";

const getLabelText = (key) => <LabelComponent val={key}/>

const ImplementationModuleScreen = ({ togglePageLoad, user, ...props }) => {
    const { loading, data } = useQuery(IMPLEMENTATION_MODULES_QUERY)
    const [modules, setModules] = useState([])
    const [isCreatingNewItem, setIsCreatingNewItem] = useState(false);
    const [itemBeingEdited, setItemBeingEdited] = useState("");
    const [moduleId, setModuleId] = useState(null)
    const [moduleName, setModuleName] = useState("");
    const [openModuleModal, setOpenModuleModal] = useState(false);

    const reset = () => {
        setIsCreatingNewItem(false)
        setItemBeingEdited("")
        setModuleId(null)
        setModuleName("")
        setOpenModuleModal(false)
        props.toggleDirtyState(true)
    };

    const onError = (error) => {
        displayErrorToast(error);
        reset();
    };

    const [createImplementationMilestone] = useMutation(CREATE_IMPLEMENTATION_MILESTONE_MUTATION, {
        onError,
        onCompleted: ({ createImplementationMilestone }) => {
            setModules(mapModuleData(modules, moduleId, itemBeingEdited, "CREATE", createImplementationMilestone));
            reset();
        },
    });

    const [updateImplementationMilestone] = useMutation(UPDATE_IMPLEMENTATION_MILESTONE_MUTATION, {
        onError,
        onCompleted: ({ updateImplementationMilestone }) => {
            setModules(mapModuleData(modules, moduleId, itemBeingEdited, "UPDATE", updateImplementationMilestone));
            reset();
        },
    });

    const [deleteImplementationMilestone] = useMutation(UPDATE_IMPLEMENTATION_MILESTONE_MUTATION, {
        onError,
        onCompleted: ({ updateImplementationMilestone }) => {
            setModules(mapModuleData(modules, moduleId, updateImplementationMilestone.id, "DELETE"));
            reset();
        },
    });

    const onOpenCreate = (id) => {
        setIsCreatingNewItem(true);
        setModuleId(id)
        props.toggleDirtyState()
        setItemBeingEdited("");
    };

    const onOpenEdit = (id, moduleId) => {
        setIsCreatingNewItem(false);
        setModuleId(moduleId)
        props.toggleDirtyState()
        setItemBeingEdited(id);
    };

    const onDelete = (id, name, moduleId) => {
        setModuleId(moduleId);
        deleteImplementationMilestone({ variables: { id: id, name: name, deleted: true } });
    };

    const onSave = ({ id, name, isDelete = false }) => {
        if(isDelete) {
            return updateImplementationMilestone({ variables: { id: id, name: name, deleted: true } })
        } else {
            if (id) {
                return updateImplementationMilestone({ variables: { id: id, name: name, deleted: false } })
            } else {
                return createImplementationMilestone({ variables: { name: name, implementationModule: moduleId } })
            }
        }
    };

    const editImplementationModule = (id, name) => {
        setModuleId(id);
        setModuleName(name);
        setOpenModuleModal(true);
    }

    const [createImplementationModule] = useMutation(CREATE_IMPLEMENTATION_MODULE_MUTATION, {
        onError,
        onCompleted: ({ createImplementationModule }) => {
            setModules([{...createImplementationModule, milestones: []}, ...modules]);
            reset();
        },
    });

    const [updateImplementationModule] = useMutation(UPDATE_IMPLEMENTATION_MODULE_MUTATION, {
        onError,
        onCompleted: ({ updateImplementationModule }) => {
            const nextModules = modules.map((e) =>
                e.id === updateImplementationModule.id ? {...updateImplementationModule, milestones: []} : e
            );
            setModules(nextModules);
            reset();
        },
    });

    const [deleteImplementationModule] = useMutation(UPDATE_IMPLEMENTATION_MODULE_MUTATION, {
        onError,
        onCompleted: ({ updateImplementationModule }) => {
            const nextModules = modules.filter((e) => e.id !== updateImplementationModule.id);
            setModules(nextModules);
            reset();
        },
    });

    const saveImplementationModule = (id, name) => {
        if(id && name)
        {
            deleteImplementationModule({
                variables: {
                    id: id,
                    name: name,
                    deleted: true
                }
            })
        }
        else
        {
            if(!moduleId) {
                createImplementationModule({
                    variables: {
                        name: moduleName
                    }
                })
            } else {
                updateImplementationModule({
                    variables: {
                        id: moduleId,
                        name: moduleName,
                        deleted: false
                    }
                })
            }
        }
    }

    useEffect(() => {
        if(data && data.implementationModules)
        {
            setModules(data.implementationModules)
        }
        togglePageLoad(loading)
    }, [loading, data, togglePageLoad])

    return (
        <Fragment>
            <SubHeaderComponent>
                <Row>
                    <Button className={"btn btn-primary btn-nrml ml-2"} onClick={() => setOpenModuleModal(true)}>{getLabelText("add")}<FontAwesomeIcon className="ml-2" icon={faPlus} /></Button>
                </Row>
            </SubHeaderComponent>
            <div className="standardise-space-between-grey-header-and-content standardise-space-left-and-left-align-logo-page-title-and-content">
                <Row>
                    <Col>
                        <Table bordered className="table-heading-background bordered-minus-1">
                            <tbody>
                            {modules.map((module) => (
                                    <ModuleRow
                                        key={module.id}
                                        module={module}
                                        create={onOpenCreate}
                                        edit={onOpenEdit}
                                        save={onSave}
                                        onDelete={onDelete}
                                        itemId={itemBeingEdited}
                                        moduleId={moduleId}
                                        isCreatingNewItem={isCreatingNewItem}
                                        reset={reset}
                                        editModule={editImplementationModule}
                                        onDeleteModule={saveImplementationModule}
                                    />
                                )
                            )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>

            <Modal
                isOpen={openModuleModal}
                onRequestClose={() => reset()}
                contentLabel="Result"
                className="standard-modal standard-modal-modules"
            >
                <div className="d-flex mb-2">
                    <div className="col-12 text-center">
                        <h6 className="cdi-blue-txt ml-0 mb-1">{ getLabelText(moduleId ? "edit" : "add") } { getLabelText("implementation_module") }</h6>
                    </div>
                </div>
                <Row className="mt-3">
                    <Col lg={12}>
                        <input className="form-control" value={moduleName} onChange={(e) => setModuleName(e.target.value)} />
                    </Col>
                </Row>
                <Row className="mt-4 width">
                    <Col xs={6} className="text-right">
                        <Button variant="secondary" className={"btn-scheduled"} onClick={() => reset()}>
                            {getLabelText("cancel")}
                        </Button>
                    </Col>
                    <Col xs={6} className="text-left">
                        <Button variant="primary" disabled={!moduleName} className={"btn-scheduled"} onClick={() => saveImplementationModule()}>
                            {getLabelText("save")}
                        </Button>
                    </Col>
                </Row>
            </Modal>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    user: state.userReducer.user
})

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ImplementationModuleScreen);
