import React, { useEffect, useState } from 'react';

const FiscalYearDateSelector = ({ action, startMonth, selectedValue, isChart = false }) => {

  const [monthNames] = useState(["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"])
  const [selectedOption, setSelectedOption] = useState(selectedValue)
  const [date] = useState(isChart ? selectedValue : new Date())
  const [options, setOptions] = useState([])
  
  const fiscalStartMonth = monthNames.findIndex(month => startMonth.includes(month));
  
  useEffect(() => {
    let arr = [];
    let fy = "";

    let monthIndex = date.getMonth() + 1;

    // For Start Month: July
    // 8 > 6, 20242025
    // 7 > 6, 20242025
    // 6 > 6, 20232024
    // 5 > 6, 20232024
    
    if (monthIndex > fiscalStartMonth) {
      fy = String(date.getFullYear()) + String(date.getFullYear() + 1);
    } else {
      fy = String(date.getFullYear() - 1) + String(date.getFullYear());
    }

    for (let i = fiscalStartMonth; i < monthNames.length; i++) {
      arr.push({
        value: new Date(parseInt(fy.substring(0, 4)), i + 1, 0),
        text: monthNames[i] + " " + parseInt(fy.substring(0, 4)),
      });
    }

    for (let i = 0; i < fiscalStartMonth; i++) {
      arr.push({
        value: new Date(parseInt(fy.substring(4)), i + 1, 0),
        text: monthNames[i] + " " + parseInt(fy.substring(4)),
      });
    }

    setOptions(arr);
  }, [selectedValue])

  useEffect(() => {
    if(!startMonth) return false

    if(selectedValue) {
      setSelectedOption(new Date(selectedValue))
    }
  }, [startMonth, selectedValue, setSelectedOption]); 

  return (
    <select
      className="form-control"
      value={selectedOption}
      onChange={(e) => action(new Date(e.target.value))}
      >
      {options.map((option, i) => {
        return <option key={i} value={option.value}>{option.text}</option>
      })}
    </select>
  )
}

export default FiscalYearDateSelector
