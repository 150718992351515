import { client } from "./BaseService";
import { gql } from "apollo-boost";
import * as userActions from '../actions/UserActions';
import * as teamActions from '../actions/TeamActions';

export const ME_QUERY = gql`
  query {
    me {
      id
      firstNames
      nickName
      role
      companies {
        id
        name
        finalcialYearEnd
        hasCompanyValues
        hasBusinessGoals
        hasRoutines
        hasReviewCriteria
        hasSkills
        hasUsers
        hasMeasurements
        supportEmail
        language
        consultant {
          id
          firstNames
          lastName
          email
        }
        companyGlossaries {
          id
          key
          value
        }
      }
      teams {
        id
        purpose
        name
        coach {
          id
          firstNames
          lastName
          nickName
        }
        leaders {
          id
          firstNames
          nickName
          lastName
        }
        members {
          id
          firstNames
          nickName
          lastName
        }
        department {
          id
          name
          departmentType {
            name
          }
        }
        partners {
          id
          name
          departmentType {
            name
          }
        }
        customers {
          id
          name
          departmentType {
            name
          }
        }

      }
    }
  }
`;

export const fetchUser = (selectLast = false) => async (dispatch) => {
  dispatch(userActions.fetchUserStarted());

  try {
    const response = await client.query({
      query: ME_QUERY,
    });

    if(selectLast) {
      dispatch(teamActions.fetchTeamSuccess({
        team: response.data.me.teams[response.data.me.teams.length -1]
      }));
    } else {
      dispatch(teamActions.fetchTeamSuccess({
        team: response.data.me.teams[0]
      }));
    }
    dispatch(userActions.fetchUserSuccess({ user: response.data.me}));
  } catch (err) {
    if(err.message === "Not authorized") {
      // Need to find a better way to do this. This is just a TEMP FIX.
      window.location.href = window.location.origin + "/";
    }
  }
};

export const updatePermissions = (name, value) => async (dispatch) => {
  dispatch(userActions.updateCompanyPagePermissions({name, value}))
}
