import React, { Fragment } from "react";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretSquareLeft, faCaretSquareRight } from "@fortawesome/free-solid-svg-icons";

const Header = ({ recognitions, selectedRecognition, setNext, setSelected }) => {
  return (
    <div className="team-col row goal-title-container">
      <div className="col-xl-6 col-lg-9 offset-xl-3 offset-lg-0 col-xs-12 offset-xs-0 pb-2 pt-1 text-left">
        {recognitions.length
          ? (
            <Fragment>
              <table className="w-100 h-100">
                <tbody>
                  <tr>
                    <td valign="middle" className="pr-2">
                      <button
                        className="button-unstyled d-inline text-white"
                        style={{ color: "#fff!important" }}
                        onClick={() => setNext(true)}
                      >
                        <FontAwesomeIcon
                          className="text-white"
                          size="2x"
                          icon={faCaretSquareLeft}
                        />
                      </button>
                    </td>
                    <td valign="middle" className="text-center">
                      <Dropdown>
                        <Dropdown.Toggle className={"nav-link w-100"} variant="none" id="dropdown-basic" style={{ whiteSpace: "break-spaces" }}>
                          <h4 className="d-inline"><b>{selectedRecognition.name}</b></h4>
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ maxHeight: "400px", overflow: "auto" }}>
                          {recognitions.map((recognition, i) => {
                            return <Dropdown.Item key={i} value={i} onClick={() => setSelected(recognition)}>{recognition.name}</Dropdown.Item>
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                    <td valign="middle" className="pl-2">
                      <button
                        className="button-unstyled d-inline text-white"
                        onClick={() => setNext()}
                      >
                        <FontAwesomeIcon size="2x" icon={faCaretSquareRight} />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Fragment>
          ) : null
        }
      </div>
      <div className="col-lg-3 col-xs-12 text-right">

      </div>
    </div>
  )
}

export default Header;
