import * as presentationTypes from '../types/PresentationTypes';

const presentationStarted = () => ({
  type: presentationTypes.PRESENTATION_STARTED,
});

const presentationEnded = () => ({
  type: presentationTypes.PRESENTATION_ENDED
});

const presentationUpdated= (payload) => ({
  type: presentationTypes.PRESENTATION_UPDATED,
  payload,
});

export {
  presentationStarted,
  presentationEnded,
  presentationUpdated,
};
