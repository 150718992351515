import React from "react"
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { mapEventData } from "./resources/mapEventData";
const localizer = momentLocalizer(moment)

const Calendar = ({ events, edit, selectDate }) => {

  return (
    <BigCalendar
      localizer={localizer}
      events={mapEventData(events)}
      popup={true}
      selectable={true}
      onSelectEvent={(e) => edit(e.id)}
      startAccessor="start"
      endAccessor="end"
      views={['month']}
      style={{ height: 800 }}
      onNavigate={(e) => { selectDate(e) }}
      eventPropGetter={
          (event) => {
              return {
                  style: {
                    backgroundColor: event.sharedStatus === 'INIT' ? "none" : event.bgColour,
                    color: event.sharedStatus === 'INIT' ? "white" : event.fgColour
                  },
                  className: event.sharedStatus === 'INIT' ? "invited-style" : "",
              };
          }
      }
    />
  )
}

export default Calendar
