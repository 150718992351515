import React, { useCallback, useState, Fragment, useEffect } from "react";
import classnames from "../../components/utils/classnames";
import { useMutation } from "@apollo/react-hooks";
import displayErrorToast from "../../components/utils/displayErrorToast";
import {
  UPDATE_METRIC_MUTATION,
  CREATE_METRIC_MUTATION,
} from "./resources/gql";

const STATUSES = [
  "DKGRY",
  "RED",
  "ORNG",
  "SLVR",
  "GLD"
]

const criteriaCellProps = {
  [STATUSES[0]]: { classes:"text-white bg-dk-grey", value: 0 },
  [STATUSES[1]]: { classes:"text-white bg-color-negative", value: 1 },
  [STATUSES[2]]: { classes:"text-white bg-orange", value: 2 },
  [STATUSES[3]]: { classes:"text-white bg-green", value: 3 },
  [STATUSES[4]]: { classes:"text-black bg-silver", value: 4 },
  [STATUSES[5]]: { classes:"text-black bg-gold", value: 5 },
}

const UserCell = ({
  day,
  subjectId,
  teamId,
  disabledDays,
  result,
  metrics,
  updateMetricData,
  monthValues,
  selectedPeriod
}) => {
  const [resultId, setResultId] = useState(result.id)
  const [value, setValue] = useState(result.value)
  const [displayValue, setDisplayValue] = useState(value)
  const [displayedStatus, setDisplayedStatus] = useState(STATUSES[value])
  const [eventFired, setEventFired] = useState(false)

  const onError = (error) => {
    setDisplayedStatus(STATUSES[value])
    setDisplayValue(value)
    displayErrorToast(error)
  };

  const moduleChanged = useCallback(() => {
    setResultId(result.id)
    setValue(result.value)
    setDisplayValue(result.value)
    setDisplayedStatus(STATUSES[result.value])
  }, [result, setResultId, setValue, setDisplayedStatus, setDisplayValue])

  useEffect(() => {
    moduleChanged()
  }, [subjectId, moduleChanged])

  const [updateMetric] = useMutation(UPDATE_METRIC_MUTATION, {
    onError,
    onCompleted: ({ updateCoachingReviewMetric }) => {
      setValue(updateCoachingReviewMetric.value);
      const newMetrics = metrics.map(metric => {
        return metric.periodValue === result.periodValue ? {
          ...metric,
          value: updateCoachingReviewMetric.value
        } : metric
      })
      updateMetricData(newMetrics, subjectId)
    },
  });

  const [createMetric] = useMutation(CREATE_METRIC_MUTATION, {
    onError,
    onCompleted: ({ createCoachingReviewMetric }) => {
      setValue(createCoachingReviewMetric.value);
      setResultId(createCoachingReviewMetric.id);
      const newMetrics = metrics.map(metric => {
        return metric.periodValue === result.periodValue ? {
          ...metric,
          value: createCoachingReviewMetric.value,
          id: createCoachingReviewMetric.id
        } : metric
      })
      updateMetricData(newMetrics, subjectId)
    },
  });


  const create = useCallback(() => {
    var localPeriodKey = result.periodKey
    if (selectedPeriod === "next") {
      localPeriodKey = localPeriodKey + 1;
    }
    if (selectedPeriod === "previous") {
      localPeriodKey = localPeriodKey - 1;
    }
    createMetric({
      variables: {
        subject: subjectId,
        team: teamId,
        value: displayValue,
        periodKey: `${localPeriodKey}`,
        periodValue: `${result.periodValue}`
      },
    });
  }, [createMetric, displayValue, teamId, subjectId, result]);

  const update = useCallback(() => {
    updateMetric({
      variables: {
        value: displayValue,
        id: resultId,
      },
    });
  }, [updateMetric, displayValue, resultId]);

  useEffect(() => {
    if(eventFired) {
      setEventFired(false)
      if (resultId) update();
      else create();
    }
  }, [eventFired, resultId, setEventFired]);



  const { classes } = criteriaCellProps[displayedStatus]

  const disabled = disabledDays.has(day) && "disabled";

  const className = classnames(
    "review-cell text-center font-weight-bold px-1 mr-3 attendance-date-border",
    classes,
    disabled
  );

  const toggleStatus = () => {
    let previousMetric = null

    for(let i=monthValues.indexOf(parseInt(result.periodValue)); i >= 0; --i) {
      if(!previousMetric) {
        previousMetric = metrics.find(metric => metric.periodValue === `${monthValues[i - 1]}`)
      }
    }

    const val = (previousMetric && previousMetric.value > 0 && !resultId) ? previousMetric.value : (value === 5) ? 0 : (value + 1)
    
    setDisplayedStatus(STATUSES[val])
    setDisplayValue(val)
    setEventFired(true)
  }


  return (
    <Fragment>
      <div onClick={() => toggleStatus()} className={className}>
        {displayValue && <span>{displayValue}</span>}
      </div>
    </Fragment>
  );
};

export default UserCell;
