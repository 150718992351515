export const lightBlue = "#4086A4";
export const MetricPeriodStatuses = {
  NORMAL: {
    value: "NORMAL",
    color: "#fff",
    tickLabelColor: "#005e85"
  },
  TRIGGER: {
    value: "TRIGGER",
    color: "#dc3545",
    tickLabelColor: "#dc3545",
  },
  NON_WORKING: {
    value: "NON_WORKING",
    color: "#212529",
    tickLabelColor: "#fff",
  },
};

export const MetricStatuses = {
  RED: {
    value: "RED",
    color: "#dc3545",
  },
  BLUE: {
    value: "BLUE",
    color: localStorage.getItem("colour") === "Green" ? '#28a745' : "#005e85"
  },
};

export const toNextPeriodStatus = (metric) => {
  const { NORMAL, TRIGGER, NON_WORKING } = MetricPeriodStatuses;

  switch (metric.periodStatus) {
    case NORMAL.value:
      return TRIGGER.value;

    case TRIGGER.value:
      return NON_WORKING.value;

    case NON_WORKING.value:
      return NORMAL.value;

    default:
      // Incase of unset status (for a new metric),
      // we toggle to TRIGGER as if NORMAL was set
      return TRIGGER.value;
  }
};

export const getDisabledDays = metrics => {
  const disabledDays = Object.entries(metrics).reduce(
    (all, [period, { periodStatus, periodValue }]) => {
      console.log(period)
      if (periodStatus === "NON_WORKING") all.add(parseInt(periodValue));
      return all;
    },
    new Set()
  );

  return disabledDays
}

export const getPeriodKey = (monthValues, selectedPeriod) => {
  const currentMonth = new Date().getMonth() + 1
  const firstMonth = monthValues[0]

  const year = currentMonth < firstMonth ? new Date().getFullYear() - 1 : new Date().getFullYear()
  let periodKey = year;
  if (selectedPeriod === 'next') {
    periodKey = year + 1
  }
  else if (selectedPeriod === 'previous') {
    periodKey = year - 1
  }
  return periodKey;
}

export const mapCriteriaData = (subjects, monthValues) => {
  return subjects.map(subject => {
    const { metrics } = subject

    return {
      ...subject,
      metrics: monthValues.map(val => {
        const metric = metrics.find(metric => metric.periodValue === `${val}`)

        return metric ? metric : {
          id: null,
          value: 0,
          periodValue: `${val}`,
          periodKey: getPeriodKey(monthValues),
        }
      })
    }
  })
}

export const mapPeriodData = (metrics, monthValues) => {
  return monthValues.map(value => {
    const metric = metrics.find(metric => metric.periodValue === `${value}`)

    return metric ? metric : {
      id: null,
      periodStatus: "NORMAL",
      periodValue: value,
      periodKey: getPeriodKey(monthValues),
    }
  })
}
