import { client } from "./BaseService";
import { gql } from "apollo-boost";

export const getBehaviours = async (teamId, valueId) => {
  return await client.query({
    query: gql`query{
      behaviours(teamId: "${teamId}", valueId: "${valueId}") {
        id
        description
        value {
          id
          value
        }
        team {
          id
          name
        }
      }
    }`
  })
}

export const getTeamBehaviours = async (teamId) => {
  return await client.query({
    query: gql`query{
      teamBehaviours(teamId: "${teamId}") {
        id
        description
        value {
          id
          value
        }
        team {
          id
          name
        }
      }
    }`
  })
}

export const createBehaviour = async(teamId, valueId, description) => {
  return await client.mutate({
    mutation: gql`mutation {
      createBehaviour(
        teamId: "${teamId}",
        valueId: "${valueId}",
        description: "${description}"
      )
      {
        id
      }
    }`
  })
}

export const updateBehaviour = async(id, description) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateBehaviour(
        id: "${id}",
        description: "${description}"
      )
      {
        id
      }
    }`
  })
}

export const deleteBehaviour = async(id) => {
  return await client.mutate({
    mutation: gql`mutation {
      deleteBehaviour(id: "${id}")
      {
        id
      }
    }`
  })
}
