import { client } from "./BaseService";
import { gql } from "apollo-boost";
import toGqlStrArray from "../components/utils/toGqlStrArray";

const updatePartners = async(id, customers, suppliers, departmentId) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateTeam(
        id: "${id}",
        departmentId: "${departmentId}"
        partnerIds: ${toGqlStrArray(suppliers)},
        customerIds: ${toGqlStrArray(customers)}
      ) {
        id
      }
    } `
  })
}

const createDepartment = async (name, departmentTypeId, teamId) => {
  return await client.mutate({
    mutation: gql`mutation {
      createDepartment(name: "${name}", departmentTypeId: "${departmentTypeId}", shiftCount: 1, type: EXTERNAL, creatorId: "${teamId}") {
        id
      }
    } `
  })
}

const updatePartnerName = async (id, name) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateDepartment(
        id: "${id}",
        name: "${name}"
      ) {
        id
      }
    } `
  })
}




export {
  updatePartners,
  createDepartment,
  updatePartnerName
}
