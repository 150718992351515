import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import TeamGoalsContent from "./TeamGoalsContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import {
  TEAM_MEASUREMENTS_LIST_QUERY,
  TEAM_MEASUREMENT_DATA_QUERY,
  TEAM_ATTENDANCE_DATA_QUERY,
  COMPANY_START_WEEKS, GET_TEAM_MEMBERS
} from "./gql";
import { getISOWeeksInMonth, getDaysInMonthArray, getPeriodKey } from "./hooks";
import { connect } from 'react-redux';
import { createUserLog, updateUserLog } from "../../services/TeamGoalAssignedUserLogService"
import { toast } from 'react-toastify'
import { Redirect } from "react-router";
import RealtimeRefreshComponent from "../../components/RealtimeRefreshComponent";
import LabelComponent from "../../components/utils/getCompanyLabel";

const getLabelText = key => <LabelComponent val={key} />

function getYearList(startMonth, isValues) {
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const array = []
  const index = months.indexOf(startMonth)

  for (let i = index; i < months.length; i++) {
    array.push(isValues ? (i + 1) : months[i])
  }

  for (let i = 0; i < index; i++) {
    array.push(isValues ? (i + 1) : months[i])
  }

  return array
}

function getYearValues(startMonth) {
  let array = []
  const monthValues = getYearList(startMonth, true)
  monthValues.map((month, index) => {
    let date = new Date(new Date().getFullYear(), (monthValues[0] - 1) + index, 2)

    array.push(date.getFullYear())

    return true
  })

  return array
}

function setSelectedIndex(presentation) {
  if (presentation && presentation.active && !presentation.paused && presentation.schedule.length && presentation.schedule[presentation.index].children.length) {
    return presentation.subIndex
  }
  return localStorage.getItem("selectedTeamResult") && localStorage.getItem("selectedTeamResult") !== "" ? localStorage.getItem("selectedTeamResult") : 0
}


function sortMeasures(measures, presentation, firstMonth) {
  const months = getYearList(firstMonth, true)
  const period = (new Date().getMonth() + 1) < months[0] ? `${new Date().getFullYear() - 1}${new Date().getFullYear()}` : `${new Date().getFullYear()}${new Date().getFullYear() + 1}`

  if (presentation && presentation.active && !presentation.paused && presentation.schedule[presentation.index].children.length) {
    return presentation.schedule[presentation.index].children.map(child => {
      let measure = measures.find(measure => measure.id === child.id)

      return measure ? measure : null
    })
  }

  return measures.filter(measure => measure.period === period).sort((a, b) => a.measurement.goalCategory.order - b.measurement.goalCategory.order)
}

const TeamGoalsScreen = ({ selectedTeam, user, standIn, presentation, refresh, hasNewData, toggleRefresh, toggleHasNewData, setAdditionalParam, togglePageLoad }) => {
  const [date, setDate] = useState(new Date());
  const [teamGoals, setTeamGoals] = useState([]);
  const [loaded, setLoaded] = useState(false)
  const [hasGoals, setHasGoals] = useState(true)
  const [teamAttendances, setTeamAttendances] = useState([]);
  const [period, setPeriod] = useState('WEEK');
  const [periodValues, setPeriodValues] = useState([]);
  const [selectedGoalIndex, setSelectedGoalIndex] = useState(0);
  const [monthList, setMonthList] = useState([]);
  const [monthValues, setMonthValues] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [loadedGoal, setLoadedGoal] = useState(null);
  const [isTrend, setIsTrend] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState();
  const [selectedStandInId, setSelectedStandInId] = useState();
  const [trendTarget, setTrendTarget] = useState(null);
  const [fiscalYear, setFiscalYear] = useState(1)
  const [selectedCompany] = useState(localStorage.getItem("companyId"))
  const [hasNext, setHasNext] = useState(false)
  const [hasPrev, setHasPrev] = useState(false)
  const [showNext, setShowNext] = useState(false)
  const [showPrev, setShowPrev] = useState(false)
  const [linkedId, setLinkedId] = useState("")
  const [colour, setColour] = useState(selectedTeam.company.color)
  const [startWeeks, setStartWeeks] = useState([])
  const [currentStartWeek, setCurrentStartWeek] = useState(null)

  const [teamGoalAssignedUserLogs, setTeamGoalAssignedUserLogs] = useState([]);
  const [logLoading, setLogLoading] = useState({
    owner: false,
    coOwner: false
  });

  const onStarWeekComplete = useCallback(
    (data) => {
      setStartWeeks(data.companyStartWeeks)
    },
    [setStartWeeks]
  );

  const onListComplete = useCallback(
    (data) => {
      setTeamGoals(data.team ? sortMeasures(data.team.measures, presentation, selectedTeam.company.finalcialYearEnd) : [])
      if (data.team) {
        setLoaded(true)
      }
    },
    [setTeamGoals, presentation, selectedTeam]
  );

  const onTeamAttendanceDataComplete = useCallback(
    (data) => setTeamAttendances(data.team ? data.team.attendances : []),
    [setTeamAttendances]
  );

  useQuery(TEAM_ATTENDANCE_DATA_QUERY, {
    // set the team id so we get the correct attendances for the correct team.
    variables: { id: selectedTeam.id },
    onCompleted: onTeamAttendanceDataComplete,
  });

  const [getMeasures] = useLazyQuery(TEAM_MEASUREMENTS_LIST_QUERY, {
    variables: { id: selectedTeam.id },
    onCompleted: onListComplete,
  });

  useQuery(COMPANY_START_WEEKS, {
    onCompleted: onStarWeekComplete,
  });

  const onLoadComplete = useCallback(
    (data) => {
      setLoadedGoal(data.teamMeasurement)
      if (refresh) {
        toggleRefresh()
        toggleHasNewData()
        togglePageLoad(false)
      }
    },
    [setLoadedGoal, toggleRefresh, toggleHasNewData, refresh, togglePageLoad]
  );

  const onGetUsers = useCallback(
      (data) => {
        setTeamGoalAssignedUserLogs(data?.team?.teamGoalAssignedUserLogs);
        setSelectedUser()
      },
      []
  )

  const [getGoal, { loading: isLoadingGoal }] = useLazyQuery(
    TEAM_MEASUREMENT_DATA_QUERY,
    {
      onCompleted: onLoadComplete,
    }
  );

  const [getUsers] = useLazyQuery(GET_TEAM_MEMBERS, {
    variables: { id: selectedTeam.id },
    onCompleted: onGetUsers,
  });

  const updateGoalMetricsFromDelta = (metricDelta, isConfig = false) => {
    setLoadedGoal((goal) => {
      if (isConfig) {
        const configs = [...goal.teamGoalGraphConfigs];
        const index = goal.teamGoalGraphConfigs.findIndex(c => c.key === metricDelta.key)

        if (index > -1) configs[index] = { ...configs[index], ...metricDelta }
        else if (index === -1) configs.push(metricDelta)

        return { ...goal, configs };
      } else {
        const metrics = [...goal.metrics];
        const index = goal.metrics.findIndex(m => m.periodValue === metricDelta.periodValue)

        if (index > -1) metrics[index] = { ...metrics[index], ...metricDelta }
        else if (index === -1) metrics.push(metricDelta)

        return { ...goal, metrics };
      }
    });
  };

  const setSelectedUser = useCallback(() => {
    let userId = null;
    let standInId = null;
    if (loadedGoal) {
      const assignedUsers = teamGoalAssignedUserLogs.filter(log => log.teamMeasurement && log.teamMeasurement.id === loadedGoal.id)
      if (assignedUsers.length > 0) {
        let assignedUser = null
        const currentMonth = date.getMonth() + 1
        let currentPeriod = `${currentMonth}${date.getFullYear()}`
        if (assignedUsers.find(log => log.periodKey === currentPeriod)) {
          assignedUser = assignedUsers.find(log => log.periodKey === currentPeriod)
        }
        if (assignedUser) {
          userId = assignedUser.user ? assignedUser.user.id : userId
          standInId = assignedUser.standIn ? assignedUser.standIn.id : standInId
        }
      }
    }
    setSelectedUserId(userId)
    setSelectedStandInId(standInId)
  }, [loadedGoal, date, setSelectedUserId, setSelectedStandInId, teamGoalAssignedUserLogs])

  const setAssignedUser = async value => {
    try {
      setLogLoading({
        ...logLoading, owner: true
      })

      setSelectedUserId(value)
      const { id } = selectedTeam
      const assignedUser = teamGoalAssignedUserLogs.find(log => log.teamMeasurement && log.teamMeasurement.id === loadedGoal.id && log.periodKey === `${(date.getMonth() + 1)}${date.getFullYear()}`)

      if (assignedUser) {
        await updateUserLog(assignedUser.id, value, selectedStandInId)
      } else {
        await createUserLog(id, value, selectedStandInId, loadedGoal.id, `${(date.getMonth() + 1)}${date.getFullYear()}`)
      }
      getUsers()
    } catch (err) {
      console.log(err)
    } finally {
      setLogLoading({
        ...logLoading, owner: false
      })
    }
  }

  const setAssignedStandIn = async value => {
    try {
      setLogLoading({
        ...logLoading, coOwner: true
      })
      const { id } = selectedTeam
      const assignedUser = teamGoalAssignedUserLogs.find(log => log.teamMeasurement && log.teamMeasurement.id === loadedGoal.id && log.periodKey === `${(date.getMonth() + 1)}${date.getFullYear()}`)

      setSelectedStandInId(value)
      if (assignedUser) {
        await updateUserLog(assignedUser.id, selectedUserId, value)
      } else {
        await createUserLog(id, selectedUserId, value, loadedGoal.id, `${(date.getMonth() + 1)}${date.getFullYear()}`)
      }
      getUsers()
    } catch (err) {
      console.log(err)
    } finally {
      setLogLoading({
        ...logLoading, coOwner: false
      })
    }
  }

  const loadGoal = useCallback(() => {
    let dates = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    let currentPeriod = period;

    if (isTrend) {
      currentPeriod = "MONTH";
      dates = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    } else {
      switch (teamGoals[selectedGoalIndex] ? teamGoals[selectedGoalIndex].frequency : teamGoals[0].frequency) {
        case "Daily":
          currentPeriod = "DAY"
          dates = getDaysInMonthArray(date);
          break;
        case "Weekly":
          currentPeriod = "WEEK"
          dates = getISOWeeksInMonth(date);
          const lastMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
          const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
          const lastMonthDates = getISOWeeksInMonth(lastMonth);
          const nextMonthDates = getISOWeeksInMonth(nextMonth);
          dates = [lastMonthDates[lastMonthDates.length - 2], lastMonthDates[lastMonthDates.length - 1], ...dates, nextMonthDates[0], nextMonthDates[1]];
          break;
        default:
          currentPeriod = "MONTH"
      }
    }

    if (linkedId && linkedId !== "") {
      getGoal({
        variables: {
          id: linkedId,
          period: currentPeriod,
          periodKey: isTrend ? getPeriodKey(fiscalYear, date, null) : getPeriodKey(fiscalYear, date, (teamGoals[selectedGoalIndex] ? teamGoals[selectedGoalIndex].frequency : teamGoals[0].frequency)),
          periodValues: dates,
          isTrend: isTrend,
          teamId: localStorage.getItem("teamId")
        },
      });
    } else {
      getGoal({
        variables: {
          id: teamGoals[selectedGoalIndex] ? teamGoals[selectedGoalIndex].id : teamGoals[0].id,
          period: currentPeriod,
          periodKey: isTrend ? getPeriodKey(fiscalYear, date, null) : getPeriodKey(fiscalYear, date, (teamGoals[selectedGoalIndex] ? teamGoals[selectedGoalIndex].frequency : teamGoals[0].frequency)),
          periodValues: dates,
          isTrend: isTrend,
          teamId: localStorage.getItem("teamId")
        },
      });
    }
  }, [isTrend, period, getGoal, teamGoals, selectedGoalIndex, date, fiscalYear, linkedId])

  useEffect(() => {

    if (loadedGoal && loadedGoal.nextYearLink) {
      setHasNext(true)
    } else {
      setHasNext(false)
    }

    if (loadedGoal && loadedGoal.prevYearLink) {
      setHasPrev(true)
    } else {
      setHasPrev(false)
    }

  }, [loadedGoal, setHasPrev, setHasNext])

  useEffect(() => {
    setLinkedId("")
    setIsTrend(false)
    setDate(new Date())
  }, [selectedGoalIndex, setLinkedId, setIsTrend, setDate])

  useEffect(() => {
    const selectedMonth = date.getMonth()
    if (isTrend || (loadedGoal && loadedGoal.frequency === "Monthly")) {
      setShowNext(hasNext)
      setShowPrev(hasPrev)
    } else {
      if (selectedMonth + 1 === monthValues[monthValues.length - 1]) {
        setShowNext(hasNext)
      } else if (selectedMonth + 1 === monthValues[0]) {
        setShowPrev(hasPrev)
      } else {
        setShowNext(true)
        setShowPrev(true)
      }
    }

  }, [date, setShowPrev, setShowNext, hasNext, hasPrev, monthValues, loadedGoal, isTrend])

  useEffect(() => {
    const months = getYearList(selectedTeam.company.finalcialYearEnd, true)
    const period = (date.getMonth() + 1) < months[0] ? `${date.getFullYear() - 1}${date.getFullYear()}` : `${date.getFullYear()}${date.getFullYear() + 1}`

    const startWeek = startWeeks.find(week => week.period === period)

    setCurrentStartWeek(startWeek)
  }, [date, startWeeks, setCurrentStartWeek, selectedTeam])

  useEffect(() => {
    if (!teamGoals.length) return;
    let index = setSelectedIndex(presentation)
    setSelectedGoalIndex(index)
    loadGoal();
    if (user && user.companies && user.companies.length > 0) {
      const company = user.companies.find(comp => comp.id === selectedCompany)

      if (company) {
        setMonthList(getYearList(company.finalcialYearEnd, false))
        setMonthValues(getYearList(company.finalcialYearEnd, true))
        setYearList(getYearValues(company.finalcialYearEnd))
      }
    }
    if (standIn && standIn.companies && standIn.companies.length > 0) {
      const company = standIn.companies.find(comp => comp.id === selectedCompany)

      if (company) {
        setMonthList(getYearList(company.finalcialYearEnd, false))
        setMonthValues(getYearList(company.finalcialYearEnd, true))
        setYearList(getYearValues(company.finalcialYearEnd))
      }
    }
  }, [selectedTeam, period, periodValues, teamGoals, loadGoal, user, standIn, selectedCompany, setSelectedGoalIndex, presentation]);

  useEffect(() => {
    if (refresh) {
      togglePageLoad(true)
      setSelectedUser()
      loadGoal();
    }
  }, [refresh, toggleRefresh, toggleHasNewData, setSelectedUser, loadGoal, togglePageLoad])

  useEffect(() => {
    if (loadedGoal) {
      setAdditionalParam(loadedGoal.id)
    }
  }, [loadedGoal, setAdditionalParam])

  useEffect(() => {
    if (selectedTeam) {
      setColour(selectedTeam.company.color)
    }
  }, [selectedTeam, setColour])

  useEffect(() => {
    if (!loadedGoal) return
    const trendPeriodKey = `${((date.getMonth() + 1) < monthValues[0] ? date.getFullYear() - 1 : date.getFullYear())}`
    const trendMetric = loadedGoal.allMetrics.find((metric) => metric.period === "MONTH" && metric.periodKey === trendPeriodKey && metric.periodValue === (date.getMonth() + 1))
    setTrendTarget(trendMetric && trendMetric.target ? trendMetric.target : null)
  }, [loadedGoal, setTrendTarget, monthValues, date])

  useEffect(() => {
    if (!teamGoals.length && !loadedGoal) return;
    setSelectedUser()
  }, [selectedTeam, period, periodValues, loadedGoal, setSelectedUser, teamGoals]);

  useEffect(() => {
    if (selectedTeam.id !== 0 && loaded && teamGoals.length < 1) setHasGoals(false)
  }, [loaded, teamGoals, selectedTeam]);

  useEffect(() => {
    if (!monthValues.length) return

    setFiscalYear(monthValues[0])
  }, [setFiscalYear, monthValues])

  useEffect(() => {
    getMeasures();
  }, [presentation.active, presentation.paused]);

  useEffect(() => {
    if(selectedTeam.id) getUsers()
  }, [selectedTeam.id]);

  const getNextIndex = (goBackwards) => {
    const nextIndex = goBackwards
      ? parseInt(selectedGoalIndex) - 1
      : parseInt(selectedGoalIndex) + 1;
    if (!teamGoals[nextIndex]) return goBackwards ? teamGoals.length - 1 : 0;
    return nextIndex;
  };

  const skipToNext = async date => {
    if (loadedGoal.nextYearLink) {
      if ((selectedGoalIndex && loadedGoal.nextYearLink === teamGoals[selectedGoalIndex].id) || (loadedGoal.nextYearLink === teamGoals[0].id)) {
        setLinkedId("")
      } else {
        setLinkedId(loadedGoal.nextYearLink)
      }
    }
    setDate(date)
  }

  const skipToPrev = async date => {
    if (loadedGoal.prevYearLink) {
      if ((selectedGoalIndex && loadedGoal.prevYearLink === teamGoals[selectedGoalIndex].id) || (loadedGoal.prevYearLink === teamGoals[0].id)) {
        setLinkedId("")
      } else {
        setLinkedId(loadedGoal.prevYearLink)
      }
    }

    setDate(date)
  }

  const refreshGraph = () => {
    setPeriodValues([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])
    setPeriod('MONTH')
    setIsTrend(!isTrend)
  }

  const signOutStyle = {
    float: "right"
  }
  const titleStyle = {
    float: "left"
  }

  if (loaded && !hasGoals) {
    toast.error("Please first create Team Goals")
    return <Redirect to="/teams/team-measures" />
  }

  return (
    <div className="result-container-fluid">
      <Row className="w-100 result-title-row extend-container">
        <Col xl={4} md={4} xs={4} className="text-left">
          <table className="w-100 h-100">
            <tbody>
              <tr>
                <td valign="middle" className="text-left">
                  <h4 className="app-title ml-2 mr-3 d-inline" style={titleStyle}>
                    {getLabelText("team_results_screen")}
                  </h4>
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
        <Col xl={4} md={4} xs={4} className="text-center">
          {loadedGoal
            ? (
              <Fragment>
                <table className="w-100 h-100">
                  <tbody>
                    <tr>
                      <td width="10%" valign="middle">
                        <h6 className="app-title">{getLabelText("owners")}</h6>
                      </td>
                      <td width="45%" valign="middle" className="pl-1">
                        {logLoading.owner ? <FontAwesomeIcon className="color-blue mr-2 fa-spin" icon={faSpinner} /> : (
                          <select className={"form-control"} value={selectedUserId || ""} onChange={(e) => setAssignedUser(e.target.value)}>
                            <option value="" disabled>Not Assigned</option>
                            {selectedTeam.combinedMembers.map((member, i) => (
                                <option value={member.id} key={i}>{member.firstNames} {member.lastName}</option>
                            ))}
                          </select>
                        )}
                      </td>
                      <td width="45%" valign="middle" className="pl-1">
                        {logLoading.coOwner ? <FontAwesomeIcon className="color-blue mr-2 fa-spin" icon={faSpinner} /> : (
                          <select className={"form-control"} value={selectedStandInId || ""} onChange={(e) => setAssignedStandIn(e.target.value)}>
                            <option value="" disabled>Not Assigned</option>
                            {selectedTeam.combinedMembers.map((member, i) => (
                              <option value={member.id} key={i}>{member.firstNames} {member.lastName}</option>
                            ))}
                          </select>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Fragment>
            ) : null
          }
        </Col>
        <Col xl={4} md={4} xs={4}>
          <table className="w-100 h-100">
            <tbody>
              <tr>
                <td valign="middle">
                  <span style={signOutStyle}>
                    <Col className="refreshBtn">
                      <div className={"refreshBtn"}>
                        <RealtimeRefreshComponent
                          refresh={refresh}
                          hasNewData={hasNewData}
                          toggleRefresh={toggleRefresh}
                        />
                      </div>
                      <button className="btn btn-primary mr-2 ml-2">
                        <Link className="btn-text-Wht m-0 font-weight-bold" to="/so-what/problem-solving">PS</Link>
                      </button>
                      <button className="btn btn-primary mr-2">
                        <Link className="btn-text-Wht m-0 font-weight-bold" to="/now-what/actions">NS</Link>
                      </button>
                    </Col>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
      {loadedGoal ? (
        <TeamGoalsContent
          date={date}
          setDate={setDate}
          skipToNext={skipToNext}
          skipToPrev={skipToPrev}
          isLoading={isLoadingGoal}
          key={loadedGoal.id}
          goal={loadedGoal}
          selectedGoalIndex={selectedGoalIndex}
          teamGoals={teamGoals}
          presentation={presentation}
          setSelectedGoalIndex={setSelectedGoalIndex}
          getNextIndex={getNextIndex}
          setIsTrend={setIsTrend}
          deltaUpdate={updateGoalMetricsFromDelta}
          attendances={teamAttendances}
          refreshGraph={refreshGraph}
          isTrend={isTrend}
          monthList={monthList}
          monthValues={monthValues}
          yearList={yearList}
          trendTarget={trendTarget}
          showNext={hasNext}
          showPrev={hasPrev}
          colour={colour}
          startWeek={currentStartWeek}
          selectedTeam={selectedTeam}
        />
      ) : (
        <Fragment>
          <div className="overlay">
          </div>
          <div className="page-loader">
            <div className="loader-box text-center">
              <FontAwesomeIcon className="text-white fa-spin fa-3x" icon={faSpinner} />
              <p className="text-white mt-4"><strong>Loading</strong></p>
            </div>
          </div>
        </Fragment>
      )}
      <div
        style={{ bottom: "60px", width: "98%" }}
        className="position-absolute d-flex justify-content-between align-items-center"
      >

      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam,
  user: state.userReducer.user,
  presentation: state.presentationReducer
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TeamGoalsScreen);
