


import { client } from "./BaseService";
import { gql } from "apollo-boost";
import toGqlStrArray from "../components/utils/toGqlStrArray";

const getCompanyValues = async () => {
  return await client.query({
    query: gql`query {
      companyValues {
        id
        value
        definition
        order
      }
    }`
  })
}

const createCompanyValue = async (value, definition) => {
  return await client.mutate({
    mutation: gql`mutation {
      createCompanyValue(value: "${value}", definition: "${definition}") {
        id
      }
    } `
  })
}

const updateCompanyValue = async(id, value, definition) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateCompanyValue(id: "${id}",value: "${value}", definition: "${definition}") {
        id
      }
    } `
  })
}

const updateCompanyValuesOrder = async(values) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateCompanyValuesOrder(
        companyValues: ${toGqlStrArray(values)}
      )
    }`
  })
}

const deleteCompanyValue = async(id) => {
  return await client.mutate({
    mutation: gql`mutation {
      deleteCompanyValue(id: "${id}") {
        id
      }
    } `
  })
}

export {
  getCompanyValues,
  createCompanyValue,
  updateCompanyValue,
  updateCompanyValuesOrder,
  deleteCompanyValue
}
