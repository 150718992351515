import { client } from "./BaseService";
import { gql } from "apollo-boost";

const createDepartment = async (name, departmentTypeId, shiftCount, parentId = null) => {
  if(parentId) {
    return await client.mutate({
      mutation: gql`mutation {
        createDepartment(name: "${name}", parentId: "${parentId}", shiftCount: ${shiftCount}, departmentTypeId: "${departmentTypeId}") {
          id
          name
          shiftCount
          teamLength
          children {
            id
            name
            shiftCount
            teamLength
            departmentType {
              id
              name
            }
          }
          departmentType {
            id
            name
          }
        }
      } `
    })
  } else {
    return await client.mutate({
      mutation: gql`mutation {
        createDepartment(name: "${name}", departmentTypeId: "${departmentTypeId}", shiftCount: ${shiftCount}) {
          id
          name
          shiftCount
          teamLength
          children {
            id
            name
            shiftCount
            teamLength
            departmentType {
              id
              name
            }
          }
          departmentType {
            id
            name
          }
        }
      } `
    })
  }
}

const updateDepartment = async (id, name, departmentTypeId, shiftCount) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateDepartment(id: "${id}", name: "${name}", departmentTypeId: "${departmentTypeId}", shiftCount: ${shiftCount}) {
        id
        name
        shiftCount
        teamLength
        children {
          id
          name
          shiftCount
          teamLength
          departmentType {
            id
            name
          }
        }
        departmentType {
          id
          name
        }
      }
    } `
  })
}

const updateParent = async(id, parentId) => {
  return await client.mutate({
    mutation: gql`mutation{
      updateDepartment(id: "${id}", parentId: "${parentId}") {
        id
        name
        shiftCount
        teamLength
        departmentType {
          id
          name
        }
      }
    }`
  })
}

const getDepartmentsForFunctionAdmin = async (ids) => {
  return await client.query({
    query: gql`
      query {
        getCompanyStructure(teamIds:"${ids.toString()}") {
          departments 
        }
      }
    `
  });
}

const getDepartments = async (all = false) => {
  return await client.query({
    query: gql`
    fragment childrenFields on Department {
      id
      name
      hasTeams
      shiftCount
      teamLength
      type
      departmentType {
        id
        name
      }
      creator {
        id
      }
    }

    query {
      departments(all: ${all}) {
        ...childrenFields
        children {
          ...childrenFields
          children {
            ...childrenFields
            children {
              ...childrenFields
              children {
                ...childrenFields
                children {
                  ...childrenFields
                }
              }
            }
          }
        }
      }
    }
    `
  })
}

const getInternalDepartments = async (all = false) => {
  return await client.query({
    query: gql`
    fragment childrenFields on Department {
      id
      name
      type
      departmentType {
        id
        name
      }
      creator {
        id
      }
    }

    query {
      departments(all: ${all}) {
        ...childrenFields
        children {
          ...childrenFields
          children {
            ...childrenFields
            children {
              ...childrenFields
              children {
                ...childrenFields
                children {
                  ...childrenFields
                }
              }
            }
          }
        }
      }
    }
    `
  })
}

const getExternalDepartments = async (all = false) => {
  return await client.query({
    query: gql`
    fragment childrenFields on Department {
      id
      name
      type
      departmentType {
        id
        name
      }
      creator {
        id
      }
    }

    query {
      departments(all: ${all}) {
        ...childrenFields
      }
    }
    `
  })
}

const getDepartmentsForPartners = async (teamId) => {
  return await client.query({
    query: gql`
    query {
      departmentsForPartners(teamId: "${teamId}") {
        all {
          id
          name
          type
          departmentType {
            id
            name
          }
          creator {
            id
          }
        }
        internal {
          id
          name
          type
          departmentType {
            id
            name
          }
          creator {
            id
          }
        }
      }
    }
    `
  })
}

const getSingleDepartment = async (id) => {
  return await client.query({
    query: gql`
    fragment childrenFields on Department {
      id
      name
      hasTeams
      shiftCount
      teamLength
      type
      children{
        teams {
          id
          name
          modules{
            id
            name
            subjects{
              id
              name
            }
          }
        }
      }
      departmentType {
        id
        name
      }
      creator {
        id
      }
    }
    query {
      department(id: "${id}") {
        ...childrenFields
        children {
          ...childrenFields
          children {
            ...childrenFields
            children {
              ...childrenFields
              children {
                ...childrenFields
                children {
                  ...childrenFields
                }
              }
            }
          }
        }
      }
    }
    `
  })
}

const deleteDepartment = async(id) => {
  return await client.mutate({
    mutation: gql`mutation {
      deleteDepartment(id: "${id}") {
        name
      }
    } `
  })
}

export {
  getDepartments,
  getDepartmentsForPartners,
  getInternalDepartments,
  getExternalDepartments,
  getSingleDepartment,
  createDepartment,
  updateDepartment,
  deleteDepartment,
  updateParent, 
  getDepartmentsForFunctionAdmin
}
