import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faSearch, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {addMonths} from "date-fns";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import {isEmpty} from "lodash";

import SubHeaderComponent from "../../components/SubHeaderComponent";
import LabelComponent from "../../components/utils/getCompanyLabel";
import Legend from "../TeamLeadershipRoutinesScreen/components/Legend";
import TrackerModal from "./TrackerModal";
import {
    deleteTaskTracker,
    getCategoriesQuery,
    getTaskTrackers,
} from "../../services/TaskCategoryService";
import MonthSelect from "../../components/MonthSelect";

import {
    getDateDay,
    getDateFromPeriod,
    getDaysInMonthArray,
    getFirstDay, getLastDay, getMonthTextByIndex, isApplicableForMonthly, isApplicableForWeekly
} from "../TeamLeadershipRoutinesScreen/resources/hooks";
import classnames from "../../components/utils/classnames";
import IconButton from "../../components/shared/IconButton";
import {frequencyProps} from "../TeamLeadershipRoutinesScreen/resources/enums";
import Edit from "../../assets/ButtonIcons/Edit Blue.svg";
import Delete from "../../assets/ButtonIcons/Delete Blue.svg";
import TaskTrackerPeriodCell from "./TaskTrackerPeriodCell";
import TaskTrackerCell from "./TaskTrackerCell";
import FilterTaskTrackerModal from "./FilterTaskTracker";
import {downloadXLSX} from "../../utils/utils";

const getLabelText = key => <LabelComponent val={key}/>

const TaskTrackerScreen = ({team, ...props}) => {
    const [showModal, setShowModal] = useState(false);
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [date, setDate] = useState(new Date());
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [taskTrackers, setTaskTrackers] = useState([]);
    const [periodMetrics, setPeriodMetrics] = useState([]);
    const [disabledDays, setDisabledDays] = useState(new Set());
    const [selectedTask, setSelectedTask] = useState();
    const [isDateChange, setIsDateChange] = useState(false);

    const headerRef = useRef();
    const scrollRef = useRef();

    const [filters, setFilters] = useState({ userId: null, frequency: null, taskTracker: null})

    const openModal = () => {
        setShowModal(true);
    }

    const changeMonth = async (delta) => {
        const newDate = addMonths(date, delta);
        setDate(newDate);
    };

    const handleScroll = (isFirst) => {
        if (headerRef.current && scrollRef.current) {
            if(isFirst)
            {
                scrollRef.current.scrollLeft = headerRef.current.scrollLeft;
            }
            else
            {
                headerRef.current.scrollLeft = scrollRef.current.scrollLeft;
            }
        }
    };

    const getFilteredTaskTracker = () => {
        let filteredTaskTrackers = taskTrackers;
        const { userId, frequency, taskTracker } = filters;

        if(!isEmpty(userId)) {
            filteredTaskTrackers = filteredTaskTrackers.filter(task => task.user.id === userId)
        }

        if(!isEmpty(frequency)) {
            filteredTaskTrackers = filteredTaskTrackers.filter(task => task.frequency === frequency)
        }

        if(!isEmpty(taskTracker)) {
            filteredTaskTrackers = filteredTaskTrackers.filter(task => task?.task?.taskCategory?.id === taskTracker)
        }

        return filteredTaskTrackers
    }

    const periodArray = useMemo(
        () => [...getDaysInMonthArray(date)],
        [date]
    );

    const getPeriodMetric = (period) => {
        const metric = periodMetrics.find(metric => getDateDay(metric.date) === period)

        return metric ? metric : {
            id: null,
            status: "NORMAL",
            period: "DAY",
            date: getDateFromPeriod(period, date)
        }
    }

    const isMetricApplicable = (routine, periodDate) => {
        switch(routine.frequency) {
            case "DAY":
                return true
            case "WEEK":
                return isApplicableForWeekly(routine.days, periodDate)
            default:
                return isApplicableForMonthly(routine.weeks, routine.days, periodDate, date)
        }
    }

    const onPeriodCellChange = ({ index: day, state }) => {
        if (state === 2) {
            const nextSet = new Set(disabledDays);
            nextSet.add(day);
            setDisabledDays(nextSet);
        } else {
            const nextSet = new Set(disabledDays);
            nextSet.delete(day);
            setDisabledDays(nextSet);
        }
    };

    const deleteSingleTaskTracker = async (id) => {
        const response = await deleteTaskTracker(id);
        if(response.data?.deleteTaskTracker?.id) {
            toast.success("Task Tracker Deleted Successfully");
        } else {
            toast.error("Something Went Wrong");
        }
        await getAllTaskTrackers(date);
    }

    const onEditSingleTaskTracker = (taskTracker) => {
        setSelectedTask(taskTracker);
        setShowModal(true);
    }

    const getRoutineMetric = (period, id) => {
        const task = taskTrackers.find(task => task.id === id)
        const metric = task.metrics.find(metric => getDateDay(metric.date) === period)

        return metric ? metric : {
            id: null,
            status: "SCHEDULED",
            date: getDateFromPeriod(period, date),
            user: {},
            routine: {}
        }
    }

    const getAllTaskTrackers = async (date) => {
        setIsLoading(true);

        const response = await getTaskTrackers(team.id, getFirstDay(date).toISOString(), getLastDay(date).toISOString());

        setPeriodMetrics(response.data.team.taskTrackersPeriodMetrics);
        setTaskTrackers(response.data.team.taskTrackers);

        setIsLoading(false);

        props.togglePageLoad(false);
    }

    const getCategories = async () => {
        const response = await getCategoriesQuery(team.id, 1, false, true);

        let categories = response.data?.getTaskCategories?.categories ?? [];
        let tasks = [];
        categories.forEach(cat => {
            cat.tasks.forEach(task => {
                tasks.push({
                    id: task.id,
                    task_name: task.name,
                    category_name: cat.name
                })
            })
        })
        setCategories(tasks);

        props.togglePageLoad(false);
    }

    const getRoutineStatus = (period, taskTracker) => {
        let routineMetric = getRoutineMetric(period, taskTracker.id);
        let metricApplicable = isMetricApplicable(taskTracker, getDateFromPeriod(period, date));
        if (metricApplicable) {
            return routineMetric.status;
        }
        else {
            return "";
        }
    }

    const saveExcel = async () => {
        let columns = [];
        columns.push({ header: "Task Tracker" , key: "taskTracker" });
        columns.push({ header: "Member" , key: "member" });
        columns.push({ header: "Frequency" , key: "frequency" });

        periodArray.forEach(period => {
            columns.push({ header: period, key: "Day " + period  });
        })

        let allTaskTrackers = [];
        let filteredTaskTracker = getFilteredTaskTracker();
        if (filteredTaskTracker != null && filteredTaskTracker.length > 0) {
            filteredTaskTracker.forEach(tsk => {
                let taskObject = {
                    "Task Tracker": tsk.task.name + " - " + tsk.task.taskCategory.name,
                    "Member": tsk.user.firstNames + " " + tsk.user?.lastName,
                    "Frequency": frequencyProps[tsk.frequency].text,
                };
                periodArray.forEach(period => {
                    taskObject["Day " + period] = getRoutineStatus(period, tsk);
                })
                allTaskTrackers.push(taskObject);
            });
        }
        
        const fileName = team.name + "_" + getMonthTextByIndex(date.getMonth()) + "_" + date.getFullYear() + "_Task_Tracker";

        await downloadXLSX(allTaskTrackers, fileName)
    };

    useEffect(() => {
        props.togglePageLoad(true);
        setCategories([]);
        setTaskTrackers([]);
        setPeriodMetrics([]);
        setIsDateChange(true);
        if(team.id) {
            getAllTaskTrackers(date);
            getCategories();
        }
    }, [team.id, date]);

    return (
        <Fragment>
            <SubHeaderComponent>
                <Col className="text-right childenSpace">
                    <Button className={"btn btn-primary btn-nrml ml-2"} onClick={() => openModal()}>{getLabelText("add")}<FontAwesomeIcon className="ml-2" icon={faPlus} /></Button>
                    <Button className="ml-2" onClick={() => saveExcel()}>Export</Button>
                </Col>
            </SubHeaderComponent>
            <Row className="mb-5 mr-1 overflow-auto">
                {isLoading && (
                    <Fragment>
                        <div className="page-loader">
                            <div className="loader-box text-center">
                                <FontAwesomeIcon className="text-white fa-spin fa-3x" icon={faSpinner} />
                                <p className="text-white mt-4"><strong>Loading</strong></p>
                            </div>
                        </div>
                    </Fragment>
                )}

                <div className="grey-header-space container-left-plus20">
                    {taskTrackers.length ? (
                        <div className="project-tracker-container w-100">
                            <div className={"row overflow-hidden align-items-center w-100 mb-3" + (isLoading ? " disabled" : "")} ref={headerRef} onScroll={() => handleScroll(true)}>
                                <Col xs={4}>
                                    <table className="w-100">
                                        <tbody>
                                            <tr>
                                                <th width="70%">
                                                    <MonthSelect date={date} onChange={changeMonth} />
                                                </th>
                                                <th width="30%">
                                                    <Button className={"btn btn-primary btn-nrml ml-2 w-100"} onClick={() => setShowFilterModal(true)}>{getLabelText("filter")}<FontAwesomeIcon className="ml-2" icon={faSearch} /></Button>
                                                </th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                                <Col xs={8}>
                                    <Row className="flex-nowrap">
                                        {periodArray.map((period, i) => {
                                            return (
                                                <TaskTrackerPeriodCell
                                                    result={getPeriodMetric(period)}
                                                    teamId={team.id}
                                                    key={`${period}_${date.toISOString()}`}
                                                    day={period}
                                                    today={date}
                                                    onCellChange={onPeriodCellChange}
                                                    index={i}
                                                    periodMetrics={periodMetrics}
                                                />
                                            )
                                        })}
                                    </Row>
                                </Col>
                            </div>
                            <div style={{maxHeight: "62.5vh", minHeight: "60vh", overflow: "scroll"}} className="w-100" ref={scrollRef} onScroll={() => handleScroll(false)}>
                                {getFilteredTaskTracker().map((task, i) => (
                                    <Row
                                        className={classnames(
                                            "w-100 mb-2",
                                            isLoading && "disabled"
                                        )}
                                        key={`${task.id}_${date}`}
                                    >
                                        <Col xs={4}>
                                            <table className="w-100">
                                                <tbody>
                                                    <tr>
                                                        <td width="40%">{(i + 1) + ".  " + task.task?.taskCategory?.name} - {task.task?.name}</td>
                                                        <td width="20%">{task.user.firstNames}</td>
                                                        <td width="20%">{getLabelText(frequencyProps[task.frequency].text.toLowerCase())}</td>
                                                        <td width="20%">
                                                            <IconButton tooltip={getLabelText("edit")} eventHandler={() => onEditSingleTaskTracker(task)}>
                                                                <img src={Edit} alt="Edit" className="btn-icons"/>
                                                            </IconButton>
                                                            <IconButton tooltip={getLabelText("delete")} eventHandler={() => deleteSingleTaskTracker(task.id)}>
                                                                <img src={Delete} alt="Delete" className="btn-icons"/>
                                                            </IconButton>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Col>
                                        <Col xs={8}>
                                            <Row className="flex-nowrap">
                                                {periodArray.map((period) => (
                                                    <TaskTrackerCell
                                                        key={period}
                                                        day={period}
                                                        today={date}
                                                        taskTrackerId={task.id}
                                                        disabledDays={disabledDays}
                                                        result={getRoutineMetric(period, task.id)}
                                                        colour={team.company.color}
                                                        isMetricApplicable={isMetricApplicable(task, getDateFromPeriod(period, date))}
                                                        team={team}
                                                    />
                                                ))}
                                                <div style={{minWidth: "0.5rem"}} />
                                            </Row>
                                        </Col>
                                    </Row>
                                    ))
                                }
                            </div>
                        </div>
                    ) : (
                        <Row>
                            {!isDateChange && (
                                <Col className="text-center py-3 ml-3">
                                    <label className="cdi-blue-txt ml-0">No Task Tracker Are Added For Your Team. Please Add Some Task to Start on.</label>
                                </Col>
                            )}
                        </Row>
                    )}
                </div>
            </Row>
            <Legend includeCoach={false} />

            <TrackerModal
                showModal={showModal}
                setShowModal={setShowModal}
                tasks={categories}
                users={team.combinedMembers}
                team={team}
                getAllTaskTrackers={getAllTaskTrackers}
                selectedTask={selectedTask}
                date={date}
            />
            <FilterTaskTrackerModal
                users={team.combinedMembers}
                routines={taskTrackers}
                selectedRoutine={selectedTask}
                teamRoutines={taskTrackers}
                setTeamRoutines={setTaskTrackers}
                showModal={showFilterModal}
                setShowModal={setShowFilterModal}
                filters={filters}
                setFilters={setFilters}
            />
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    team: state.teamsReducer.selectedTeam,
    user: state.userReducer.user
})

export default connect(mapStateToProps)(TaskTrackerScreen);