import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Row, Col, Button, Table, } from "react-bootstrap";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from 'react-modal';
import {
  faPlus,
  faSpinner,
  faUsers
} from "@fortawesome/free-solid-svg-icons";
import UserCell from "./UserCell";
import Legend from "./Legend";
import { connect } from 'react-redux';

import { TEAM_WITH_SKILLS_QUERY, ALL_SKILLS_QUERY, CREATE_TEAM_SKILL_MUTATION, CREATE_SKILL_MATRIX_MUTATION, DELETE_TEAM_SKILL_MUTATION } from "./resources/gql";

import displayErrorToast from "../../components/utils/displayErrorToast";
import classnames from "../../components/utils/classnames";
import SubHeaderComponent from "../../components/SubHeaderComponent";
import RealtimeRefreshComponent from "../../components/RealtimeRefreshComponent";
import {SKILL_STATUSES} from './resources/statuses';
import LabelComponent from "../../components/utils/getCompanyLabel";
import * as DefaultLabels from '../../assets/glossary.json';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
// icons
import Delete from '../../assets/ButtonIcons/Delete Blue.svg';
import Loader from '../../assets/ButtonIcons/Loader Blue.svg';

const labels = DefaultLabels.default
const workSheetName = 'Worksheet-1';

const getLabelText = key => <LabelComponent val={key}/>

// const customStyles = {
//   content : {
//     top                   : '50%',
//     left                  : '50%',
//     right                 : 'auto',
//     bottom                : 'auto',
//     marginRight           : '-50%',
//     transform             : 'translate(-50%, -50%)',
//     width                 : '20%'
//   }
// };

const customStylesManage = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '50%'
  }
};

const SkillsMatrixScreen = ({ selectedTeam, user, togglePageLoad, refresh, hasNewData, toggleRefresh, toggleHasNewData }) => {
  const [team, setTeam] = useState({});
  const [users, setUsers] = useState([]);
  const [skills, setSkills] = useState([]);
  const [teamSkills, setTeamSkills] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userSkills, setUserSkills] = useState([])
  const [selectedSkillId, setSelectedSkillId] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null)
  const [dueDate, setDueDate] = useState(new Date())
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [matrixSkill, setMatrixSkill] = useState(null)
  const [modalType, setModalType] = useState(null)
  const [matrixId, setMatrixId] = useState(null)
  const [showModalManage, setShowModalManage] = useState(false);
  const [modalTypeManage, setModalTypeManage] = useState(null)
  const [deleting, setDeleting] = useState(false)
  const [deleteId, setDeleteId] = useState(null)

  const computeData = useCallback(
    (data) => {
      if (!data) return;
      setTeam(data.team);
      setUsers(data.team.combinedMembers);
      setTeamSkills(data.team.teamSkills)
      setUserSkills(data.team.userSkills)
      setPageLoad(false);
      setIsLoading(false);
      togglePageLoad(false)
      if(refresh) {
        toggleRefresh()
        toggleHasNewData()
        togglePageLoad(false)
      }
    },
    [refresh, setTeam, setUsers, setIsLoading, setUserSkills, togglePageLoad, toggleRefresh, toggleHasNewData]
  );

  const loadSkills = useCallback((data) => {
    setSkills(data.skills);
  }, [setSkills]);

  const [loadData] = useLazyQuery(TEAM_WITH_SKILLS_QUERY, {
    variables: { id: selectedTeam.id },
    onCompleted: computeData,
    onError: displayErrorToast,
  });

  const [loadSkillData] = useLazyQuery(ALL_SKILLS_QUERY, {
    onCompleted: loadSkills,
    onError: displayErrorToast
  });

  const toggleDeleteLoader = (id = null) => {
    if (id) {
      setDeleting(true)
      setDeleteId(id)
    } else {
      setDeleting(false)
      setDeleteId(null)
    }
  }

  const getText = val => {

    const companyId = localStorage.getItem("companyId");
    let lang = "EN";
    let langLabels = labels.find(lbl => lbl.lang === lang);
    let def = langLabels.glossary.find(label => label.key === val);

    if(user && user.companies && user.companies.length) {
      const company = user.companies.find(company => company.id === companyId);
      if(company) {
        lang = company.language;
        langLabels = labels.find(lbl => lbl.lang === lang);
        def = langLabels.glossary.find(label => label.key === val);
        if(!def) {
          lang = "EN";
          langLabels = labels.find(lbl => lbl.lang === lang);
          def = langLabels.glossary.find(label => label.key === val);
        }
      }
      if(company && company.companyGlossaries) {
        const custom = company.companyGlossaries.find(label => label.key === val);

        if(custom) return custom.value
      }
    }

    if(!def) {
      console.log(val)
    }
    return def.value
  }

  useEffect(() => {
    if(refresh) {
      setIsLoading(true)
      togglePageLoad(true)
      if (selectedTeam.id !== 0) loadSkillData()
      if (selectedTeam.id !== 0) loadData()

    }
  }, [refresh, toggleRefresh, toggleHasNewData, selectedTeam, loadData, togglePageLoad, loadSkillData])

  useEffect(() => {
    setIsLoading(true);
    togglePageLoad(true)
    if (selectedTeam.id !== 0) loadSkillData()
    if (selectedTeam.id !== 0) loadData();
  }, [selectedTeam, loadData, setIsLoading, togglePageLoad, loadSkillData]);

  const onUserCellChange = (skill, status, userSkills, userId) => {
    users.map(user => {
      if(user.id === userId) {
        user.skills[skill] = {
          ...userSkills,
          status: SKILL_STATUSES[status]
        }
      }

      return true
    })

    setUsers(users)
  }


 const [createTeamSkill] = useMutation(CREATE_TEAM_SKILL_MUTATION, {
  onCompleted: () => {
    const skill = skills.find(skill => skill.id === selectedSkillId)
    setTeamSkills([...teamSkills, skill])
    setIsLoading(false)
    setShowModal(false)
  },
});

const [deleteTeamSkill] = useMutation(DELETE_TEAM_SKILL_MUTATION, {
  onCompleted: () => {
    setTeamSkills(teamSkills.filter(skill => skill.id !== deleteId))
    toggleDeleteLoader()
  },
});

const addSkill = useCallback(() => {
  setIsLoading(true)

  createTeamSkill({
    variables: {
      id: team.id,
      skill: selectedSkillId
    },
  });
}, [createTeamSkill, selectedSkillId, team, setIsLoading]);

const [createMatrix] = useMutation(CREATE_SKILL_MATRIX_MUTATION, {
 onCompleted: async () => {
   await loadData()
   setIsLoading(false)
   setShowModal(false)
 },
});

const saveMatrix = useCallback(() => {
 setIsLoading(true)

 createMatrix({
   variables: {
     user: selectedUser,
     team: team.id,
     skill: matrixSkill,
     status: selectedStatus,
     dueDate: dueDate
   },
 });
}, [createMatrix, matrixSkill, team, setIsLoading, selectedUser, selectedStatus, dueDate]);

const showMatrix = useCallback((obj = null) => {
  setShowModal(obj !== null)
  setModalType(obj ? "matrix" : null)
  setMatrixSkill(obj ? obj.skill : null)
  setSelectedUser(obj ? obj.user : null)
  setSelectedStatus(obj ? obj.status : null)
  setDueDate(obj ? obj.date : new Date())
  setMatrixId(obj ? obj.id : null)
}, [setMatrixId, setShowModal, setModalType, setSelectedUser, setDueDate, setSelectedStatus])

const handleDateChange = date => {
  setDueDate(date)
}

const handleDeleteSkill = (id) => {
  if (window.confirm(getText("delete_department_prompt"))) {
    toggleDeleteLoader(id)
    deleteTeamSkill({
      variables: {
        id: team.id,
        skill: id
      }
    })
  }
}

  const getStatusText = (status) => {
    let statusText = "";
    switch (status) {
      case "ASSESSED_COMPETENT":
        statusText = "ASSESSED COMPETENT"
        break;
      case "TRAINING_COMPLETED":
        statusText = "TRAINING COMPLETED"
        break;
      case "OVERDUE":
        statusText = "OVERDUE"
        break;
      case "PLANNED":
        statusText = "PLANNED"
        break;
      case "NOT_APPLICABLE":
        statusText = "NOT APPLICABLE"
        break;
      default:
          statusText = "";
          break;
    }

    return statusText;
  }

const saveExcel = async () => {
  const workbook = new Excel.Workbook();
  try {
   const columns = [];
    if (teamSkills != null && teamSkills.length > 0) {
      columns.push({ header: "User" , key: "userName" });

      teamSkills.forEach(teamSkill => {
        columns.push({ header: teamSkill.name , key: teamSkill.name });
      });
    }

    let allUsers = [];
    if (users != null && users.length > 0) {
      users.forEach(user => {
        let userObject = { userName: user.firstNames + " " + user.lastName };
        teamSkills.forEach(teamSkill => {
          let userSkill = userSkills.filter(us => us.skill.id === teamSkill.id && us.user.id === user.id);
          if (userSkill != null && userSkill.length > 0) {
            userObject[teamSkill.name] = getStatusText(userSkill[0].status);
          }
          else
          {
            userObject[teamSkill.name] = "NA";
          }
        });
        allUsers.push(userObject);
      });
    }

    let teamName;
    if (selectedTeam.id !== 0) {
      teamName = selectedTeam.name;
    }
    const fileName = teamName + "_SkillsMatrix";
    // creating one worksheet in workbook
    const worksheet = workbook.addWorksheet(workSheetName);

    // add worksheet columns
    // each columns contains header and its mapping key from data
    worksheet.columns = columns;

    // updated the font for first row.
    worksheet.getRow(1).font = { bold: true };

    // loop through all of the columns and set the alignment with width.
    worksheet.columns.forEach(column => {
      column.width = 20;
      column.alignment = { horizontal: 'left' };
    });

    allUsers.forEach(user => {
      worksheet.addRow(user);
    });

    // loop through all of the rows and set the outline style.
    worksheet.eachRow({ includeEmpty: false }, row => {
      // store each cell to currentCell
      const currentCell = row._cells;

      // loop through currentCell to apply border only for the non-empty cell of excel
      currentCell.forEach(singleCell => {
        // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
        const cellAddress = singleCell._address;

        // apply border
        worksheet.getCell(cellAddress).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });
    });

    // write the content using writeBuffer
    const buf = await workbook.xlsx.writeBuffer();

    // download the processed file
    saveAs(new Blob([buf]), `${fileName}.xlsx`);
  } catch (error) {
    console.error('Error saving file - ', error.message);
  } finally {
    // removing worksheet's instance to create new one
    workbook.removeWorksheet(workSheetName);
  }
};

  return (
    <Fragment>
      <SubHeaderComponent>
        <Col className="text-right childenSpace">
          <div className={"refreshBtn"}>
            <RealtimeRefreshComponent
              refresh={refresh}
              hasNewData={hasNewData}
              toggleRefresh={toggleRefresh}
            />
          </div>
          <Button
            className={"btn btn-primary btn-nrml mr-2 ml-2"}
            onClick={() => {setShowModal(true);
              setModalType("skill")}}
          >
            {getLabelText("add")}<FontAwesomeIcon className="ml-2" icon={faPlus} />
          </Button>
          {teamSkills && (
            <Button
              className={"btn btn-primary btn-nrml"}
              onClick={() => {setShowModalManage(true);
                setModalTypeManage("skill")}}
            >
              {getLabelText("manage")}<FontAwesomeIcon className="ml-2" icon={faUsers} />
            </Button>
          )}
          <Button className="ml-2" onClick={(e) => saveExcel()}>Export</Button>
        </Col>
      </SubHeaderComponent>
      <div className="grey-header-space container-left-plus20">
        <Row className="mb-5 overflow-auto paddingForScreenBottom">
          <Row className="mt-10 align-items-baseline w-100 mb-3">
            <Col xs={{ span: 9, offset: 3 }}>
              <Row className="flex-nowrap">
                {!pageLoad ?
                    teamSkills.map((skill) => (
                        <div key={skill.id} className="ml-0 text-vertical-180 p-1 mr-2">
                          {skill.name}
                        </div>

                    )) : null}
              </Row>
            </Col>
          </Row>
          {pageLoad ? (
              <div>
                Loading Skills ...
              </div>
          ) : (
              users.map(({ firstNames, lastName, id}, i) => (
                  <Row
                      className={classnames(
                          "align-items-baseline w-100 mb-2"
                      )}
                      key={`${id}`}
                  >
                    <Col className="ml-2">{(i + 1) + ".  " + firstNames + " " + lastName}</Col>
                    <Col xs={9}>
                      <Row className="flex-nowrap">
                        {/* For each user, we need either an existing matrix or an empty matrix object */}
                        {teamSkills.map((skill) => (
                            <UserCell
                                // styles={customStyles}
                                key={skill.id}
                                fullName={firstNames + " " + lastName}
                                skill={skill}
                                userId={id}
                                dueDate={dueDate}
                                teamId={team.id}
                                matrix={userSkills.find(userSkill => userSkill.user.id === id && userSkill.skill.id === skill.id)}
                                onCellChange={onUserCellChange}
                                setSelectedStatus={setSelectedStatus}
                                setDueDate={handleDateChange}
                                saveMatrix={saveMatrix}
                                showModal={showModal}
                                modalType={modalType}
                                showMatrix={showMatrix}
                                selectedStatus={selectedStatus}
                                selectedUser={selectedUser}
                                matrixSkill={matrixSkill}
                                userSkills={userSkills}
                                matrixId={matrixId}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                getText={getText}
                            />
                        ))}
                      </Row>
                    </Col>
                  </Row>
              ))
          )}
          <Legend />
          <Modal
              isOpen={showModal && modalType === "skill"}
              onRequestClose={() => {setShowModal(false); setSelectedSkillId(0); setModalType(null)}}
              contentLabel="Add Team Skill"
              // style={customStyles}
              className="standard-modal standard-modal-skill-matrix"
          >
            <div className="row">
              <div className="col-12 text-center">
                <label className="meeting-view-txt">{getLabelText("add_skill")}</label>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-12">
                <select
                    className={"form-control cdi-ddl w-100 ml-0"}
                    defaultValue={selectedSkillId}
                    onChange={(e) => setSelectedSkillId(e.target.value)}
                >
                  <option disabled value={0}>{getText("select_skill")}</option>
                  {skills.filter(skill => !teamSkills.some(teamSkill => teamSkill.id === skill.id)).map((skill, i) => {
                    return <option key={i} value={skill.id}>{skill.name}</option>
                  })}
                </select>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-6">
                <button className="btn btn-primary w-100" onClick={() => addSkill() } disabled={isLoading || !selectedSkillId}>
                  {isLoading
                      ? (
                          <>{getLabelText("saving")} <FontAwesomeIcon icon={faSpinner} className="fa-spin ml-2"></FontAwesomeIcon></>
                      ) : (
                          <>{getLabelText("save")}</>
                      )
                  }
                </button>
              </div>
              <div className="col-6">
                <button className="btn btn-default cdi-blue-outline color-blue w-100" onClick={() => {setShowModal(false); setSelectedSkillId(0); setModalType(null)}}>{getLabelText("cancel")}</button>
              </div>
            </div>
          </Modal>
          <Modal
              isOpen={showModalManage && modalTypeManage === "skill"}
              onRequestClose={() => {setShowModalManage(false); setSelectedSkillId(); setModalTypeManage(null)}}
              contentLabel="Delete A Skill"
              style={customStylesManage}
          >
            <div className="row">
              <div className="col-12 text-center">
                <label className="meeting-view-txt">{getLabelText("delete_skill")}</label>
              </div>
            </div>
            <div className="row include-users-content">
              <div className="col-12 text-center">
                <Table striped bordered hover className="w-100">
                  <thead>
                  <tr>
                    <td width="90%"><b>{getLabelText("skills_screen")}</b></td>
                    <td width="10%" className="text-left"><b> </b></td>
                  </tr>
                  </thead>
                  <tbody className="include-users-content">
                  {teamSkills.map((skill) => {
                    return (
                        <tr>
                          <td className="text-left">
                            <div
                                key={skill.id}
                                className="cdi-blue-txt bold">
                              {skill.name}
                            </div>
                          </td>
                          <td>
                            <button
                                className={"btn-icons-container"}
                                variant="danger"
                                onClick={() => handleDeleteSkill(skill.id)}
                                // alt="delete"
                            >
                              {deleting && deleteId === skill.id
                                  ? (
                                  <img src={Loader} alt="Loader" className="loader-spinner"/>
                                  ) : (
                                  <img src={Delete} alt="Delete" className="btn-icons"/>
                                  )
                              }
                            </button>
                          </td>
                        </tr>
                    )
                  })}
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 btn-close">
                <button
                    className="btn btn-default cdi-blue-outline color-blue w-50"
                    onClick={() => {setShowModalManage(false); setSelectedSkillId(0); setModalTypeManage(null)}}
                >
                  {getLabelText("close")}
                </button>
              </div>
            </div>
          </Modal>
        </Row>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam,
  user: state.userReducer.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SkillsMatrixScreen);
