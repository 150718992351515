import React, { Component, Fragment } from "react"

// Bootstrap
import { Row, Col } from "react-bootstrap"

// services
import { createDepartment, getDepartments, updateDepartment, deleteDepartment, updateParent, getDepartmentsForFunctionAdmin } from "../services/DepartmentService"
import { getDepartmentTypes } from "../services/DepartmentTypeService"
import { getCompany } from "../services/CompanyService"
import SortableTree, { changeNodeAtPath, addNodeUnderParent, removeNode } from 'react-sortable-tree'
import 'react-sortable-tree/style.css'
import { faCheck, faBan, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from 'react-redux'
import * as DefaultLabels from '../assets/glossary.json';
import NodeRendererDefault from '../components/NodeRendererDefault.js'
import { toast } from 'react-toastify'
import displayErrorToast from "../components/utils/displayErrorToast"
import putOtherLast from "../components/utils/putOtherLast"
import SubHeaderComponent from "../components/SubHeaderComponent";
import LabelComponent from "../components/utils/getCompanyLabel";
import Constants from "../utils/constants";

const labels = DefaultLabels.default;

const getNodeKey = ({ node }) => node.id

class StructureScreen extends Component {
  constructor(props) {
    super(props)

    this.props.toggleDirtyState(true);
    this.props.togglePageLoad(true);

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  state = {
    isLoading: false,
    departments: [],
    name: "",
    nodeId: "",
    mode: "",
    departmentTypes: [],
    queuedNodes: [],
    company: "",
    type: "",
    shiftCount: 1,
    structureChanged: false,
    isHandlingRequest: false,
    undoLoading: false,
    saveLoading: false,
    departmentIds: [],
    flattenedTree: [],
  }

  async componentDidMount() {
    await this.fetchCompany()
    await this.fetchDepartments()
    await this.fetchDepartmentTypes()
    this.props.togglePageLoad(false)

  }

  async fetchCompany() {
    const response = await getCompany()
    this.setState({
      company: response.data.company
    })
  }

  async fetchDepartmentTypes() {
    const response = await getDepartmentTypes()

    this.setState({
      isLoading: false,
      departmentTypes: putOtherLast(response.data.departmentTypes)
    })
  }

  getLabelText = key => <LabelComponent val={key}/>

  getText = val => {
    const { user } = this.props;

    const companyId = localStorage.getItem("companyId");
    let lang = "EN";
    let langLabels = labels.find(lbl => lbl.lang === lang);
    let def = langLabels.glossary.find(label => label.key === val);

    if(user && user.companies && user.companies.length) {
      const company = user.companies.find(company => company.id === companyId);
      if(company) {
        lang = company.language;
        langLabels = labels.find(lbl => lbl.lang === lang);
        def = langLabels.glossary.find(label => label.key === val);
        if(!def) {
          lang = "EN";
          langLabels = labels.find(lbl => lbl.lang === lang);
          def = langLabels.glossary.find(label => label.key === val);
        }
      }
      if(company && company.companyGlossaries) {
        const custom = company.companyGlossaries.find(label => label.key === val);

        if(custom) return custom.value
      }
    }

    if(!def) {
      console.log(val)
    }
    return def.value
  }

  getDepartmentCount = (array) => {
    let count = 1;
    
    array && array.some(function iter(a) {
      count += 1
      return Array.isArray(a.children) && a.children.some(iter);
    });

    return count;
  }

  flattenTree(node, flatArray) 
  {
    flatArray.push(node);
  
    if (node.children && node.children.length > 0) 
    {
      for (const child of node.children) 
      {
        this.flattenTree(child, flatArray);
      }
    }
  }

  async fetchDepartments() {
    let response = null;

    let ids = [];
    ids = this.props.user?.teams?.map(team => `'${team.department.id}'`);

    if(this.props.user?.role === Constants.Permission.FunctionAdmin || this.props.user?.role === Constants.Permission.TeamAdmin || this.props.user?.role === Constants.Permission.User)
    {
      this.setState({...this.state, departmentIds: this.props.user?.teams?.map(team => team.department.id)});

      if(ids.length > 0)
      {
        response = await getDepartmentsForFunctionAdmin(ids);
  
        let res = JSON.parse(response.data.getCompanyStructure.departments);    
        
        let flattenedTree = [];
        for (const node of res) 
        {
          this.flattenTree(node, flattenedTree);
        }

        this.setState({...this.state, flattenedTree: flattenedTree});

        response = JSON.parse(response.data.getCompanyStructure.departments);    
      }
      else 
      {
        response = [];
      }
    }
    else 
    {
      // response = await getDepartments();
      response = await getDepartmentsForFunctionAdmin(ids);
      response = JSON.parse(response.data.getCompanyStructure.departments);
    }
    const data = [{
      id: "",
      name: this.state.company.name,
      expanded: true,
      isCompany: true,
      department: [],
      children: this.setDepartmentStructure(response ?? []),
    }]

    this.setState({
      departments: data,
      departmentCount: this.getDepartmentCount(response)
    })
  }

  setDepartmentStructure = (departments) => {
    return departments?.map((department) => {
      return {
        ...department,
        expanded: true,
        children: department.children ? department.children?.map((child) => {
          return {
            ...child,
            expanded: true,
            children: child.children ? child.children?.map((child) => {
              return {
                ...child,
                expanded: true,
                children: child.children ? child.children?.map((child) => {
                  return {
                    ...child,
                    expanded: true,
                    children: child.children ? child.children?.map((child) => {
                      return {
                        ...child,
                        expanded: true,
                        children: child.children ? child.children?.map((child) => {
                          return {
                            ...child,
                            expanded: true,
                          }
                        }) : []
                      }
                    }) : []
                  }
                }) : []
              }
            }) : []
          }
        }) : []
      }
    })
  }

  getChildren = (department) => {
    return {
      ...department,
      expanded: true,
      children: this.getChildren(department.children)
    }
  }

  setCreate = (id) => {
    const { departments } = this.state
    this.props.toggleDirtyState();
    this.cancel()

    const department = this.getObj(departments, id)

    department.expanded = true
    department.children.unshift({
      children: [],
      departmentType: null,
      expanded: true,
      id: 0,
      name: "",
      subtitle: "",
      title: ""
    })

    this.setState({
      departments: this.state.departments?.map(el => (el.id === id ? Object.assign({}, el, department) : el)),
      nodeId: id,
      mode: "create",
      type: ""
    })
  }

  getObj = (array, value) => {
    var o;
    array && array.some(function iter(a) {
      if (a["id"] === value) {
        o = a;
        return true;
      }
      return Array.isArray(a.children) && a.children.some(iter);
    });
    return o;
  }

  addToUpdateList = (id, nextParentNodeId) => {
    let { queuedNodes } = this.state
    // Check if the node has not already been added to the queue.
    // If yes, just update the existing entry. Else add new entry
    let existingNode = queuedNodes.filter(({nodeId}) => {
      return nodeId === id
    })
    if(existingNode.length > 0) {
      const index = queuedNodes.indexOf(existingNode[0])
      queuedNodes[index].nextParentNodeId = nextParentNodeId
    } else {
      queuedNodes.push({
        nodeId: id,
        nextParentNodeId
      })

    }
  }

  updateParentNode = async({ node, nextParentNode, prevPath, nextPath }) => {
    // Bail if no change to parent
    if (!nextParentNode || prevPath.every((id, index) => id === nextPath[index])) return;

    this.props.toggleDirtyState();
    this.setState({ structureChanged: true })
    this.addToUpdateList(node.id, nextParentNode.id)
  }


  setEdit = (id, type) => {
    const { departments } = this.state
    this.cancel()
    this.props.toggleDirtyState();
    const department = this.getObj(departments, id)
    this.setState({
      nodeId: id,
      mode: "edit",
      name: department.name,
      shiftCount: department.shiftCount,
      type
    })
  }

  cancel = () => {
    const { mode, nodeId, departments } = this.state
    this.props.toggleDirtyState();
    let options = {
      nodeId: "",
      mode: "",
      name: "",
      type: "",
      shiftCount: 1
    }
    if (mode === "create") {
      const department = this.getObj(departments, nodeId)

      department.children = department.children.filter(({ id }) => id !== 0)

      options = {
        ...options,
        departments: this.state.departments?.map(el => (el.id === nodeId ? Object.assign({}, el, department) : el)),
      }

    }

    this.setState(options)
  }

  deleteItem = async (id, path) => {
    const node = this.getObj(this.state.departments, id)

    if(node.children.length === 0 && !node.hasTeams) {
      if (window.confirm(this.getText('delete_department_prompt'))) {
        this.setState({ isHandlingRequest: true })
        try {
          await deleteDepartment(id)
          const { treeData } = removeNode({ treeData: this.state.departments, path, getNodeKey })
          this.setState({ departments: treeData })
        } catch (error) {
          displayErrorToast(error)
        }
        this.setState({ isHandlingRequest: false })
      }
    } else {
      if(node.children.length !== 0) {
        toast.error(this.getText('delete_department_children_error'))
      }

      if(node.hasTeams) {
        toast.error(this.getText('delete_department_team_error'))
      }
    }
  }

  validForm = () => {
    const { nodeId, shiftCount, mode, departments } = this.state

    const department = this.getObj(departments, nodeId)

    if(mode !== "create") {
      if(department.teamLength > shiftCount) {
        toast.error("Amount of assigned teams cannot exceed the amount of shifts")

        return false
      }
    }
    return this.state.name.length > 0 && this.state.type.length && shiftCount > 0
  }

  async handleSubmit(path) {
    const { name, nodeId, type, shiftCount } = this.state
    if (this.validForm()) {
      this.setState({ isHandlingRequest: true })
      try {
        if (this.state.mode === "create") {
          const response = nodeId === 0 ? await createDepartment(name, type, shiftCount) : await createDepartment(name, type, shiftCount, nodeId)
          const { treeData } = addNodeUnderParent({ treeData: this.state.departments, newNode: response.data.createDepartment, parentKey: this.state.nodeId, addAsFirstChild: true, getNodeKey })
          this.setState({ departments: treeData })
        } else {
          const response = await updateDepartment(nodeId, name, type, shiftCount)
          let department = {
            ...this.getObj(this.state.departments,nodeId),
            name: response.data.updateDepartment.name,
            departmentType: response.data.updateDepartment.departmentType,
            shiftCount: response.data.updateDepartment.shiftCount
          }
          const departments = changeNodeAtPath({ treeData: this.state.departments, path, newNode: department, getNodeKey })
          this.setState({ departments })
        }
        this.cancel()
      } catch (error) {
        displayErrorToast(error)
      }
      this.setState({ isHandlingRequest: false })
    }
  }
  handleChange = (e) => {
    e.persist()

    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleTreeData = (treeData) => {
    this.setState({
      departments: treeData
    })
  }

  undoStructureChanges = async () => {
    this.props.toggleDirtyState();
    this.setState({
      undoLoading: true
    })
    await this.fetchDepartments()

    this.setState({
      undoLoading: false,
      queuedNodes: [],
      structureChanged: false
    })
  }

  saveParentNodes = (queuedNodes) => {
    return Promise.all(queuedNodes?.map(async({nodeId, nextParentNodeId}) => await updateParent(nodeId, nextParentNodeId)))
  }

  saveStructureChanges = async () => {
    this.props.toggleDirtyState();
    const { queuedNodes } = this.state
    this.setState({
      saveLoading: true
    })

    try {
      await this.saveParentNodes(queuedNodes)

      this.setState({
        saveLoading: false,
        queuedNodes: [],
        structureChanged: false
      })

    }catch (error) {
      displayErrorToast(error)

      this.setState({
        saveLoading: false
      })
    }
  }

  render() {
    const {isLoading, saveLoading, structureChanged, undoLoading, departmentCount } = this.state
    if (isLoading) {
      return <div>Loading</div>;
    } else {
      return (
        <Fragment>
          <SubHeaderComponent>
            <Row className="justify-content-center">
              <Col xs={10} className="text-left mt-5">
                {structureChanged
                  ? (
                    <Fragment>
                      <button className="btn btn-primary" onClick={() => this.saveStructureChanges()}>
                        {saveLoading ? this.getLabelText("saving") : this.getLabelText("save")}
                        <FontAwesomeIcon className={"ml-2 " + (saveLoading ? "fa-spin" : "")} icon={saveLoading ? faSpinner : faCheck} />
                      </button>
                      <button className="btn btn-primary ml-1" onClick={() => this.undoStructureChanges()}>
                        {undoLoading ? "Undoing..." : "Undo"}
                        <FontAwesomeIcon className={"ml-2 " + (undoLoading ? "fa-spin" : "")} icon={undoLoading ? faSpinner : faBan} />
                      </button>
                    </Fragment>
                  ) : null
                }
              </Col>
            </Row>
          </SubHeaderComponent>
          <div className="grey-header-space container-left">
            <Row className="justify-content-left" style={{ height: "100%" }}>
              <Col xl={12} lg={12} md={12} sm={12} xs={12} style={{ height: "100%" }}>
                <div className={"department-structure-container mb-5" + (saveLoading || undoLoading ? " disabled" : "") + (this.props.permissions.canEdit ? "" : " non-draggable")} style={{ height:  ((departmentCount * 82) + 100) + "px"}}>
                  <SortableTree
                      maxDepth={6}
                      treeData={this.state.departments}
                      onChange={treeData => this.handleTreeData(treeData)}
                      getNodeKey={({ node }) => node.id}
                      rowHeight={82}
                      onMoveNode={this.updateParentNode}
                      className="mb-5"
                      canDrag={!saveLoading || !undoLoading}
                      nodeContentRenderer={(props) => {
                        let canEdit = this.props.permissions.canEdit;
                        /*
                        if(this.props.user?.role === Constants.Permission.FunctionAdmin)
                        {
                          canEdit = canEdit || this.state.departmentIds?.includes(props.node.id) || this.state.departmentIds?.includes(props.node.parent);
                          if(!canEdit)
                          {
                            const res = this.state.flattenedTree?.find(ele => ele.id === props.node.parent);
                            if(res) 
                            {
                              canEdit = canEdit || this.state.departmentIds?.includes(res.parent);
                              if(!canEdit)
                              {
                                const r = this.state.flattenedTree?.find(ele => ele.id === res.parent);
                                if(r)
                                {
                                  canEdit = canEdit || this.state.departmentIds?.includes(r.parent);
                                }
                              }
                            }
                          }
                        }
                        else if(this.props.user?.role === Constants.Permission.TeamAdmin || this.props.user?.role === Constants.Permission.TeamLeader)
                        {
                          canEdit = canEdit || this.state.departmentIds?.includes(props.node.id);
                        }
                        */
                        return (
                          <NodeRendererDefault
                              node={props.node}
                              title={props.node.title}
                              subtitle={props.node.subtitle}
                              nodeId={this.state.nodeId}
                              canEdit={canEdit}
                              setEdit={this.setEdit}
                              setCreate={this.setCreate}
                              buttonsDisabled={this.state.structureChanged}
                              mode={this.state.mode}
                              cancel={this.cancel}
                              name={this.state.name}
                              handleChange={this.handleChange}
                              types={this.state.departmentTypes}
                              type={this.state.type}
                              submit={this.handleSubmit}
                              deleteItem={this.deleteItem}
                              isHandlingRequest={this.state.isHandlingRequest}
                              shiftCount={this.state.shiftCount}
                              getText={this.getText}
                              {...props}
                          />
                        )
                      }
                    }
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Fragment>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user
})

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StructureScreen)
