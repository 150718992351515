import { useMutation } from "@apollo/react-hooks";
import { CREATE_METRIC, UPDATE_METRIC, CREATE_TEAM_GOAL_GRAPH_CONFIG, UPDATE_TEAM_GOAL_GRAPH_CONFIG } from "./gql";
import displayErrorToast from "../../components/utils/displayErrorToast";
import { useMemo } from "react";
import { MetricStatuses, MetricPeriodStatuses } from "./resources";
import _ from 'lodash';
import {
  setISOWeek,
  getMonth,
  getISOWeek,
  format,
  startOfWeek,
  lastDayOfWeek,
  getDaysInMonth,
} from "date-fns";


// Reusable CRUD hook for upserting metrics
export const useCreateOrUpdateMetric = () => {
  const [createMetric, { loading: createIsLoading }] = useMutation(
    CREATE_METRIC
  );
  const [updateMetric, { loading: updateIsLoading }] = useMutation(
    UPDATE_METRIC
  );

  return [createMetric, updateMetric, createIsLoading || updateIsLoading];
};


export const useCreateOrUpdateTeamGoalGraphConfig = () => {
  const [createTeamGoalGraphConfig, { loading: createIsLoading }] = useMutation(
    CREATE_TEAM_GOAL_GRAPH_CONFIG,
    {
      onError: displayErrorToast,
    }
  );
  const [updateTGeamGoalGraphConfig, { loading: updateIsLoading }] = useMutation(
    UPDATE_TEAM_GOAL_GRAPH_CONFIG,
    {
      onError: displayErrorToast,
    }
  );

  return [createTeamGoalGraphConfig, updateTGeamGoalGraphConfig, createIsLoading || updateIsLoading];
};

export const useDynamicDomain = (data, domain, target, lowerTrigger, upperTrigger) => {

  return useMemo(() => {
    const minData = _.minBy(data, (d) => {
      return d.y
    })
    const maxData = _.maxBy(data, (d) => {
      return d.y
    })

    let all = []

    if(minData) {
      all.push(minData.y)
    }

    if(maxData) {
      all.push(maxData.y)
    }

    if(target && target.length > 0) {
      all.push(...target)
    }

    if(lowerTrigger && lowerTrigger.length > 0) {
      all.push(...lowerTrigger)
    }

    if(upperTrigger && upperTrigger.length > 0) {
      all.push(...upperTrigger)
    }


    all = all.filter(val => val !== "")

    if(all.length < 1 || (all[0] === 0 && all[0] === 0 && all.length < 3)) {
      all = domain
    }
    const min  = _.minBy(all, (d) => {
      return d
    })
    const max = _.maxBy(all, (d) => {
      return d
    })

    return [min, max]

  }, [data, domain, target, lowerTrigger, upperTrigger]);
};

export const useDateString = (data) =>
  useMemo(() => {
    const date = setISOWeek(new Date(), data.x);
    const first = format(startOfWeek(date), "d");
    const last = format(lastDayOfWeek(date), "d");
    const month = format(date, "MMM");
    const year = date.getFullYear();
    return `(${first}-${last} ${month} ${year})`;
  }, [data.x]);

// For a given date, returns an array of ISO week numbers (int)
// export const getISOWeeksInMonth = (date) => {
//   console.log(date)
//   const firstDayOfMonth = startOfMonth(date);
//   const lastDayOfMonth = addDays(endOfMonth(date), 1);
//   const diff = differenceInCalendarISOWeeks(lastDayOfMonth, firstDayOfMonth);
//   let dates = [];
//   for (let i = 0; i < diff; i++) {
//     const day = addDays(firstDayOfMonth, i * 7);
//     dates.push(getISOWeek(day));
//   }
//   // Trim year start/end overlaps
//   if (date.getMonth() === 11) dates = dates.filter(d => d > 10)
//   if (date.getMonth() === 0) dates = dates.filter(d => d < 50)
//
//   return dates;
// };

export const getISOWeeksInMonth = (date) => {
  let dates = [];
  // Get all thursdays within the current month
  const thursdays = getThursdays(date)
  thursdays.map(day => dates.push(getISOWeek(day)))
  return dates;
};

export const getThursdays = (date) => {
  var d = new Date(date),
      month = d.getMonth(),
      thursdays = [];

  d.setDate(1);

  // Get the first Monday in the month
  while (d.getDay() !== 4) {
      d.setDate(d.getDate() + 1);
  }

  // Get all the other Mondays in the month
  while (d.getMonth() === month) {
      thursdays.push(new Date(d.getTime()));
      d.setDate(d.getDate() + 7);
  }

  return thursdays;
}

// Transforms the weekly metric data to an array of graph coordinates
export const useMetricData = (goal, date, isTrend, monthValues) => {
  // switch to dialy which is 1- 31 for the month
  // or switch to yearly
  const graphData = useMemo(() => {
    let dates = [];
    if (isTrend) {
      dates = monthValues
    } else {
      switch (goal.frequency) {
        case "Daily":
          dates = getDaysInMonthArray(date);
          break;
        case "Weekly":
          dates = getISOWeeksInMonth(date);
          const lastMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
          const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
          const lastMonthDates = getISOWeeksInMonth(lastMonth);
          const nextMonthDates = getISOWeeksInMonth(nextMonth);

          dates = [lastMonthDates[lastMonthDates.length - 2], lastMonthDates[lastMonthDates.length - 1], ...dates, nextMonthDates[0], nextMonthDates[1]];

          break;
        default:
          dates = monthValues
      }
    }

    const metrics = goal.metrics.reduce((all, metric) => {
      return {
        ...all,
        [metric.periodValue]: {
          value: metric.value,
          id: metric.id,
          status: metric.status,
          periodStatus: metric.periodStatus,
          target: metric.target,
          lowerTrigger: metric.lowerTrigger,
          upperTrigger: metric.upperTrigger,
          issue: metric.issue
        },
      };
    }, {});
    return dates.map((day) => {

      const m = metrics[day] || {};
      return {
        x: dates.indexOf(day) + 1,
        y: m.value === undefined ? null : m.value,
        id: m.id || null,
        status: m.status || MetricStatuses.BLUE.value,
        periodStatus: m.periodStatus || MetricPeriodStatuses.NORMAL.value,
        target:m.target === undefined ? null : m.target,
        lowerTrigger: m.lowerTrigger === undefined ? null : m.lowerTrigger,
        upperTrigger: m.upperTrigger === undefined ? null : m.upperTrigger,
        date: date,
        issue: m.issue
      };
    });
  }, [isTrend, goal, date, monthValues]);

  return graphData;
};

export const getPeriodDisplayText = (frequency) => {
  switch (frequency) {
    case "Daily":
      return "Day"
    case "Weekly":
      return "Week"
    default:
      return "Month"
  }
}

export const getPeriodKey = (fiscalYearStartMonth, date, frequency) => {
  const currentMonth = date.getMonth() + 1

  if (frequency === "Daily") {
    return `${date.getMonth() + 1}${date.getFullYear()}`
  }

  if (frequency === "Weekly") {
    return `${date.getFullYear()}`
  }
  return currentMonth < fiscalYearStartMonth ? `${(date.getFullYear() - 1)}` : `${date.getFullYear()}`
}

const getResultYear = (resultDate, monthValues,key) =>{
  let resultYear = resultDate.getFullYear();

  return ((resultDate.getMonth() + 1) < monthValues[0] 
  ? (monthValues[key - 1] < monthValues[0] ? resultYear : resultYear - 1) 
  : (monthValues[key - 1] < monthValues[0] ? resultYear + 1: resultYear))
}

export const getMinValue = (data, key, frequency, monthValues, resultDate) => {
  let currKey = `${monthValues[key - 1]}${getResultYear(resultDate, monthValues, key)}`

  //  if (resultYear === new Date().getFullYear()) {
  //     currKey = `${monthValues[key - 1]}${monthValues[key - 1] < monthValues[0] ? new Date().getFullYear() : new Date().getFullYear()- 1}`
  //   }

  //const currKey = `${monthValues[key - 1]}${monthValues[key - 1] < monthValues[0] ? resultYear - 1: resultYear}`
  const currentYear = `${getResultYear(resultDate, monthValues, key)}`

  const min = _.minBy(data, (metric) => {
    if(frequency === "Daily") {
      if (metric.periodKey && metric.periodKey === currKey && metric.periodStatus !== "NON_WORKING") {
        return metric.value
      }
    } else if(frequency === "Weekly") {
      const monthWeeks = getISOWeeksInMonth(new Date(currentYear, monthValues[key - 1], 0))
      if (metric.periodKey && metric.periodKey === `${currentYear}` && monthWeeks.includes(metric.periodValue) && metric.periodStatus !== "NON_WORKING") {
        return metric.value ? metric.value : 0
      }
    }
  });
  return min ? min.value : 0;
}

export const getMaxValue = (data, key, frequency, monthValues, resultDate) => {
  let currKey = `${monthValues[key - 1]}${getResultYear(resultDate, monthValues, key)}`
  // if (resultYear === new Date().getFullYear()) {
  //   currKey = `${monthValues[key - 1]}${monthValues[key - 1] < monthValues[0] ? new Date().getFullYear() : new Date().getFullYear()- 1}`
  // }
  //const currKey = `${monthValues[key - 1]}${monthValues[key - 1] < monthValues[0] ? resultYear - 1: resultYear}`
  const currentYear = `${getResultYear(resultDate, monthValues, key)}`
  const max = _.maxBy(data, (metric) => {
    if(frequency === "Daily") {
      if (metric.periodKey && metric.periodKey === currKey && metric.periodStatus !== "NON_WORKING") {
        return metric.value
      }
    } else if(frequency === "Weekly") {
      const monthWeeks = getISOWeeksInMonth(new Date(currentYear, monthValues[key - 1], 0))
      if (metric.periodKey && metric.periodKey === `${currentYear}` && monthWeeks.includes(metric.periodValue) && metric.periodStatus !== "NON_WORKING") {
        return metric.value
      }
    }
  });
  return max ? max.value : 0;
}

export const getTotal = (data, key, frequency, monthValues, resultDate) => {

  let currKey = `${monthValues[key - 1]}${getResultYear(resultDate, monthValues, key)}`
  // if (resultYear === new Date().getFullYear()) {
  //   currKey = `${monthValues[key - 1]}${monthValues[key - 1] < monthValues[0] ? new Date().getFullYear() : new Date().getFullYear()- 1}`
  // }

  //const currKey = `${monthValues[key - 1]}${monthValues[key - 1] < monthValues[0] ? resultYear - 1: resultYear}`
  const currentYear = `${getResultYear(resultDate, monthValues, key)}`
  const total = _.sumBy(data, (metric) => {
    if(frequency === "Daily") {
      if (metric.periodKey && metric.periodKey === currKey && metric.periodStatus !== "NON_WORKING") {
        return metric.value || 0
      }
    } else if(frequency === "Weekly") {
      const monthWeeks = getISOWeeksInMonth(new Date(currentYear, monthValues[key - 1], 0))
      if (metric.periodKey && metric.periodKey === `${currentYear}` && monthWeeks.includes(metric.periodValue) && metric.periodStatus !== "NON_WORKING") {
        return metric.value || 0
      }
    }
  });
  return !isNaN(parseFloat(total)) ? parseFloat(total).toFixed(2) : 0;
}

export const countTriggers = (data, key, frequency, monthValues, resultDate) => {
  let triggerDays = 0;

  let currYear = `${getResultYear(resultDate, monthValues, key)}`;
  // if (resultYear === new Date().getFullYear()) {
  //   currYear = `${monthValues[key - 1] < monthValues[0] ? new Date().getFullYear() -1 : new Date().getFullYear()}`
  // }
  // else {
  //   currYear = `${monthValues[key - 1] < monthValues[0] ? resultYear - 1: resultYear}`
  // }

  //const currYear = `${monthValues[key - 1] < monthValues[0] ? resultYear - 1: resultYear}`
  _.each(data, (metric) => {
    if(frequency === "Daily") {
      if (metric.periodKey && metric.periodKey === `${monthValues[key - 1]}${currYear}`) {
        if (metric.periodStatus === "TRIGGER") triggerDays++
      }
    } else if(frequency === "Weekly") {
      const monthWeeks = getISOWeeksInMonth(new Date(currYear, monthValues[key - 1], 0))
      if (metric.periodKey && metric.periodKey === currYear && monthWeeks.includes(metric.periodValue)) {
        if (metric.periodStatus === "TRIGGER") triggerDays++
      }
    }
  });

  return triggerDays;
}

export const countRedDays = (data, key, frequency, monthValues, resultDate) => {
  let redDays = 0;

  let currYear = `${getResultYear(resultDate, monthValues, key)}`;
  // if (resultYear === new Date().getFullYear()) {
  //   currYear = `${monthValues[key - 1] < monthValues[0] ? new Date().getFullYear() - 1 : new Date().getFullYear()}`
  // }
  // else {
  //   currYear = `${monthValues[key - 1] < monthValues[0] ? resultYear - 1: resultYear}`
  // }

  //const currYear = `${monthValues[key - 1] < monthValues[0] ? resultYear - 1: resultYear}`
  _.each(data, (metric) => {
    if(metric.value) {
      if(frequency === "Daily") {
        if (metric.periodKey && metric.periodKey === `${monthValues[key - 1]}${currYear}` && metric.periodStatus !== "NON_WORKING") {
          if (metric.status === "RED") redDays++
        }
      } else if(frequency === "Weekly") {
        const monthWeeks = getISOWeeksInMonth(new Date(currYear, monthValues[key - 1], 0))
        if (metric.periodKey && metric.periodKey === currYear && monthWeeks.includes(metric.periodValue) && metric.periodStatus !== "NON_WORKING") {
          if (metric.status === "RED") redDays++
        }
      }
    }
  });

  return redDays;
}

export const getAverage = (data, key, frequency, monthValues, resultDate) => {
  let total = 0
  let count = 0
  let currYear = `${getResultYear(resultDate, monthValues, key)}`;
  // if (resultYear === new Date().getFullYear()) {
  //   currYear = `${monthValues[key - 1] < monthValues[0] ? new Date().getFullYear() - 1 : new Date().getFullYear()}`
  // }
  // else {
  //   currYear = `${monthValues[key - 1] < monthValues[0] ? resultYear - 1 : resultYear}`
  // }
  
  _.each(data, (metric) => {
    if(metric.value !== null && metric.periodStatus !== "NON_WORKING") {
      if(frequency === "Daily") {
        if (metric.periodKey && metric.periodKey === `${monthValues[key - 1]}${currYear}`) {
          total += metric.value
          count++
        }
      } else if(frequency === "Weekly") {
        const monthWeeks = getISOWeeksInMonth(new Date(currYear, monthValues[key - 1], 0))
        if (metric.periodKey && metric.periodKey === currYear && monthWeeks.includes(metric.periodValue) && metric.periodStatus !== "NON_WORKING") {
          total += metric.value
          count++
        }
      }
    }
  });
  const average = count > 0 ? total/count : 0
  return parseFloat(average ? average : 0).toFixed(2);
}

export const getDaysInMonthArray = (date) => {
  let days = [];
  var x = getDaysInMonth(date);

  for (let index = 0; index < x; index++) {
    days.push(index + 1);
  }

  return days;
}

export const getPeriodValue = (value, months) => {
  return months[value - 1]
}

export const getWeeklyPeriodValue = (value, date) => {
  let dates = getISOWeeksInMonth(date);
  const lastMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
  const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
  const lastMonthDates = getISOWeeksInMonth(lastMonth);
  const nextMonthDates = getISOWeeksInMonth(nextMonth);
  dates = [lastMonthDates[lastMonthDates.length - 2], lastMonthDates[lastMonthDates.length - 1], ...dates, nextMonthDates[0], nextMonthDates[1]];

  return dates[value - 1]
}

export const getWeeklyPeriodKey = (value, date, year) => {
  let periodValue = getWeeklyPeriodValue(value, date);
  if (date.getMonth() === 0 && periodValue > 40) return `${parseInt(year) - 1}`;
  if (date.getMonth() === 11 && periodValue < 40) return `${parseInt(year) + 1}`;
  return year;
}


export const getCalculatedCurrentTarget = (targets, date, monthValues, dueDate) => {
  const month = getMonth(Date.parse(dueDate));
  const monthIndex = monthValues.indexOf(month + 1)

  const currentMonth = getMonth(date)
  const currIndex = monthValues.indexOf(currentMonth + 1)

  let climb;
  if(targets[0] > targets[1]) {
    climb = (targets[0] - targets[1]) / (monthIndex)
  } else if(targets[0] < targets[1]) {
    climb = (targets[1] - targets[0]) / (monthIndex)
  } else {
    climb = 0
  }

  if(currIndex < monthIndex) {
    if(targets[0] > targets[1]) {
      return parseFloat(targets[0] - (climb * currIndex)).toFixed(2)
    } else {
      return parseFloat(targets[0] + (climb * currIndex)).toFixed(2)
    }
  } else {
    return targets[1]
  }
}

export const getCalculatedDisplayWeek = (week, startWeek) => {
  if(!startWeek) return week
  if(week < startWeek) {
    return 52 - (startWeek - week) + 1
  } else {
    return week - startWeek + 1
  }
}
