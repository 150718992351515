import React, { useCallback, useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";


const PaginationComponent = (props) => {
  const [paginationItems, setPaginationItems] = useState([])

  const onPageChange = (page) => props.setPage(page)


  const paginationLogic = useCallback(() => {
    const items = [];
    let itemRange = [1,10]
    const createPaginationItem = (i) => {
      return <Pagination.Item
        key={i}
        active={i === props.active}
        onClick={() => props.setPage(i)}
      >
        {i}
      </Pagination.Item>
    };
    if (props.active >= itemRange[1]) {
      itemRange = [(props.active - 5), (props.active + 5)]
    }
    if(itemRange[1] > props.total){
      itemRange[1] = props.total
    }
    for (let index = itemRange[0]; index <= itemRange[1]; index++) {
      items.push(createPaginationItem(index));
    }
    if(!(props.total >= itemRange[0] && props.total <= itemRange[1])){
      items.push(<Pagination.Ellipsis />);
    }
    setPaginationItems(items)
  }, [props, setPaginationItems])

  useEffect(() => { paginationLogic() }, [paginationLogic])


  return (
    <Pagination className="paginationInfo">
      <Pagination.Prev
        onClick={() => onPageChange(props?.active - 1)}
        disabled={props.active === 1}
      />
      {paginationItems}
      <Pagination.Next
        onClick={() => onPageChange(props.active + 1)}
        disabled={props.active === props?.total}
      />
    </Pagination>
  );
}

export default PaginationComponent;
