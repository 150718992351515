import React, { useState, useEffect, useCallback } from "react";
import Modal from 'react-modal';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { PERIOD_FREQUENCIES, frequencyProps, days, weeks } from "../TeamLeadershipRoutinesScreen/resources/enums";
import LabelComponent from "../../components/utils/getCompanyLabel";
import {createTaskTracker, updateTaskTracker} from "../../services/TaskCategoryService";
import {toast} from "react-toastify";

const getLabelText = key => <LabelComponent val={key}/>

const TrackerModal = ({ users, tasks, selectedTask, showModal, setShowModal, team, getAllTaskTrackers, date }) => {
    const [id, setId] = useState(null)
    const [selectedTaskTracker, setSelectedTaskTracker] = useState("")
    const [selectedUser, setSelectedUser] = useState("")
    const [selectedFrequency, setSelectedFrequency] = useState("")
    const [selectedDay, setSelectedDay] = useState([])
    const [selectedWeek, setSelectedWeek] = useState([])
    const [loading, setLoading] = useState(false)
    
    const reset = useCallback(
        () => {
            setId("");
            setSelectedUser("");
            setSelectedFrequency("");
            setSelectedTaskTracker("");
            setSelectedDay([]);
            setSelectedWeek([]);
            setLoading(false);
        },
        [setId, setSelectedUser, setSelectedFrequency, setSelectedTaskTracker, setSelectedDay, setSelectedWeek, setLoading]
    );

    useEffect(() => {
        if(!selectedTask) return;
        console.log(selectedTask);
        setId(selectedTask.id);
        setSelectedUser(selectedTask.user.id);
        setSelectedFrequency(selectedTask.frequency);
        setSelectedTaskTracker(selectedTask.task.id);
        setSelectedDay(selectedTask.days && selectedTask.days !== "" ? selectedTask.days.split(",") : []);
        setSelectedWeek(selectedTask.weeks && selectedTask.weeks !== "" ? selectedTask.weeks.split(",") : []);
    }, [selectedTask, users, setSelectedUser, setSelectedFrequency, setSelectedTaskTracker, setId, setSelectedDay, setSelectedWeek]);

    const close = useCallback(
        () => {
            reset();
            setShowModal(false);
        },
        [reset, setShowModal]
    );
    
    const onSave = async () => {
        setLoading(true);
        let response = {};
        if(id) {
            response = await updateTaskTracker(id, selectedUser, team.id);
        } else {
            response = await createTaskTracker(selectedUser, team.id, selectedTaskTracker, selectedFrequency, selectedWeek, selectedDay);
        }

        if(response.data?.createTaskTracker?.id || response.data?.updateTaskTracker?.id) {
            toast.success(`Task Tracker ${id ? "Updated" : "Created"} Successfully`);
        } else {
            toast.error("Something Went Wrong");
        }

        reset();

        close();

        await getAllTaskTrackers(date);
    };

    const isValid = () => {
        let valid = true;

        if(selectedFrequency !== "DAY") {
            if(!selectedDay.length) valid = false;
            if(selectedFrequency === "MONTH" && !selectedWeek.length) valid = false;
        }
        if(!selectedUser) valid = false;
        if(!selectedFrequency) valid = false;
        if(!selectedTaskTracker) valid = false;

        return valid;
    };
    const changeFrequency = (frequency) => {
        setSelectedFrequency(frequency);
        if(frequency !== "MONTH") setSelectedWeek([]);
        if(frequency === "DAY") setSelectedDay([]);
    }
    const toggleDaySelect = day => {
        if (selectedDay.includes(day)) return setSelectedDay(selectedDay.filter(d => d !== day));
        return setSelectedDay([...selectedDay, day]);
    }
    const toggleWeekSelect = week => {
        if (selectedWeek.includes(week)) return setSelectedWeek(selectedWeek.filter(wk => wk !== week));
        return setSelectedWeek([...selectedWeek, week]);
    }

    return (
        <Modal
            isOpen={showModal}
            onRequestClose={() => {
                reset();
                setShowModal(false);
            }}
            contentLabel="Result"
            className="standard-modal standard-modal-task-category"
        >
            <div className="d-flex mb-2">
                <div className="col-12 text-center">
                    <h6 className="cdi-blue-txt ml-0 mb-1">Task Tracker</h6>
                </div>
            </div>
            <div className="d-flex mb-2">
                <div className="col-12 text-center">
                    <select
                        className={"form-control cdi-ddl w-100 ml-0" + (id ? " disabled" : "")}
                        value={selectedTaskTracker}
                        onChange={({ target: { value } }) => setSelectedTaskTracker(value)}
                    >
                        <option value="" disabled>Select Task Category</option>
                        {tasks.map(({ id, task_name, category_name }) => {
                            return <option key={id} value={id}>{category_name.toUpperCase()} - {task_name.toUpperCase()}</option>
                        })}
                    </select>
                </div>
            </div>
            <div className="d-flex mb-2">
                <div className="col-12 text-center">
                    <select
                        className="form-control cdi-ddl w-100 ml-0"
                        value={selectedUser}
                        onChange={({ target: { value } }) => setSelectedUser(value)}
                        autoComplete="off"
                    >
                        <option value="" disabled>Select Member</option>
                        {users.map(({ id, firstNames, lastName }) => {
                            return <option key={id} value={id}>{firstNames} {lastName}</option>
                        })}
                    </select>
                </div>
            </div>
            <div className="d-flex mb-2">
                <div className="col-12 text-center">
                    <select
                        className={"form-control cdi-ddl w-100 ml-0" + (id ? " disabled" : "")}
                        value={selectedFrequency}
                        onChange={({ target: { value } }) => changeFrequency(value)}
                        autoComplete="off"
                    >
                        <option value="" disabled>Select Frequency</option>
                        {PERIOD_FREQUENCIES.map((frequency, i) => {
                            return <option key={i} value={frequency}>{frequencyProps[frequency].text}</option>
                        })}
                    </select>
                </div>
            </div>
            {(selectedFrequency === "WEEK" || selectedFrequency === "MONTH") ? (
                <div className="d-flex mb-1">
                    <div className="col-12 text-center">
                        <h6 className="cdi-blue-txt ml-0 mb-1">{getLabelText("when")}</h6>
                    </div>
                </div>
            ) : null}
            {selectedFrequency === "MONTH" ? (
                <div className={"d-flex flex-nowrap mb-2 justify-content-center" + (id ? " disabled" : "")}>
                    {weeks.map((week, i) => {
                        return (
                            <div
                                key={i}
                                className={
                                    "attendance-cell text-center font-weight-bold p-1 mr-2 cdi-blue-outline" +
                                    (selectedWeek.includes(week.value)
                                        ? " text-white bg-blue"
                                        : " color-blue")
                                }
                                onClick={() => toggleWeekSelect(week.value)}
                            >
                                {week.text}
                            </div>
                        );
                    })}
                </div>
            ) : null}
            {(selectedFrequency === "WEEK" || selectedFrequency === "MONTH") ? (
                <div className={"d-flex flex-nowrap justify-content-center" + (id ? " disabled" : "")}>
                    {days.map((day, i) => {
                        return (
                            <div
                                key={i}
                                className={
                                    "attendance-cell text-center font-weight-bold p-1 mr-2 cdi-blue-outline" +
                                    (selectedDay.includes(day.value)
                                        ? " text-white bg-blue"
                                        : " color-blue")
                                }
                                onClick={() => toggleDaySelect(day.value)}
                            >
                                {getLabelText(day.key)}
                            </div>
                        );
                    })}
                </div>
            ) : null}
            <div className="d-flex mt-4">
                <div className="col-6">
                    <button className="btn btn-primary w-100" disabled={loading || !isValid()} onClick={() => onSave()}>
                        {loading
                            ? (
                                <>{getLabelText("saving")}... <FontAwesomeIcon icon={faSpinner} className="fa-spin ml-2"></FontAwesomeIcon></>
                            ) : (
                                <>{getLabelText("save")}</>
                            )
                        }
                    </button>
                </div>
                <div className="col-6">
                    <button className="btn btn-default cdi-blue-outline color-blue w-100" onClick={() => close()}>{getLabelText("cancel")}</button>
                </div>
            </div>
        </Modal>
    )
}

export default TrackerModal
