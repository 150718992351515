import {downloadWithMultipleSheetXLSX, downloadXLSX} from "./utils";
import {getCalculatedDisplayWeek} from "../screens/ProjectTracker/resources/utils";
import {mapCriteriaData, mapPeriodData} from "../screens/CoachingReviewScreen/resources/hooks";

export const getDownloadInnovationsData = async (rows) => {
    let result = [];

    rows.forEach((ele, idx) => {
        const nextStepCount = ele.actions.filter(action => action.status === "NEXT_STEP").length;
        const completedCount = ele.actions.filter(action => action.status === "COMPLETED").length;
        const totalNextSteps = ele.actions.filter(action => action.status !== "NONE").length;

        let NextStepStatus = "";
        if(ele.innovationStatus !== "STANDARDISED" && nextStepCount > 0 && completedCount !== totalNextSteps)
        {
            NextStepStatus = "Action In Progress";
        }
        else if (ele.innovationStatus !== "STANDARDISED" && (completedCount !== totalNextSteps || totalNextSteps === 0))
        {
            NextStepStatus = "Action Not Posted";
        }
        else if (ele.innovationStatus !== "STANDARDISED" && totalNextSteps > 0 && completedCount === totalNextSteps)
        {
            NextStepStatus = "Action Completed";
        }

        let innovationStatuses = {
            "NONE": "Planning In Progress",
            "PLANNED": "Implementation Is Planned",
            "IMPLEMENTED": "Plan Is Implemented",
            "ASSESSED": "Outcome Assessed",
            "STANDARDISED": "Method Is Standardised"
        };

        result.push({
            "Sr. No.": idx + 1,
            "Date": (new Date(ele.reviewDate)).toLocaleDateString(),
            "Creator": `${ele.creator.departmentName} (${ele.creator.teamName})`,
            "Innovation / Improvement Ideas": ele.whatText,
            "Suggested By": ele.suggestedBy.firstNames ? (ele.suggestedBy ? ele.suggestedBy.firstNames + " " + ele.suggestedBy.lastName : "-") : "-",
            "Approval Status": ele.innovationApproved,
            "Innovation Archived": ele.innovationArchived ? "Yes" : "No",
            "Progress Status": innovationStatuses[ele.innovationStatus],
            "Next Step Status": NextStepStatus
        })
    });

    /* downloadCSV(result, "Improvement Ideas"); */
    await downloadXLSX(result, "IMPROVEMENT IDEA", "Sheet", { "key": "Approval_Status", index: 6 }, { "key": "Next_Step", index: 9 });
}

export const downloadProblemSolvingData = async (rows) => {
    let result = [];

    rows.forEach((ele, idx) => {
        result.push({
            "Sr. No.": idx + 1,
            "Creator": `${ele.creator.departmentName} (${ele.creator.teamName})`,
            "Origin": ele.origin.measurement,
            "Date": (new Date(ele.reviewDate)).toLocaleDateString(),
            "Archived": ele.archived ? "Yes" : "No",
            "What Text": ele.whatText,
            "Q1": ele.actions.filter(action => action.actionType === 'FEEDBACK').map((act) => ` ${act.description} `).toString(),
            "Q2": ele.whyText,
            "Q3": ele.actions.filter(action => action.actionType === 'ROOT_CAUSE').map((act) => ` ${act.description} `).toString()
        })
    })

    await downloadXLSX(result, "PROBLEM SOLVING", "Sheet");
}

export const exportProjectTracker = async (data, startWeeks) => {
    let result = [];

    result.push({
        "Project Name": data.name,
        "From Date": (new Date(data.fromDate)).toLocaleDateString(),
        "To Date": (new Date(data.toDate)).toLocaleDateString(),
        "Milestones": []
    });

    data.milestones.forEach((ele) => {
        let NextStepStatus = '';
        if(ele.issue === null)
        {
            NextStepStatus = "Action Not Posted";
        }
        else if(ele.issue.actions.filter(action => action.status === "COMPLETED").length === ele.issue.actions.length)
        {
            NextStepStatus = "Action Completed";
        }
        else
        {
            NextStepStatus = "Action In Progress";
        }

        let ProjectProgress = "";
        if(ele.milestoneStates.length === 0)
        {
            ProjectProgress = "No Status";
        }
        else
        {
            if(ele.milestoneStates.sort((a, b) => a.key - b.key).filter((mile) => mile.state === 2).length)
            {
                const milestone = ele.milestoneStates.find(mile => mile.state === 2);

                let period = (new Date(ele.fromDate)).getFullYear();
                const week = startWeeks.find(ele => ele.period === `${period}${period+1}`);
                const displayWeek = getCalculatedDisplayWeek(ele.from + milestone.key, week.week);

                ProjectProgress = `Overdue: Week ${displayWeek}`;
            }
            else if(ele.milestoneStates.sort((a, b) => a.key - b.key).filter((mile) => mile.state === 1).length)
            {
                const milestone = ele.milestoneStates.find(mile => mile.state === 1);

                let period = (new Date(ele.fromDate)).getFullYear();
                const week = startWeeks.find(ele => ele.period === `${period}${period+1}`);
                const displayWeek = getCalculatedDisplayWeek(ele.from + milestone.key, week.week);
                
                ProjectProgress = `Completed On Time: Week ${displayWeek}`;
            }
        }

        result[0].Milestones.push({
            "Milestone": ele.name,
            "From Date": (new Date(ele.fromDate)).toLocaleDateString(),
            "To Date": (new Date(ele.toDate)).toLocaleDateString(),
            "Owner": ele.user.firstNames ? `${ele.user.firstNames} ${ele.user.lastName}` : "-",
            "Milestone Status": ele.archived ? "Archived" : "Not Archived",
            "Next Step Status": NextStepStatus,
            "Project Progress": ProjectProgress
        })
    });

   await downloadXLSX(result, "PROJECT TRACKER", "Sheet", { "key": "Next_Step", index: 6 }, {}, {"key": "Milestones"});
}

export const exportImplementationTracker = async (data, startWeeks) => {
    let result = [];

    result.push({
        "Implementation Module": data.name,
        "From Date": (new Date(data.fromDate)).toLocaleDateString(),
        "To Date": (new Date(data.toDate)).toLocaleDateString(),
        "Milestones": []
    });

    data.milestones.forEach((ele) => {
        let NextStepStatus = '';
        if(ele.issue === null)
        {
            NextStepStatus = "Action Not Posted";
        }
        else if(ele.issue?.actions.filter(action => action.status === "COMPLETED").length === ele.issue?.actions?.length)
        {
            NextStepStatus = "Action Completed";
        }
        else
        {
            NextStepStatus = "Action In Progress";
        }

        let ImplementationProgress = "";
        if(ele.milestoneStates.length === 0)
        {
            ImplementationProgress = "No Status";
        }
        else
        {
            if(ele.milestoneStates.sort((a, b) => a.key - b.key).filter((mile) => mile.state === 2).length)
            {
                const milestone = ele.milestoneStates.find(mile => mile.state === 2);

                let period = (new Date(ele.fromDate)).getFullYear();
                const week = startWeeks.find(ele => ele.period === `${period}${period+1}`);
                const displayWeek = getCalculatedDisplayWeek(ele.from + milestone.key, week.week);

                ImplementationProgress = `Overdue: Week ${displayWeek}`;
            }
            else if(ele.milestoneStates.sort((a, b) => a.key - b.key).filter((mile) => mile.state === 1).length)
            {
                const milestone = ele.milestoneStates.find(mile => mile.state === 1);

                let period = (new Date(ele.fromDate)).getFullYear();
                const week = startWeeks.find(ele => ele.period === `${period}${period+1}`);
                const displayWeek = getCalculatedDisplayWeek(ele.from + milestone.key, week.week);

                ImplementationProgress = `Completed On Time: Week ${displayWeek}`;
            }
        }

        result[0].Milestones.push({
            "Milestone": ele.name,
            "From Date": (new Date(ele.fromDate)).toLocaleDateString(),
            "To Date": (new Date(ele.toDate)).toLocaleDateString(),
            "Owner": ele.user.firstNames ? `${ele.user.firstNames} ${ele.user.lastName}` : "-",
            "Milestone Status": ele.archived ? "Archived" : "Not Archived",
            "Next Step Status": NextStepStatus,
            "Milestone Progress": ImplementationProgress
        })
    });

    await downloadXLSX(result, "IMPLEMENTATION TRACKER", "Sheet", { "key": "Next_Step", index: 6 }, {}, {"key": "Milestones"});
}

export const exportCoachingReview = async (data) => {
    let result = {};

    data.teamModules.forEach(module => {
        let res = [];

        let subjects = mapCriteriaData(module.subjects, data.monthValues);
        console.log(module);
        subjects.forEach((ele, idx) => {
            let item = {
                "Criteria No.": {
                    value: idx + 1
                },
                "Module": {
                    value: module.name,
                },
                "Criteria": {
                    value: ele.name
                }
            };

            let metric = {};
            ele.metrics.forEach(ele => {
                let date = new Date(ele.periodKey, ele.periodValue - 1, 1);
                const periodMetrics = mapPeriodData(module.periodMetrics, data.monthValues);
                metric[date.toLocaleDateString(undefined, {month: "long"})] = {
                    value: ele.value,
                    status: periodMetrics.find(e => parseInt(e.periodValue) === parseInt(ele.periodValue)).periodStatus
                };
            });

            item = {...item, ...metric};

            res.push(item);
        })
        result[module.name] = res;
    })

    await downloadWithMultipleSheetXLSX(result, "Coaching_Review");
}