import React, { useState, useEffect, useMemo, useCallback, Fragment, useRef } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import displayErrorToast from "../../components/utils/displayErrorToast";
import {
  addMonths
} from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
// icons
import Delete from '../../assets/ButtonIcons/Delete Blue.svg';
import Edit from '../../assets/ButtonIcons/Edit Blue.svg';
import Loader from '../../assets/ButtonIcons/Loader Blue.svg';

import PeriodCell from "./components/PeriodCell";
import RoutineCell from "./components/RoutineCell";
import { connect } from 'react-redux';
import { getDisabledDays, getFirstDay, getLastDay, getDateDay, isApplicableForWeekly, isApplicableForMonthly, getDateFromPeriod, getDaysInMonthArray, getMonthTextByIndex } from "./resources/hooks";
import { frequencyProps } from "./resources/enums";
import { GET_LEADERSHIP_ROUTINES_QUERY, GET_TEAM_LEADERSHIP_ROUTINES_QUERY, DELETE_TEAM_LEADERSHIP_ROUTINES_MUTATION } from "./resources/gql";
import classnames from "../../components/utils/classnames";
import MonthSelect from "../../components/MonthSelect";
import RoutineModal from './components/Modal';
import IconButton from "../../components/shared/IconButton";
import SubHeaderComponent from "../../components/SubHeaderComponent";
import RealtimeRefreshComponent from "../../components/RealtimeRefreshComponent";
import Legend from "./components/Legend";
import { Redirect } from "react-router-dom";
import LabelComponent from "../../components/utils/getCompanyLabel";
import * as DefaultLabels from '../../assets/glossary.json';
import FilterModal from "./components/FilterModal";
import {isEmpty} from "lodash";

import Excel from 'exceljs';
import { saveAs } from 'file-saver';

const labels = DefaultLabels.default;
const workSheetName = 'Worksheet-1';

const getLabelText = key => <LabelComponent val={key}/>

const TeamLeadershipRoutinesScreen = ({ selectedTeam, user, togglePageLoad, refresh, hasNewData, toggleRefresh, toggleHasNewData }) => {
  const { data, loading, error } = useQuery(GET_LEADERSHIP_ROUTINES_QUERY);
  const [routines, setRoutines] = useState([]);
  const [teamRoutines, setTeamRoutines] = useState([]);
  const [periodMetrics, setPeriodMetrics] = useState([]);
  const [showModal, setShowModal] = useState(false)
  const [selectedRoutine, setSelectedRoutine] = useState(null);
  const [date, setDate] = useState(new Date());
  const [nextDate, setNextDate] = useState(date);
  const [disabledDays, setDisabledDays] = useState(new Set());
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [redir, setRedir] = useState(null);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filters, setFilters] = useState({
                                  userId: null,
                                  frequency: null,
                                  leadershipRoutine: null,
                                })
  const headerRef = useRef();
  const scrollRef = useRef();

  const onRoutinesLoadComplete = useCallback(
    ({ leadershipRoutines }) => {
      setRoutines(leadershipRoutines)
    },
    [setRoutines]
  );

  const onError = (error) => {
    displayErrorToast(error);
  };

  useEffect(() => {
    if(loading) return;
    if(data) onRoutinesLoadComplete(data)
    if(error) onError(error)
  },[loading, data, error, onRoutinesLoadComplete]);

  useEffect(() => {
    if(selectedTeam.id !== 0) {
      if(selectedTeam.department.departmentType.level === "LVL1") {
        setRedir("/what/team-attendance")
        return
      }
    }
  }, [setRedir, selectedTeam])

  const onTeamRoutinesLoadComplete = useCallback(
    ({ team: { teamLeadershipRoutines, routinePeriodMetrics, combinedMembers, coach } }) => {
      setTeamRoutines(teamLeadershipRoutines)
      setPeriodMetrics(routinePeriodMetrics)
      setDisabledDays(getDisabledDays(routinePeriodMetrics))
      setUsers([coach, ...combinedMembers ])
      setDate(nextDate)
      if(refresh) {
        toggleRefresh()
        toggleHasNewData()
      }
      setIsLoading(false)
      togglePageLoad(false)
    },
    [setTeamRoutines, setPeriodMetrics, setUsers, setDisabledDays, refresh, toggleRefresh, toggleHasNewData, setIsLoading, togglePageLoad, nextDate, setDate]
  );

  const [getTeamRoutines] = useLazyQuery(GET_TEAM_LEADERSHIP_ROUTINES_QUERY, {
    variables: {
      id: selectedTeam.id,
      from: getFirstDay(nextDate),
      to: getLastDay(nextDate)
    },
    onCompleted: onTeamRoutinesLoadComplete,
  });

  const getText = val => {
    const companyId = localStorage.getItem("companyId");
    let lang = "EN";
    let langLabels = labels.find(lbl => lbl.lang === lang);
    let def = langLabels.glossary.find(label => label.key === val);

    if(user && user.companies && user.companies.length) {
      const company = user.companies.find(company => company.id === companyId);
      if(company) {
        lang = company.language;
        langLabels = labels.find(lbl => lbl.lang === lang);
        def = langLabels.glossary.find(label => label.key === val);
        if(!def) {
          lang = "EN";
          langLabels = labels.find(lbl => lbl.lang === lang);
          def = langLabels.glossary.find(label => label.key === val);
        }
      }
      if(company && company.companyGlossaries) {
        const custom = company.companyGlossaries.find(label => label.key === val);

        if(custom) return custom.value
      }
    }
    return def.value
  }

  const [removeRoutine] = useMutation(DELETE_TEAM_LEADERSHIP_ROUTINES_MUTATION, {
    onCompleted: (data) => {
      setTeamRoutines(teamRoutines.filter(({ id }) => id !== deleteId))
      setDeleteId(null)
      setDeleting(false)
    }
  })

  useEffect(() => {
    togglePageLoad(true)
    if (selectedTeam.id === 0) return;
    getTeamRoutines()
  }, [nextDate, selectedTeam, getTeamRoutines, togglePageLoad]);

  const getPeriodMetric = period => {
    const metric = periodMetrics.find(metric => getDateDay(metric.date) === period)

    return metric ? metric : {
      id: null,
      status: "NORMAL",
      period: "DAY",
      date: getDateFromPeriod(period, date)
    }
  }

  const getRoutineMetric = (period, id) => {
    const routine = teamRoutines.find(routine => routine.id === id)
    const metric = routine.metrics.find(metric => getDateDay(metric.date) === period)
    return metric ? metric : {
      id: null,
      status: "SCHEDULED",
      date: getDateFromPeriod(period, date),
      user: users[0],
      routine: routines[0]
    }
  }

  const onPeriodCellChange = ({ index: day, state }) => {
    if (state === 2) {
      const nextSet = new Set(disabledDays);
      nextSet.add(day);
      setDisabledDays(nextSet);
    } else {
      const nextSet = new Set(disabledDays);
      nextSet.delete(day);
      setDisabledDays(nextSet);
    }
  };

  const changeMonth = (delta) => {
    setNextDate(addMonths(date, delta));
  };

  const periodArray = useMemo(
    () => [...getDaysInMonthArray(date)],
    [date]
  );

  const addRoutine = () => {
    setSelectedRoutine({
      id: null,
      user: users[0],
      routine: routines[0],
      frequency: "DAY",
      weeks: "",
      days: ""
    })

    setShowModal(true)
  }

  const editRoutine = routine => {
    setSelectedRoutine(routine)
    setShowModal(true)
  }

  const deleteRoutine = id => {
    setDeleteId(id)
    setDeleting(true)
    removeRoutine({
      variables: { id }
    })
  }

  const isMetricApplicable = (routine, periodDate) => {
    switch(routine.frequency) {
      case "DAY":
        return true
      case "WEEK":
        return isApplicableForWeekly(routine.days, periodDate)
      default:
        return isApplicableForMonthly(routine.weeks, routine.days, periodDate, date)
    }
  }

  const getFilteredRoutines = () => {
    let filteredRoutines = teamRoutines;
    const { userId, frequency, leadershipRoutine } = filters;

    if(!isEmpty(userId)) {
      filteredRoutines = filteredRoutines.filter(routine => routine.user.id === userId)
    }

    if(!isEmpty(frequency)) {
      filteredRoutines = filteredRoutines.filter(routine => routine.frequency === frequency)
    }

    if(!isEmpty(leadershipRoutine)) {
      filteredRoutines = filteredRoutines.filter(routine => routine.routine.id === leadershipRoutine)
    }

    return filteredRoutines
  }

  const handleScroll = (isFirst) => {
    if (headerRef.current && scrollRef.current) {
      if(isFirst)
      {
        const scrollLeft = headerRef.current.scrollLeft;
        scrollRef.current.scrollLeft = scrollLeft;
      }
      else
      {
        const scrollLeft = scrollRef.current.scrollLeft;
        headerRef.current.scrollLeft = scrollLeft;
      }
    }
  };

  if(redir) {
    return <Redirect to={redir} />
  }

  const getRoutineStatus = (period, routine) => {
    let routineMetric = getRoutineMetric(period, routine.id);
    let metricApplicable = isMetricApplicable(routine, getDateFromPeriod(period, date));
    if (metricApplicable) {
      return routineMetric.status;
    }
    else {
      return "";
    }
  }

  const saveExcel = async () => {
    const workbook = new Excel.Workbook();
    try {

     let columns = [];
     columns.push({ header: "Leadership Routine" , key: "leadershipRoutine" });
     columns.push({ header: "Member" , key: "memberName" });
     columns.push({ header: "Frequency" , key: "frequency" });

      if (periodArray != null && periodArray.length > 0) {
        periodArray.forEach(period => {
          columns.push({ header: period, key: "status" + period  });
        })
      }

      let allTeamRoutines = [];
      let teamFilteredRoutines = getFilteredRoutines();
      if (teamFilteredRoutines != null && teamFilteredRoutines.length > 0) {
        teamFilteredRoutines.forEach(rtn => {
          let routineObject = {
            leadershipRoutine: rtn.routine.name, memberName: rtn.user.firstNames,
            frequency: frequencyProps[rtn.frequency].text,
          };

          periodArray.forEach(period => {
            routineObject["status"+ period] = getRoutineStatus(period, rtn);
          })
          allTeamRoutines.push(routineObject);
        });
      }

      let monthText =  getMonthTextByIndex(date.getMonth());
      let yearText = date.getFullYear()
      let teamName;
      if (selectedTeam.id !== 0) {
        teamName = selectedTeam.name;
      }
      const fileName = teamName + "_" + monthText + "_" + yearText + "_Leadership_Routines";
      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(workSheetName);
  
      // add worksheet columns
      // each columns contains header and its mapping key from data
      worksheet.columns = columns;
  
      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true };
  
      // loop through all of the columns and set the alignment with width.
      worksheet.columns.forEach(column => {
        column.width = 15;
        column.alignment = { horizontal: 'left' };
      });
  
      allTeamRoutines.forEach(routine => {
        worksheet.addRow(routine);
      });
  
      // loop through all of the rows and set the outline style.
      worksheet.eachRow({ includeEmpty: false }, row => {
        // store each cell to currentCell
        const currentCell = row._cells;
  
        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach(singleCell => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;
  
          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        });
      });
  
      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();
  
      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error('Error saving file - ', error.message);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
    }
  };


  return (
    <Fragment>
      <SubHeaderComponent>
        <Col className="text-right childenSpace">
          <div className={"refreshBtn"}>
            <RealtimeRefreshComponent
              refresh={refresh}
              hasNewData={hasNewData}
              toggleRefresh={toggleRefresh}
            />
          </div>
          <Button className={"btn btn-primary btn-nrml ml-2"} onClick={() => addRoutine()}>{getLabelText("add")}<FontAwesomeIcon className="ml-2" icon={faPlus} /></Button>
          <Button className="ml-2" onClick={(e) => saveExcel()}>Export</Button>
        </Col>
      </SubHeaderComponent>
      <div className="grey-header-space container-left-plus20">
        <Row className="mb-5 mr-1 overflow-auto">
          {isLoading && (
              <Fragment>
                <div className="page-loader">
                  <div className="loader-box text-center">
                    <FontAwesomeIcon className="text-white fa-spin fa-3x" icon={faSpinner} />
                    <p className="text-white mt-4"><strong>Loading</strong></p>
                  </div>
                </div>
              </Fragment>
          )}

          {teamRoutines.length ? (
              <div className="project-tracker-container w-100">
                <div className={"row overflow-hidden align-items-center w-100 mb-3" + (isLoading ? " disabled" : "")} ref={headerRef} onScroll={() => handleScroll(true)}>
                  <Col xs={4}>
                    <table className="w-100">
                      <tbody>
                      <th width="70%"><MonthSelect date={date} onChange={changeMonth} /></th>
                      <th width="30%">
                        <Button className={"btn btn-primary btn-nrml ml-2 w-100"} onClick={() => setShowFilterModal(true)}>{getLabelText("filter")}<FontAwesomeIcon className="ml-2" icon={faSearch} /></Button>
                      </th>
                      </tbody>
                    </table>
                  </Col>
                  <Col xs={8}>
                    <Row className="flex-nowrap">
                      {teamRoutines.length ?
                          periodArray.map((period, i) => (
                              <PeriodCell
                                  result={getPeriodMetric(period)}
                                  teamId={selectedTeam.id}
                                  key={period}
                                  day={period}
                                  today={date}
                                  onCellChange={onPeriodCellChange}
                                  index={i}
                              />
                          )) : null}
                        </Row>
                  </Col>
                </div>
                <div style={{maxHeight: "62.5vh", overflow: "scroll"}} className="w-100" ref={scrollRef} onScroll={() => handleScroll(false)}>
                  {teamRoutines.length ? (
                      getFilteredRoutines().map((routine, i) => (
                          <Row
                              className={classnames(
                                  "w-100 mb-2",
                                  isLoading && "disabled"
                              )}
                              key={`${routine.id}_${date}`}
                          >
                            <Col xs={4}>
                              <table className="w-100">
                                <tbody>
                                <td width="40%">{(i + 1) + ".  " + routine.routine.name}</td>
                                <td width="20%">{routine.user.firstNames}</td>
                                <td width="20%">{getLabelText(frequencyProps[routine.frequency].text.toLowerCase())}</td>
                                <td width="20%">
                                  <IconButton tooltip={getLabelText("edit")} eventHandler={() => editRoutine(routine)}>
                                    <img src={Edit} alt="Edit" className="btn-icons"/>
                                  </IconButton>
                                  <IconButton tooltip={getLabelText("delete")} eventHandler={() => deleteRoutine(routine.id)}>
                                    {deleting && deleteId === routine.id
                                        ? <img src={Loader} alt="Loader" className="loader-spinner"/>
                                        : <img src={Delete} alt="Delete" className="btn-icons"/>
                                    }
                                  </IconButton>
                                </td>
                                </tbody>
                              </table>
                            </Col>
                            <Col xs={8}>
                              <Row className="flex-nowrap">
                                {periodArray.map((period) => (
                                    <RoutineCell
                                        key={period}
                                        day={period}
                                        today={date}
                                        routineId={routine.id}
                                        disabledDays={disabledDays}
                                        result={getRoutineMetric(period, routine.id)}
                                        colour={selectedTeam.company.color}
                                        isMetricApplicable={isMetricApplicable(routine, getDateFromPeriod(period, date))}
                                    />
                                ))}
                                <div style={{minWidth: "0.5rem"}} />
                              </Row>
                            </Col>
                          </Row>
                      ))
                  ) : null}
                </div>
              </div>
          ) : (
              <Row>
                <Col className="text-center">
                  <label className="cdi-blue-txt ml-0">{getLabelText("no_routines_error")}</label>
                </Col>
              </Row>
          )}
        </Row>
        <Legend />
        <RoutineModal
            users={users}
            routines={routines}
            selectedRoutine={selectedRoutine}
            teamRoutines={teamRoutines}
            setTeamRoutines={setTeamRoutines}
            showModal={showModal}
            setShowModal={setShowModal}
            teamId={selectedTeam.id}
            getText={getText}
        />
        <FilterModal
            users={users}
            routines={routines}
            selectedRoutine={selectedRoutine}
            teamRoutines={teamRoutines}
            setTeamRoutines={setTeamRoutines}
            showModal={showFilterModal}
            setShowModal={setShowFilterModal}
            getText={getText}
            filters={filters}
            setFilters={setFilters}
        />
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam,
  user: state.userReducer.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TeamLeadershipRoutinesScreen);
