function alphabetizeUsers(array) {

  return array.sort(function(a, b) {
    var textA = a.firstNames.toUpperCase();
    var textB = b.firstNames.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
}

const mapAPIData = (data) => {
  const { team } = data

  const allUsers = [...alphabetizeUsers(team.leaders), ...alphabetizeUsers(team.members)];

  if (team.coach) {

    if(allUsers.find(user => user.id === team.coach.id)) {
      const index = allUsers.findIndex(user => user.id === team.coach.id)

      allUsers.splice(index, 1)
    }

    allUsers.push({ ...team.coach, isCoach: true });
  }

  const users = allUsers;


  return { team, users };
};

export default mapAPIData;
