import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isDescendant } from 'react-sortable-tree';
import classnames from './utils/classnames';
// Bootstrap
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap"

// icons
import LabelComponent from "./utils/getCompanyLabel";
import Add from '../assets/ButtonIcons/Add Blue.svg';
import Edit from '../assets/ButtonIcons/Edit Blue.svg';
import Delete from '../assets/ButtonIcons/Delete Blue.svg';
import Save from '../assets/ButtonIcons/Save Blue.svg';
import Cancel from '../assets/ButtonIcons/Cancel Blue.svg';

function renderTooltip(props, text) {
  return (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );
}

const NodeRow = ({ node, ...rest }) => {
  const {
    nodeId,
    setEdit,
    mode,
    cancel,
    setCreate,
    name,
    handleChange,
    types,
    type,
    shiftCount,
    submit,
    deleteItem,
    isHandlingRequest,
    path,
    buttonsDisabled,
    canEdit,
    getText
  } = rest

  const getLabelText = key => <LabelComponent val={key}/>

  if ((nodeId === node.id && mode === "edit") || (node.id === 0 && mode === "create")) {
    return (
      <tr>
        <td width="30%">
          <small className="cdi-blue-txt bold" style={{ fontSize: "80%" }}>{getLabelText('department_name')}</small>
          <input
            autoFocus={true}
            disabled={isHandlingRequest}
            className="form-control"
            type="text"
            value={name}
            name="name"
            onChange={(e) => handleChange(e)}
            autoComplete="off"
          />
        </td>
        <td width="70%" className="structure-row">
          <div className="structure-team-type">
            <small className="cdi-blue-txt bold" style={{ fontSize: "80%" }}>{getLabelText('structure_team_type')}</small>
            <select
              value={type}
              className="form-control"
              name="type"
              onChange={(e) => handleChange(e)}
              disabled={isHandlingRequest}
            >
              <option value="" default disabled>{getText('structure_team_type_select')}</option>
              {types.map((type) => (
                <option key={type.id} value={type.id}>{type.name}</option>
              ))}
            </select>
          </div>
          <div className="structure-shift">
            <small className="cdi-blue-txt bold" style={{ fontSize: "80%" }}>{getLabelText('num_of_shifts')}</small>
            <input
              disabled={isHandlingRequest}
              className="form-control"
              type="number"
              min="1"
              value={shiftCount}
              name="shiftCount"
              onChange={(e) => handleChange(e)}
              autoComplete="off"
            />
          </div>
          <div className="text-right pt-2 structure-btn">
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, getLabelText('save'))}
            >
              <Button disabled={isHandlingRequest || buttonsDisabled} className="btn-icons-container" onClick={() => submit(path)}>
                <img src={Save} alt="Save" className="btn-icons"/>
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, getLabelText('cancel'))}
            >
              <Button disabled={isHandlingRequest || buttonsDisabled} className="btn-icons-container" onClick={cancel}>
                <img src={Cancel} alt="Cancel" className="btn-icons"/>
              </Button>
            </OverlayTrigger>
          </div>
        </td>
      </tr>
    )
  } else {
    return (
      <tr>
        <td valign="middle" width="50%" className="pt-2">
          <OverlayTrigger
            placement="left"
            delay={{ show: 25, hide: 40 }}
            overlay={(props) => renderTooltip({...props}, node.name)}
          >
          <p className="cdi-blue-txt">{node.name}</p>
          </OverlayTrigger>
        </td>
        <td valign="middle" width="50%" className="pt-2 structure-row">
          <div className="structure-team-type">
            {!node.isCompany
              ? <p className="cdi-blue-txt">{node.departmentType ? node.departmentType.name : getLabelText('no_data')}</p> : null
            }
          </div>
          <div className="structure-shift">
            {!node.isCompany
              ? <p className="cdi-blue-txt">{getLabelText('shifts')}: { node.shiftCount }</p> : null
            }
          </div>
          {canEdit && (
          <div className="text-right pt-2 structure-btn">
            {node.children
              ? (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 25, hide: 40 }}
                  overlay={(props) => renderTooltip({...props}, getLabelText('add'))}
                >
                  <Button disabled={isHandlingRequest || buttonsDisabled ||mode !== ""} className="btn-icons-container" onClick={() => setCreate(node.id)}>
                    <img src={Add} alt="Add" className="btn-icons"/>
                  </Button>
                </OverlayTrigger>
              ) : null
            }
            {!node.isCompany
              ? (
                <>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 25, hide: 40 }}
                    overlay={(props) => renderTooltip({...props}, getLabelText('edit'))}
                  >
                    <Button disabled={isHandlingRequest || buttonsDisabled ||mode !== ""} className="btn-icons-container" onClick={() => setEdit(node.id, node.departmentType ? node.departmentType.id : "" )}>
                      <img src={Edit} alt="Edit" className="btn-icons"/>
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 25, hide: 40 }}
                    overlay={(props) => renderTooltip({...props}, getLabelText('delete'))}
                  >
                    <Button disabled={isHandlingRequest || buttonsDisabled || mode !== ""} className="btn-icons-container" onClick={() => {
                      deleteItem(node.id, path)
                    }}>
                      <img src={Delete} alt="Delete" className="btn-icons"/>
                    </Button>
                  </OverlayTrigger>
                </>
              ) : null
            }
          </div>
          )}
        </td>
      </tr>
    )
  }
}

class NodeRendererDefault extends Component {
  render() {
    const {
      scaffoldBlockPxWidth,
      toggleChildrenVisibility,
      connectDragPreview,
      connectDragSource,
      isDragging,
      canDrop,
      canDrag,
      node,
      draggedNode,
      path,
      treeIndex,
      isSearchMatch,
      isSearchFocus,
      buttons,
      className,
      style,
      didDrop,
      rowDirection
    } = this.props;
    const rowDirectionClass = rowDirection === 'rtl' ? 'rst__rtl' : null;

    let handle;
    if (canDrag) {
      if (typeof node.children === 'function' && node.expanded) {
        // Show a loading symbol on the handle when the children are expanded
        //  and yet still defined by a function (a callback to fetch the children)
        handle = (
          <div className="rst__loadingHandle">
            <div className="rst__loadingCircle">
              {[...new Array(12)].map((_, index) => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className={classnames(
                    'rst__loadingCirclePoint',
                    rowDirectionClass
                  )}
                />
              ))}
            </div>
          </div>
        );
      } else {
        // Show the handle used to initiate a drag-and-drop
        handle = connectDragSource(
            <div className="rst__moveHandle" style={{
              backgroundColor: node.redColor ? "#D70000" : null,
            }}
            />, {
          dropEffect: 'copy',
        });
      }
    }

    const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
    const isLandingPadActive = !didDrop && isDragging;

    let buttonStyle = { left: -0.5 * scaffoldBlockPxWidth };
    if (rowDirection === 'rtl') {
      buttonStyle = { right: -0.5 * scaffoldBlockPxWidth };
    }

    return (
      <div style={{ height: '100%' }}>
        {toggleChildrenVisibility &&
          node.children &&
          (node.children.length > 0 || typeof node.children === 'function') && (
            <div>
              <button
                type="button"
                aria-label={node.expanded ? 'Collapse' : 'Expand'}
                className={classnames(
                  node.expanded ? 'rst__collapseButton' : 'rst__expandButton',
                  rowDirectionClass
                )}
                style={buttonStyle}
                onClick={() =>
                  toggleChildrenVisibility({
                    node,
                    path,
                    treeIndex,
                  })
                }
              />

              {node.expanded && !isDragging && (
                <div
                  style={{ width: scaffoldBlockPxWidth }}
                  className={classnames('rst__lineChildren', rowDirectionClass)}
                />
              )}
            </div>
          )}

        <div className={classnames('rst__rowWrapper', rowDirectionClass)}>
          {/* Set the row preview to be used during drag and drop */}
          {connectDragPreview(
            <div
              className={classnames(
                'rst__row',
                isLandingPadActive && 'rst__rowLandingPad',
                isLandingPadActive && !canDrop && 'rst__rowCancelPad',
                isSearchMatch && 'rst__rowSearchMatch',
                isSearchFocus && 'rst__rowSearchFocus',
                rowDirectionClass,
                className
              )}
              style={{
                opacity: isDraggedDescendant ? 0.5 : 1,
                ...style,
              }}
            >
              {handle}

              <div
                className={classnames(
                  'rst__rowContents',
                  !canDrag && 'rst__rowContentsDragDisabled',
                  rowDirectionClass
                )}
              >
                <div className={classnames('rst__rowLabel', rowDirectionClass)}>
                  <table className="department-node-row" style={{ marginTop: "-10px" }}>
                    <tbody>
                      <NodeRow {...this.props} />
                    </tbody>
                  </table>
                </div>

                <div className="rst__rowToolbar">
                  {buttons.map((btn, index) => (
                    <div
                      key={index} // eslint-disable-line react/no-array-index-key
                      className="rst__toolbarButton"
                    >
                      {btn}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

NodeRendererDefault.defaultProps = {
  isSearchMatch: false,
  isSearchFocus: false,
  canDrag: false,
  toggleChildrenVisibility: null,
  buttons: [],
  className: '',
  style: {},
  parentNode: null,
  draggedNode: null,
  canDrop: false,
  title: null,
  subtitle: null,
  rowDirection: 'ltr',
};

NodeRendererDefault.propTypes = {
  node: PropTypes.shape({}).isRequired,
  title: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  path: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  treeIndex: PropTypes.number.isRequired,
  treeId: PropTypes.string.isRequired,
  isSearchMatch: PropTypes.bool,
  isSearchFocus: PropTypes.bool,
  canDrag: PropTypes.bool,
  scaffoldBlockPxWidth: PropTypes.number.isRequired,
  toggleChildrenVisibility: PropTypes.func,
  buttons: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  style: PropTypes.shape({}),

  // Drag and drop API functions
  // Drag source
  connectDragPreview: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  parentNode: PropTypes.shape({}), // Needed for dndManager
  isDragging: PropTypes.bool.isRequired,
  didDrop: PropTypes.bool.isRequired,
  draggedNode: PropTypes.shape({}),
  // Drop target
  isOver: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool,

  // rtl support
  rowDirection: PropTypes.string,
};

export default NodeRendererDefault;
