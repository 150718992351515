import React, { Component, Fragment } from "react"
import { connect } from 'react-redux';
import Pusher from 'pusher-js';
import _ from 'lodash';
import classnames from "../components/utils/classnames";
// bootstrap
import { Container } from "react-bootstrap"
import { PrivateRoute } from "../components/AuthGuard.js";
import { Switch } from "react-router-dom";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// components
import HeaderComponent from "../components/HeaderComponent"
import ToastComponent from "../components/ToastComponent"
import FooterComponent from "../components/FooterComponent/FooterComponent";
import CompanyScreen from "../screens/CompanyScreen";
import SetupScreen from "../screens/SetupScreen";
import UserScreen from "../screens/UserScreen";
import StructureScreen from "../screens/StructureScreen";
import BusinessResultsScreen from "../screens/BusinessResultsScreen/Screen";
import InputUserScreen from "../screens/users/InputUserScreen";
import InputCompanyScreen from "../screens/company/InputCompanyScreen";
import InputGoalCategoryScreen from "../screens/goalCategory/InputGoalCategoryScreen";
import GoalsScreen from "../screens/GoalsScreen";
import MeasurementScreen from "../screens/MeasurementScreen";
import TeamIdentityScreen from "../screens/TeamIdentityScreen";
import TeamPartnersScreen from "../screens/TeamPartnersScreen/TeamPartnersScreen";
import TeamMeasuresScreen from "../screens/TeamMeasuresScreen";
import IndividualGoalsScreen from "../screens/IndividualGoalsScreen";
import GoalCategoryScreen from "../screens/GoalCategoryScreen";
import InputUnitScreen from "../screens/unit/InputUnitScreen";
import UnitScreen from "../screens/UnitScreen";
import TeamsScreen from "../screens/TeamsScreen";
import SkillsScreen from "../screens/SkillsScreen";
import SkillsMatrixScreen from "../screens/SkillsMatrixScreen/Screen";
import MeetingAttendanceScreen from "../screens/MeetingAttendanceScreen/Screen";
import IndividualResultsScreen from "../screens/IndividualResultsScreen/Screen";
import CoachingReviewScreen from "../screens/CoachingReviewScreen/Screen";
import CoachingReviewDashboardScreen from "../screens/CoachingReviewDashboardScreen/Screen";
import DepartmentTypesScreen from "../screens/DepartmentTypesScreen/Screen";
import TeamGoalsScreen from "../screens/TeamGoalsScreen/Screen";
import CustomersScreen from "../screens/CustomerSupplier/CustomersScreen";
import SuppliersScreen from "../screens/CustomerSupplier/SuppliersScreen";
import ActionsScreen from "../screens/ActionsScreen";
import EscalationsScreen from "../screens/EscalationsScreen";
import ProblemSolvingScreen from "../screens/ProblemSolving/ProblemSolvingScreen";
import ImprovementIdeasScreen from "../screens/ImprovementIdeasScreen";
import CoachesFeedbackScreen from "../screens/CoachesFeedbackScreen";
import ValuesBehavioursScreen from "../screens/ValuesBehavioursScreen";
import TeamDashboardScreen from "../screens/TeamDashboardScreen";
import MemberDashboardScreen from "../screens/MemberDashboardScreen";
import MeetingViewScreen from "../screens/MeetingView/MeetingViewScreen"
import MultiLevelMeetingViewScreen from "../screens/MultiLevelMeetingView/MeetingViewScreen"
import ScheduledEventsScreen from "../screens/ScheduledEvents/Screen"
import ProjectTrackerScreen from "../screens/ProjectTracker/Screen"
import ReviewModulesScreen from "../screens/ReviewModulesScreen/Screen"
import RecognitionTypesScreen from "../screens/RecognitionTypesScreen/Screen"
import ReviewSubjectsScreen from "../screens/ReviewSubjectsScreen/Screen"
import TeamValuePartnersScreen from "../screens/TeamValuePartnersScreen"
import ValueStreamPartnersScreen from "../screens/ValueStreamPartnersScreen/Screen"
import LeadershipRoutinesScreen from "../screens/LeadershipRoutinesScreen/Screen"
import TeamLeadershipRoutinesScreen from "../screens/TeamLeadershipRoutinesScreen/Screen"
import TeamSuccessScreen from "../screens/TeamSuccessScreen/Screen";
import WelcomeScreen from "../screens/WelcomeScreen/Screen";
import DirtyPrompt from "../components/DirtyPrompt.js";
import * as Roles from '../assets/roles.json';
import LabelComponent from "../components/utils/getCompanyLabel";
import TaskCategory from "../screens/TaskCategory/TaskCategory";
import TaskTrackerScreen from "../screens/TaskCategory/TaskTrackerScreen";
import ImplementationModuleScreen from "../screens/ImplementationModule/ImplementationModuleScreen";
import ImplementaionTrackerScreen from "../screens/ImplementationTracker/ImplementationTrackerScreen";
import { PUSHER_ID } from "../redux/services/BaseService.js";

const getLabelText = key => <LabelComponent val={key}/>
// eslint-disable-next-line react/prop-types
class DashboardContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDirty: false,
      refresh: false,
      hasNewData: false,
      loading: false,
      additionalParam: null
    }

    this.toggleDirtyState = this.toggleDirtyState.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
  }

  componentDidMount() {
    /*
    let pusher = new Pusher(PUSHER_ID, {
      cluster: 'eu'
    });
    let self = this
    let channel = pusher.subscribe('my-channel');
    channel.bind('my-event', function (data) {
      const token = localStorage.getItem("token")
      const teamId = localStorage.getItem("teamId")
      if (!data.additionalParam) {
        if (token !== data.token && teamId === data.teamId && data.pathnames.includes(window.location.pathname)) {
          self.toggleHasNewData(true)
        }
      } else {
        if (token !== data.token && teamId === data.teamId && data.pathnames.includes(window.location.pathname) && data.additionalParam === self.state.additionalParam) {
          self.toggleHasNewData(true)
        }
      }
    });
    */
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.toggleDirtyState(true);
    }
  }

  toggleRefresh = (refresh = false) => {
    this.setState({
      refresh
    })
  }

  toggleHasNewData = (hasNewData = false) => {
    this.setState({
      hasNewData
    })
  }

  setAdditionalParam = (param) => {
    this.setState({
      additionalParam: param
    })
  }

  getRoleData(screen) {
    const roleData = Roles.default;


    const screenData = _.find(roleData, (data) => data.screen === screen);


    const permissions = {
      canView: false,
      canEdit: false,
    }

    if (screenData.canView.includes(this.props.user.role)) {
      permissions.canView = true;
    }

    if (screenData.canEdit.includes(this.props.user.role)) {
      permissions.canView = true;
      permissions.canEdit = true;
    }

    return permissions;
  }

  toggleDirtyState(reset = false) {
    const { isDirty } = this.state;
    if (reset) {
      this.setState({ isDirty: false });
    } else {
      this.setState({
        isDirty: !isDirty
      });
    }
  }

  toggleLoading(loading) {
    this.setState({
      loading
    })
  }

  render() {
    const { token, user, team } = this.props;
    const { companies } = user;
    const selectedCompanyId = localStorage.getItem("companyId");
    const company = companies ? companies.find(({ id }) => id === selectedCompanyId) : null;
    const { refresh, hasNewData } = this.state

    const height = (window.location.pathname === "/dashboard/structure") && " h-100";
    const display = this.state.loading && "d-none";
    const paddingTop = (window.location.pathname !== "/what/team-goals" &&
                        window.location.pathname !== "/what/individual-results" &&
                        window.location.pathname !== "/what/business-results") && "pt-50";
    const containerClasses = classnames(
      height,
      display,
      paddingTop,
      (!(this.props.presentation.active && !this.props.presentation.paused) ? "pb-50" : "")
    )
    const containerStyles = { paddingBottom: "110px" };

    return (
      <div>
        <HeaderComponent
          refresh={refresh}
          hasNewData={hasNewData}
          toggleRefresh={this.toggleRefresh}
        />
        <ToastComponent />
        <DirtyPrompt isDirty={this.state.isDirty} />
        {this.state.loading
          ? (
            <Fragment>
              <div className="overlay">
              </div>
              <div className="page-loader">
                <div className="loader-box text-center">
                  <FontAwesomeIcon className="text-white fa-spin fa-3x" icon={faSpinner} />
                <p className="text-white mt-4"><strong>{getLabelText("loading")}</strong></p>
                </div>
              </div>
            </Fragment>
          ) : null}

        <Container fluid className={containerClasses} id="cdiAppContainer"
          style={this.props.presentation.active && !this.props.presentation.paused ? containerStyles : {}}
        >
          <Switch>
            <PrivateRoute
              authenticated={token !== null}
              exact
              path="/now-what/actions"
              component={ActionsScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
              refresh={refresh}
              hasNewData={hasNewData}
              toggleRefresh={this.toggleRefresh}
              toggleHasNewData={this.toggleHasNewData}
            />
            <PrivateRoute
                authenticated={token !== null}
                exact
                path="/me/my-actions"
                component={ActionsScreen}
                toggleDirtyState={this.toggleDirtyState}
                togglePageLoad={this.toggleLoading}
                refresh={refresh}
                hasNewData={hasNewData}
                toggleRefresh={this.toggleRefresh}
                toggleHasNewData={this.toggleHasNewData}
            />
            {this.getRoleData("Company").canView && (
              <PrivateRoute
                authenticated={token !== null}
                exact
                permissions={this.getRoleData("Company")}
                path="/dashboard"
                component={CompanyScreen}
                toggleDirtyState={this.toggleDirtyState}
                togglePageLoad={this.toggleLoading}
              />
            )}
            <PrivateRoute
              authenticated={token !== null}
              exact
              path="/dashboard/setup"
              component={SetupScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
            />
            {this.getRoleData("Users").canView && (

              <PrivateRoute
                authenticated={token !== null}
                exact
                path="/dashboard/users"
                permissions={this.getRoleData("Users")}
                component={UserScreen}
                toggleDirtyState={this.toggleDirtyState}
                togglePageLoad={this.toggleLoading}
              />
            )}
            {this.getRoleData("Structure").canView && (
              <PrivateRoute
                authenticated={token !== null}
                exact
                path="/dashboard/structure"
                permissions={this.getRoleData("Structure")}
                component={StructureScreen}
                toggleDirtyState={this.toggleDirtyState}
                togglePageLoad={this.toggleLoading}
              />
            )}
            <PrivateRoute
              authenticated={token !== null}
              exact
              path="/dashboard/users/create"
              component={InputUserScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
            />
            <PrivateRoute
              authenticated={token !== null}
              exact
              path="/dashboard/users/:id"
              component={InputUserScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
            />
            <PrivateRoute
              authenticated={token !== null}
              exact
              permissions={this.getRoleData("Company")}
              path="/dashboard/company"
              component={InputCompanyScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
            />
          {user && company && company.hasUsers && company.hasMeasurements && this.getRoleData("TrueNorth").canView && (
              <PrivateRoute
                authenticated={token !== null}
                exact
                permissions={this.getRoleData("TrueNorth")}
                path="/dashboard/goals"
                component={GoalsScreen}
                toggleDirtyState={this.toggleDirtyState}
                togglePageLoad={this.toggleLoading}
              />
            )}
            {this.getRoleData("GoalCategories").canView && (
              <PrivateRoute
                authenticated={token !== null}
                exact
                permissions={this.getRoleData("GoalCategories")}
                path="/dashboard/goal-categories"
                component={GoalCategoryScreen}
                toggleDirtyState={this.toggleDirtyState}
                togglePageLoad={this.toggleLoading}
              />
            )}
            <PrivateRoute
              authenticated={token !== null}
              exact
              permissions={this.getRoleData("GoalCategories")}
              path="/dashboard/goal-categories/create"
              component={InputGoalCategoryScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
            />
            <PrivateRoute
              authenticated={token !== null}
              exact
              permissions={this.getRoleData("GoalCategories")}
              path="/dashboard/goal-categories/:id"
              component={InputGoalCategoryScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
            />
            {this.getRoleData("Units").canView && (
              <PrivateRoute
                authenticated={token !== null}
                exact
                permissions={this.getRoleData("Units")}
                path="/dashboard/units"
                component={UnitScreen}
                toggleDirtyState={this.toggleDirtyState}
                togglePageLoad={this.toggleLoading}
              />
            )}
            <PrivateRoute
              authenticated={token !== null}
              exact
              permissions={this.getRoleData("Units")}
              path="/dashboard/units/create"
              component={InputUnitScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
            />
            <PrivateRoute
              authenticated={token !== null}
              exact
              permissions={this.getRoleData("Units")}
              path="/dashboard/units/:id"
              component={InputUnitScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
            />
            <PrivateRoute
              authenticated={token !== null}
              exact
              permissions={this.getRoleData("Skills")}
              path="/dashboard/skills"
              component={SkillsScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
            />
            {/*<PrivateRoute*/}
            {/*  authenticated={token !== null}*/}
            {/*  exact*/}
            {/*  permissions={this.getRoleData("Modules")}*/}
            {/*  path="/dashboard/modules"*/}
            {/*  component={ReviewModulesScreen}*/}
            {/*  toggleDirtyState={this.toggleDirtyState}*/}
            {/*  togglePageLoad={this.toggleLoading}*/}
            {/*/>*/}
            <PrivateRoute
              authenticated={token !== null}
              exact
              path="/dashboard/recognitions"
              component={RecognitionTypesScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
            />
            <PrivateRoute
              authenticated={token !== null}
              exact
              permissions={this.getRoleData("Criteria")}
              path="/dashboard/criteria"
              component={ReviewSubjectsScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
            />
            {this.getRoleData("ImplementationModule").canView && (
                <PrivateRoute
                    authenticated={token !== null}
                    exact
                    permissions={this.getRoleData("ImplementationModule")}
                    path="/dashboard/implementation-module"
                    component={ImplementationModuleScreen}
                    toggleDirtyState={this.toggleDirtyState}
                    togglePageLoad={this.toggleLoading}
                />
            )}
            {this.getRoleData("Measurements").canView && (
              <PrivateRoute
                authenticated={token !== null}
                exact
                permissions={this.getRoleData("Measurements")}
                path="/dashboard/measurements"
                component={MeasurementScreen}
                toggleDirtyState={this.toggleDirtyState}
                togglePageLoad={this.toggleLoading}
              />
            )}
            {this.getRoleData("TeamTypes").canView && (

              <PrivateRoute
                authenticated={token !== null}
                exact
                permissions={this.getRoleData("TeamTypes")}
                path="/dashboard/team-types"
                component={DepartmentTypesScreen}
                toggleDirtyState={this.toggleDirtyState}
                togglePageLoad={this.toggleLoading}
              />
            )}
            <PrivateRoute
              authenticated={token !== null}
              exact
              path="/teams/team-identity"
              permissions={this.getRoleData("TeamIdentity")}
              component={TeamIdentityScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
            />
            <PrivateRoute
              authenticated={token !== null}
              exact
              path="/teams/team-partners"
              permissions={this.getRoleData("TeamPartners")}
              component={TeamPartnersScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
            />
            <PrivateRoute
              authenticated={token !== null}
              exact
              path="/team/value-stream-partners"
              component={TeamValuePartnersScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
            />
          {user && team.id !== 0 && company && company.hasMeasurements && company.hasUsers && this.getRoleData("TeamMeasures").canView && (
              <PrivateRoute
                authenticated={token !== null}
                exact
                path="/teams/team-measures"
                permissions={this.getRoleData("TeamMeasures")}
                component={TeamMeasuresScreen}
                toggleDirtyState={this.toggleDirtyState}
                togglePageLoad={this.toggleLoading}
              />
            )}
            {user && team.id !== 0 && company && company.hasMeasurements && company.hasUsers && this.getRoleData("IndividualGoals").canView && (
              <PrivateRoute
                authenticated={token !== null}
                exact
                path="/teams/individual-goals"
                permissions={this.getRoleData("IndividualGoals")}
                component={IndividualGoalsScreen}
                toggleDirtyState={this.toggleDirtyState}
                togglePageLoad={this.toggleLoading}
              />
              )}
              <PrivateRoute
                authenticated={token !== null}
                exact
                path="/what/welcome"
                component={WelcomeScreen}
                togglePageLoad={this.toggleLoading}
              />
            {user && team.id !== 0 && company && company.hasUsers && this.getRoleData("MeetingView").canView && (
              <PrivateRoute
                authenticated={token !== null}
                exact
                permissions={this.getRoleData("MeetingView")}
                path="/dashboard/meeting-view"
                component={MeetingViewScreen}
                toggleDirtyState={this.toggleDirtyState}
                togglePageLoad={this.toggleLoading}
              />
              )}
            {user && team.id !== 0 && company && company.hasUsers && this.getRoleData("MeetingView").canView && (
              <PrivateRoute
                authenticated={token !== null}
                exact
                path="/dashboard/multi-level-meeting-view"
                permissions={this.getRoleData("MeetingView")}
                component={MultiLevelMeetingViewScreen}
                toggleDirtyState={this.toggleDirtyState}
                togglePageLoad={this.toggleLoading}
              />
              )}
            {this.getRoleData("LeadershipRoutines").canView && (
              <PrivateRoute
                authenticated={token !== null}
                exact
                permissions={this.getRoleData("LeadershipRoutines")}
                path="/dashboard/leadership-routines"
                component={LeadershipRoutinesScreen}
                toggleDirtyState={this.toggleDirtyState}
                togglePageLoad={this.toggleLoading}
              />
            )}
            {this.getRoleData("LeadershipRoutines").canView && (
                <PrivateRoute
                    authenticated={token !== null}
                    exact
                    permissions={this.getRoleData("LeadershipRoutines")}
                    path="/dashboard/task-list"
                    component={TaskCategory}
                    toggleDirtyState={this.toggleDirtyState}
                    togglePageLoad={this.toggleLoading}
                />
            )}
            {this.getRoleData("Teams").canView && (
              <PrivateRoute
                authenticated={token !== null}
                exact
                permissions={this.getRoleData("Teams")}
                path="/dashboard/teams"
                component={TeamsScreen}
                toggleDirtyState={this.toggleDirtyState}
                togglePageLoad={this.toggleLoading}
              />
            )}
            <PrivateRoute
              authenticated={token !== null}
              exact
              path="/what/team-attendance"
              component={MeetingAttendanceScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
              refresh={refresh}
              hasNewData={hasNewData}
              toggleRefresh={this.toggleRefresh}
              toggleHasNewData={this.toggleHasNewData}
            />
            <PrivateRoute
              authenticated={token !== null}
              exact
              path="/so-what/project-tracker"
              component={ProjectTrackerScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
              refresh={refresh}
              hasNewData={hasNewData}
              toggleRefresh={this.toggleRefresh}
              toggleHasNewData={this.toggleHasNewData}
            />
            <PrivateRoute
              authenticated={token !== null}
              exact
              path="/what/individual-results"
              component={IndividualResultsScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
              refresh={refresh}
              hasNewData={hasNewData}
              toggleRefresh={this.toggleRefresh}
              toggleHasNewData={this.toggleHasNewData}
            />
            {user && team.id !== 0 && company && company.hasReviewCriteria && (
              <PrivateRoute
                authenticated={token !== null}
                exact
                path="/what/coaching-reviews"
                component={CoachingReviewScreen}
                toggleDirtyState={this.toggleDirtyState}
                togglePageLoad={this.toggleLoading}
                refresh={refresh}
                hasNewData={hasNewData}
                toggleRefresh={this.toggleRefresh}
                toggleHasNewData={this.toggleHasNewData}
              />
            )}

            {user && team.id !== 0 && company && company.hasBusinessGoals && (
              <PrivateRoute
                authenticated={token !== null}
                exact
                path="/what/coaching-reviews-dashboard"
                component={CoachingReviewDashboardScreen}
                toggleDirtyState={this.toggleDirtyState}
                togglePageLoad={this.toggleLoading}
                refresh={refresh}
                hasNewData={hasNewData}
                toggleRefresh={this.toggleRefresh}
                toggleHasNewData={this.toggleHasNewData}
              />
            )}
            {user && team.id !== 0 && company && company.hasBusinessGoals && (
              <PrivateRoute
                authenticated={token !== null}
                exact
                path="/what/business-results"
                component={BusinessResultsScreen}
                toggleDirtyState={this.toggleDirtyState}
                togglePageLoad={this.toggleLoading}
                refresh={refresh}
                hasNewData={hasNewData}
                toggleRefresh={this.toggleRefresh}
                toggleHasNewData={this.toggleHasNewData}
                setAdditionalParam={this.setAdditionalParam}
              />
              )}
            {user && team.id !== 0 && company && company.hasSkills && (
              <PrivateRoute
                authenticated={token !== null}
                exact
                path="/what/skills-matrix"
                component={SkillsMatrixScreen}
                toggleDirtyState={this.toggleDirtyState}
                togglePageLoad={this.toggleLoading}
                refresh={refresh}
                hasNewData={hasNewData}
                toggleRefresh={this.toggleRefresh}
                toggleHasNewData={this.toggleHasNewData}
                setAdditionalParam={this.setAdditionalParam}
              />
            )}
            <PrivateRoute
              authenticated={token !== null}
              exact
              path="/what/team-goals"
              component={TeamGoalsScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
              refresh={refresh}
              hasNewData={hasNewData}
              toggleRefresh={this.toggleRefresh}
              toggleHasNewData={this.toggleHasNewData}
              setAdditionalParam={this.setAdditionalParam}
            />
            <PrivateRoute
              authenticated={token !== null}
              exact
              path="/what/customers"
              component={CustomersScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
              refresh={refresh}
              hasNewData={hasNewData}
              toggleRefresh={this.toggleRefresh}
              toggleHasNewData={this.toggleHasNewData}
            />
            <PrivateRoute
              authenticated={token !== null}
              exact
              path="/what/suppliers"
              component={SuppliersScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
              refresh={refresh}
              hasNewData={hasNewData}
              toggleRefresh={this.toggleRefresh}
              toggleHasNewData={this.toggleHasNewData}
            />
            <PrivateRoute
              authenticated={token !== null}
              exact
              path="/what/scheduled-events"
              component={ScheduledEventsScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
              refresh={refresh}
              hasNewData={hasNewData}
              toggleRefresh={this.toggleRefresh}
              toggleHasNewData={this.toggleHasNewData}
            />
            <PrivateRoute
              authenticated={token !== null}
              exact
              path="/what/value-stream-partners"
              component={ValueStreamPartnersScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
              refresh={refresh}
              hasNewData={hasNewData}
              toggleRefresh={this.toggleRefresh}
              toggleHasNewData={this.toggleHasNewData}
            />
          {user && team.id !== 0 && company && company.hasRoutines && (
            <PrivateRoute
              authenticated={token !== null}
              exact
              path="/what/leadership-routines"
              component={TeamLeadershipRoutinesScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
              refresh={refresh}
              hasNewData={hasNewData}
              toggleRefresh={this.toggleRefresh}
              toggleHasNewData={this.toggleHasNewData}
            />
          )}
          {user && team.id !== 0 && company && team.hasImplementationModule && (
              <PrivateRoute
                  authenticated={token !== null}
                  exact
                  path="/what/task-tracker"
                  component={TaskTrackerScreen}
                  toggleDirtyState={this.toggleDirtyState}
                  togglePageLoad={this.toggleLoading}
                  refresh={refresh}
                  hasNewData={hasNewData}
                  toggleRefresh={this.toggleRefresh}
                  toggleHasNewData={this.toggleHasNewData}
              />
          )}
            <PrivateRoute
                authenticated={token !== null}
                exact
                path="/what/implementation-tracker"
                component={ImplementaionTrackerScreen}
                toggleDirtyState={this.toggleDirtyState}
                togglePageLoad={this.toggleLoading}
                refresh={refresh}
                hasNewData={hasNewData}
                toggleRefresh={this.toggleRefresh}
                toggleHasNewData={this.toggleHasNewData}
            />
            <PrivateRoute
              authenticated={token !== null}
              exact
              path="/now-what/escalations"
              component={EscalationsScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
              refresh={refresh}
              hasNewData={hasNewData}
              toggleRefresh={this.toggleRefresh}
              toggleHasNewData={this.toggleHasNewData}
            />
            <PrivateRoute
              authenticated={token !== null}
              exact
              path="/so-what/problem-solving"
              component={ProblemSolvingScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
              refresh={refresh}
              hasNewData={hasNewData}
              toggleRefresh={this.toggleRefresh}
              toggleHasNewData={this.toggleHasNewData}
            />
            <PrivateRoute
              authenticated={token !== null}
              exact
              path="/so-what/improvement-ideas"
              component={ImprovementIdeasScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
              refresh={refresh}
              hasNewData={hasNewData}
              toggleRefresh={this.toggleRefresh}
              toggleHasNewData={this.toggleHasNewData}
            />
            <PrivateRoute
              authenticated={token !== null}
              exact
              path="/now-what/coaches-feedback"
              component={CoachesFeedbackScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
              refresh={refresh}
              hasNewData={hasNewData}
              toggleRefresh={this.toggleRefresh}
              toggleHasNewData={this.toggleHasNewData}
            />
          {user && team.id !== 0 && company && company.hasUsers && company.hasCompanyValues && (
            <PrivateRoute
              authenticated={token !== null}
              exact
              path="/what/values-behaviours"
              component={ValuesBehavioursScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
              refresh={refresh}
              hasNewData={hasNewData}
              toggleRefresh={this.toggleRefresh}
              toggleHasNewData={this.toggleHasNewData}
              setAdditionalParam={this.setAdditionalParam}
            />
            )}
            <PrivateRoute
              authenticated={token !== null}
              exact
              path="/now-what/team-dashboard"
              component={TeamDashboardScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
              refresh={refresh}
              hasNewData={hasNewData}
              toggleRefresh={this.toggleRefresh}
              toggleHasNewData={this.toggleHasNewData}
            />
            <PrivateRoute
              authenticated={token !== null}
              exact
              path="/now-what/member-dashboard"
              component={MemberDashboardScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
              refresh={refresh}
              hasNewData={hasNewData}
              toggleRefresh={this.toggleRefresh}
              toggleHasNewData={this.toggleHasNewData}
              setAdditionalParam={this.setAdditionalParam}
            />
            <PrivateRoute
              authenticated={token !== null}
              exact
              path="/now-what/celebrating-success"
              component={TeamSuccessScreen}
              toggleDirtyState={this.toggleDirtyState}
              togglePageLoad={this.toggleLoading}
              refresh={refresh}
              hasNewData={hasNewData}
              toggleRefresh={this.toggleRefresh}
              toggleHasNewData={this.toggleHasNewData}
              setAdditionalParam={this.setAdditionalParam}
            />
          </Switch>

          {this.props.team.company.color === "Green"
            ? (
              <style dangerouslySetInnerHTML={{
                __html: `
              .color-selected {
                background-color: #28a745!important;
              }

              .font-color-selected {
                color: #28a745!important;
              }

              .skill-selected-color-border {
                border-color: #28a745!important;
              }

            `}} />
            ) : (
              <style dangerouslySetInnerHTML={{
                __html: `
                .color-selected {
                  background-color: #005e85!important;
                }

                .font-color-selected {
                  color: #005e85!important;
                }

                .border-color-selected {
                  border-color: #005e85!important;
                }

                .skill-selected-color-border {
                  border-color: #005e85!important;
                }
          `}} />
            )
          }
          {window.location.pathname === "/what/team-goals" || window.location.pathname === "/what/business-results"
            ? (
              <style dangerouslySetInnerHTML={{
                __html: `
              #root {
                background-color: #dadada!important;
              }
              #cdiAppContainer {
                background-color: #dadada!important;
              }
            `}} />
            ) : (
              <style dangerouslySetInnerHTML={{
                __html: `
                #root {
                  background-color: #fff!important;
                }

                #cdiAppContainer {
                  background-color: #fff!important;
                }
          `}} />
            )
          }
        </Container>

        <FooterComponent />
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  token: state.authenticationReducer.token,
  user: state.userReducer.user,
  team: state.teamsReducer.selectedTeam,
  presentation: state.presentationReducer
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
