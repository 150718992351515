import { client } from "./BaseService";
import { gql } from "apollo-boost";

export const getTeamData = async (id, selectedMonth) => {
  return await client.query({
    query: gql`query {
      teamMeasurements(teamId: "${id}", current: ${true}) {
        id
        frequency
        numberTo
        numberFrom
        dueDate
        dashboardMetricData(period: "${selectedMonth}", teamId: "${id}") {
          periodValue
          period
          status
        }
        measurement {
          id
          measurement
          unit {
            id
            name
          }
          goalCategory {
            id
            name
          }
        }
      }
    }`
  })
}

export const getTeamStats = async (id, selectedMonth) => {
  return await client.query({
    query: gql`query {
      team(id: "${id}") {
        id
        problemSolvingMonthCount(period: "${selectedMonth}")
        problemSolvingYearCount(period: "${selectedMonth}")
        innovationMonthCount(period: "${selectedMonth}")
        innovationMonthClosedCount(period: "${selectedMonth}")
        innovationYearCount(period: "${selectedMonth}")
        innovationYearClosedCount(period: "${selectedMonth}")
        nextStepsMonthCount(period: "${selectedMonth}")
        nextStepsMonthOnTimeCount(period: "${selectedMonth}")
        nextStepsMonthLateCount(period: "${selectedMonth}")
        nextStepsYearCount(period: "${selectedMonth}")
        nextStepsYearOnTimeCount(period: "${selectedMonth}")
        nextStepsYearLateCount(period: "${selectedMonth}")
        triggerMonthCount(period: "${selectedMonth}")
        triggerYearCount(period: "${selectedMonth}")
      }
    }`
  })
}

export const getStartWeeks = async () => {
  return await client.query({
    query: gql`query {
      companyStartWeeks {
        id
        period
        week
      }
    }`
  })
}
