function getYearList(startMonth, isValues) {
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const array = []
  const index = months.indexOf(startMonth)

  for (let i = index; i < months.length; i++) {
    array.push(isValues ? (i + 1) : months[i])
  }

  for (let i = 0; i < index; i++) {
    array.push(isValues ? (i + 1) : months[i])
  }

  return array
}

export const getNextIndex = (index, length, back) => {
  let nextIndex = 0;
  if(back) nextIndex = (index === 0 ? length - 1 : index - 1);
  else nextIndex =  index === (length - 1) ? 0 : index + 1;

  return nextIndex;
}

export const getPeriod = (startMonth) => {
  const months = getYearList(startMonth, true);
  const period = (new Date().getMonth() + 1) < months[0] ? `${new Date().getFullYear() - 1}${new Date().getFullYear()}` : `${new Date().getFullYear()}${new Date().getFullYear() + 1}`;

  return period;
}

export const getNextPeriod = (period, back) => {
  const startYear = back ? period.substr(0, 4) : period.substr(period.length - 4);
  return back ? `${parseInt(startYear) - 1}${startYear}` : `${startYear}${parseInt(startYear) + 1}`;
}

export const mapTileData = (successes, period, firstMonth) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  const monthValues = getYearList(firstMonth, true);
  const firstMonthValue = monthValues[0];
  const startYear = period.substr(0, 4);
  const endYear = period.substr(period.length - 4);
  return monthValues.map(val => {
    const success = successes.find(success => success.period === period && success.month === val);

    return {
      period,
      month: val,
      data: success,
      title: `${months[val - 1]} ${val < firstMonthValue ? endYear : startYear}`
    }
  });
}

export const checkLatestPeriod = (period, firstMonth) => {
  const monthValues = getYearList(firstMonth, true);
  const firstMonthValue = monthValues[0];
  const date = new Date();

  if(date.getMonth() < (firstMonthValue - 1))
    return parseInt(period.substr(period.length - 4)) === (new Date().getFullYear());

  return parseInt(period.substr(period.length - 4)) === (new Date().getFullYear() + 1);

}

export const getMonthIndex = (month) => {
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  return months.indexOf(month);
}

export const getMonthFromIndex = (monthIndex) => {
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  return months[monthIndex];
}
