import React from "react";
import LabelComponent from "../../components/utils/getCompanyLabel";

const getLabelText = key => <LabelComponent val={key}/>

const Legend = ({ active }) => (
  <div
    style={{ bottom: active ? "116px" : "58px" }}
    className="bg-light-grey d-flex p-2 justify-content-around fixed-bottom legend"
  >
      <div style={{ display:"inline-flex"}}>
        <div className="attendance-cell text-center bg-grey p-1 mr-2 text-white max-cell-width">
        </div>
        <div style = {{ paddingTop: "1.5%"}}>
          {getLabelText("not_applicable")}
        </div>
      </div>
      <div style={{ display:"inline-flex"}}>
        <div className="attendance-cell text-center font-weight-bold p-1 mr-2 bg-white text-white max-cell-width skill-border-lg skill-red-border">
        </div>
        <div style = {{ paddingTop: "1.5%"}}>
          {getLabelText("planned")}
        </div>
      </div>
      <div style={{ display:"inline-flex"}}>
        <div className="attendance-cell font-weight-bold p-1 mr-2 max-cell-width bg-color-negative">
        </div>
        <div style = {{ paddingTop: "1.5%"}}>
          {getLabelText("overdue")}
        </div>
      </div>
      <div style={{ display:"inline-flex"}}>
        <div className="attendance-cell text-center font-weight-bold p-1 mr-2 text-white bg-white max-cell-width skill-border-lg skill-selected-color-border">
        </div>
        <div style = {{ paddingTop: "1.5%"}} >
          {getLabelText("training_completed")}
        </div>
      </div>
      <div style={{ display:"inline-flex"}}>
        <div className="attendance-cell text-center font-weight-bold p-1 mr-2 max-cell-width color-selected">
        </div>
        <div style = {{ paddingTop: "1.5%"}}>
          {getLabelText("assessed_completed")}
        </div>
      </div>
  </div>
);

export default Legend;
