import React, {Fragment, useEffect, useState} from 'react';
import {connect, useDispatch} from "react-redux";
import {Accordion, Button, Row, Table, Card, ToggleButton, ButtonGroup, Col} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileImport, faPlus, faSpinner} from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-modal';
import {toast} from "react-toastify";
import Papa from 'papaparse';

import {
    createTask,
    createTaskCategory,
    getAllCategoriesQuery,
    getCategoriesQuery, updateTask,
    updateTaskCategory
} from "../../services/TaskCategoryService";

import SubHeaderComponent from "../../components/SubHeaderComponent";
import LabelComponent from "../../components/utils/getCompanyLabel";
import Pagination from "../../components/shared/Pagination";

import Add from "../../assets/ButtonIcons/Add Blue.svg";
import Edit from "../../assets/ButtonIcons/Edit Blue.svg";
import Archive from "../../assets/ButtonIcons/Archive Blue.svg";
import Delete from "../../assets/ButtonIcons/Delete Blue.svg";
import PutBack from "../../assets/ButtonIcons/Put Back Blue.svg";
import Expand from "../../assets/ButtonIcons/Expand Blue.svg";
import {selectTeam} from "../../redux/services/TeamService";

const getLabelText = key => <LabelComponent val={key}/>

const TaskCategory = ({ team,  ...props}) => {
    const dispatch = useDispatch();

    const [allCategories, setAllCategories] = useState([]);
    const [categories, setCategories] = useState([]);
    const [meta, setMeta] = useState({});
    const [page, setPage] = useState(1);
    const [archived, setArchived] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [modalLabel, setModalLabel] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [currentCategory, setCurrentCategory] = useState({});
    const [importData, setImportData] = useState([]);

    const setTeamHasTask = (isDelete = false) => {
        if(categories.length === 1 && (categories[0].tasks.length === 0 || categories[0].tasks.length === 1 || isDelete)) {
            dispatch(selectTeam(team.id));
        }
    }

    const getAllCategories = async () => {
        const response = await getAllCategoriesQuery(team.id);

        setAllCategories(response.data.categories);
    }

    const getCategories = async (team, page, archived) => {
        const response = await getCategoriesQuery(team, page, archived);

        setCategories(response.data.getTaskCategories.categories);
        setMeta(response.data.getTaskCategories.meta);

        props.togglePageLoad(false);
        setIsLoading(false);
    }

    const refreshData = async () => {
        setIsLoading(true);
        await getAllCategories();
        await getCategories(team.id, page, archived);
    }

    const toggleArchived = () => {
        setArchived(!archived);
    }

    const closeModal = () => {
        setModalLabel("");
        setShowModal(false);
        setCurrentCategory({});
        setImportData([]);
    }

    const openModal = (category = null, addTask = false, task = null, nonEditableCategory = false) => {
        setShowModal(true);
        if(!category) {
            setModalLabel("Add Category");
        } else if(category && !addTask) {
            setModalLabel("Edit Category");
        } else if (task) {
            setModalLabel("Edit Task");
        } else {
            setModalLabel("Add Task");
        }
        setCurrentCategory({...category, addTask: addTask, editTask: task !== null, taskId: task?.id, taskName: task?.name, taskArchived: task?.archived === true, taskDeleted: task?.deleted === true, nonEditableCategory: nonEditableCategory});
    }

    const saveCategory = async (isTask = false) => {
        if(isTask === false)
        {
            if(currentCategory.id) {
                const res = await updateTaskCategory({id: currentCategory.id, name: currentCategory.name.replaceAll(/"/g, '\\"'), archived: currentCategory.archived === true, deleted: currentCategory.deleted === true});

                if(res.data?.updateTaskCategory?.id) {
                    toast.success("Task Category Updated Successfully");
                } else {
                    toast.error("Something Went Wrong");
                }
            } else {
                const res = await createTaskCategory({name: currentCategory.name.replaceAll(/"/g, '\\"'), team: team.id});

                if(res.data?.createTaskCategory?.id) {
                    toast.success("Task Category Created Successfully");
                } else {
                    toast.error("Something Went Wrong");
                }
            }
        }
        else
        {
            if(currentCategory.taskId) {
                const res = await updateTask({id: currentCategory.taskId, name: currentCategory.taskName.replaceAll(/"/g, '\\"'), archived: currentCategory.taskArchived === true, deleted: currentCategory.taskDeleted === true});

                if(res.data?.updateTask?.id) {
                    toast.success("Task Updated Successfully");
                } else {
                    toast.error("Something Went Wrong");
                }
            } else {
                const res = await createTask({name: currentCategory.taskName.replaceAll(/"/g, '\\"'), category: currentCategory.id});

                if(res.data?.createTask?.id) {
                    toast.success("Task Created Successfully");
                    setTeamHasTask();
                } else {
                    toast.error("Something Went Wrong");
                }
            }
        }
        closeModal();
        await refreshData();
    }

    const archiveCategory = async (category) => {
        const res = await updateTaskCategory({id: category.id, name: category.name, archived: true, deleted: category.deleted === true});

        if(res.data?.updateTaskCategory?.id) {
            toast.success("Task Category Archived Successfully");
        } else {
            toast.error("Something Went Wrong");
        }
        await refreshData();
    }

    const deleteCategory = async (category) => {
        const res = await updateTaskCategory({id: category.id, name: category.name, archived: category.archived === true, deleted: true});

        if(res.data?.updateTaskCategory?.id) {
            toast.success("Task Category Deleted Successfully");
            setTeamHasTask(true);
        } else {
            toast.error("Something Went Wrong");
        }
        await refreshData();
    }

    const putBackCategory = async (category) => {
        const res = await updateTaskCategory({id: category.id, name: category.name, archived: false, deleted: category.deleted === true});

        if(res.data?.updateTaskCategory?.id) {
            toast.success("Task Category Put Back Successfully");
        } else {
            toast.error("Something Went Wrong");
        }
        await refreshData();
    }

    /*
        const archiveTask = async (task) => {
            const res = await updateTask({id: task.id, name: task.name, archived: true, deleted: task.deleted === true});

            if(res.data?.updateTask?.id) {
                toast.success("Task Archived Successfully");
            } else {
                toast.error("Something Went Wrong");
            }
            await refreshData();
        }
    */

    /*
        const putBackTask = async (task) => {
            const res = await updateTask({id: task.id, name: task.name, archived: false, deleted: task.deleted === true});

            if(res.data?.updateTask?.id) {
                toast.success("Task Put Back Successfully");
            } else {
                toast.error("Something Went Wrong");
            }
            await refreshData();
        }
    */

    const deleteTask = async (task) => {
        const res = await updateTask({id: task.id, name: task.name, archived: task.archived === true, deleted: true});

        if(res.data?.updateTask?.id) {
            toast.success("Task Deleted Successfully");
            setTeamHasTask(true)
        } else {
            toast.error("Something Went Wrong");
        }
        await refreshData();
    }

    const importFile = async (e) => {
        Papa.parse(e.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                const data = [];
                results.data.forEach(result => {
                    const category = allCategories.find(category => (!category.archived && category.name === result.category));
                    if(result.category && result.task)
                    {
                        if(category) {
                            data.push({
                                id: category.id,
                                categoryName: category.name,
                                task: result.task?.replaceAll(/"/g, '\\"'),
                                isValid: true
                            })
                        } else {
                            data.push({
                                id: null,
                                category: "",
                                categoryName: result.category?.replaceAll(/"/g, '\\"'),
                                task: result.task?.replaceAll(/"/g, '\\"'),
                                isValid: false
                            })
                        }
                    }
                })
                setImportData(data);
            },
        });
    }

    const importTask = async () => {
        importData.filter(record => record.isValid).map(async (data) => {
            await createTask({name: data.task, category: data.id});
            setTeamHasTask();
        })
        let new_categories = {};
        importData.filter(record => !record.isValid).map(async (data) => {
            if(!new_categories[data.categoryName]) {
                new_categories[data.categoryName] = [data];
            } else {
                new_categories[data.categoryName].push(data);
            }
        })

        Object.keys(new_categories).map(async (category_key) => {
            const res = await createTaskCategory({name: category_key, team: team.id});
            if(res.data?.createTaskCategory?.id) {
                new_categories[category_key].map(async (cat) => {
                    await createTask({name: cat.task, category: res.data?.createTaskCategory?.id});
                    setTeamHasTask();
                })
            }
        })

        closeModal();
        await refreshData();
    }

    useEffect(() => {
        async function getCategoriesData() {
            if(team.id)
            {
                if(!allCategories.length)
                {
                    await getAllCategories();
                }
                await getCategories(team.id, page, archived);
            }
        }
        setIsLoading(true);
        getCategoriesData();
    }, [team.id, page, archived]);

    useEffect(() => {
        if(team.id) {
            getAllCategories();
        }
    }, [team.id]);

    useEffect(() => {
        props.togglePageLoad(true);
    },[props.togglePageLoad])

    return (
        <Fragment>
            <SubHeaderComponent>
                <Row className="justify-content-end">
                    <div className="d-flex text-right">
                        <div className="mr-2 d-flex justify-content-center align-items-center px-2">
                            <label htmlFor="file-input" className='btn-nrml m-0 pointer' style={{color: "#005e85"}}>
                                Import
                                <FontAwesomeIcon className="ml-2" icon={faFileImport} color="#005e85" />
                            </label>
                            <input type="file" key={Math.random()} multiple={false} id="file-input" className="d-none" onChange={(e) => importFile(e)}/>
                        </div>
                        <Button className="btn-nrml" onClick={() => openModal()}>
                            {getLabelText("add")}
                            <FontAwesomeIcon className="ml-2" icon={faPlus} />
                        </Button>
                    </div>
                </Row>
            </SubHeaderComponent>
            <Row className="justify-content-end mt-3">
                <ButtonGroup toggle className="ml-1">
                    <ToggleButton
                        type="radio"
                        variant="secondary"
                        name="createType"
                        value="0"
                        checked={!archived}
                        onChange={() => toggleArchived()}
                    >
                        {getLabelText("active")}
                    </ToggleButton>
                    <ToggleButton
                        type="radio"
                        variant="secondary"
                        name="createType"
                        value="1"
                        checked={archived}
                        onChange={() => toggleArchived()}
                    >
                        {getLabelText("archived")}
                    </ToggleButton>

                </ButtonGroup>
            </Row>
            <div className="grey-header-space container-left">
                {!isLoading ? (
                    <Table striped bordered hover className="skills-tbl table-heading-background user-text">
                        <tbody>
                        <tr>
                            <Accordion defaultActiveKey="">
                                {categories.map(category => (
                                    <Card key={category.id}>
                                        <Card.Header className="d-flex justify-content-between py-2 position-relative font-bold">
                                            {category.name}
                                            <Accordion.Toggle variant="link" className="border-0 outline-0 bg-transparent position-absolute" eventKey={category.id} style={{right: "0"}} >
                                                <button className={"btn-icons-container mr-2"}>
                                                    <img src={Expand} alt="Expand" className="btn-icons" />
                                                </button>
                                            </Accordion.Toggle>
                                            <Row className="justify-content-between">
                                                <Col className="mr-4">
                                                    {!archived ? (
                                                        <Row className="justify-content-end me-3">
                                                            <button className={"btn-icons-container mr-2"} onClick={() => openModal(category)}>
                                                                <img src={Edit} alt="Edit" className="btn-icons" />
                                                            </button>
                                                            <button className={"btn-icons-container mr-2"}>
                                                                <img src={Add} alt="Add" className="btn-icons"  onClick={() => openModal(category, true, null, true)} />
                                                            </button>
                                                            <button className={"btn-icons-container"} onClick={() => archiveCategory(category)}>
                                                                <img src={Archive} alt="Archive" className="btn-icons" />
                                                            </button>
                                                        </Row>
                                                    ) : (
                                                        <Row className="justify-content-end me-3">
                                                            <button className={"btn-icons-container mr-2"} onClick={() => putBackCategory(category)}>
                                                                <img src={PutBack} alt="PutBack" className="btn-icons" />
                                                            </button>
                                                            <button className={"btn-icons-container"} onClick={() => deleteCategory(category)}>
                                                                <img src={Delete} alt="Delete" className="btn-icons" />
                                                            </button>
                                                        </Row>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Card.Header>
                                        {category.tasks?.map(task => (
                                            <Accordion.Collapse key={task.id} eventKey={category.id}>
                                                <Card.Body className="py-2">
                                                    <Row className="justify-content-between">
                                                        <Col>
                                                            { task.name }
                                                        </Col>
                                                        <Col>
                                                            {!archived ? (
                                                                <Row className="justify-content-end">
                                                                    <button className={"btn-icons-container mr-2"} onClick={() => openModal(category, false, task)}>
                                                                        <img src={Edit} alt="Edit" className="btn-icons" />
                                                                    </button>
                                                                    {/*<button className={"btn-icons-container"} onClick={() => archiveTask(task)}>*/}
                                                                    {/*    <img src={Archive} alt="Archive" className="btn-icons" />*/}
                                                                    {/*</button>*/}
                                                                    <button className={"btn-icons-container"} onClick={() => deleteTask(task)}>
                                                                        <img src={Delete} alt="Delete" className="btn-icons" />
                                                                    </button>
                                                                </Row>
                                                            ) : (
                                                                <Row className="justify-content-end">
                                                                    {/*<button className={"btn-icons-container mr-2"} onClick={() => putBackTask(task)}>*/}
                                                                    {/*    <img src={PutBack} alt="PutBack" className="btn-icons" />*/}
                                                                    {/*</button>*/}
                                                                    <button className={"btn-icons-container"} onClick={() => deleteTask(task)}>
                                                                        <img src={Delete} alt="Delete" className="btn-icons" />
                                                                    </button>
                                                                </Row>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        ))}
                                        {!category.tasks?.length && (
                                            <Accordion.Collapse eventKey={category.id}>
                                                <div className="d-flex justify-content-center py-3">No Tasks</div>
                                            </Accordion.Collapse>
                                        )}
                                    </Card>
                                ))}
                            </Accordion>
                            {!categories.length && (
                                <div className="d-flex justify-content-center py-5 my-5">Nothing to Show</div>
                            )}
                        </tr>
                        </tbody>
                    </Table>
                ) : (
                    <div className="d-flex justify-content-center py-5 my-5">
                        <FontAwesomeIcon className="fa-spin cdi-blue" fontSize={36} icon={faSpinner} />
                    </div>
                )}
                <Row className="pb-5">
                    <Pagination current={page} totalPages={meta?.totalPages} setPage={setPage} />
                </Row>
            </div>

            <Modal
                isOpen={showModal}
                onRequestClose={() => closeModal()}
                contentLabel={modalLabel}
                className="standard-modal standard-modal-task-category"
            >
                <h5 className="pb-3">{ modalLabel }</h5>
                <Row>
                    <Col lg={2} className="d-flex align-items-center">
                        <label className="m-0">Category</label>
                    </Col>
                    <Col lg={10}>
                        {(currentCategory.addTask || currentCategory.editTask) ? (
                            <select className="form-control" value={currentCategory.id} disabled={currentCategory.editTask === true || currentCategory.nonEditableCategory === true} onChange={(e) => setCurrentCategory({...currentCategory, id: e.target.value})}>
                                {allCategories.map((val => (
                                    <option key={val.id} value={val.id}>{ val.name }</option>
                                )))}
                            </select>
                        ) : (
                            <input className="form-control" value={currentCategory.name} onChange={(e) => setCurrentCategory({ ...currentCategory, name: e.target.value })} />
                        )}
                    </Col>
                </Row>
                {(currentCategory.addTask || currentCategory.editTask) && (
                    <Row className="mt-3">
                        <Col lg={2} className="d-flex align-items-center">
                            <label className="m-0">Task</label>
                        </Col>
                        <Col lg={10}>
                            <input className="form-control" value={currentCategory.taskName} onChange={(e) => setCurrentCategory({ ...currentCategory, taskName: e.target.value })} />
                        </Col>
                    </Row>
                )}
                <Row className="mt-4 width">
                    <Col xs={6} className="text-right">
                        <Button variant="secondary" className={"btn-scheduled"} onClick={() => closeModal()}>
                            Cancel
                        </Button>
                    </Col>
                    <Col xs={6} className="text-left">
                        <Button variant="primary" className={"btn-scheduled"} onClick={() => saveCategory(currentCategory.addTask || currentCategory.editTask)}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </Modal>

            <Modal
                isOpen={importData.length > 0}
                onRequestClose={() => closeModal()}
                className="standard-modal standard-modal-task-category"
            >
                <h5 className="pb-3">Import Data</h5>
                <div style={{maxHeight: "400px", scrollBehavior: "auto", overflowY: "scroll"}}>
                    <Table striped bordered hover className="skills-tbl table-heading-background user-text">
                        <thead>
                        <tr>
                            <th width="40%">Category</th>
                            <th width="40%">Task</th>
                            <th width="20%">Remarks</th>
                        </tr>
                        </thead>
                        <tbody>
                        {importData.map((data, idx) => (
                            <tr key={idx}>
                                <td>{data.categoryName}</td>
                                <td>{data.task}</td>
                                <td>{data.isValid ? "" : "New Category"}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>
                <h6 className="py-2 text-danger">Only the Valid data will be imported.</h6>
                <Row className="mt-4 width">
                    <Col xs={6} className="text-right">
                        <Button variant="secondary" className={"btn-scheduled"} onClick={() => closeModal()}>
                            Cancel
                        </Button>
                    </Col>
                    <Col xs={6} className="text-left">
                        <Button variant="primary" className={"btn-scheduled"} onClick={() => importTask()}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </Modal>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    team: state.teamsReducer.selectedTeam,
    user: state.userReducer.user
})

export default connect(mapStateToProps)(TaskCategory);