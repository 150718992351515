import React, { Component, Fragment } from "react"
import { connect } from "react-redux";
import { updatePermissions } from '../redux/services/UserService';
// bootstrap
import { Table, Button, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap"

import { createSkill, getSkills, deleteSkill, updateSkill } from "../services/SkillService";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from 'react-toastify'
import displayErrorToast from '../components/utils/displayErrorToast'
import PaginationComponent from "../components/PaginationComponent"
import SubHeaderComponent from "../components/SubHeaderComponent";
import LabelComponent from "../components/utils/getCompanyLabel";
import * as DefaultLabels from '../assets/glossary.json';
// icons
import Cancel from '../assets/ButtonIcons/Cancel Blue.svg';
import Delete from '../assets/ButtonIcons/Delete Blue.svg';
import Edit from '../assets/ButtonIcons/Edit Blue.svg';
import Loader from '../assets/ButtonIcons/Loader Blue.svg';
import Save from '../assets/ButtonIcons/Save Blue.svg';
const labels = DefaultLabels.default;

function renderTooltip(props, text) {
  return (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );
}

class SkillsScreen extends Component {
  constructor(props) {
    super(props)

    this.props.toggleDirtyState(true)
    this.state = {
      skills: [],
      newSkills: [],
      newSkill: "",
      paginateDisabled: false,
      page: 1,
      totalPages: 0,
      firstIndex: 0,
      lastIndex: 9,
      loading: false,
      loadType: ""
    }

    this.setPage = this.setPage.bind(this)
    this.props.togglePageLoad(true)
  }

  async componentDidMount() {
    await this.fetchSkills()
    this.props.togglePageLoad(false)
  }

  getLabelText = key => <LabelComponent val={key}/>

  getText = val => {
    const { user } = this.props;

    const companyId = localStorage.getItem("companyId");
    let lang = "EN";
    let langLabels = labels.find(lbl => lbl.lang === lang);
    let def = langLabels.glossary.find(label => label.key === val);

    if(user && user.companies && user.companies.length) {
      const company = user.companies.find(company => company.id === companyId);
      if(company) {
        lang = company.language;
        langLabels = labels.find(lbl => lbl.lang === lang);
        def = langLabels.glossary.find(label => label.key === val);
        if(!def) {
          lang = "EN";
          langLabels = labels.find(lbl => lbl.lang === lang);
          def = langLabels.glossary.find(label => label.key === val);
        }
      }
      if(company && company.companyGlossaries) {
        const custom = company.companyGlossaries.find(label => label.key === val);

        if(custom) return custom.value
      }
    }

    if(!def) {
      console.log(val)
    }
    return def.value
  }

  fetchSkills = async (newSkill = null) => {
    const { newSkills } = this.state
    const response = await getSkills()
    let skills = response.data.company.skills.filter(skill => !newSkills.find(newSkill => newSkill.id === skill.id))

    if(newSkill) {
      skills = skills.filter(skill => skill.id !== newSkill.id)
    }

    if(response.data.company) {
      this.setState({
        skills: skills,
        totalPages: (Math.ceil(response.data.company.skills.length / 10))
      })
    }
  }

  fetchSkillCount = async () => {
    const response = await getSkills()

    this.setState({
      totalPages: (Math.ceil(response.data.company.skills.length / 10))
    })
  }

  setPage = async (page) => {
    this.setState({
      paginateDisabled: true
    })
    const firstIndex = (parseInt(page) * 10) - 10
    const lastIndex = (parseInt(page) * 10) - 1

    this.setState({
      paginateDisabled: false,
      page: page,
      firstIndex: firstIndex,
      lastIndex: lastIndex

    })
  }

  toggleMode = (e, mode, id = 0) => {
    e.preventDefault()
    const activeMode = this.state.mode
    if(activeMode !== "") {
      this.props.toggleDirtyState()

    }
    this.cancel()
    let options = {
      selectedId: id,
      mode: mode
    }

    let { skills, newSkills } = this.state
    const jointSkills = [...newSkills, ...skills]

    if (mode === "create") {
      newSkills.unshift({
        id: 0,
        name: ""
      })

      options = {
        ...options,
      }
    } else {
      const skill = jointSkills.filter((skill) => {
        return skill.id === id
      })

      options = {
        ...options,
        name: skill[0].name,
      }
      console.log(options);
    }

    this.setState(options)
    if (mode === "create") this.setPage(1)
  }

  cancel = () => {
    const { mode } = this.state
    this.props.toggleDirtyState();
    let options = {
      selectedId: "",
      mode: "",
      name: ""
    }

    if (mode === "create") {
      let { newSkills } = this.state

      if (newSkills[0].id === 0) {
        newSkills.shift()
      }

    }

    this.setState(options)
  }

  validate = () => {
    const {
      name
    } = this.state

    return name.length > 0
  }

  handleCheck = (key) => {
    this.setState((prevState) => ({
      [key]: !prevState[key]
    }))
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    this.setState({
      loading: true,
      loadType: "create"
    })
    if (this.validate()) {
      try {
        const { name, mode, selectedId, newSkills } = this.state
        let newSkill = null
        if(mode === "create") {
          const response = await createSkill({
            name
          })

          newSkill = response.data.createSkill
        } else {
          const response = await updateSkill(selectedId,{
            name
          })
          if(newSkills.find(skill => skill.id === selectedId)) {
            newSkills[newSkills.findIndex(skill => skill.id === selectedId)] = response.data.updateSkill
          }
        }
        await this.fetchSkills(newSkill)
        // if(selectedId === this.props.skill.id) {
        //   await fetchSkill()
        // }
        this.cancel()
        if(newSkill) {
          newSkills.unshift(newSkill)
        }
        this.setState({
          newSkills
        })
        this.props.updatePermissions("hasSkills", true)
      } catch (error) {
        displayErrorToast(error)
      }
    }

    this.setState({
      loading: false,
      loadType: ""
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  deleteItem = async (id) => {
    const { newSkills } = this.state
    this.setState({
      loading: true,
      loadType: "delete",
      deleteId: id
    })
    if (window.confirm(this.getText("delete_department_prompt"))) {
      try {
        await deleteSkill(id)
        if(newSkills.find(skill => skill.id === id)) {
          newSkills.splice(newSkills.findIndex(skill => skill.id === id), 1)
        }
        await this.fetchSkills()
      } catch (error) {
        error.graphQLErrors.map(error => {
          toast.error(error.message)

          return true
        })
      }
    }

    this.setState({
      loading: false,
      loadType: "",
      deleteId: ""
    })
  }

  skillList = (skill, i) => {
    const { selectedId, loading, loadType, deleteId, name } = this.state
    let skillRow;
    if(selectedId === skill.id) {
      skillRow = (
        <tr key={i}>
          <td>
            <input
              autoFocus
              className="form-control"
              type="text"
              required
              placeholder="Name"
              value={name}
              name="name"
              tabIndex="1"
              onChange={(e) => this.handleChange(e)}
              autoComplete="off"
            />
            <small><b>*{this.getLabelText("required")}</b></small>
          </td>
          <td className="text-right">
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, this.getLabelText('save'))}
            >
              <button className="btn-icons-container" type="submit">
                {loading && loadType === "create" && selectedId === skill.id
                  ? (
                    <Fragment>
                      <img src={Loader} alt="Loader" className="loader-spinner"/>
                    </Fragment>
                  )
                  : (
                    <Fragment>
                      <img src={Save} alt="Save" className="btn-icons"/>
                    </Fragment>
                  )
                }
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, this.getLabelText('cancel'))}
            >
              <Button
                className="btn-icons-container"
                variant="danger"
                onClick={this.cancel}
              >
                <img src={Cancel} alt="Cancel" className="btn-icons"/>
              </Button>
            </OverlayTrigger>
          </td>
        </tr>
      )
    } else {
      skillRow = (
        <tr key={i}>
          <td>
            {skill.name}
          </td>
          <td className="text-right">
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, this.getLabelText('edit'))}
            >
              <Button
                className={"btn-icons-container" + (selectedId || typeof(selectedId) === "number" ? " disabled" : "")}
                variant="primary"
                onClick={(e) => this.toggleMode(e, "edit", skill.id)}
              >
                <img src={Edit} alt="Edit" className="btn-icons"/>
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, this.getLabelText('delete'))}
            >
              <Button
                  className={"btn-icons-container" + (selectedId || typeof(selectedId) === "number" ? " disabled" : "")}
                  disabled={skill.hasTeams}
                  variant="danger"
                  onClick={() => this.deleteItem(skill.id)}
              >
                {loading && loadType === "delete" && deleteId === skill.id
                  ? (
                    <Fragment>
                      <img src={Loader} alt="Loader" className="loader-spinner"/>
                    </Fragment>
                  )
                  : (
                    <Fragment>
                      <img src={Delete} alt="Delete" className="btn-icons"/>
                    </Fragment>
                  )
                }
              </Button>
            </OverlayTrigger>
          </td>
        </tr>
      )
    }
    return skillRow
  }

  render() {
    const { skills, newSkills, firstIndex, lastIndex } = this.state

    const jointSkills = [...newSkills, ...skills]
    return (
      <Fragment>
        <SubHeaderComponent>
          <Row className="justify-content-center">
            <Col className="text-right" xs={12}>
              <Button
                className={"btn btn-primary btn-nrml" + (this.state.selectedId || typeof(this.state.selectedId) === "number" ? " disabled" : "")}
                onClick={(e) => this.toggleMode(e, "create")}
              >
                {this.getLabelText("add")}<FontAwesomeIcon className="ml-2" icon={faPlus} />
              </Button>
            </Col>
          </Row>
        </SubHeaderComponent>
        <div className="grey-header-space container-left">
          <Row className="justify-content-center paddingForScreenBottom">
            <Col xl={12}>
              <form onSubmit={(e) => this.handleSubmit(e)}>
                <Table striped bordered hover className="skills-tbl table-heading-background user-text">
                  <thead>
                  <tr>
                    <th width="25%" className="text-center">{this.getLabelText("skill")}</th>
                    <th width="15%"></th>
                  </tr>
                  </thead>
                  <tbody>
                  {jointSkills.map((skill, i) => {
                    if(i >= firstIndex && i <= lastIndex){
                      return this.skillList(skill, i)
                    }

                    return null
                  })}
                  </tbody>
                </Table>
                {
                  this.state.totalPages > 0 ?
                  <div className="pagination_container">
                    <PaginationComponent
                        active={this.state.page}
                        disabled={this.state.paginateDisabled}
                        setPage={this.setPage}
                        total={this.state.totalPages}
                    />
                  </div>
                      : null
                }
              </form>
            </Col>
          </Row>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam,
  user: state.userReducer.user
})

const mapDispatchToProps = {
  updatePermissions
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillsScreen);
