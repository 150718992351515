import React, { useEffect, useCallback, useState, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from 'react-modal';
import classnames from "../../components/utils/classnames";
import { useMutation } from "@apollo/react-hooks";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
  CREATE_SKILL_MATRIX_MUTATION,
  UPDATE_SKILL_MATRIX_MUTATION,
} from "./resources/gql";
import displayErrorToast from "../../components/utils/displayErrorToast";
import {SKILL_STATUSES} from './resources/statuses';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import LabelComponent from "../../components/utils/getCompanyLabel";

const getLabelText = key => <LabelComponent val={key}/>

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '30%'
  }
};



const UserCell = ({
  userId,
  teamId,
  skill,
  matrix,
  setSelectedStatus,
  matrixSkill,
  setDueDate,
  showMatrix,
  showModal,
  modalType,
  fullName,
  dueDate,
  selectedStatus,
  selectedUser,
  userSkills,
  matrixId,
  isLoading,
  setIsLoading,
  getText
}) => {
  const [obj, setObj] = useState(matrix ? matrix : {id: null, status: SKILL_STATUSES[0]});
  const [displayedStatus, setDisplayedStatus] = useState(matrix ? matrix.status : SKILL_STATUSES[0])

  const matrixCellProps = {
    [SKILL_STATUSES[0]]: {
      classes: "bg-grey text-grey",
      label: getText("not_applicable"),
      inputClasses: "bg-grey"
    },
    [SKILL_STATUSES[1]]: { inputClasses: "bg-white skill-border-lg skill-red-border", classes: "bg-white skill-border-lg skill-red-border", label: getText("planned") },
    [SKILL_STATUSES[2]]: { inputClasses: "bg-color-negative", classes: "bg-color-negative", label: getText("overdue")},
    [SKILL_STATUSES[3]]: { inputClasses: "bg-white skill-border-lg skill-selected-color-border", classes: "bg-white skill-border-lg skill-selected-color-border", label: getText("training_completed")},
    [SKILL_STATUSES[4]]: { inputClasses: "color-selected", classes: "color-selected", label: getText("assessed_completed") },
  };

  useEffect(() => {
    setObj(matrix ? matrix : {id: null, status: SKILL_STATUSES[0]})
    setDisplayedStatus(matrix ? matrix.status : SKILL_STATUSES[0])
  }, [matrix, setObj, setDisplayedStatus])

  const onError = (error) => {
    displayErrorToast(error);
  };

  const [createMatrix] = useMutation(CREATE_SKILL_MATRIX_MUTATION, {
   onCompleted: async ({ createSkillsMatrix }) => {
     setObj(createSkillsMatrix)
     setDisplayedStatus(createSkillsMatrix.status)
     setIsLoading(false)
     showMatrix()
   },
  });

  const saveMatrix = useCallback(() => {
   setIsLoading(true)

   createMatrix({
     variables: {
       user: selectedUser,
       team: teamId,
       skill: matrixSkill,
       status: selectedStatus,
       dueDate: dueDate
     },
   });
 }, [createMatrix, matrixSkill, teamId, setIsLoading, selectedUser, selectedStatus, dueDate]);

  const [updateMatrix] = useMutation(UPDATE_SKILL_MATRIX_MUTATION, {
    onError,
    onCompleted: ({ updateSkillsMatrix }) => {
      setObj(updateSkillsMatrix)
      setDisplayedStatus(updateSkillsMatrix.status)
      setIsLoading(false)
      showMatrix()
    },
  });

  const editMatrix = useCallback(() => {
   setIsLoading(true)
   updateMatrix({
     variables: {
       id: obj.id,
       status: selectedStatus,
       dueDate: dueDate
     },
   });
 }, [updateMatrix, selectedStatus, dueDate, obj, setIsLoading]);



  useEffect(() => {
    if(userSkills.length) {
      const userSkill = userSkills.find(skill => skill.user.id === userId && skill.skill.id === skill.id)
      if(userSkill) {
        setObj(userSkill)
      }
    }
  }, [userSkills, setObj, userId]);

  const { classes, icon, text } = matrixCellProps[displayedStatus];

  const className = classnames(
    "attendance-cell text-center font-weight-bold p-1 mr-2",
    classes,
  );

  const saveData = () => {
    if(obj.id) {
      editMatrix()
    } else {
      saveMatrix()
    }
  }

  return (
    <Fragment>
      <div onClick={() => showMatrix({
          user: userId,
          skill: skill.id,
          status: displayedStatus,
          date: obj.dueDate ? new Date(obj.dueDate) : new Date(),
          matrixId: obj.id ? obj.id : null
        })} className={className}>
        {icon && <FontAwesomeIcon icon={icon} />}
        {!!text && text}
      </div>
      <Modal
        isOpen={showModal && modalType === "matrix" && selectedUser === userId && matrixSkill === skill.id}
        onRequestClose={() => showMatrix()}
        contentLabel="Set Skill Matrix"
        style={customStyles}
      >
        <div className="row">
          <div className="col-12 text-center">
            <label className="cdi-blue-txt ml-0 mb-2">{getLabelText("status_for")} {fullName}</label>
            <br/>
            <label className="cdi-blue-txt ml-0 mb-3">{skill.name}</label>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-3">
            <label className="cdi-blue-txt ml-2">{getLabelText("due_date")}:</label>
          </div>
          <div className="col-9 datepicker-100">
            <DatePicker
              name="date"
              className="form-control w-100"
              required={true}
              dateFormat="yyyy/MM/dd"
              showYearDropdown
              selected={dueDate}
              onChange={setDueDate}
              inline={false}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-3">
            <label className="cdi-blue-txt ml-2">{getLabelText("status")}:</label>
          </div>
          <div className="col-9">
            <table className="w-100">
              <tbody>
                <tr>
                  <td valign="middle">
                    <div style={{ height: "32px", width: "32px" }} className={"attendance-cell text-center font-weight-bold p-1 mr-2 " + matrixCellProps[selectedStatus ? selectedStatus : "NOT_APPLICABLE"].inputClasses}>

                    </div>
                  </td>
                  <td valign="middle">
                    <select
                      className={"form-control cdi-ddl w-100 ml-0"}
                      defaultValue={selectedStatus}
                      onChange={(e) => setSelectedStatus(e.target.value)}
                      autoComplete="off"
                      >
                      {SKILL_STATUSES.map((status, i) => {
                        const { label } = matrixCellProps[status]
                        return (
                          <option key={i} value={status}>
                            {label}
                          </option>
                        )
                      })}
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-6">
            <button className="btn btn-primary w-100" disabled={isLoading} onClick={() => saveData()}>
              {isLoading
                ? (
                  <>{getLabelText("saving")} <FontAwesomeIcon icon={faSpinner} className="fa-spin ml-2"></FontAwesomeIcon></>
                ) : (
                  <>{getLabelText("save")}</>
                )
              }
            </button>
          </div>
          <div className="col-6">
            <button className="btn btn-default cdi-blue-outline color-blue w-100" onClick={() => showMatrix()}>{getLabelText("cancel")}</button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default UserCell;
