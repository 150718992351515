import React, { useEffect, useState, useCallback } from "react";
import { useMutation } from "@apollo/react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import classnames from "../../../components/utils/classnames";
import displayErrorToast from "../../../components/utils/displayErrorToast";

import { PERIOD_STATES } from "../resources/enums";
import {
  CREATE_PERIOD_ROUTINE_MUTATION,
  UPDATE_PERIOD_ROUTINE_MUTATION
} from "../resources/gql";

const dateCellProps = {
  [PERIOD_STATES[0]]: { classes: "", loadingClasses: "fa-spin text-black" },
  [PERIOD_STATES[1]]: { classes: "bg-danger text-white", loadingClasses: "fa-spin text-white" },
  [PERIOD_STATES[2]]: { classes: "bg-dark text-white", loadingClasses: "fa-spin text-white" },
};

const PeriodCell = ({
  result,
  teamId,
  key,
  day,
  today,
  onCellChange,
  index
}) => {
  const [resultId, setResultId] = useState(result.id);
  const [status, setStatus] = useState(result.status);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(result.date);
  const [currDate, setCurrDate] = useState(today);
  const [displayedStatus, setDisplayedStatus] = useState(result.status);

  const onError = (error) => {
    setDisplayedStatus(status);
    displayErrorToast(error);
    setLoading(false);
  };

  useEffect(() => {
    if(currDate.getMonth() === today.getMonth()) return;

    setResultId(result.id);
    setStatus(result.status);
    setDisplayedStatus(result.status);
    setDate(result.date);
    setCurrDate(today);
  }, [result, setResultId, setDisplayedStatus, setStatus, currDate, setCurrDate, today, setDate]);

  const [updatePeriodRoutine] = useMutation(UPDATE_PERIOD_ROUTINE_MUTATION, {
    onError,
    onCompleted: ({ updateRoutinePeriodMetric }) => {
      setChange(updateRoutinePeriodMetric.status);
      setStatus(updateRoutinePeriodMetric.status);
      setLoading(false);
    },
  });

  const [createPeriodRoutine] = useMutation(CREATE_PERIOD_ROUTINE_MUTATION, {
    onError,
    onCompleted: ({ createRoutinePeriodMetric }) => {
      setChange(createRoutinePeriodMetric.status);
      setStatus(createRoutinePeriodMetric.status);
      setResultId(createRoutinePeriodMetric.id);
      setLoading(false);
    },
  });

  const create = useCallback((newStatus) => {
    createPeriodRoutine({
      variables: {
        id: teamId,
        status: newStatus,
        date
      },
    });
  }, [createPeriodRoutine, teamId, date]);

  const update = useCallback((newStatus) => {
    updatePeriodRoutine({
      variables: {
        status: newStatus,
        id: resultId,
      },
    });
  }, [updatePeriodRoutine, resultId]);

  const setChange = (status) => {
    onCellChange({ state: PERIOD_STATES.indexOf(status), index: day });
  }

  const toggleStatus = () => {
    const nextIndex = PERIOD_STATES.indexOf(displayedStatus) === 2 ? 0 : (PERIOD_STATES.indexOf(displayedStatus) + 1);

    setDisplayedStatus(PERIOD_STATES[nextIndex]);

    return PERIOD_STATES[nextIndex];
  }

  const onClick = () => {
    setLoading(true);
    const newStatus = toggleStatus();
    if(resultId) return update(newStatus);
    create(newStatus);
  };

  const className = classnames(
    "attendance-cell attendance-date-border text-center p-1 border mr-2",
    dateCellProps[displayedStatus].classes,
    (loading ? " disabled" : "")
  );

  return (
    <div onClick={onClick} className={className}>
      {loading
        ? (<FontAwesomeIcon icon={faSpinner} className={dateCellProps[displayedStatus].loadingClasses} />)
        : <span>{day}</span>
      }
    </div>
  );
};

export default PeriodCell;
