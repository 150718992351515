import React, {Fragment, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";
import {getIssue} from "../../services/EscalationsService";

const NextStep = ({ loading, loadingType, loadingId, editType, issue, action, actionId, status, nextSteps, loadingClassName }) => {
    const history =useHistory();

    const [disabled, setDisabled] = useState(false);

    const onClick = async (redirect) => {
        setDisabled(true);
        if(!redirect)
        {
            if(!disabled)
            {
                nextSteps();
            }
        }
        else
        {
            if(action.actionType === 'ESCALATION')
            {
                const res = await getIssue(issue.id);

                const filtered = res.data.issue.actions.filter(ac => ac.actionType === 'FEEDBACK');

                if (filtered.length)
                {
                    action = filtered[0];
                }
            }

            let nextStepLink = '';

            if(action.archived === false || action.archived === true)
            {
                nextStepLink = `/now-what/actions?action=${action.id}`;

                nextStepLink += `&archived=${action.archived}`;
            }
            history.push(nextStepLink);
        }
    }

    if (editType === "create" && actionId === action.id)
    {
        return <Fragment></Fragment>
    }

    if(loading === true && loadingType === "nextSteps" && (loadingId === issue?.id || loadingId === actionId))
    {
        return (
            <span className={loadingClassName ?? "ml-4 mt-1 chk chk-white"}>
                <FontAwesomeIcon className="mr-1 fa-spin" icon={faSpinner} />
            </span>
        )
    }

    if(status === "EMPTY")
    {
        return <Fragment></Fragment>
    }

    if(status === "NEXT_STEP" || status === 'TRIGGERED')
    {
        return (
            <button className="cdi-blue-txt cdi-blue-outline bg-white rounded-pill border-0 outline-0 px-2 py-0 font-weight-bold ml-2" onClick={() => onClick(true)}>
                NS
            </button>
        )
    }
    else if(status === "NONE")
    {
        return (
            <button className="bg-grey no-outline rounded-pill border-0 outline-0 px-2 py-0 font-weight-bold ml-2" onClick={() => onClick(false)}>
                NS
            </button>
        )
    }
    else if(status === "COMPLETED")
    {
        return (
            <button className="text-white bg-cdi-blue border-color-selected rounded-pill border-0 outline-0 px-2 py-1 font-weight-bold ml-2" onClick={() => onClick(true)}>
                NS
            </button>
        )
    }
    else
    {
        return <Fragment></Fragment>;
    }
}

export default NextStep;