import React from 'react';
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

const IconButton = (props) => {
  const tooltip = (
    <Tooltip id={`tooltip-top`}>
      {props.tooltip}
    </Tooltip>
  )

  return (
    <Button disabled={props.disabled ? props.disabled : false} className={"btn-icons-container " + (props.classNames ? props.classNames : "")} onClick={props.eventHandler}>
      <OverlayTrigger overlay={tooltip}>
        {props.children}
      </OverlayTrigger>
    </Button>
  )
}

export default IconButton;
