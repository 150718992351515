import React, { useState, useEffect, useMemo, useCallback, Fragment } from "react";
import { Row, Col, Form, Table } from "react-bootstrap";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  addMonths
} from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import PeriodCell from "./PeriodCell";
import UserCell from "./UserCell";
import { connect } from 'react-redux';
import { getISOWeeksInMonth, getDaysInMonthArray, getPeriodKey, getPeriod, getDisabledDays, getCalculatedCurrentTarget } from "./resources/hooks";
import { TEAM_IDIVIDUAL_GOALS_LIST_QUERY, TEAM_IDIVIDUAL_GOALS_DATA_QUERY, LINK_INDIVIDUAL_GOAL_USER_MUTATION, COMPANY_START_WEEKS } from "./resources/gql";
import { createUserLog, updateUserLog } from "../../services/IndividualResultAssignedUserLogService"

import classnames from "../../components/utils/classnames";
import MonthSelect from "../../components/MonthSelect";
import mapAPIData from "./resources/mapAPIData";
import Header from "./Header"
import { formatDate } from "./resources/hooks";
import Modal from 'react-modal';
import { toast } from 'react-toastify'
import { Redirect } from "react-router";
import LabelComponent from "../../components/utils/getCompanyLabel";

const getLabelText = key => <LabelComponent val={key}/>

// New Code
// const customStyles = {
//   content : {
//     top                   : '50%',
//     left                  : '50%',
//     right                 : 'auto',
//     bottom                : 'auto',
//     marginRight           : '-50%',
//     transform             : 'translate(-50%, -50%)',
//     width                 : '500px'
//   }
// };

function getYearList(startMonth, isValues) {
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const array = []
  const index = months.indexOf(startMonth)

  for(let i = index; i < months.length; i++) {
    array.push(isValues ? (i + 1) : months[i])
  }

  for(let i = 0; i < index; i++) {
    array.push(isValues ? (i + 1) : months[i])
  }

  return array
}

// function getYearValues(startMonth) {
//   let array = []
//   const monthValues = getYearList(startMonth, true)
//   monthValues.map((month, index) => {
//     let date = new Date(new Date().getFullYear(), (monthValues[0] - 1) + index, 2)
//
//     array.push(date.getFullYear())
//
//     return true
//   })
//
//   return array
// }

function sortMeasures(measures, presentation, firstMonth, selectedMonth) {
  const months = getYearList(firstMonth, true)
  const selectedMonth1 = new Date(selectedMonth);
  const period = (selectedMonth1.getMonth() + 1) < months[0] ? `${selectedMonth1.getFullYear() - 1}${selectedMonth1.getFullYear()}` : `${selectedMonth1.getFullYear()}${selectedMonth1.getFullYear() + 1}`
  if(presentation && presentation.active && !presentation.paused) {
    return presentation.schedule[presentation.index].children.map(child => {
      let measure = measures.find(measure => measure.id === child.id)

      return measure ? measure : null
    })
  }
  return measures.filter(measure => measure.period === period).sort((a,b) => a.measurement.goalCategory.order - b.measurement.goalCategory.order)
}


function getPeriodKeyArray(firstMonth, selectedMonth) {
  var periodArray = [];
  const months = getYearList(firstMonth, true)
  const selectedMonth1 = new Date(selectedMonth);
  if ((selectedMonth1.getMonth() + 1) < months[0]) {
    periodArray.push(`${selectedMonth1.getFullYear() - 1}`);
    periodArray.push(`${selectedMonth1.getFullYear()}`);
  }
  else {
    periodArray.push(`${selectedMonth1.getFullYear()}`);
    periodArray.push(`${selectedMonth1.getFullYear() + 1}`);
  }

  return periodArray;
}
function setSelectedIndex(presentation) {
  if(presentation && presentation.active && !presentation.paused) {
    return presentation.subIndex
  }
  return localStorage.getItem("selectedIndividualResult") && localStorage.getItem("selectedIndividualResult") !== "" ? localStorage.getItem("selectedIndividualResult") : 0
}
// End New Code

const IndividualResultsScreen = ({ user, selectedTeam, togglePageLoad, refresh, hasNewData, toggleRefresh, toggleHasNewData, presentation }) => {
  // New Code
  const [loadedGoal, setLoadedGoal] = useState(null);
  const [individualGoals, setIndividualGoals] = useState([]);
  const [showModal, setShowModal] = useState(false)
  //const [noData, setNoData] = useState(false)
  // const [setLoaded] = useState(false)
  const [period] = useState('WEEK');
  const [selectedGoalIndex, setSelectedGoalIndex] = useState(0);
  // const [hasNext, setHasNext] = useState(false)
  // const [hasPrev, setHasPrev] = useState(false)
  // const [showNext, setShowNext] = useState(false)
  // const [showPrev, setShowPrev] = useState(false)
  const [linkedId] = useState("")
  // const [colour, setColour] = useState(selectedTeam.company.color)
  const [monthList, setMonthList] = useState([]);
  const [monthValues, setMonthValues] = useState([]);
  // const [yearList, setYearList] = useState([]);
  const [fiscalYear] = useState(1)
  const [selectedCompany] = useState(localStorage.getItem("companyId"))
  // const [periodValues] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState();
  const [selectedStandInId, setSelectedStandInId] = useState();
  const [userMetrics, setUserMetrics] = useState([])
  // End New Code
  const [date, setDate] = useState(new Date());
  const [disabledDays, setDisabledDays] = useState(new Set());
  const [team, setTeam] = useState({ attendances: [] });
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dayTotals, setDayTotals] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([])
  const [goalUsers, setGoalUsers] = useState([])
  const [linkLoading, setLinkLoading] = useState(false)
  const [startWeeks, setStartWeeks] = useState([])
  const [currentStartWeek, setCurrentStartWeek] = useState(null)


  const onStarWeekComplete = useCallback(
    (data) => {
      setStartWeeks(data.companyStartWeeks)
    },
    [setStartWeeks]
  );
  // New Code
    const onLinkComplete = useCallback(
    ({ linkIndividualGoalUser: users }) => {
      setGoalUsers(users.users)
      const selected = []
      users.users.map(user => selected.push(user.id))
      setSelectedUsers(selected)
      setLinkLoading(false)
      setShowModal(false)
    },
    [setGoalUsers, setSelectedUsers, setLinkLoading]
  );
  const [linkUsers] = useMutation(LINK_INDIVIDUAL_GOAL_USER_MUTATION, {
    onCompleted: onLinkComplete
  });

  const saveUserLinks = useCallback(() => {
   setLinkLoading(true)
   linkUsers({
     variables: { id: loadedGoal.id, users: selectedUsers },
   });
 }, [linkUsers, loadedGoal, selectedUsers, setLinkLoading]);

    const onListComplete = useCallback(
    (data) => {
        setIndividualGoals(data.team ? sortMeasures(data.team.individualGoals, presentation, selectedTeam.company.finalcialYearEnd, date) : [])
      if(data.team) {
        const computed = mapAPIData(data)
        setUsers(computed.users)
        setTeam(data.team)
      }
      // else
      //   setNoData(true);
    },
      [setIndividualGoals, presentation, setUsers, setTeam, selectedTeam, date]
  );

  useQuery(TEAM_IDIVIDUAL_GOALS_LIST_QUERY, {
    variables: { id: selectedTeam.id },
    onCompleted: onListComplete,
  });
  const [getIndividualGoalList] = useLazyQuery(
    TEAM_IDIVIDUAL_GOALS_LIST_QUERY, {
    variables: { id: selectedTeam.id },
    onCompleted: onListComplete,
  });

  useQuery(COMPANY_START_WEEKS, {
    onCompleted: onStarWeekComplete,
  });

  const onLoadComplete = useCallback(
    (data) => {
      setLoadedGoal(data.individualGoal)
      const days = getDisabledDays(data.individualGoal.periodMetrics)
      setUserMetrics(data.individualGoal.userMetrics)
      setGoalUsers(data.individualGoal.users)
      const selected = []
      data.individualGoal.users.map(user => selected.push(user.id))
      setSelectedUsers(selected)
      setDisabledDays(days)
      if(refresh) {
        toggleRefresh()
        toggleHasNewData()
      }
      setIsLoading(false)
      togglePageLoad(false)
    },
    [setDisabledDays, setLoadedGoal,toggleRefresh,toggleHasNewData, refresh, togglePageLoad, setIsLoading]
  );

  const [getGoal] = useLazyQuery(
    TEAM_IDIVIDUAL_GOALS_DATA_QUERY,
    {
      onCompleted: onLoadComplete,
    }
  );

  const loadGoal = useCallback(() => {
    let dates =  [1,2,3,4,5,6,7,8,9,10,11,12]
    let currentPeriod = period;

    switch (individualGoals[selectedGoalIndex] ? individualGoals[selectedGoalIndex].frequency : individualGoals[0].frequency) {
      case "Daily":
        currentPeriod = "DAY"
        dates = getDaysInMonthArray(date);
        break;
      case "Weekly":
        currentPeriod = "WEEK"
        dates = getISOWeeksInMonth(date);
        break;
      default:
        currentPeriod = "MONTH"
    }

    if(linkedId && linkedId !== "") {
      getGoal({
        variables: {
          id: linkedId,
          period: currentPeriod,
          periodKey: individualGoals[selectedGoalIndex].frequency ==='Monthly'? getPeriodKeyArray(selectedTeam.company.finalcialYearEnd, date) :
          getPeriodKey(fiscalYear, date, (individualGoals[selectedGoalIndex] ? individualGoals[selectedGoalIndex].frequency : individualGoals[0].frequency)),// ,
          periodValues: dates
        },
      });
    } else {
      getGoal({
        variables: {
          id: individualGoals[selectedGoalIndex] ? individualGoals[selectedGoalIndex].id : individualGoals[0].id,
          period: currentPeriod,
          periodKey: individualGoals[selectedGoalIndex].frequency ==='Monthly'? getPeriodKeyArray(selectedTeam.company.finalcialYearEnd, date): 
          getPeriodKey(fiscalYear, date, (individualGoals[selectedGoalIndex] ? individualGoals[selectedGoalIndex].frequency : individualGoals[0].frequency)),
          periodValues: dates
        },
      });
    }
  }, [period, getGoal, individualGoals, selectedGoalIndex, date, fiscalYear, linkedId, selectedTeam])


  useEffect(() => {
    if (!individualGoals.length) return;
    let index = setSelectedIndex(presentation)
    setSelectedGoalIndex(index)
    if(user && user.companies && user.companies.length > 0) {
      const company = user.companies.find(comp => comp.id === selectedCompany)

      if(company) {
        setMonthList(getYearList(company.finalcialYearEnd, false))
        setMonthValues(getYearList(company.finalcialYearEnd, true))
      }
    }
  }, [individualGoals, user, selectedCompany, setSelectedGoalIndex, presentation]);

  useEffect(() => {
    const months = getYearList(selectedTeam.company.finalcialYearEnd, true)
    const period = (date.getMonth() + 1) < months[0] ? `${date.getFullYear() - 1}${date.getFullYear()}` : `${date.getFullYear()}${date.getFullYear() + 1}`

    const startWeek = startWeeks.find(week => week.period === period)

    setCurrentStartWeek(startWeek)
  }, [date, startWeeks, setCurrentStartWeek, selectedTeam])

  const getNextIndex = (goBackwards) => {
    const nextIndex = goBackwards
      ? parseInt(selectedGoalIndex) - 1
      : parseInt(selectedGoalIndex) + 1;
    if (!individualGoals[nextIndex]) return goBackwards ? individualGoals.length - 1 : 0;
    return nextIndex;
  };

  const setSelectedUser = useCallback(() => {
    const { individualResultAssignedUserLogs, combinedMembers } = selectedTeam
    let userId = combinedMembers[0].id
    let standInId = combinedMembers[0].id
    if(loadedGoal) {
      const assignedUsers = individualResultAssignedUserLogs.filter(log => log.goal && log.goal.id === loadedGoal.id)
      if(assignedUsers.length > 0) {
        let assignedUser = null
        const currentMonth = date.getMonth() + 1
        let currentPeriod = `${currentMonth}${date.getFullYear()}`
        if(assignedUsers.find(log => log.periodKey === currentPeriod)) {
          assignedUser = assignedUsers.find(log => log.periodKey === currentPeriod)
        } else {

          let currentUserFound = false;
          assignedUsers.map(user => {

            const userMonth = user.periodKey.substring(0, user.periodKey.length - 4).length > 1 ? user.periodKey.substring(0, user.periodKey.length - 4) : "0" + user.periodKey.substring(0, user.periodKey.length - 4)
            const userPeriod = `${user.periodKey.substring(user.periodKey.length - 4, user.periodKey.length)}${userMonth}`;
            if(userPeriod === currentPeriod) {
              assignedUser = user
              currentPeriod = userPeriod
              currentUserFound = true
            }

            if(userPeriod < currentPeriod && !currentUserFound) {
              assignedUser = user
              currentPeriod = userPeriod
            }

            return true
          })
        }
        if(assignedUser) {
          userId = assignedUser.user ? assignedUser.user.id : userId
          standInId = assignedUser.standIn ? assignedUser.standIn.id : standInId
        }
      }
    }
    setSelectedUserId(userId)
    setSelectedStandInId(standInId)
  }, [selectedTeam, loadedGoal, date, setSelectedUserId])

  const setAssignedUser = async value => {
    const { individualResultAssignedUserLogs, id } = selectedTeam
    const assignedUser = individualResultAssignedUserLogs.find(log => log.goal && log.goal.id === loadedGoal.id && log.periodKey === `${(date.getMonth() + 1)}${date.getFullYear()}`)

    try {
      setSelectedUserId(value)
      if(assignedUser) {
        await updateUserLog(assignedUser.id, value, selectedStandInId)
        assignedUser.standIn.id = selectedStandInId
      } else {
        const response = await createUserLog(id, value, selectedStandInId, loadedGoal.id, `${(date.getMonth() + 1)}${date.getFullYear()}`)
        individualResultAssignedUserLogs.push(response.data.createIndividualResultAssignedUserLog)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const setAssignedStandIn = async value => {
    const { individualResultAssignedUserLogs, id } = selectedTeam
    const assignedUser = individualResultAssignedUserLogs.find(log => log.goal && log.goal.id === loadedGoal.id && log.periodKey === `${(date.getMonth() + 1)}${date.getFullYear()}`)

    try {
      setSelectedStandInId(value)
      if(assignedUser) {
          await updateUserLog(assignedUser.id, selectedUserId, value)
          assignedUser.standIn.id = value
      } else {
          const response = await createUserLog(id, selectedUserId, value, loadedGoal.id, `${(date.getMonth() + 1)}${date.getFullYear()}`)
          individualResultAssignedUserLogs.push(response.data.createIndividualResultAssignedUserLog)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getPeriodMetric = period => {
    const { periodMetrics } = loadedGoal

    const metric = periodMetrics.find(metric => metric.periodValue === period)

    return metric ? metric : {
      id: null,
      status: "NORMAL",
      period: getPeriod(loadedGoal.frequency),
      periodValue: period,
      periodKey: getPeriodKey(fiscalYear, date, (individualGoals[selectedGoalIndex] ? individualGoals[selectedGoalIndex].frequency : individualGoals[0].frequency)),
    }
  }

  const getUserMetric = (period, { id, firstNames, lastName }) => {
    const metric = userMetrics.find(metric => metric.periodValue === period && metric.user.id === id)

    return metric ? metric : {
      id: null,
      status: "BLUE",
      period: getPeriod(loadedGoal.frequency),
      periodValue: period,
      target: getCalculatedCurrentTarget([loadedGoal.numberFrom, loadedGoal.numberTo], date, monthValues, loadedGoal.dueDate, getPeriod(loadedGoal.frequency), period),
      value: null,
      periodKey: getPeriodKey(fiscalYear, date, (individualGoals[selectedGoalIndex] ? individualGoals[selectedGoalIndex].frequency : individualGoals[0].frequency)),
      user: {
        id: id,
        firstNames: firstNames,
        lastName: lastName
      }
    }
  }

  const getPeriodTotal = period => {
    if(!disabledDays.has(period)) {
      let total = 0
      userMetrics
        .filter(
          (metric) =>
            metric.periodValue === period &&
            users
              .filter((user) =>
                goalUsers.find((goalUser) => goalUser.id === user.id)
              )
              .find((goalUser) => goalUser.id === metric.user.id)
        )
        .map((metric) => (total += metric.value))

      return total > 999 && total < 1000000 ? parseInt(total/1000) + "K" : total > 999999 ? parseInt(total/1000000) + "K" : parseInt(total)
    }
    return null
  }

  const getTargetTotal = period => {
    if(!disabledDays.has(period)) {
      let total = 0
      users
        .filter((user) => goalUsers.find((goalUser) => goalUser.id === user.id))
        .map((user, id) => {
          const metric = userMetrics.find(
            (metric) =>
              metric.periodValue === period && metric.user.id === user.id
          );
        total = parseFloat(total) + (metric ? parseFloat(metric.target) : parseFloat(getCalculatedCurrentTarget([loadedGoal.numberFrom, loadedGoal.numberTo], date, monthValues, loadedGoal.dueDate, getPeriod(loadedGoal.frequency), period)))
        return total
      })
      return total > 999 && total < 1000000 ? parseInt(total/1000) + "K" : total > 999999 ? parseInt(total/1000000) + "K" : parseInt(total)
    }
    return null
  }

  const setNextIndex = index => {
    setIsLoading(true)
    setSelectedGoalIndex(parseInt(index))
    localStorage.setItem("selectedIndividualResultsResult", index)
  }
  // End New Code

  useEffect(() => {
    setIsLoading(true);
    if (selectedTeam.id !== 0 && individualGoals.length) loadGoal();
    else 
    {
      setIsLoading(false)
      setLoadedGoal(null)
    }
  }, [selectedGoalIndex, loadGoal, selectedTeam, setIsLoading, togglePageLoad, individualGoals, date]);

  useEffect(() => {
    if (!individualGoals.length) return;
    setSelectedUser()
  }, [selectedTeam, period, loadedGoal, setSelectedUser, individualGoals]);

  const onPeriodCellChange = ({ index: day, state }) => {
    let totals = dayTotals
    if (state === 2) {
      const nextSet = new Set(disabledDays);
      nextSet.add(day);
      if(totals[day - 1]) {
        totals[day - 1] = {
          day: day,
          count: totals[day - 1] && totals[day - 1].count,
          visible: false
        }
      }
      setDayTotals(dayTotals)
      setDisabledDays(nextSet);
    } else {
      const nextSet = new Set(disabledDays);
      nextSet.delete(day);
      if(totals[day - 1]) {
        totals[day - 1] = {
          day: day,
          count: totals[day - 1] && totals[day - 1].count,
          visible: true
        }
      }
      setDayTotals(dayTotals)
      setDisabledDays(nextSet);
    }
  };

  const changeMonth = (delta) => {

    let previousMonth = date.getMonth();
    let latestDate = addMonths(date, delta);
    let company = user.companies.find(comp => comp.id === selectedCompany);
    let currentMonth = latestDate.getMonth();
    let companyStartMonthIndex = 0;
    if(company) {
      let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
      companyStartMonthIndex = months.indexOf(company.finalcialYearEnd)
    }
    setDate(addMonths(date, delta));
    if ( companyStartMonthIndex === currentMonth + 1  || companyStartMonthIndex === previousMonth + 1) {
      setSelectedGoalIndex(0);
      getIndividualGoalList();
    }
  };

  const periodArray = useMemo(
    () => {
      if(!loadedGoal) return []
      const { frequency } = loadedGoal

      switch(frequency) {
        case "Daily":
          return [...getDaysInMonthArray(date)]
        case "Weekly":

          return [...getISOWeeksInMonth(date)]
        default:

          return monthValues
      }
    },
    [date, loadedGoal, monthValues]
  );

  const cancelLink = () => {
    setSelectedUsers(loadedGoal.users.map(user => user.id))
    setShowModal(false)
  }

  const manageUsers = (id) => {
    if(selectedUsers.includes(id)) {
      setSelectedUsers(selectedUsers.filter(userId => userId !== id))

    } else {
      setSelectedUsers([...selectedUsers, id])
    }
  }

  const IsUserIncluded = (id) => {
    return selectedUsers.includes(id)
  }

  if (selectedTeam.id !== 0 && !selectedTeam.hasIndividualGoals) {
    toast.error("Please first create Individual Goals")
    return <Redirect to="/teams/individual-goals" />
  }

  return (
    <Fragment>
      <div className="result-container-fluid">
        <Header
          selectedTeam={selectedTeam}
          presentation={presentation}
          refresh={refresh}
          hasNewData={hasNewData}
          toggleRefresh={toggleRefresh}
          loadedGoal={loadedGoal}
          setSelectedGoalIndex={setSelectedGoalIndex}
          getNextIndex={getNextIndex}
          selectedGoalIndex={selectedGoalIndex}
          individualGoals={individualGoals}
          selectedUserId={selectedUserId}
          selectedStandInId={selectedStandInId}
          setAssignedUser={setAssignedUser}
          setAssignedStandIn={setAssignedStandIn}
          setNextIndex={setNextIndex}
          setShowModal={setShowModal}
        />
        <Row className="m-4 overflow-auto">
          {isLoading && (
            <Fragment>
              <div className="page-loader">
                <div className="loader-box text-center">
                  <FontAwesomeIcon className="text-white fa-spin fa-3x" icon={faSpinner} />
                  <p className="text-white mt-4"><strong>Loading</strong></p>
                </div>
              </div>
            </Fragment>
          )}
          {loadedGoal && individualGoals.length > 0 && (
            <>
             <Row className={"align-items-baseline w-100 mb-3" + (isLoading ? " disabled" : "")}>
            {loadedGoal && (<MonthSelect date={date} onChange={changeMonth} />)}
            <Col xs={9}>
              <Row className="flex-nowrap">
                {loadedGoal &&
                periodArray.map((period, i) => (
                  <PeriodCell
                    result={getPeriodMetric(period)}
                    loading={isLoading}
                    teamId={team.id}
                    goalId={loadedGoal.id}
                    key={period}
                    day={period}
                    today={date}
                    onCellChange={onPeriodCellChange}
                    frequency={loadedGoal.frequency}
                    monthList={monthList}
                    index={i}
                    startWeek={currentStartWeek}
                  />
                ))}
              </Row>
            </Col>
          </Row>
          {loadedGoal && (
            users.filter(user => goalUsers.find(goalUser => goalUser.id === user.id)).map(({ firstNames, lastName, id, isCoach }, i) => (
              <Row
                className={classnames(
                  "align-items-baseline w-100 mb-2",
                  isCoach && "bg-light-grey",
                  isLoading && "disabled"
                )}
                key={`${id}_${date}`}
              >
                <Col className="ml-2">{(i + 1) + ".  " + firstNames + " " + lastName}</Col>
                <Col xs={9}>
                  <Row className="flex-nowrap">
                    {periodArray.map((period) => (
                      <UserCell
                        key={period}
                        day={period}
                        userId={id}
                        today={date}
                        teamId={team.id}
                        disabledDays={disabledDays}
                        result={getUserMetric(period, { firstNames, lastName, id })}
                        dayTotals={dayTotals}
                        goalId={loadedGoal.id}
                        userMetrics={userMetrics}
                        setUserMetrics={setUserMetrics}
                        formattedDate={formatDate(date, loadedGoal.frequency, monthList, monthValues, period, currentStartWeek)}
                        colour={selectedTeam.company.color}
                        frequency={loadedGoal.frequency}
                      />
                    ))}
                  </Row>
                </Col>
              </Row>
            ))
          )}
          {loadedGoal && (
            <Row
              className={classnames(
                "align-items-baseline w-100 mb-1",
                isLoading && "disabled"
              )}
            >
              <Col className="ml-2">{getLabelText("total_target")}</Col>
              <Col xs={9}>
                <Row className="flex-nowrap">
                  {periodArray.map((period, i) => (
                    <div key={`${period + "_" + i}`} className="attendance-cell text-center font-weight-bold p-1 mr-2">
                      <small><b>{getTargetTotal(period)}</b></small>
                    </div>
                  ))}
                </Row>
              </Col>
            </Row>
          )}
          {loadedGoal && (
            <Row
              className={classnames(
                "align-items-baseline w-100 mb-5",
                isLoading && "disabled"
              )}
            >
              <Col className="ml-2">{getLabelText("actual_total")}</Col>
              <Col xs={9}>
                <Row className="flex-nowrap">
                  {periodArray.map((period, i) => (
                    <div key={`${period + "_" + i}`} className="attendance-cell text-center font-weight-bold p-1 mr-2">
                      <small><b>{getPeriodTotal(period)}</b></small>
                    </div>
                  ))}
                </Row>
              </Col>
            </Row>
          )}
            </>
          )}
          {/* {noData === true && (
            <label>
                No individual goals found for selected year. Please reload to see current year data.
            </label>
          )} */}
         
        </Row>
        {loadedGoal && (
          <Modal
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            contentLabel="Result"
            // style={customStyles}
            className="standard-modal standard-modal-individual-results"
          >
            <div className="row">
              <div className="col-12 text-center">
                <h6 className="cdi-blue-txt ml-0 mb-1">{getLabelText("users_for")}</h6>
                <h6 className="cdi-blue-txt ml-0 mb-3">{loadedGoal.measurement.goalCategory.name + " - " + loadedGoal.measurement.measurement} ({loadedGoal.measurement.unit.name})</h6>
              </div>
            </div>
            <div className="row include-users-content">
              <div className="col-12 text-center">
                <Table striped bordered hover className="w-100">
                  <thead>
                  <tr>
                    <td width="10%"><b>{getLabelText("included")}</b></td>
                    <td width="90%" className="text-left"><b>{getLabelText("full_name")}</b></td>
                  </tr>
                  </thead>
                  <tbody>
                  {users.map((user, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <Form.Check type="checkbox" checked={IsUserIncluded(user.id)} onChange={() => manageUsers(user.id)}/>
                        </td>
                        <td className="text-left">
                          { user.firstNames } { user.lastName }
                        </td>
                      </tr>
                    )
                  })}
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-6">
                <button className="btn btn-primary w-100" disabled={linkLoading} onClick={() => saveUserLinks()}>
                  {linkLoading
                    ? (
                      <>{getLabelText("saving")} <FontAwesomeIcon icon={faSpinner} className="fa-spin ml-2"></FontAwesomeIcon></>
                    ) : (
                      <>{getLabelText("save")}</>
                    )
                  }
                </button>
              </div>
              <div className="col-6">
                <button className="btn btn-default cdi-blue-outline color-blue w-100" onClick={() => cancelLink()}>{getLabelText("cancel")}</button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam,
  presentation: state.presentationReducer,
  user: state.userReducer.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(IndividualResultsScreen);
