import React, { Component } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { faPlus, faSpinner, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getMeetingItems, createMeetingItem, updateItemIndex, createMeetingSubItem, updateSubItemIndex, deleteTeamMeetingSubItem, deleteTeamMeetingItem, updateMeetingItem, updateMeetingSubItem } from "../../services/MeetingViewService"
import { getGoals } from '../../services/GoalService'
import { getTeamMeasurements } from '../../services/TeamMeasureService'
import { getIndividualGoals } from '../../services/IndividualGoalService'
import { startPresentation, updatePresentation } from '../../redux/services/PresentationService';
import Modal from 'react-modal';
import { connect } from 'react-redux'
import displayErrorToast from "../../components/utils/displayErrorToast";
import { toast } from "react-toastify";
import MeetingAttendanceIcon from "../../assets/MeetingIcons/Meeting Attendance.png";
import BusinessResultsIcon from "../../assets/MeetingIcons/Business Results.png";
import IndividualResultsIcon from "../../assets/MeetingIcons/Individual Results.png";
import SkillsMatrixIcon from "../../assets/MeetingIcons/Skills Matrix.png";
import CustomersIcon from "../../assets/MeetingIcons/Customers.png";
import EscalationFeedbackIcon from "../../assets/MeetingIcons/Escalations & Feedback.png";
import CoachesFeedbackIcon from "../../assets/MeetingIcons/Escalations & Feedback.png";
import ImporvementIdeasIcon from "../../assets/MeetingIcons/Improvement Ideas.png";
import MemberDashboardIcon from "../../assets/MeetingIcons/Member Dashboard.png";
import NextStepsIcon from "../../assets/MeetingIcons/Next Steps.png";
import ProblemSolvingIcon from "../../assets/MeetingIcons/Problem Solving.png";
import SuppliersIcon from "../../assets/MeetingIcons/Suppliers.png";
import TeamDashboardIcon from "../../assets/MeetingIcons/Team Dashboard.png";
import TeamResultsIcon from "../../assets/MeetingIcons/Team Results.png";
import ValuesBehavioursIcon from "../../assets/MeetingIcons/Values Behaviours.png";
import ScheduledEventsIcon from "../../assets/MeetingIcons/Scheduled Events.png";
import ProjectTrackerIcon from "../../assets/MeetingIcons/Project Tracking.png";
import CoachingReviewsIcon from "../../assets/MeetingIcons/Coaching Review.png";
import ValueStreamPartnersIcon from "../../assets/MeetingIcons/Customers.png";
import LabelComponent from "../../components/utils/getCompanyLabel";
import SubHeaderComponent from "../../components/SubHeaderComponent";
import * as DefaultLabels from '../../assets/glossary.json';
// icons
import Add from '../../assets/ButtonIcons/Add Blue.svg';
import Delete from '../../assets/ButtonIcons/Delete Blue.svg';
import Edit from '../../assets/ButtonIcons/Edit Blue.svg';
import Loader from '../../assets/ButtonIcons/Loader Blue.svg';
import MoveDown from '../../assets/ButtonIcons/Move Down Blue.svg';
import MoveDownRed from '../../assets/ButtonIcons/Move Down Red.svg';
import MoveUp from '../../assets/ButtonIcons/Move Up Blue.svg';
import MoveUpRed from '../../assets/ButtonIcons/Move Up Red.svg';

const labels = DefaultLabels.default;

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

function getYearList(startMonth, isValues) {
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const array = []
  const index = months.indexOf(startMonth)

  for (let i = index; i < months.length; i++) {
    array.push(isValues ? (i + 1) : months[i])
  }

  for (let i = 0; i < index; i++) {
    array.push(isValues ? (i + 1) : months[i])
  }

  return array
}

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 20;

const itemNotValid = (item) => {
  return (((item.url === "/what/individual-results" || item.url === "/what/team-goals") && !item.children.length) || ((item.url === "/now-what/escalations" || item.url === "/now-what/team-dashboard") && item.target === "OTHER" && !item.children.length))
}

const getItemStyle = (isDragging, draggableStyle, item) => {
  const notValid = itemNotValid(item)
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: `${grid * 0.5}px ${grid * 1}px ${grid * 2}px ${grid * 1}px`,
    margin: `${grid}px ${grid}px 0 0`,
    borderRadius: "10px",
    width: "220px",
    minWidth: "220px",
    maxWidth: "220px",
    height: "280px",
    maxHeight: "280px",
    border: notValid ? "2px solid #c00000" : "2px solid #005e85",
    color: "#FFF",

    // change background colour if dragging
    background: isDragging ? notValid? '#c00000' : '#005e85' : '#dadada',

    // styles we need to apply on draggables
    ...draggableStyle,
  }
};

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'white',
  display: 'flex',
  padding: grid,
  overflowX: 'scroll',
});

const getSubListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'lightgreen',
  display: 'flex',
  padding: grid,
  overflow: 'auto',
});

class MultiLevelMeetingViewScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      showModal: false,
      screens: [
        {
          url: "/what/team-attendance",
          title: "Meeting Attendance",
          icon: MeetingAttendanceIcon,
          key: "meeting_attendance_screen"
        },
        {
          url: "/what/team-goals",
          title: "Team Results",
          icon: TeamResultsIcon,
          key: "team_results_screen"
        },
        {
          url: "/what/customers",
          title: "Customers",
          icon: CustomersIcon,
          key: "customers_screen"
        },
        {
          url: "/what/suppliers",
          title: "Suppliers",
          icon: SuppliersIcon,
          key: "suppliers_screen"
        },
        {
          url: "/what/values-behaviours",
          title: "Values Behaviours",
          icon: ValuesBehavioursIcon,
          key: "values_behaviours"
        },
        {
          url: "/what/business-results",
          title: "Business Results",
          icon: BusinessResultsIcon,
          key: "business_results_screen"
        },
        {
          url: "/what/skills-matrix",
          title: "Skills Matrix",
          icon: SkillsMatrixIcon,
          key: "skills_matrix_screen"
        },
        {
          url: "/what/individual-results",
          title: "Individual Results",
          icon: IndividualResultsIcon,
          key: "individual_results_screen"
        },
        {
          url: "/what/scheduled-events",
          title: "Scheduled Events",
          icon: ScheduledEventsIcon,
          key: "scheduled_events_screen"
        },
        {
          url: "/so-what/project-tracker",
          title: "Project Tracker",
          icon: ProjectTrackerIcon,
          key: "project_tracker_screen"
        },
        {
          url: "/what/coaching-reviews",
          title: "Coaching Reviews",
          icon: CoachingReviewsIcon,
          key: "coaching_reviews_screen"
        },
        {
          url: "/what/value-stream-partners",
          title: "Value Stream Partners",
          icon: ValueStreamPartnersIcon,
          key: "value_stream_partners_screen"
        },
        {
          url: "/what/leadership-routines",
          title: "Leadership Routines",
          icon: ScheduledEventsIcon,
          key: "leadership_routines_screen"
        },
        {
          url: "/so-what/problem-solving",
          title: "Problem Solving",
          icon: ProblemSolvingIcon,
          key: "problem_solving_screen"
        },
        {
          url: "/so-what/improvement-ideas",
          title: "Improvement Ideas",
          icon: ImporvementIdeasIcon,
          key: "impr_ideas_screen"
        },
        {
          url: "/now-what/actions",
          title: "Next Steps",
          icon: NextStepsIcon,
          key: "next_steps_screen"
        },
        {
          url: "/now-what/escalations",
          title: "Escalations & Feedback",
          icon: EscalationFeedbackIcon,
          key: "escalations_screen"
        },
        {
          url: "/now-what/coaches-feedback",
          title: "Coaches Feedback",
          icon: CoachesFeedbackIcon,
          key: "coaches_feedback_screen"
        },
        {
          url: "/now-what/team-dashboard",
          title: "Team Dashboard",
          icon: TeamDashboardIcon,
          key: "team_dashboard_screen"
        },
        {
          url: "/now-what/member-dashboard",
          title: "Member Dashboard",
          icon: MemberDashboardIcon,
          key: "member_dashboard_screen"
        },
        {
          url: "/now-what/celebrating-success",
          title: "Celebrating Success",
          icon: MemberDashboardIcon,
          key: "celebrating_success_screen"
        },
      ],
      frequencies: [
        {
          value: "daily",
          text: "Daily"
        },
        {
          value: "weekly",
          text: "Weekly"
        },
        {
          value: "monthly",
          text: "Monthly"
        }
      ],
      days: [
        {
          value: "monday",
          text: "M"
        },
        {
          value: "tuesday",
          text: "T"
        },
        {
          value: "wednesday",
          text: "W"
        },
        {
          value: "thursday",
          text: "T"
        },
        {
          value: "friday",
          text: "F"
        },
        {
          value: "saturday",
          text: "S"
        },
        {
          value: "sunday",
          text: "S"
        }
      ],
      weeks: [
        {
          value: "wk1",
          text: "WK 1"
        },
        {
          value: "wk2",
          text: "WK 2"
        },
        {
          value: "wk3",
          text: "WK 3"
        },
        {
          value: "wk4",
          text: "WK 4"
        },
        {
          value: "lw",
          text: "WK L"
        },
      ],
      selectedScreen: "",
      selectedFrequency: "",
      selectedDays: [],
      selectedWeeks: [],
      loading: false,
      loadingType: "save",
      loadingId: "",
      expandItemId: 0,
      subItems: [],
      goals: [],
      measures: [],
      individualGoals: [],
      showSubModal: false,
      selectedType: "",
      selectedArray: [],
      showExpand: false,
      selectedId: "",
      selectedSubValue: "",
      editId: 0,
      period: "",
      selectedTarget: "OWN"
    };
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onSubDragEnd = this.onSubDragEnd.bind(this);
    this.props.togglePageLoad(true)
  }

  async componentDidMount () {
    if (this.props.selectedTeam.id !== 0) {
      await this.fetchMeetingItems()
      await this.fetchGoals()

      this.props.togglePageLoad(false)
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.selectedTeam.id !== this.props.selectedTeam.id) {
      await this.fetchMeetingItems()
      await this.fetchGoals()
      this.props.togglePageLoad(false)
    }
  }

  storeSession = (newSession) => {
    localStorage.setItem("session", JSON.stringify(newSession))
    this.props.updatePresentation(newSession)
  }

  getLabelText = key => <LabelComponent val={key}/>

  getContentType = (child) => {
    switch(child.type) {
      case "BUSINESS":

        return `${child.goal.measurement.measurement} (${child.goal.measurement.unit.name})`
      case "TEAM":

        return `${child.measure.measurement.measurement} (${child.measure.measurement.unit.name})`
      case "LVL2":

        return `${child.selected.department.name}(${child.selected.name})`
      default:

        return `${child.individualGoal.measurement.measurement} (${child.individualGoal.measurement.unit.name})`
    }
  }

  fetchMeetingItems = async() => {
    const response = await getMeetingItems(this.props.selectedTeam.id, "MULTILEVEL")
    let items = []
    let subItems = []
    if(response.data.teamMeetingItems.length > 0) {
      response.data.teamMeetingItems.sort((a, b) => a.index - b.index).map(item => {
        const content = this.state.screens.find(
          (screen) => screen.url === item.screen
        )
        return items.push({
          id: `${item.id}`,
          content: content.key ? this.getLabelText(content.key) : content.title,
          isAddButton: false,
          frequency: item.frequency,
          days: item.days,
          weeks: item.weeks,
          icon: this.state.screens.find(screen => screen.url === item.screen).icon,
          url: item.screen,
          children: item.children,
          target: item.target
        })
      })
    }

    items.map(item => {
      if (item.id === this.state.expandItemId && item.children.length > 0) {
        item.children.map(child => {
          return subItems.push({
            id: `${child.id}`,
            content: this.getContentType(child),
            isAddButton: false,
            frequency: child.frequency,
            weeks: child.weeks,
            days: child.days,
            icon: this.state.screens.find(screen => screen.url === item.url).icon,
            children: []
          })
        })
      }

      return true
    })

    subItems.push({
      id: `item-0`,
      content: this.getLabelText("add_item"),
      isAddButton: true,
      frequency: "",
      days: "",
      icon: faPlus,
      url: ""
    })

    items.push({
      id: `item-${response.data.teamMeetingItems.length}`,
      content: this.getLabelText("add_item"),
      isAddButton: true,
      frequency: "",
      days: "",
      icon: faPlus,
      url: "",
      children: []
    });

    this.setState({
      items,
      subItems
    })
  }

  getPeriod = () => {
    const { finalcialYearEnd } = this.props.selectedTeam.company
    const months = getYearList(finalcialYearEnd, true)
    const period = (new Date().getMonth() + 1) < months[0] ? `${new Date().getFullYear() - 1}${new Date().getFullYear()}` : `${new Date().getFullYear()}${new Date().getFullYear() + 1}`

    return period
  }

  fetchGoals = async() => {
    const goals = await getGoals()
    const measures = await getTeamMeasurements(this.props.selectedTeam.id)
    const individual = await getIndividualGoals(this.props.selectedTeam.id)
    const period = this.getPeriod()

    this.setState({
     goals: goals.data.goals,
     measures: measures.data.teamMeasurements.filter(goal => goal.period === period),
     individualGoals: individual.data.individualGoals.filter(goal => goal.period === period),
     period
    })
  }

  async onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      (result.destination.index === (this.state.items.length - 1) ? result.destination.index - 1 : result.destination.index)
    );

    let meetingItems = []

    items.map(item => {
      if(!item.isAddButton) {
        return meetingItems.push(item.id)
      }
      return false
    })

    updateItemIndex(result.draggableId, this.props.selectedTeam.id, meetingItems.join(","))

    this.setState({
      items,
    });
  }

  async onSubDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const subItems = reorder(
      this.state.subItems,
      result.source.index,
      (result.destination.index === (this.state.subItems.length - 1) ? result.destination.index - 1 : result.destination.index)
    );

    let meetingItems = []

    subItems.map(item => {
      if(!item.isAddButton) {
        return meetingItems.push(item.id)
      }
      return false
    })

    updateSubItemIndex(result.draggableId, meetingItems.join(","))

    this.setState({
      subItems,
    });
  }

  addItem = () => {
    this.setState({
      showModal: true,
      selectedFrequency: "daily"
    })
  }

  toggleDay = (day) => {
    const { selectedDays } = this.state

    if(selectedDays.includes(day)) {
      const index = selectedDays.indexOf(day)

      selectedDays.splice(index, 1)
    } else {
      selectedDays.push(day)
    }

    this.setState({
      selectedDays
    })
  }

  toggleWeek = (week) => {
    const { selectedWeeks } = this.state

    if(selectedWeeks.includes(week)) {
      const index = selectedWeeks.indexOf(week)

      selectedWeeks.splice(index, 1)
    } else {
      selectedWeeks.push(week)
    }

    this.setState({
      selectedWeeks
    })
  }

  setEdit = (id, parent = false) => {
    let options
    let item

    if(parent) {
      item = this.state.items.find(item => item.id === id)

      options = {
        showModal: true,
        selectedScreen: item.url,
        selectedTarget: item.target
      }
    } else {
      item = this.state.subItems.find(item => item.id === id)
      options = {
        showSubModal: true,
        selectedId: id,
        selectedSubValue: item.content?.trim()
      }
    }


    options = {
      ...options,
      selectedDays: item.days && item.days !== "" ? item.days.split(",") : [],
      selectedWeeks: item.weeks && item.weeks !== "" ? item.weeks.split(",") : [],
      editId: id,
      selectedFrequency: item.frequency,
    }

    this.setState(options)
  }

  closeModal = () => {
    this.setState({
      showModal: false,
      selectedDays: [],
      selectedWeeks: [],
      selectedFrequency: "",
      selectedScreen: "",
      editId: 0
    });
  }

  toggleExpandItem = (itemId, selectedType, children) => {
    const { showExpand, goals, measures, individualGoals } = this.state

    let subItems = []

    if(children.length > 0) {
      children.sort((a, b) => a.index - b.index).map(item => {
        return subItems.push({
          id: `${item.id}`,
          content: this.getContentType(item),
          isAddButton: false,
          frequency: item.frequency,
          days: item.days,
          weeks: item.weeks,
          icon: this.state.items.find(item => item.id === itemId).icon,
          children: []
        })
      })
    }
    subItems.push({
      id: `item-0`,
      content: this.getLabelText("add_item"),
      isAddButton: true,
      frequency: "",
      days: "",
      icon: faPlus,
      url: ""
    })

    let selectedArray = []

    if(selectedType === "GOAL") {
      selectedArray = goals.map(goal => {
        return {
          value: goal.id,
          text: `${goal.measurement.measurement}(${goal.measurement.unit.name})`
        }
      })
    } else if(selectedType === "MEASURE") {
      selectedArray = measures.map(goal => {
        return {
          value: goal.id,
          text: `${goal.measurement.measurement}(${goal.measurement.unit.name})`
        }
      })
    } else if(selectedType === "INDIVIDUAL") {
      selectedArray = individualGoals.map(goal => {
        return {
          value: goal.id,
          text: `${goal.measurement.measurement}(${goal.measurement.unit.name})`
        }
      })
    } else {
      selectedArray = this.props.teams.map(team => {
        return {
          value: team.id,
          text: `${team.department.name}(${team.name})`
        }
      })
    }

    this.setState({
      subItems,
      expandItemId: showExpand ? 0 : itemId,
      showExpand: !showExpand,
      selectedType: showExpand ? "" : selectedType,
      selectedArray: showExpand ? [] : selectedArray
    })
  }

  addSubItem = () => {
    const { showSubModal } = this.state

    this.setState({
      showSubModal: !showSubModal,
      selectedFrequency: "daily"
    })
  }

  closeSubModal = () => {
    this.setState({
      showSubModal: false,
      selectedDays: [],
      selectedWeeks: [],
      selectedFrequency: "",
      selectedId: "",
      selectedSubValue: "",
      editId: 0
    })
  }

  getSelectedArray = () => {
    const { selectedType, goals, measures, individualGoals } = this.state

    return selectedType === "GOAL" ? goals : selectedType === "MEASURE" ? measures : individualGoals
  }

  handleSubmit = async () => {
    const { selectedDays, selectedWeeks, selectedScreen, selectedFrequency, items, editId, selectedTarget } = this.state
    const { selectedTeam } = this.props
    this.setState({
      loading: true,
      loadingType: "save"
    })
    try {
      if(editId !== 0) {
        await updateMeetingItem(editId, selectedFrequency, selectedDays.join(","), selectedWeeks.join(","))
      } else {
        if(selectedScreen === "/now-what/team-dashboard" || selectedScreen === "/now-what/escalations") {
          await createMeetingItem(selectedTeam.id, selectedScreen, selectedFrequency, selectedDays.join(","), items.length - 1, "MULTILEVEL", selectedWeeks.join(","), selectedTarget)
        } else {
          await createMeetingItem(selectedTeam.id, selectedScreen, selectedFrequency, selectedDays.join(","), items.length - 1, "MULTILEVEL", selectedWeeks.join(","))
        }
      }
      await this.fetchMeetingItems()

      this.closeModal()
      this.setState({
        loading: false,
        loadingType: ""
      })
    } catch (err) {
      displayErrorToast(err)
      this.setState({
        loading: false,
        loadingType: ""
      })
    }
  }

  deleteItem = async (id) => {
    this.setState({
      loading: true,
      loadingType: "delete",
      loadingId: id
    })
    try {
      await deleteTeamMeetingItem(id)
      await this.fetchMeetingItems()

      this.closeModal()
      this.setState({
        loading: false,
        loadingType: "",
        loadingId: 0
      })
    } catch (err) {
      displayErrorToast(err)
      this.setState({
        loading: false,
        loadingType: "",
        loadingId: 0
      })
    }
  }

  deleteSubItem = async (id) => {
    this.setState({
      loading: true,
      loadingType: "delete",
      loadingId: id
    })
    try {
      await deleteTeamMeetingSubItem(id)
      await this.fetchMeetingItems()

      this.closeModal()
      this.setState({
        loading: false,
        loadingType: "",
        loadingId: 0
      })
    } catch (err) {
      displayErrorToast(err)
      this.setState({
        loading: false,
        loadingType: "",
        loadingId: 0
      })
    }
  }

  validInput = () => {
    const { selectedDays, selectedWeeks, selectedFrequency } = this.state

    let valid = true

    if(selectedFrequency === "") {
      valid = false
    }

    if(selectedFrequency === "weekly" && selectedDays.length < 1) {
      valid = false
    }

    if(selectedFrequency === "monthly" && (selectedDays.length < 1 || selectedWeeks.length < 1)) {
      valid = false
    }

    return valid
  }

  handleSubItemSubmit = async () => {
    const { selectedDays, selectedWeeks, selectedFrequency, items, expandItemId, selectedType, selectedId, editId } = this.state
    const { selectedTeam } = this.props
    this.setState({
      loading: true,
      loadingType: "save"
    })
    try {
      if(editId !== 0) {
        await updateMeetingSubItem(
          editId,
          selectedFrequency,
          selectedDays.join(","),
          selectedWeeks.join(",")
        )

      } else {
        await createMeetingSubItem(
          selectedTeam.id,
          expandItemId,
          selectedType === "GOAL" ? selectedId : null,
          selectedType === "MEASURE" ? selectedId : null,
          selectedType === "LVL2" ? selectedId : null,
          selectedType === "INDIVIDUAL" ? selectedId : null,
          selectedFrequency, selectedDays.join(","),
          items.find(item => item.id === expandItemId).children.length,
          selectedType === "GOAL" ? "BUSINESS" : selectedType === "MEASURE" ? "TEAM" : selectedType,
          selectedWeeks.join(",")
        )

      }
      await this.fetchMeetingItems()

      this.closeSubModal()
      this.setState({
        loading: false,
        loadingType: ""
      })
    } catch (err) {
      displayErrorToast(err)
      this.setState({
        loading: false,
        loadingType: ""
      })
    }
  }

  setLoading = (loading, type) => {
    this.setState({
      loading: loading,
      loadingType: type
    })
  }

  getText = val => {
    const { user } = this.props;

    const companyId = localStorage.getItem("companyId");
    let lang = "EN";
    let langLabels = labels.find(lbl => lbl.lang === lang);
    let def = langLabels.glossary.find(label => label.key === val);

    if(user && user.companies && user.companies.length) {
      const company = user.companies.find(company => company.id === companyId);
      if(company) {
        lang = company.language;
        langLabels = labels.find(lbl => lbl.lang === lang);
        def = langLabels.glossary.find(label => label.key === val);
        if(!def) {
          lang = "EN";
          langLabels = labels.find(lbl => lbl.lang === lang);
          def = langLabels.glossary.find(label => label.key === val);
        }
      }
      if(company && company.companyGlossaries) {
        const custom = company.companyGlossaries.find(label => label.key === val);

        if(custom) return custom.value
      }
    }

    if(!def) {
      console.log(val)
    }
    return def.value
  }

  getChildId = (child) => {
    switch(child.type) {
      case "BUSINESS":

        return child.goal.id
      case "TEAM":

        return child.measure.id
      case "LVL2":

        return child.selected.id
      default:

        return child.individualGoal.id
    }
  }

  startSession = async() => {
    this.setLoading(true, "session")
    const type = "MULTILEVEL"
    const response = await getMeetingItems(this.props.selectedTeam.id, type, true)

    let schedule = []
    let session
    response.data.teamMeetingItems.sort((a,b) => a.index - b.index).map(item => {
      if((item.screen === "/what/individual-results" || item.screen === "/what/team-goals") && !item.currentChildren.length) return false
      if((item.screen === "/now-what/escalations" || item.screen === "/now-what/team-dashboard") && item.target === "OTHER" && !item.currentChildren.length) return false

      let obj = {
        screen: item.screen,
        children: []
      }

      if(item.currentChildren && item.currentChildren.length > 0 && item.screen !== "/what/business-results") {
          let children = []

          item.currentChildren.sort((a,b) => a.index - b.index).map(child => {
            children.push({
              id: this.getChildId(child),
              title: this.getContentType(child)
            })
            return true
          })

          obj = {
            ...obj,
            children
          }

      }

      schedule.push(obj)

      return true
    })

    if(schedule.length < 1) {
      this.setLoading(false, "")
      toast.error(this.getText("no_items_scheduled"))
      return false
    }

    const page = window.location.pathname

    session = {
      ...this.props.presentation,
      type,
      schedule,
      active: true,
      lastPage: page,
      childActive: schedule[0].children.length > 0,
      childLastIndex: schedule[0].children.length > 0 ? schedule[0].children.length - 1 : 0
    }

    this.setLoading(false, "")
    this.props.startPresentation()
    this.storeSession(session)

    this.props.history.push(schedule[0].screen)
  }

  getImg = (item) => {
    const {expandItemId} = this.state
    if (item.id === expandItemId) {
      if (itemNotValid(item)) {
        return MoveUpRed
      } else {
        return MoveUp
      }
    } else {
      if (itemNotValid(item)) {
        return MoveDownRed
      } else {
        return MoveDown
      }
    }
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    const {
      showModal,
      items,
      screens,
      expandItemId,
      showSubModal,
      selectedType,
      selectedArray,
      loading,
      loadingType
    } = this.state
    return (
      <div>
        <SubHeaderComponent>
          <div className="row">
            <div className="col-12 mlm">
              <table>
                <tbody>
                <tr>
                  <td vAlign="middle">
                    <button className='btn btn-primary btn-nrml ml-2' onClick={() => this.startSession()} style={{ cursor: "pointer" }}>
                      {loading && loadingType === "session" ? (
                        <span>MLM<FontAwesomeIcon className="text-white fa-spin ml-2" icon={faSpinner}></FontAwesomeIcon></span>
                      ) : (
                        <span>MLM<FontAwesomeIcon className="ml-2" icon={faPlay}></FontAwesomeIcon></span>
                      )}
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </SubHeaderComponent>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal" className="drop-scroll">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                {items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={item.isAddButton}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                            ...getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                              item
                            )}}
                      >
                        {!snapshot.isDragging && !item.isAddButton && (
                          <table className="w-100">
                            <tbody>
                              <tr>
                                <td className="text-right">
                                  {(item.url === "/what/team-goals" || item.url === "/what/individual-results" || ((item.url === "/now-what/escalations" || item.url === "/now-what/team-dashboard") && this.props.selectedTeam.department.departmentType.level !== "LVL1" && item.target === "OTHER")) && (
                                    <btn
                                      className={"btn-icons-container " + (itemNotValid(item) ? "border-color-red" : "border-color-blue")}
                                      style={{ paddingLeft: "9px!important" }}
                                      onClick={() => this.toggleExpandItem(item.id, item.url === "/what/team-goals" ? "MEASURE" : item.url === "/what/individual-results" ? "INDIVIDUAL" : "LVL2", item.children)}
                                    >
                                      <img src={this.getImg(item)} alt={this.getImg} className="btn-icons" />

                                    </btn>
                                  )}
                                  <btn
                                    className="btn-icons-container"
                                    style={{ paddingLeft: "9px!important" }}
                                    onClick={() => this.setEdit(item.id, true)}
                                    >
                                    <img src={Edit} alt="Edit" className="btn-icons"/>
                                  </btn>

                                  <btn className="btn-icons-container" onClick={() => this.deleteItem(item.id)} style={{ paddingLeft: "9px!important" }}>

                                      {this.state.loading && this.state.loadingType === "delete" && this.state.loadingId === item.id
                                        ? (
                                          <img src={Loader} alt="Loader" className="loader-spinner"/>
                                        ) : (
                                          <img src={Delete} alt="Delete" className="btn-icons"/>
                                        )
                                      }
                                  </btn>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        )}
                        <table className={"w-100 mt-4" + (item.isAddButton ? " h-100" : "")}>
                          <tbody>
                            {!item.isAddButton && (
                              <tr>
                                <td className="text-center pt-1">
                                  <img src={item.icon} style={{ objectFit: "contain", width: "100px", height: "100px" }} alt="Icon"/>
                                </td>
                              </tr>
                            )}
                            <tr>
                              <td className="text-center pb-3">
                                <label className={"meeting-view-txt " + (snapshot.isDragging ? "text-white" : "color-blue")}>{item.content}</label>
                              </td>
                            </tr>
                            {item.isAddButton && (
                              <tr>
                                <td className="text-center">
                                  <button className="btn btn-primary w-100" onClick={() => this.addItem()}><img src={Add} alt="Add" className="btn-icons"/></button>
                                </td>
                              </tr>
                            )}
                            {itemNotValid(item) && (item.url !== "/now-what/escalations" && item.url !== "/now-what/team-dashboard") && (
                              <tr>
                                <td className="text-center">
                                  <label className="cdi-red-txt ml-0">Please select</label>
                                </td>
                              </tr>
                            )}
                            {((item.url === "/now-what/escalations" ||
                              item.url ===
                                "/now-what/team-dashboard") &&
                              this.props.selectedTeam.department
                                .departmentType.level !== "LVL1") && (
                                  <tr>
                                    <td className="text-center">
                                      <label className={"ml-0 " + (itemNotValid(item) ? "cdi-red-txt" : "cdi-blue-txt")}><b>{item.target ? item.target : "OWN"}</b></label>
                                    </td>
                                  </tr>
                                )}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {expandItemId !== 0 && (
          <>
          <DragDropContext onDragEnd={this.onSubDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal" className="drop-scroll">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getSubListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >

                  {this.state.subItems.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={item.isAddButton}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                              ...getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                                item
                              )}}
                        >
                          {!snapshot.isDragging && !item.isAddButton && (
                            <table className="w-100">
                              <tbody>
                                <tr>
                                  <td className="text-right">
                                    <btn
                                      className="btn-icons-container"
                                      style={{ paddingLeft: "9px!important" }}
                                      onClick={() => this.setEdit(item.id)}
                                      >
                                      <img src={Edit} alt="Edit" className="btn-icons"/>
                                    </btn>
                                      <btn className="btn-icons-container" onClick={() => this.deleteSubItem(item.id)} style={{ paddingLeft: "9px!important" }}>

                                          {this.state.loading && this.state.loadingType === "delete" && this.state.loadingId === item.id
                                            ? (
                                              <img src={Loader} alt="Loader" className="loader-spinner"/>
                                            ) : (
                                              <img src={Delete} alt="Delete" className="btn-icons"/>
                                            )
                                          }
                                      </btn>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          )}
                          <table className={"w-100 mt-4" + (item.isAddButton ? " h-100" : "")}>
                            <tbody>
                              {!item.isAddButton && (
                                <tr>
                                  <td className="text-center pt-1">
                                    <img src={item.icon} style={{ objectFit: "contain", width: "100px", height: "100px" }} alt="Icon"/>
                                  </td>
                                </tr>
                              )}
                              <tr>
                                <td className="text-center pb-3">
                                  <label className={"meeting-view-txt " + (snapshot.isDragging ? "text-white" : "color-blue")}>{item.content}</label>
                                </td>
                              </tr>
                              {item.isAddButton && (
                                <tr>
                                  <td className="text-center">
                                    <button className="btn btn-primary w-100" onClick={() => this.addSubItem()}><img src={Add} alt="Add" className="btn-icons"/></button>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Modal
              isOpen={showSubModal}
              onRequestClose={() => this.closeSubModal()}
              contentLabel="Add Meeting"
              style={customStyles}
            >
              <div className="row">
                <div className="col-12 text-center">
                  <label className="meeting-view-txt">{this.getLabelText("add_item")}</label>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-12">
                  <select
                    className={"form-control cdi-ddl w-100 ml-0" + (this.state.editId !== 0 ? " disabled" : "")}
                    value={this.state.selectedId}
                    onChange={(e) => this.setState({ selectedId: e.target.value, selectedType: selectedType})}
                    >
                    <option disabled value="">Select Item</option>
                    {this.state.editId !== 0 &&
                      <option value="">
                        {this.state.selectedSubValue}
                      </option>
                    }
                      {selectedArray.map((item, i) => {
                        return <option key={i} value={item.value}>{item.text}</option>
                      })}
                  </select>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-6">
                  <button className="btn btn-primary w-100" disabled={this.state.loading || !this.validInput() || this.state.selectedId === ""} onClick={() => this.handleSubItemSubmit()}>
                    {this.state.loading && this.state.loadingType === "save"
                      ? (
                        <>{this.getLabelText("saving")} <FontAwesomeIcon icon={faSpinner} className="fa-spin ml-2"></FontAwesomeIcon></>
                      ) : (
                        <>{this.getLabelText("save")}</>
                      )
                    }
                  </button>
                </div>
                <div className="col-6">
                  <button className="btn btn-default cdi-blue-outline color-blue w-100" onClick={() => this.closeSubModal()}>{this.getLabelText("cancel")}</button>
                </div>
              </div>
            </Modal>
          </>
        )}
        <Modal
            isOpen={showModal}
            onRequestClose={() => this.closeModal()}
            contentLabel="Add Meeting"
            style={customStyles}
          >
            <div className="row">
              <div className="col-12 text-center">
                <label className="meeting-view-txt">{this.getLabelText('add_meeting_screen')}</label>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-12">
                <select
                  className={"form-control cdi-ddl w-100 ml-0" + (this.state.editId !== 0 ? " disabled" : "")}
                  value={this.state.selectedScreen}
                  onChange={(e) => this.setState({ selectedScreen: e.target.value})}
                  >
                  <option disabled value="">{this.getText("select_screen")}</option>
                  {screens.map((screen, i) => {
                    return <option key={i} value={screen.url}>{screen.key ? this.getText(screen.key) : screen.title}</option>
                  })}
                </select>
              </div>
            </div>
            {((this.state.selectedScreen === "/now-what/escalations" ||
              this.state.selectedScreen ===
                "/now-what/team-dashboard") &&
              this.props.selectedTeam.department
                .departmentType.level !== "LVL1") && (
              <>
              <div className="row mt-2">
                <div className="col-12">
                  <label>Select Target View</label>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-12">
                  <ButtonGroup toggle className={"w-100" + (this.state.editId !== 0 ? " disabled" : "")}>
                    <ToggleButton
                      type="radio"
                      variant="secondary"
                      name="createType"
                      value="OWN"
                      checked={this.state.selectedTarget === "OWN"}
                      onChange={(e) => this.setState({ selectedTarget: e.target.value })}
                    >
                      {this.getLabelText("own")}
                    </ToggleButton>
                    <ToggleButton
                      type="radio"
                      variant="secondary"
                      name="createType"
                      value="OTHER"
                      checked={this.state.selectedTarget === "OTHER"}
                      onChange={(e) => this.setState({ selectedTarget: e.target.value })}
                    >
                      {this.getLabelText("other")}
                    </ToggleButton>
                  </ButtonGroup>
                </div>
              </div>
              </>
            )}
            <div className="row mt-4">
              <div className="col-6">
                <button className="btn btn-primary w-100" disabled={this.state.loading || !this.validInput() || this.state.selectedScreen === ""} onClick={() => this.handleSubmit()}>
                  {this.state.loading && this.state.loadingType === "save"
                    ? (
                      <>{this.getLabelText("saving")} <FontAwesomeIcon icon={faSpinner} className="fa-spin ml-2"></FontAwesomeIcon></>
                    ) : (
                      <>{this.getLabelText("save")}</>
                    )
                  }
                </button>
              </div>
              <div className="col-6">
                <button className="btn btn-default cdi-blue-outline color-blue w-100" onClick={() => this.closeModal()}>{this.getLabelText("cancel")}</button>
              </div>
            </div>
          </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam,
  presentation: state.presentationReducer,
  teams: state.teamsReducer.teams,
  user: state.userReducer.user
})

const mapDispatchToProps = {
  startPresentation,
  updatePresentation
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiLevelMeetingViewScreen)
