import React, { Fragment } from "react";
import { Row, Col, Button } from "react-bootstrap";
import RealtimeRefreshComponent from "../../components/RealtimeRefreshComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import {
  faCaretSquareLeft,
  faCaretSquareRight,
  faUsers
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import LabelComponent from "../../components/utils/getCompanyLabel";

const getLabelText = key => <LabelComponent val={key}/>

const Header = ({
  selectedTeam,
  presentation,
  refresh,
  hasNewData,
  toggleRefresh,
  loadedGoal,
  setSelectedGoalIndex,
  getNextIndex,
  selectedGoalIndex,
  individualGoals,
  selectedUserId,
  selectedStandInId,
  setAssignedUser,
  setAssignedStandIn,
  setNextIndex,
  setShowModal
}) => {

  const signOutStyle = {

    float: "right"
  }
  const titleStyle = {

    float: "left"
  }

  return (
    <Fragment>
      <Row className="result-title-row extend-container">
        <Col xl={4} md={4} xs={4}>
          <table className="w-100 h-100">
            <tbody>
              <tr>
                <td valign="middle" className="text-left">
                  <h4 className="app-title ml-2 mr-3 d-inline" style={titleStyle}>
                    {getLabelText("individual_results_screen")}
                  </h4>
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
        <Col xl={4} md={4} xs={4}>
          {loadedGoal
            ? (
              <Fragment>
                <table className="w-100 h-100">
                  <tbody>
                    <tr>
                      <td width="10%" valign="middle">
                        <h6 className="app-title">{getLabelText("owners")}</h6>
                      </td>
                      <td width="45%" valign="middle" className="pl-1">
                        <select className="form-control" value={selectedUserId} onChange={(e) => setAssignedUser(e.target.value)}>
                          {selectedTeam.combinedMembers.map((member, i) =>(
                            <option value={member.id} key={i}>{member.firstNames} {member.lastName}</option>
                          ))}
                        </select>
                      </td>
                      <td width="45%" valign="middle" className="pl-1">
                        <select className="form-control" value={selectedStandInId} onChange={(e) => setAssignedStandIn(e.target.value)}>
                          {selectedTeam.combinedMembers.map((member, i) =>(
                              <option value={member.id} key={i}>{member.firstNames} {member.lastName}</option>
                          ))}
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Fragment>
            ) : null
          }
        </Col>
        <Col xl={4} md={4} xs={4}>
          <table className="w-100 h-100">
            <tbody>
              <tr>
                <td valign="middle">
                  <span style={signOutStyle}>
                    <Col className="refreshBtn">
                      <div className={"refreshBtn"}>
                      <RealtimeRefreshComponent
                        refresh={refresh}
                        hasNewData={hasNewData}
                        toggleRefresh={toggleRefresh}
                      />
                    </div>
                    {loadedGoal && (<Button className={"btn btn-primary btn-nrml ml-2"} onClick={() => {setShowModal(true);}}>{getLabelText("manage")}<FontAwesomeIcon className="ml-2" icon={faUsers} /></Button>)}
                    <button className="btn btn-primary mr-2 ml-2">
                      <Link className="btn-text-Wht m-0 font-weight-bold" to="/so-what/problem-solving">PS</Link>
                    </button>
                    <button className="btn btn-primary mr-2">
                      <Link className="btn-text-Wht m-0 font-weight-bold" to="/now-what/actions">NS</Link>
                    </button>
                    </Col>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
      <div className={"team-col row goal-title-container"}>
        <div className="col-xl-6 col-lg-9 offset-xl-3 offset-lg-0 col-xs-12 offset-xs-0 pb-2 pt-1 text-left">
          {loadedGoal && (!presentation.active || (presentation.active && presentation.paused) || !presentation.schedule[presentation.index]?.children.length)
            ? (
              <Fragment>
                <table className="w-100 h-100">
                  <tbody>
                    <tr>
                      <td valign="middle" className="pr-2">
                        <button
                          className="button-unstyled d-inline text-white"
                          style={{ color: "#fff!important" }}
                          onClick={() => {
                            setSelectedGoalIndex(getNextIndex(true)); localStorage.setItem("selectedTeamResult", getNextIndex(true))
                          }}
                        >
                          <FontAwesomeIcon
                            className="text-white"
                            size="2x"
                            icon={faCaretSquareLeft}
                          />
                        </button>
                      </td>
                      <td valign="middle" className="text-center">
                        <Dropdown>
                          <Dropdown.Toggle className={"nav-link w-100"} variant="none" id="dropdown-basic" style={{ whiteSpace: "break-spaces" }}>
                            <h4 className="d-inline"><b>{loadedGoal.measurement.goalCategory.name + " - " + loadedGoal.measurement.measurement} ({loadedGoal.measurement.unit.name})</b></h4>
                          </Dropdown.Toggle>
                          <Dropdown.Menu style={{ maxHeight: "400px", overflow: "auto" }}>
                            {individualGoals.map((goal, i) => {
                              return <Dropdown.Item key={i} value={i} onClick={(e) => { setSelectedGoalIndex(parseInt(i)); localStorage.setItem("selectedTeamResult", i) }}>{goal.measurement.goalCategory.name + " - " + goal.measurement.measurement} ({goal.measurement.unit.name})</Dropdown.Item>
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                      <td valign="middle" className="pl-2">
                        <button
                          className="button-unstyled d-inline text-white"
                          onClick={() => { setSelectedGoalIndex(getNextIndex()); localStorage.setItem("selectedTeamResult", getNextIndex()) }}
                        >
                          <FontAwesomeIcon size="2x" icon={faCaretSquareRight} />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Fragment>
            ) : null
          }
        </div>
        <div className="col-lg-3 col-xs-12 text-right">

        </div>
      </div>
    </Fragment>
  )
}

export default Header
