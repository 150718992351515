export const changeIndex = (session, next = false) => {
  const { schedule, index, subIndex, childActive, childLastIndex } = session
  let obj = session

  if(childActive) {
    if(next) {
      if(subIndex === childLastIndex) {
        if(index !== (schedule.length - 1)) {
          obj = {
            ...obj,
            index: index + 1,
            subIndex: 0,
            childActive: schedule[index + 1].children.length > 0 ? true : false,
            childLastIndex: schedule[index + 1].children.length > 0 ? schedule[index + 1].children.length - 1 : 0
          }
        }
      } else {
        obj = {
          ...obj,
          subIndex: subIndex + 1,
        }
      }
    } else {
      if(subIndex === 0) {
        if(index !== 0) {
          obj = {
            ...obj,
            index: index - 1,
            subIndex: schedule[index - 1].children.length > 0 ? schedule[index - 1].children.length - 1 : 0,
            childActive: schedule[index - 1].children.length > 0 ? true : false,
            childLastIndex: schedule[index - 1].children.length > 0 ? schedule[index - 1].children.length - 1 : 0
          }
        }
      } else {
        obj = {
          ...obj,
          subIndex: subIndex - 1,
        }
      }
    }
  } else {
    if(next) {
      if(index !== (schedule.length - 1)) {
        if(schedule[index + 1].children.length > 0) {
          obj = {
            ...obj,
            childActive: true,
            subIndex: 0,
            childLastIndex: schedule[index + 1].children.length - 1
          }
        }

        obj = {
          ...obj,
          index: index + 1
        }
      }
    } else {
      if(index !== 0) {
        if(schedule[index - 1].children.length > 0) {
          obj = {
            ...obj,
            childActive: true,
            subIndex: schedule[index - 1].children.length - 1,
            childLastIndex: schedule[index - 1].children.length - 1
          }
        }

        obj = {
          ...obj,
          index: index - 1
        }
      }
    }
  }

  return obj
}

export const setPaused = (session) => {
  const { paused } = session
  console.log(paused)
  return {
    ...session,
    paused: !paused
  }
}
