export const lightBlue = "#4086A4";

export const MetricPeriodStatuses = {
  NORMAL: {
    value: "NORMAL",
    color: "#fff",
    tickLabelColor: "#005e85"
  },
  TRIGGER: {
    value: "TRIGGER",
    color: "#dc3545",
    tickLabelColor: "#dc3545",
  },
  NON_WORKING: {
    value: "NON_WORKING",
    color: "#212529",
    tickLabelColor: "#fff",
  },
};

export const MetricStatuses = {
  RED: {
    value: "RED",
    color: "#dc3545",
  },
  BLUE: {
    value: "BLUE",
    color: localStorage.getItem("colour") === "Green" ? '#28a745' : "#005e85"
  },
};


export const toNextPeriodStatus = (metric) => {
  const { NORMAL, TRIGGER, NON_WORKING } = MetricPeriodStatuses;

  switch (metric.periodStatus) {
    case NORMAL.value:
      return TRIGGER.value;

    case TRIGGER.value:
      return NON_WORKING.value;

    case NON_WORKING.value:
      return NORMAL.value;

    default:
      // Incase of unset status (for a new metric),
      // we toggle to TRIGGER as if NORMAL was set
      return TRIGGER.value;
  }
};
