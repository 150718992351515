import { client } from "./BaseService";
import { gql } from "apollo-boost";

export const getSelectedValueLog = async (userId) => {
  return await client.query({
    query: gql`query{
      selectedValueLog(userId: "${userId}") {
        id
        value {
          id
          value
        }
      }
    }`
  })
}

export const createSelectedValueLog = async(userId, valueId) => {
  return await client.mutate({
    mutation: gql`mutation {
      createSelectedValueLog(
        userId: "${userId}",
        valueId: "${valueId}",
      )
      {
        id
      }
    }`
  })
}

export const updateSelectedValueLog = async(id, valueId) => {
  return await client.mutate({
    mutation: gql`mutation {
      updateSelectedValueLog(
        id: "${id}",
        valueId: "${valueId}"
      )
      {
        id
      }
    }`
  })
}
