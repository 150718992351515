import * as authenticationTypes from '../types/AuthenticationTypes';

const authenticationStarted = () => ({
  type: authenticationTypes.AUTHENTICATION_STARTED,
});

const authenticationSuccess = (payload) => ({
  type: authenticationTypes.AUTHENTICATION_SUCCESS,
  payload
});

const authenticationFailed= (error) => ({
  type: authenticationTypes.AUTHENTICATION_FAILED,
  payload: { error },
});

const authenticationEnded = () => ({
  type: authenticationTypes.AUTHENTICATION_ENDED,
});

export {
  authenticationStarted,
  authenticationSuccess,
  authenticationFailed,
  authenticationEnded
};
