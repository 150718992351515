import React, {Component, createRef, Fragment} from 'react'
import Pusher from 'pusher-js';
import Switch from "react-switch";
import { Table, Tooltip, OverlayTrigger, Row, Col, ButtonGroup, ToggleButton, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import {
  escalateAction,
  updateActionStatus,
  revertActionStatus,
  archiveAction,
  updateAction,
  createIssue,
  createAction,
  deleteAction,
  getLoggedNextStepActions
} from '../services/IssueService';
import { updateIssue } from "../services/CustomerSupplierService";
import displayErrorToast from "../components/utils/displayErrorToast";
import SubHeaderComponent from "../components/SubHeaderComponent";
import RealtimeRefreshComponent from "../components/RealtimeRefreshComponent";
import { toast } from "react-toastify";
import { faSpinner, faPlus, faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import * as DefaultLabels from '../assets/glossary.json';
import LabelComponent from "../components/utils/getCompanyLabel";

import Excel from 'exceljs';
import { saveAs } from 'file-saver';
// icons
import Add from '../assets/ButtonIcons/Add Blue.svg';
import Archive from '../assets/ButtonIcons/Archive Blue.svg';
import Cancel from '../assets/ButtonIcons/Cancel Blue.svg';
import Delete from '../assets/ButtonIcons/Delete Blue.svg';
import Edit from '../assets/ButtonIcons/Edit Blue.svg';
import Loader from '../assets/ButtonIcons/Loader Blue.svg';
import PutBack from '../assets/ButtonIcons/Put Back Blue.svg';
import Save from '../assets/ButtonIcons/Save Blue.svg';
import { PUSHER_ID } from '../redux/services/BaseService';

const workSheetName = 'Worksheet-1';
const columns = [
  { header: 'Date', key: 'reviewDate' },
  { header: 'Issue / Problem', key: 'whatText' },
  { header: 'Our Action', key: 'description' },
  { header: 'Who', key: 'who' },
  { header: 'Team', key: 'team' },
  { header: 'When', key: 'when' },
  { header: 'Status', key: 'status' },
  { header: 'Escalated', key: 'escalated' }
];
const labels = DefaultLabels.default;
const getLabelText = key => <LabelComponent val={key} />

function renderTooltip(props, text) {
  return (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );
}

export class ActionsScreen extends Component {


  constructor(props) {
    super(props);

    this.state = {
      issues: [],
      actions: [],
      issueId: "",
      loading: false,
      loadingId: "",
      loadingType: "",
      issueDate: "",
      issueText: "",
      actionId: "",
      whoId: "",
      when: "",
      whenChanged: false,
      editable: false,
      archived: false,
      users: [],
      editType: "",
      sortBy: "",
      orderBy: "",
      newData: false,
      postponedCount: 0,
      nextStepActionId: null,
      isMyActions: false,
    }

    this.targetRowRef = createRef();

    this.renderRows = this.renderRows.bind(this)
    this.props.togglePageLoad(true)
  }

  async fetchLoggedIssues(isArchived, isDownload, isMine = this.state.isMyActions) {

    const response = await getLoggedNextStepActions(this.props.selectedTeam.id, isArchived, isMine);

    const issues = JSON.parse(response.data.getNextStepActions);
    let allActions = []

    issues.map(({ actions, id, whyText, whatText, reviewDate, creator }) => {
      actions.sort((a, b) => a.id > b.id);
      actions.forEach((action, i) => {
        action.isDateIssueEditable = i === 0;
        let act = {...action, issue: { id, whyText, whatText, reviewDate, creator }}
        if(this.state.isMyActions) {
          if(act.who?.id === this.props.user.id) {
            allActions.push(act);
          }
        } else {
          allActions.push(act);
        }
      })
    })
    
    allActions.sort((a, b) => {
      return (new Date(b.issue.reviewDate).setHours(24, 0, 0, 0) - new Date(a.issue.reviewDate).setHours(24, 0, 0, 0))
    })

    if(isDownload) {
      return allActions;
    }

    let users = [...this.props.selectedTeam.members, ...this.props.selectedTeam.leaders]

    const nextStepValue = this.getNextStepId(allActions, issues, isArchived);

    let prevArchiveState = this.state.archived;

    if(this.state.isMyActions) {
      allActions = allActions.filter(act => act.who && act.who.id === this.props.user.id);
    }

    this.setState({
      issues: response.data.issues,
      actions: [...allActions],
      nextStepActionId: nextStepValue?.nextStepActionId,
      archived: nextStepValue?.nextArchived,
      users: users.sort((a, b) => a.firstNames.localeCompare(b.firstNames)),
    })

    if(prevArchiveState === false && nextStepValue?.nextArchived)
    {
      await this.toggleArchived(true, false);
    }

    this.props.togglePageLoad(false)

    this.scrollToTargetRow();
  }

  async componentDidMount() {
    let pusher = new Pusher(PUSHER_ID, {
      cluster: 'eu'
    });
    let self = this
    let channel = pusher.subscribe('my-channel');
    channel.bind('my-event', function (data) {
      const token = localStorage.getItem("token")
      const teamId = localStorage.getItem("teamId")
      if (token !== data.token && teamId === data.teamId) {
        self.setState({
          newData: true
        })
      }
    });

    const res = this.setNextStepType();

    if (this.props.selectedTeam.id !== 0) {
      await this.fetchLoggedIssues(this.state.archived, false, res);
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.selectedTeam.id !== this.props.selectedTeam.id || this.props.path !== prevProps.path) {
      this.props.togglePageLoad(true);

      const res = this.setNextStepType();

      await this.fetchLoggedIssues(this.state.archived, false, res);
    }

    if (this.props.refresh && prevProps.refresh !== this.props.refresh) {
      const { sortBy } = this.state
      this.props.togglePageLoad(true)
      await this.fetchLoggedIssues(this.state.archived)
      this.props.toggleRefresh()
      this.props.toggleHasNewData()
      this.handleSort(sortBy)
      this.props.togglePageLoad(false)
    }
  }

  setNextStepType() {
    let section = this.props.location.pathname;
    let result = false;
    if(section.indexOf('me') === 1) {
      result = true;
    } else {
      result = false;
    }
    this.setState({
      isMyActions: result
    })

    return result;
  }

  scrollToTargetRow = () => {
    if (this.targetRowRef.current)
    {
      this.targetRowRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    }
  };

  getText = val => {
    const { user } = this.props;

    const companyId = localStorage.getItem("companyId");
    let lang = "EN";
    let langLabels = labels.find(lbl => lbl.lang === lang);
    let def = langLabels.glossary.find(label => label.key === val);

    if (user && user.companies && user.companies.length) {
      const company = user.companies.find(company => company.id === companyId);
      if (company) {
        lang = company.language;
        langLabels = labels.find(lbl => lbl.lang === lang);
        def = langLabels.glossary.find(label => label.key === val);
        if (!def) {
          lang = "EN";
          langLabels = labels.find(lbl => lbl.lang === lang);
          def = langLabels.glossary.find(label => label.key === val);
        }
      }
      if (company && company.companyGlossaries) {
        const custom = company.companyGlossaries.find(label => label.key === val);

        if (custom) return custom.value
      }
    }

    return def.value
  }

  getNextStepId = (actions, issues, archived) => {
    const queryParams = new URLSearchParams(window.location.search);
    let nextStepActionId = queryParams.get('action');
    let isActionArchived = queryParams.get('archived') === 'true';

    let nextArchived = archived;

    const value = {
      nextStepActionId: nextStepActionId,
      nextArchived: nextArchived || isActionArchived
    };

    return value;
  }

  async toggleArchived(isArchived, toRemove = true) {
    this.setState({
      archived: isArchived
    })
    if(toRemove)
    {
      this.props.history.push("/now-what/actions")
    }
    const { sortBy } = this.state
    this.props.togglePageLoad(true)
    await this.fetchLoggedIssues(isArchived)
    this.props.toggleRefresh()
    this.props.toggleHasNewData()
    this.handleSort(sortBy)
    this.props.togglePageLoad(false)
  }

  getIssueText(issue, action) {
    const { editable, actionId, issueText, editType } = this.state

    if (editable && (editType === 'create' || action.isDateIssueEditable) && actionId === action.id) {
      return <input
        type="text"
        value={issueText}
        className={"form-control" + (editType === "append" ? " disabled" : "")}
        onChange={(e) => this.setState({ issueText: e.target.value })}
        autoComplete="off"
      />
    }
    return action.actionType === "ROOT_CAUSE" ? issue.whyText : issue.whatText
  }

  getActionText(action) {
    const { editable, actionId, actionText } = this.state

    if (editable && actionId === action.id) {
      return <input
        type="text"
        value={actionText}
        className="form-control"
        onChange={(e) => this.setState({ actionText: e.target.value })}
        autoComplete="off"
      />
    }

    return action.description ? action.description : ""
  }

  getUserInput(action) {
    const { editable, actionId, whoId } = this.state

    if (editable && actionId === action.id) {
      return (
        <select className="form-control cdi-ddl w-100 ml-0"
          name="who"
          value={whoId ? whoId : 1}
          required={true}
          onChange={(e) => this.setState({ whoId: e.target.value })}
          autoComplete="off"
        >
          <option value="1" disabled>{this.getText("select_member")}</option>
          {this.state.users.map((user, index) => {
            return <option value={user.id} key={index}>{user.firstNames + " " + user.lastName}</option>
          })}
        </select>
      )
    }
    return action.who?.firstNames ? action.who.firstNames + " " + action.who.lastName : getLabelText("none_assigned")
  }

  getTeamName = (action) => {
    return action.issue.creator ? action.issue.creator.name : "";
  }

  getWhenInput(action) {
    const { editable, actionId, when, whenChanged } = this.state

    if (editable && actionId === action.id) {
      return (
        <Fragment>
          <DatePicker
            name="date"
            className="form-control"
            required={true}
            dateFormat="dd/MM/yyyy"
            showYearDropdown
            selected={when}
            onChange={this.handleDateChange}
            tabIndex="0"
            autoComplete="off"
          />
          <div className="pt-2 d-block mt-1">
            <Switch
              className="ml-2"
              checkedIcon={false}
              uncheckedIcon={false}
              offColor="#005e85"
              onColor="#c00000"
              checked={whenChanged}
              onChange={this.whenChanged}
              autoComplete="off"
            />
          </div>
        </Fragment>
      )
    }
    return (
      <Fragment>
        <span className={action.whenChanged ? "color-red" : ""}>
          {action.when ? this.formattedDate(new Date(action.when)) : getLabelText("none_assigned")}
        </span>
        {action.whenChanged && action.postponedCount > 0 ? (
          <span className="postponed-count">{action.postponedCount}</span>
        ) : null}
      </Fragment>
    )
  }

  handleDateChange = date => {
    const { actions, actionId } = this.state;
    const action = actions.find(({ id }) => id === actionId);

    if (date > new Date(action.when) && action.whenChanged && action.postponedCount > 0) {
      if (window.confirm("Are you sure you wish to postpone this action?")) {
        this.setState({
          when: date,
          postponedCount: action.postponedCount + 1
        })
      }
    } else {
      this.setState({
        when: date
      })
    }
  }

  handleReviewDateChange = date => {
    this.setState({
      issueDate: date
    })
  }

  getReviewDate = (date, action) => {
    const { editable, actionId, issueDate, editType } = this.state

    if (editable && (editType === 'create' || action.isDateIssueEditable) && actionId === action.id) {
      return (
        <DatePicker
          name="date"
          className={"form-control" + (editType === "append" ? " disabled" : "")}
          required={true}
          dateFormat="dd/MM/yyyy"
          showYearDropdown
          selected={issueDate}
          onChange={this.handleReviewDateChange}
          tabIndex="0"
          autoComplete="off"
        />
      )
    }

    return this.formattedDate(date)
  }

  formattedDate(date) {
    let month = date.getMonth() + 1
    let day = date.getDate()

    if (month.toString().length < 2)
      month = "0" + month.toString()

    if (day.toString().length < 2)
      day = "0" + day.toString()

    return day + "/" + month + "/" + date.getFullYear()
  }

  getStatus(action) {
    const { loading, loadingId, loadingType, editable } = this.state
    if (loading && loadingType === "complete" && loadingId === action.id) {
      return (<span className="indicator chk chk-white">
        <FontAwesomeIcon className="color-blue mx-auto fa-spin m-auto" icon={faSpinner} />
      </span>)
    } else {
      return <span className={"indicator chk chk-" + (action.status === "COMPLETED" ? "blue color-selected" : action.status === "TRIGGERED" ? "red" : "white") + (editable || action.archived ? " disabled" : "")} onClick={() => this.complete(action.id, action.status)}></span>
    }
  }

  getEscalated(action) {
    const { loading, loadingId, loadingType, editable } = this.state
    if (loading && loadingType === "escalate" && loadingId === action.id) {
      return (<span className="indicator chk chk-white">
        <FontAwesomeIcon className="color-blue mx-auto fa-spin m-auto" icon={faSpinner} />
      </span>)
    } else {
      if (action.shared) {
        return <span className={"indicator chk chk-blue color-selected" + (editable || action.archived ? " disabled" : "")}></span>
      } else {
        return <span className={"indicator chk chk-" + (action.escalated ? "red" : "white") + (editable || action.archived ? " disabled" : "")} onClick={() => this.escalate(action.id, action.escalated)}></span>
      }
    }
  }

  whenChanged = () => {
    const { whenChanged, postponedCount } = this.state;
    const newWhenChanged = !whenChanged;
    let options = {
      whenChanged: newWhenChanged
    };
    console.log(newWhenChanged)
    if (newWhenChanged && postponedCount === 0) {
      options = {
        ...options,
        postponedCount: 1
      }
    };

    this.setState(options);
  }

  validateInput = () => {
    const { issueText, issueDate } = this.state
    let valid = true

    if (issueText === "") {
      valid = false
    }

    if (issueDate === "") {
      valid = false
    }

    return valid
  }

  async save(issue, action) {
    let { editType, actionId, whoId, when, actionText, issueText, issueDate, whenChanged, postponedCount } = this.state;
    if(this.state.isMyActions) {
      whoId = this.props.user.id;
    }
    this.setLoading(actionId, "save")
    if (this.validateInput()) {
      try {
        if (editType === "create") {
          const response = await createIssue(this.props.selectedTeam.id, issueText.replaceAll(/"/g, '\\"'), "", issueDate.toISOString())
          await createAction(response.data.createIssue.id, "UNASSIGNED", "NEXT_STEP", actionText, whoId, (when && when !== "" ? when.toISOString() : null))
        } else if (editType === "edit") {
          await updateIssue(issue.id, action.actionType === "ROOT_CAUSE" ? issue.whatText : issueText.replaceAll(/"/g, '\\"'), action.actionType === "ROOT_CAUSE" ? issueText : issue.whyText, issueDate.toISOString())
          await updateAction(actionId, actionText, whoId, (when && when !== "" ? when.toISOString() : null), whenChanged, postponedCount)
        } else if (editType === "append") {
          await updateIssue(issue.id, action.actionType === "ROOT_CAUSE" ? issue.whatText : issueText.replaceAll(/"/g, '\\"'), action.actionType === "ROOT_CAUSE" ? issueText : issue.whyText, issueDate.toISOString())
          await createAction(issue.id, action.actionType, "NEXT_STEP", actionText, whoId, (when && when !== "" ? when.toISOString() : null))
        }
        await this.fetchLoggedIssues(this.state.archived)
        this.sortList(this.state.sortBy)
        this.setState({
          editable: false,
          actionId: "",
          whoId: "",
          when: "",
          issueDate: "",
          issueText: "",
          actionText: "",
          editType: "",
          postponedCount: 0,
        })
        this.cancelLoading()
      } catch (err) {
        displayErrorToast(err)
        this.cancelLoading()
      }
    }
  }

  cancel = () => {
    const { editType, actions } = this.state
    let options = {
      editable: false,
      actionId: "",
      whoId: "",
      when: "",
      issueDate: "",
      issueText: "",
      actionText: "",
      editType: "",
      postponedCount: 0,
    }

    if (editType === "create") {
      actions.shift()
      options = {
        ...options,
        actions
      }
    } else if (editType === "append") {
      const { actionId, issueId } = this.state

      actions.map((action) => {
        if (action.issue.id === issueId) {
          const index = actions.findIndex(action => action.id === actionId)

          if (index !== -1) {
            actions.splice(index, 1)
          }
        }

        return true
      })

      options = {
        ...options,
        actions
      }
    }

    this.setState(options)
  }

  async escalate(id, escalated) {
    this.setLoading(id, "escalate")
    try {
      await escalateAction(id, !escalated)
      await this.fetchLoggedIssues(this.state.archived)
      this.sortList(this.state.sortBy)
      this.cancelLoading()
    } catch (err) {
      this.cancelLoading()
      displayErrorToast(err)
    }
  }

  async complete(id, status) {
    this.setLoading(id, "complete")
    let newStatus = "TRIGGERED"
    if (status === "COMPLETED") {
      newStatus = "NEXT_STEP"
    } else if (status === "TRIGGERED") {
      newStatus = "COMPLETED"
    }
    try {
      await updateActionStatus(id, newStatus)
      await this.fetchLoggedIssues(this.state.archived)
      this.sortList(this.state.sortBy)
      this.cancelLoading()
    } catch (err) {
      this.cancelLoading()
      displayErrorToast(err)
    }
  }

  async revert(id) {
    this.setLoading(id, "revert")
    if (window.confirm("Are you sure you want to remove this from Next Steps?")) {
      try {
        await revertActionStatus(id)
        await this.fetchLoggedIssues(this.state.archived)
        this.sortList(this.state.sortBy)
        this.cancelLoading()
      } catch (err) {
        this.cancelLoading()
        displayErrorToast(err)
      }
    }
  }

  async archive(id, archived, status) {
    if (!archived && status !== "COMPLETED") {
      toast.error("Cannot archive incomplete actions")

      return false
    }

    this.setLoading(id, "archive")
    try {
      await archiveAction(id, !archived)
      await this.fetchLoggedIssues(this.state.archived)
      this.sortList(this.state.sortBy)
      this.cancelLoading()
    } catch (err) {
      displayErrorToast(err)
      this.cancelLoading()
    }
  }

  async delete(action) {
    if (window.confirm(this.getText("delete_department_prompt"))) {
      try {
        this.setLoading(action.id, "delete")
        await deleteAction(action.id)
        await this.fetchLoggedIssues(this.state.archived)
        this.sortList(this.state.sortBy)
      } catch (error) {
        error.graphQLErrors.map(error => {
          toast.error(error.message)

          return true
        })
      } finally {
        this.cancelLoading()
      }
    }
  }

  append(issueId, actionId) {
    const { actions } = this.state
    let options
    actions.map((action) => {
      if (action.issue.id === issueId && action.id === actionId) {
        const index = actions.findIndex(action => action.id === actionId)
        const generatedId = Math.ceil(Math.random() * 100)

        const newAction = {
          id: generatedId,
          description: "",
          actionType: actions.find(action => action.id === actionId).actionType,
          status: "NEXT_STEP",
          issue: action.issue,
          archived: false,
          postponedCount: 0
        }
        actions.splice(index + 1, 0, newAction)
        options = {
          editable: true,
          actionId: generatedId,
          issueId: action.issue.id,
          issueDate: new Date(action.issue.reviewDate),
          issueText: actions.find(action => action.id === actionId).actionType === "ROOT_CAUSE" ? action.issue.whyText : action.issue.whatText,
          actionText: newAction.description,
          whoId: newAction.who ? newAction.who.id : "",
          when: newAction.when ? new Date(newAction.when) : "",
          postponedCount: newAction.postponedCount,
          whenChanged: newAction.whenChanged,
          editType: "append"
        }

      }
      return true
    })

    this.setState({
      ...options,
      actions
    })
  }

  setLoading(id, type) {
    this.setState({
      loading: true,
      loadingId: id,
      loadingType: type
    })
  }

  cancelLoading() {
    this.setState({
      loading: false,
      loadingId: "",
      loadingType: ""
    })
  }

  setEdit(issue, action) {
    this.setState({
      editable: true,
      actionId: action.id,
      issueDate: new Date(issue.reviewDate),
      issueText: action.actionType === "ROOT_CAUSE" ? issue.whyText : issue.whatText,
      actionText: action.description,
      whoId: action.who ? action.who.id : "",
      when: action.when ? new Date(action.when) : "",
      whenChanged: action.whenChanged,
      postponedCount: action.postponedCount,
      editType: "edit"
    })
  }

  setCreate() {
    const { actions } = this.state
    const generatedId = Math.ceil(Math.random() * 100)
    actions.unshift({
      id: generatedId,
      description: "",
      actionType: "UNASSIGNED",
      status: "NEXT_STEP",
      archived: false,
      postponedCount: 0,
      issue: {
        id: generatedId,
        reviewDate: "",
        whatText: "",
        whyText: "",
        archived: false,
      }
    })
    this.setState({
      actions,
      editable: true,
      actionId: generatedId,
      issueDate: new Date(),
      actionText: "",
      issueText: "",
      whoId: "",
      when: "",
      editType: "create"
    })
  }

  saveAction = (type) => {
    const { newSolution, newFix, actions } = this.state;
    actions.push({ description: type === "FEEDBACK" ? newSolution : newFix, status: "NONE", actionType: type })
    type === "FEEDBACK" ? this.setState({ newSolution: "" }) : this.setState({ newFix: "" })
  }

  saveExcel = async () => {
    this.props.togglePageLoad(true)
    const workbook = new Excel.Workbook();
    try {
      let allActions = []
      let teamName;
      if (this.props.selectedTeam.id !== 0) {
        teamName = this.props.selectedTeam.name;
        allActions = await this.fetchLoggedIssues(this.state.archived, true);
      }
      this.props.togglePageLoad(false)
      const fileName = this.state.isMyActions ? "My Actions" : teamName + "-Issues";
      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(workSheetName);

      // add worksheet columns
      // each columns contains header and its mapping key from data
      worksheet.columns = columns;

      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true };

      // loop through all of the columns and set the alignment with width.
      worksheet.columns.forEach(column => {
        column.width = column.header.length + 15;
        column.alignment = { horizontal: 'left' };
      });

      // loop through data and add each one to worksheet

      //Prepare data 
      let allIssues = allActions.map((action) => ({
        ...action,
        reviewDate: action.issue?.reviewDate ? this.formattedDate(new Date(action.issue?.reviewDate)) : "",
        whatText: (action.actionType === "ROOT_CAUSE" ? action.issue?.whyText : action.issue?.whatText),
        who: (!action.who?.firstNames) ? "" : action.who?.firstNames + " " + action.who?.lastName,
        team: (!action.issue?.creator?.name) ? "" : action.issue?.creator?.name,
        when: action.when ? this.formattedDate(new Date(action.when)) : "",
        escalated: action.escalated ? "Yes" : "No"
      }));
      allIssues.sort((a, b) => {
        return (new Date(a.reviewDate).setHours(24, 0, 0, 0) - new Date(b.reviewDate).setHours(24, 0, 0, 0))
      })
      allIssues.forEach(singleData => {
        worksheet.addRow(singleData);
      });

      // loop through all of the rows and set the outline style.
      worksheet.eachRow({ includeEmpty: false }, row => {
        // store each cell to currentCell
        const currentCell = row._cells;

        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach(singleCell => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;

          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        });
      });

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error('Error saving file - ', error.message);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
    }
  };

  getInputButtons(issue, action) {
    const { editable, actionId, loading, loadingId, loadingType } = this.state
    let buttons;

    if (editable && actionId === action.id) {
      buttons = (
        <Fragment>
          <OverlayTrigger
            placement="top"
            delay={{ show: 25, hide: 40 }}
            overlay={(props) => renderTooltip({ ...props }, getLabelText("save"))}
          >
            <button className={"btn-icons-container" + (!this.validateInput() ? " disabled" : "")} type="submit" onClick={() => this.save(issue, action)}>
              {loading && loadingType === "save" && loadingId === action.id
                ? (
                  <img src={Loader} alt="Loader" className="loader-spinner" />
                ) : (
                  <img src={Save} alt="Save" className="btn-icons" />
                )
              }
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            delay={{ show: 25, hide: 40 }}
            overlay={(props) => renderTooltip({ ...props }, getLabelText("cancel"))}
          >
            <button className="btn-icons-container" type="submit" onClick={this.cancel}>
              <img src={Cancel} alt="Cancel" className="btn-icons" />
            </button>
          </OverlayTrigger>
        </Fragment>
      )
    } else {
      buttons = (
        <Fragment>
          {!this.state.archived && (
            <Fragment>
              <OverlayTrigger
                placement="top"
                delay={{ show: 25, hide: 40 }}
                overlay={(props) => renderTooltip({ ...props }, getLabelText("add_step"))}
              >
                <button className={"btn-icons-container" + (this.state.editable ? " disabled" : "")} type="submit" onClick={() => this.append(issue.id, action.id)}>
                  <img src={Add} alt="Add" className="btn-icons" />
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                delay={{ show: 25, hide: 40 }}
                overlay={(props) => renderTooltip({ ...props }, getLabelText("edit"))}
              >
                <button className={"btn-icons-container" + (this.state.editable ? " disabled" : "")} type="submit" onClick={() => this.setEdit(issue, action)}>
                  <img src={Edit} alt="Edit" className="btn-icons" />
                </button>
              </OverlayTrigger>
            </Fragment>
          )}
          <OverlayTrigger
            placement="top"
            delay={{ show: 25, hide: 40 }}
            overlay={(props) => renderTooltip({ ...props }, (this.state.archived ? getLabelText("put_back") : getLabelText("archive")))}
          >
            <button className={"btn-icons-container" + (this.state.editable || action.status !== "COMPLETED" ? " disabled" : "")} type="submit" onClick={() => this.archive(action.id, action.archived, action.status)}>
              {loading && loadingType === "archive" && loadingId === action.id
                ? (
                  <img src={Loader} alt="Loader" className="loader-spinner" />

                ) : action.archived
                  ? (
                    <img src={PutBack} alt="PutBack" className="btn-icons" />
                  ) : (
                    <img src={Archive} alt="Archive" className="btn-icons" />
                  )
              }
            </button>
          </OverlayTrigger>
          {this.state.archived && (
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({ ...props }, getLabelText("delete"))}
            >
              <button className={"btn-icons-container" + (this.state.editable ? " disabled" : "")} type="submit" onClick={() => this.delete(action)}>
                {loading && loadingType === "delete" && loadingId === action.id
                  ? (
                    <img src={Loader} alt="Loader" className="loader-spinner" />
                  ) : (
                    <img src={Delete} alt="Delete" className="btn-icons" />
                  )
                }
              </button>
            </OverlayTrigger>
          )}
        </Fragment>
      )
    }

    return buttons
  }

  handleSort = (type) => {
    const { actions, sortBy, orderBy } = this.state
    let newActions = actions
    let newOrder = "asc"

    switch (type) {
      case "reviewDate":
        if (type === sortBy) {
          if (orderBy === "desc") {
            newActions.sort((a, b) => new Date(b.issue.reviewDate).setHours(24, 0, 0, 0) - new Date(a.issue.reviewDate).setHours(24, 0, 0, 0))
          } else if (orderBy === "asc") {
            newOrder = "desc"
            newActions.sort((a, b) => new Date(a.issue.reviewDate).setHours(24, 0, 0, 0) - new Date(b.issue.reviewDate).setHours(24, 0, 0, 0))
          }
        } else {
          newActions.sort((a, b) => new Date(b.issue.reviewDate).setHours(24, 0, 0, 0) - new Date(a.issue.reviewDate).setHours(24, 0, 0, 0))
        }
        break;
      case "issue":
        if (type === sortBy) {
          if (orderBy === "desc") {
            newActions.sort((a, b) => a.actionType === "ROOT_CAUSE" ? a.issue.whyText?.trim().localeCompare(b.actionType === "ROOT_CAUSE" ? b.issue.whyText?.trim() : b.issue.whatText?.trim()) : a.issue.whatText?.trim().localeCompare(b.actionType === "ROOT_CAUSE" ? b.issue.whyText?.trim() : b.issue.whatText?.trim()))
          } else if (orderBy === "asc") {
            newOrder = "desc"
            newActions.sort((a, b) => b.actionType === "ROOT_CAUSE" ? b.issue.whyText?.trim().localeCompare(a.actionType === "ROOT_CAUSE" ? a.issue.whyText?.trim() : a.issue.whatText?.trim()) : b.issue.whatText?.trim().localeCompare(a.actionType === "ROOT_CAUSE" ? a.issue.whyText?.trim() : a.issue.whatText?.trim()))
          }
        } else {
          newActions.sort((a, b) => a.actionType === "ROOT_CAUSE" ? a.issue.whyText?.trim().localeCompare(b.actionType === "ROOT_CAUSE" ? b.issue.whyText?.trim() : b.issue.whatText?.trim()) : a.issue.whatText?.trim().localeCompare(b.actionType === "ROOT_CAUSE" ? b.issue.whyText?.trim() : b.issue.whatText?.trim()))
        }
        break;
      case "action":
        if (type === sortBy) {
          if (orderBy === "asc") {
            newOrder = "desc"
            let hasDataArray = newActions.filter(action => action.description).sort((a, b) => b.description ? b.description?.trim().localeCompare(a.description ? a.description?.trim() : "") : false)
            let noDataArray = newActions.filter(action => !action.description)

            newActions = [...noDataArray, ...hasDataArray]
          } else if (orderBy === "desc") {
            let hasDataArray = newActions.filter(action => action.description).sort((a, b) => a.description ? a.description?.trim().localeCompare(b.description ? b.description?.trim() : "") : false)
            let noDataArray = newActions.filter(action => !action.description)

            newActions = [...hasDataArray, ...noDataArray]
          }
        } else {
          let hasDataArray = newActions.filter(action => action.description).sort((a, b) => a.description ? a.description?.trim().localeCompare(b.description ? b.description?.trim() : "") : false)
          let noDataArray = newActions.filter(action => !action.description)

          newActions = [...hasDataArray, ...noDataArray]

        }
        break;

      case "who":
        if (type === sortBy) {
          if (orderBy === "asc") {
            newOrder = "desc"
            let hasDataArray = newActions.filter(action => action.who).sort((a, b) => a.who ? a.who.firstNames?.trim().localeCompare(b.who ? b.who.firstNames?.trim() : "") : false)
            let noDataArray = newActions.filter(action => !action.who)

            newActions = [...noDataArray, ...hasDataArray]
          } else if (orderBy === "desc") {
            let hasDataArray = newActions.filter(action => action.who).sort((a, b) => b.who ? b.who.firstNames?.trim().localeCompare(a.who ? a.who.firstNames?.trim() : "") : false)
            let noDataArray = newActions.filter(action => !action.who)

            newActions = [...hasDataArray, ...noDataArray]
          }
        } else {
          let hasDataArray = newActions.filter(action => action.who).sort((a, b) => b.who ? b.who.firstNames?.trim().localeCompare(a.who ? a.who.firstNames?.trim() : "") : false)
          let noDataArray = newActions.filter(action => !action.who)

          newActions = [...hasDataArray, ...noDataArray]
        }
        break;

      case "when":
        if (type === sortBy) {
          if (orderBy === "asc") {
            newOrder = "desc"

            let hasDateArray = newActions.filter(action => action.when).sort((a, b) => {
              if (new Date(b.when).setHours(24, 0, 0, 0) === new Date(a.when).setHours(24, 0, 0, 0)) {
                return new Date(a.issue.reviewDate).setHours(24, 0, 0, 0) - new Date(b.issue.reviewDate).setHours(24, 0, 0, 0)
              }

              return new Date(a.when).setHours(24, 0, 0, 0) - new Date(b.when).setHours(24, 0, 0, 0)
            })
            let noDateArray = newActions.filter(action => !action.when).sort((a, b) => {
              return new Date(b.issue.reviewDate).setHours(24, 0, 0, 0) - new Date(a.issue.reviewDate).setHours(24, 0, 0, 0)
            })

            newActions = [...noDateArray, ...hasDateArray]

          } else if (orderBy === "desc") {
            let hasDateArray = newActions.filter(action => action.when).sort((a, b) => {
              if (new Date(a.when).setHours(24, 0, 0, 0) === new Date(b.when).setHours(24, 0, 0, 0)) {
                return new Date(b.issue.reviewDate).setHours(24, 0, 0, 0) - new Date(a.issue.reviewDate).setHours(24, 0, 0, 0)
              }

              return new Date(b.when).setHours(24, 0, 0, 0) - new Date(a.when).setHours(24, 0, 0, 0)
            })
            let noDateArray = newActions.filter(action => !action.when).sort((a, b) => {
              return new Date(a.issue.reviewDate).setHours(24, 0, 0, 0) - new Date(b.issue.reviewDate).setHours(24, 0, 0, 0)
            })

            newActions = [...hasDateArray, ...noDateArray]
          }
        } else {
          let hasDateArray = newActions.filter(action => action.when).sort((a, b) => {
            if (new Date(a.when).setHours(24, 0, 0, 0) === new Date(b.when).setHours(24, 0, 0, 0)) {
              return new Date(b.issue.reviewDate).setHours(24, 0, 0, 0) - new Date(a.issue.reviewDate).setHours(24, 0, 0, 0)
            }

            return new Date(b.when).setHours(24, 0, 0, 0) - new Date(a.when).setHours(24, 0, 0, 0)
          })
          let noDateArray = newActions.filter(action => !action.when).sort((a, b) => {
            return new Date(a.issue.reviewDate).setHours(24, 0, 0, 0) - new Date(b.issue.reviewDate).setHours(24, 0, 0, 0)
          })

          newActions = [...hasDateArray, ...noDateArray]
        }
        break;

      case "status":
        const completedActions = newActions.filter(action => action.status === "COMPLETED")
        const triggeredActions = newActions.filter(action => action.status === "TRIGGERED")
        const disabledActions = newActions.filter(action => action.status === "NEXT_STEP")

        if (type === sortBy) {
          if (orderBy === "asc") {
            newOrder = "desc";

            newActions = [...triggeredActions, ...completedActions, ...disabledActions];
          } else if (orderBy === "desc") {
            newActions = [...disabledActions, ...completedActions, ...triggeredActions];

          }
        } else {
          newActions = [...disabledActions, ...completedActions, ...triggeredActions];

        }
        break;

      case "escalated":
        const escalatedActions = newActions.filter(action => action.escalated)
        const normalActions = newActions.filter(action => !action.escalated)

        if (type === sortBy) {
          if (orderBy === "asc") {
            newOrder = "desc";

            escalatedActions.sort((a, b) => a.shared - b.shared);
            newActions = [...escalatedActions, ...normalActions];
          } else if (orderBy === "desc") {
            escalatedActions.sort((a, b) => b.shared - a.shared);
            newActions = [...normalActions, ...escalatedActions];
          }
        } else {
          escalatedActions.sort((a, b) => b.shared - a.shared);
          newActions = [...normalActions, ...escalatedActions];
        }
        break;

      case 'team':
        if (type === sortBy) {
          if (orderBy === "asc") {
            newOrder = "desc"
            let hasDataArray = newActions.filter(action => action.issue.creator).sort((a, b) => a.issue.creator.name?.trim().localeCompare(b.issue.creator.name?.trim()))
            let noDataArray = newActions.filter(action => !action.issue.creator)

            newActions = [...noDataArray, ...hasDataArray]
          } else if (orderBy === "desc") {
            let hasDataArray = newActions.filter(action => action.issue.creator).sort((a, b) => a.issue.creator.name?.trim().localeCompare(b.issue.creator.name?.trim()))
            let noDataArray = newActions.filter(action => !action.issue.creator)

            newActions = [...hasDataArray, ...noDataArray]
          }
        }
        break;

      default:
        break;
    }

    this.setState({
      orderBy: newOrder,
      sortBy: newOrder === "" ? "" : type,
      actions: newActions
    })
  }

  sortList = (type) => {
    const { actions, sortBy, orderBy } = this.state
    let newActions = actions

    switch (type) {
      case "reviewDate":
        if (type === sortBy) {
          if (orderBy === "desc") {
            newActions.sort((a, b) => new Date(a.issue.reviewDate).setHours(24, 0, 0, 0) - new Date(b.issue.reviewDate).setHours(24, 0, 0, 0))
          } else if (orderBy === "asc") {
            newActions.sort((a, b) => new Date(b.issue.reviewDate).setHours(24, 0, 0, 0) - new Date(a.issue.reviewDate).setHours(24, 0, 0, 0))
          }
        }
        break;
      case "issue":
        if (type === sortBy) {
          if (orderBy === "desc") {
            newActions.sort((a, b) => b.actionType === "ROOT_CAUSE" ? b.issue.whyText.localeCompare(a.actionType === "ROOT_CAUSE" ? a.issue.whyText : a.issue.whatText) : b.issue.whatText.localeCompare(a.actionType === "ROOT_CAUSE" ? a.issue.whyText : a.issue.whatText))
          } else if (orderBy === "asc") {
            newActions.sort((a, b) => a.actionType === "ROOT_CAUSE" ? a.issue.whyText.localeCompare(b.actionType === "ROOT_CAUSE" ? b.issue.whyText : b.issue.whatText) : a.issue.whatText.localeCompare(b.actionType === "ROOT_CAUSE" ? b.issue.whyText : b.issue.whatText))
          }
        }
        break;
      case "action":
        if (type === sortBy) {
          if (orderBy === "asc") {
            let hasDataArray = newActions.filter(action => action.description).sort((a, b) => a.description ? a.description.localeCompare(b.description ? b.description : "") : false)
            let noDataArray = newActions.filter(action => !action.description)

            newActions = [...hasDataArray, ...noDataArray]
          } else if (orderBy === "desc") {
            let hasDataArray = newActions.filter(action => action.description).sort((a, b) => b.description ? b.description.localeCompare(a.description ? a.description : "") : false)
            let noDataArray = newActions.filter(action => !action.description)

            newActions = [...noDataArray, ...hasDataArray]
          }
        }
        break;

      case "who":
        if (type === sortBy) {
          if (orderBy === "asc") {
            let hasDataArray = newActions.filter(action => action.who).sort((a, b) => b.who ? b.who.firstNames.localeCompare(a.who ? a.who.firstNames : "") : false)
            let noDataArray = newActions.filter(action => !action.who)

            newActions = [...hasDataArray, ...noDataArray]
          } else if (orderBy === "desc") {
            let hasDataArray = newActions.filter(action => action.who).sort((a, b) => a.who ? a.who.firstNames.localeCompare(b.who ? b.who.firstNames : "") : false)
            let noDataArray = newActions.filter(action => !action.who)

            newActions = [...noDataArray, ...hasDataArray]
          }
        }
        break;

      case "when":
        if (type === sortBy) {
          if (orderBy === "asc") {
            let hasDateArray = newActions.filter(action => action.when).sort((a, b) => {
              if (new Date(a.when).setHours(24, 0, 0, 0) === new Date(b.when).setHours(24, 0, 0, 0)) {
                return new Date(b.issue.reviewDate).setHours(24, 0, 0, 0) - new Date(a.issue.reviewDate).setHours(24, 0, 0, 0)
              }

              return new Date(b.when).setHours(24, 0, 0, 0) - new Date(a.when).setHours(24, 0, 0, 0)
            })
            let noDateArray = newActions.filter(action => !action.when).sort((a, b) => {
              return new Date(a.issue.reviewDate).setHours(24, 0, 0, 0) - new Date(b.issue.reviewDate).setHours(24, 0, 0, 0)
            })

            newActions = [...hasDateArray, ...noDateArray]
          } else if (orderBy === "desc") {
            let hasDateArray = newActions.filter(action => action.when).sort((a, b) => {
              if (new Date(b.when).setHours(24, 0, 0, 0) === new Date(a.when).setHours(24, 0, 0, 0)) {
                return new Date(a.issue.reviewDate).setHours(24, 0, 0, 0) - new Date(b.issue.reviewDate).setHours(24, 0, 0, 0)
              }

              return new Date(a.when).setHours(24, 0, 0, 0) - new Date(b.when).setHours(24, 0, 0, 0)
            })
            let noDateArray = newActions.filter(action => !action.when).sort((a, b) => {
              return new Date(b.issue.reviewDate).setHours(24, 0, 0, 0) - new Date(a.issue.reviewDate).setHours(24, 0, 0, 0)
            })

            newActions = [...noDateArray, ...hasDateArray]
          }
        }
        break;

      case "status":
        const completedActions = newActions.filter(action => action.status === "COMPLETED")
        const triggeredActions = newActions.filter(action => action.status === "TRIGGERED")
        const disabledActions = newActions.filter(action => action.status === "NEXT_STEP")

        if (type === sortBy) {
          if (orderBy === "asc") {
            newActions = [...disabledActions, ...completedActions, ...triggeredActions];
          } else if (orderBy === "desc") {
            newActions = [...triggeredActions, ...completedActions, ...disabledActions];

          }
        }
        break;

      case "escalated":
        const escalatedActions = newActions.filter(action => action.escalated)
        const normalActions = newActions.filter(action => !action.escalated)

        if (type === sortBy) {
          if (orderBy === "asc") {
            escalatedActions.sort((a, b) => b.shared - a.shared);
            newActions = [...normalActions, ...escalatedActions];
          } else if (orderBy === "desc") {
            escalatedActions.sort((a, b) => a.shared - b.shared);
            newActions = [...escalatedActions, ...normalActions];
          }
        }
        break;

      default:

        break;
    }

    this.setState({
      actions: newActions
    })
  }

  renderRows() {

    return (
      <Table striped bordered hover className="standard-tbl standard-actions-tbl mt-2 table-heading-background user-text pr-0">
        <thead>
          <tr>
            <th width="10%" onClick={() => this.handleSort("reviewDate")}>{getLabelText("date")} {this.state.sortBy === "reviewDate" && (
              (this.state.orderBy === "asc" && (
                <FontAwesomeIcon className="ml-2" icon={faChevronUp} />
              )) || (this.state.orderBy === "desc" && (
                <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
              ))
            )}</th>
            <th width="20%" onClick={() => this.handleSort("issue")}>{getLabelText("issue_problem")}{this.state.sortBy === "issue" && (
              (this.state.orderBy === "asc" && (
                <FontAwesomeIcon className="ml-2" icon={faChevronUp} />
              )) || (this.state.orderBy === "desc" && (
                <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
              ))
            )}</th>
            <th width="20%" onClick={() => this.handleSort("action")}>{getLabelText("our_action")} {this.state.sortBy === "action" && (
              (this.state.orderBy === "asc" && (
                <FontAwesomeIcon className="ml-2" icon={faChevronUp} />
              )) || (this.state.orderBy === "desc" && (
                <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
              ))
            )}</th>
            {
              !this.state.isMyActions && (
                  <th width="15%" onClick={() => this.handleSort("who")}>{getLabelText("who")} {this.state.sortBy === "who" && (
                      (this.state.orderBy === "asc" && (
                          <FontAwesomeIcon className="ml-2" icon={faChevronUp} />
                      )) || (this.state.orderBy === "desc" && (
                          <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
                      ))
                  )}</th>
                )
            }
            {
              this.state.isMyActions && (
                  <th width="15%" onClick={() => this.handleSort("team")}>{getLabelText("team")} {this.state.sortBy === "team" && (
                      (this.state.orderBy === "asc" && (
                          <FontAwesomeIcon className="ml-2" icon={faChevronUp} />
                      )) || (this.state.orderBy === "desc" && (
                          <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
                      ))
                  )}</th>
                )
            }
            <th width="10%" onClick={() => this.handleSort("when")}>{getLabelText("when")} {this.state.sortBy === "when" && (
              (this.state.orderBy === "asc" && (
                <FontAwesomeIcon className="ml-2" icon={faChevronUp} />
              )) || (this.state.orderBy === "desc" && (
                <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
              ))
            )}</th>
            <th width="5%" onClick={() => this.handleSort("status")}>{getLabelText("status")}</th>
            <th width="5%" onClick={() => this.handleSort("escalated")}>{getLabelText("escalated")}</th>
            <th width="15%"></th>
          </tr>
        </thead>
        <tbody>
          {this.state.actions.map((action, k) => {
            if (action.status !== "NONE" && action.archived === this.state.archived) {
              const flag = this.state.nextStepActionId === action.id;
              return (
                <tr key={k} ref={flag ? this.targetRowRef : null} className={flag ? "bg-highlight" : ""}>
                  <td>{this.getReviewDate(new Date(action.issue.reviewDate), action)}</td>
                  <td>{this.getIssueText(action.issue, action)}</td>
                  <td>{this.getActionText(action)}</td>
                  {!this.state.isMyActions && <td>{this.getUserInput(action)}</td>}
                  {this.state.isMyActions && <td>{this.getTeamName(action)}</td>}
                  <td valign="middle">{this.getWhenInput(action)}</td>
                  <td className="text-center">{this.getStatus(action)}</td>
                  <td className="text-center">{this.getEscalated(action)}</td>
                  <td>{this.getInputButtons(action.issue, action)}</td>
                </tr>
              )
            }
            return null
          }
          )}
        </tbody>
      </Table>
    )
  }

  render() {
    return (
      <Fragment>
        <SubHeaderComponent>
          <Col className="text-right childenSpace">
            <div className={"refreshBtn"}>
              <RealtimeRefreshComponent
                refresh={this.props.refresh}
                hasNewData={this.props.hasNewData}
                toggleRefresh={this.props.toggleRefresh}
              />
            </div>
            {!this.state.archived && (
              <Button className={"ml-2 btn btn-primary" + (this.state.editable ? " disabled" : "")} onClick={() => this.setCreate()}>{getLabelText("add")}<FontAwesomeIcon className="ml-2" icon={faPlus} /></Button>
            )}
          </Col>
        </SubHeaderComponent>
        <div className="grey-header-space">
          <Row className="mb-3">
            <Col className="text-right pr-0">
              <ButtonGroup toggle className="ml-1">
                <ToggleButton
                  type="radio"
                  variant="secondary"
                  name="createType"
                  value="0"
                  checked={!this.state.archived}
                  onChange={() => this.toggleArchived(false)}
                >
                  {getLabelText("active")}
                </ToggleButton>
                <ToggleButton
                  type="radio"
                  variant="secondary"
                  name="createType"
                  value="1"
                  checked={this.state.archived}
                  onChange={() => this.toggleArchived(true)}
                >
                  {getLabelText("archived")}
                </ToggleButton>
                <Button onClick={(e) => this.saveExcel()}>Export</Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row>
            <Col className="pr-0">

              {this.renderRows()}
            </Col>
          </Row>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam,
  user: state.userReducer.user
})

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ActionsScreen)
