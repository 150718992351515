import React, { Fragment } from "react";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import DatePicker from "react-datepicker"
// icons
import LightbulbBlack from "../../assets/improvement-idea-black.png";
import LightbulbWhite from "../../assets/improvement-idea-white.png";
import LightbulbBlue from "../../assets/improvement-idea-blue.png";

import Add from '../../assets/ButtonIcons/Add Blue.svg';
import Archive from '../../assets/ButtonIcons/Archive Blue.svg';
import Cancel from '../../assets/ButtonIcons/Cancel Blue.svg';
import Delete from '../../assets/ButtonIcons/Delete Blue.svg';
import Edit from '../../assets/ButtonIcons/Edit Blue.svg';
import Loader from '../../assets/ButtonIcons/Loader Blue.svg';
import PutBack from '../../assets/ButtonIcons/Put Back Blue.svg';
import Save from '../../assets/ButtonIcons/Save Blue.svg';
import Post from '../../assets/ButtonIcons/Post to Next Year Blue.svg';

import "react-datepicker/dist/react-datepicker.css"
import LabelComponent from "../../components/utils/getCompanyLabel";
import {useHistory} from "react-router-dom";
import NextStep from "../../components/shared/NextStep";

const getLabelText = key => <LabelComponent val={key}/>

function renderTooltip(props, text) {
  return (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );
}

function formattedDate(date) {
  let month = date.getMonth() + 1
  let day = date.getDate()

  if(month.toString().length < 2)
    month = "0" + month.toString()

  if(day.toString().length < 2)
    day = "0" + day.toString()

  return day + "/" + month + "/" + date.getFullYear()
}

const IssueComponent = (props) => {
  const router = useHistory();

  const {problem, editable, problemId} = props;
  const sharedIssue = problem.isShared && problem.creator.id === props.teamId;
  if(editable === 1 && problemId === problem.id) {
    return (
      <Fragment>
        <tr>
          <td></td>
          <td className="text-right">
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, getLabelText("save"))}
            >
              <button className="btn-icons-container" type="submit" onClick={props.saveProblem}>
                {props.loading && props.loadingType === "save" && props.loadingId === problem.id
                  ? (
                    <img src={Loader} alt="Loader" className="loader-spinner"/>
                  ) : (
                    <img src={Save} alt="Save" className="btn-icons"/>
                  )
                }
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, getLabelText("cancel"))}
            >
              <Button className="btn-icons-container" variant="primary" onClick={props.clearEditableState}>
                {props.loading && props.loadingType === "cancel" && props.loadingId === problem.id
                  ? (
                    <img src={Loader} alt="Loader" className="loader-spinner"/>
                  ) : (
                    <img src={Cancel} alt="Cancel" className="btn-icons"/>
                  )
                }
              </Button>
            </OverlayTrigger>
          </td>
        </tr>
        <tr>
          <td width="50%">
            <table>
              <tbody>
                <tr>
                  <th width="30%">{getLabelText("date")}</th>
                  <th width="70%">{getLabelText("what_happened")} ({props.type === "customer" ? getLabelText("customer_feedback") : getLabelText("supplier_issue")})?</th>
                </tr>
                <tr>
                  <td>
                    <DatePicker
                      name="date"
                      className="form-control"
                      required={true}
                      dateFormat="yyyy/MM/dd"
                      showYearDropdown
                      selected={props.date}
                      onChange={props.handleDateChange}
                      tabIndex="0"
                      inline={false}
                      autoComplete="off"
                    />
                  </td>
                  <td valign="top">
                    <input
                      type="text"
                      className="form-control"
                      name="feedback"
                      value={props.feedback}
                      onChange={(e) => props.handleChange(e)}
                      tabIndex="1"
                      autoComplete="off"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td>
            <table>
              <tbody>
                <tr>
                  <th>Q2: {getLabelText("why_did_it_happen")}</th>
                </tr>
                <tr>
                  <td><input
                    type="text"
                    className="form-control"
                    name="rootCause"
                    value={props.rootCause}
                    onChange={(e) => props.handleChange(e)}
                    tabIndex="3"
                    autoComplete="off"
                  /></td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td className="bottom-border">
            <table>
              <tbody>
                <tr>
                  <th width="100%" colSpan={2}>Q1: {getLabelText("what_must_we_do")}</th>
                </tr>
                {props.actions.map((action, k) => {
                  if(action.actionType === "FEEDBACK") {
                    return (
                      <tr key={k}>
                        <td><input
                          type="text"
                          className="form-control"
                          value={action.description}
                          onChange={(e) => props.editAction(k, e)}
                          autoComplete="off"
                        /></td>
                      </tr>
                    )
                  }

                  return null
                })}
                {!(props.loading && props.loadingId === problem.id && props.loadingType === "save") && (
                  <tr>
                    <td><input
                      type="text"
                      className="form-control"
                      name="newSolution"
                      value={props.newSolution}
                      onChange={(e) => props.handleChange(e)}
                      tabIndex="2"
                      autoComplete="off"/></td>
                    <td>
                      <button className="btn-icons-container" type="submit" onClick={() => props.saveAction("FEEDBACK")}>
                        <img src={Add} alt="Add" className="btn-icons"/>
                      </button>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </td>
          <td className="bottom-border">
            <table>
              <tbody>
                <tr>
                  <th width="100%" colSpan={2}>Q3: {props.type === "customer" ? getLabelText("prevent_root_cause") : getLabelText("how_will_supplier_prevent")}</th>
                </tr>
                {props.actions.map((action, k) => {
                  if(action.actionType === "ROOT_CAUSE") {
                    return (
                      <tr key={k}>
                        <td><input
                          type="text"
                          className="form-control"
                          onChange={(e) => props.editAction(k, e)}
                          value={action.description}
                          autoComplete="off"
                        /></td>
                      </tr>
                    )
                  }

                  return null
                })}
                {!(props.loading && props.loadingId === problem.id && props.loadingType === "save") && (
                  <tr>
                    <td><input
                      type="text"
                      className="form-control"
                      name="newFix"
                      value={props.newFix}
                      onChange={(e) => props.handleChange(e)}
                      tabIndex="4"
                      autoComplete="off"
                    /></td>
                    <td>
                      <button className="btn-icons-container" type="submit" onClick={() => props.saveAction("ROOT_CAUSE")}>
                        <img src={Add} alt="Add" className="btn-icons"/>
                      </button>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </td>
        </tr>
      </Fragment>
    )
  } else {
    return (
      <Fragment>
        <tr>
          <td></td>
          <td className="text-right">
            {!problem.archived && props.shareRequested && !sharedIssue && !problem.isShared && (
              <OverlayTrigger
                placement="top"
                delay={{ show: 25, hide: 40 }}
                overlay={(props) => renderTooltip({...props}, getLabelText("share_issue"))}
              >
                <Button
                  className={"btn-icons-container" + (editable === 1 ? " disabled" : "")}
                  variant="primary"
                  onClick={() => props.shareIssue(props.shareRequested, problem.id)}
                >
                  {props.loading && props.loadingId === problem.id && props.loadingType === "sharing"
                    ? (
                      <img src={Loader} alt="Loader" className="loader-spinner"/>

                    ) : (
                        <img src={Post} alt="Share" className="btn-icons"/>
                    )
                  }
                </Button>
              </OverlayTrigger>
            )}
            {!problem.archived && !sharedIssue && props.canEdit && (
              <OverlayTrigger
                placement="top"
                delay={{ show: 25, hide: 40 }}
                overlay={(props) => renderTooltip({...props}, getLabelText("edit"))}
              >
                <Button
                  className={"btn-icons-container" + (editable === 1 ? " disabled" : "")}
                  variant="primary"
                  disabled={problem.archived}
                  onClick={() => props.editProblem(props.entityId, problem.id)}
                >
                  <img src={Edit} alt="Edit" className="btn-icons"/>
                </Button>
              </OverlayTrigger>
            )}
            {
              props.canEdit && (
                <>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 25, hide: 40 }}
                    overlay={(props) => renderTooltip({...props}, problem.archived ? getLabelText("put_back") : getLabelText("archive"))}
                  >
                    <Button
                      className={"btn-icons-container" + (editable === 1 ? " disabled" : "")}
                      variant="primary"
                      onClick={() => props.archiveProblem(problem.id, problem.archived, problem.actions)}
                    >
                      {props.loading && props.loadingId === problem.id && props.loadingType === "archive"
                        ? (
                          <img src={Loader} alt="Loader" className="loader-spinner"/>

                        ) : problem.archived
                        ? (
                            <img src={PutBack} alt="PutBack" className="btn-icons"/>
                        ) : (
                            <img src={Archive} alt="Archive" className="btn-icons"/>
                        )
                      }
                    </Button>
                  </OverlayTrigger>
                </>
              )
            }
            {problem.archived && props.canEdit && (
              <OverlayTrigger
                placement="top"
                delay={{ show: 25, hide: 40 }}
                overlay={(props) => renderTooltip({...props}, getLabelText("delete"))}
              >
                <Button
                  className={"btn-icons-container" + (editable === 1 ? " disabled" : "")}
                  variant="primary"
                  onClick={() => props.deleteProblem(problem)}
                >
                  {props.loading && props.loadingId === problem.id && props.loadingType === "delete"
                    ? (
                      <img src={Loader} alt="Loader" className="loader-spinner"/>
                    ) : (
                      <img src={Delete} alt="Delete" className="btn-icons"/>
                    )
                  }
                </Button>
              </OverlayTrigger>
            )}
          </td>
        </tr>
        <tr className={problem.archived || editable === 1 || sharedIssue ? "disabled" : ""}>
          <td width="50%">
            <table>
              <tbody>
                <tr>
                  <th width="30%">{getLabelText("date")}</th>
                  <th width="70%">{getLabelText("what_happened")} ({props.type === "customer" ? getLabelText("customer_feedback") : getLabelText("supplier_issue")})?</th>
                </tr>
                <tr>
                  <td>{ formattedDate(new Date(problem.reviewDate))}</td>
                  <td>{problem.whatText}</td>
                </tr>
              </tbody>
            </table>
          </td>
          <td>
            <table>
              <tbody>
                <tr>
                  <th>Q2: {getLabelText("why_did_it_happen")}</th>
                </tr>
                <tr>
                  <td>{problem.whyText}</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr className={problem.archived || editable === 1 || sharedIssue ? "disabled" : ""}>
          <td className="bottom-border">
            <table>
              <tbody>
                <tr>
                  <th width="80%">Q1: {getLabelText("what_must_we_do")}</th>
                  <th colSpan="2" width="20%" className="text-center">{getLabelText("status")}</th>
                </tr>
                {problem.actions.map((solution, k) => {
                  if(solution.actionType === "FEEDBACK") {
                    return (
                      <tr key={k}>
                        <td>{solution.description}</td>
                        <td className="text-center">
                          <NextStep
                              loading={false}
                              loadingType={null}
                              loadingId={null}
                              editType={null}
                              issue={null}
                              action={solution}
                              actionId={null}
                              status={solution.status}
                              nextSteps={() => props.nextStep(solution.id)}
                          />
                        </td>
                      </tr>
                    )
                  }

                  return null
                })}
              </tbody>
            </table>
          </td>
          <td className="bottom-border">
            <table>
              <tbody>
                <tr>
                  <th width="80%">Q3: {props.type === "customer" ? getLabelText("prevent_root_cause") : getLabelText("how_will_supplier_prevent")}</th>
                  <th colSpan="2" width="20%" className="text-center">{getLabelText("status")}</th>
                </tr>
                {problem.actions.map((fix, k) => {
                  if(fix.actionType === "ROOT_CAUSE") {
                    return (
                      <tr key={k}>
                        <td>{fix.description}</td>
                          <td className="text-center">
                            <NextStep
                                loading={false}
                                loadingType={null}
                                loadingId={null}
                                editType={null}
                                issue={null}
                                action={fix}
                                actionId={null}
                                status={fix.status}
                                nextSteps={() => props.nextStep(fix.id)}
                            />
                          </td>
                          <td className="text-center">
                            {!fix.linkedIssue
                              ? (
                                <button className="status bg-grey cdi-grey-outline no-outline rounded-pill border-0 outline-0 font-weight-bold ml-2" onClick={() => props.postToImprovementIdeas(problem, fix)}>
                                  <img src={LightbulbBlack} alt="Lightbulb" className="lightbulb" />
                                </button>
                              ) : fix.linkedIssue && fix.linkedIssue.innovationStatus === "STANDARDISED"
                              ? (
                                <button className="status border-color-selected color-selected rounded-pill border-0 outline-0 font-weight-bold ml-2">
                                  <img src={LightbulbWhite} alt="LightbulbWhite" className="lightbulb-white text-white" />
                                </button>
                              )
                              : <button className="status cdi-blue-outline bg-white rounded-pill border-0 outline-0 font-weight-bold ml-2">
                                  <img src={LightbulbBlue} alt="LightbulbBlue" className="lightbulb cdi-blue-txt" />
                              </button>
                            }
                          </td>
                      </tr>
                    )
                  }

                  return null
                })}
              </tbody>
            </table>
          </td>
        </tr>
      </Fragment>
    )
  }
}

export default IssueComponent
