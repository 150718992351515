import { gql } from "apollo-boost";

// TODO: remove hardcoded value
export const TEAM_ATTENDANCE_DATA_QUERY = gql`
  query TeamAttendance($id: ID!) {
    team(id: $id) {
      id
      attendances {
        id
        date
        status
      }
    }
  }
`;

export const COMPANY_MEASUREMENTS_LIST_QUERY = gql`
  query {
    goals {
      id
      period
      nextYearLink
      prevYearLink
      measurement {
        id
        measurement
        unit {
          id
          name
        }
        goalCategory {
          id
          name
          order
        }
      }
    }
  }
`;

// $period: MetricPeriod!
// $periodValues: [Int!]!
// $periodKey: String!
// $isTrend: Boolean!

export const COMPANY_MEASUREMENT_DATA_QUERY = gql`
  query TeamMeasurementData(
    $id: ID!
    $period: MetricPeriod!
    $periodValues: [Int!]!
    $periodKey: String!
    $teamId: String!
  ) {

      goal(id: $id) {
        id
        period
        numberTo
        numberFrom
        dueDate
        nextYearLink
        prevYearLink
        companyMetrics(period: $period, periodValues: $periodValues, periodKey: $periodKey, teamId: $teamId) {
          id
          value
          status
          periodValue
          periodStatus
          periodKey
          comment
          target
        }
        measurement {
          id
          measurement
          unit {
            id
            name
          }
          goalCategory {
            id
            name
          }
        }
      }

  }
`;

export const CREATE_COMPANY_METRIC = gql`
  mutation CreateCompanyMetric(
    $goal: String!
    $team: String!
    $period: MetricPeriod!
    $periodValue: Int!
    $periodKey: String!
    $periodStatus: MetricPeriodStatus!
    $value: Float
    $status: MetricStatus!
    $comment: String
    $target: Float
  ) {
    createCompanyMetric(
      goal: $goal
      team: $team
      period: $period
      periodValue: $periodValue
      periodKey: $periodKey
      periodStatus: $periodStatus
      value: $value
      status: $status
      comment: $comment
      target: $target
    ) {
      id
      value
      status
      periodValue
      periodStatus
      comment
      target
    }
  }
`;

export const UPDATE_COMPANY_METRIC = gql`
  mutation UpdateMetric(
    $id: ID!
    $value: Float
    $status: MetricStatus
    $periodStatus: MetricPeriodStatus
    $comment: String
    $target: Float
  ) {
    updateCompanyMetric(
      id: $id
      value: $value
      status: $status
      periodStatus: $periodStatus
      comment: $comment
      target: $target
    ) {
      id
      value
      status
      periodValue
      periodStatus
      comment
      target
    }
  }
`;
