import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

const DropdownLink = ({ children, href }) => (
  <Dropdown.Item as={Link} to={href}>
    {children}
  </Dropdown.Item>
);

export default DropdownLink;
