const Constants = {
    Permission: {
        Consultant: "Consultant",
        CompanyAdmin: "Administrator",
        FunctionAdmin: "Function Admin",
        TeamAdmin: "Coach",
        User: "Team Member",
    }
}

export default Constants;