import React, {useState, useCallback, useEffect} from "react";
import Modal from 'react-modal';
import LabelComponent from "../../components/utils/getCompanyLabel";

const getLabelText = key => <LabelComponent val={key}/>

const PERIOD_FREQUENCIES = [
    "DAY",
    "WEEK",
    "MONTH"
];

const frequencyProps = {
    [PERIOD_FREQUENCIES[0]]: { text: "Daily" },
    [PERIOD_FREQUENCIES[1]]: { text: "Weekly" },
    [PERIOD_FREQUENCIES[2]]: { text: "Monthly" }
};

const FilterTaskTrackerModal = ({ users, routines, showModal, setShowModal, filters, setFilters }) => {
    const [taskTracker, setTaskTracker] = useState(filters.taskTracker)
    const [taskTrackersCategories, setTaskTrackersCategories] = useState([])
    const [selectedUser, setSelectedUser] = useState(filters.userId);
    const [selectedFrequency, setSelectedFrequency] = useState(filters.frequency)

    const close = useCallback(
        () => {
            setShowModal(false);
        },
        [setShowModal]
    );

    const reset = useCallback(
        () => {
            setSelectedUser(null);
            setSelectedFrequency(null);
            setTaskTracker(null);
            setFilters({
                userId: null,
                frequency: null,
                leadershipRoutine: null,
            })
            close();
        },
        [setSelectedUser, setSelectedFrequency, setTaskTracker, setFilters, close]
    );

    const getUniqueTaskCategories = () => {
        let categories = [];
        routines.map(r => {
            if(categories.findIndex(c => c.id === r.task?.taskCategory?.id) === -1)
            {
                categories.push(r?.task?.taskCategory);
            }
        })
        return categories;
    }

    const onSave = () => {
        setFilters({
            userId: selectedUser,
            frequency: selectedFrequency,
            taskTracker: taskTracker
        })
        close();
    };

    const changeFrequency = (frequency) => {
        setSelectedFrequency(frequency)
    }

    useEffect(() => {
        const categories = getUniqueTaskCategories();
        setTaskTrackersCategories(categories)
    }, [routines]);

    return (
        <Modal
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            contentLabel="Result"
            className="standard-modal standard-modal-task-category"
        >
            <div className="row mb-2">
                <div className="col-12 text-center">
                    <h6 className="cdi-blue-txt ml-0 mb-1">Task Tracker Filter</h6>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-12 text-center">
                    <select
                        className={"form-control cdi-ddl w-100 ml-0"}
                        value={taskTracker}
                        onChange={({ target: { value } }) => setTaskTracker(value)}
                        autoComplete="off"
                    >
                        <option value={''}>Select Task</option>
                        {taskTrackersCategories.map(({ id, name }) => {
                            return <option key={id} value={id}>{name}</option>
                        })}
                    </select>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-12 text-center">
                    <select
                        className="form-control cdi-ddl w-100 ml-0"
                        value={selectedUser}
                        onChange={({ target: { value } }) => setSelectedUser(value)}
                        autoComplete="off"
                    >
                        <option value={''}>Select Member</option>
                        {users.map(({ id, firstNames, lastName }) => {
                            return <option key={id} value={id}>{firstNames} {lastName}</option>
                        })}
                    </select>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-12 text-center">
                    <select
                        className={"form-control cdi-ddl w-100 ml-0"}
                        value={selectedFrequency}
                        onChange={({ target: { value } }) => changeFrequency(value)}
                        autoComplete="off"
                    >
                        <option value={''}>Select Frequency</option>
                        {PERIOD_FREQUENCIES.map((frequency, i) => {
                            return <option key={i} value={frequency}>{frequencyProps[frequency].text}</option>
                        })}
                    </select>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-4">
                    <button className="btn btn-primary w-100" onClick={() => onSave()}>
                        {getLabelText("save")}
                    </button>
                </div>
                <div className="col-4">
                    <button className="btn btn-default cdi-blue-outline color-blue w-100" onClick={() => close()}>{getLabelText("cancel")}</button>
                </div>
                <div className="col-4">
                    <button className="btn btn-default cdi-blue-outline color-blue w-100" onClick={() => reset()}>{getLabelText("clear")} {getLabelText("filter")}</button>
                </div>
            </div>
        </Modal>
    )
}

export default FilterTaskTrackerModal;
