import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
// services
import { getMeasurements, createMeasurement, updateMeasurement, deleteMeasurement } from '../services/MeasurementService'
import { getUnits, createUnit } from "../services/UnitService"
import { updatePermissions } from '../redux/services/UserService';
// bootstrap
import { Row, Button, Col, OverlayTrigger, Tooltip, Table } from "react-bootstrap"

import displayErrorToast from '../components/utils/displayErrorToast'
import putOtherLast from "../components/utils/putOtherLast"
import SubHeaderComponent from "../components/SubHeaderComponent";
import CreatableSelect from 'react-select/creatable'
import LabelComponent from "../components/utils/getCompanyLabel";
import * as DefaultLabels from '../assets/glossary.json';
// icons
import Add from '../assets/ButtonIcons/Add Blue.svg';
import Cancel from '../assets/ButtonIcons/Cancel Blue.svg';
import Delete from '../assets/ButtonIcons/Delete Blue.svg';
import Edit from '../assets/ButtonIcons/Edit Blue.svg';
import Loader from '../assets/ButtonIcons/Loader Blue.svg';
import Save from '../assets/ButtonIcons/Save Blue.svg';
import { toast } from 'react-toastify';
const labels = DefaultLabels.default;

function renderTooltip(props, text) {
  return (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );
}

class MeasurementScreen extends Component {
  state = {
    goalCategories: [],
    units: [],
    measurements: [],
    category: "",
    measurement: "",
    unit: "",
    editId: 0,
    loading: false,
    loadType: ""
  }

  constructor(props) {
    super(props)

    this.props.toggleDirtyState(true)
    this.props.togglePageLoad(true)
  }

  async componentDidMount() {
    await this.fetchMeasurements()
    await this.fetchUnits()
    this.props.togglePageLoad(false)
  }

  async fetchMeasurements() {
    const response = await getMeasurements()

    this.setState({
      goalCategories: response.data.goalCategories
    })
  }

  async fetchUnits () {
    const response = await getUnits()

    this.setState({
      units: putOtherLast(response.data.units)
    })
  }

  getLabelText = key => <LabelComponent val={key}/>

  getText = val => {
    const { user } = this.props;

    const companyId = localStorage.getItem("companyId");
    let lang = "EN";
    let langLabels = labels.find(lbl => lbl.lang === lang);
    let def = langLabels.glossary.find(label => label.key === val);

    if(user && user.companies && user.companies.length) {
      const company = user.companies.find(company => company.id === companyId);
      if(company) {
        lang = company.language;
        langLabels = labels.find(lbl => lbl.lang === lang);
        def = langLabels.glossary.find(label => label.key === val);
        if(!def) {
          lang = "EN";
          langLabels = labels.find(lbl => lbl.lang === lang);
          def = langLabels.glossary.find(label => label.key === val);
        }
      }
      if(company && company.companyGlossaries) {
        const custom = company.companyGlossaries.find(label => label.key === val);

        if(custom) return custom.value
      }
    }

    if(!def) {
      console.log(val)
    }
    return def.value
  }

  toggleAdd = (id) => {
    this.clearState()
    this.setState({
      category: id,
      newCategory: id
    })
  }

  clearState = () => {
    this.props.toggleDirtyState()
    this.setState({
      category: "",
      measurement: "",
      unit: "",
      editId: 0
    })
  }

  validForm = () => {
    const { measurement, unit } = this.state

    return measurement.length > 0 && unit.length > 0
  }

  handleSubmit = async () => {
    const { category, measurement, unit, editId, units, newCategory } = this.state
    this.setState({
      loading: true,
      loadType: "create"
    })
    if (this.validForm()) {
      let selectedUnit = unit
      try {
        if(units.every(({ id }) => id !== unit)) {
          const response = await createUnit(unit)
          selectedUnit = response.data.createUnit.id
        }
        if(editId !== 0) {
          await updateMeasurement(editId, newCategory, measurement, selectedUnit)
        } else {
          await createMeasurement(category, measurement, selectedUnit)
        }
        await this.fetchMeasurements()
        await this.fetchUnits
        this.clearState()
        this.props.updatePermissions("hasMeasurements", true)
      } catch (error) {
        displayErrorToast(error)
      }
    }

    this.setState({
      loading: false,
      loadType: ""
    })
  }

  renderAddMeasurement = (id) => {
    const { loading, loadType, units, goalCategories, category, newCategory, editId, unit } = this.state
    let selectedUnit = {label: unit, value: unit}
    const unitOptions = units.sort((a,b) => a.name.localeCompare(b.name)).map(({id, name}) => {
      if(id === unit) {
        selectedUnit = { value: id, label: name }
      }

      return { value: id, label: name }
    })
    if (this.state.category === id) {
      return (
        <Fragment>
          <td className="pt-1">
            <select
              className={"form-control" + (editId === 0 && id === category ? " disabled" : "")}
              name="category"
              onChange={(e) => this.setState({ newCategory: e.target.value })}
              value={newCategory}
              >
              <option value={0} disabled>Select Goal Category</option>
              {goalCategories.map((category, i) => {
                return <option value={category.id} key={i}>{category.name}</option>
              })}
            </select>
          </td>
          <td className="pt-1">
            <input
              className="form-control"
              autoFocus
              name="measurement"
              type="text"
              value={this.state.measurement}
              onChange={(e) => this.setState({ measurement: e.target.value })}
              autoComplete="off"
            ></input>
          </td>
          <td className="pt-1">
            <CreatableSelect
              className="font-weight-normal"
              onChange={({ value }) => this.setState({ unit: value })}
              options={unitOptions}
              value={selectedUnit}
              autoComplete="off"
            />
            <small>{this.getLabelText("create_new_unit_measurements")}</small>
          </td>
          <td className="pt-1 text-right">
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, this.getLabelText('save'))}
            >
              <Button className="btn-icons-container" variant="primary"
                onClick={this.handleSubmit}>
                {loading && loadType === "create"
                  ? (
                    <img src={Loader} alt="Loader" className="loader-spinner"/>
                  )
                  : (
                    <img src={Save} alt="Save" className="btn-icons"/>
                  )
                }
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, this.getLabelText('cancel'))}
            >
              <Button variant="primary" className="btn-icons-container" onClick={this.clearState}>
                <img src={Cancel} alt="Cancel" className="btn-icons"/>
              </Button>
            </OverlayTrigger>
          </td>
        </Fragment>
      )
    }
  }

  deleteItem = async id => {
    this.setState({
      loading: true,
      loadType: "delete",
      deleteId: id
    });
    if (window.confirm(this.getText('delete_department_prompt'))) {
      try {
        this.props.togglePageLoad(true);
        await deleteMeasurement(id);
        toast.success('Measurement Deleted Successfully');
        this.fetchMeasurements();
      } catch (error) {
        displayErrorToast(error)
      } finally {
        this.props.togglePageLoad(false);
      }
    }
    this.setState({
      loading: false,
      loadType: "",
      deleteId: 0
    })
  }

  setEdit = (id, measurement) => {
    this.clearState()
    this.setState({
      editId: measurement.id,
      category: id,
      newCategory: id,
      measurement: measurement.measurement,
      unit: measurement.unit.id
    })
  }

  renderMeasurementRow = (j, measurement, category) => {
    const { editId, loading, loadType, deleteId } = this.state
    if (editId === measurement.id) {
      return this.renderAddMeasurement(measurement.id, measurement.name)
    } else {
      return (
        <Fragment>
          <td className="pt-1">
            {category.name}
          </td>
          <td className="pt-1">
            {measurement.measurement}
          </td>
          <td className="pt-1">
            {measurement.unit.name}
          </td>
          <td className="pt-1 text-right">
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, this.getLabelText('edit'))}
            >
              <Button
                className={"btn-icons-container" + (this.state.category !== "" ? " disabled" : "")}
                variant="primary"
                onClick={() => this.setEdit(category.id, measurement)}
                >
                <img src={Edit} alt="Save" className="btn-icons"/>
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              delay={{ show: 25, hide: 40 }}
              overlay={(props) => renderTooltip({...props}, this.getLabelText('delete'))}
            >
              <Button
                className={"btn-icons-container" + (this.state.category !== "" ? " disabled" : "")}
                disabled={false}
                variant="primary"
                onClick={() => this.deleteItem(measurement.id)}
                >
                {loading && loadType === "delete" && deleteId === measurement.id
                  ? (
                    <img src={Loader} alt="Loader" className="loader-spinner"/>
                  )
                  : (
                    <img src={Delete} alt="Delete" className="btn-icons"/>
                  )
                }
              </Button>
            </OverlayTrigger>
          </td>
        </Fragment>
      )
    }
  }


  render() {
    return (
      <Fragment>
        <SubHeaderComponent></SubHeaderComponent>
        <div className="grey-header-space container-left">
          <Row className="justify-content-left">
            <Col xs="12" className="px-0">
              <Table bordered className="bordered-minus">
                <tbody>
                {this.state.goalCategories.map((category, i) => (
                    <Fragment key={i}>
                      <tr>
                        <td valign="top" className="bordered-fix">
                          <div className="w-100 mb-3 table-sub-heading">
                            <h5 className="d-inline">{category.name}</h5>
                            <div className="d-inline" style={{ float: "right" }}>
                              <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 25, hide: 40 }}
                                  overlay={(props) => renderTooltip({...props}, this.getLabelText('add'))}
                              >
                                <Button className={"btn-icons-container" + (this.state.category !== "" ? " disabled" : "")} variant="primary" onClick={() => this.toggleAdd(category.id)}>
                                  <img src={Add} alt="Add" className="btn-icons"/>
                                </Button>
                              </OverlayTrigger>
                            </div>
                          </div>
                          <Table striped bordered hover className="standard-tbl standard-measurement-tbl user-text table-heading-background">
                            <thead>
                            <tr>
                              <th>{this.getLabelText("goal_category")}</th>
                              <th>{this.getLabelText("measurement")}</th>
                              <th>{this.getLabelText("unit")}</th>
                              <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              { this.state.editId === 0 && category.id === this.state.category
                                  ? this.renderAddMeasurement(category.id, category.name)
                                  : null
                              }
                            </tr>
                            {category.measurements.length > 0
                                ? category.measurements.map((measurement, j) => (
                                    <tr key={j}>
                                      {this.state.editId === measurement.id
                                          ? this.renderAddMeasurement(category.id, category.name)
                                          : this.renderMeasurementRow(j, measurement, category)
                                      }
                                    </tr>
                                )) : (
                                    <tr>
                                      <td colSpan="4" className="text-center">{this.getLabelText("no_data_measurements")}</td>
                                    </tr>
                                )
                            }
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    </Fragment>
                ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Fragment>
    )

  }
}

const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam,
  user: state.userReducer.user
})

const mapDispatchToProps = {
  updatePermissions
};

export default connect(mapStateToProps, mapDispatchToProps)(MeasurementScreen);
