import React, { useCallback, useState, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import classnames from "../../components/utils/classnames";
import { useMutation } from "@apollo/react-hooks";
import {
  CREATE_PARTNER_STATUS_MUTATION,
  UPDATE_PARTNER_STATUS_MUTATION,
} from "./resources/gql";
import displayErrorToast from "../../components/utils/displayErrorToast";
import {PARTNER_STATUSES} from './resources/statuses';

const matrixCellProps = {
  [PARTNER_STATUSES[0]]: { classes: "bg-grey skill-border-lg partner-grey-border" },
  [PARTNER_STATUSES[1]]: { classes: "bg-white skill-border-lg partner-grey-border" },
  [PARTNER_STATUSES[2]]: { classes: "color-selected skill-border-lg skill-selected-color-border" },
  [PARTNER_STATUSES[3]]: { classes: "bg-color-negative skill-border-lg skill-red-border" },
};

const PartnerCell = ({
  partnerStatus,
  teamId,
  canEdit
}) => {
  const [obj, setObj] = useState(partnerStatus);
  const [status, setStatus] = useState(partnerStatus.status)
  const [displayedStatus, setDisplayedStatus] = useState(partnerStatus.status)
  const [loading, setLoading] = useState(false)

  // useEffect(() => {
  //   setObj(partnerStatus)
  //   setStatus(partnerStatus.status)
  //   setDisplayedStatus(partnerStatus.status)
  // }, [setObj, setStatus, setDisplayedStatus, partnerStatus])

  const reset = () => {
    setDisplayedStatus(status)
    setLoading(false)
  }

  const onError = (error) => {
    displayErrorToast(error);
    reset();
  };

  const onMutationComplete = useCallback(
    (data) => {
      const newData = data.createPartnerValueStatus ? data.createPartnerValueStatus : data.updatePartnerValueStatus
      setObj(newData)
      setStatus(newData.status)
      setLoading(false)
    },
    [setObj, setStatus]
  );

  const [updatePartnerStatus] = useMutation(UPDATE_PARTNER_STATUS_MUTATION, {
   onCompleted: onMutationComplete,
   onError
 });

 const [createPartnerStatus] = useMutation(CREATE_PARTNER_STATUS_MUTATION, {
   onCompleted: onMutationComplete,
   onError
});


  const { classes, icon, text } = matrixCellProps[displayedStatus];

  const toggleStatus = () => {
    setLoading(true)
    const statusIndex = PARTNER_STATUSES.indexOf(displayedStatus)
    const nextStatusIndex = (statusIndex === 3) ? 0 : statusIndex + 1
    const nextStatus = PARTNER_STATUSES[nextStatusIndex]

    if(obj.id === 0) createPartnerStatus({
      variables: {
        teamId,
        customerId: obj.customer.id,
        supplierId: obj.supplier.id,
        status: nextStatus
      }
    })
    else updatePartnerStatus({
      variables: {
        id: obj.id,
        status: nextStatus
      }
    })

    setDisplayedStatus(nextStatus)
  }

  const className = classnames(
    "attendance-cell text-center font-weight-bold mr-2",
    classes,
  );

  return (
    <Fragment>
      {obj.customer.id === obj.supplier.id
        ? (
          <div className="bg-white skill-border-lg partner-grey-border attendance-cell text-center font-weight-bold p-1 mr-2">
            {icon && <FontAwesomeIcon icon={icon} />}
            {!!text && text}
          </div>
        ) : (
          <div className={className + ((loading || !canEdit()) ? " disabled" : "")} onClick={() => canEdit() && toggleStatus()}>
            {loading && <FontAwesomeIcon icon={faSpinner} className="fa-spin text-black" />}
          </div>
        )
      }
    </Fragment>
  );
};

export default PartnerCell;
