import React, { useState, useEffect } from "react";
import classnames from "../../components/utils/classnames";
import { getPeriodDisplayText } from "./hooks";
import LabelComponent from "../../components/utils/getCompanyLabel";

const getLabelText = key => <LabelComponent val={key}/>

const getGoalFormattedDate = date => {
  const months = [
    {
      index: 0,
      name: "Jan"
    },
    {
      index: 1,
      name: "Feb"
    },
    {
      index: 2,
      name: "Mar"
    },
    {
      index: 3,
      name: "Apr"
    },
    {
      index: 4,
      name: "May"
    },
    {
      index: 5,
      name: "Jun"
    },
    {
      index: 6,
      name: "Jul"
    },
    {
      index: 7,
      name: "Aug"
    },
    {
      index: 8,
      name: "Sep"
    },
    {
      index: 9,
      name: "Oct"
    },
    {
      index: 10,
      name: "Nov"
    },
    {
      index: 11,
      name: "Dec"
    }
  ]

  return months[new Date(date).getMonth()].name + " " + new Date(date).getFullYear()
}

//check if upper of lower
const Section = ({ teamGoalGraphConfig, date, title, from, to, single = false, edit = false, setTrigger, isUpper = false, isFrequency = false, isLower = false, isTrend = false, isTarget = false, by="", triggers, editable }) => {
  const [fromValue, setFromValue] = useState(triggers[0])
  const [toValue, setToValue] = useState(triggers[1])
  let periodKey = `${date.getMonth()}${date.getFullYear()}`
  useEffect(() => {
    setFromValue(!triggers[0] && triggers[0] !== 0 ? null : triggers[0])
    setToValue(!triggers[1] && triggers[1] !== 0 ? null: triggers[1])
  }, [triggers, setFromValue, setToValue])

  const onChange = (e) => {
    let emptyTo = false
    e.persist()
    if (e.target.value === "-" || e.target.value === "e")
      return


    if (e.target.value === "") e.target.value = null;

    const variables = {
      key: periodKey
    };

    if (isTarget) {
      if (e.target.name === "to") {
        variables.targetTo = parseFloat(e.target.value)
        setToValue(parseFloat(e.target.value))
      }
      if (e.target.name === "from") {
        variables.targetFrom = parseFloat(e.target.value)
        setFromValue(parseFloat(e.target.value))
        if(typeof(teamGoalGraphConfig) === "undefined" || teamGoalGraphConfig.targetTo === null  || to === null) {
          emptyTo = true
          variables.targetTo = parseFloat(e.target.value)
          setToValue(parseFloat(e.target.value))
        }
      }
    }

    if (isUpper) {
      if (e.target.name === "to") {
        variables.upperTriggerTo = parseFloat(e.target.value)
        setToValue(parseFloat(e.target.value))
      }
      if (e.target.name === "from") {
        variables.upperTriggerFrom = parseFloat(e.target.value)
        setFromValue(parseFloat(e.target.value))
        if(typeof(teamGoalGraphConfig) === "undefined" || teamGoalGraphConfig.upperTriggerTo === null  || to === null) {
          emptyTo = true
          variables.upperTriggerTo = parseFloat(e.target.value)
          setToValue(parseFloat(e.target.value))
        }
      }
    }

    if (isLower) {
      if (e.target.name === "to") {
        variables.lowerTriggerTo = parseFloat(e.target.value)
        setToValue(parseFloat(e.target.value))
      }

      if (e.target.name === "from") {
        variables.lowerTriggerFrom = parseFloat(e.target.value)
        setFromValue(parseFloat(e.target.value))
        if(typeof(teamGoalGraphConfig) === "undefined" || teamGoalGraphConfig.lowerTriggerTo === null  || to === null) {
          emptyTo = true
          variables.lowerTriggerTo = parseFloat(e.target.value)
          setToValue(parseFloat(e.target.value))
        }
      }
    }

    if (isFrequency) {
      variables.frequencyTrigger = parseFloat(e.target.value)
      setTrigger(variables.frequencyTrigger)
    } else {
      if (e.target.name === "from") {
        setTrigger(
          [parseFloat(e.target.value), e.target.value === "" ? null : !emptyTo ? to : parseFloat(e.target.value)]
        )
      } else {
        setTrigger(
          [e.target.value === "" ? null : from, parseFloat(e.target.value)]
        )
      }
    }


  }

  return (
    <div className="w-100">
      <h6 className="font-weight-bold">{title}</h6>
      <div
        className={classnames(
          "w-100",
          single ? "justify-content-center" : "justify-content-between"
        )}
      >
        {single ? (
          editable ? (
            <label className="font-weight-bold control-label">
              <input style={{ width: '70px' }} className="form-control d-inline controls-input" type="number" name="from" onChange={onChange} value={fromValue || fromValue === 0 ? fromValue : ""}></input>
            </label>
          ) : (
            <label className="font-weight-bold control-label">
              {fromValue || fromValue === 0 ? fromValue : "N/A"}
            </label>
            )
        ) : (
            <>
              {!isTrend && (
                <>
                  {editable
                    ? (
                      <>
                        <label className="mr-2 control-label d-inline">{getLabelText("from")}</label>
                        <label className="font-weight-bold control-label">
                          <input style={{ width: '70px' }} className="form-control d-inline controls-input" type="number" name="from" onChange={onChange} value={fromValue || fromValue === 0 ? fromValue : ""}></input>
                        </label>
                        <label className="mx-2 control-label d-inline">{getLabelText("to")}</label>
                        <label className="font-weight-bold control-label">
                          <input style={{ width: '70px' }} className="form-control d-inline controls-input" type="number" name="to" onChange={onChange} value={toValue || toValue === 0 ? toValue : ""}></input>
                        </label>
                      </>
                  ) : (
                    <>
                    <label className="mr-2 control-label d-inline">{getLabelText("from")}</label>
                    <label className="font-weight-bold control-label">
                      {fromValue || fromValue === 0 ? fromValue : "N/A"}
                    </label>
                    <label className="mx-2 control-label d-inline">{getLabelText("to")}</label>
                    <label className="font-weight-bold control-label">
                      {toValue || toValue === 0 ? toValue : "N/A"}
                    </label>
                    </>
                  )
                  }
                </>
              )}
              {(title === "TREND TARGET" && isTrend) && (
                <>
                  <label className="mr-2 control-label d-inline">{getLabelText("from")}</label>
                  <label className="font-weight-bold control-label">
                    {fromValue}
                  </label>
                  <label className="mx-2 control-label d-inline">{getLabelText("to")}</label>
                  <label className="font-weight-bold control-label">
                    {toValue}
                  </label>
                  <label className="mx-2 control-label d-inline">{getLabelText("by")}</label>
                  <label className="font-weight-bold control-label">
                    {getGoalFormattedDate(by)}
                  </label>
                </>
              )}
            </>
          )}
      </div>
    </div>
  );
};

const Controls = ({ resultDate, goal, upperTrigger, lowerTrigger, setUpperTrigger, setLowerTrigger, isTrend, currentTarget, setCurrentTarget, calculatedTarget, editable, teamGoalGraphConfig, frequencyTrigger, setFrequencyTrigger }) => {
  return (
    <div>
      <div className="row justify-content-center">
        {(!isTrend && getPeriodDisplayText(goal.frequency).toUpperCase() !== "MONTH") && (
          <div className="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-xs-6 text-center">
            <div>
              <div className="d-flex flex-column w-100 justify-content-center align-items-center">
                <h6 className="font-weight-bold">{getLabelText("trend_target")}</h6>
                <div
                  className={classnames(
                    "d-flex w-100",
                    "justify-content-center"
                  )}
                >
                  {editable
                    ? (
                      <input style={{ width: '70px' }} className="form-control d-inline disabled controls-input" type="number" disabled value={calculatedTarget}></input>
                    ) : (
                      <label className="font-weight-bold control-label">
                        {calculatedTarget}
                      </label>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-xs-6 text-center">
          {(isTrend || getPeriodDisplayText(goal.frequency).toUpperCase() === "MONTH") && (
            <Section date={resultDate} goal={goal}
              title={getLabelText("trend_target")} teamGoalGraphConfig={teamGoalGraphConfig} editable={editable} from={goal.numberFrom} to={goal.numberTo} by={goal.dueDate} triggers={[goal.numberFrom, goal.numberTo]} setTrigger={setCurrentTarget} isTrend={isTrend || getPeriodDisplayText(goal.frequency).toUpperCase() === "MONTH"} isTarget={true} />
          )}
          {(!isTrend && getPeriodDisplayText(goal.frequency).toUpperCase() !== "MONTH") && (
            <Section date={resultDate} goal={goal}
              title={getLabelText("our_target")} teamGoalGraphConfig={teamGoalGraphConfig} editable={editable} from={currentTarget[0]} to={currentTarget[1]} setTrigger={setCurrentTarget} triggers={currentTarget} isTrend={isTrend} isTarget={true} />
          )}
        </div>
        {(!isTrend && getPeriodDisplayText(goal.frequency).toUpperCase() !== "MONTH") && (
          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-6 text-center">
            <Section
              date={resultDate}
              goal={goal}
              title={getLabelText("upper_trigger")}
              from={upperTrigger[0]}
              to={upperTrigger[1]}
              setTrigger={setUpperTrigger}
              triggers={upperTrigger}
              isUpper={true}
              isTrend={false}
              editable={editable}
              teamGoalGraphConfig={teamGoalGraphConfig}
            />
          </div>
        )}
        {(!isTrend && getPeriodDisplayText(goal.frequency).toUpperCase() !== "MONTH") && (
          <div className="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-xs-6 text-center">
            <Section
              date={resultDate}
              goal={goal}
              title={getLabelText("lower_trigger")}
              from={lowerTrigger[0]}
              to={lowerTrigger[1]}
              triggers={lowerTrigger}
              setTrigger={setLowerTrigger}
              isLower={true}
              isTrend={false}
              editable={editable}
              teamGoalGraphConfig={teamGoalGraphConfig}
              />
          </div>
        )}
        {(!isTrend && getPeriodDisplayText(goal.frequency).toUpperCase() !== "MONTH") && (
          <div className="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-xs-6 text-center">
            <Section single title={getLabelText("frequency_trigger")} edit={true}
              date={resultDate}
              goal={goal}
              from={frequencyTrigger}
              isFrequency={true}
              triggers={[frequencyTrigger, null]}
              setTrigger={setFrequencyTrigger}
              editable={editable}
              teamGoalGraphConfig={teamGoalGraphConfig}
              isTrend={false} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Controls;
