import React from "react";
import { VictoryLabel } from "victory";
import { lightBlue } from "./resources";
import { MetricPeriodStatuses } from "../resources";

function getLabelList(months) {
  let array = []
  if(typeof months !== "undefined") {
    months.map((month) => array.push(month.charAt(0)))
  }

  return array
}

const TickLabelComponent = ({ style, data, monthList, monthValues, resultDate, bottomLabels, ...props }) => {
  const dates = getLabelList(monthList)
  if(bottomLabels) {
    return (
      <VictoryLabel
        {...props}
        backgroundPadding={{ top: 4, left: 10, right: 10, bottom: 4 }}
        text={dates[props.text -1]}
        backgroundStyle={{
          fill: ({ index }) => {
            if (!data) return '#FFF'
            const { periodStatus } = data[index]
            const { color } = MetricPeriodStatuses[periodStatus]
            return color;
          },
          stroke: lightBlue,
          strokeWidth: 2,
        }}
        style={{ ...style, fontSize: "20px" }}
      />
    );
  } else {
    return (
      <VictoryLabel
        {...props}
        backgroundPadding={{ top: 4, left: 10, right: 10, bottom: 4 }}
        backgroundStyle={{
          fill: ({ index }) => {
            if (!data) return '#FFF'
            const { periodStatus } = data[index]
            const { color } = MetricPeriodStatuses[periodStatus]
            return color;
          },
          stroke: lightBlue,
          strokeWidth: 2,
        }}
        style={{ ...style, fontSize: "20px" }}
      />
    );
  }
};

export default TickLabelComponent;
