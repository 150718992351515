import React, { Component } from "react";
import { connect } from 'react-redux';
import { fetchUser } from '../../redux/services/UserService';
import { changeCompany } from '../../redux/services/AuthenticationService';
import { withRouter  } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import displayErrorToast from "../utils/displayErrorToast";
import SessionControls from "../SessionControls.js"
import SessionContext from "../../contexts/SessionContext";
import SessionLegendMenu from "./SessionLegendMenu"
import TeamSelector from '../TeamSelector';
import LabelComponent from '../utils/getCompanyLabel';

class FooterComponent extends Component {
  static contextType = SessionContext;

  componentDidMount() {
    this.props.fetchUser();
  }

  state = {
    loadingCompany: false
  }

  async selectCompany(id) {
    this.setState({
      loadingCompany: true
    })
    try {
      await this.props.changeCompany(id)
      localStorage.setItem("selectedTeamResult", "")
      localStorage.setItem("selectedBusinessResult", "")
      window.location.reload()
    } catch (err) {
      displayErrorToast(err)
      this.setState({
        loadingCompany: false
      })
    }
  }

  getLabelText = key => <LabelComponent val={key}/>

  logout = () => {
    localStorage.clear()

    window.location.href = "/"
  }

  getCompanyInput(user) {
    const companyId = localStorage.getItem("companyId")

    const { companies } = user

    const company = companies.find(company => company.id === companyId)

    if(companies.length > 1) {
      return (
        <Dropdown className="company-select-dropdown">
          <Dropdown.Toggle className="nav-link nav-pill team-select" variant="none" id="dropdown-basic">
            {this.state.loadingCompany
              ? <FontAwesomeIcon className="color-blue mx-auto fa-spin" icon={faSpinner} />
              :  company.name
            }
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {companies.map((comp, index) => {
              return <Dropdown.Item key={index} onClick={() => this.selectCompany(comp.id)}>{comp.name}</Dropdown.Item>
            })}
          </Dropdown.Menu>
        </Dropdown>
      )
    } else {
      return <div>{company.name}</div>
    }
  }

  render() {
    return (
      <>
        <footer className="text-uppercase footer-container">
          {this.props.presentation.active && !this.props.presentation.paused && (
            <SessionLegendMenu />
          )}
          {!this.props.isLoading && (
            <div className="d-flex pt-2 pb-2 pl-2 pr-2 justify-content-between">
              <table className="w-100 footer-tbl">
                <tbody>
                  <tr>
                    <td valign="middle" width="30%">
                      <div className={"copyright"}><span className={"copyIcon"}>&copy;</span> {this.getLabelText("copyright")}</div>
                    </td>
                    <td width="40%">
                      <SessionControls team={this.props.team}/>
                    </td>
                    <td width="30%">
                      <TeamSelector />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </footer>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  team: state.teamReducer.team,
  isLoading: state.userReducer.isLoading,
  presentation: state.presentationReducer
});

const mapDispatchToProps = {
  fetchUser,
  changeCompany
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FooterComponent));
