import React, { Component, Fragment } from "react";
import { Button, FormGroup, FormControl, FormLabel, Alert, Row, Col, Container } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { deleteUnit, updateUnit, createUnit, getUnit } from "../../services/UnitService";

class InputUnitScreen extends Component {
  state = {
    title: "Create Unit",
    edit: false,
    unitId: 0,
    unit: {
      name: "",
    },
    error: "",
    redirect: false
  }

  validateForm = () => {
    let valid = true
    const { unit } = this.state

    if (unit.name.length < 1)
      valid = false


    return valid
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    if (this.validateForm()) {
      const { edit, unit, unitId } = this.state
      try {
        edit ? await updateUnit(unitId, unit) : await createUnit(unit)
        this.setState({
          redirect: true
        })
      } catch (error) {
        this.setState({
          error: error.message
        })
      }
    } else {
      this.setState({
        error: "Validation Failed"
      })
    }
  }

  async componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ title: "Edit Unit", edit: true })
      try {
        const response = await getUnit(this.props.match.params.id)
        this.setState({
          unit: response.data.unit,
          unitId: this.props.match.params.id
        })
      } catch (error) {
        this.setState({
          error: error.message
        })
      }
    }
  }

  deleteUnit = async (e) => {
    e.preventDefault()
    if (window.confirm('Are you sure you wish to delete this item?')) {
      const { unitId } = this.state
      try {
        await deleteUnit(unitId)
        this.setState({
          redirect: true
        })
      } catch (error) {
        this.setState({
          error: error.message
        })
      }
    }
  }

  handleChange = (e) => {
    e.persist()
    this.setState((prevState) => ({
      unit: {
        ...prevState.unit,
        [e.target.name]: e.target.value
      },
      error: null
    }))
  }

  handleCheck = (key) => {
    this.setState((prevState) => ({
      unit: {
        ...prevState.unit,
        [key]: !prevState.unit[key]
      },
      error: null
    }))
  }

  render() {
    const { unit, error, redirect, title } = this.state

    if (redirect) return <Redirect to="/dashboard/units" />

    return (
      <Fragment>
        <Container>
          <Row className="justify-content-center">
            <Col xl={6} md={8} sm={12}>

              <form onSubmit={(e) => this.handleSubmit(e)} className="create-unit-from">
                <Row>
                  <Col className="text-left">
                    <h2 className="page-title pull-left mt-0">{title}</h2>
                  </Col>
                  <Col className="text-right">
                    {this.state.edit
                      ? <button className="btn btn-danger" onClick={(e) => this.deleteUnit(e)}>Delete Goal Category</button>
                      : null
                    }
                  </Col>
                </Row>
                <Alert className={error ? "d-block" : "d-none"} variant="danger">
                  {error}
                </Alert>
                <FormGroup>
                  <FormLabel htmlFor="name">Name</FormLabel>
                  <FormControl
                    autoFocus
                    type="text"
                    required
                    placeholder="Name"
                    value={unit.name}
                    name="name"
                    tabIndex="1"
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>

                <Button
                  block
                  type="submit"
                  variant="primary"
                >
                  {this.state.edit ? "Submit" : "Create"}
                </Button>
              </form>
            </Col>
          </Row>
        </Container>
      </Fragment>
    )
  }
}

export default InputUnitScreen;
