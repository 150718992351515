import { useMutation } from "@apollo/react-hooks";
import { CREATE_COMPANY_METRIC, UPDATE_COMPANY_METRIC } from "./gql";
import displayErrorToast from "../../components/utils/displayErrorToast";
import { useMemo } from "react";
import { MetricStatuses, MetricPeriodStatuses } from "./resources";
import {
  setISOWeek,
  startOfMonth,
  addDays,
  endOfMonth,
  differenceInCalendarISOWeeks,
  getISOWeek,
  format,
  startOfWeek,
  lastDayOfWeek,
  getDaysInMonth,
} from "date-fns";
import _ from 'lodash';

// Reusable CRUD hook for upserting metrics
export const useCreateOrUpdateMetric = () => {
  const [createMetric, { loading: createIsLoading }] = useMutation(
    CREATE_COMPANY_METRIC,
    {
      onError: displayErrorToast,
    }
  );
  const [updateMetric, { loading: updateIsLoading }] = useMutation(
    UPDATE_COMPANY_METRIC,
    {
      onError: displayErrorToast,
    }
  );

  return [createMetric, updateMetric, createIsLoading || updateIsLoading];
};

export const getPeriodValue = (value, months) => {
  return months[value - 1]
}

export const useDynamicDomain = (data, domain, target) => {

  return useMemo(() => {
    const minData = _.minBy(data, (d) => {
      return d.y
    })
    const maxData = _.maxBy(data, (d) => {
      return d.y
    })

    let all = []

    if(minData) {
      all.push(minData.y)
    }

    if(maxData) {
      all.push(maxData.y)
    }

    if(target && target.length > 0) {
      all.push(...target)
    }


    all = all.filter(val => val !== "")

    if(all.length < 1 || (all[0] === 0 && all[0] === 0 && all.length < 3)) {
      all = domain
    }
    const min  = _.minBy(all, (d) => {
      return d
    })
    const max = _.maxBy(all, (d) => {
      return d
    })

    if(min === max) return [min - 1, min + 1]
    return [min, max]

  }, [data, domain, target]);
};

export const useDateString = (data) =>
  useMemo(() => {
    const date = setISOWeek(new Date(), data.x);
    const first = format(startOfWeek(date), "d");
    const last = format(lastDayOfWeek(date), "d");
    const month = format(date, "MMM");
    const year = date.getFullYear();
    return `(${first}-${last} ${month} ${year})`;
  }, [data.x]);

// For a given date, returns an array of ISO week numbers (int)
export const getISOWeeksInMonth = (date) => {
  const firstDayOfMonth = startOfMonth(date);
  const lastDayOfMonth = addDays(endOfMonth(date), 1);

  const diff = differenceInCalendarISOWeeks(lastDayOfMonth, firstDayOfMonth);

  let dates = [];
  for (let i = 0; i < diff; i++) {
    const day = addDays(firstDayOfMonth, i * 7);
    dates.push(getISOWeek(day));
  }

  // Trim year start/end overlaps
  if (date.getMonth() === 11) dates = dates.filter(d => d > 10)
  if (date.getMonth() === 0) dates = dates.filter(d => d < 50)

  return dates;
};

// Transforms the weekly metric data to an array of graph coordinates
// OLD FUNCTION
// export const useMetricData = (goal, date, isTrend, monthValues) => {
//
//   // switch to dialy which is 1- 31 for the month
//   // or switch to yearly
//   const graphData = useMemo(() => {
//     let dates =  monthValues
//
//     const metrics = goal.companyMetrics.reduce((all, metric) => {
//       return {
//         ...all,
//         [metric.periodValue]: {
//           value: metric.value,
//           id: metric.id,
//           status: metric.status,
//           periodStatus: metric.periodStatus,
//           comment: metric.comment,
//           target: metric.target
//         },
//       };
//     }, {});
//
//     return dates.map((day) => {
//       const m = metrics[day] || {};
//       return {
//         x: day,
//         y: m.value || 0,
//         id: m.id || null,
//         status: m.status || MetricStatuses.RED.value,
//         periodStatus: m.periodStatus || MetricPeriodStatuses.NORMAL.value,
//         comment: m.comment || "",
//         target: m.target || null
//       };
//     });
//   }, [goal, monthValues]);
//
//   return graphData;
// };

// Transforms the weekly metric data to an array of graph coordinates
export const useMetricData = (goal, date, isTrend, monthValues) => {

  // switch to dialy which is 1- 31 for the month
  // or switch to yearly
  const graphData = useMemo(() => {
    let dates = [];
    if (isTrend) {
      dates = monthValues
    } else {
      switch (goal.frequency) {
        case "Daily":
          dates = getDaysInMonthArray(date);
          break;
        case "Weekly":
          dates = getISOWeeksInMonth(date);
          break;
        default:
          dates = monthValues
      }
    }

    const metrics = goal.companyMetrics.reduce((all, metric) => {
      return {
        ...all,
        [metric.periodValue]: {
          value: metric.value,
          id: metric.id,
          status: metric.status,
          periodStatus: metric.periodStatus,
          comment: metric.comment,
          target: metric.target
        },
      };
    }, {});
    return dates.map((day) => {

      const m = metrics[day] || {};
      return {
        x: dates.indexOf(day) + 1,
        y: m.value === undefined ? null : m.value,
        id: m.id || null,
        status: m.status || MetricStatuses.BLUE.value,
        periodStatus: m.periodStatus || MetricPeriodStatuses.NORMAL.value,
        target:m.target === undefined ? null : m.target,
        comment: m.comment || "",
        date: date
      };
    });
  }, [isTrend, goal, date, monthValues]);
  return graphData;
};

export const getPeriodKey = (fiscalYearStartMonth, date) => {
  const currentMonth = date.getMonth() + 1

  return currentMonth < fiscalYearStartMonth ? `${(date.getFullYear() - 1)}` : `${date.getFullYear()}`
}

export const getDaysInMonthArray = (date) => {
  let days = [];
  var x = getDaysInMonth(date);

  for (let index = 0; index < x; index++) {

    days.push(index + 1);

  }
  return days;
}
