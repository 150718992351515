import PasswordValidator from 'password-validator';
// const PasswordValidator = require('password-validator');

export const isPasswordValid = new PasswordValidator();

isPasswordValid
    .is().min(8)
    .is().max(100)
    .has().uppercase()
    .has().lowercase()
    .has().digits(1)
    .has().symbols(1)
    .has().not().spaces()
    .is().not().oneOf(['Passw0rd', 'Password123']);     // Blacklist these values

// // Validate against a password string
// console.log(isPasswordValid.validate('validPASS123'));
// // => true
// console.log(isPasswordValid.validate('invalidPASS'));
// // => false
//
// // Get a full list of rules which failed
// console.log(isPasswordValid.validate('joke', { list: true }));
// // => [ 'min', 'uppercase', 'digits' ]
