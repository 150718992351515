import React, { Fragment } from "react";
import { getWeekNumber, getDateFromWeek } from "../resources/utils";
import IconButton from "../../../components/shared/IconButton";
// icons
import Add from '../../../assets/ButtonIcons/Add Blue.svg';
import Archive from '../../../assets/ButtonIcons/Archive Blue.svg';
import Delete from '../../../assets/ButtonIcons/Delete Blue.svg';
import Edit from '../../../assets/ButtonIcons/Edit Blue.svg';
import Loader from '../../../assets/ButtonIcons/Loader Blue.svg';
import PutBack from '../../../assets/ButtonIcons/Put Back Blue.svg';
import Export from '../../../assets/ButtonIcons/Export Blue.svg';

const getTitle = type => type.charAt(0).toUpperCase() + type.slice(1)

const ButtonList = ({ type, obj, setCurrentObject, setCurrentType, setShowModal, deleteItem, loading, loadingType, loadingId, itemIndex, archiveItem, archive, getLabelText, archiveMilestone, exportProject }) => (
<div className="tracker-btn">
  <Fragment>
    {type === 'project' && (
        <IconButton tooltip={"Export"} eventHandler={() => exportProject(obj.id)}>
          <img src={Export} alt="Export" className="btn-icons"/>
        </IconButton>
    )}
    {type === "project" && !archive ? (
      <IconButton tooltip={getLabelText("add")} eventHandler={() => {
        setCurrentObject({
          id: 0,
          from: getWeekNumber(new Date()),
          to: (getWeekNumber(new Date()) + 1),
          fromDate: new Date(),
          toDate: getDateFromWeek(getWeekNumber(new Date()) + 1),
          order: obj.milestones.length,
          project: obj,
          name: ""
        });
        setCurrentType("Milestone");
        setShowModal(true)
      }}>
        <img src={Add} alt="Add" className="btn-icons"/>
      </IconButton>
    ) : null}
  </Fragment>
  <Fragment>
    {!archive ? (
      <IconButton tooltip={getLabelText("edit")} eventHandler={() => { setCurrentObject({
          ...obj,
          fromDate: obj.fromDate ? new Date(obj.fromDate) : getDateFromWeek(obj.from),
          toDate: obj.toDate ? new Date(obj.toDate) : getDateFromWeek(obj.to),
          order: obj.order ? obj.order : itemIndex
        }); setCurrentType(getTitle(type)); setShowModal(true); }}>
        <img src={Edit} alt="Edit" className="btn-icons"/>
      </IconButton>
    ) : null}
  </Fragment>
  <Fragment>
    {type === "milestone" || !archive ? (
      <IconButton tooltip={getLabelText("delete")} eventHandler={() => deleteItem(obj)}>
        {loading && loadingType === "delete" && loadingId === obj.id
          ? <img src={Loader} alt="Loader" className="loader-spinner"/>
          : <img src={Delete} alt="Delete" className="btn-icons"/>
        }
      </IconButton>
    ) : null}
  </Fragment>
  {type === "project" ? (
    <IconButton tooltip={obj.archived ? getLabelText("put_back") : getLabelText("archive")} eventHandler={() => archiveItem(obj)}>
      {obj.archived
        ? (
          <img src={PutBack} alt="PutBack" className="btn-icons"/>
        ) : (
          <img src={Archive} alt="Archive" className="btn-icons"/>
        )
      }
    </IconButton>
  ) : null}
  <Fragment>
    {type === "milestone" ? (
      <IconButton disabled={(obj.archived && !archive)} tooltip={getLabelText("archive")} eventHandler={() => archiveItem(obj)}>
        {
          loading && loadingType === "archive" && loadingId === obj.id
          ? <img src={Loader} alt="Loader" className="loader-spinner"/>
          : 
          <>
            { !obj.archived || !archive ?
              (
                <img src={Archive} alt="Archive" className="btn-icons"/>
              ) : (
                <img src={PutBack} alt="PutBack" className="btn-icons"/>
              )
            }
          </>
        }
      </IconButton>
    ) : null}
  </Fragment>
</div>

);

export default ButtonList;
